import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { contentSelectors } from '../../../models/content/index.js';

import { Icon } from '../../ui';
import ContentLink from '../content-link';

const ContentImage = ({
  contentId,
  size,
  orientation,
  imageIndex,
  imageUrl,
  imageUrls,
  imageAlt,
  isPublished,
  isProcessing,
  defaultImageUrl,
  isVideo,
  isPortrait,
  isMultiImage,
  isSquare,
  hideCount,
  ignoreLink,
  bgColor,
  style,
  analyticsData,
  onClick,
}) => {
  const className = cx('content__image', {
    'content__image--portrait': isPortrait,
    'content__image--square': isSquare,
    'content__image--video': isVideo,
  });

  if (orientation === 'landscape' && (isPortrait || isSquare))
    if (imageUrl) {
      imageUrl = imageUrl.match(/h_([0-9]+)/)
        ? imageUrl.replace(/w_([0-9]+)/, 'w_768').replace(/h_([0-9]+)/, 'h_475')
        : imageUrl.replace(/w_([0-9]+)/, 'w_768,h_475');
    }

  if (!imageUrl && !bgColor) bgColor = 'rgba(0, 0, 0, 0.05)';

  if (isMultiImage) imageUrl = imageUrls[imageIndex || 0];

  return (
    <div
      style={{
        backgroundImage: `url('${imageUrl || defaultImageUrl}')`,
        backgroundColor: bgColor,
        ...style,
      }}
      className={className}
      //need to put this on all child components: https://stackoverflow.com/questions/52204588/aria-hidden-true-on-parent-does-not-make-its-children-also-aria-hidden
      aria-hidden={!imageAlt}
    >
      <ContentLink
        contentId={contentId}
        ignoreLink={ignoreLink || !isPublished || isProcessing}
        analyticsData={analyticsData}
        onClick={onClick}
        aria-hidden={!imageAlt}
      >
        {isVideo ? (
          <img
            className="icon play-button"
            src="/images/icons/play-button.svg"
            alt="Play Button"
          />
        ) : null}

        {isMultiImage && size !== 'full' && !hideCount ? (
          <div className="image-count">
            <Icon className="multi-image" type="image" />
            {imageUrls.length}
          </div>
        ) : null}

        <img
          src={imageUrl || defaultImageUrl}
          alt={imageAlt}
          aria-hidden={!imageAlt}
          className="image"
        />
      </ContentLink>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  imageUrl:
    ownProps.size === 'full'
      ? contentSelectors.getContentImageUrl(state, ownProps)
      : contentSelectors.getContentThumbnailUrl(state, ownProps),
  imageUrls:
    ownProps.size === 'full'
      ? contentSelectors.getContentImageUrls(state, ownProps)
      : contentSelectors.getContentThumbnailUrls(state, ownProps),
  imageAlt: contentSelectors.getContentImageAlt(state, ownProps),
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
  isProcessing: contentSelectors.getContentIsProcessing(state, ownProps),
  defaultImageUrl: contentSelectors.getContentDefaultImage(state, ownProps),
  isVideo: contentSelectors.getContentIsVideo(state, ownProps),
  isPortrait: contentSelectors.getContentIsPortrait(state, ownProps),
  isSquare: contentSelectors.getContentIsSquare(state, ownProps),
  isMultiImage: contentSelectors.getContentIsMultiImage(state, ownProps),
  bgColor: contentSelectors.getContentBorderColor(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentImage);
