import { connect } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  contentSelectors,
  contentOperations,
} from '../../models/content/index.js';

import { Button, LikeIcon, LikeIconOutline } from '../ui';

import {
  trackContentCardLike,
  trackContentCardUnlike,
} from '../../models/content/analytics';

import './content.scss';
import { Feature } from '../../models/features/features';
import useFeatureFlag from '../../common/use-feature-flag';

const ContentActionLike = ({
  contentId,
  noText,

  liked,
  likeCount,
  hideEmptyCount,
  likeContent,
  unlikeContent,
  analyticsData,
}) => {
  const isThumbIcon = useFeatureFlag(Feature.LIKE_ICON_TYPE);
  const { t } = useTranslation();
  likeCount = likeCount && likeCount >= 0 ? likeCount : 0;

  const className = cx('content__action', 'content__action--like', {
    'content__action--like--heart': !isThumbIcon,
    'content__action--liked': liked,
    'content__action--empty': !likeCount,
  });

  const handleClick = () => {
    liked ? unlikeContent(contentId) : likeContent(contentId);

    liked
      ? trackContentCardUnlike(contentId, analyticsData)
      : trackContentCardLike(contentId, analyticsData);
  };

  const likeActionText = liked
    ? t('content.like_remove')
    : t('content.like', { count: 1 });
  const likeActionTitle = noText
    ? likeCount + ' ' + t('content.like', { count: likeCount })
    : null;

  return (
    <div className={className}>
      <Button
        onClick={handleClick}
        aria-label={likeActionText}
        title={likeActionTitle}
      >
        <span className="icons">
          <LikeIconOutline />
          <LikeIcon />
        </span>

        {likeCount || !hideEmptyCount ? (
          <span className="count">{likeCount}</span>
        ) : null}
        {noText ? null : (
          <span className="text">
            &nbsp;{t('content.like', { count: likeCount })}
          </span>
        )}
      </Button>
    </div>
  );
};

export default connect(
  (state, props) => ({
    liked: contentSelectors.getContentLiked(state, props),
    likeCount:
      props.likeCount || props.likeCount === 0
        ? props.likeCount
        : contentSelectors.getContentLikeCount(state, props),
  }),
  {
    likeContent: contentOperations.likeContent,
    unlikeContent: contentOperations.unlikeContent,
  }
)(ContentActionLike);
