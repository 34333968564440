import { useEffect, useState } from 'react';
import useElementSize from './use-element-size';

function useIsTruncated<T extends HTMLElement = HTMLDivElement>(): [
  T | null,
  (node: T | null) => void,
  boolean
] {
  const [setRef, size, ref] = useElementSize<T>();
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  useEffect(() => {
    setIsTruncated(size.height < size.scrollHeight);
  }, [size.height, size.scrollHeight]);

  return [ref, setRef, isTruncated];
}

export default useIsTruncated;
