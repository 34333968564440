import { useCallback, useContext, useMemo } from 'react';
import MojoApiFactory from '../../config/api-factory';
import { NotificationContext } from './context';
import { useSetRecoilState } from 'recoil';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import { AxiosPromise } from 'axios';
import { NotificationCounts } from '../../models/notifications/types';

enum BulkEditAction {
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  MARK_AS_READ = 'mark_as_read',
  MARK_AS_UNREAD = 'mark_as_unread',
  OPEN = 'open',
}

export const useBulkEdit = () => {
  const {
    multiSelectedItems: [multiSelectItems],
  } = useContext(NotificationContext);
  const setNotificationCounts = useSetRecoilState(notificationCountsState);

  const archivableItems = useMemo(
    () =>
      multiSelectItems.filter(
        (n) =>
          !n.attributes.archived &&
          !n.attributes.action_required &&
          !n.attributes.important
      ),
    [multiSelectItems]
  );
  const unArchivableItems = useMemo(
    () => multiSelectItems.filter((n) => n.attributes.archived),
    [multiSelectItems]
  );
  const markableAsReadItems = useMemo(
    () => multiSelectItems.filter((n) => !n.attributes.read),
    [multiSelectItems]
  );
  const markableAsUnreadItems = useMemo(
    () => multiSelectItems.filter((n) => n.attributes.read),
    [multiSelectItems]
  );

  //todo: modal confirmation if some of the selected items are not archived and also important or action required
  const someSelectedItemsNotArchivable = useMemo(
    () =>
      multiSelectItems.some(
        (n) =>
          !n.attributes.archived &&
          (n.attributes.important || n.attributes.action_required)
      ),
    [multiSelectItems]
  );
  const allSelectedItemsNotArchivable = useMemo(
    () =>
      multiSelectItems.length > 0 &&
      multiSelectItems
        .filter((n) => !n.attributes.archived)
        .every((n) => n.attributes.important || n.attributes.action_required),
    [multiSelectItems]
  );

  //todo: error handling and success toasts
  //todo: refresh feed after bulk edit (use SWR mutate)
  const bulkEditAction = useCallback(
    ({ ids, action }: { ids: string[]; action: BulkEditAction }) =>
      (
        MojoApiFactory.notifications.put(`notifications/bulk_edit`, {
          ids,
          edit_action: action,
        }) as AxiosPromise<{
          modified: {
            count: number;
            ids: string[];
          };
          unmodified: {
            count: number;
            ids: string[];
          };
          counts: NotificationCounts;
          undo_action: string;
          errors: string[];
        }>
      )
        .then((response) => {
          response.data.counts && setNotificationCounts(response.data.counts);
          return response;
        })
        .catch((error) => {
          if (error.response) {
            //axios error
            console.error('Error during bulk edit', error.response.data);
          } else {
            console.error('Error during bulk edit', error);
          }
        }),
    [setNotificationCounts]
  );

  const archiveItems = useCallback(
    () =>
      bulkEditAction({
        ids: archivableItems.map((n) => n.id),
        action: BulkEditAction.ARCHIVE,
      }),
    [archivableItems, bulkEditAction]
  );
  const unarchiveItems = useCallback(
    () =>
      bulkEditAction({
        ids: unArchivableItems.map((n) => n.id),
        action: BulkEditAction.UNARCHIVE,
      }),
    [unArchivableItems, bulkEditAction]
  );
  const markItemsAsRead = useCallback(
    () =>
      bulkEditAction({
        ids: markableAsReadItems.map((n) => n.id),
        action: BulkEditAction.MARK_AS_READ,
      }),
    [markableAsReadItems, bulkEditAction]
  );
  const markItemsAsUnread = useCallback(
    () =>
      bulkEditAction({
        ids: markableAsUnreadItems.map((n) => n.id),
        action: BulkEditAction.MARK_AS_UNREAD,
      }),
    [markableAsUnreadItems, bulkEditAction]
  );

  return {
    archivableItems,
    unArchivableItems,
    markableAsReadItems,
    markableAsUnreadItems,
    someSelectedItemsNotArchivable,
    allSelectedItemsNotArchivable,
    archiveItems,
    unarchiveItems,
    markItemsAsRead,
    markItemsAsUnread,
  };
};
