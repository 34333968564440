import { AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePatronSelector } from '../../common/use-patron-selector';
import { setTokenData } from '../../models/box-integration/operations';
import {
  getTokenExpiresAt,
  getTokenValue,
} from '../../models/box-integration/selectors';
import {
  BoxResourceId,
  BoxResourceType,
  BoxTokenData,
} from '../../models/box-integration/types';
import {
  fetchUserToken as apiFetchUserToken,
  fetchBoxObjectToken as apiFetchBoxObjectToken,
  getBoxFolder,
} from '../../services/box-integration';

type UseBoxTokenProps = {
  resourceType: BoxResourceType;
  resourceId: BoxResourceId;
  onError?: (err: unknown) => void;
};

export function useBoxToken({
  resourceType,
  resourceId,
  onError,
}: UseBoxTokenProps) {
  const dispatch = useDispatch();
  const token = usePatronSelector((state) =>
    getTokenValue(state, resourceType, resourceId)
  );
  const tokenExpiresAt = usePatronSelector((state) =>
    getTokenExpiresAt(state, resourceType, resourceId)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [, setRefreshTimeout] = useState<NodeJS.Timeout>();

  const tokenExpiration = useMemo(
    () => (tokenExpiresAt ? new Date(tokenExpiresAt) : undefined),
    [tokenExpiresAt]
  );

  const fetchBoxUserToken = useCallback(async (folderId: string) => {
    // only validate if folderId is not root
    if (folderId !== '0') {
      let boxFolder: AxiosResponse;

      try {
        boxFolder = await getBoxFolder(folderId);
      } catch (err) {
        if (
          isAxiosError(err) &&
          err.response?.status &&
          err.response.status % 400 < 100
        ) {
          throw new BoxTokenError(err.response);
        }
        throw err;
      }

      if (!boxFolder.data) {
        throw new Error('Box folder not found');
      }
    }

    const res = await apiFetchUserToken();
    return {
      token: res.data.token,
      expiresAt: res.data.token_expires_at,
    };
  }, []);

  const fetchBoxObjectToken = useCallback(
    async (resourceId: string): Promise<BoxTokenData> => {
      const res = await apiFetchBoxObjectToken(resourceId);
      const expiresAt = new Date();
      expiresAt.setTime(
        expiresAt.getTime() + (res.data.expires_in - 300) * 1000 // offset by 5 minutes earlier
      );

      return {
        token: res.data.access_token,
        expiresAt: expiresAt.toISOString(),
      };
    },
    []
  );

  const fetchToken = useCallback(async () => {
    setIsLoading(true);

    try {
      const tokenData =
        resourceType === 'folder'
          ? await fetchBoxUserToken(resourceId)
          : await fetchBoxObjectToken(resourceId);

      dispatch(setTokenData(tokenData, resourceType, resourceId));
      return tokenData;
    } catch (err) {
      onError?.(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    dispatch,
    fetchBoxObjectToken,
    fetchBoxUserToken,
    onError,
    resourceId,
    resourceType,
  ]);

  useEffect(() => {
    const isTokenExpired =
      !tokenExpiration || tokenExpiration.getTime() < Date.now();

    if (isTokenExpired) {
      void fetchToken();
    }
  }, [fetchToken, tokenExpiration]);

  useEffect(() => {
    if (!tokenExpiration) return;

    const expiresIn = tokenExpiration.getTime() - Date.now();

    setRefreshTimeout((timeout) => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
      return setTimeout(fetchToken, expiresIn);
    });

    return () => {
      setRefreshTimeout((timeout) => {
        if (timeout !== undefined) {
          clearTimeout(timeout);
        }
        return undefined;
      });
    };
  }, [fetchToken, tokenExpiration]);

  return { token: token || '', isLoading, refetch: fetchToken };
}

export class BoxTokenError extends Error {
  status: number;

  constructor(errorResponse: AxiosResponse) {
    super(errorResponse.data);
    this.name = 'BoxTokenError';
    this.status = errorResponse.status;
  }
}
