import { track } from '../analytics/helpers';
import {
  isIOSUIWebView,
  isAndroidWebView,
} from '../../lib/is-web-view-helpers';
import { androidEvents, iosEvents, webEvents } from './keys';

const detectEventGroup = () => {
  if (isIOSUIWebView()) return iosEvents;
  if (isAndroidWebView()) return androidEvents;
  return webEvents;
};

export const trackTranslateComment = (traits = {}) => {
  track(detectEventGroup().COMMENT_TRANSLATE, traits);
};

export const trackSeeOriginalComment = (traits = {}) => {
  track(detectEventGroup().COMMENT_SEE_ORIGINAL, traits);
};

export const trackTranslateAllComments = (traits = {}) => {
  track(detectEventGroup().COMMENT_TRANSLATE_ALL, traits);
};

export const trackSeeOriginalAllComments = (traits = {}) => {
  track(detectEventGroup().COMMENT_SEE_ORIGINAL_ALL, traits);
};
