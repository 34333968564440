import React from 'react';
import { useTranslation } from 'react-i18next';
import ChannelContributorsList from './channel-contributors-list';

const ChannelContributorsSection = ({ channelContributors }) => {
  const { t } = useTranslation();

  return (
    <div className="channel-details-v2__contributors">
      <h2 className="channel-details-v2__subtitle">
        {t('channel_details.contributors')}
      </h2>
      <ChannelContributorsList channelContributors={channelContributors} />
    </div>
  );
};

export default ChannelContributorsSection;
