import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';
import ChannelFeed from '../../../screens/channel-feed';
import TopicPage from './topic-page';
import TopicPageV2 from '../topic-page-v2';
import { TopicPageProps } from './types';
export const TopicPageWrapper = (props: TopicPageProps) => {
  const topicLandingEnabled = useFeatureFlag(
    Feature.TOPIC_LANDING_PAGES_ENABLED
  );
  const topicLandingV2Enabled = useFeatureFlag(
    Feature.TOPIC_LANDING_PAGES_V2_ENABLED
  );
  let ChannelComponent;
  if (topicLandingV2Enabled) {
    ChannelComponent = TopicPageV2;
  } else if (topicLandingEnabled) {
    ChannelComponent = TopicPage;
  } else {
    ChannelComponent = ChannelFeed;
  }

  return <ChannelComponent {...props} />;
};
