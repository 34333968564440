import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledLayout = styled.div`
  background-color: #f2f2f2;
  position: relative;
  font-size: 14px;
  font-family: Roboto, san-serif;
`;

interface RelatedContentLayoutProps {
  children: ReactNode;
}

const RelatedContentLayout: React.FC<RelatedContentLayoutProps> = ({
  children,
}) => <StyledLayout className="related-content">{children}</StyledLayout>;

export default RelatedContentLayout;
