import React from 'react';
import { useRecoilValue } from 'recoil';
import cx from 'classnames';

import { Link } from '../../components/ui';

import { trackQuickLinksClick } from '../../models/analytics';
import { homeLinks as homeLinksRecoilState } from '../../models/home-page/recoil-state';

import store from '../../config/store';

const unprefixUrl = (url) => {
  const state = store.getState();
  return url.replace(state.program.experience_url, '');
};

const HomeQuickLink = ({ item, className }) => {
  const {
    url,
    new_tab: newTab,
    processed_image: processedImage,
    title,
    description,
    order,
  } = item.attributes;

  const unprefixedUrl = newTab ? url : unprefixUrl(url);
  const action = unprefixedUrl !== url && !newTab ? 'push' : null;

  className = cx(className, 'quick-link');

  const handleClick = () => {
    trackQuickLinksClick({
      title,
      url,
      order,
      id: item.id,
    });
  };

  return (
    <Link
      href={unprefixedUrl}
      action={action}
      className={className}
      onClick={handleClick}
      target={newTab ? '_blank' : null}
      external={newTab}
    >
      <div className="quick-link__icon">
        <img src={processedImage} alt={title} aria-hidden="true" />
      </div>
      <div className="quick-link__description">
        <div className="title">{title}</div>
        <div className="text">{description}</div>
      </div>
    </Link>
  );
};

const HomeQuickLinks = () => {
  const homeLinks = useRecoilValue(homeLinksRecoilState);

  return (
    <div className="home-quick-links">
      {homeLinks &&
        homeLinks.map((item, idx) => (
          <HomeQuickLink
            item={item}
            className={idx === 0 ? 'quick-link' : null}
            key={idx}
          />
        ))}
    </div>
  );
};

export default HomeQuickLinks;
