import React from 'react';
import useProfileOrganizationFetcher from '../../common/use-profile-organization-fetcher';
import { Avatar, Link, Spinner } from '../../components/ui';
import cx from 'classnames';
import NotFound from '../not-found';
import AssistantError from '../assistant/assistant-error';
import ViewTrigger from '../../components/view-trigger';

const ChartUser = ({
  user: { id, avatar, name, jobTitle, currentUser },
  level,
}) => {
  const classes = cx(
    'org-chart-user__card',
    `org-chart-user__card-level-${level}`,
    {
      'org-chart-user__card-requested': currentUser,
      'pt-secondary-light-bordercolor': currentUser,
    }
  );

  const Wrapper = ({ children }) =>
    currentUser ? (
      <div className={classes} children={children} />
    ) : (
      <Link
        href={`/profiles/${id}/organization`}
        action="push"
        className={classes}
        children={children}
      />
    );

  return (
    <Wrapper>
      <div className={'org-chart-user__avatar'}>
        <Avatar src={avatar.url} alt={avatar.alt} bgColor={avatar.color} />
      </div>
      <div className={'org-chart-user__fields'}>
        <p className={'org-chart-user__name'}>{name}</p>
        <p className={'org-chart-user__job-title'}>{jobTitle}</p>
      </div>
    </Wrapper>
  );
};

const ChartSubordinateGroup = ({ children }) => {
  return <div className={'org-chart-sub-group'}>{children}</div>;
};

const ProfileOrganization = ({ profileId }) => {
  const {
    organization,
    isFetching,
    isNotFound,
    isError,
    getNextPage,
    nextPageAvailable,
  } = useProfileOrganizationFetcher({ profileId });

  const orgChartEmpty = organization === undefined || organization.length === 0;

  const renderOrganization = () => {
    if (orgChartEmpty) {
      return null;
    }

    return expandUser({ userArray: organization, userIndex: 0 });
  };

  const expandUser = ({ userArray, userIndex }) => {
    const user = userArray[userIndex];

    const nextUserHTML =
      userArray.length > userIndex + 1
        ? expandUser({ userArray, userIndex: userIndex + 1 })
        : null;

    return (
      <React.Fragment key={user.id}>
        <ChartUser user={user} />
        {user.hasSubordinates ? (
          <ChartSubordinateGroup>{nextUserHTML}</ChartSubordinateGroup>
        ) : (
          nextUserHTML
        )}
      </React.Fragment>
    );
  };

  if (isNotFound) return <NotFound />;
  if (isError) return <AssistantError />;

  return (
    <div className={'user-profile-organization'}>
      <h1 className={'user-profile-organization__title'}>
        Organizational Chart
      </h1>
      {renderOrganization()}
      {isFetching ? (
        <Spinner center={orgChartEmpty} />
      ) : nextPageAvailable ? (
        <ViewTrigger offset="200px" onInview={getNextPage} />
      ) : null}
    </div>
  );
};

export default ProfileOrganization;
