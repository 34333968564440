import { MouseEventHandler } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../ui';

import { trackContentCardEdit } from '../../../models/content/analytics';
import { trackEditClick } from '../../../models/content-submission/analytics';
import Tooltip from '../../tooltip/tooltip';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentSelectors } from '../../../models/content';

interface IContentActionEdit {
  showText?: boolean;
  onClick: MouseEventHandler | undefined;
  contentTitle: string;
  wideMode?: boolean;
}

const ContentActionEdit = ({
  showText = false,
  onClick,
  contentTitle,
  wideMode,
}: IContentActionEdit) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const displayText = showText || wideMode;

  const actionLabel = t('content.edit_action', { title: contentTitle });

  return (
    <div className="content__action content__action--edit">
      {displayText ? (
        <Button
          theme={'text'}
          size={'compact'}
          icon="edit"
          onClick={onClick}
          aria-label={actionLabel}
        >
          <span>{t('common.edit')}</span>
        </Button>
      ) : (
        <Tooltip text={t('common.edit')}>
          <Button
            theme={'icon-only'}
            size="32"
            icon="edit"
            onClick={onClick}
            aria-label={actionLabel}
          />
        </Tooltip>
      )}
    </div>
  );
};

type ContentActionEditWrapperProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  showText?: boolean;
  wideMode?: boolean;
};

const ContentActionEditWrapper = ({
  contentId,
  analyticsData,
  showText,
  wideMode,
}: ContentActionEditWrapperProps) => {
  const history = useHistory();

  const handleClick = () => {
    const editRoute = `/contents/${contentId}/edit`;
    history.push(editRoute);

    trackContentCardEdit(contentId, analyticsData);
    trackEditClick({ content_id: contentId });
  };

  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, { contentId })
  );

  return (
    <ContentActionEdit
      onClick={handleClick}
      contentTitle={contentTitle}
      showText={showText}
      wideMode={wideMode}
    />
  );
};

export { ContentActionEdit };

export default ContentActionEditWrapper;
