import { createSelector } from 'reselect';

import { notWithin, randNotWithin } from '../../lib/array-utils';
import { Channel } from './types';
import { RootPatronState } from '../../common/use-patron-selector';

const allChannels = (state: RootPatronState) => state.channels;

export const getChannels = createSelector(
  [allChannels],
  (channels): (Omit<Channel, 'id'> & { id: number })[] =>
    Object.values(channels).map((c) => ({ ...c, id: parseInt(c.id, 10) }))
);

export const getChannelsIds = createSelector([allChannels], (channels) =>
  Object.keys(channels)
);

export const getChannelsWithoutHome = createSelector(
  [getChannels],
  (channels) =>
    channels
      .filter((c) => !c.default)
      .sort((a, b) => a.originalIndex - b.originalIndex)
);

export const getChannelsFollowed = createSelector(
  [getChannelsWithoutHome],
  (channels) => channels.filter((c) => c.following)
);

export const getChannelsWithFollowers = createSelector(
  [getChannelsWithoutHome],
  (channels) => channels.filter((c) => c.follower_count > 0)
);

export const getChannelsNewest = createSelector(
  [getChannelsWithoutHome],
  (channels) =>
    channels
      .filter((c) => !!c.last_content_published_at)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
);

export const getChannelsRecommended = createSelector(
  [getChannelsNewest],
  (channels) =>
    channels
      .filter((c) => !!c.last_content_published_at && c.is_recommended)
      .sort((a, b) => {
        if (!a.following > !b.following) return -1;
        if (!a.following < !b.following) return 1;

        if (new Date(a.created_at) > new Date(b.created_at)) return -1;
        if (new Date(a.created_at) < new Date(b.created_at)) return 1;

        return 0;
      })
);

export const getChannelIdsNewest = createSelector(
  [getChannelsNewest],
  (channels) => channels.map((c) => c.id)
);

export const getChannelIdsRecommended = createSelector(
  [getChannelsRecommended],
  (channels) => channels.map((c) => c.id)
);

export const getChannelsPopular = createSelector(
  [getChannelsWithoutHome, getChannelsWithFollowers],
  (channels, channelsWithFollowers) =>
    (channelsWithFollowers.length ? channelsWithFollowers : channels)
      .filter((c) => !!c.last_content_published_at)
      .sort((a, b) => {
        if (a.follower_count > b.follower_count) return -1;
        if (a.follower_count < b.follower_count) return 1;

        if (new Date(a.created_at) > new Date(b.created_at)) return -1;
        if (new Date(a.created_at) < new Date(b.created_at)) return 1;

        return 0;
      })
);

export const getChannelIdsPopular = createSelector(
  [getChannelsPopular],
  (channels) => channels.map((c) => c.id)
);

const getChannelIdFirstUniqRecommended = createSelector(
  [getChannelIdsRecommended],
  (recommendedIds) => recommendedIds[0]
);

const getChannelIdFirstUniqNewest = createSelector(
  [getChannelIdsNewest, getChannelIdFirstUniqRecommended],
  (newestIds, recommendedId) => notWithin(newestIds, [recommendedId])[0]
);

const getChannelIdFirstUniqPopular = createSelector(
  [
    getChannelIdsPopular,
    getChannelIdFirstUniqRecommended,
    getChannelIdFirstUniqNewest,
  ],
  (popularIds, recommendedId, newestId) =>
    notWithin(popularIds, [recommendedId, newestId])[0]
);

const getChannelIdRandUniqRecommended = createSelector(
  [getChannelIdsRecommended],
  (recommendedIds) => randNotWithin(recommendedIds.slice(0, 5), [])
);

const getChannelIdRandUniqNewest = createSelector(
  [getChannelIdsNewest, getChannelIdRandUniqRecommended],
  (newestIds, recommendedId) =>
    randNotWithin(newestIds.slice(0, 5), [recommendedId])
);

const getChannelIdRandUniqPopular = createSelector(
  [
    getChannelIdsPopular,
    getChannelIdRandUniqRecommended,
    getChannelIdRandUniqNewest,
  ],
  (popularIds, recommendedId, newestId) =>
    randNotWithin(popularIds.slice(0, 5), [recommendedId, newestId])
);

export const getChannelById = (
  state: RootPatronState,
  channelId: string | number
): Channel | null =>
  Object.entries(allChannels(state)).find(
    ([key]) => key === String(channelId)
  )?.[1] ?? null;

export default {
  getChannels,
  getChannelById,
  getChannelsIds,
  getChannelsWithoutHome,
  getChannelsFollowed,
  getChannelsRecommended,
  getChannelIdsRecommended,
  getChannelsNewest,
  getChannelIdsNewest,
  getChannelsWithFollowers,
  getChannelsPopular,
  getChannelIdsPopular,
  getChannelIdFirstUniqRecommended,
  getChannelIdFirstUniqNewest,
  getChannelIdFirstUniqPopular,
  getChannelIdRandUniqRecommended,
  getChannelIdRandUniqNewest,
  getChannelIdRandUniqPopular,
};
