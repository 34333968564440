import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { darken } from 'polished';

import { contentSelectors } from '../../../models/content/index.js';

import { Icon } from '../../ui';
import ContentLink from '../content-link';

const ContentImage = ({
  contentId,
  ignoreLink,
  style,
  analyticsData,
  onClick,

  isPublished,
  isProcessing,
  isPortrait,
  isSquare,
  bgColor,
}) => {
  const className = cx('content__image', 'content__image--note', {
    'content__image--portrait': isPortrait,
    'content__image--square': isSquare,
  });

  if (!bgColor) bgColor = 'rgba(0, 0, 0, 0.05)';

  return (
    <div
      style={{
        backgroundColor: bgColor,
        ...style,
      }}
      className={className}
    >
      <ContentLink
        contentId={contentId}
        ignoreLink={ignoreLink || !isPublished || isProcessing}
        analyticsData={analyticsData}
        onClick={onClick}
      >
        <Icon
          className="message-quote"
          type="format_quote"
          style={{ color: darken(0.15, bgColor) }}
        />
      </ContentLink>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
  isProcessing: contentSelectors.getContentIsProcessing(state, ownProps),
  isPortrait: contentSelectors.getContentIsPortrait(state, ownProps),
  isSquare: contentSelectors.getContentIsSquare(state, ownProps),
  bgColor: contentSelectors.getContentBorderColor(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentImage);
