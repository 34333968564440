import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { contentSelectors } from '../../models/content/index.js';

import { Button, ShareIcon } from '../ui';
import ContentShare from './content-share';

import useDocumentListeners from '../../common/use-document-listeners';

import { trackContentCardShare } from '../../models/content/analytics';

import './content.scss';

const ContentActionShare = ({ contentId, isShared, analyticsData }) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const className = cx('content__action', 'content__action--share', {
    'content__action--shared': isShared,
  });

  const shareElement = useRef();
  const buttonElement = useRef();

  const handleDocClick = (e) => {
    const tarInShare =
      shareElement.current && shareElement.current.contains(e.target);
    const tarIsButton =
      buttonElement.current && buttonElement.current === e.target;

    if (!tarInShare || tarIsButton) {
      setShowModal(false);
    }
  };

  useDocumentListeners('click, wheel', showModal, handleDocClick);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);

    trackContentCardShare(contentId, analyticsData);
  };

  return (
    <div className={className} ref={shareElement}>
      <Button
        iconComponent={<ShareIcon />}
        ref={buttonElement}
        onClick={handleClick}
        aria-label={t('content.share')}
      />

      <ContentShare contentId={contentId} active={showModal} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isShared: contentSelectors.getContentShared(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentActionShare);
