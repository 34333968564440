import { useCallback, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

export const DEFAULT_SKIP_TO_CONTENT_ID = 'skip-to-content';

export default function useSkipToContent(
  targetId: string = DEFAULT_SKIP_TO_CONTENT_ID
) {
  const [focused, setFocused] = useState(false);

  const skipToContent = useCallback(() => {
    const element = document.getElementById(targetId);
    if (element) {
      element.setAttribute('data-tabindex-orig', String(element.tabIndex));
      element.tabIndex = -1;
      element.focus();
      setFocused(true);
    }
  }, [targetId]);

  useEventListener('focusout', (event) => {
    const { target: element } = event;

    if (!(element instanceof HTMLElement)) {
      return;
    }
    if (element.id !== targetId || element.tabIndex !== -1 || !focused) {
      return;
    }

    setFocused(false);

    const originalTabIndex = element.getAttribute('data-tabindex-orig');
    if (originalTabIndex) {
      element.tabIndex = Number(originalTabIndex);
    } else {
      element.removeAttribute('tabindex');
    }

    element.removeAttribute('data-tabindex-orig');
  });

  return {
    targetId,
    skipToContent,
  };
}
