import { useTranslation } from 'react-i18next';
import { Dialog } from '../ui';

const DiscardChangesDialog = ({ onContinue, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('dialogs.discard_changes.title')}
      accept={{
        text: t('dialogs.discard_changes.title'),
        theme: 'danger',
        onClick: onContinue,
      }}
      cancel={{
        text: t('common.cancel'),
        theme: 'link',
        onClick: onCancel,
      }}
    >
      {t('dialogs.discard_changes.body')}
    </Dialog>
  );
};
export const ID = 'Overlays.DISCARD_CHANGES_DIALOG';

export default DiscardChangesDialog;
