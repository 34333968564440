import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Icon, Checkbox } from '../ui';
import './external-link-modal.scss';

/*
  As part of our MS Teams integration, Microsoft requires that we inform users when they are leaving
  the Teams app. So this modal serves to warn users that they are leaving the app and opening an external link.

  This modal is only opened if the app is being accessed within Teams

  Currently, the following places in code call this modal:
  - src/common/use-assistant-action-handlers.ts when opening an "external" navigation action (This covers shortcuts and other assistant integrations)
  - src/components/ui/link.tsx when opening an external link
  - src/components/content/content-frame.tsx when handling an external link within an article body
*/

type ExternalLinkModalProps = {
  close: () => void;
  continueFn?: () => void; // `continue` is a reserved word
  linkUrl: string;
};

export const EXTERNAL_LINK_IGNORE_WARNING_KEY = 'ignoreExternalLinkWarning';

const ExternalLinkModal = ({
  close,
  continueFn,
  linkUrl,
}: ExternalLinkModalProps) => {
  const { t } = useTranslation();
  const [ignoreWarning, setIgnoreWarning] = useState(false);

  const continueAndClose = () => {
    if (continueFn) {
      continueFn?.();
    } else {
      window.open(linkUrl, '_blank');
    }

    if (ignoreWarning) {
      localStorage.setItem(EXTERNAL_LINK_IGNORE_WARNING_KEY, 'true');
    }

    close();
  };

  const handleIgnoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    setIgnoreWarning(target.checked);
  };

  return (
    <Modal className="external-link-modal" onClose={close}>
      <Icon type="launch" className="external-link-modal__icon" />
      <h3 className="external-link-modal__title">
        {t('dialogs.external_link.title')}
      </h3>
      <p className="external-link-modal__body">
        {t('dialogs.external_link.body')} <br />
        <a
          className="external-link-modal__link"
          onClick={close}
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
        >
          {linkUrl}
        </a>
        <p className="external-link-modal__remember">
          <Checkbox
            label={t('comments.private_dialog.hide_warn')}
            name="private_profile"
            checked={ignoreWarning}
            onChange={handleIgnoreChange}
          />
        </p>
      </p>
      <button
        className="external-link-modal__button button button--primary"
        type="button"
        onClick={continueAndClose}
      >
        {t('common.continue')}
      </button>
      <button
        className="external-link-modal__button button button--default"
        type="button"
        onClick={close}
      >
        {t('common.go_back')}
      </button>
    </Modal>
  );
};

export const ID = 'Overlays.EXTERNAL_LINK_MODAL';

export default ExternalLinkModal;
