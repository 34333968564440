import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import withRerenderPrevent from '../../common/with-rerender-prevent';
import { programSelectors } from '../../models/program';
import useScreenTitle from '../../common/use-screen-title';
import { trackSearchLoad } from '../../models/analytics';

import Page, { PageHeader } from '../../components/ui/page';
import SearchHeader from '../../components/search-header';
import SecondaryNav from '../../components/secondary-nav';

import SearchContent from './search-content';
import SearchChannels from './search-channels';
import SearchPeople from './search-people';
import SearchEnterprise from './search-enterprise';

import AssistantService from '../../services/assistant';

import './search.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import useFeatureFlag from '../../common/use-feature-flag';
import SecondarySearchNav from '../../components/v2/search/secondary-search-nav/secondary-search-nav';

const SEARCH_MAP = {
  contents: SearchContent,
  channels: SearchChannels,
  people: SearchPeople,
  enterprise: SearchEnterprise,
};

export const SearchResults = ({ count }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="search-component__result-count">
        {t(`assistant.search_results_count`, { count })}
      </p>
      {count === 0 ? (
        <p className="search-component__try-later">
          {t(`assistant.search_try_later`)}
        </p>
      ) : null}
    </>
  );
};

const Search = ({
  searchBarInHeaderEnabled,
  match: {
    params: { searchType, searchTerm = '', integrationId, command },
  },
}) => {
  const [searchProviders, setSearchProviders] = useState([]);
  const { t } = useTranslation();

  const term = decodeURIComponent(searchTerm);

  useScreenTitle(t('nav_links.search'));

  const analyticsData = {
    location: 'search',
  };

  const SearchComponent = SEARCH_MAP[searchType];
  let searchParams = null;
  let baseUrl;
  if (searchType === 'enterprise') {
    searchParams = {
      integrationId,
      command,
    };
    baseUrl = `/search/${searchType}/${integrationId}/${command}`;
  } else {
    baseUrl = `/search/${searchType}`;
  }

  async function getSearchProviders() {
    const assistant = new AssistantService();
    const integrationSearchProviders = await assistant.fetchSearchProviders();
    setSearchProviders(integrationSearchProviders);
  }

  useEffect(() => {
    trackSearchLoad({ searchType, searchTerm });
    getSearchProviders();
  }, []);

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const improvedSearchExperience = useFeatureFlag(Feature.IMPROVED_SEARCH);

  const channelsString = newTopicsString
    ? 'assistant.topics'
    : 'assistant.channels';

  const peopleString = improvedSearchExperience
    ? 'assistant.people_list'
    : 'assistant.people';

  const searchTypeString =
    searchType === 'channels' && newTopicsString
      ? `assistant.search_topics_title`
      : `assistant.search_${searchType}_title`;

  return (
    <Page className="search">
      <PageHeader>
        {!searchBarInHeaderEnabled ? (
          <SearchHeader
            baseUrl={baseUrl}
            term={term}
            title={
              <Fragment>
                {t(searchTypeString, {
                  term,
                  nest: false,
                })}
              </Fragment>
            }
          />
        ) : null}
        {improvedSearchExperience ? (
          <SecondarySearchNav
            totalResults={12}
            navItems={[
              {
                title: t('assistant.overview'),
                url: `/search/${term}`,
                type: 'overview',
                typeResults: 12,
              },
              {
                title: t('assistant.contents'),
                url: `/search/contents/${term}`,
                type: 'contents',
                typeResults: 12,
              },
              {
                title: t(channelsString),
                url: `/search/channels/${term}`,
                type: 'channels',
                typeResults: 12,
              },
              {
                title: t(peopleString),
                url: `/search/people/${term}`,
                type: 'people',
                typeResults: 12,
              },
              ...searchProviders.map(({ command, label, integration_id }) => {
                return {
                  title: label,
                  url: `/search/enterprise/${integration_id}/${command}/${searchTerm}`,
                  type: command,
                };
              }),
            ]}
          />
        ) : (
          <SecondaryNav
            navItems={[
              {
                title: t('assistant.contents'),
                url: `/search/contents/${searchTerm}`,
              },
              {
                title: t(channelsString),
                url: `/search/channels/${searchTerm}`,
              },
              {
                title: t(peopleString),
                url: `/search/people/${searchTerm}`,
              },
              ...searchProviders.map(({ command, label, integration_id }) => {
                return {
                  title: label,
                  url: `/search/enterprise/${integration_id}/${command}/${searchTerm}`,
                };
              }),
            ]}
          />
        )}
      </PageHeader>
      <SearchComponent
        term={term}
        analyticsData={analyticsData}
        key={term}
        searchParams={searchParams}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default compose(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(Search);
