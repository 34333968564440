import {
  useRef,
  useState,
  useEffect,
  RefObject,
  PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import './ui.scss';

type TooltipProps = PropsWithChildren<{
  className?: string;
  attach?: RefObject<HTMLElement>;
}>;

function Tooltip({ className, children, attach }: TooltipProps) {
  const [style, setStyle] = useState({ top: -9999, left: -9999 });
  const ref = useRef<HTMLDivElement>(null);
  const attachEle = attach && attach.current;

  className = cx(className, 'tooltip', {
    'tooltip--attached': attach,
  });

  useEffect(() => {
    const elPos = ref.current!.getBoundingClientRect();
    const minLeft = 5;
    const maxLeft = window.innerWidth - elPos.width - minLeft;

    const handleMouseMove = (e: MouseEvent) => {
      const cursorX = e.clientX;
      const cursorY = e.clientY;
      const posY = cursorY - (elPos.height + 5);
      const posX = cursorX - elPos.width / 2;

      setStyle({
        top: posY,
        left: posX < minLeft ? minLeft : posX > maxLeft ? maxLeft : posX,
      });
    };

    if (attachEle) {
      const attachPos = attachEle.getBoundingClientRect();
      const posX = attachPos.x - elPos.width - 10;
      const posY = attachPos.y + (attachPos.height - elPos.height) / 2;

      setStyle({
        top: posY,
        left: posX,
      });

      window.removeEventListener('mousemove', handleMouseMove);
    } else {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [attachEle]);

  if (!style) {
    return null;
  }

  return createPortal(
    // break tooltip out of current tree position to avoid z-index collisions
    <div className={className} style={style} ref={ref}>
      {children}
    </div>,
    document.body
  );
}

export default Tooltip;
