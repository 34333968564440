import { Content } from './types';

// function typing at utils.js are weird - we can merge these two modules once utils.js are typed

export function getContentAuthorName(content: Content) {
  return content.source_name;
}

export function getContentAuthorAvatarSrc(content: Content) {
  return (
    content?.source_avatar?.url ||
    (content.submitter_id ? null : content.source_image_url)
  );
}

export function getContentAuthorAvatarColor(content: Content) {
  const isSubmitted = getContentIsUserSubmitted(content);
  return isSubmitted ? content.source_avatar?.color : undefined;
}

export function getContentAuthorProfilePath(authorId: string | number) {
  return `/profiles/${authorId}`;
}

export function getContentDetailPath(contentId: string | number) {
  return `/contents/${contentId}`;
}

export function getContentIsUserSubmitted(content: Content) {
  return content.source_type === 'submitted';
}
