import { PropsWithChildren, useEffect, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import type * as PopperJS from '@popperjs/core';

interface IPopperWrapper {
  buttonRef: HTMLElement | null;
  closeCallback: () => void;
  isOpen?: boolean;
  className?: string;
  options?: Omit<Partial<PopperJS.Options>, 'modifiers'> & {
    createPopper?: typeof PopperJS.createPopper;
    modifiers?: ReadonlyArray<Modifier<unknown>>;
  };
}

const PopperWrapper = ({
  buttonRef,
  children,
  closeCallback,
  isOpen = false,
  className = '',
  options = {},
}: PropsWithChildren<IPopperWrapper>) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(buttonRef, popperElement, {
    ...options,
    modifiers: [
      { name: 'eventListeners', enabled: isOpen },
      ...(options.modifiers ?? []),
    ],
  });

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent | TouchEvent) => {
      //menu button click will bubble up to document in some cases when using keyboard to click it.
      if (
        isOpen &&
        e.target !== buttonRef &&
        e.target instanceof Element &&
        !e.target.closest('.popper-wrapper')
      ) {
        setTimeout(closeCallback, 15); // add slight timeout for mobile clients
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleDocumentClick);
      document.addEventListener('touchend', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('touchend', handleDocumentClick);
    };
  }, [isOpen, children, buttonRef, closeCallback]);

  return (
    <div
      className={className + ' popper-wrapper'}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};

export default PopperWrapper;
