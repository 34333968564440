import { useCallback, useMemo, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import defaultLocalStorageLib, {
  localStorageEnabled,
  LocalStorageLib,
} from '../lib/localstorage';
import NamespacedLocalStorage from '../lib/localstorage-namespaced';
import { usePatronSelector } from './use-patron-selector';

const storeEnabled = localStorageEnabled();

/**
 * A React hook to CRUD values in local storage. By default, the values are namespaced by users, and they will be
 * cleared when another user has signed in.
 *
 * @param key         Local storage key to store the values in
 * @param namespaced  Whether the values should be namespaced. True by default.
 */
export const useLocalStorage = (key: string, namespaced = true) => {
  const { advocate, program } = usePatronSelector((state) => state);

  const storageLib = useMemo<LocalStorageLib>(
    () =>
      namespaced
        ? new NamespacedLocalStorage({
            advocate_id: String(advocate.id),
            program_id: String(program.id),
          })
        : defaultLocalStorageLib,
    [advocate.id, namespaced, program.id]
  );

  // Adapted from https://github.com/juliencrn/usehooks-ts/blob/master/src/useLocalStorage/useLocalStorage.ts
  const readValue = useCallback(() => {
    return storageLib.getStorageItem(key, {});
  }, [key, storageLib]);

  const [storedValue, setStoredValue] = useState(readValue);

  const handleStorageChange = useCallback(
    (event) => {
      if (event?.key !== storageLib.getStorageKey(key)) {
        return;
      }
      setStoredValue(readValue());
    },
    [storageLib, key, readValue]
  );

  useEventListener('storage', handleStorageChange);

  const update = useCallback(
    (value) => {
      storageLib.setStorageItem(key, value);
    },
    [storageLib, key]
  );

  const remove = useCallback(() => {
    storageLib.removeStorageItem(key);
  }, [storageLib, key]);

  return {
    storeEnabled,
    value: storedValue,
    get: readValue,
    update,
    remove,
  };
};
