import { languageDirection } from '../../../../lib/language-helper';
import {
  getContentLang,
  getContentSummary,
  getContentTitle,
} from '../../../../models/content/selectors';
import Tooltip from '../../../tooltip/tooltip';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import useIsTruncated from '../../../../common/use-is-truncated';
import { DangerousHTMLWithMentionsAndAutolinks } from '../../../ui/mention-text';
import { usePlainTextMentions } from '../../../../common/use-plaintext-mentions';

type ContentDescriptionProps = {
  id: string;
  title: string;
  summary: string;
  language: string;
  maxLinebreaks: number;
};

const ContentDescription = ({
  id,
  title,
  summary,
  language,
  maxLinebreaks,
}: ContentDescriptionProps) => {
  const [, setRef, isTruncated] = useIsTruncated<HTMLParagraphElement>();

  const hideTooltip = !isTruncated;
  const parsedSummary = usePlainTextMentions({
    text: summary,
    disabled: hideTooltip,
  });

  //return empty if there is no title, as we trust that the summary has replaced the title in that case. (in the title component)
  //this is such an ugly wart but not much we can do without a major refactor.
  if (!title || !summary) {
    // Return an empty paragraph so that `aria-describedby` can be resolved. To the end user, they won't see
    // anything
    return <p id={id} />;
  }

  return (
    <Tooltip text={parsedSummary} disabled={hideTooltip}>
      <p
        id={id}
        ref={setRef}
        dir={languageDirection(language)}
        style={{ WebkitLineClamp: maxLinebreaks }}
        className="content__description"
      >
        <DangerousHTMLWithMentionsAndAutolinks text={summary} />
      </p>
    </Tooltip>
  );
};

type ContentDescriptionWrapperProps = {
  id: string;
  contentId: number;
  maxLinebreaks: number;
};

const ContentDescriptionWrapper = ({
  id,
  contentId,
  maxLinebreaks = 5,
}: ContentDescriptionWrapperProps) => {
  const title = usePatronSelector((state) =>
    getContentTitle(state, { contentId })
  );
  const summary = usePatronSelector((state) =>
    getContentSummary(state, { contentId })
  );
  const language = usePatronSelector((state) =>
    getContentLang(state, { contentId })
  );

  return (
    <ContentDescription
      id={id}
      title={title}
      summary={summary}
      maxLinebreaks={maxLinebreaks}
      language={language}
    />
  );
};

export default ContentDescriptionWrapper;
