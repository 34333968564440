import { ComponentPropsWithoutRef, useState } from 'react';
import cx from 'classnames';
import useRandomId from '../../common/use-random-id';

type ToggleProps = ComponentPropsWithoutRef<'label'> &
  Pick<
    ComponentPropsWithoutRef<'input'>,
    'name' | 'checked' | 'disabled' | 'readOnly' | 'onChange'
  > & {
    className: string;
    label?: string;
    helpText?: string;
    error?: string;
  };

export const Toggle = ({
  name,
  checked,
  disabled,
  readOnly,
  label,
  helpText,
  error,
  tabIndex,
  onChange,
  ...props
}: ToggleProps) => {
  const { id } = useRandomId();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <label
      aria-labelledby={id}
      {...props}
      className={cx('toggle', props.className)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        disabled={disabled}
        aria-disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        tabIndex={tabIndex || 0}
      />

      <span
        className={cx('input', {
          'is-focused': isFocused,
          'is-disabled': disabled,
        })}
      />

      <span className="label">
        <span id={id} className="label-text" hidden={!label}>
          {label}
        </span>

        {!error && helpText ? (
          <div className="help-text">{helpText}</div>
        ) : null}

        {error ? <div className="error-text">{error}</div> : null}
      </span>
    </label>
  );
};

/** @component */
export default Toggle;
