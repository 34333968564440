import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Icon, Button, SubmitCommentIcon } from '../ui';

interface CommentFormFooterProps {
  commentLength: number;
  commentLengthMax: number;
  disabled: boolean;
  onSubmit: () => void;
  onEmojiIconClick: () => void;
  onMentionIconClick: () => void;
}

const CommentFormFooter: React.FC<CommentFormFooterProps> = ({
  commentLength,
  commentLengthMax,
  disabled,
  onSubmit,
  onEmojiIconClick,
  onMentionIconClick,
}) => {
  const { t } = useTranslation();

  const counterClassName = cx('counter', {
    'counter--error': commentLength > commentLengthMax,
  });

  return (
    <div className="comment-form-footer">
      <div className="actions">
        <div className="basic-actions">
          <Button
            type="button"
            title={t('comments.actions.emoji')}
            onClick={onEmojiIconClick}
            aria-label={t('comments.actions.emoji')}
          >
            <Icon type={'insert_emoticon'} />
          </Button>

          <Button
            type="button"
            title={t('comments.actions.mention')}
            onClick={onMentionIconClick}
            aria-label={t('comments.actions.mention')}
          >
            <Icon type={'alternate_email'} />
          </Button>
        </div>
      </div>

      <div className="submit">
        <span
          role="status"
          aria-label="Character count"
          className={counterClassName}
        >
          {commentLength}/{commentLengthMax}
        </span>
        <Button
          type="submit"
          disabled={
            disabled || !commentLength || commentLength > commentLengthMax
          }
          onClick={onSubmit}
          aria-label={t('common.submit')}
        >
          <SubmitCommentIcon />
        </Button>
      </div>
    </div>
  );
};

export default CommentFormFooter;
