import { useTranslation } from 'react-i18next';
import { Icon } from '../ui';
import './profile-card.scss';

const ProfileCardPrivate = () => {
  const { t } = useTranslation();

  return (
    <div className="private-profile-card">
      <Icon type="lock" />
      <div className="private-profile-card__title">
        {t('screens.profile.private.title')}
      </div>
    </div>
  );
};

export default ProfileCardPrivate;
