import { Container } from '@socialchorus/shared-ui-components';
import styles from './search-topics.module.scss';
import FollowButton from '../../topic-page-v2/common/FollowButton';
import { Channel } from '../../../../models/channels/types';
import { useTranslation } from 'react-i18next';
import { useMemberCount } from './topics-search';

export function TopicCard({
  topic,
  analyticsData,
}: {
  topic: Channel;
  analyticsData: IAnalyticsData;
}) {
  const { t } = useTranslation();
  const topicNameElementId = `topic-name-${topic.id}`;
  const topicDescriptionElementId = `topic-description-${topic.id}`;
  const { memberCount, handleFollowUnfollow } = useMemberCount(
    topic.follower_count
  );

  return (
    <Container
      shape="large"
      shadow="light"
      aria-labelledby={topicNameElementId}
      aria-describedby={topicDescriptionElementId}
    >
      <div className={styles.CardContainer}>
        <div className={styles.Header}>
          {topic.background_image_url ? (
            <Container
              tag="img"
              src={topic.background_image_url}
              alt=""
              className={styles.Avatar}
            />
          ) : (
            <Container
              tag="div"
              alt=""
              className={styles.Avatar}
              style={{ background: topic.background_color }}
            />
          )}
          <div className={styles.TopicDetails}>
            <div className={styles.DetailsWrapper}>
              <a
                id={topicNameElementId}
                className={styles.TopicName}
                href={topic.permalink_url ?? '#'}
              >
                {topic.name}
              </a>
              <span className={styles.MemberCount}>
                {t('channel_details.number_joined', { count: memberCount })}
              </span>
            </div>
          </div>
          <FollowButton
            topicId={topic.id}
            analyticsData={analyticsData}
            onFollowUnfollow={handleFollowUnfollow}
          />
        </div>
        <h2 id={topicDescriptionElementId} className={styles.TopicDescription}>
          {topic.description}
        </h2>
      </div>
    </Container>
  );
}
