import { useState, useEffect } from 'react';
import AssistantService from '../services/assistant';
import { SearchResponse } from '../models/assistant/interfaces/SearchResponse';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';

function useAssistantSearch(category: string, ids: string[]) {
  const [searchResults, setSearchResults] = useState<{
    data: AssistantResponse[];
  }>();
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  function fetchSearchResults() {
    setIsFetching(true);

    new AssistantService()
      .search({ category, ids })
      .then(handleFetchSuccess, handleFetchFailure)
      .finally(() => setIsFetching(false));
  }

  const handleFetchSuccess = (
    res: SearchResponse | { data: AssistantResponse[] }
  ) => {
    if (!('data' in res)) {
      setIsError(true);
      return;
    }

    setSearchResults({ data: res.data });
  };

  const handleFetchFailure = () => {
    setIsError(true);
  };

  useEffect(() => {
    fetchSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, ...ids]);

  return {
    searchResults,
    fetchSearchResults,
    isFetching,
    isError,
  };
}

export default useAssistantSearch;
