import { FeedCard as SharedFeedCard } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../../loading-skeleton';
export function FeedCardLoading() {
  return (
    <SharedFeedCard.Root>
      <div className="col-span-7">
        <LoadingSkeleton height={14} />
        <LoadingSkeleton height={14} width="50%" />
        <LoadingSkeleton height={9} marginTop={13} />
        <LoadingSkeleton height={9} width="30%" />
        <LoadingSkeleton height={26} width={164} marginTop={56} />
        <LoadingSkeleton height={24} width={24} marginTop={24} />
      </div>
      <div className="col-span-1"></div>
      <div className="col-span-4">
        <LoadingSkeleton height={200} borderRadius={0} />
      </div>
    </SharedFeedCard.Root>
  );
}
