import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../models/ui';

const useHeaderLogoOnly = (hide) => {
  const dispatch = useDispatch();

  useEffect(() => {
    hide
      ? dispatch(uiOperations.logoOnlyHeader())
      : dispatch(uiOperations.defaultHeader());

    return () => dispatch(uiOperations.defaultHeader());
  }, [hide]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useHeaderLogoOnly;
