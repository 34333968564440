import { connect } from 'react-redux';
import { Link, Icon } from '../ui';
import { contentOperations } from '../../models/content/index.js';
import { trackContentCardLinkClick } from '../../models/content/analytics';

const ContentFrameLink = ({
  contentId,
  url,
  setIsContentCompleted,
  analyticsData,
}) => {
  const handleClick = () => {
    trackContentCardLinkClick(contentId, {
      ...analyticsData,
      is_external: true,
      link_location: url,
    });
    setIsContentCompleted(contentId);
  };

  const friendlyUrl = url && url.replace(/https?:\/\//i, '');
  const matches = url && url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  const externalHost = matches && matches[1];

  return (
    <div className="content-item__external-source">
      <Link
        href={url}
        className="external-link"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <span className="link-text">{friendlyUrl}</span>
        <span className="detail-text">View on {externalHost}</span>
        <Icon type="launch" />
      </Link>
    </div>
  );
};

export default connect(
  null,
  {
    setIsContentCompleted: contentOperations.completeContent,
  },
  null,
  { forwardRef: true }
)(ContentFrameLink);
