import { useEffect, useState } from 'react';
import ChannelService from '../../services/channel';
import useFeatureFlag from '../use-feature-flag';
import { Feature } from '../../models/features/features';
import { Channel, NormalizedChannel } from '../../models/channels/types';
import { useProgram } from '../use-program';

interface State {
  data: NormalizedChannel[];
  loading: boolean;
  error: any;
}

const defaultState: State = {
  data: [],
  loading: false,
  error: null,
};

function useSuggestedChannels() {
  const [state, setState] = useState<State>(defaultState);
  const program = useProgram();
  const topicLimit = program?.user_generated_content_topic_limit || 10;
  const topicLimitEnabled = useFeatureFlag(Feature.UGC_TOPIC_LIMITS);

  useEffect(() => {
    if (!topicLimitEnabled) {
      setState(defaultState);
      return;
    }
    const channelService = new ChannelService('');
    setState((prevState) => ({ ...prevState, loading: true, error: null }));
    channelService
      .fetchMostContributed(topicLimit)
      .then((response) => {
        setState({
          data: response.data.content_channels.map((c: Channel) => ({
            ...c,
            id: parseInt(c.id, 10),
          })),
          loading: false,
          error: null,
        });
      })
      .catch((e) => {
        setState((prevState) => ({ ...prevState, loading: false, error: e }));
        throw e;
      });
  }, [topicLimit, topicLimitEnabled]);

  return state;
}

export default useSuggestedChannels;
