export const size1 = '1.750rem';
export const size2 = '1.500rem';
export const size3 = '1.250rem';
export const size4 = '1.125rem';
export const size5 = '1.000rem';
export const size6 = '0.875rem';
export const size7 = '0.750rem';
export const size8 = '0.625rem';
export const sizeXs = size7;
export const sizeSm = size6;
export const sizeNm = size5;
export const sizeMd = size4;
export const sizeLg = size3;
export const sizeXl = size1;

export const weightXl = 100;
export const weightLt = 300;
export const weightNm = 400;
export const weightMd = 500;
export const weightBd = 700;
