import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import './ui.scss';

interface SpinnerProps {
  loading?: boolean;
  center?: boolean;
  children?: ReactNode;
}

export const Spinner: React.FC<SpinnerProps> = ({
  loading,
  center,
  children,
}) => {
  const { t, ready } = useTranslation(undefined, { useSuspense: false });

  const className = cx('spinner', {
    'spinner--center': center,
  });

  return (
    <>
      {loading ? (
        <div
          role="progressbar"
          aria-label={ready ? t('common.loading') : 'Loading'}
          className={className}
        />
      ) : (
        children
      )}
    </>
  );
};

Spinner.defaultProps = {
  loading: true,
};

Spinner.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default Spinner;
