import * as types from './keys';
import { track } from '../../analytics/helpers';
import store from '../../../config/store';

export const assistantTraits = (assignTraits) => {
  const state = store.getState();

  const advocate = state.advocate;

  return {
    advocate_id: advocate.id,
    ...assignTraits,
  };
};

// PAGE LOADS
export const trackAssistantButtonClick = (traits) => {
  track(types.ASSISTANT_BUTTON_CLICK, assistantTraits(traits));
};

export const trackAssistantInboxLoad = (traits) => {
  track(types.ASSISTANT_INBOX_LOAD, assistantTraits(traits));
};

export const trackAssistantExploreLoad = (traits) => {
  track(types.ASSISTANT_EXPLORE_LOAD, assistantTraits(traits));
};

export const trackAssistantNotificationsLoad = (traits) => {
  track(types.ASSISTANT_NOTIFICATIONS_LOAD, assistantTraits(traits));
};

export const trackAssistantDialogLoad = (traits) => {
  track(types.ASSISTANT_DIALOG_LOAD, assistantTraits(traits));
};

export const trackAssistantIntegrationLoad = (traits) => {
  track(types.ASSISTANT_INTEGRATION_LOAD, assistantTraits(traits));
};

export const trackAssistantResourcesLoad = (traits) => {
  track(types.ASSISTANT_RESOURCES_LOAD, assistantTraits(traits));
};

// AssistanSearch
export const trackAssistantSearchClick = (traits) => {
  track(types.ASSISTANT_SEARCH_CLICK, assistantTraits(traits));
};

export const trackAssistantExampleClick = (traits) => {
  track(types.ASSISTANT_EXAMPLE_CLICK, assistantTraits(traits));
};

export const trackAssistantSearchType = (traits) => {
  track(types.ASSISTANT_SEARCH_TYPE, assistantTraits(traits));
};

export const trackAssistantSearchSuccess = (traits) => {
  track(types.ASSISTANT_SEARCH_SUCCESS, assistantTraits(traits));
};

export const trackAssistantSearchFail = (traits) => {
  track(types.ASSISTANT_SEARCH_FAIL, assistantTraits(traits));
};

// AssistantInbox
export const trackAssistantInboxClick = (traits) => {
  track(types.ASSISTANT_INBOX_CLICK, assistantTraits(traits));
};

export const trackAssistantInboxItemView = (traits) => {
  track(types.ASSISTANT_INBOX_ITEM_VIEW, assistantTraits(traits));
};

export const trackAssistantSmartNotifClick = (traits) => {
  track(types.ASSISTANT_SMART_NOTIF_CLICK, assistantTraits(traits));
};

export const trackAssistantSmartNotifDismiss = (traits) => {
  track(types.ASSISTANT_SMART_NOTIF_DISMISS, assistantTraits(traits));
};

// AssistantExplorer
export const trackAssistantExploreClick = (traits) => {
  track(types.ASSISTANT_EXPLORE_CLICK, assistantTraits(traits));
};

export const trackAssistantQuickActionClick = (traits) => {
  track(types.ASSISTANT_QUICK_ACTION_CLICK, assistantTraits(traits));
};

export const trackAssistantActionsClick = (traits) => {
  track(types.ASSISTANT_ACTIONS_CLICK, assistantTraits(traits));
};

export const trackAssistantActionsView = (traits) => {
  track(types.ASSISTANT_ACTIONS_VIEW, assistantTraits(traits));
};

// NotificationsHistory
export const trackAssistantNotificationsHistoryClick = (traits) => {
  track(types.NOTIFICATIONS_HISTORY_CLICK, assistantTraits(traits));
};

export const trackAssistantNotificationsHistoryCardView = (traits) => {
  track(types.NOTIFICATIONS_HISTORY_CARD_VIEW, assistantTraits(traits));
};

export const trackAssistantNotificationsHistoryCardClick = (traits) => {
  track(types.NOTIFICATIONS_HISTORY_CARD_CLICK, assistantTraits(traits));
};

// Resources
export const trackAssistantResourceView = (traits) => {
  track(types.ASSISTANT_RESOURCE_VIEW, assistantTraits(traits));
};

export const trackAssistantResourceClick = (traits) => {
  track(types.ASSISTANT_RESOURCE_CLICK, assistantTraits(traits));
};

// AssistantCommand
export const trackAssistantDialogClick = (traits) => {
  track(types.ASSISTANT_DIALOG_CLICK, assistantTraits(traits));
};

export const trackAssistantCommandRun = (traits) => {
  track(types.ASSISTANT_COMMAND_RUN, assistantTraits(traits));
};

export const trackAssistantIntegrationLinkClick = (traits) => {
  track(types.ASSISTANT_INTEGRATION_LINK_CLICK, assistantTraits(traits));
};

// Response Cards
export const trackAssistantCommandSummaryResponseView = (traits) => {
  track(types.ASSISTANT_COMMAND_SUMMARY_RESPONSE_VIEW, assistantTraits(traits));
};

export const trackAssistantCommandSummaryResponseClick = (traits) => {
  track(
    types.ASSISTANT_COMMAND_SUMMARY_RESPONSE_CLICK,
    assistantTraits(traits)
  );
};

export const trackAssistantCommandContentResponseView = (traits) => {
  track(types.ASSISTANT_COMMAND_CONTENT_RESPONSE_VIEW, assistantTraits(traits));
};

export const trackAssistantCommandContentResponseClick = (traits) => {
  track(
    types.ASSISTANT_COMMAND_CONTENT_RESPONSE_CLICK,
    assistantTraits(traits)
  );
};

export const trackAssistantCommandChannelResponseView = (traits) => {
  track(types.ASSISTANT_COMMAND_CHANNEL_RESPONSE_VIEW, assistantTraits(traits));
};

export const trackAssistantCommandChannelResponseClick = (traits) => {
  track(
    types.ASSISTANT_COMMAND_CHANNEL_RESPONSE_CLICK,
    assistantTraits(traits)
  );
};

export const trackAssistantCommandPollResponseView = (traits) => {
  track(types.ASSISTANT_COMMAND_POLL_RESPONSE_VIEW, assistantTraits(traits));
};

export const trackAssistantCommandLinkResponseView = (traits) => {
  track(types.ASSISTANT_COMMAND_LINK_RESPONSE_VIEW, assistantTraits(traits));
};

export const trackAssistantCommandLinkResponseClick = (traits) => {
  track(types.ASSISTANT_COMMAND_LINK_RESPONSE_CLICK, assistantTraits(traits));
};

// Summary detail view
export const trackAssistantCommandDetailViewLoad = (traits) => {
  track(types.ASSISTANT_COMMAND_DETAIL_VIEW_LOAD, assistantTraits(traits));
};

// NeedsAttention
export const trackAssistantNeedsAttentionCardView = (traits) => {
  track(types.ASSISTANT_NEEDS_ATTENTION_CARD_VIEW, assistantTraits(traits));
};

export const trackAssistantNeedsAttentionCardClick = (traits) => {
  track(types.ASSISTANT_NEEDS_ATTENTION_CARD_CLICK, assistantTraits(traits));
};
