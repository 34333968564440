import { Comment, CommentWithReplies, isCommentWithReplies } from '../types';
import { toDate } from '../../../lib/date-formatter';

type State = (Comment | CommentWithReplies)[];

export enum SortOrder {
  TOP = 'top',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  MOST_LIKED = 'most_liked',
  MOST_REPLIED = 'most_replied',
}

export function addComment(
  state: State,
  {
    comment,
    sortOrder,
  }: {
    comment: Comment;
    sortOrder: SortOrder;
  }
) {
  if (
    [SortOrder.OLDEST, SortOrder.MOST_LIKED, SortOrder.MOST_REPLIED].includes(
      sortOrder
    )
  ) {
    //put new comment last (is assuming no negative like/reply count)
    return [...state, comment];
  } else if (sortOrder === SortOrder.TOP) {
    //put new comment after the last highlighted comment
    return [comment, ...state].sort((leftComment, rightComment) => {
      if (leftComment.highlightedAt && !rightComment.highlightedAt) {
        return -1;
      }
      if (!leftComment.highlightedAt && rightComment.highlightedAt) {
        return 1;
      }
      if (toDate(leftComment.createdAt) > toDate(rightComment.createdAt)) {
        return -1;
      }
      if (toDate(leftComment.createdAt) < toDate(rightComment.createdAt)) {
        return 1;
      }
      return 0;
    });
  } else {
    //put new comment first
    return [comment, ...state];
  }
}

export function updateComment(
  state: State,
  { comment }: { comment: Partial<Comment> }
) {
  if (comment.replyToId) {
    const commentIndex = state.findIndex((c) => c.id === comment.replyToId);

    // It's possible for a parent comment to have been deleted.
    if (state[commentIndex]) {
      return updateReply(state, {
        parentComment: state[commentIndex] as CommentWithReplies,
        comment,
      });
    }
  }

  const index = state.findIndex((c) => c.id === comment.id);
  return [
    ...state.slice(0, index),
    { ...state[index], ...comment },
    ...state.slice(index + 1),
  ];
}

export function updateReply(
  state: State,
  {
    parentComment,
    comment,
  }: { parentComment: CommentWithReplies; comment: Partial<Comment> }
) {
  if (!parentComment.replies) {
    return state;
  }

  const parentIndex = state.findIndex((c) => c.id === parentComment.id);
  const oldParentComment = state[parentIndex] as CommentWithReplies;
  const replyIndex = oldParentComment.replies.findIndex(
    (c) => c.id === comment.id
  );
  const parentReplies = oldParentComment.replies;
  return [
    ...state.slice(0, parentIndex),
    {
      ...oldParentComment,
      replies: [
        ...parentReplies.slice(0, replyIndex),
        { ...parentReplies[replyIndex], ...comment },
        ...parentReplies.slice(replyIndex + 1),
      ],
    },
    ...state.slice(parentIndex + 1),
  ];
}

export function removeComment(state: State, { comment }: { comment: Comment }) {
  if (comment.replyToId) {
    return removeReply(state, { comment });
  }

  return state.filter((c) => c.id !== comment.id);
}

export function removeReply(state: State, { comment }: { comment: Comment }) {
  const commentIndex = state.findIndex((c) => c.id === comment.replyToId);
  const parentComment = state[commentIndex];

  if (!isCommentWithReplies(parentComment)) {
    return state;
  }

  const replies = parentComment.replies.filter((c) => c.id !== comment.id);

  return [
    ...state.slice(0, commentIndex),
    {
      ...parentComment,
      replyCount: parentComment.replyCount - 1,
      replies: replies,
      replyAuthors: replies.map((reply) => reply.author),
    },
    ...state.slice(commentIndex + 1),
  ];
}
