import { useTranslation } from 'react-i18next';

import { Spinner } from '../../components/ui';
import { AssistantPoll, AssistantBackBar } from '../../components/assistant';
import EmptyFeed from '../../components/feed/empty-feed';
import ViewTrigger from '../../components/view-trigger';
import AssistantError from './assistant-error';
import AssistantContainer from './assistant-container';

import usePollsFetcher from '../../common/use-polls-fetcher';
import AssistantContent from './assistant-content';
import AssistantTitle from './assistant-title';

function AssistantAnsweredPolls() {
  const { t } = useTranslation();

  const { polls, fetchPolls, isFetching, canLoadMore, isError } =
    usePollsFetcher(/*match.params.pollId*/);

  return (
    <AssistantContainer flex className="assistant__polls">
      <AssistantBackBar />
      <AssistantContent>
        <AssistantTitle>{t('assistant.poll_results')}</AssistantTitle>
        <div className="assistant__poll-list">
          {polls && polls.length ? (
            polls.map((poll) => (
              <div className="assistant__todo-item" key={poll.id}>
                <AssistantPoll subject={poll.subject} />
              </div>
            ))
          ) : isError ? (
            <AssistantError
              inline
              error={t('screens.subscriptions.not_found')}
            />
          ) : null}

          {isFetching ? (
            <Spinner center={!polls.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={() => fetchPolls()} />
          ) : polls && polls.length ? null : (
            <EmptyFeed />
          )}
        </div>
      </AssistantContent>
    </AssistantContainer>
  );
}

export default AssistantAnsweredPolls;
