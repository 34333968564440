import cx from 'classnames';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { programSelectors } from '../../models/program';
import { NavLink } from '../ui';
import ConditionalWrapper from '../../common/conditional-wrapper';
import './site-header.scss';
import { ReactNode } from 'react';
import { RootPatronState } from '../../common/use-patron-selector';
import { uiSelectors } from '../../models/ui';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  logoOnly?: boolean;
  nameOnly?: boolean;
  isMinimized?: boolean;
};

type SiteHeaderLogoProps = StateProps & OwnProps;

const SiteHeaderLogo = ({
  logoOnly,
  searchBarInHeaderEnabled,
  nameOnly = false,
  isMinimized,
}: SiteHeaderLogoProps) => {
  const programheaderUrl = useSelector(programSelectors.getProgramHeaderUrl);
  const programName = useSelector(programSelectors.getProgramName);
  const tabIndexProps = isMinimized ? { tabIndex: -1 } : {};

  return (
    <ConditionalWrapper
      condition={!logoOnly}
      wrapper={(children: ReactNode) => (
        <NavLink href="/" exact reloadDocument {...tabIndexProps}>
          {children}
        </NavLink>
      )}
    >
      <>
        {programheaderUrl && !nameOnly ? (
          <img
            className={cx('site-header__logo', { 'logo-only': logoOnly })}
            src={programheaderUrl || '/images/sc-logo.svg'}
            alt={`${programName} logo`}
          />
        ) : null}
        {searchBarInHeaderEnabled ? (
          <div
            className={cx('site-header__heading', {
              hidden: !!programheaderUrl,
              search_bar_in_header: searchBarInHeaderEnabled,
              'logo-only': logoOnly,
            })}
          >
            {programName}
          </div>
        ) : (
          <div
            className={cx('site-header__heading', {
              hidden: !!programheaderUrl,
              'logo-only': logoOnly,
            })}
          >
            {programName}
          </div>
        )}
      </>
    </ConditionalWrapper>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
  isMinimized: uiSelectors.getMinimizeHeader(state),
});

export default connect(mapStateToProps)(SiteHeaderLogo);
