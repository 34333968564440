import { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { programSelectors } from '../../models/program';
import { getChannelById } from '../../models/channels/selectors';
import { homeFeedSections } from '../../models/home-page/recoil-state';
import { Feature, getFeatureFlag } from '../../models/features/features';
import InitiativeTagService from '../../services/initiative';

import withRerenderPrevent from '../../common/with-rerender-prevent';
import useContentFeed from '../../common/use-content-feed';
import ViewTrigger from '../../components/view-trigger';

import HomeFeaturedFeed from './home-featured-feed';
import HomeFilteredFeed from './home-filtered-feed';
import HomeCommunityGallery from './home-community-gallery';
import ContentLayoutGallery from '../../components/v2/content-layouts/content-layout-gallery';
import ContentLayoutRow from '../../components/v2/content-layouts/content-layout-row';
import ContentLayoutGrid from '../../components/v2/content-layouts/content-layout-grid';
import ContentLayoutHero from '../../components/v2/content-layouts/content-layout-hero';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const HomeFeedSection = ({
  item,
  className,
  updateSectionContents,
  contents,
  initiativeTags,
  shouldRefetch,
  onRefetch,
  newUIEnabled = false,
}) => {
  const {
    filter_id: filterId,
    display_type: displayType,
    feed,
    order,
    min_contents: minContents,
  } = item.attributes;

  const { t } = useTranslation();

  const [updated, setUpdated] = useState(false);

  const manualFetch = order > 1;
  const channel = useSelector((state) => getChannelById(state, filterId));

  const initiative = initiativeTags.filter(
    (tag) => parseInt(tag.id) === filterId
  )[0]?.attributes;
  const filter = () => {
    if (feed === 'community') return 'community_images';
    if (feed === 'channel') return null;
    return feed;
  };

  const displayTitle = () => {
    if (feed === 'channel') return channel?.name;
    if (feed === 'initiative') return initiative?.name;
    return t(`landing.${feed}`, feed);
  };

  const displayDescription = () => {
    if (feed === 'channel') return channel?.description;
    if (feed === 'initiative') return initiative?.description;
    return t(`landing.${feed}_description`);
  };

  const getSectionId = () => {
    if (feed === 'channel' && channel) return `channel-${channel.id}`;
    if (feed === 'initiative' && initiative) {
      return `initiative-${initiative.id}`;
    }
    return feed;
  };

  const viewAllText =
    feed === 'featured' || feed === 'trending' || feed === 'latest'
      ? t(`landing.see_all_${feed}`, '')
      : `${t('landing.see_all')} ${displayTitle()}`;

  const maxDisplayContents = {
    hero: 4,
    grid: 6,
    gallery: 9,
    row: 3,
  };

  const viewAllUrl = () => {
    if (feed === 'channel') return `/channels/${filterId}`;
    if (feed === 'initiative') return `/initiatives/${filterId}`;
    return `/feed/${feed}`;
  };

  const perPage =
    Math.max(...Object.values(maxDisplayContents)) * order +
    maxDisplayContents[displayType];

  const { fetching, loaded, feedItems, fetchFeed } = useContentFeed({
    filter: filter(),
    perPage: perPage,
    channelId: feed === 'channel' ? filterId : null,
    initiativeId: feed === 'initiative' ? filterId : null,
    manualFetch: manualFetch,
    includeTypes: displayType === 'gallery' ? 'image' : null,
  });

  useEffect(() => {
    if (!fetching && !updated) {
      updateSectionContents(order, feedItems, maxDisplayContents[displayType]);
      setUpdated(true);
      onRefetch?.();
    }
  }, [fetching, updated]);

  useEffect(() => {
    setUpdated((updated) => {
      if (!updated || !shouldRefetch) return updated;

      fetchFeed(true);
      return false;
    });
  }, [fetchFeed, shouldRefetch]);

  const feedComponentDisplays = {
    hero: HomeFeaturedFeed,
    grid: HomeFilteredFeed,
    gallery: HomeCommunityGallery,
    row: HomeFilteredFeed, // Row will fallback to grid display when configured and disabled, but adding this for safety
  };

  const v2FeedComponentDisplays = {
    hero: ContentLayoutHero,
    grid: ContentLayoutGrid,
    gallery: ContentLayoutGallery,
    row: ContentLayoutRow,
  };

  const feedComponentProps = () => {
    if (displayType === 'gallery') {
      return {
        id: getSectionId(),
        contents: contents,
        description: displayDescription(),
        analyticsData: { location: `home_${feed}` },
        title: displayTitle(),
        feedUrl: viewAllUrl(),
        // @deprecated: v2 components use `viewAllText`
        seeAllText: viewAllText,
        viewAllText,
        isTopic: feed === 'channel',
      };
    } else {
      return {
        id: getSectionId(),
        title: displayTitle(),
        description: displayDescription(),
        // @deprecated: v2 components use `viewAllText`
        seeAllText: viewAllText,
        viewAllText,
        feedUrl: viewAllUrl(),
        contents: contents,
        minLength: minContents,
        maxLength: displayType === 'hero' ? 4 : 6,
        analyticsData: { location: `home_${feed}` },
        isFetching: fetching,
        isTopic: feed === 'channel',
      };
    }
  };

  const shouldDisplay = () => {
    //the home feed component assume an array of content with length > 1. Ran into this edge case on my local, adding defensive check for uninitialized data coming from server
    let result = contents.length >= (minContents || 1);
    if (feed === 'initiative') {
      result = result && initiative !== undefined && initiative.is_active;
    }

    if (feed === 'channel') {
      result = result && channel !== undefined;
    }

    return result;
  };

  const classNames = newUIEnabled
    ? {
        hero: 'home__featured-v2',
        grid: 'home__digests',
        gallery: 'home__community-v2',
        row: 'home__row-v2',
      }
    : {
        hero: 'home__featured',
        grid: 'home__digests',
        gallery: 'home__community',
        row: 'home__digests',
      };

  const FeedComponent = newUIEnabled
    ? v2FeedComponentDisplays[displayType]
    : feedComponentDisplays[displayType];

  if (manualFetch && !loaded) {
    return <ViewTrigger offset="200px" onInview={fetchFeed} />;
  }

  if (shouldDisplay()) {
    const feedProps = feedComponentProps();

    return (
      <section
        id={DEFAULT_SKIP_TO_CONTENT_ID}
        className={cx(classNames[displayType], className)}
        aria-labelledby={`${feedProps.id}--title`}
        aria-describedby={`${feedProps.id}--description`}
      >
        <div className="container">
          <FeedComponent {...feedProps} />
        </div>
      </section>
    );
  }

  return null;
};

const dedupContents = (contentsArr, lengthArr) => {
  const flattenContentIds = (contents) =>
    [].concat.apply([], contents).map((c) => c.id);

  return contentsArr.reduce((arr, contents, idx) => {
    const curIds = flattenContentIds(arr);
    const uniqContents = contents
      .filter((c) => !curIds.includes(c.id))
      .slice(0, lengthArr[idx]);

    return [...arr, uniqContents];
  }, []);
};

const HomeFeeds = ({
  inCaseYouMissedItEnabled,
  fromTheCommunityEnabled,
  forYouFeedEnabled,
  shouldRefetch,
  onRefetch,
  newUIEnabled = false,
}) => {
  const feedSections = useRecoilValue(homeFeedSections);

  const numFeeds = feedSections.length;
  const [sectionsContents, setSectionsContents] = useState(
    Array(numFeeds).fill([])
  );
  const [maxContents, setMaxContents] = useState(Array(numFeeds).fill(0));
  const uniqContents = useMemo(() => {
    return dedupContents(sectionsContents, maxContents);
  }, [sectionsContents, maxContents]);

  const [initiativeTags, setInitiativeTags] = useState([]);

  useEffect(() => {
    const filter_ids = feedSections
      .map((section) => section.attributes.filter_id)
      .filter((id) => id);
    new InitiativeTagService({ ids: filter_ids })
      .fetch()
      .then((res) => setInitiativeTags(res.data.data))
      .catch((err) => console.error(err));
  }, [feedSections]);

  const updateSectionContents = (order, newContents, newMaxContents) => {
    setMaxContents((maxContentsOLD) =>
      maxContentsOLD.map((length, index) => {
        return index === order ? newMaxContents : length;
      })
    );

    setSectionsContents((sectionsContentsOLD) =>
      sectionsContentsOLD.map((contents, index) => {
        return index === order ? newContents : contents;
      })
    );
  };

  const display = (feed) => {
    if (feed === 'community') return fromTheCommunityEnabled;
    if (feed === 'icymi') return inCaseYouMissedItEnabled;
    if (feed === 'for_you') return forYouFeedEnabled;
    return true;
  };

  return (
    <div
      className={cx({
        'home__feed-sections': newUIEnabled,
      })}
    >
      {feedSections.map((feed_section, idx) =>
        display(feed_section.attributes.feed) ? (
          <HomeFeedSection
            item={feed_section}
            className={cx({
              home_row: newUIEnabled,
              home__first: idx === 0,
            })}
            updateSectionContents={updateSectionContents}
            contents={uniqContents[idx]}
            initiativeTags={initiativeTags}
            shouldRefetch={shouldRefetch}
            onRefetch={onRefetch}
            newUIEnabled={newUIEnabled}
            key={idx}
          />
        ) : null
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  inCaseYouMissedItEnabled: programSelectors.getProgramInCaseYouMissedItEnabled(
    state,
    ownProps
  ),
  fromTheCommunityEnabled: programSelectors.getProgramFromTheCommunityEnabled(
    state,
    ownProps
  ),
  forYouFeedEnabled: programSelectors.getForYouFeedEnabled(state, ownProps),
  program: programSelectors.getProgram(state, ownProps),
  newUIEnabled: getFeatureFlag(state, Feature.NEW_UI_ENABLED),
});

export default compose(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(HomeFeeds);
