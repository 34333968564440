import { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';
import { RootPatronState } from '../../common/use-patron-selector';
import { programSelectors } from '../../models/program';
import ProgramService from '../../services/program';
import { Button, Modal, ModalCloseButton, Spinner } from '../ui';
import styles from './styles.module.scss';

type InviteUserModalProps = {
  close: () => void;
} & ReturnType<typeof mapStateToProps>;

const InviteUserModal = ({
  programId,
  programName,
  programBrandName,
  programWallpaper,
  close,
}: InviteUserModalProps) => {
  const [copyValue, copy] = useCopyToClipboard();
  const { t } = useTranslation();
  const copied = Boolean(copyValue);
  const { shareLink, fetching } = useInviteLinkFetcher(programId);

  const headerImageSrc = programWallpaper || '/images/welcome-main.jpg';
  const title = t('dialogs.invite.invite_someone', { program: programName });
  const body = t('dialogs.invite.invite_a_friend');
  const ctaLink = t('dialogs.invite.join_me', {
    program: programName,
    brand: programBrandName,
    url: shareLink,
  });

  const handleCopy = () => {
    copy(ctaLink);
  };

  const copyButtonText = !copied ? t('common.copy') : t('common.copied');

  return (
    <Modal className={styles.inviteUser} onClose={close}>
      <ModalCloseButton onClick={close} />

      <div
        style={{ backgroundImage: `url(${headerImageSrc})` }}
        className={styles.inviteUser__image}
      />

      <header className={styles.inviteUser__header}>
        <h2 className={styles.heading}>{title}</h2>
        <p className={styles.subheading}>{body}</p>
      </header>

      <div className={styles.inviteUser__body}>
        <div className={styles.pill}>
          <Spinner loading={fetching}>
            <div className={styles.textInput}>
              <textarea value={ctaLink} readOnly />
            </div>
            <Button onClick={handleCopy} aria-label={copyButtonText}>
              {copyButtonText}
            </Button>
          </Spinner>
        </div>
      </div>
    </Modal>
  );
};

const useInviteLinkFetcher = (programId: string) => {
  const [shareLink, setShareLink] = useState<string>();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchLink = () => {
      new ProgramService().fetchInviteLink().then(handleFetchSuccess);
    };

    const handleFetchSuccess = (
      res: AxiosResponse<{ invite_link: string }>
    ) => {
      setShareLink(res.data.invite_link);
      setFetching(false);
    };

    fetchLink();
  }, [programId]);

  return { shareLink, fetching };
};

const mapStateToProps = (state: RootPatronState) => ({
  programId: programSelectors.getProgramId(state),
  programName: programSelectors.getProgramName(state),
  programBrandName: programSelectors.getProgramBrandName(state),
  programWallpaper: programSelectors.getProgramWallpaper(state),
});

export const ID = 'Overlays.INVITE_USER_MODAL';

export default connect(mapStateToProps, null)(InviteUserModal);
