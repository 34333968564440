import { FC, PropsWithChildren } from 'react';
import { PreviewCard } from '../common';
import { CardProps } from '../../sidebar';
import { useTranslation } from 'react-i18next';
import useChannelDetails from '../../../../../common/channel/use-channel-details';
import { Spinner } from '../../../../ui';
import { getChannelById } from '../../../../../models/channels/selectors';
import { usePatronSelector } from '../../../../../common/use-patron-selector';
import { localizeDate, timeAgoOrDate } from '../../../../../lib/date-formatter';
import styles from './styles.module.scss';

const TopicStat = ({
  label,
  children,
}: PropsWithChildren<{
  label: string;
}>) =>
  children ? (
    <div className={styles.statItemContainer}>
      <dt className={styles.statItemTitle}>{label}</dt>
      <dd className={styles.statItemBody}>{children}</dd>
    </div>
  ) : null;

const StatsPreview: FC<CardProps> = ({ topicId }: { topicId: string }) => {
  const { t } = useTranslation();

  const topic = usePatronSelector((state) => getChannelById(state, topicId));
  const { fetching, channelStats } = useChannelDetails(topicId);
  return (
    <PreviewCard title={t('screens.topic_page.sidebar.topic_statistics.title')}>
      {fetching ? (
        <Spinner />
      ) : (
        <div className={styles.statContent}>
          <dl className={styles.statList}>
            {topic?.created_at && (
              <TopicStat label={t('channel_details.created')}>
                <time dateTime={topic.created_at}>
                  {localizeDate(topic.created_at)}
                </time>
              </TopicStat>
            )}
            {channelStats?.lastUpdated && (
              <TopicStat label={t('channel_details.last_updated')}>
                <time dateTime={channelStats?.lastUpdated}>
                  {timeAgoOrDate(channelStats.lastUpdated)}
                </time>
              </TopicStat>
            )}
            <TopicStat label={t('channel_details.avg_posts_per_week')}>
              {t('channel_details.number_post', {
                count: channelStats?.postsPerWeek,
              })}
            </TopicStat>
            <TopicStat label={t('channel_details.members')}>
              {t('channel_details.number_joined', {
                count: channelStats?.followerCount,
              })}
            </TopicStat>
            <TopicStat label={t('channel_details.contributors')}>
              {t('channel_details.number_contributor', {
                count: channelStats?.contributorCount,
              })}
            </TopicStat>
          </dl>
        </div>
      )}
    </PreviewCard>
  );
};
export default StatsPreview;
