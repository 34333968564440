import { useState, useEffect } from 'react';
import cx from 'classnames';
import './assistant.scss';
import { ResultItem } from '../../models/assistant/interfaces/ResultItem';

interface AssistantPollResultsProps {
  results: ResultItem[];
  poll_type: string;
}

function AssistantPollResults({
  results,
  poll_type,
}: AssistantPollResultsProps) {
  const [ready, setReady] = useState(false);
  const pollResultsClassNames = cx(['assistant__poll-results', poll_type]);
  const maxPercent = Math.max(...results.map((r) => r.percent));

  useEffect(() => {
    // Delay initial render of the percent-bar width to enable the entry animation
    requestAnimationFrame(() => {
      setReady(true);
    });
  }, []);

  return (
    <ul className={pollResultsClassNames}>
      {poll_type === 'star' || poll_type === 'image'
        ? results.map((result) => (
            <li
              className="assistant__poll-image-result"
              key={result.option_text}
            >
              <div className="percent-result">{result.percent_result}</div>
              <img
                className="option-image"
                src={result.option_image!.url}
                alt={result.option_image!.alt_text}
              />
              <div className="percentage-container">
                <div
                  className="percentage-bar"
                  style={
                    ready
                      ? { height: `${(result.percent / maxPercent) * 100}%` }
                      : undefined
                  }
                />
              </div>
            </li>
          ))
        : results.map((result) => (
            <li className="assistant__poll-result" key={result.option_text}>
              <div className="option-text">{result.option_text}</div>
              <div className="percent-result">{result.percent_result}</div>
              <div
                className="percentage-bar"
                style={ready ? { width: result.percent_result } : undefined}
              ></div>
            </li>
          ))}
    </ul>
  );
}

export default AssistantPollResults;
