import { ContentBlock, ContentState } from 'draft-js';

function processBlock(block: ContentBlock, content: ContentState): string {
  const text = block.getText();
  const result: string[] = [];
  block.findEntityRanges(
    (value) => true,
    (start, end) => {
      const entityKey = block.getEntityAt(start);
      if (entityKey) {
        const entity = content.getEntity(entityKey);
        if (entity.getType() !== 'REFERENCE') {
          result.push(text.slice(start, end));
          return;
        }
        const data = entity ? entity.getData() : {};
        const refType = data.refType;
        const id = data.id;
        const name = data.name;
        result.push(`@{ref:${refType}::id:${id}::name:${name}}`);
      } else {
        result.push(text.slice(start, end));
      }
    }
  );
  return result.join('');
}

function stateToTextWithRefs(content: ContentState): string {
  return content
    .getBlocksAsArray()
    .map((block) => processBlock(block, content))
    .join('\n')
    .trim();
}

export default stateToTextWithRefs;
