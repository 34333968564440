import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
  DecodedValueMap,
  encodeDelimitedArray,
  decodeDelimitedArray,
  SetQuery,
  ArrayParam,
} from 'use-query-params';
import { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { Filter } from './filter-bar/filters/filters';
import { useFilters } from './filter-context';
import { useSearchRoute } from './search-screen';

export const CommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

export const searchQueryParams: Record<string, any> = {
  query: StringParam,
  sort: withDefault(StringParam, 'relevance'),
  page: withDefault(NumberParam, 1),
  perPage: withDefault(NumberParam, 20),
};

type UseSearchQueryParamsProps = {
  // If true, the hook will stop watching for query string changes
  disabled?: boolean;
};

type UseSearchQueryParamsResult = [
  SearchParams,
  SetQuery<typeof searchQueryParams & ReturnType<typeof filtersToQueryParams>>
];

/**
 * Hook to manage search feature query params.
 */
export function useSearchQueryParams({
  disabled,
}: UseSearchQueryParamsProps): UseSearchQueryParamsResult {
  const { searchType } = useSearchRoute();
  const { filters, isLoading } = useFilters();
  // We need to make sure the query params parsing is defined dynamically since it depends on the response from the server's metadata
  // The meta will include info on what filter behaviours are available for the search type which may change the type of query params ( array or string )
  const filterParams =
    !isLoading && filters ? filtersToQueryParams(filters) : {};
  const combinedParams: Record<
    string,
    typeof StringParam | typeof NumberParam | typeof ArrayParam
  > = { ...searchQueryParams, ...filterParams };

  const [liveQueryParams, setLiveQueryParams] = useQueryParams(combinedParams);
  const [queryParams, setQueryParams] =
    useState<typeof liveQueryParams>(liveQueryParams);

  if (!disabled && !isEqual(liveQueryParams, queryParams)) {
    setQueryParams(liveQueryParams);
  }

  const isFirstRender = useRef(true);

  // Reset the query params when the search type changes but not on first render
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setLiveQueryParams((prev) => {
      return {
        query: prev.query,
      };
    }, 'replace');
  }, [searchType, setLiveQueryParams]);

  return [queryParams, setLiveQueryParams];
}

export function useDateRangeQueryParams() {
  return useQueryParams({
    ...searchQueryParams,
    to: StringParam,
    from: StringParam,
  });
}

export type SearchParams = DecodedValueMap<
  typeof searchQueryParams & ReturnType<typeof filtersToQueryParams>
>;

/**
 * Convert a search params object to a query string
 */
export function stringify(params: SearchParams) {
  const query = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value && Array.isArray(value)) {
      value.forEach((v) => {
        query.append(key, v);
      });
    } else if (value) {
      query.set(key, String(value));
    }
  }
  return query.toString();
}

function filtersToQueryParams(
  filters: Filter[] | undefined
): Record<string, typeof StringParam | typeof ArrayParam> {
  const query: Record<string, typeof StringParam | typeof ArrayParam> = {};

  filters?.forEach((filter) => {
    if (
      filter.behaviour === 'selectable' ||
      filter.behaviour === 'searchable'
    ) {
      query[filter.parameter] = ArrayParam;
    } else if (filter.behaviour === 'listable') {
      query[filter.parameter] = StringParam;
    } else if (filter.behaviour === 'daterangeable') {
      query[filter.parameters[0]] = StringParam;
      query[filter.parameters[1]] = StringParam;
    }
  });

  return query;
}
