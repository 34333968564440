import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  submissionIsCommentable,
  submissionIsShareable,
  submissionIsTranslatable,
  submissionType,
  submissionIsSubmitting,
} from '../../models/content-submission/atoms';

import { Chat, Icon, Toggle } from '../../components/ui';

import { advocateSelectors } from '../../models/advocate';

const Settings = ({ canShare, canComment, canTranslate }) => {
  const [isCommentable, setIsCommentable] = useRecoilState(
    submissionIsCommentable
  );
  const [isShareable, setIsShareable] = useRecoilState(submissionIsShareable);
  const [isTranslatable, setIsTranslatable] = useRecoilState(
    submissionIsTranslatable
  );
  const contentType = useRecoilValue(submissionType);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();

  canShare = canShare && contentType !== 'note';

  return (
    <div className="content-submission-options">
      {canComment ? (
        <Toggle
          label={
            <>
              <Chat aria-hidden /> {t('content_submission.commentable')}
            </>
          }
          name="commentable"
          checked={isCommentable}
          disabled={isSubmitting}
          onChange={() => setIsCommentable(!isCommentable)}
        />
      ) : null}

      {canShare ? (
        <Toggle
          label={
            <>
              <Icon type="share" aria-hidden />{' '}
              {t('content_submission.shareable')}
            </>
          }
          name="shareable"
          checked={isShareable}
          disabled={isSubmitting}
          onChange={() => setIsShareable(!isShareable)}
        />
      ) : null}

      {canTranslate ? (
        <Toggle
          label={
            <>
              <Icon type="translate" aria-hidden />{' '}
              {t('content_submission.translatable')}
            </>
          }
          name="translatable"
          checked={isTranslatable}
          disabled={isSubmitting}
          onChange={() => setIsTranslatable(!isTranslatable)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  canShare: advocateSelectors.getCanShareSubmission(state, ownProps),
  canComment: advocateSelectors.getCanCommentSubmission(state, ownProps),
  canTranslate: advocateSelectors.getCanTranslateSubmission(state, ownProps),
});

export default connect(mapStateToProps, null)(Settings);
