export const ZendeskIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.034 10.2199H12.0325C10.121 10.2199 8.57095 11.7699 8.57095 13.6814V13.6834H15.498C15.498 11.7709 13.9475 10.2204 12.035 10.2204L12.034 10.2199ZM7.42895 5.32141L0.501953 13.6834H7.42895V5.32141ZM0.501953 2.31791C0.501953 4.23091 2.05245 5.78141 3.96545 5.78141C5.87845 5.78141 7.42895 4.23091 7.42895 2.31791H0.501953ZM8.57095 2.31641V10.6804L15.498 2.31741H8.57095V2.31641Z"
      fill="black"
    />
  </svg>
);
export const ServiceNowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3373_34220)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.04883 0.827881C6.44005 0.831455 4.86909 1.31598 3.53779 2.21921C2.2065 3.12244 1.17568 4.4031 0.577782 5.89665C-0.020112 7.3902 -0.157767 9.02841 0.182504 10.6008C0.522775 12.1732 1.32543 13.6079 2.48733 14.7206C2.76189 14.9827 3.12133 15.1377 3.5004 15.1574C3.87947 15.1772 4.25305 15.0603 4.55333 14.8281C5.54568 14.0921 6.74844 13.6947 7.98396 13.6947C9.21947 13.6947 10.4222 14.0921 11.4146 14.8281C11.7176 15.068 12.0975 15.1893 12.4835 15.1695C12.8694 15.1498 13.2349 14.9902 13.5118 14.7206C14.668 13.6138 15.4687 12.188 15.8121 10.6247C16.1556 9.06142 16.0262 7.43131 15.4404 5.94177C14.8547 4.45224 13.8391 3.17061 12.5229 2.25996C11.2066 1.34932 9.64923 0.850813 8.04883 0.827881ZM8.00383 12.8966C7.47179 12.9139 6.94183 12.8222 6.44647 12.6273C5.95111 12.4324 5.50078 12.1384 5.12312 11.7632C4.74546 11.3881 4.44843 10.9397 4.25024 10.4457C4.05205 9.95161 3.95688 9.42228 3.97058 8.89013C3.95256 8.35239 4.04292 7.81653 4.23626 7.31443C4.42961 6.81233 4.722 6.35426 5.09602 5.96749C5.47005 5.58072 5.91807 5.27316 6.41341 5.0631C6.90875 4.85305 7.44129 4.7448 7.97933 4.7448C8.51737 4.7448 9.04991 4.85305 9.54525 5.0631C10.0406 5.27316 10.4886 5.58072 10.8626 5.96749C11.2367 6.35426 11.5291 6.81233 11.7224 7.31443C11.9157 7.81653 12.0061 8.35239 11.9881 8.89013C12.0023 9.42013 11.9083 9.94747 11.712 10.44C11.5157 10.9325 11.2211 11.3798 10.8462 11.7547C10.4713 12.1296 10.0239 12.4242 9.53142 12.6205C9.03892 12.8169 8.51158 12.9108 7.98158 12.8966"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3373_34220">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const MicrosoftIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.996094 0.955566H7.64703V7.60588H0.996094V0.955566Z"
      fill="black"
    />
    <path
      d="M8.33984 0.955566H14.9902V7.60588H8.33984V0.955566Z"
      fill="black"
    />
    <path
      d="M0.996094 8.30029H7.64703V14.9503H0.996094V8.30029Z"
      fill="black"
    />
    <path d="M8.33984 8.30029H14.9902V14.9503H8.33984V8.30029Z" fill="black" />
  </svg>
);
export const GoogleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7863 6.92125L14.7156 6.62156H8.20187V9.37844H12.0938C11.6897 11.2972 9.81469 12.3072 8.28312 12.3072C7.16875 12.3072 5.99406 11.8384 5.21656 11.085C4.80635 10.6811 4.47983 10.2003 4.25568 9.6701C4.03154 9.13987 3.91418 8.57064 3.91031 7.995C3.91031 6.83375 4.43219 5.67219 5.19156 4.90812C5.95094 4.14406 7.09781 3.71656 8.23812 3.71656C9.54406 3.71656 10.48 4.41 10.83 4.72625L12.7891 2.7775C12.2144 2.2725 10.6356 1 8.175 1C6.27656 1 4.45625 1.72719 3.12562 3.05344C1.8125 4.35938 1.13281 6.24781 1.13281 8C1.13281 9.75219 1.77594 11.5463 3.04844 12.8625C4.40812 14.2663 6.33375 15 8.31656 15C10.1206 15 11.8306 14.2931 13.0494 13.0106C14.2475 11.7481 14.8672 10.0013 14.8672 8.17C14.8672 7.39906 14.7897 6.94125 14.7863 6.92125Z"
      fill="black"
    />
  </svg>
);
export const DropboxIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3373_34263)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00959 9.79756L4.66 12.5972L3.2 11.655V12.7112L8 15.5999L12.8 12.7112V11.655L11.3496 12.5972L8.00959 9.79756ZM16 3.53857L11.2936 0.399902L8 3.20732L12.7456 6.20068L16 3.53857ZM8 9.19365L11.2936 12.0015L16 8.86279L12.7456 6.20068L8 9.19365ZM0 8.86279L4.7064 12.0015L8 9.19365L3.25439 6.20068L0 8.86279ZM8 3.20732L3.25439 6.20068L0 3.53857L4.7064 0.399902L8 3.20732Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3373_34263">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ConfluenceIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3373_34132)">
      <path
        d="M0.578784 12.0207C0.413712 12.2899 0.228324 12.6023 0.0708712 12.8511C-0.0700615 13.0893 0.00559679 13.3964 0.241022 13.5419L3.54246 15.5735C3.65834 15.6451 3.79811 15.6671 3.93038 15.6346C4.06265 15.6022 4.17633 15.5179 4.24591 15.4009C4.37797 15.1799 4.54812 14.8929 4.73351 14.5857C6.04139 12.427 7.35688 12.6911 9.72883 13.8238L13.0023 15.3805C13.1263 15.4395 13.2689 15.4458 13.3976 15.398C13.5263 15.3502 13.6301 15.2522 13.6855 15.1266L15.2575 11.5712C15.3685 11.3173 15.2555 11.0212 15.0035 10.9058C14.3127 10.5808 12.9388 9.93317 11.7021 9.33637C7.25276 7.17521 3.47135 7.31488 0.578784 12.0207Z"
        fill="black"
      />
      <path
        d="M15.3819 3.952C15.547 3.6828 15.7324 3.37044 15.8898 3.12156C16.0308 2.8834 15.9551 2.57625 15.7197 2.4308L12.4182 0.399146C12.3014 0.320455 12.1571 0.293818 12.0199 0.325597C11.8827 0.357377 11.7648 0.444719 11.6945 0.566757C11.5624 0.787699 11.3923 1.07467 11.2069 1.38196C9.899 3.54059 8.5835 3.27647 6.21155 2.14383L2.94821 0.594692C2.82423 0.535682 2.68164 0.529381 2.55294 0.577225C2.42424 0.625069 2.32039 0.722982 2.26506 0.848649L0.693074 4.40404C0.58201 4.65797 0.695019 4.95405 0.94703 5.0694C1.63779 5.39447 3.0117 6.04206 4.24846 6.63886C8.70794 8.79749 12.4894 8.65273 15.3819 3.952Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3373_34132">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const BoxIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3373_34038)">
      <path
        d="M13.3477 16H2.65225C1.18744 16 0 14.8126 0 13.3477V2.65225C0 1.18744 1.18744 0 2.65225 0H13.3477C14.8126 0 16 1.18744 16 2.65225V13.3477C16 14.8126 14.8126 16 13.3477 16Z"
        fill="black"
      />
      <path
        d="M8.18848 6.7366C7.35605 6.7366 6.63167 7.20485 6.26686 7.89229C5.90192 7.20485 5.17792 6.7366 4.34492 6.7366C3.87403 6.7361 3.41576 6.8888 3.0393 7.17166V5.33897C3.0372 5.22504 2.99042 5.1165 2.90903 5.03674C2.82765 4.95698 2.71818 4.9124 2.60423 4.9126C2.36661 4.9126 2.17536 5.10216 2.16992 5.33897V8.94785H2.17036C2.18955 10.1328 3.15517 11.0873 4.34492 11.0873C5.17792 11.0873 5.90192 10.6187 6.26686 9.93191C6.63167 10.6187 7.35605 11.0873 8.18848 11.0873C9.38948 11.0873 10.3637 10.1135 10.3637 8.91154C10.3637 7.71022 9.38948 6.7366 8.18848 6.7366ZM4.34492 10.2164C3.62423 10.2164 3.0393 9.6326 3.0393 8.91185C3.0393 8.19135 3.62423 7.60747 4.34492 7.60747C5.06555 7.60747 5.64923 8.19135 5.64923 8.91154C5.64923 9.63241 5.06555 10.2164 4.34492 10.2164ZM8.18848 10.2164C7.46761 10.2164 6.88311 9.63241 6.88311 8.91154C6.88311 8.19135 7.46761 7.60747 8.18848 7.60747C8.90905 7.60747 9.49323 8.19135 9.49323 8.91154C9.49323 9.63241 8.90905 10.2164 8.18848 10.2164Z"
        fill="white"
      />
      <path
        d="M13.7385 10.3591L12.5561 8.90874L13.7399 7.45562C13.8896 7.26393 13.8467 6.99624 13.6401 6.85405C13.4332 6.71062 13.1429 6.74737 12.9829 6.93337V6.93312L11.9644 8.18136L10.9468 6.93312V6.93337C10.7884 6.74737 10.4963 6.71062 10.2901 6.85405C10.0838 6.99637 10.0407 7.26393 10.1911 7.45562H10.1907L11.3724 8.90874L10.1907 10.3591H10.1911C10.0407 10.5514 10.0838 10.8182 10.2901 10.961C10.4963 11.1038 10.7884 11.0674 10.9468 10.8812L11.9644 9.63468L12.9814 10.8812C13.1417 11.0674 13.4318 11.1038 13.6387 10.961C13.8454 10.8182 13.8885 10.5514 13.7385 10.3591Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3373_34038">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EnterpriseIcons: { [key: string]: JSX.Element } = {
  google_drive_search: <GoogleIcon />,
  servicenow_search: <ServiceNowIcon />,
  box_search: <BoxIcon />,
  microsoft365_search: <MicrosoftIcon />,
  zendesk_search: <ZendeskIcon />,
  dropbox_search: <DropboxIcon />,
  confluence_search: <ConfluenceIcon />,
};
