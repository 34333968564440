import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { connect, useSelector } from 'react-redux';
import { Icon } from '../ui';
import { trackSearchClick } from '../../models/analytics';
import { programSelectors } from '../../models/program';
import ProgramService from '../../services/program';
import AssistantService from '../../services/assistant';
import { isInMSTeams } from '../../lib/teams-helper';
import './search-bar.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import useFeatureFlag from '../../common/use-feature-flag';
import SearchBarTerms from '../v2/search/search-bar-terms/search-bar-terms';
import useSWR from 'swr';
import { searchTypes } from '../v2/search/search-screen';
import { uiSelectors } from '../../models/ui';
import queryString from 'query-string';

const SearchBarStandard = React.forwardRef(
  (
    { searchTerm, handleKeyDown, handleChange, handleClick, handleSearch },
    ref
  ) => {
    const { t } = useTranslation();
    const newTopicsString = useSelector((state) =>
      getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
    );

    const searchLabelTranslationKey = newTopicsString
      ? 'discover.search_label_topics'
      : 'discover.search_label';

    return (
      <div className="search-dropdown" ref={ref}>
        <div className="search-bar">
          <input
            type="text"
            value={searchTerm}
            aria-label={t(searchLabelTranslationKey)}
            placeholder={t(searchLabelTranslationKey)}
            className="search-bar__search-input"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          <button
            className="search-bar__search-button"
            onClick={handleSearch}
            aria-label={t('nav_links.search')}
          >
            <Icon>search</Icon>
          </button>
        </div>
      </div>
    );
  }
);

SearchBarStandard.displayName = 'SearchBarStandard';

const SearchBarSearchHeader = React.forwardRef(
  (
    {
      searchTerm,
      handleKeyDown,
      handleChange,
      handleClick,
      handleSearch,
      handleClose,
      handleSearchTermClick,
      handleSearchTermKeyDown,
      showSearchTerms,
      handleSetShowSearchTerms,
      previousSearches,
      trendingSearches,
      isMinimized,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const improvedSearchExperience = useFeatureFlag(Feature.IMPROVED_SEARCH);
    const tabIndexProps = isMinimized ? { tabIndex: -1 } : {};

    const buttonClasses = cx(
      'search-bar__search-button',
      'search_bar_in_header'
    );

    const improvedSearchButtonClasses = cx(
      'search-bar__improved-search-button',
      'search_bar_in_header'
    );

    const closeButtonClass = improvedSearchExperience
      ? cx('search-bar__improved-close-button')
      : cx('search-bar__close-button');

    const dropdownClasses = cx('search-dropdown', 'search_bar_in_header');

    const newTopicsString = useSelector((state) =>
      getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
    );

    // Don't display an empty container if there are no searches to show within the container
    const searchesToDisplay =
      (trendingSearches &&
        trendingSearches.trending_searches &&
        trendingSearches.trending_searches.length > 0) ||
      (previousSearches && previousSearches.length > 0);

    const msTeamsStyleOverrides = isInMSTeams
      ? {
          marginLeft: 0,
          paddingLeft: 0,
          maxWidth: '600px',
        }
      : {};

    const searchLabelTranslationKey = improvedSearchExperience
      ? 'discover.improved_search_label'
      : newTopicsString
      ? 'discover.search_label_topics'
      : 'discover.search_label';

    return (
      <div
        className={dropdownClasses}
        ref={ref}
        style={{ flex: 1, paddingLeft: '1.5rem', ...msTeamsStyleOverrides }}
      >
        <div className="search-bar">
          {!improvedSearchExperience && (
            <button
              className={buttonClasses}
              onClick={handleSearch}
              {...tabIndexProps}
            >
              <Icon>search</Icon>
            </button>
          )}
          <input
            type="text"
            value={searchTerm}
            aria-label={t(searchLabelTranslationKey)}
            placeholder={t(searchLabelTranslationKey)}
            className="search-bar__search-input"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            {...tabIndexProps}
          />
          {handleClose ? (
            <button className={closeButtonClass} onClick={handleClose}>
              <Icon>close</Icon>
            </button>
          ) : null}
          {improvedSearchExperience && (
            <button
              className={improvedSearchButtonClasses}
              onClick={handleSearch}
            >
              <Icon>search</Icon>
            </button>
          )}
          {showSearchTerms && searchesToDisplay && improvedSearchExperience && (
            <SearchBarTerms
              previousSearchTerms={previousSearches}
              trendingSearchTerms={trendingSearches}
              handleClick={handleSearchTermClick}
              handleKeyDown={handleSearchTermKeyDown}
              setShowSearchTerms={handleSetShowSearchTerms}
            />
          )}
        </div>
      </div>
    );
  }
);

SearchBarSearchHeader.displayName = 'SearchBarSearchHeader';

const SearchBar = ({
  term,
  baseUrl,
  searchBarInHeaderEnabled,
  handleClose,
  isMinimized,
}) => {
  // search_bar_in_header
  const [searchTerm, setSearchTerm] = useState(term || '');
  const [showSearchTerms, setShowSearchTerms] = useState(false);
  const [searchTermClicked, setSearchTermClicked] = useState(false);
  const searchDropdown = useRef();
  const isDropdownTermsMounted = useRef(false);
  const improvedSearchExperience = useFeatureFlag(Feature.IMPROVED_SEARCH);
  const history = useHistory();
  const { pathname, search: locationSearch } = useLocation();
  const programTrendingSearches = useTrendingSearchesFetcher(
    improvedSearchExperience
  );
  useEffect(() => {
    if (!improvedSearchExperience) {
      return;
    }
    const queryObject = queryString.parse(location.search);
    setSearchTerm(queryObject.query || '');
  }, [improvedSearchExperience, locationSearch]);
  const { previousSearches, mutate: refetchPreviousSearches } =
    usePreviousSearchesFetcher(improvedSearchExperience);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
    if (improvedSearchExperience) {
      // Want to account for removing value to zero to trigger search terms dropdown but avoiding any unexpected behaviour
      setSearchTerm(e.currentTarget.value);
      handleShowSearchTerms();
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    handleShowSearchTerms();
  };

  const handleClick = () => {
    trackSearchClick();
    handleShowSearchTerms();
  };

  const handleSearch = () => {
    const safeTerm = searchTerm.replace(/[%}{}]/g, '').trim();
    const encodedTerm = encodeURIComponent(safeTerm);
    if (!encodedTerm) {
      return;
    }

    if (improvedSearchExperience) {
      // add search term to `query=` in the URL
      const match = pathname.match(
        new RegExp(`/search/(${searchTypes.join('|')})(/.*)?`)
      );
      let baseUrl = '';

      if (match) {
        baseUrl = match[0];
      } else {
        baseUrl = '/search/overview';
      }

      history.push(`${baseUrl}?query=${encodedTerm}`);
      return;
    }

    history.push(`${baseUrl}/${encodedTerm}`);
  };

  const handleShowSearchTerms = async () => {
    if (improvedSearchExperience && searchTerm.length == 0) {
      await refetchPreviousSearches();
      setShowSearchTerms(true);
    } else {
      setShowSearchTerms(false);
    }
  };

  const handleSearchTermClick = (searchTerm) => {
    setSearchTerm(searchTerm);
    setSearchTermClicked(true);
  };

  const handleSearchTermKeyDown = (e, searchTerm) => {
    if (e.key === 'Enter') {
      setSearchTerm(searchTerm);
      setSearchTermClicked(true);
    }
  };

  const handleSetShowSearchTerms = (value) => {
    //Need to handle the case where the user clicks outside of the search bar independently of the search length check
    setShowSearchTerms(value);
  };

  useEffect(() => {
    if (!isDropdownTermsMounted.current) {
      isDropdownTermsMounted.current = true;
      return;
    }

    if (searchTerm !== '' && searchTermClicked) {
      const selectedSearchTerm = searchTerm;
      handleSearch();
      setSearchTerm(selectedSearchTerm); //Set the set term back post encoding in handleSearch()
      setShowSearchTerms(false);
      setSearchTermClicked(false);
    }
  }, [searchTerm]);

  const EnabledSearchBar = searchBarInHeaderEnabled
    ? SearchBarSearchHeader
    : SearchBarStandard;

  return (
    <>
      <EnabledSearchBar
        ref={searchDropdown}
        searchTerm={searchTerm}
        handleKeyDown={handleKeyDown}
        handleChange={handleChange}
        handleClick={handleClick}
        handleSearch={handleSearch}
        handleClose={handleClose}
        handleSearchTermClick={handleSearchTermClick}
        handleSearchTermKeyDown={handleSearchTermKeyDown}
        showSearchTerms={showSearchTerms}
        handleSetShowSearchTerms={handleSetShowSearchTerms}
        previousSearches={previousSearches}
        trendingSearches={programTrendingSearches.trendingSearches}
        isMinimized={isMinimized}
      />
    </>
  );
};

export const useTrendingSearchesFetcher = (improvedSearchExperience) => {
  const programService = new ProgramService();
  const { data, ...rest } = useSWR(
    improvedSearchExperience ? 'trending_searches' : null,
    async () => {
      const res = await programService.fetchTrendingSearches();
      return res.data;
    }
  );

  return {
    trendingSearches: data || [],
    ...rest,
  };
};

const usePreviousSearchesFetcher = (improvedSearchExperience) => {
  const assistantService = new AssistantService();
  const { data, ...rest } = useSWR(
    improvedSearchExperience ? 'previous_searches' : null,
    async () => {
      const data = await assistantService.fetchUserPreviousSearches();
      const buttons = data[0].subject.buttons;
      const previousSearches = buttons.map((button, index) => ({
        id: index,
        text: button.button_text,
      }));
      return previousSearches;
    }
  );

  return {
    previousSearches: data || [],
    ...rest,
  };
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
  isMinimized: uiSelectors.getMinimizeHeader(state),
});

export default connect(mapStateToProps, null)(SearchBar);
