import { atom } from 'recoil';

//home page is global state mainly to implement persistence: leaving an re-visiting the home page without a hard refresh should not trigger a reload.
//(or at least the app should have control over when it reloads the home page instead of on every mount)

export const homeLinks = atom({
  key: 'home-page_homeLinks',
  default: [],
});

export const homeLinksLoaded = atom({
  key: 'home-page_homeLinksLoaded',
  default: false,
});

export const homeFeedSections = atom({
  key: 'home-page_homeFeedSections',
  default: [],
});

export const homeFeedSectionsLoaded = atom({
  key: 'home-page_homeFeedSectionsLoaded',
  default: false,
});

export const homePageVisitAnalyticsSent = atom({
  key: 'home-page_homePageVisitAnalyticsSent',
  default: false,
});

export const shortcutHeaderOpen = atom({
  key: 'home-page_shortcutHeaderOpen',
  default: false,
});
