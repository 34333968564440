import { useEffect, useState } from 'react';
import { Feature } from '../../models/features/features';
import { FeatureFlagsService } from '../../services/feature-flags';
import { useTranslation } from 'react-i18next';

const fetchFeatureFlags = (programId: string) => {
  return new FeatureFlagsService(programId).fetch();
};

type UseNativeRedirectMessageResult = {
  heading: string;
  body: string;
  hideButtons: boolean;
};

export function useNativeRedirectMessage(
  programId: string
): UseNativeRedirectMessageResult {
  const { t } = useTranslation();

  const [
    dynamicMigrationCustomizedMessageEnabled,
    setDynamicMigrationCustomizedMessageEnabled,
  ] = useState(false);

  useEffect(() => {
    if (!programId) return;

    fetchFeatureFlags(programId).then((flags) => {
      setDynamicMigrationCustomizedMessageEnabled(
        flags[Feature.CUSTOMIZED_DAVEY_MESSAGE]
      );
    });
  }, [programId]);

  const heading = dynamicMigrationCustomizedMessageEnabled
    ? t('screens.native_redirect.dynamic_migration.heading')
    : t('screens.native_redirect.heading');

  const body = dynamicMigrationCustomizedMessageEnabled
    ? t('screens.native_redirect.dynamic_migration.body')
    : t('screens.native_redirect.body');

  return {
    heading,
    body,
    hideButtons: dynamicMigrationCustomizedMessageEnabled,
  };
}
