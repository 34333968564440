import { useRef, useCallback, useEffect } from 'react';

const useOutsideClick = ({ disabled, onClick }) => {
  const ref = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (ref.current && ref.current.contains(e.target)) return;

      onClick(e);
    },
    [onClick, ref]
  );

  useEffect(() => {
    if (disabled) {
      document.removeEventListener('click', handleClick);
    } else {
      document.addEventListener('click', handleClick);
    }

    return () => document.removeEventListener('click', handleClick);
  }, [disabled, handleClick]);

  return ref;
};

export default useOutsideClick;
