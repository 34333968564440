import cx from 'classnames';
import ChannelCard from '../../components/channel-card';
import './channel-list.scss';

const renderChannelCard = ({ channelId, direction, analyticsData }) => (
  <ChannelCard
    channelId={channelId}
    orientation={direction === 'vertical' ? 'landscape' : 'portrait'}
    size={direction === 'vertical' ? 'large' : 'small'}
    analyticsData={analyticsData}
    key={channelId}
  />
);

const ChannelList = ({
  channelIds,
  direction,
  analyticsData,
  render = renderChannelCard,
}) => {
  const className = cx('channel-list', {
    [`channel-list--${direction}`]: direction,
  });

  return (
    <div className={className}>
      {channelIds.map((channelId) =>
        render({ channelId, direction, analyticsData })
      )}
    </div>
  );
};

export default ChannelList;
