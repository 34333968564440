import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useHistory } from 'react-router-dom';
import {
  UserSuggestion,
  useUserSuggestions,
} from '../../../../common/use-user-suggestions';
import Avatar from '../../avatar';
import Link from '../../link';
import Icon from '../../icon';
import TagPluginStore from './TagPluginStore';
import style from './styles.module.scss';

interface EntryProps {
  isActive: boolean;
  index: number;
  data: UserSuggestion;
  onClick: (userId: string, label: string) => void;
  onHover: (index: number) => void;
}

function Entry({ isActive, data, onClick, onHover, index }: EntryProps) {
  const history = useHistory();
  const profileUrl = history.createHref({ pathname: `/profiles/${data.id}` });
  const entryRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    //If not visible scroll into view
    if (isActive) {
      entryRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [isActive]);
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(data.id, data.name);
    },
    [onClick, data.id, data.name]
  );
  const handleHover = useCallback(() => {
    onHover(index);
  }, [onHover, index]);

  return (
    <div ref={entryRef} className={isActive ? style.entryActive : style.entry}>
      <div
        className={style.entryLink}
        onClick={handleClick}
        onFocus={handleHover}
        onMouseOver={handleHover}
        tabIndex={index * 2}
        role="button"
      >
        <div className={style.avatarContainer}>
          <Avatar
            src={data.avatar.url ?? undefined}
            bgColor={data.avatar.color}
            alt={data.name}
            id={data.id}
          />
        </div>
        <div className={style.entryText}>
          <div className={style.name}>{data.name}</div>
          {data.display_attributes.length > 0 && (
            <div className={style.details}>
              {data.display_attributes.join(' \u2022 ')}
            </div>
          )}
        </div>
      </div>
      <Link
        href={profileUrl}
        target="_blank"
        className={style.profileLink}
        tabIndex={index * 2 + 1}
      >
        <Icon type="open_in_new" size="xl" />
      </Link>
    </div>
  );
}

export interface InternalSuggestionPortalProps {
  store: TagPluginStore;
  searchText: string;
  triggerElement: HTMLSpanElement | null;
  onSelect: (userId: string, label: string) => void;
}

function InternalSuggestionPortal({
  store,
  searchText,
  triggerElement,
  onSelect,
}: InternalSuggestionPortalProps) {
  const [hidden, setHidden] = useState(!store.isEditorFocused);
  const [container, setContainer] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(triggerElement, container, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  const suggestions = useUserSuggestions(searchText);
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    setActiveIndex(0);
  }, [suggestions]);

  // When editor loses focus, hide the suggestions, but keep it in DOM to not lose state
  useEffect(() => {
    return store.on('editorFocus', (hasFocus) => {
      setHidden(!hasFocus);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return store.on('keyPress', (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setActiveIndex((i) => (i + 1) % suggestions.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setActiveIndex(
            (i) => (i - 1 + suggestions.length) % suggestions.length
          );
          break;
        case 'Tab':
        case 'Enter':
          e.preventDefault();
          if (suggestions.length > 0 && activeIndex >= 0) {
            const suggestion = suggestions[activeIndex];
            onSelect(suggestion.id, suggestion.name);
          } else {
            store.exitTagging();
          }
          break;
      }
    });
  }, [store, suggestions, activeIndex, onSelect]);

  if (!searchText) {
    return null;
  }

  return (
    <div
      ref={setContainer}
      className={cx({
        [style.hidden]: !suggestions.length || hidden,
        [style.dropdown]: true,
      })}
      style={styles.popper}
      {...attributes.popper}
    >
      {suggestions.map((s, index) => (
        <Entry
          key={s.id}
          data={s}
          index={index}
          isActive={index === activeIndex}
          onClick={onSelect}
          onHover={setActiveIndex}
        />
      ))}
    </div>
  );
}

export default InternalSuggestionPortal;
