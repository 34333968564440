import { FC } from 'react';
import { CardLink, PreviewCard } from '../common';
import { CardProps } from '../../sidebar';
import { useTranslation } from 'react-i18next';
import useTopicAboutPageFetcher from '../../../../../common/use-topic-about-page-fetcher';
import { Spinner } from '../../../../ui';
import styles from './styles.module.css';

const AboutPreview: FC<CardProps> = ({ topicId }: { topicId: string }) => {
  const { t } = useTranslation();

  const { aboutPreview, isFetching, isError } = useTopicAboutPageFetcher({
    topicId: topicId,
  });
  const errorMessage = t('screens.topics.about.error');

  return (
    <PreviewCard
      title={t('screens.topic_page.sidebar.about_preview.title')}
      link={
        <CardLink
          label={t('screens.topic_page.sidebar.about_preview.view_all')}
          to={`/channels/${topicId}/about`}
        />
      }
    >
      {isError && !isFetching && <div>{errorMessage}</div>}
      {isFetching && <Spinner />}
      {aboutPreview && (
        <span className={styles.aboutPreviewContent}>{aboutPreview}</span>
      )}
    </PreviewCard>
  );
};
export default AboutPreview;
