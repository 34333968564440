import { useCallback, useEffect, useRef } from 'react';

/**
 * This idea for this hook was taken from the `useOutsideClick` hook.
 *
 * This hook listens for clicks outside of the ref element (the menu wrapper) and clicks
 * on anchor elements within the ref element. Technically, it listens to if a click happens
 * on any element that is or is _within_ an anchor. If we only listened to exact anchor clicks,
 * then a <span> clicked inside of an anchor would not trigger the onClick event.
 *
 * This lets us handle closing the menu when a user clicks outside of the menu or on an anchor
 * element. Admittedly, it's a _bit_ of a hack because we want to listen to navigation events,
 * but the 'navigate' event is not widely supported yet:
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigation/navigate_event#browser_compatibility
 */
const useMenuClick = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: (event: MouseEvent) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return;

      const target = event.target as HTMLElement;
      const outsideClicked = !ref.current.contains(target);
      const containedAnchor = ref.current.contains(target?.closest('a'));
      if (containedAnchor || outsideClicked) {
        onClick(event);
      }
    },
    [onClick, ref]
  );

  useEffect(() => {
    if (disabled) {
      document.removeEventListener('click', handleClick);
    } else {
      document.addEventListener('click', handleClick);
    }

    return () => document.removeEventListener('click', handleClick);
  }, [disabled, handleClick]);

  return ref;
};

export default useMenuClick;
