import {
  Button,
  Icon,
  IconButton,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePatronSelector } from '../../../../../../common/use-patron-selector';
import { contentSelectors } from '../../../../../../models/content';
import { trackContentCardShare } from '../../../../../../models/content/analytics';
import { uiOperations } from '../../../../../../models/ui';
import { ID as ContentCardShareModalID } from '../../../../content-card/content-card-share-modal/content-card-share-modal';
import styles from './styles.module.scss';
import { FeedCardActionsProps } from './type';

export function FeedCardActionShare({
  content,
  analyticsData,
  isHidden,
}: FeedCardActionsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShared = usePatronSelector((state) =>
    contentSelectors.getContentShared(state, {
      contentId: content.id,
    })
  );

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();

    dispatch(
      uiOperations.addOverlay(ContentCardShareModalID, {
        shouldSnapToTop: false,
        contentId: content.id,
      })
    );

    trackContentCardShare(content.id, analyticsData);
  };

  const label = t('content.share');

  if (isHidden) {
    return (
      <Button
        variant="text"
        selected={isShared}
        label={label}
        leftIcon={<Icon size={20}>share</Icon>}
        onClick={handleClick}
      />
    );
  }

  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <IconButton
          aria-label={t('content.share_action', { title: content.title })}
          className={classNames({
            [styles.IconFilled]: isShared,
          })}
          iconFilled={isShared}
          iconName="share"
          size="compact"
          onClick={handleClick}
        />
      </TooltipTrigger>
      <TooltipContent description={label} />
    </TooltipRoot>
  );
}
