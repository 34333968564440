import { atom, DefaultValue, selectorFamily } from 'recoil';
import { Message } from './types';

export const messagesState = atom<{
  [key: string]: Message | DefaultValue | null;
}>({
  key: 'messagesState',
  default: {},
});

export const messageById = selectorFamily<Message | null, string>({
  key: 'messageSelector',
  get:
    (messageId: string) =>
    ({ get }) => {
      return (get(messagesState)[messageId] as Message) || null;
    },
  set:
    (messageId: string) =>
    ({ set, get }, message: Message | DefaultValue | null) => {
      if (message === null) return;
      set(messagesState, {
        ...get(messagesState),
        [messageId]: message,
      });
    },
});
