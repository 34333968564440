import axios from 'axios';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  JSXElementConstructor,
  KeyboardEventHandler,
} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';

import { uiOperations } from '../../models/ui';
import { advocateSelectors } from '../../models/advocate';
import { programMembershipSelectors } from '../../models/program-membership';

import { Spinner } from '../ui';
import CommentFormHeader from './comment-form-header';
import CommentFormFooter from './comment-form-footer';

import { ID as NameRequiredDialogID } from '../name-required-dialog';
import { ID as PrivateProfileDialogID } from '../private-profile-dialog';

import { isMention, isLink, elWordPos } from '../../lib/string-utils';
import TextValidator from '../../lib/text-validator';

import { trackCommentEvent } from '../../models/comments/analytics';
import { programSelectors } from '../../models/program';
import { RouteComponentProps } from 'react-router';
import CommentLinkPreview from './comment-link-preview';
import { RootPatronState } from '../../common/use-patron-selector';
import { Comment } from '../../models/comments/types';
import { FlashMessage } from './comment-flash-message';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { saveComment } from '../../services/comment';
import { CommentContext } from './types';

const MAX_COMMENT_LENGTH = 500;

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: CommentContext;
  onError?: (opts: FlashMessage) => void;
  onReset?: () => void;
  onSubmit?: (comment: Comment) => void;
  onFormFocus?: FocusEventHandler;
  onFormBlur?: FocusEventHandler;
};

type CommentFormProps = WithTranslation &
  RouteComponentProps<
    Record<string, string | undefined>,
    Record<string, string | undefined>,
    { focusComments: boolean }
  > &
  StateProps &
  DispatchProps &
  OwnProps;

type MentionText = {
  pos: [number, number];
  value: string;
};

interface CommentFormState {
  commentText: string;
  disabled: boolean;
  submitting: boolean;
  showEmojis: boolean;
  mentionText: MentionText | null;
  link: LinkPreview;
  original: string | null;
}

interface LinkPreview {
  linkText: string | null;
  preview: boolean; // true if showing preview
  preventPreviews: string[];
}

class CommentForm extends React.Component<CommentFormProps, CommentFormState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private textValidator: TextValidator = null as any;
  private formEl: HTMLElement | null = null;
  private textareaEl: HTMLTextAreaElement | null = null;

  state = {
    commentText: '',
    disabled: this.props.disabled ?? false,
    original: null,
    submitting: false,
    showEmojis: false,
    mentionText: null as MentionText | null,
    link: {
      linkText: null,
      preview: false,
      preventPreviews: [],
    },
  };

  componentDidMount() {
    this.textValidator = new TextValidator(
      {
        preventHtml: true,
        preventWhiteSpaceOnly: true,
        minLength: 2,
        maxLength: MAX_COMMENT_LENGTH,
      },
      'Comment'
    );

    this.focusIfNecessary();
  }

  componentDidUpdate(prevProps: CommentFormProps) {
    if (prevProps.disabled) {
      this.focusIfNecessary();
    }

    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled ?? false });
    }

    if (this.props.context.commentText !== prevProps.context.commentText) {
      this.setState({ commentText: this.props.context.commentText || '' });
      this.setState({ original: this.props.context.commentText || null });
    }

    if (['edit', 'reply'].includes(this.props.context.action || '')) {
      this.focusForm();
    }
  }

  componentWillUnmount() {
    this.removeDocumentClickListener();
  }

  render() {
    return (
      <form
        className="comment-form"
        ref={(c) => {
          this.formEl = c;
        }}
        onFocus={this.props.onFormFocus}
        onBlur={this.props.onFormBlur}
      >
        <CommentFormHeader
          context={this.props.context}
          showEmojis={this.state.showEmojis}
          mentionText={this.state.mentionText?.value}
          onEmojiClick={this.handleEmojiClick}
          onMentionClick={this.handleMentionClick}
          onCancelClick={this.handleEditCancel}
        />

        <textarea
          value={this.state.commentText}
          placeholder={this.props.t('comments.write.comment')}
          disabled={this.state.disabled || this.state.submitting}
          aria-disabled={this.state.disabled || this.state.submitting}
          className="comment-form__input"
          onChange={!this.state.submitting ? this.handleChange : undefined}
          onKeyDown={!this.state.submitting ? this.handleKeyDown : undefined}
          ref={(c) => {
            this.textareaEl = c;
          }}
        />

        {this.state.link.preview ? (
          <CommentLinkPreview
            linkText={this.state.link.linkText}
            handleClose={this.preventLinkPreview}
          />
        ) : null}

        <CommentFormFooter
          commentLength={this.state.commentText.length}
          commentLengthMax={MAX_COMMENT_LENGTH}
          disabled={
            this.state.disabled ||
            this.state.submitting ||
            this.state.original === this.state.commentText.trim()
          }
          onEmojiIconClick={this.handleEmojiIconClick}
          onMentionIconClick={this.handleMentionIconClick}
          onSubmit={this.handleSubmit}
        />

        {!this.props.newContentDetail && this.state.submitting ? (
          <Spinner />
        ) : null}
      </form>
    );
  }

  focusIfNecessary = () => {
    if (
      !this.props.disabled &&
      this.props.history.location.state?.focusComments
    ) {
      this.textareaEl?.focus();
    }
  };

  handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const text = e.target.value || '';

    this.updateCommentText(text);
    this.debouncedCheckForMention();
    if (!this.state.link.preview) this.debouncedCheckForLink();
  };

  handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.shiftKey && !this.state.mentionText && e.key === 'Enter') {
      e.preventDefault();

      this.handleSubmit();
    }

    if (e.key === 'Escape') {
      this.setState({
        showEmojis: false,
        mentionText: null,
      });

      this.removeDocumentClickListener();
    }
  };

  handleEmojiIconClick = () => {
    if (this.props.disabled) return false;

    this.showEmojiDrawer();
  };

  handleMentionIconClick = () => {
    if (this.props.disabled) return false;

    this.showMentionDrawer();
  };

  handleSubmit = (e?: SubmitEvent) => {
    e?.preventDefault?.();

    const presubmitDialog =
      this.props.advocateNameMissing && this.props.profileEditNameEnabled
        ? NameRequiredDialogID
        : this.props.advocateIsPrivate && !this.props.advocateHidPrivateWarning
        ? PrivateProfileDialogID
        : null;

    if (presubmitDialog) {
      this.props.addOverlay(presubmitDialog, {
        advocateId: this.props.advocateId,
        continue:
          presubmitDialog === NameRequiredDialogID
            ? this.handleSubmit // run submit again to check profile conditions
            : this.submitComment,
      });

      return;
    }

    this.submitComment();
  };

  handleEmojiClick = (emoji: string) => {
    this.addEmojiToText(emoji);

    this.focusForm();
  };

  handleMentionClick = (mention: string) => {
    this.addMentionToText(mention);

    this.focusForm();
  };

  handleEditCancel = () => {
    this.resetForm();

    this.props.onReset?.();
  };

  handleDocumentClick: EventListener = (e) => {
    if (!this.formEl) return; // For IE11, as click listener still invokes after unmount

    if (!this.formEl.contains(e.target as Node) || e.target === this.textareaEl)
      this.setState({
        showEmojis: false,
        mentionText: null,
      });
  };

  handleError = (e: unknown) => {
    let errorText = '';

    if (axios.isAxiosError(e)) {
      errorText = e.response?.data.message;
    }
    errorText = errorText || 'There was an error submitting';

    this.props.onError?.({ text: errorText, type: 'error' });

    this.setState({ submitting: false });
  };

  updateCommentText = (comment: string) => {
    this.setState({ commentText: comment });
  };

  updateMentionText = (text: MentionText | null) => {
    if (text) {
      this.setState({
        mentionText: text,
        showEmojis: false,
      });

      this.addDocumentClickListener();
    } else {
      this.setState({ mentionText: null });

      this.removeDocumentClickListener();
    }
  };

  setLinkText = (
    text: string | null,
    preview: boolean,
    preventPreviews: string[]
  ) => {
    this.setState({
      link: {
        linkText: text,
        preview: preview,
        preventPreviews: preventPreviews,
      },
    });
  };

  updateLinkText = (text: string) => {
    // prevent update if already previewing a link
    if (text && !this.state.link.preview) {
      this.setLinkText(text, false, this.state.link.preventPreviews);
    }
  };

  preventLinkPreview = () => {
    const newPreviews = this.state.link.linkText
      ? this.state.link.preventPreviews.concat([this.state.link.linkText])
      : this.state.link.preventPreviews;
    this.setLinkText(null, false, newPreviews);
  };

  showLinkPreview = () =>
    this.setLinkText(
      this.state.link.linkText,
      true,
      this.state.link.preventPreviews
    );

  resetLinkPreviews = () => this.setLinkText(null, false, []);

  checkForMention = () => {
    const posArr = this.currentWordPos;
    const text = this.state.commentText.slice(posArr[0], posArr[1]);
    const mention = isMention(text);

    if (mention)
      this.updateMentionText({
        pos: [posArr[0], posArr[1]],
        value: text,
      });

    if (!mention && this.state.mentionText) this.updateMentionText(null);
  };

  checkForLink = () => {
    const posArr = this.currentWordPos;
    const text = this.state.commentText.slice(posArr[0], posArr[1]);
    const link = isLink(text);

    if (link && !this.state.link.preventPreviews.some((el) => el === text))
      this.updateLinkText(text);

    // text no longer a link, not previewing, and link text present then show preview
    if (!link && this.state.link.linkText && !this.state.link.preview)
      this.showLinkPreview();
  };

  showMentionDrawer = () => {
    const currentWord = this.currentWord;
    const commentText =
      currentWord === '' || currentWord === ' '
        ? this.state.commentText + '@'
        : currentWord !== '@'
        ? this.state.commentText + ' @'
        : this.state.commentText.substring(
            0,
            this.state.commentText.length - 2
          );

    this.setState({ commentText }, this.checkForMention);

    this.focusForm();
  };

  showEmojiDrawer = () => {
    this.setState({
      showEmojis: true,
      mentionText: null,
    });

    this.addDocumentClickListener();
  };

  closeEmojiDrawer = () => {
    this.setState({ showEmojis: false });

    this.removeDocumentClickListener();
  };

  addEmojiToText = (emoji: string) => {
    const text = this.state.commentText;
    const emojiEl = document.createElement('div');
    emojiEl.innerHTML = emoji;
    emoji = emojiEl.innerHTML;

    const pos = this.textareaEl?.selectionEnd;
    const emojiText =
      pos !== undefined && text[pos] !== ' ' ? emoji + ' ' : emoji;
    const newText = text.slice(0, pos) + emojiText + text.slice(pos);

    this.updateCommentText(newText);

    this.setState({ showEmojis: false });
  };

  addMentionToText = (mention: string) => {
    const text = this.state.commentText;

    if (this.state.mentionText) {
      const { pos } = this.state.mentionText;

      const mentionText = text[pos[1]] !== ' ' ? mention + ' ' : mention;
      const newText = text.slice(0, pos[0]) + mentionText + text.slice(pos[1]);

      this.updateCommentText(newText);
    }

    this.setState({ mentionText: null });
  };

  commentTextFormatted = () => {
    let result = this.state.commentText;
    this.state.link.preventPreviews.forEach((link) => {
      // Discourse will show markdown formatted links inline and not preview
      result = result.replace(link, `[${link}](${link})`);
    });
    return result;
  };

  submitComment = () => {
    const errors = this.textValidator.validate(this.state.commentText);

    if (errors.length)
      return this.props.onError?.({ text: errors[0], type: 'error' }); // only supports a single error at the moment

    this.setState({ submitting: true });

    saveComment({
      contentId: this.props.context.contentId,
      replyToId: this.props.context.replyToId || undefined,
      body: this.commentTextFormatted(),
    })
      .then(
        (res): Comment => ({
          ...res.data.data.attributes,
          id: res.data.data.id,
          replyToId: this.props.context.replyToId || null,
        })
      )
      .then((comment) => {
        trackCommentEvent('submit', comment, !!comment.replyToId);
        this.notifyNativeApps();

        this.props.onSubmit?.(comment);

        this.resetForm();
      })
      .catch((e) => this.handleError(e));
  };

  notifyNativeApps = () => {
    // Notify iOS app
    if (window?.webkit?.messageHandlers?.commentCreated !== undefined) {
      window.webkit.messageHandlers.commentCreated.postMessage();
    }
    // Notify Android app
    if (window?.commentCreated !== undefined) {
      window.commentCreated.postMessage();
    }
  };

  focusForm = () => {
    this.textareaEl?.focus();
  };

  resetForm = () => {
    this.setState({
      commentText: '',
      mentionText: null,
      showEmojis: false,
      submitting: false,
      link: {
        linkText: null,
        preview: false,
        preventPreviews: [],
      },
    });
  };

  addDocumentClickListener = () => {
    ['click', 'touchend'].forEach((e) => {
      document.addEventListener(e, this.handleDocumentClick);
    });
  };

  removeDocumentClickListener = () => {
    ['click', 'touchend'].forEach((e) => {
      document.removeEventListener(e, this.handleDocumentClick);
    });
  };

  debouncedCheckForMention = debounce(this.checkForMention, 250);

  debouncedCheckForLink = debounce(this.checkForLink, 250);

  get currentWordPos() {
    if (!this.textareaEl) {
      return [0, 0];
    }
    return elWordPos(this.textareaEl);
  }

  get currentWord() {
    if (!this.textareaEl) {
      return [0, 0];
    }
    const posArr = elWordPos(this.textareaEl);
    return this.state.commentText.slice(posArr[0], posArr[1]);
  }
}

const mapStateToProps = (state: RootPatronState) => ({
  advocateId: advocateSelectors.getAdvocateId(state),
  advocateNameMissing: advocateSelectors.getAdvocateNameMissing(state),
  advocateIsPrivate: advocateSelectors.getAdvocateIsPrivate(state),
  advocateHidPrivateWarning:
    programMembershipSelectors.getProgramMembershipHidePrivateWarning(state),
  profileEditNameEnabled: programSelectors.getProfileEditNameEnabled(state),
  newContentDetail: getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW),
});

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
};

export default compose<JSXElementConstructor<OwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(CommentForm);
