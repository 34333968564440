import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCategoryMetadata } from './filter-bar/queries';
import { useSearchRoute } from './search-screen';
import { CategoryMetadata } from './http';

// Define types for our context
type FilterContextType = {
  filters: CategoryMetadata['filters'];
  defaultFilters: string[];
  isLoading: boolean;
  setFilters: (filters: CategoryMetadata['filters']) => void;
  setDefaultFilters: (defaultFilters: string[]) => void;
  setIsLoading: (isLoading: boolean) => void;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export function FilterProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<CategoryMetadata['filters']>([]);
  const [defaultFilters, setDefaultFilters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { items, isLoading: metadataIsLoading } = useCategoryMetadata();
  const { searchType } = useSearchRoute();

  useEffect(() => {
    if (items) {
      const routeMetadata = items.get(searchType);
      if (routeMetadata?.filters) {
        setFilters(routeMetadata.filters);
        setDefaultFilters(routeMetadata.defaultFilters || []);
      }
    }
    setIsLoading(metadataIsLoading);
  }, [items, searchType, metadataIsLoading]);

  const value = {
    filters,
    defaultFilters,
    isLoading,
    setFilters,
    setDefaultFilters,
    setIsLoading,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
}
