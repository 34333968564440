import { createSelector } from 'reselect';

//this is only compatible with the v3 profile structure. be careful pushing in props.advocate from older versions of the API.
const getProfile = (props) => props.profile || props.advocate;

export const getProfileId = createSelector(
  [getProfile],
  (profile) => profile.id
);

export const getProfileName = createSelector([getProfile], (profile) =>
  (profile.name || '').trim()
);

export const getProfileNameMissing = createSelector(
  [getProfile],
  (profile) => profile.hasFallbackNameValue === true //if value undefined, return false
);

export const getProfileAvatarUrl = createSelector(
  [getProfile],
  (profile) => profile.avatar.url
);

export const getProfileAvatarColor = createSelector(
  [getProfile],
  (profile) => profile.avatar.color
);

export const getProfileIsPrivate = createSelector(
  [getProfile],
  (profile) => profile.privateProfile
);

export default {
  getProfileId,
  getProfileName,
  getProfileNameMissing,
  getProfileAvatarUrl,
  getProfileAvatarColor,
  getProfileIsPrivate,
};
