export const webEvents = {
  COMMENT_TRANSLATE: 'w:ContentCard:Comment:Translation',
  COMMENT_SEE_ORIGINAL: 'w:ContentCard:Comment:SeeOriginal',
  COMMENT_TRANSLATE_ALL: 'w:ContentCard:Comment:TranslateAll',
  COMMENT_SEE_ORIGINAL_ALL: 'w:ContentCard:Comment: SeeOriginalAll',
};

export const iosEvents: Record<keyof typeof webEvents, string> = {
  COMMENT_TRANSLATE: 'iOS:ContentCard:Comment:Translation',
  COMMENT_SEE_ORIGINAL: 'iOS:ContentCard:Comment:SeeOriginal',
  COMMENT_TRANSLATE_ALL: 'iOS:ContentCard:Comment:TranslateAll',
  COMMENT_SEE_ORIGINAL_ALL: 'iOS:ContentCard:Comment: SeeOriginalAll',
};

export const androidEvents: Record<keyof typeof webEvents, string> = {
  COMMENT_TRANSLATE: 'ADV:ContentCard:Comment:Translation',
  COMMENT_SEE_ORIGINAL: 'ADV:ContentCard:Comment:SeeOriginal',
  COMMENT_TRANSLATE_ALL: 'ADV:ContentCard:Comment:TranslateAll',
  COMMENT_SEE_ORIGINAL_ALL: 'ADV:ContentCard:Comment: SeeOriginalAll',
};
