import { ContentType } from '../../models/content/types';
import { Optional } from '../../lib/utility-types';

type SiteHeaderSubmitImageProps = {
  contentType: Optional<ContentType>;
};

const SiteHeaderSubmitImage = ({ contentType }: SiteHeaderSubmitImageProps) => {
  return (
    <div aria-hidden="true" className="site-header-submit-image">
      <div className="site-header-submit-image__planets">
        <img
          className="site-header-submit-image__planet site-header-submit-image__planet--bookmark"
          src="/images/submit-badge-bookmark.svg"
          alt="bookmark badge"
        />
        <img
          className="site-header-submit-image__planet site-header-submit-image__planet--star"
          src="/images/submit-badge-star.svg"
          alt="star badge"
        />
        <img
          className="site-header-submit-image__planet site-header-submit-image__planet--like"
          src="/images/submit-badge-like.svg"
          alt="like badge"
        />
        <img
          className="site-header-submit-image__planet site-header-submit-image__planet--profile"
          src="/images/submit-badge-profile.svg"
          alt="profile badge"
        />
      </div>

      <div className="site-header-submit-image__image">
        {contentType === 'video' ? (
          <img src="/images/submit-image-video.svg" alt="video_submission" />
        ) : null}

        {contentType === 'image' ? (
          <img src="/images/submit-image-image.svg" alt="image_submission" />
        ) : null}

        {contentType === 'link' ? (
          <img src="/images/submit-image-link.svg" alt="link_submission" />
        ) : null}

        {contentType === 'note' ? (
          <img src="/images/submit-image-note.svg" alt="note_submission" />
        ) : null}

        {contentType === 'article' ? (
          <img
            src="/images/submit-image-article.svg"
            alt="article_submission"
          />
        ) : null}

        {!contentType ? (
          <img
            src="/images/submit-image-default.svg"
            alt="default_submission"
          />
        ) : null}
      </div>
    </div>
  );
};

export default SiteHeaderSubmitImage;
