import styles from './styles.module.scss';
import cx from 'classnames';
import { Container } from '@socialchorus/shared-ui-components';
interface Props {
  title: string;
  description: string;
  className?: string;
  cta?: JSX.Element;
}

function EmptyPlaceholder({
  title,
  description,
  className,
  cta,
}: Props): JSX.Element {
  return (
    <Container
      className={cx(styles.Container, className)}
      shadow="extra-light"
      fullWidth
    >
      <h2 className={styles.Title}>{title}</h2>
      <p className={styles.Description}>{description}</p>
      {cta && <div className={styles.CtaWrapper}>{cta}</div>}
    </Container>
  );
}

export default EmptyPlaceholder;
