import { HTMLProps, ReactNode, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  submissionImages,
  submissionImageUrls,
  submissionSetImageUrl,
  submissionIsEdit,
  submissionIsDraft,
} from '../../../models/content-submission/atoms';

import { Button, Icon } from '../../../components/ui';
import MediaUploader from '../media-uploader';

import {
  trackAddImageAddImageClick,
  trackAddImageAddImageError,
  trackLoadImagePreviewRemoveClick,
} from '../../../models/content-submission/analytics';

type ImageDetailsProps = {
  errorMessage?: string;
};

const ImageDetails = ({ errorMessage }: ImageDetailsProps) => {
  const imageUrls = useRecoilValue(submissionImageUrls);

  const { t } = useTranslation();

  const imageLength = imageUrls.length;

  const className = cx('content-submission-input-multi-image__details', {
    'content-submission-input-multi-image__details--error': !!errorMessage,
  });

  return (
    <div className={className}>
      <div
        role="status"
        className="content-submission-input-multi-image__count"
      >
        {!errorMessage
          ? t('content_submission.n_image_attached', { count: imageLength })
          : t('common.error') + ': ' + errorMessage}
      </div>
    </div>
  );
};

type ImageItemProps = {
  disabled?: boolean;
  onAdd?: () => void;
  onRemove: (index: number) => void;
};

const ImageItems = SortableContainer(
  ({ disabled, onAdd, onRemove }: ImageItemProps) => {
    const images = useRecoilValue(submissionImages);
    const imageUrls = useRecoilValue(submissionImageUrls);

    const items = disabled
      ? imageUrls.map((imageUrl: string, index: number) => (
          <ImagePreview imageUrl={imageUrl} key={index} />
        ))
      : images.map((image, index) => (
          <ImageSortable
            image={image}
            index={index}
            sortIndex={index}
            disabled={disabled || !imageUrls[index]} // disable until has preview image
            isDisabled={disabled}
            onRemove={onRemove}
            key={index}
          />
        ));

    return (
      <div className="content-submission-input-multi-image__previews">
        {items}

        {!disabled && images.length < 10 && <AddItem onClick={onAdd} />}
      </div>
    );
  }
);

type ImageSortableProps = {
  image: any;
  sortIndex: number;
  isDisabled?: boolean;
  onRemove: (index: number) => void;
};

const ImageSortable = SortableElement(
  ({ image, sortIndex, isDisabled, onRemove }: ImageSortableProps) => {
    const [imageUrl, setImageUrl] = useRecoilState(
      submissionSetImageUrl(sortIndex)
    );
    const [isUploaded, setIsUploaded] = useState(!!imageUrl);

    const { t } = useTranslation();

    const handleUpload = (url: string) => {
      setImageUrl(url);
      setIsUploaded(true);
    };

    const handleError = () => {
      trackAddImageAddImageError();
    };

    const handleRemove = () => {
      onRemove(sortIndex);
    };

    return (
      <ImagePreview imageUrl={imageUrl}>
        {!isUploaded && (
          <MediaUploader
            file={image}
            onComplete={handleUpload}
            onError={handleError}
          />
        )}

        {!isDisabled && (
          <Button
            icon="delete"
            onClick={handleRemove}
            aria-label={t('common.delete')}
          />
        )}
      </ImagePreview>
    );
  }
);

type ImagePreviewProps = {
  imageUrl: string;
  children?: ReactNode;
};

const ImagePreview = ({ imageUrl, children }: ImagePreviewProps) => {
  const { t } = useTranslation();

  return (
    <div
      role="img"
      aria-label={t('common.preview')}
      className="content-submission-input-multi-image__preview"
      style={{ backgroundImage: `url('${imageUrl}')` }}
    >
      {children}
    </div>
  );
};

type AddItemProps = {
  disabled?: boolean;
  onClick?: () => void;
};

const AddItem = ({ disabled, onClick }: AddItemProps) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="content-submission-input-multi-image__dropzone"
      disabled={disabled}
      tabIndex={0}
      onClick={onClick}
      aria-label={t('content_submission.header_add_image')}
    >
      <Icon type="add_circle_outline" /> {t('common.add')}
    </button>
  );
};

type MultiImageInput = {
  disabled?: boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
  openFileBrowser: () => void;
  errorMessage?: string;
};

const MultiImageInput = ({
  disabled,
  inputProps,
  openFileBrowser,
  errorMessage,
}: MultiImageInput) => {
  const [images, setImages] = useRecoilState(submissionImages);
  const [imageUrls, setImageUrls] = useRecoilState(submissionImageUrls);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isDraft = useRecoilValue(submissionIsDraft);

  disabled = disabled || (isEdit && !isDraft);

  const handleAdd = () => {
    if (disabled) return;

    openFileBrowser();

    trackAddImageAddImageClick();
  };

  const handleRemove = (index: number) => {
    if (disabled) return;

    const newImages = [...images];
    const newImageUrls = [...imageUrls];

    newImages.splice(index, 1);
    newImageUrls.splice(index, 1);

    setImages(newImages);
    setImageUrls(newImageUrls);

    trackLoadImagePreviewRemoveClick();
  };

  const handleResort: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newImages = [...images];
    const newImageUrls = [...imageUrls];

    newImages.splice(newIndex, 0, newImages.splice(oldIndex, 1)[0]);
    newImageUrls.splice(newIndex, 0, newImageUrls.splice(oldIndex, 1)[0]);

    setImages(newImages);
    setImageUrls(newImageUrls);
  };

  return (
    <div className="content-submission-input-multi-image">
      {inputProps ? <input {...inputProps} /> : null}

      <ImageDetails errorMessage={errorMessage} />

      <ImageItems
        axis="xy"
        distance={5}
        disabled={disabled}
        onAdd={handleAdd}
        onRemove={handleRemove}
        onSortEnd={handleResort}
      />
    </div>
  );
};

export default MultiImageInput;
