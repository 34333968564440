import { Fragment } from 'react';
import { Container } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../../loading-skeleton';
import styles from './enterprise-loading-skeleton-text.module.scss';
export function EnterpriseLoadingSkeletonText({
  description,
}: {
  description: boolean;
}) {
  const numOfCards = 8;
  const childComponents = [];

  const loadingCardComponent = (
    <Container className={styles.EnterpriseSearchItem} shape="small">
      <section className={styles.ItemTitle}>
        <LoadingSkeleton width={'90%'} />
        <LoadingSkeleton width={'70%'} />
      </section>
      {description ? (
        <section className={styles.ItemDescription}>
          <LoadingSkeleton width={'90%'} height={12} />
          <LoadingSkeleton width={'90%'} height={12} />
          <LoadingSkeleton width={'90%'} height={12} />
          <LoadingSkeleton width={'90%'} height={12} />
          <LoadingSkeleton width={'60%'} height={12} />
        </section>
      ) : null}
      <section className={styles.ItemAuthor}>
        <LoadingSkeleton height={10} width={'50%'} marginTop={20} />
      </section>
    </Container>
  );

  for (let i = 0; i < numOfCards; i++) {
    childComponents.push(<Fragment key={i}>{loadingCardComponent}</Fragment>);
  }
  return <div className={styles.SearchResultRow}>{childComponents}</div>;
}
