import React from 'react';
import cx from 'classnames';

const DropzoneOverlay = ({ placeholderText, errorText }) => {
  const className = cx('dropzone-overlay', {
    'is-error': !!errorText,
  });

  return (
    <div className={className}>
      {/* eslint-disable max-len */}
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="51"
        viewBox="0 0 61 51"
        className="image"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M50.032 17s-2.535-.375-5.004 0h5.004zM8.028 15a8 8 0 0 1 16 0" />
          <path d="M44.028 39H48c6.066 0 11.028-4.963 11.028-11.028 0-5.371-3.894-10.01-8.996-10.972-.115-8.699-7.193-16-15.919-16-5.56 0-10.779 3.005-13.661 7.336A8 8 0 0 0 8.028 15c0 .153.015.303.023.454-.01.183-.023.366-.023.546-3.988 1.912-7 6.457-7 11.155C1.028 33.67 6.358 39 12.873 39h5.155M31.028 50V27v23zM31.028 27l8 8-8-8zM23.028 35l8-8-8 8z" />
        </g>
      </svg>
      {/* eslint-enable max-len */}

      <div className="text">{errorText || placeholderText}</div>
    </div>
  );
};

export default DropzoneOverlay;
