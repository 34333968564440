import cx from 'classnames';
import Select from 'react-select';
import { lighten } from 'polished';
import useProgramTheme from '../../common/use-program-theme';

const SelectInput = ({
  name,
  label,
  options = [],
  value,
  placeholder,
  className,
  isDisabled,
  onChange,
  isSearchable = true,
}) => {
  const normalizedOptions = options.map(({ value, label }) => ({
    value: value || label,
    label,
  }));

  //unlike the other inputs, this does NOT pass a react synthetic event to the onChange.
  //will likely cause brittleness down the line, long term we need to place an abstraction layer between the caller of all of the input componets that defines a limimted onChange api
  const wrappedOnChange = (newOption) => {
    const value = newOption && newOption.value;
    onChange({ target: { name, value, type: 'react-select' } });
  };

  const { themeColor } = useProgramTheme();

  return (
    <div className={cx('select-input', className)}>
      {label ? (
        <label htmlFor={name} className="label">
          {label}
        </label>
      ) : null}

      <Select
        name={name}
        options={normalizedOptions}
        value={normalizedOptions.find((option) => option.value === value)}
        onChange={wrappedOnChange}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        placeholder={placeholder}
        isClearable={true}
        classNamePrefix="react-select"
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,

            //not fully lightening the theme colors, as the themeColor value will not be at full saturation
            primary: themeColor,
            primary75: lighten(0.1, themeColor),
            primary50: lighten(0.25, themeColor),
            primary25: lighten(0.3, themeColor),
          },
        })}
      />
    </div>
  );
};

export default SelectInput;
