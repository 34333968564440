import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Link, Spinner } from '../../components/ui';
import { Label } from '../../components/ui/text-input';
import ProfileEditContainer from './profile-edit-container';

import { trackProfileEditCancel } from '../../models/profile/analytics';
import ProfileTextInput from './inputs/profile-text-input';

import ProfileDateInput from './inputs/profile-date-input';
import ProfileSelectInput from './inputs/profile-select-input';
import ProfileBooleanInput from './inputs/profile-boolean-input';
import useProfileFetcherEdit from '../../common/use-profile-fetcher-edit';
import NotFound from '../not-found';
import ProfileSectionHeader from './profile-section-header';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const ProfileField = ({ label, labelHidden, name, children }) => {
  return (
    <div className="fields-group__field">
      <div className={`field-label ${labelHidden ? 'hidden-label' : ''}`}>
        <Label htmlFor={name}>{label}</Label>
      </div>
      <div className={`field-input  ${labelHidden ? 'extra-margin' : ''}`}>
        {children}
      </div>
    </div>
  );
};

const ProfileFields = ({ fields, updatedValues, onChange, onBlur }) =>
  fields.map((fieldProps) => {
    let Component;
    const { type, name, label } = fieldProps;
    const value =
      updatedValues[name] !== undefined
        ? updatedValues[name]
        : fieldProps.value;

    switch (type) {
      case 'text':
      case 'email':
        Component = ProfileTextInput;
        break;
      case 'date':
        Component = ProfileDateInput;
        break;
      case 'select':
        Component = ProfileSelectInput;
        break;
      case 'boolean':
        Component = ProfileBooleanInput;
        break;
      default:
        Component = ProfileTextInput;
    }

    return (
      <ProfileField
        name={name}
        label={label}
        labelHidden={type === 'boolean'}
        key={name}
      >
        <Component
          {...fieldProps}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          dateFormat={fieldProps.dateFormat}
        />
      </ProfileField>
    );
  });

const ProfileGroup = ({
  section,
  fields,
  updatedValues,
  onChange,
  onBlur,
  iconUrl,
}) => {
  if (!fields.length) return null;

  return (
    <div className="fields-group">
      <ProfileSectionHeader title={section} iconUrl={iconUrl} />
      <ProfileFields
        fields={fields}
        updatedValues={updatedValues}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

const ProfileEdit = ({ analyticsData, profileId, syncAdvocate }) => {
  const { t } = useTranslation();

  const {
    profileGroups,
    isFetching,
    isNotFound,
    syncAdvocate: wrappedSyncAdvocate,
  } = useProfileFetcherEdit({ profileId, syncAdvocate });

  const analyticsDataEdit = {
    ...analyticsData,
    location: 'profile_edit',
  };

  const handleCancelClick = () => {
    trackProfileEditCancel(analyticsDataEdit);
  };

  if (isNotFound) return <NotFound />;

  return !isFetching ? (
    <ProfileEditContainer
      analyticsData={analyticsDataEdit}
      syncAdvocate={wrappedSyncAdvocate}
    >
      {({
        updatedValues,
        displayGdprOptions,
        submitting,
        onChange,
        onBlur,
        onExportClick,
        onEraseClick,
        onSubmit,
      }) => (
        <div className="user-profile-edit">
          <div
            className="user-profile-edit__body"
            id={DEFAULT_SKIP_TO_CONTENT_ID}
          >
            <form className="form" onSubmit={onSubmit}>
              {profileGroups.map(({ label, fields, iconUrl, name }, idx) => (
                <ProfileGroup
                  updatedValues={updatedValues}
                  section={label}
                  iconUrl={iconUrl}
                  fields={fields}
                  onChange={onChange}
                  onBlur={onBlur}
                  key={name}
                />
              ))}

              <div className="form-footer">
                <div className="cols">
                  <div className="col-2">
                    <Button
                      theme="primary-legacy"
                      type="submit"
                      loading={submitting}
                      disabled={submitting}
                      onClick={onSubmit}
                      aria-label={t('common.save')}
                    >
                      <Icon type="check" />
                      {t('common.save')}
                    </Button>

                    <Link
                      href="/account/profile"
                      action="replace"
                      onClick={handleCancelClick}
                    >
                      {t('common.cancel')}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {displayGdprOptions ? (
            <div className="user-profile-edit__footer">
              <Button theme="link" size="small" onClick={onExportClick}>
                {t('screens.profile.edit_profile.export_my_data')}
              </Button>
              <Button theme="link" size="small" onClick={onEraseClick}>
                {t('screens.profile.edit_profile.forget_me')}
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </ProfileEditContainer>
  ) : (
    <Spinner center />
  );
};

export default ProfileEdit;
