import { HTMLProps, KeyboardEventHandler } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  submissionImages,
  submissionImageUrls,
} from '../../../models/content-submission/atoms';

import { Icon } from '../../../components/ui';
import MediaUploader from '../media-uploader';

import {
  trackAddImageAddImageClick,
  trackAddImageAddImageError,
} from '../../../models/content-submission/analytics';

type ImageInputProps = {
  inputProps?: HTMLProps<HTMLInputElement>;
  openFileBrowser: () => void;
  errorMessage?: string;
  isDisabled?: boolean;
};

const ImageInput = ({
  inputProps,
  openFileBrowser,
  errorMessage,
  isDisabled,
}: ImageInputProps) => {
  const setImageUrls = useSetRecoilState(submissionImageUrls);
  const [image] = useRecoilValue(submissionImages);

  const { t } = useTranslation();

  const handleClick = () => {
    if (isDisabled) return;

    openFileBrowser();
    trackAddImageAddImageClick();
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (!isDisabled && e.key === 'Enter') handleClick();
  };

  const handleUpload = (url: string) => {
    setImageUrls([url]);
  };

  const handleError = () => {
    trackAddImageAddImageError();
  };

  const dropClassName = cx('content-submission-input-image__dropzone', {
    'content-submission-input-image__dropzone--error': !!errorMessage,
    'content-submission-input-image__dropzone--disabled': isDisabled,
  });

  return (
    <div className="content-submission-input-image">
      <input {...inputProps} />

      {!image ? (
        <button
          type="button"
          className={dropClassName}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          aria-labelledby="content-submission-field-image-placeholder"
        >
          {!errorMessage ? <Icon type="panorama" /> : null}

          <div className="text">
            <span
              id="content-submission-field-image-placeholder"
              hidden={!!errorMessage}
            >
              {t('content_submission.field_image_placeholder')}
            </span>
            {errorMessage && <span role="alert">{errorMessage}</span>}
          </div>
        </button>
      ) : (
        <MediaUploader
          file={image}
          onComplete={handleUpload}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default ImageInput;
