import { Container } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../loading-skeleton';
import styles from './shortcuts.module.scss';

export function ShortcutLoadingSkeleton() {
  return (
    <Container className={styles.ShortcutCardPlaceholder}>
      <LoadingSkeleton height="40px" width="40px" borderRadius={4} />
      <LoadingSkeleton height={14} width="75%" />
    </Container>
  );
}
