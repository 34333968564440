import { History } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ForwardActions } from '../config/history';

function stateHasRequiredProperty(
  state: unknown
): state is { shouldRefetchData: boolean } {
  return (
    typeof state === 'object' && state !== null && 'shouldRefetchData' in state
  );
}

/**
 * Triggers the callback whenever the current path is navigated to using the exposed `navigateWithRefetch` function.
 *
 * Only *ONE* `useNavigationRefetch` hook with callback should exist on a route!
 */
export default function useNavigationRefetch(refetchCallback?: () => void) {
  const history = useHistory();
  const location = useLocation();

  const callbackRef = useRef(refetchCallback);

  const navigateWithRefetch = (
    location: History.Path | History.LocationDescriptor,
    state: Record<string, unknown> = {},
    action: ForwardActions
  ) => {
    if (typeof location === 'object') {
      location.state = {
        ...(typeof location.state === 'object' ? location.state : {}),
        shouldRefetchData: true,
      };

      history[action](location);
      return;
    }

    history[action](location, {
      ...state,
      shouldRefetchData: true,
    });
  };

  useEffect(() => {
    if (
      !callbackRef.current ||
      !stateHasRequiredProperty(location.state) ||
      !location.state.shouldRefetchData
    ) {
      return;
    }

    callbackRef.current();

    history.replace({
      ...location,
      state: {
        shouldRefetchData: false,
      },
    });
  }, [history, location]);

  return {
    navigateWithRefetch,
  };
}
