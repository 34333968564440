/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useEffect,
} from 'react';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import '../ui.scss';
import styles from './styles.module.scss';
import { useSetRecoilState } from 'recoil';
import { siteHeaderShouldBeInModalOverlay } from '../../../models/ui/recoil-state';

type ModalProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  transparent?: boolean;
  lockScroll?: boolean;
  showSiteHeader?: boolean; //if true, keep the site header interactive when modal is open
  children: ReactNode;
  onClose?: () => void;
};
const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      className: classNameProp,
      transparent,
      lockScroll,
      showSiteHeader = false,
      children,
      onClose,
      ...props
    },
    ref
  ) => {
    const className = cx(classNameProp, styles.modal);
    const setSiteHeaderInModal = useSetRecoilState(
      siteHeaderShouldBeInModalOverlay
    );

    const containerClassName = cx(styles.modal__container, {
      [styles.modal__containerTransparent]: transparent,
      [styles.modal__containerShowSiteHeader]: showSiteHeader,
    });

    useEffect(() => {
      if (lockScroll) {
        document.body.classList.add('lock-scroll');

        return () => document.body.classList.remove('lock-scroll');
      }
    }, [lockScroll]);

    useEffect(() => {
      setSiteHeaderInModal(showSiteHeader);

      return () => setSiteHeaderInModal(false);
    }, [showSiteHeader, setSiteHeaderInModal]);

    const handleKeyPress = (ev: React.KeyboardEvent) => {
      if (ev.code === 'Escape') {
        onClose?.();
      }
    };

    return (
      <FocusLock group={showSiteHeader ? 'nav-modal' : undefined}>
        <div
          className={containerClassName}
          onClick={onClose}
          onKeyDown={handleKeyPress}
        >
          <div
            ref={ref}
            className={className}
            // hacky way to implement this, but without refactoring too much this should work
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            role="dialog"
            {...props}
          >
            {children}
          </div>
        </div>
      </FocusLock>
    );
  }
);

export { ModalCloseButton } from './CloseButton';

Modal.displayName = 'UI.Modal';

export default Modal;
