import { useState, useEffect, useCallback } from 'react';

type UseIframeHeightProps = {
  onRefChange?: (node: HTMLIFrameElement | null) => void;
  styles?: string;
  iframeIsVisible?: boolean;
  disabled?: boolean;
};

const useIframeResize = ({
  onRefChange,
  styles = '',
  iframeIsVisible = true,
  disabled = false,
}: UseIframeHeightProps) => {
  const [hasResized, setHasResized] = useState(false);
  const [height, setHeight] = useState<number>();
  const [iframeElement, setIframeElement] = useState<HTMLIFrameElement | null>(
    null
  );

  const fixHeight = useCallback(() => {
    try {
      if (!iframeElement?.contentWindow || disabled) {
        return;
      }
      const {
        contentWindow: { document },
      } = iframeElement;
      const { body, head } = document;

      let style = document.getElementById('patron-style');
      if (style === null) {
        style = document.createElement('style');
        style.id = 'patron-style';
        head.appendChild(style);
      }

      style.innerHTML = styles;

      //this complex way of getting the body height is here to make sure it works in all browsers. (firefox especially)
      const { scrollHeight, offsetHeight, clientHeight } = body;
      const { bottom } = body.getBoundingClientRect();

      const contentHeight = Math.max(
        bottom,
        clientHeight,
        offsetHeight,
        scrollHeight
      );

      setHeight(contentHeight);
      setHasResized(true);
    } catch (error) {
      console.error(
        'An error occurred while adjusting the iframe height:',
        error
      );
    }
  }, [iframeElement, styles, disabled]);

  useEffect(() => {
    if (iframeElement && !disabled) {
      fixHeight();
      iframeElement.addEventListener('load', fixHeight);
      window.addEventListener('resize', fixHeight);

      return () => {
        iframeElement.removeEventListener('load', fixHeight);
        window.removeEventListener('resize', fixHeight);
      };
    }
  }, [iframeIsVisible, fixHeight, iframeElement, disabled]);

  useEffect(() => {
    if (!iframeElement?.contentDocument?.body) return;

    const resizeObserver = new ResizeObserver(fixHeight);
    resizeObserver.observe(iframeElement.contentDocument.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [fixHeight, iframeElement?.contentDocument?.body]);

  const ref = useCallback(
    (node) => {
      if (node !== null) {
        setIframeElement(node);
      }
      if (onRefChange) {
        onRefChange(node);
      }
    },
    [onRefChange]
  );

  return { height, ref, hasResized };
};

export default useIframeResize;
