import { Avatar } from '@socialchorus/shared-ui-components';
import { Author } from '../../models/assistant/interfaces/Author';
import { Link } from '../ui';

type CommentAuthorAvatarProps = {
  author: Author;
  onClick: () => void;
};

const CommentAuthorAvatar = ({ author, onClick }: CommentAuthorAvatarProps) => {
  const avatar = (
    <Avatar
      size="medium"
      shape="circle"
      imgSrc={author.avatar.url || undefined}
    />
  );

  const linkedAvatar = (
    <Link
      href={`/profiles/${author.id}`}
      action="push"
      className="comment-author-avatar"
      onClick={onClick}
      aria-label="Profile"
    >
      {avatar}
    </Link>
  );

  return author.id ? (
    linkedAvatar
  ) : (
    <div className="comment-author-avatar">{avatar}</div>
  );
};

export default CommentAuthorAvatar;
