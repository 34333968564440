import { Container } from '@socialchorus/shared-ui-components';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';
import { useProgram } from '../../../../common/use-program';
import { ResourceObject } from '../../../../lib/types/json-api';
import {
  trackShortcutClick,
  trackShortcutView,
} from '../../../../models/analytics';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../skip-to-content/skip-to-content';
import { VisuallyHidden } from '../../visually-hidden';
import { createCacheKey, optimus, parseCacheKey } from '../http';
import { InfiniteSearchResults, useInfiniteSearch } from '../infinite-search';
import { useLocalizedScreenReaderLoadingMessage } from '../locale';
import { CompleteError } from '../overview/overview';
import { NoResultsFound } from '../no-results-found';
import { SearchResultState } from '../search-screen';
import { SearchParams, stringify, useSearchQueryParams } from '../query-params';
import { ShortcutLoadingSkeleton } from './shortcut-loading-skeleton';
import styles from './shortcuts.module.scss';
import ShortcutButton from '../../homepage-shortcuts/shortcut-button/shortcut-button';
import { useLinkNavigation } from '../../../../common/use-link-navigation';
import { DEFAULT_SHORTCUT_ICON } from '../../../ui/shortcut';
import { TopicPageShortcut } from '../../topic-page-v2/tabs/ShortcutsTab/shortcut';
import { TopicShortcut } from '../../../../models/channels/types';
import { Filter } from '../filter-bar/filters/filters';
import { useFilters } from '../filter-context';

type Shortcut = {
  id: number;
  type: string;
  name: string;
  description: string;
  links?: {
    url: string;
    label: string;
  }[];
  imageUrl: string;
};

export type ShortcutSearchResponse = {
  data: ResourceObject<'shortcut', Shortcut>[];
};

export async function searchShortcuts(programId: string, params: SearchParams) {
  const query = stringify(params);

  return optimus
    .get<ShortcutSearchResponse>(
      `/v1/programs/${programId}/search/shortcuts?${query}`
    )
    .then((response) => response.data);
}

export const shortcutsCacheKey = createCacheKey('shortcuts');
export const useShortcutSearch = (searchParams: SearchParams) => {
  const programId = useProgram().id;
  const {
    data: shortcutSearchResponse,
    isLoading,
    ...rest
  } = useInfiniteSearch(
    (page) => [shortcutsCacheKey({ ...searchParams, page: page + 1 })],
    ([cacheKey]) => searchShortcuts(programId, parseCacheKey(cacheKey).params)
  );

  const isLoadingMore = Boolean(
    isLoading ||
      (shortcutSearchResponse &&
        shortcutSearchResponse[shortcutSearchResponse.length - 1] === undefined)
  );

  const isEmpty = shortcutSearchResponse?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (shortcutSearchResponse &&
        shortcutSearchResponse[shortcutSearchResponse.length - 1]?.data.length <
          Number(searchParams?.perPage))
  );

  return {
    shortcutsData: shortcutSearchResponse,
    isLoadingMore,
    isReachingEnd,
    ...rest,
  };
};

type ShortcutSearchProps = {
  searchReturnedResults?: (searchResultState: SearchResultState) => void;
};

export function ShortcutSearch({ searchReturnedResults }: ShortcutSearchProps) {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });

  const {
    shortcutsData,
    error,
    isValidating,
    isLoadingMore,
    isReachingEnd,
    mutate: retry,
    size,
    setSize,
  } = useShortcutSearch(query);

  if (searchReturnedResults && !shortcutsData) {
    searchReturnedResults(SearchResultState.Loading);
  }

  if (searchReturnedResults && shortcutsData !== undefined && !error) {
    searchReturnedResults(SearchResultState.HasResults);
  }

  const srOnlyLoadingMessage =
    useLocalizedScreenReaderLoadingMessage('shortcuts');

  const retryShortcutSearch = () => {
    retry();
  };

  useEffect(() => {
    if (shortcutsData !== undefined && shortcutsData[0].data.length > 0) {
      trackShortcutView();
    }
  }, [shortcutsData]);

  if (shortcutsData !== undefined && shortcutsData[0].data.length > 0) {
    return (
      <section id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {shortcutsData.length > 0 ? (
          <InfiniteSearchResults
            isLoadingMore={isValidating || isLoadingMore}
            isReachingEnd={isReachingEnd}
            onViewTrigger={() => setSize(size + 1)}
            errorFetching={Boolean(error)}
            handleRetry={retryShortcutSearch}
          >
            <Container
              variant="large"
              shadow="light"
              className={styles.ShortcutsContainer}
            >
              {shortcutsData.map((shortcuts) => {
                return shortcuts.data.map((item) => (
                  <ShortcutSearchResultItem
                    key={item.id}
                    item={item.attributes}
                    onClick={trackShortcutClick}
                  />
                ));
              })}
            </Container>
          </InfiniteSearchResults>
        ) : (
          <NoResultsFound
            title={t('search.no_results_type.title', {
              type: t(`search.types.documents`),
            })}
            description={t('search.no_results_type.description')}
          />
        )}
      </section>
    );
  }

  if (shortcutsData !== undefined && shortcutsData[0].data.length === 0) {
    return (
      <NoResultsFound
        title={t('search.no_results_type.title', {
          type: t(`search.types.shortcuts`),
        })}
        description={t('search.no_results_type.description')}
      />
    );
  }

  if (error) {
    return (
      <CompleteError
        query={query.query ?? ''}
        handleRetry={retryShortcutSearch}
      />
    );
  }

  return (
    <>
      <VisuallyHidden id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {srOnlyLoadingMessage}
      </VisuallyHidden>
      <Container
        variant="large"
        className={styles.ShortcutsContainer}
        aria-busy
        aria-live="polite"
      >
        {Array.from({ length: 35 }).map((_, index) => (
          <ShortcutLoadingSkeleton key={index} />
        ))}
      </Container>
    </>
  );
}

type ShortcutSearchResultItemProps = {
  item: Shortcut;
  onClick?: () => void;
};

export function ShortcutSearchResultItem({
  item,
  onClick,
}: ShortcutSearchResultItemProps) {
  const getLinkNavigation = useLinkNavigation();

  const shouldOpenAssistant =
    item.type !== 'link' || (item.links && item.links.length > 1);
  const assistantLink = `/assistant/service/${item.id}`;

  const isTLPShortcut = item.type === 'channel_shortcut';

  const link = useMemo(() => {
    if (!shouldOpenAssistant) {
      return item.links && item.links[0].url;
    }
    return getLinkNavigation(assistantLink);
  }, [getLinkNavigation, assistantLink, item.links, shouldOpenAssistant]);

  return isTLPShortcut ? (
    <TopicPageShortcut shortcut={mapResultToTopicPageShortcut(item)} />
  ) : (
    <ShortcutButton
      id={item.id + ''}
      description={item.description}
      label={item.name}
      iconSrc={item.imageUrl || DEFAULT_SHORTCUT_ICON}
      href={link}
      target={shouldOpenAssistant ? undefined : '_blank'}
      rel={shouldOpenAssistant ? undefined : 'noopener noreferrer'}
      onClick={onClick}
    />
  );
}

const mapResultToTopicPageShortcut = (item: Shortcut): TopicShortcut => {
  return {
    ...item,
    position: 0,
    id: item.id + '',
    icon_url: item.imageUrl,
    links: (item.links || []).map((link) => ({
      name: link.label,
      url: link.url,
    })),
  };
};
