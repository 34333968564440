import React from 'react';
import styled from 'styled-components';
import SourceAttribution from './source-attribution';
import Thumbnail from './thumbnail';
import { TrackableElement } from './view-tracker';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../../ui';
import { Item } from '../models';
import { TextWithMentions } from '../../../../ui/mention-text';

const StyledLayout = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid #fafafa;

  > div.image {
    width: 61px;
    height: 61px;
    margin-left: 10px;
    float: right;
  }

  > div.text {
    display: block;

    .attribution {
      margin-bottom: 10px;
      font-size: 0.7rem;
    }
    > p {
      text-decoration: none;
      margin: 0;
      margin-top: 10px;
      padding: 0;
      line-height: 1.35em;
      font-size: 18px;
      font-weight: 500;
    }
    .footer {
      width: 100%;
      .bookmark {
        float: right;
      }
    }
  }
  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: transparent;
    margin-top: 10px;
    padding: 0;
    line-height: 1.35em;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    display: block;
  }
  a:hover {
    text-decoration: none;
  }
`;

interface RelatedContentProps {
  item: Item;
  href: string;
  onClick?: () => void;
  trackableClassName?: string;
}

const RelatedContent: React.FC<RelatedContentProps> = ({
  item,
  href,
  onClick,
  trackableClassName,
}) => {
  const { t } = useTranslation();

  const contentTitle = item.title ?? item.summary ?? '\u00A0';
  return (
    <TrackableElement
      trackingId={item.id}
      trackableClassName={trackableClassName}
    >
      <StyledLayout className="feed-item">
        {item.image && (
          <Link href={href} action="push" onClick={onClick}>
            <Thumbnail item={item} />
          </Link>
        )}
        <div className="text">
          <SourceAttribution
            {...item}
            showSource={false}
            label={t('common.published')}
          />
          <Link
            href={href}
            action="push"
            aria-label={item.title ?? item.summary ?? 'No Description'}
            onClick={onClick}
          >
            <TextWithMentions text={contentTitle} />
          </Link>
        </div>
      </StyledLayout>
    </TrackableElement>
  );
};

export default RelatedContent;
