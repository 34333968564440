import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@socialchorus/shared-ui-components';
import React from 'react';
import styles from '../filter-bar.module.scss';

/**
 * DropdownFilter is a wrapper for the dropdown menu from shared-ui-components.
 * It should act as a container for the dropdown menu content and trigger.
 */
export function DropdownFilter({
  children,
  onOpenChange,
  open,
}: Pick<
  Parameters<typeof DropdownMenu>[0],
  'onOpenChange' | 'open' | 'children'
>) {
  return (
    <DropdownMenu modal={false} onOpenChange={onOpenChange} open={open}>
      {children}
    </DropdownMenu>
  );
}

/**
 * DropdownFilterTrigger is a wrapper for the dropdown menu trigger from shared-ui-components.
 * It is the element that toggles the dropdown menu open and closed.
 * Should be used as the child of DropdownFilter.
 */
export const DropdownFilterTrigger = DropdownMenuTrigger;

/**
 * DropdownFilterContent is a wrapper for the dropdown menu content from shared-ui-components.
 * It applies the correct styles for the filter bar dropdown panel.
 * Should be used as the child of DropdownFilter.
 */
export const DropdownFilterContent = React.forwardRef<
  HTMLDivElement,
  Pick<
    Parameters<typeof DropdownMenuContent>[0],
    'children' | 'onFocusOutside' | 'onInteractOutside'
  >
>(({ children, ...props }, ref) => (
  <DropdownMenuContent
    ref={ref}
    className={styles.FilterBar__allFiltersDropdown}
    align="start"
    sideOffset={15}
    {...props}
  >
    {children}
  </DropdownMenuContent>
));

DropdownFilterContent.displayName = 'DropdownFilterContent';

/**
 * DropdownFilterItem is a wrapper for the dropdown menu item from shared-ui-components.
 * It applies the correct styles for the filter bar dropdown items.
 */
export const DropdownFilterItem = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onSelect?: (event: Event) => void;
  }
>(({ children, ...props }, ref) => (
  <DropdownMenuItem
    ref={ref}
    className={styles.FilterBar__allFiltersDropdownItem}
    {...props}
  >
    {children}
  </DropdownMenuItem>
));

DropdownFilterItem.displayName = 'DropdownFilterItem';
