import { Container } from '@socialchorus/shared-ui-components';
import styles from './no-results-found.module.scss';
import { useTranslation } from 'react-i18next';

export interface NoResultsFoundProps {
  title: string;
  description: string;
}

export function NoResultsFound({ title, description }: NoResultsFoundProps) {
  const { t } = useTranslation();

  return (
    <Container shape="large" className={styles.NoResultsContainer}>
      <h2>{title}</h2>
      <p>{description}</p>
    </Container>
  );
}
