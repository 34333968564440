import { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { programMembershipSelectors } from '../../models/program-membership';
import useContentFetcher from '../../common/use-content-fetcher';
import useHeaderMinimizer from '../../common/use-header-minimizer';
import { Spinner } from '../../components/ui';
import ContentDetail from './content-detail';
import ContentInfo from './content-info';
import { trackContentCardRead } from '../../models/content/analytics';
import './content-details.scss';
import { setStorageItem } from '../../lib/localstorage';
import ContentDetailsNotFound from '../email-content-details/content-details-not-found';
import { ContentDetail as ContentDetailV2 } from '../../components/v2/content-detail/content-detail';
import { Feature } from '../../models/features/features';
import { useHistory, useRouteMatch } from 'react-router';
import useFeatureFlag from '../../common/use-feature-flag';

const ContentDetailsOrBlank = (props) =>
  props.isActiveMembership ? <ContentDetails {...props} /> : null;

const ContentDetails = ({ contentId, feedItemId }) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const isApproval = path.includes('approvals');
  const { content, fetching } = useContentFetcher({
    contentId,
    feedItemId,
    isApproval,
    mentionFormat: 'with_metadata',
  });

  contentId = content && content.id;

  const newContentPage = useFeatureFlag(Feature.CONTENT_DETAIL_NEW);
  const backButtonDisabled = useFeatureFlag(
    Feature.CONTENT_BACK_BUTTON_REMOVED
  );
  //for amazon, they want to force the header to be hidden even with the back button removed.
  //related to their desire to use the content detail page as a standalone landing page without being embedded, but also use the site normally for other users/departments
  //if this keeps being an issue, lets give them a new url to use for content detail.
  const forceHideHeader = useFeatureFlag(Feature.CONTENT_DETAIL_HIDE_HEADER);

  const loadComplete = !fetching;
  const showNotFound = loadComplete && !content;

  const className = cx('content-details', {
    'content-details-v2': newContentPage,
  });

  useHeaderMinimizer(
    forceHideHeader || (!backButtonDisabled && loadComplete && content)
  );

  const navigateBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setStorageItem('prevPage', 'article');
  }, []);

  useEffect(() => {
    if (feedItemId && content) {
      history.replace(`/contents/${content.id}`);
    } else if (!fetching && content) {
      trackContentCardRead(contentId);
    }
  }, [contentId, fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  if (showNotFound) return <ContentDetailsNotFound />;

  return (
    <section className={className}>
      {fetching ? (
        <Spinner />
      ) : newContentPage ? (
        <ContentDetailV2 contentId={contentId} navigateBack={navigateBack} />
      ) : (
        <>
          <ContentDetail contentId={contentId} onClose={navigateBack} />
          <ContentInfo contentId={contentId} />
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isActiveMembership: programMembershipSelectors.getProgramMembershipIsActive(
    state,
    ownProps
  ),
  overlays: state.ui.overlays,
});

export default connect(mapStateToProps, null)(ContentDetailsOrBlank);
