import useSWR from 'swr';
import { Feature, getFeatureFlag } from '../models/features/features';
import { Program } from '../models/program/types';
import { FeatureFlagsService } from '../services/feature-flags';
import { usePatronSelector } from './use-patron-selector';
import { useProgram } from './use-program';

//refactored to only take 1 flag at a time to make mocking easier.
//we dont use multiple flags in enough places to make this worth it.
export function useFeatureFlag(
  flag: Feature
): ReturnType<typeof getFeatureFlag> {
  const state = usePatronSelector((state) => state);
  return getFeatureFlag(state, flag);
}

const fetcher = (programId: Program['id']) =>
  new FeatureFlagsService(programId).fetch();

export function useFeatureFlagQuery() {
  const programId = useProgram()?.id;

  const enabled = Boolean(programId);
  const { data: flags, ...rest } = useSWR(
    enabled ? ['/custom_slugs', programId] : null,
    () => fetcher(programId)
  );

  return {
    flags: flags,
    ...rest,
  };
}

export default useFeatureFlag;
