import { ComponentProps, ReactElement } from 'react';
import { NavLink, Icon } from '../ui';
import './page-nav.scss';

type PageNavItem<K extends string | number> = {
  title: string;
  url: string;
  key?: K;
  onClick?: ComponentProps<typeof NavLink>['onClick'];
  icon?: string;
  iconComponent?: ReactElement;
};

type PageNavProps<K extends string | number> = {
  title?: string;
  items: PageNavItem<K>[];
  activeItemKey?: K;
};

export default function PageNav<K extends string | number>({
  title,
  items,
  activeItemKey,
}: PageNavProps<K>) {
  return (
    <nav className="page-nav">
      {title ? <div className="page-nav__title">{title}</div> : null}
      {items.map((item) => {
        const activeOverride =
          activeItemKey === undefined ? undefined : activeItemKey === item.key;

        const className = `page-nav__link pt-secondary-color ${
          activeOverride === true ? 'page-nav__link--active' : ''
        }`;
        const activeClassName =
          activeOverride === undefined ? 'page-nav__link--active' : '';

        return (
          <div
            className="page-nav__item"
            key={item.key || item.url || item.title}
          >
            <NavLink
              href={item.url}
              onClick={item.onClick}
              className={className}
              activeClassName={activeClassName}
            >
              {item.icon && <Icon type={item.icon} aria-hidden="true" />}
              {item.iconComponent ? item.iconComponent : null}
              <span className="text">{item.title}</span>
            </NavLink>
          </div>
        );
      })}
    </nav>
  );
}
