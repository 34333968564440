import { useSelector } from 'react-redux';
import { getChannelById } from '../../models/channels/selectors';
import { Icon } from '../ui';

export const FollowerCount = ({ channelId }) => {
  const followerCount = useSelector((state) =>
    getChannelById(state, channelId)
  )?.follower_count;
  return (
    <div className="channel__follower-count">
      <Icon type="people" />
      {followerCount}
    </div>
  );
};

export default FollowerCount;
