import React from 'react';
import { useTranslation } from 'react-i18next';

import './email-content-details.scss';

const ContentDetailsNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="content-not-found">
      <div className="content-not-found__image">
        <img src="/images/feed-empty.svg" alt="Content not found" />
      </div>

      <div className="content-not-found__title">Content Not Found</div>
      <p className="content-not-found__text">
        Please check the URL and try again.
      </p>
    </div>
  );
};

export default ContentDetailsNotFound;
