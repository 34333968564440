import { useTranslation } from 'react-i18next';
import useCommentFetcher from '../../common/use-comment-fetcher';
import { useCustomSlugRoute } from '../../common/use-custom-slug-route';
import { Link, Spinner } from '../ui';
import CommentListItem from './comment-list-item';
import CommentsFooter from './comments-footer';
import './comments.scss';
import { CommentContext } from './types';

type CommentProps = {
  contentId: number;
  commentId: string;
};

const Comment = ({ contentId, commentId }: CommentProps) => {
  const context: CommentContext = { contentId, commentId };

  const customSlug = useCustomSlugRoute();
  const detailsRoute = `/contents/${customSlug || contentId}/comments`;

  const { comment, message, isFetching } = useCommentFetcher({
    contentId,
    commentId,
  });

  const { t } = useTranslation();

  return (
    <div className="comments">
      <div className="comments__single">
        {!isFetching && comment ? (
          <>
            <CommentListItem comment={comment} context={context} readOnly />
            <Link href={detailsRoute} action="push" className="view-all">
              {t('comments.view_all_comments')}
            </Link>
          </>
        ) : (
          <Spinner />
        )}
      </div>

      <CommentsFooter context={context} message={message} isDisabled />
    </div>
  );
};

export default Comment;
