import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { contentSelectors } from '../../../models/content';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { useContentLikes } from '../../../common/use-content-likes';

import { Button } from '../../ui';
import { uiOperations } from '../../../models/ui';
import { ID as ViewLikesModalID } from './view-likes-modal/view-likes-modal';
import useFeatureFlag from '../../../common/use-feature-flag';
import useScreenSize from '../../../common/use-screen-size';
import { Feature } from '../../../models/features/features';

type ContentActionViewLikeProps = {
  contentId: number;
};

const ContentActionViewLike = ({ contentId }: ContentActionViewLikeProps) => {
  const dispatch = useDispatch();
  const likeCount = usePatronSelector((s) =>
    contentSelectors.getContentLikeCount(s, { contentId })
  );

  const tescoQ3 = useFeatureFlag(Feature.TESCO_Q3);
  const { isMobile } = useScreenSize();
  const compactLabel = tescoQ3 && isMobile;

  const { likes, isLoading } = useContentLikes(contentId);

  const { t } = useTranslation();

  const openViewLikesModal = () =>
    dispatch(uiOperations.displayOverlay(ViewLikesModalID, { contentId }));

  if (isLoading || likeCount === 0 || likes.length === 0) {
    return null;
  }

  let textLabel = 'content.liked_by_many';

  if (likeCount === 1) {
    textLabel = 'content.liked_by_one';
  } else if (likeCount === 2) {
    textLabel = 'content.liked_by_two';
  }

  if (compactLabel) {
    textLabel = 'content.liked_compact';
  }

  return (
    <Button
      theme="text"
      size="compact"
      aria-label={t('content.view_likes_action')}
      onClick={openViewLikesModal}
      className="content-action__view-likes"
    >
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        dangerouslySetInnerHTML={{
          __html: t(textLabel, {
            count: likeCount,
            name1: likes[0]?.name,
            name2: likes[1]?.name,
            remainder: likeCount - 2,
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </Button>
  );
};

export default ContentActionViewLike;
