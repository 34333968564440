import { useTranslation } from 'react-i18next';
import useContentFeed from '../../../../../common/use-content-feed';
import useRandomId from '../../../../../common/use-random-id';
import { timeAgoOrDate } from '../../../../../lib/date-formatter';
import {
  getContentAuthorAvatarColor,
  getContentAuthorAvatarSrc,
  getContentAuthorProfilePath,
  getContentDetailPath,
} from '../../../../../models/content/helpers';
import { Content } from '../../../../../models/content/types';
import { Avatar, Icon, Link, Spinner } from '../../../../ui';
import { TopicPageSidebarEmptyState } from '../../empty-states/topic-page-sidebar-empty-state';
import './topic-page-posts-preview.scss';

type TopicPagePostsPreviewProps = {
  channelId: string;
  count: number;
};

export function TopicPagePostsPreview({
  channelId,
  count,
}: TopicPagePostsPreviewProps) {
  const { t } = useTranslation();

  const { fetching, feedItems } = useContentFeed({
    channelId,
    perPage: count,
    pinned: true,
  });

  if (fetching) return <Spinner />;

  if (feedItems.length === 0) {
    return (
      <TopicPageSidebarEmptyState
        icon={<Icon type="article" />}
        details={t('screens.topic_page.no_posts.description')}
      />
    );
  }

  return (
    <>
      {feedItems.map((item) => (
        <PostPreviewCard key={item.id} content={item} />
      ))}
    </>
  );
}

type PostPreviewCardProps = {
  content: Content;
};

function PostPreviewCard({ content }: PostPreviewCardProps) {
  const { id: titleId } = useRandomId();
  const { t } = useTranslation();

  const authorProfileLink = getContentAuthorProfilePath(content.author_id);
  const authorName = content.source_name;
  const avatarSrc = getContentAuthorAvatarSrc(content);
  const avatarColor = getContentAuthorAvatarColor(content);

  const publishedAt = content.published_at;

  const contentLink = getContentDetailPath(content.id);

  return (
    <article
      className="topic-page-posts-preview"
      aria-labelledby={titleId}
      aria-describedby={
        content.pinned
          ? t('screens.topic_page.sidebar.posts_preview.pinned_description')
          : undefined
      }
    >
      <Link
        className="topic-page-posts-preview__author"
        rel="author"
        href={authorProfileLink}
        action="push"
      >
        <Avatar
          className="topic-page-posts-preview__author__pic"
          alt=""
          src={avatarSrc || undefined}
          bgColor={avatarColor || undefined}
        />
        <span className="topic-page-posts-preview__author__name">
          {authorName}
        </span>
      </Link>
      {content.pinned && (
        <Icon
          className="topic-page-posts-preview__pin"
          type="push_pin"
          size="base"
        />
      )}
      <time
        dateTime={publishedAt}
        className="topic-page-posts-preview__datetime"
      >
        {publishedAt ? timeAgoOrDate(publishedAt) : '-'}
      </time>
      <Link
        className="topic-page-posts-preview__title"
        href={contentLink}
        action="push"
      >
        <h3 id={titleId}>{content.title || content.description}</h3>
      </Link>
    </article>
  );
}
