import { useEffect, useState } from 'react';

export function useActiveElement() {
  const [activeElement, setActiveElement] = useState<Element | null>(
    document.activeElement
  );

  useEffect(() => {
    const onFocus = () => {
      setActiveElement(document.activeElement);
    };
    window.addEventListener('focusin', onFocus);
    return () => {
      window.removeEventListener('focusin', onFocus);
    };
  }, []);

  return activeElement;
}
