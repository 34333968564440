import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ComponentProps } from 'react';
import {
  CommentAssistantSuggestionSubtype,
  CommentAssistantSuggestionType,
} from '../../../../../models/comments/types';
import { Icon } from '../../../../ui';
import { CommentAssistantDropdownMainMenu } from '../base/main-menu';
import { CommentAssistantMenuItem } from '../base/menu-item';
import { CommentAssistantDropdownSubmenuContent } from '../base/sub-menu';
import { SuggestionSelectedCallback } from '../types';
import CommentAssistantButton from '../../comment-assistant-button';

type CommentAssistantDropdownMenuProps = {
  disabled: boolean;
  onSuggestionSelected: SuggestionSelectedCallback;
};

export function CommentAssistantDropdownMenu({
  disabled,
  onSuggestionSelected,
}: CommentAssistantDropdownMenuProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <CommentAssistantButton disabled={disabled} />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <CommentAssistantDropdownMainMenu
          onSuggestionSelected={onSuggestionSelected}
        >
          <CommentAssistantDropdownSubmenu
            name="change_tone"
            icon="mood"
            onSuggestionSelected={onSuggestionSelected}
          />
        </CommentAssistantDropdownMainMenu>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

type CommentAssistantDropdownSubmenuProps = {
  name: CommentAssistantSuggestionType;
  icon?: ComponentProps<typeof Icon>['type'];
  onSuggestionSelected: SuggestionSelectedCallback;
};

function CommentAssistantDropdownSubmenu({
  name,
  icon,
  onSuggestionSelected,
}: CommentAssistantDropdownSubmenuProps) {
  const menuName = name;

  const onSubmenuSelected = (name: CommentAssistantSuggestionSubtype) => {
    onSuggestionSelected({ suggestionType: menuName, suggestionSubtype: name });
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="comment-assistant-menu__list-item__wrapper">
        <CommentAssistantMenuItem name={name} icon={icon} expandable={true} />
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <CommentAssistantDropdownSubmenuContent
          onSelected={onSubmenuSelected}
        />
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
}
