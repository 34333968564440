import { connect } from 'react-redux';
import cx from 'classnames';

import { contentSelectors } from '../../../models/content/index.js';
import { languageDirection } from '../../../lib/language-helper';
import { RootPatronState } from '../../../common/use-patron-selector';
import { DangerousHTMLWithMentionsAndAutolinks } from '../../../components/ui/mention-text';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  contentId: number;
};

type ContentInfoTitleProps = StateProps & OwnProps;

const ContentInfoTitle = ({
  titleOrSummary: displayTitle,
  language,
}: ContentInfoTitleProps) => {
  const className = cx('content-info__title', {
    'content-info__title--small': displayTitle && displayTitle.length > 60,
  });

  return displayTitle ? (
    <div dir={languageDirection(language)} className={className}>
      <DangerousHTMLWithMentionsAndAutolinks text={displayTitle} />
    </div>
  ) : null;
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  titleOrSummary: contentSelectors.getContentTitleOrSummary(state, ownProps),
  language: contentSelectors.getContentLang(state, ownProps),
});

export default connect(mapStateToProps)(ContentInfoTitle);
