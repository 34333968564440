import {
  BigIconButton,
  PopoverClose,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from '@socialchorus/shared-ui-components';
import { ComponentPropsWithRef } from 'react';
import styles from './styles.module.scss';

export const DEFAULT_SHORTCUT_ICON =
  'https://lib.socialchorus.com/assistant/public/services/icons/QuickLink.png';

type BigIconButtonProps = ComponentPropsWithRef<typeof BigIconButton>;

export type ShortcutProp = BigIconButtonProps & {
  description?: string;
  links?: { url: string; label: string }[];
  href?: never;
};
/**
 * @deprecated, only use when Feature.NOTIFICATION_CENTER_ENABLED is disabled
 */
export function Shortcut({
  label,
  description,
  imgSrc,
  links,
  ...props
}: ShortcutProp) {
  if (links?.length === 1 || props.onClick !== undefined)
    return (
      <BigIconButton
        target="_blank"
        rel="noopener noreferrer"
        {...(props as BigIconButtonProps)}
        label={label}
        imgSrc={imgSrc || DEFAULT_SHORTCUT_ICON}
        title={description}
        href={links && links[0].url}
      />
    );

  return (
    <PopoverRoot>
      <PopoverTrigger asChild>
        <BigIconButton
          label={label}
          imgSrc={imgSrc || DEFAULT_SHORTCUT_ICON}
          title={description}
        />
      </PopoverTrigger>

      <PopoverContent
        className={styles.PopoverContainer}
        align="start"
        alignOffset={20}
      >
        <div className={styles.PopoverHeader}>
          <div className={styles.PopoverHeader__Texts}>
            <span className={styles.PopoverHeader__Texts__Subheading}>
              {label}
            </span>
            <span className={styles.PopoverHeader__Texts__Caption}>
              {description}
            </span>
          </div>
          <PopoverClose className={styles.PopoverCloseBtn} />
        </div>
        <hr className={styles.PopoverContent__Divider} />
        <div className={styles.PopoverContentLinks__Container}>
          {links?.map((link, idx) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={idx}
              className={styles.PopoverContentLinks}
            >
              {link.label}
            </a>
          ))}
        </div>
      </PopoverContent>
    </PopoverRoot>
  );
}
