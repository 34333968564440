class PasswordValidator {
  constructor(strongRequired = false) {
    this.strongRequired = strongRequired;
  }

  validate(password = '', rule) {
    let valid;

    switch (rule) {
      case 'minLength':
        valid = this.minLength(password);
        break;
      case 'includeLower':
        valid = this.includeLower(password);
        break;
      case 'includeUpper':
        valid = this.includeUpper(password);
        break;
      case 'includeNumber':
        valid = this.includeNumber(password);
        break;
      case 'includeSpecial':
        valid = this.includeSpecial(password);
        break;
      default:
        valid = this.allChecks(password);
    }

    return valid;
  }

  validateAll(password) {
    const errors = [];

    if (!this.minLength(password)) errors.push('minLength');

    if (!this.includeLower(password)) errors.push('includeLower');

    if (!this.includeUpper(password)) errors.push('includeUpper');

    if (!this.includeNumber(password)) errors.push('includeNumber');

    if (!this.includeSpecial(password)) errors.push('includeSpecial');

    return errors;
  }

  minLength(password) {
    return password.length >= 8;
  }
  includeLower(password) {
    return !this.strongRequired || !!password.match(/[a-z]/g);
  }
  includeUpper(password) {
    return !this.strongRequired || !!password.match(/[A-Z]/g);
  }
  includeNumber(password) {
    return !this.strongRequired || !!password.match(/[0-9]/g);
  }
  includeSpecial(password) {
    return (
      !this.strongRequired ||
      !!password.match(/[[\]{}()<>|/?!#@$%&~:;.,"'`^*+=]/g)
    );
  }
}

export default PasswordValidator;
