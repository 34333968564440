import styles from './error.module.scss';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { Button, Icon, IconButton } from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import useRandomId from '../../../../common/use-random-id';

interface FeedCardErrorProps {
  text?: string;
  details?: string;
  handleRetry: MouseEventHandler<HTMLButtonElement>;
  handleClose?: MouseEventHandler<HTMLButtonElement>;
}

function useDefaultErrorText() {
  const { t } = useTranslation();
  return {
    text: t('search.error.text'),
    details: t('search.error.details'),
  };
}

export function ErrorBanner({
  text,
  details,
  handleRetry,
  handleClose,
}: FeedCardErrorProps) {
  const { t } = useTranslation();
  const { text: defaultText, details: defaultDetails } = useDefaultErrorText();
  text = text || defaultText;
  details = details || defaultDetails;
  const { id } = useRandomId();
  const errorElId = `${id}_error-banner__label`;
  const errorDescriptionElId = `${id}_error-banner__description`;
  return (
    <div
      className={styles.contentsSearchErrorMessage}
      role="alert"
      aria-labelledby={errorElId}
      aria-describedby={errorDescriptionElId}
    >
      <div>
        <Icon aria-hidden="true" size={24}>
          warning
        </Icon>
      </div>
      <div className={styles.detailsContainer}>
        <p className={styles.message} id={errorElId}>
          {text}
        </p>
        {details && (
          <p id={errorDescriptionElId} className={styles.details}>
            {details}
          </p>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant="text"
          onClick={handleRetry}
          label={t('comments.comment_assistant.retry')}
        />
        {handleClose && (
          <IconButton
            size="compact"
            iconName="close"
            onClick={handleClose}
            aria-label={t('comments.comment_assistant.cancel')}
          />
        )}
      </div>
    </div>
  );
}

function useUnableToLoadText(entity: string) {
  const { t } = useTranslation();
  return {
    text: t('search.error.unable_to_load.text', { entity }),
    details: t('search.error.unable_to_load.details', { entity }),
  };
}

export function UnableToLoadError({
  entity,
  spacing = 'default',
}: {
  entity: string;
  spacing?: 'default' | 'compact';
}) {
  const { text, details } = useUnableToLoadText(entity);

  const { id } = useRandomId();
  const errorElId = `${id}_error-banner__label`;
  const errorDescriptionElId = `${id}_error-banner__description`;
  return (
    <div
      className={classNames(
        styles.unableToLoad,
        styles[`unableToLoad--${spacing}`]
      )}
      role="alert"
      aria-live="polite"
      aria-labelledby={errorElId}
      aria-describedby={errorDescriptionElId}
    >
      <div className={`${styles.childrenContainer}`}>
        <div className={styles.label}>
          <Icon>warning</Icon>
          <span id={errorElId}>{text}</span>
        </div>
        <span id={errorDescriptionElId} className={styles.description}>
          {details}
        </span>
      </div>
    </div>
  );
}
