import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import styles from './styles.module.scss';

type ModalCloseButtonProps = ComponentProps<typeof Button>;

export function ModalCloseButton(props: ModalCloseButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      icon="close"
      className={styles.close}
      aria-label={t('common.close')}
      {...props}
    />
  );
}
