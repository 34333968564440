import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { assistantSelectors } from '../../models/assistant';
import { trackAssistantInboxLoad } from '../../models/assistant/analytics';
import useAssistantCommand from '../../common/use-assistant-command';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';

import {
  AssistantInboxEmpty,
  AssistantCommandSelector,
} from '../../components/assistant';
import { markMessageAsRead } from '../../common/use-assistant-messages';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { AssistantError } from './assistant-error';
import { AssistantLandingItem } from '../../components/assistant/assistant-landing-item';
import { useTranslation } from 'react-i18next';
import { getProfileName } from '../../models/profile/selectors';
import AssistantContainer from './assistant-container';
import { RootPatronState } from '../../common/use-patron-selector';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../common/use-skip-to-content';

const MaintenanceMessageKey = 'assistant.maintenance_message';

export function AssistantLanding() {
  const assistantMascotUrl = useSelector(
    assistantSelectors.getAssistantMascotAsset
  );
  const inboxData = useSelector(assistantSelectors.inbox)?.[0];
  const dismissedMessages = useSelector(
    assistantSelectors.getDismissedMessages
  );
  const { isFetching, isError } = useAssistantFetcher({
    itemTypes: [AssistantDataType.INBOX, AssistantDataType.INBOX_COUNT],
  });
  const { runCommandInAssistant } = useAssistantCommand();

  const { t } = useTranslation();
  const statusRef = useRef<HTMLDivElement>(null);

  const maintenanceMessage = useSelector((state: RootPatronState) => {
    const message = getFeatureFlag(state, Feature.INBOX_MAINTENANCE) as string;
    if (!message) {
      return undefined;
    }

    const name = getProfileName(state);

    return name ? t(MaintenanceMessageKey, { name, message }) : message;
  });

  useEffect(() => {
    trackAssistantInboxLoad();
  }, []);

  const inboxItems = useMemo(() => {
    return inboxData?.subject?.inbox_items?.filter(
      (item) => dismissedMessages[item.id] === undefined
    );
  }, [inboxData, dismissedMessages]);

  // Fix up all the message nonsense
  useEffect(() => {
    if (!isFetching && inboxItems) {
      inboxItems.forEach((message) => {
        if (message.state === 'active') {
          markMessageAsRead(message.id, message.updated_at);
        } // Mark any current message as read when opening assistant
      });
    }
  }, [inboxItems, isFetching]);

  useEffect(() => {
    // Easier for user to navigate to the main content when they first reach this page
    statusRef.current?.focus();
  }, []);

  return maintenanceMessage || isError ? (
    <AssistantError error={maintenanceMessage} />
  ) : (
    <section
      className="assistant__landing assistant__search-view"
      aria-label={t('assistant.assistant_navigation.landing')}
    >
      {inboxData ? (
        <>
          <div className="assistant__search-pane">
            <div className="assistant__banner">
              <img
                alt="Assistant Mascot"
                className="assistant__mascot"
                src={assistantMascotUrl}
                role="presentation"
              />
              <div
                id={DEFAULT_SKIP_TO_CONTENT_ID}
                ref={statusRef}
                tabIndex={-1}
                className="assistant__speech"
              >
                {inboxData.text}
              </div>
            </div>
            {inboxItems && inboxItems.length > 0 ? (
              <AssistantContainer>
                {inboxItems.map((item, position) => (
                  <AssistantLandingItem
                    item={item}
                    key={item.text}
                    analyticsData={{ position }}
                  />
                ))}
              </AssistantContainer>
            ) : (
              <AssistantInboxEmpty />
            )}
          </div>
          <AssistantCommandSelector
            onSelect={(c) => {
              runCommandInAssistant(c, {
                location: 'inbox',
                ...c.tracking_context,
              });
            }}
          />
        </>
      ) : null}
    </section>
  );
}

export default AssistantLanding;
