import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionVideoUrl,
  submissionVideoUuid,
} from '../../models/content-submission/atoms';

import { Button, Icon } from '../../components/ui';

type VideoPreviewProps = {
  onReset?: () => void;
  isEdit?: boolean;
};

const VideoPreview = ({ onReset, isEdit }: VideoPreviewProps) => {
  const videoUrl = useRecoilValue(submissionVideoUrl);
  const videoUuid = useRecoilValue(submissionVideoUuid);

  const { t } = useTranslation();

  return (
    <div
      role="group"
      aria-label={t('content_submission.video_preview')}
      className="content-submission-video-preview"
    >
      {isEdit ? (
        <iframe
          allowFullScreen
          src={`/embed/video/${videoUuid}?autoplay=true`}
          title="Video Preview"
        />
      ) : (
        <>
          <video
            autoPlay
            muted
            loop
            controls
            preload="auto"
            aria-label={t('common.preview')}
          >
            <source src={videoUrl} />
          </video>

          {onReset ? (
            <Button
              onClick={onReset}
              aria-label={['common.delete', 'content_submission.video']
                .map(t)
                .join(' ')}
            >
              <Icon type="delete">delete</Icon>
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
};

export default VideoPreview;
