import styles from './search-enterprise.module.scss';
import { Container } from '@socialchorus/shared-ui-components';
import { useIntegrationSearchProvidersFetcher } from '../search-screen';
import { useRouteMatch } from 'react-router';
import { EnterpriseIcons } from './enterprise-icons/enterprise-icons';

export function IntegrationsBar() {
  const { integrationSearchProviders } = useIntegrationSearchProvidersFetcher();
  const { params } = useRouteMatch<{
    integrationId: string;
    command: string;
  }>();
  const { integrationId, command } = params;
  const integration = integrationSearchProviders.find(
    (integration) =>
      command &&
      integration.command === command &&
      integration.integration_id === Number(integrationId)
  );

  return (
    <Container shape="medium" shadow="light" className={styles.EnterpriseBar}>
      <div className={styles.EnterpriseBar__results}>
        <span
          className={`${styles.EnterpriseBar__integration} button button--pill`}
        >
          {integration && EnterpriseIcons[integration.command]}{' '}
          {integration?.label}
        </span>
      </div>
    </Container>
  );
}
