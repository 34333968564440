import { FC } from 'react';
import { usePatronSelector } from '../../../../../common/use-patron-selector';
import useScreenSize from '../../../../../common/use-screen-size';
import { getChannelById } from '../../../../../models/channels/selectors';
import { ChannelTabType } from '../../../../../models/channels/types';
import { Sidebar } from '../../sidebar';
import styles from './styles.module.scss';

interface Props {
  currentTab: ChannelTabType;
  topicId: string;
}
export const BaseTab: FC<Props> = ({ children, currentTab, topicId }) => {
  const topic = usePatronSelector((state) => getChannelById(state, topicId));
  const { isTabletOrLarger } = useScreenSize();

  const tabs = topic?.landing_page_tabs.map((e) => e.tab_type) || [];

  const showStats = tabs.includes(ChannelTabType.Posts); //stats are only shown if the posts tab is enabled
  const anyPreviewsToShow = tabs.length > 1 || showStats;

  // First tab is "home" and should have preview cards for all other tabs
  // we also only display the previews on tablet or larger screen sizes
  const hasPreview =
    tabs[0] === currentTab && isTabletOrLarger && anyPreviewsToShow;

  return (
    <div className={styles.container}>
      <div className={hasPreview ? styles.content : styles.contentNoSidebar}>
        {children}
      </div>
      {hasPreview && (
        <Sidebar
          topicId={topicId}
          currentTab={currentTab}
          tabs={tabs}
          showStats={showStats}
        />
      )}
    </div>
  );
};
