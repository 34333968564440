import PropTypes from 'prop-types';
import { Modal, Spinner } from '../ui';

const LoadingOverlay = ({ transparent }) => {
  return (
    <Modal transparent={transparent}>
      <Spinner />
    </Modal>
  );
};

LoadingOverlay.propTypes = {
  curtain: PropTypes.bool,
};

export const ID = 'Overlays.LOADING';

export default LoadingOverlay;
