import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { AssistantState } from '../models/assistant/selectors';
import { AdvocateProfile } from '../models/advocate/types';
import { BoxIntegrationReducerState } from '../models/box-integration/types';
import type { Channel } from '../models/channels/types';
import { Content } from '../models/content/types';
import type { Feature } from '../models/features/features';
import { Program } from '../models/program/types';
import { ProgramMembership } from '../models/program-membership/types';
import { UIState } from '../models/ui';

// We should create sensible default state and types in our reducers
// and let Redux handle this type using its inference
export type RootPatronState = {
  assistant: AssistantState;
  advocate: AdvocateProfile;
  appConfig: Record<string, string>;
  boxIntegration?: BoxIntegrationReducerState;
  channels: Record<number, Channel>;
  contents: Record<number, Content>;
  contentSubmission: Record<string, unknown>;
  features: { features: Record<Feature, string | boolean> };
  location: unknown;
  program: Program;
  programMembership: ProgramMembership;
  ui: UIState;
};

export const usePatronSelector: TypedUseSelectorHook<RootPatronState> =
  useSelector;
