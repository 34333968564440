type GenericObject = Record<string, unknown>;

export interface LocalStorageLib<T extends GenericObject = GenericObject> {
  getStorageKey: (key: string) => string;
  getStorageItem: (key: string, defaultVal: T) => T;
  setStorageItem: (key: string, value: T) => boolean;
  removeStorageItem: (key: string) => boolean;
}

export const localStorageEnabled = (): boolean => {
  const test = 'sc_ls_test';
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const getStorageItem = <T extends GenericObject>(
  key: string,
  defaultVal: T
): T => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultVal;
  } catch {
    return defaultVal;
  }
};

export const setStorageItem = <T extends GenericObject>(
  key: string,
  value: T
): boolean => {
  try {
    const item = JSON.stringify(value);
    window.localStorage.setItem(key, item);
    return true;
  } catch {
    return false;
  }
};

export const removeStorageItem = (key: string): boolean => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
};

const localStorageLib: LocalStorageLib = {
  getStorageKey: (key) => key,
  getStorageItem,
  setStorageItem,
  removeStorageItem,
};

export default localStorageLib;
