import cx from 'classnames';
import './password-form-dialog.scss';

const PasswordFormRulesItem = ({ text, hint, isValid }) => {
  const className = cx('password-change-rules__item', {
    'password-change-rules__item--valid': isValid,
  });

  return (
    <li className={className}>
      {text}

      {hint ? <span>{hint}</span> : null}
    </li>
  );
};

export default PasswordFormRulesItem;
