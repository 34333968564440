import cx from 'classnames';
import { ReactChild, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCommentSuggestions from '../../../common/use-comment-suggestions';
import { CommentAssistantSuggestionSelection } from '../../../models/comments/types';
import { Button, Icon, Spinner } from '../../ui';
import { Textarea } from '../../ui/text-input';
import useScreenSize from '../../../common/use-screen-size';
import {
  trackCommentSuggestionApply,
  trackCommentSuggestionCancel,
  trackCommentSuggestionRetry,
  trackCommentSuggestionStart,
} from '../../../models/comment-suggestions';

export const CommentAssistantSuggestion = ({
  comment,
  selectedSuggestion,
  onSuggestionChanged,
  children,
  onSuggestionClosed,
  contentId,
}: {
  comment: string;
  selectedSuggestion: CommentAssistantSuggestionSelection | null;
  children: ReactChild;
  onSuggestionChanged: (suggestion: string) => void;
  onSuggestionClosed: () => void;
  contentId: string;
}) => {
  const { isMobile } = useScreenSize();
  const { isFetching, errorMessage, suggestion, requestSuggestion } =
    useCommentSuggestions();

  const [originalText] = useState(comment);

  useEffect(() => {
    if (!isFetching && !errorMessage && !!suggestion) {
      onSuggestionChanged(suggestion);
    }
  }, [isFetching, errorMessage, suggestion, onSuggestionChanged]);

  const handleCancel = () => {
    onSuggestionChanged(originalText);
    onSuggestionClosed();
    trackCommentSuggestionCancel();
  };

  const handleApply = () => {
    onSuggestionClosed();
    trackCommentSuggestionApply();
  };

  const handleRetry = () => {
    requestSuggestion({ comment: originalText, selectedSuggestion, contentId });
    trackCommentSuggestionRetry();
  };

  const { t } = useTranslation();

  useEffect(() => {
    requestSuggestion({ comment: originalText, selectedSuggestion, contentId });

    if (selectedSuggestion) {
      trackCommentSuggestionStart({
        SuggestionType: selectedSuggestion.suggestionType,
        ...(selectedSuggestion.suggestionType === 'change_tone'
          ? {
              ToneType: selectedSuggestion.suggestionSubtype,
            }
          : undefined),
      });
    }
  }, [originalText, requestSuggestion, selectedSuggestion]);

  return (
    <div
      className={cx('comment-form__assistant-suggestion', {
        'mobile-view': isMobile,
      })}
    >
      <div className="comment-form__assistant-suggestion-container">
        <div className="comment-form__assistant-suggestion-label">
          {t('comments.comment_assistant.original')}
        </div>
        <Textarea
          disabled={true}
          aria-disabled={true}
          value={originalText}
          readOnly={true}
          className="comment-form__assistant-original-text"
        />
        <hr className="comment-form__assistant-suggestion-divider" />
        <div className="comment-form__assistant-suggestion-label">
          {t('comments.comment_assistant.suggested')}
        </div>
        {isFetching ? (
          <div className="comment-form__assistant-suggestion-spinner-container">
            <Spinner />
          </div>
        ) : errorMessage ? (
          <div className="comment-form__assistant-suggestion-failure">
            <Icon type="warning" />
            {errorMessage}
          </div>
        ) : (
          children
        )}
      </div>
      {!isMobile ? (
        <div className="comment-form__assistant-suggestion-actions">
          <Button
            theme="icon-only"
            className="comment-form__assistant-actions-retry"
            icon="refresh"
            aria-label={t('comments.comment_assistant.retry')}
            onClick={handleRetry}
            disabled={isFetching}
          />
          <Button
            theme="secondary"
            shape="round"
            className="comment-form__assistant-actions-cancel"
            onClick={handleCancel}
          >
            {t('comments.comment_assistant.cancel')}
          </Button>
          <Button
            theme="primary"
            shape="round"
            className="comment-form__assistant-actions-apply"
            icon="auto_awesome"
            onClick={handleApply}
            disabled={isFetching || !!errorMessage}
          >
            {t('comments.comment_assistant.apply')}
          </Button>
        </div>
      ) : (
        <div className="comment-form__assistant-suggestion-actions">
          <Button
            theme="secondary"
            shape="round"
            className="comment-form__assistant-actions-retry"
            icon="refresh"
            aria-label={t('comments.comment_assistant.retry')}
            onClick={handleRetry}
            disabled={isFetching}
          />
          <Button
            theme="secondary"
            shape="round"
            className="comment-form__assistant-actions-cancel"
            icon="close"
            aria-label={t('comments.comment_assistant.cancel')}
            onClick={handleCancel}
          />
          <Button
            theme="primary"
            shape="round"
            className="comment-form__assistant-actions-apply"
            icon="check"
            aria-label={t('comments.comment_assistant.apply')}
            onClick={handleApply}
            disabled={isFetching || !!errorMessage}
          />
        </div>
      )}
    </div>
  );
};
