import { useEffect, useState, useRef } from 'react';

const CircleTimer = ({
  children,
  reverse,
  duration = 10000,
  tick = 1000,
  color = 'black',
  onTick,
  onComplete,
}) => {
  const circumference = 2828; // circumference of a circle where D=900
  const baseOffset = reverse ? 0 : circumference;
  const completeProgress = baseOffset - circumference;

  const [offset, setOffset] = useState(baseOffset);
  const requestRef = useRef();
  const startTimeRef = useRef();
  const lastTickRef = useRef();

  const renderFrame = (time) => {
    if (!startTimeRef.current) startTimeRef.current = time;
    if (!lastTickRef.current) lastTickRef.current = time;

    const deltaTotal = time - startTimeRef.current; // time since timer started in ms
    const deltaTick = time - lastTickRef.current; // time since last tick
    const totalProgress = (deltaTotal / duration) * circumference;

    setOffset(parseInt(baseOffset - totalProgress));

    if (deltaTick >= tick && onTick) {
      lastTickRef.current = time;
      onTick(deltaTotal);
    }

    if (totalProgress < circumference) {
      requestRef.current = requestAnimationFrame(renderFrame);
    } else {
      setOffset(completeProgress);
      onComplete && onComplete();
    }
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(renderFrame);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="circle-timer">
      <svg
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
      >
        <circle
          style={{
            strokeDashoffset: offset,
            strokeDasharray: circumference,
          }}
          className="circle-timer__circle-animation"
          r="450"
          cy="500"
          cx="500"
          strokeWidth="75"
          stroke={color}
          fill="none"
        />
      </svg>
      <div className="circle-timer__label">{children}</div>
    </div>
  );
};

export default CircleTimer;
