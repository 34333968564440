import sample from 'lodash/sample';
import without from 'lodash/without';

export const rand = (arr = [], len = 1) => sample(arr, len);

export const notWithin = (arr = [], exclude = []) => without(arr, ...exclude);

export const randNotWithin = (arr, exclude) => rand(notWithin(arr, exclude));

export const hashVal = (str, len) => {
  const rawVal = str.split('').reduce((count, current) => {
    return count + current.charCodeAt(0);
  }, 0);

  return rawVal % len;
};
