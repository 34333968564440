import { useSelector } from 'react-redux';

const useProgramTheme = () => {
  const {
    // Maps to Studio UI "Primary Color" can only be black or White
    primary_color: primaryColor,

    // secondary_color Maps to "Accent Color" in Studio UI
    // Description: Brand complementary color, used for buttons and accent elements.
    // When it is not set, we set our default accent color.
    secondary_color: secondaryColor,

    // theme_color Maps to "Theme Color" in Studio UI
    // Description: Banner color for the web experience. When it is not set, we set our default theme color.
    theme_color: themeColor,

    wallpaper_image_url: wallpaperUrl,
    custom_font: { css_value: fontCssValue, url: fontUrl },
  } = useSelector((state) => state.program?.theme || { custom_font: {} });

  return {
    primaryTextColor: primaryColor || '#ffffff',
    themeColor: themeColor || '#448aff',
    accentColor: secondaryColor || '#1d232f',
    wallpaperUrl: wallpaperUrl || '/images/welcome-main.jpg',
    fontCssValue: fontCssValue,
    fontUrl: fontUrl,
  };
};

export default useProgramTheme;
