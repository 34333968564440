import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionReset,
  submissionVideoUrl,
  submissionImageUrls,
  submissionImages,
  submissionIsEdit,
  submissionIsSubmitting,
} from '../../../models/content-submission/atoms';

import DropzoneInput from '../inputs/dropzone';
import TitleInput from '../inputs/title';
import DescriptionInput from '../inputs/description';
import ImageInput from '../inputs/image';
import VideoInput from '../inputs/video';
import DropzoneOverlay from '../inputs/dropzone-overlay';
import ImagePreview from '../image-preview';
import VideoPreview from '../video-preview';

import {
  trackLoadVideoPreviewRemoveClick,
  trackLoadImagePreviewRemoveClick,
} from '../../../models/content-submission/analytics';
import { useSelector } from 'react-redux';

import { programSelectors } from '../../../models/program';

const VideoForm = () => {
  const [[imageUrl], setImageUrls] = useRecoilState(submissionImageUrls);
  const setImages = useSetRecoilState(submissionImages);
  const reset = useResetRecoilState(submissionReset);
  const videoUrl = useRecoilValue(submissionVideoUrl);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const videoMaxUploadBytes = useSelector(
    programSelectors.getMaxVideoUploadSizeInBytes
  );
  const uploadStage = !videoUrl ? 'video' : 'image';

  const { t } = useTranslation();

  const handleVideoReset = () => {
    trackLoadVideoPreviewRemoveClick();

    reset();
  };

  const handleImageReset = () => {
    trackLoadImagePreviewRemoveClick();

    setImages([]);
    setImageUrls([]);
  };

  const FILE_TYPE_PROPS = {
    video: {
      accept: 'video/mp4, video/quicktime',
      maxSize: Number(videoMaxUploadBytes) || 524288000, //500mb
      maxLength: 1,
    },
    image: {
      accept: 'image/jpeg, image/png',
      maxSize: 10485760, // 10mb
      maxLength: 2,
    },
  };

  return (
    <DropzoneInput {...FILE_TYPE_PROPS[uploadStage]} key={uploadStage}>
      {({
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        draggedFilesErrorMessage,
        droppedFilesErrorMessage,
      }) => (
        <div
          className="content-submission-form content-submission-form--video"
          {...getRootProps()}
          role="none"
          tabIndex={-1}
          onClick={null}
          onKeyDown={null}
        >
          {uploadStage === 'image' ? (
            <div className="content-submission-form__header">
              <div className="content-submission-form__text">
                <TitleInput
                  aria-label={t('content_submission.field_title_optional')}
                  placeholder={t('content_submission.field_title_optional')}
                />
                <DescriptionInput
                  aria-label={t(
                    'content_submission.field_description_optional'
                  )}
                  placeholder={t(
                    'content_submission.field_description_optional'
                  )}
                />
              </div>
              <div className="content-submission-form__cover-image">
                {!imageUrl ? (
                  <ImageInput
                    inputProps={!isSubmitting && getInputProps()}
                    openFileBrowser={open}
                    errorMessage={droppedFilesErrorMessage}
                  />
                ) : (
                  <ImagePreview
                    onReset={!isSubmitting && !isEdit && handleImageReset}
                  />
                )}
              </div>
            </div>
          ) : null}

          <div className="content-submission-form__body">
            {!videoUrl ? (
              <VideoInput
                inputProps={getInputProps()}
                openFileBrowser={open}
                errorMessage={droppedFilesErrorMessage}
              />
            ) : (
              <VideoPreview
                onReset={!isSubmitting && handleVideoReset}
                isEdit={isEdit}
              />
            )}
          </div>

          {isDragActive ? (
            <DropzoneOverlay
              placeholderText={
                !videoUrl
                  ? t('content_submission.field_video_placeholder')
                  : t('content_submission.field_image_placeholder')
              }
              errorText={draggedFilesErrorMessage}
            />
          ) : null}
        </div>
      )}
    </DropzoneInput>
  );
};

export default VideoForm;
