import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { channelOperations } from '../../models/channels';
import { getChannelById } from '../../models/channels/selectors';
import { Button, JoinChannelsIcon, JoinedChannelsIcon } from '../ui';
import {
  trackChannelFollow,
  trackChannelUnfollow,
} from '../../models/channels/analytics';
import './channel.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { RootPatronState } from '../../common/use-patron-selector';

type FollowButtonProps = {
  channelId: string;
  size?: 'small';
  analyticsData: unknown;
};
export const FollowButton = ({
  channelId,
  size = undefined,
  analyticsData,
}: FollowButtonProps) => {
  const following =
    useSelector((state: RootPatronState) => getChannelById(state, channelId))
      ?.following ?? false;
  const className = cx('channel__follow-button', {
    'channel__follow-button--following': following,
    'channel__follow-button--small': size === 'small',
    'pt-theme-bgcolor': !following && size !== 'small',
  });

  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (!following) {
      dispatch(channelOperations.followChannel(channelId));
      trackChannelFollow(channelId, analyticsData);
    } else {
      dispatch(channelOperations.unfollowChannel(channelId));
      trackChannelUnfollow(channelId, analyticsData);
    }
  }, [analyticsData, channelId, dispatch, following]);

  const followingKey = following
    ? newTopicsString
      ? 'screens.explore.following'
      : 'screens.explore.joined'
    : newTopicsString
    ? 'screens.explore.follow'
    : 'screens.explore.join';

  const followingText = t(followingKey);

  return (
    <Button
      iconComponent={following ? <JoinedChannelsIcon /> : <JoinChannelsIcon />}
      className={className}
      onClick={handleClick}
      aria-label={followingText}
      aria-pressed={following ? 'true' : 'false'}
    >
      <span className="text">{followingText}</span>
    </Button>
  );
};

export default FollowButton;
