import { createSelector } from 'reselect';
import { Feature, getFeatureFlag } from '../features/features';
import { RootPatronState } from '../../common/use-patron-selector';

const program = (state: RootPatronState) => state.program || {};
const newUIEnabled = (state: RootPatronState) =>
  getFeatureFlag(state, Feature.NEW_UI_ENABLED);

const getProgram = createSelector([program], (program) => program);
const getNewUIEnabled = createSelector([newUIEnabled], (enabled) => enabled);

const getProgramId = createSelector([getProgram], (program) => program.id);

const getProgramName = createSelector([getProgram], (program) => program.name);

const getProgramBrandId = createSelector(
  [getProgram],
  (program) => program.brand_id
);

const getProgramBrandName = createSelector(
  [getProgram],
  (program) => program.brand_name
);

const getProgramOrgSlug = createSelector(
  [getProgram],
  (program) => program.org_slug
);

const getProgramLoginUrl = createSelector(
  [getProgram],
  (program) => program.login_url
);

const getProgramSessionIsSSO = createSelector(
  [getProgram],
  (program) => program.session_is_sso
);

const getProgramSLOEnabled = createSelector(
  [getProgram],
  (program) => program.sp_initiated_slo_enabled
);

const getProgramLogoUrl = createSelector(
  [getProgram],
  (program) => program.logo_image_url
);

const getProgramHeaderUrl = createSelector(
  [getProgram],
  (program) => program.header_image_url
);

const getProgramThemeIcon = createSelector(
  [getProgram],
  (program) => program.theme.icon_image_url
);

const getProgramThemeColor = createSelector(
  [getProgram],
  (program) => program.theme.theme_color
);

const getProgramSecondaryColor = createSelector(
  [getProgram],
  (program) => program.theme.secondary_color
);

const getProgramWallpaper = createSelector(
  [getProgram],
  (program) => program.theme.wallpaper_image_url
);

const getProgramHiddenProfileFields = createSelector(
  [getProgram],
  (program) => program.hidden_profile_values || []
);

const getProgramStrongPasswordRequired = createSelector(
  [getProgram],
  (program) => !!program.strong_password_required
);

const getProgramPasswordValidations = createSelector(
  [getProgram],
  (program) => program.password_validations
);

const getProgramGdprComplianceEnabled = createSelector(
  [getProgram],
  (program) => !!program.gdpr_compliance_enabled
);

const getProgramGdprComplianceContacts = createSelector(
  [getProgram],
  (program) => program.gdpr_compliance_contacts
);

const getProgramCanSubmitContent = createSelector(
  [getProgram],
  (program) => !!program.allow_mobile_content_submission
);

const getProgramCanShareContent = createSelector(
  [getProgram],
  (program) => !!program.content_sharing_enabled
);

const getProgramCanCommentContent = createSelector(
  [getProgram],
  (program) => !!program.commenting_enabled
);

const getProgramCanTranslateContent = createSelector(
  [getProgram],
  (program) => !!program.content_translations_enabled
);

const getProgramResourcesEnabled = createSelector(
  [getProgram],
  (program) => !!program.resources_enabled
);

const getProgramInCaseYouMissedItEnabled = createSelector(
  [getProgram],
  (program) => !!program.content_digest_enabled
);

const getProgramFromTheCommunityEnabled = createSelector(
  [getProgram],
  (program) => !!program.from_the_community_enabled
);

const getV2NotesEnabled = createSelector(
  [getProgram],
  (program) => !!program.v2_notes_enabled
);

const getV2CommentPlaceholderEnabled = createSelector(
  [getProgram],
  (program) => !!program.v2_comment_placeholder_enabled
);

const getAssistantHomePageExperimentEnabled = createSelector(
  [getProgram],
  (program) => !!program.assistant_home_page_experiment_enabled
);

const getSearchBarInHeaderEnabled = createSelector(
  [getProgram, getNewUIEnabled],
  (program, newUIFlag) => !!newUIFlag || !!program.search_bar_in_header_enabled
);

const getDirectoryExtraFieldsEnabled = createSelector(
  [getProgram],
  (program) => !!program.directory_extra_fields_enabled
);

const getCommentThinkWarningEnabled = createSelector(
  [getProgram],
  (program) => !!program.comment_think_warning_enabled
);

const getCommentThinkWarningHtml = createSelector(
  [getProgram],
  (program) => program.comment_think_warning_html
);

const getCommentThinkWarningLink = createSelector(
  [getProgram],
  (program) => program.comment_think_warning_link
);

export const getSanitizedContentEnabled = createSelector(
  [getProgram],
  (program) => !!program.sanitized_content_enabled
);

const getSubmissionsCommentDefaultEnabled = createSelector(
  [getProgram],
  (program) => !!program.commenting_default_enabled
);

const getMaxVideoUploadSizeInBytes = createSelector(
  [getProgram],
  (program) => program.max_video_upload_size_in_bytes
);

const getSwitchCommunitiesEnabled = createSelector(
  [getProgram],
  (program) => !!program.switch_communities_enabled
);

const getImagesInArticlesEnabled = createSelector(
  [getProgram],
  (program) => !!program.images_in_articles_enabled
);

const getForYouFeedEnabled = createSelector(
  [getProgram],
  (program) => !!program.for_you_feed_enabled
);

const getEmailTokenAuthEnabled = createSelector(
  [getProgram],
  (program) => !!program.email_token_auth_enabled
);

const getAdvancedProfileEnabled = createSelector(
  [getProgram],
  (program) => !!program.advanced_profile_enabled
);

const getProfileEditNameEnabled = createSelector(
  [getProgram],
  (program) => !!program.profile_edit_name_enabled
);

//deprecated when new content detail is enabled
const getCommentSortEnabled = createSelector(
  [getProgram],
  (program) => !!program.comment_sort_enabled
);

const getFeedCardViewsEnabled = createSelector(
  [getProgram],
  (program) => !!program.feed_card_views_enabled
);

const getOrgChartEnabled = createSelector(
  [getProgram],
  (program) => !!program.org_chart_enabled
);

const getRelatedContentEnabled = createSelector(
  [getProgram],
  (program) => !!program.related_content_enabled
);

export default {
  getProgram,
  getProgramId,
  getProgramName,
  getProgramBrandId,
  getProgramBrandName,
  getProgramOrgSlug,
  getProgramLoginUrl,
  getProgramSessionIsSSO,
  getProgramSLOEnabled,
  getProgramLogoUrl,
  getProgramHeaderUrl,
  getProgramThemeIcon,
  getProgramThemeColor,
  getProgramSecondaryColor,
  getProgramWallpaper,
  getProgramHiddenProfileFields,
  getProgramStrongPasswordRequired,
  getProgramPasswordValidations,
  getProgramGdprComplianceEnabled,
  getProgramGdprComplianceContacts,
  getProgramCanSubmitContent,
  getProgramCanShareContent,
  getProgramCanCommentContent,
  getProgramCanTranslateContent,
  getProgramResourcesEnabled,
  getProgramInCaseYouMissedItEnabled,
  getProgramFromTheCommunityEnabled,
  getV2NotesEnabled,
  getV2CommentPlaceholderEnabled,
  getAssistantHomePageExperimentEnabled,
  getSearchBarInHeaderEnabled,
  getDirectoryExtraFieldsEnabled,
  getCommentThinkWarningEnabled,
  getCommentThinkWarningHtml,
  getCommentThinkWarningLink,
  getSanitizedContentEnabled,
  getSubmissionsCommentDefaultEnabled,
  getMaxVideoUploadSizeInBytes,
  getSwitchCommunitiesEnabled,
  getImagesInArticlesEnabled,
  getForYouFeedEnabled,
  getEmailTokenAuthEnabled,
  getAdvancedProfileEnabled,
  getProfileEditNameEnabled,
  getCommentSortEnabled,
  getFeedCardViewsEnabled,
  getOrgChartEnabled,
  getRelatedContentEnabled,
};
