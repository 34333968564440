import { Spinner, Icon } from '../ui';
import ContentItem from '../content-item';
import useContentFetcher from '../../common/use-content-fetcher';
import './assistant.scss';
import { Card } from '../../models/assistant/interfaces/subject/Summary';
import { AssistantLink } from './assistant-link';
import { trackContentCardClick } from '../../models/content/analytics';
import { usePatronSelector } from '../../common/use-patron-selector';
import { contentSelectors } from '../../models/content';

interface AssistantAcknowledgeContentProps {
  subject: Card;
  onAcknowledge?: () => void;
}

function AssistantAcknowledgeContent({
  subject,
  onAcknowledge,
}: AssistantAcknowledgeContentProps) {
  const contentId = subject.id ? Number(subject.id) : -1;
  const { content, fetching } = useContentFetcher({
    contentId: contentId,
  });

  const contentUrl = usePatronSelector((state) =>
    contentSelectors.getContentDetailUrl(state, { contentId: contentId })
  );

  return (
    <Spinner loading={fetching}>
      {content ? (
        <div className="assistant__acknowledge-content">
          <ContentItem
            contentId={subject.id}
            orientation="portrait"
            playInline
            size="small"
          />
          <div className="assistant__acknowledge-content__open">
            <AssistantLink
              to={contentUrl}
              onClick={() => {
                trackContentCardClick(Number(subject.id));
                if (onAcknowledge) {
                  onAcknowledge();
                }
              }}
            >
              <span>{subject.action!.navigation.title}</span>
              <Icon type="arrow_right_alt" />
            </AssistantLink>
          </div>
        </div>
      ) : null}
    </Spinner>
  );
}

export default AssistantAcknowledgeContent;
