import SaveActions from './save-actions';
import Channels from './channels';
import Settings from './settings';
import { ContentSubmissionTypes } from '../../services/content-submission';

type ContentSubmissionActionsProps = {
  onSubmit: (saveAs: ContentSubmissionTypes) => void;
};

const ContentSubmissionActions = ({
  onSubmit,
}: ContentSubmissionActionsProps) => {
  return (
    <div className="content-submission-actions">
      <SaveActions onSubmit={onSubmit} />
      <Channels />
      <Settings />
    </div>
  );
};

export default ContentSubmissionActions;
