import React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  submissionUrl,
  submissionTitle,
  submissionDescription,
  submissionReset,
  submissionIsEdit,
  submissionIsFetching,
  submissionIsSubmitting,
} from '../../../models/content-submission/atoms';

import { Button, Spinner, TextInput } from '../../../components/ui';

import { trackAddLinkPreviewRemoveClick } from '../../../models/content-submission/analytics';

const UrlInput = ({ onEnterPress }) => {
  const [url, setUrl] = useRecoilState(submissionUrl);
  const reset = useResetRecoilState(submissionReset);
  const title = useRecoilValue(submissionTitle);
  const description = useRecoilValue(submissionDescription);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isFetching = useRecoilValue(submissionIsFetching);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const isValid = url && (title || description);
  const canReset = isValid && !isSubmitting && !isEdit;

  const { t } = useTranslation();

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') onEnterPress();
  };

  // Submit on blur if not already submitted
  const handleBlur = () => {
    if (isValid || !url) return;

    onEnterPress();
  };

  const handleReset = () => {
    reset();

    trackAddLinkPreviewRemoveClick();
  };

  const className = cx('content-submission-input-url', {
    'content-submission-input-url--with-icon': isFetching || canReset,
  });

  return (
    <div className={className}>
      <TextInput
        name="url"
        value={url || ''}
        placeholder={t('content_submission.field_url_placeholder')}
        isDisabled={isValid || isFetching || isEdit}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />

      {isFetching ? (
        <Spinner />
      ) : canReset ? (
        <Button
          icon="close"
          onClick={handleReset}
          aria-label={t('common.close')}
        />
      ) : null}
    </div>
  );
};

export default UrlInput;
