import { ComponentProps, PropsWithChildren, ReactElement, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from './button';
import Modal from './modal';
import useEscapeToClose from '../../common/use-escape-to-close';

type ActionButtonProps = ComponentProps<typeof Button> & {
  text: string;
  onClick: () => void;
};

type DialogProps = PropsWithChildren<{
  title?: string | ReactElement;
  header?: string;
  accept?: ActionButtonProps;
  cancel?: ActionButtonProps;
  transparent?: boolean;
  className?: string;
}>;

const Dialog = ({
  title,
  header,
  accept,
  cancel,
  transparent,
  className,
  children,
}: DialogProps) => {
  const modalEl = useRef<HTMLDivElement>(null);
  useEscapeToClose(modalEl, () => cancel?.onClick());

  className = cx('dialog', className);

  return (
    <Modal
      className={className}
      transparent={transparent}
      ref={modalEl}
      aria-labelledby={children ? 'dialog--body' : 'dialog--header'}
    >
      <div id="dialog--header" className="dialog__header">
        {title ? <h2 className="dialog__title">{title}</h2> : header}
      </div>

      {children ? (
        <div id="dialog--body" className="dialog__body">
          {children}
        </div>
      ) : null}

      <div className="dialog__footer">
        {accept && typeof accept === 'object' ? (
          <Button
            theme="primary-legacy"
            size="small"
            {...accept}
            aria-label={accept.text}
          >
            {accept.text}
          </Button>
        ) : null}

        {cancel && typeof cancel === 'object' ? (
          <Button
            theme="link"
            size="small"
            {...cancel}
            aria-label={cancel.text}
          >
            {cancel.text}
          </Button>
        ) : null}
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  /** Title displays in the dialog header */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Object that contains instructions for building a `confirm` button action.
      Props provided will be passed to a `<Button>` component. This is
      typically passed an `onClick` attribute for a callback. */
  accept: PropTypes.object,
  /** Object that contains instructions for building a `cancel` button action.
      Props provided will be passed to a `<Button>` component. This is
      typically passed an `onClick` attribute for a callback. */
  cancel: PropTypes.object,
  /** Text or nodes or all kinds of goodies to appear in the dialog body */
  children: PropTypes.node,

  header: PropTypes.string,
  transparent: PropTypes.bool,
  className: PropTypes.string,
};

export const ID = 'Overlays.DIALOG';

/** @component */
export default Dialog;
