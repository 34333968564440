import { useTranslation } from 'react-i18next';
import { Icon, TrendingIcon } from '../ui';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';

const tagMap = {
  channels: {
    recommended: { textKey: 'recommended_channel', icon: <Icon type="star" /> },
    newest: { textKey: 'newest_channel', icon: <Icon type="update" /> },
    popular: { textKey: 'most_popular_channel', icon: <TrendingIcon /> },
  },
  topics: {
    recommended: { textKey: 'recommended_topic', icon: <Icon type="star" /> },
    newest: { textKey: 'newest_topic', icon: <Icon type="update" /> },
    popular: { textKey: 'most_popular_topic', icon: <TrendingIcon /> },
  },
};

const ChannelTag = ({ tag }) => {
  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const channelStringKey = newTopicsString ? 'topics' : 'channels';
  const { textKey, icon } = tagMap[channelStringKey][tag];
  const { t } = useTranslation();

  if (!textKey || !icon) return null;

  return (
    <div className="channel__tag">
      {icon}
      {t(`landing.${textKey}`)}
    </div>
  );
};

export default ChannelTag;
