import { useEffect } from 'react';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { getIntegration } from '../../../../models/assistant/selectors';
import { ShortcutModal } from './shortcut-modal';
import { trackAssistantIntegrationLoad } from '../../../../models/assistant/analytics';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router';

//this can be thought of a re-skin of assistant-integration.tsx
//this will be rendered as a modal over the page when the assistant integration route is navigated to.

export function IntegrationModal({
  history,
  match,
  location,
}: RouteComponentProps<{ integrationId: string }>) {
  //note: not managing loading of the integration state in this modal. Integration data is fetched on app load.
  const integration = usePatronSelector((state) =>
    getIntegration(state, { integrationId: match.params.integrationId })
  );

  useEffect(() => {
    const referer: string = get(location.state, 'referer', 'deeplink'); //copied from assistant-integration.tsx
    if (integration) {
      trackAssistantIntegrationLoad({
        service_name: integration.subject.title,
        integration_id: integration.id,
        location: referer,
      });
    }
  }, [integration, location.state]);

  const close = () => {
    history.goBack();
  };

  return (
    <ShortcutModal
      close={close}
      isLoading={!integration}
      description={integration?.subject.description}
      title={integration?.subject.title || ''}
      items={integration?.subject.buttons ?? []}
      id={integration?.id}
      coverImage={integration?.subject.banner_url}
    />
  );
}
