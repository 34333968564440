import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { trackProfileEditClick } from '../../models/profile/analytics';
import { profileSelectors } from '../../models/profile';
import { uiOperations } from '../../models/ui';
import AuthService from '../../services/auth';

import {
  Avatar,
  BookmarkIcon,
  ChannelsIcon,
  EditProfileIcon,
  Icon,
  PostIcon,
  ResetPasswordIcon,
  SideBarProfileIcon,
} from '../../components/ui';
import PageNav from '../../components/page-nav';

import { ID as AvatarFormDialogID } from '../../components/avatar-form-dialog/avatar-form-dialog';
import { ID as PasswordFormDialogID } from '../../components/password-form-dialog/password-form-dialog';
import { programSelectors } from '../../models/program';
import { programMembershipSelectors } from '../../models/program-membership';
import { Feature, getFeatureFlag } from '../../models/features/features';

const ProfileAvatar = ({
  url,
  alt,
  color,
  isEditing,
  isPrivate,
  handleAvatarChange,
}) => {
  const classes = cx('user-profile__avatar', {
    'user-profile__avatar--editing': isEditing,
  });

  const handleClick = () => {
    if (isEditing) {
      handleAvatarChange();
    }
  };

  const handleKeyDown = (e) => {
    if (!isEditing) {
      return;
    }

    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      className={classes}
      tabIndex={isEditing ? '0 ' : null}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
    >
      <Avatar
        className="user-profile__avatar__avatar"
        src={url}
        alt={alt}
        bgColor={color}
      />
      {isEditing && <Icon type="photo_camera" />}
      {isPrivate && !isEditing && <Icon type="lock" />}
    </div>
  );
};

const ProfileAboutFact = ({ label, value }) => {
  return (
    <div className="user-profile__about-item">
      <p className={'user-profile__about-item-label'}>{label}</p>
      <p className={'user-profile__about-item-value'}>{value}</p>
    </div>
  );
};

const ProfileSidebar = ({
  profile,
  profileId,
  isOwner,

  avatarUrl,
  avatarColor,
  isPrivate,
  isEditing,
  advancedProfileEnabled,
  getProgramMembershipCanEditPassword,
  displayOverlay,
  removeOverlay,
  analyticsData,
}) => {
  const { t } = useTranslation();
  const [resetPasswordActive, setResetPasswordActive] = useState(false);
  const [signOutActive, setSignOutActive] = useState(false);

  const activeAction = resetPasswordActive
    ? 'reset_password'
    : signOutActive
    ? 'sign_out'
    : undefined;

  const profileBaseUrl = isOwner ? '/account' : `/profiles/${profileId}`;

  const handleAvatarChange = () => {
    displayOverlay(AvatarFormDialogID, { avatarUrl: avatarUrl });
  };

  const handleEditButtonClick = () => {
    trackProfileEditClick(analyticsData);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setResetPasswordActive(true);
    displayOverlay(PasswordFormDialogID, {
      close: () => {
        removeOverlay({ id: PasswordFormDialogID });
        setResetPasswordActive(false);
      },
    });
  };

  const signOut = (e) => {
    e.preventDefault();
    setSignOutActive(true);
  };
  useEffect(() => {
    //wait for render
    if (signOutActive) {
      new AuthService().destroySession();
    }
  }, [signOutActive]);

  const navItems = [];

  if (advancedProfileEnabled) {
    navItems.push({
      title: t('screens.profile.view_profile.title'),
      url: `${profileBaseUrl}/view`,
      iconComponent: (
        <SideBarProfileIcon style={{ fontSize: '17px', marginRight: '6px' }} />
      ),
    });

    if (profile.orgChartEnabled) {
      navItems.push({
        title: t('screens.profile.organization.title'),
        url: `${profileBaseUrl}/organization`,
        icon: 'business',
      });
    }
  }
  if (isOwner) {
    navItems.push({
      title: t('screens.bookmarks.title'),
      url: `${profileBaseUrl}/bookmarks`,
      iconComponent: <BookmarkIcon />,
    });
  }

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const content_title = newTopicsString ? 'content.topics' : 'content.channels';

  navItems.push(
    ...[
      {
        title: t('assistant.contents'),
        url: `${profileBaseUrl}/submitted`,
        iconComponent: <PostIcon />,
      },
      {
        title: t(content_title),
        url: `${profileBaseUrl}/channels`,
        iconComponent: <ChannelsIcon />,
      },
    ]
  );

  const actionItems = [];
  if (isOwner) {
    actionItems.push({
      title: t('screens.profile.edit_profile.title'),
      url: `${profileBaseUrl}/edit`,
      onClick: handleEditButtonClick,
      iconComponent: <EditProfileIcon />,
      key: 'edit_profile',
    });

    if (getProgramMembershipCanEditPassword) {
      actionItems.push({
        title: t('password.reset'),
        onClick: handlePasswordChange,
        iconComponent: <ResetPasswordIcon />,
        key: 'reset_password',
      });
    }

    actionItems.push({
      title: t('common.sign_out'),
      onClick: signOut,
      icon: 'exit_to_app',
      key: 'sign_out',
    });
  }

  const renderBasicProfileFields = (profileFields) => {
    return (
      <div className={'user-profile__about'}>
        {profileFields.map((field) => (
          <ProfileAboutFact
            label={field.label}
            value={field.value}
            key={field.name}
            type={field.type}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="user-profile-sidebar">
      <div className="user-profile__card">
        <ProfileAvatar
          url={avatarUrl}
          alt={profile.name}
          color={avatarColor}
          isEditing={isEditing}
          isPrivate={isPrivate}
          handleAvatarChange={handleAvatarChange}
        />
        <h1 className="user-profile__name">{profile.name}</h1>
        {profile.pronouns && (
          <div className={'user-profile__pronouns'}>{profile.pronouns}</div>
        )}
      </div>

      {advancedProfileEnabled && (
        <div className="user-profile__status-message">
          {profile.statusMessage}
        </div>
      )}

      {renderBasicProfileFields(profile.profileFields)}

      {(isOwner || !isPrivate) && (
        <div className="user-profile__nav">
          <PageNav items={navItems} activeItemKey={activeAction} />
        </div>
      )}

      {isOwner ? (
        <div className="user-profile__actions">
          <PageNav
            title={t('navigation.account')}
            items={actionItems}
            activeItemKey={activeAction}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const data = ownProps.isOwner ? state : ownProps;

  return {
    profileId: profileSelectors.getProfileId(data),
    avatarUrl: profileSelectors.getProfileAvatarUrl(data),
    avatarColor: profileSelectors.getProfileAvatarColor(data),
    isPrivate: profileSelectors.getProfileIsPrivate(data),

    advancedProfileEnabled: programSelectors.getAdvancedProfileEnabled(state),
    getProgramMembershipCanEditPassword:
      programMembershipSelectors.getProgramMembershipCanEditPassword(state),
  };
};

const mapDispatchToProps = {
  displayOverlay: uiOperations.displayOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar);
