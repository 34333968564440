export const ASSISTANT_BUTTON_CLICK = 'w:Assistant:click';
export const ASSISTANT_INBOX_LOAD = 'w:AssistantInbox:load';
export const ASSISTANT_EXPLORE_LOAD = 'w:AssistantExplorer:load';
export const ASSISTANT_NOTIFICATIONS_LOAD = 'w:AssistantNotifications:load';
export const ASSISTANT_DIALOG_LOAD = 'w:AssistantDialog:load';
export const ASSISTANT_INTEGRATION_LOAD = 'w:AssistantServiceLanding:load';
export const ASSISTANT_RESOURCES_LOAD = 'w:AssistantResources:load';
export const ASSISTANT_INTEGRATION_LINK_CLICK = 'w:AssistantServiceLink:click';

export const ASSISTANT_SEARCH_CLICK = 'w:AssistantSearch:click';
export const ASSISTANT_EXAMPLE_CLICK = 'w:AssistantExample:click';
export const ASSISTANT_SEARCH_TYPE = 'w:AssistantSearch:type';
export const ASSISTANT_SEARCH_SUCCESS = 'w:AssistantSearch:success';
export const ASSISTANT_SEARCH_FAIL = 'w:AssistantSearch:fails';

export const ASSISTANT_INBOX_CLICK = 'w:AssistantInbox:click';
export const ASSISTANT_INBOX_ITEM_VIEW = 'w:AssistantInbox:Item:view';
export const ASSISTANT_SMART_NOTIF_CLICK = 'w:SmartNotif:click';
export const ASSISTANT_SMART_NOTIF_DISMISS = 'w:SmartNotif:dismiss';

export const ASSISTANT_EXPLORE_CLICK = 'w:AssistantExplorer:click';
export const ASSISTANT_QUICK_ACTION_CLICK = 'w:AssistantQuickAction:click';
export const ASSISTANT_ACTIONS_CLICK = 'w:AssistantActions:click';
export const ASSISTANT_ACTIONS_VIEW = 'w:AssistantActions:view';

export const NOTIFICATIONS_HISTORY_CLICK = 'w:AssistantNotifications:click';
export const NOTIFICATIONS_HISTORY_CARD_VIEW =
  'w:AssistantNotifications:Card:view';
export const NOTIFICATIONS_HISTORY_CARD_CLICK =
  'w:AssistantNotifications:Card:click';

export const ASSISTANT_RESOURCE_VIEW = 'w:Resource:view';
export const ASSISTANT_RESOURCE_CLICK = 'w:Resource:click';

export const ASSISTANT_DIALOG_CLICK = 'w:AssistantDialog:click';
export const ASSISTANT_COMMAND_RUN = 'w:AssistantCommand:run';

export const ASSISTANT_COMMAND_SUMMARY_RESPONSE_VIEW =
  'w:AssistantCommandSummaryResponse:view';
export const ASSISTANT_COMMAND_SUMMARY_RESPONSE_CLICK =
  'w:AssistantCommandSummaryResponse:click';

export const ASSISTANT_COMMAND_DETAIL_VIEW_LOAD =
  'w:AssistantCommandDetailView:load';

export const ASSISTANT_COMMAND_CONTENT_RESPONSE_VIEW =
  'w:AssisantCommandContentResponse:view';
export const ASSISTANT_COMMAND_CONTENT_RESPONSE_CLICK =
  'w:AssisantCommandContentResponse:click';

export const ASSISTANT_COMMAND_CHANNEL_RESPONSE_VIEW =
  'w:AssisantCommandChannelResponse:view';
export const ASSISTANT_COMMAND_CHANNEL_RESPONSE_CLICK =
  'w:AssisantCommandChannelResponse:click';

export const ASSISTANT_COMMAND_LINK_RESPONSE_VIEW =
  'w:AssisantCommandLinkResponse:view';
export const ASSISTANT_COMMAND_LINK_RESPONSE_CLICK =
  'w:AssistantCommandLinkResponse:click';

export const ASSISTANT_NEEDS_ATTENTION_CARD_VIEW = 'w:NeedsAttentionCard:view';
export const ASSISTANT_NEEDS_ATTENTION_CARD_CLICK =
  'w:NeedsAttentionCard:click';

export const ASSISTANT_COMMAND_POLL_RESPONSE_VIEW =
  'w:AssistantCOmmandPollResponse:view';
