import api from '../config/api';
import { InitiativeTag } from '../models/initiative/types';
import { ResourceObject } from '../lib/types/json-api';

type ConstructorParams = {
  query?: string;
  ids?: string[];
};

type FetchParams = {
  page?: number;
  perPage?: number;
};

type FetchResponse = {
  data: Required<
    Omit<ResourceObject<'initiative_tag', InitiativeTag>, 'relationships'>
  >[];
};

class InitiativeTagService {
  ids?: string[];
  query?: string;

  constructor({ query, ids }: ConstructorParams = {}) {
    this.ids = ids;
    this.query = query;
  }

  fetchAll = () => {
    return api.get<FetchResponse>(this.url);
  };

  fetch = ({ page, perPage }: FetchParams = {}) => {
    return api.get<FetchResponse>(this.url, {
      params: {
        query: this.query,
        ids: this.ids,
        page_size: perPage,
        page,
      },
    });
  };

  get url() {
    return '/initiative_tags';
  }
}

export default InitiativeTagService;
