import { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import PollResults from './assistant-poll-results';
import PollAnswers from './assistant-poll-answers';
import AssistantService from '../../services/assistant';
import './assistant.scss';
import cx from 'classnames';
import { timeTo } from '../../lib/date-formatter';
import {
  Card,
  subjectTypes,
} from '../../models/assistant/interfaces/subject/Summary';
import { SubjectItem } from '../../models/assistant/interfaces/SubjectItem';
import { Actionable } from '../../models/assistant/interfaces/Actionable';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

interface AssistantPollProps {
  subject: Card | SubjectItem;
  onAcknowledge?: () => void;
  setItemLinkEnabled?: (enabled: boolean) => void;
}

function subjectIsCard(subject: Card | SubjectItem): subject is Card {
  return Object.keys(subjectTypes).includes(subject.type);
}

function AssistantPoll({ subject, setItemLinkEnabled }: AssistantPollProps) {
  const [subj, setSubject] = useState(subject);
  const { pushMessage } = usePushToastOrFlashMessage();
  const { t } = useTranslation();

  const pollClosed = moment().isAfter(subj.expired_at); // Poll expired in the past

  const handleSubmit = (action: Actionable, answers: Array<string>) => {
    if (answers.length) {
      new AssistantService()
        .handleRequest(action.request, { answers })
        .then((res) => {
          setSubject(res.data.data.subject);

          //when results are shown, we want to disable the link to the poll, as answered polls are not accessible to the user.
          setItemLinkEnabled && setItemLinkEnabled(false);
        })
        .catch((err) => {
          console.error('error submitting assistant poll', err);
          pushMessage({
            text: t('errors.default'),
            type: 'error',
          });
        });
    }
  };

  useEffect(() => {
    subjectIsCard(subj) &&
      subj.buttons?.forEach((button) => {
        button.image && (new Image().src = button.image.url);
        button.selected &&
          button.selected.image &&
          (new Image().src = button.selected.image.url);
      });
  }, [subj]);

  const pollResults = subjectIsCard(subj)
    ? subj.poll_result
    : subj.poll_results;

  const selectionType = subjectIsCard(subj) ? subj.selection_type : undefined;

  return (
    <div className="assistant__poll" role="group" aria-label={subj.title}>
      <div className="assistant__poll__title" aria-hidden>
        {subj.title}
      </div>
      <div className={cx(['assistant__poll__answers'], selectionType)}>
        {pollResults ? (
          <PollResults results={pollResults} poll_type={selectionType || ''} />
        ) : subjectIsCard(subj) && subj.buttons ? (
          <PollAnswers subject={subj} handleSubmit={handleSubmit} />
        ) : null}
        <div className="description">
          {subj.description && (
            <>
              <span>{subj.description}</span>
              <span> &middot; </span>
            </>
          )}
          <span>
            {pollClosed ? (
              t('common.closed')
            ) : (
              <span>
                {' '}
                {t('datetime.time_left', {
                  interval: timeTo(subj.expired_at),
                })}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AssistantPoll;
