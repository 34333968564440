import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, LikeIcon, LikeIconOutline } from '../../ui';

import {
  trackContentCardLike,
  trackContentCardUnlike,
} from '../../../models/content/analytics';
import { useDispatch } from 'react-redux';
import { contentOperations, contentSelectors } from '../../../models/content';
import Tooltip from '../../tooltip/tooltip';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { useSWRConfig } from 'swr';
import { getContentLikesDataKey } from '../../../common/use-content-likes';

interface IContentActionLike {
  liked: boolean;
  likeCount: number;
  onClick: MouseEventHandler;
  contentTitle: string;
  hideEmptyCount?: boolean;
  showText?: boolean;
}

const ContentActionLike: React.FC<IContentActionLike> = ({
  liked,
  likeCount,
  onClick,
  contentTitle,
  hideEmptyCount = false,
  showText = false,
}) => {
  const className = cx('content__action', 'content__action--like', {
    'content__action--liked': liked,
    'content__action--active': liked,
    'content__action--empty': !likeCount,
  });

  const { t } = useTranslation(undefined, { useSuspense: false });

  const likeActionKey = liked
    ? 'content.like_remove_action'
    : 'content.like_action';
  const actionLabel = t(likeActionKey, { title: contentTitle });

  return (
    <div className={className}>
      {showText ? (
        <Button
          theme="text"
          size="compact"
          onClick={onClick}
          aria-label={actionLabel}
        >
          <div className="icon-animation-container">
            <LikeIconOutline />
            <LikeIcon />
          </div>

          <span>
            {liked ? t('content.liked') : t('content.like', { count: 1 })}
          </span>
        </Button>
      ) : (
        <Tooltip
          text={
            liked ? t('content.like_remove') : t('content.like', { count: 1 })
          }
        >
          <Button
            theme="text"
            size="compact"
            onClick={onClick}
            aria-label={actionLabel}
          >
            <div className="icon-animation-container">
              <LikeIconOutline />
              <LikeIcon />
            </div>

            {likeCount || !hideEmptyCount ? (
              <span className="count">{likeCount}</span>
            ) : null}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

type ContentActionLikeWrapperProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  showText?: boolean;
};

const ContentActionLikeWrapper = ({
  contentId,
  analyticsData,
  showText,
}: ContentActionLikeWrapperProps) => {
  const isLiked = usePatronSelector((s) =>
    contentSelectors.getContentLiked(s, { contentId })
  );
  const likeCount = usePatronSelector((s) =>
    contentSelectors.getContentLikeCount(s, { contentId })
  );
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const likeContent = (id: number) =>
    dispatch(contentOperations.likeContent(id));
  const unlikeContent = (id: number) =>
    dispatch(contentOperations.unlikeContent(id));

  const handleClick = async () => {
    if (isLiked) {
      trackContentCardUnlike(contentId, analyticsData);
      await unlikeContent(contentId);
    } else {
      trackContentCardLike(contentId, analyticsData);
      await likeContent(contentId);
    }
    // Trigger revalidation on the content likes list
    void mutate(getContentLikesDataKey(contentId));
  };

  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, { contentId })
  );
  return (
    <ContentActionLike
      liked={isLiked}
      likeCount={likeCount}
      onClick={handleClick}
      contentTitle={contentTitle}
      showText={showText}
    />
  );
};

export { ContentActionLike };

export default ContentActionLikeWrapper;
