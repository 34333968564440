import { atom, DefaultValue, selectorFamily } from 'recoil';

export const aboutPageState = atom<{
  [key: string]: AboutPage | DefaultValue | null;
}>({
  key: 'topicAboutPageState',
  default: {},
});

type AboutPage = {
  pageContent: string;
  previewContent: string;
};
export const aboutPageByChannelId = selectorFamily<AboutPage | null, string>({
  key: 'topicAboutPageSelector',
  get:
    (channelId: string) =>
    ({ get }) => {
      return (get(aboutPageState)[channelId] as AboutPage) || null;
    },
  set:
    (channelId: string) =>
    ({ set, get }, aboutPage: DefaultValue | AboutPage | null) => {
      const store = get(aboutPageState);
      const newStore = {
        ...store,
        [channelId]: aboutPage,
      };

      if (aboutPage !== null) {
        set(aboutPageState, newStore);
      }
    },
});
