import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePatronSelector } from '../../../../../../common/use-patron-selector';
import { contentSelectors } from '../../../../../../models/content';
import { trackContentCardComment } from '../../../../../../models/content/analytics';
import Tooltip from '../../../../../tooltip/tooltip';
import { FeedCardActionsProps } from './type';

export function FeedCardActionComment({
  content,
  analyticsData,
}: FeedCardActionsProps) {
  const commentCount = usePatronSelector((s) =>
    contentSelectors.getContentCommentCount(s, { contentId: content.id })
  );
  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, { contentId: content.id })
  );
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    const commentsHash = '#comments';
    const targetPath = `/contents/${content.id}`;

    history.push({
      pathname: targetPath,
      hash: commentsHash,
    });

    trackContentCardComment(content.id, analyticsData);
  };

  const actionLabel = t('content.comment_action', { title: contentTitle });

  return (
    <Tooltip text={t('content.comment')}>
      <Button
        aria-label={actionLabel}
        label={String(commentCount)}
        leftIcon={<Icon size={20}>chat_bubble</Icon>}
        size="compact"
        variant="text"
        onClick={handleClick}
      />
    </Tooltip>
  );
}
