import { connect } from 'react-redux';
import { RootPatronState } from '../../common/use-patron-selector';
import { Message } from '../../models/messages/types';
import { programSelectors } from '../../models/program';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  messageId: Message['id'];
};

type MessageFrameProps = StateProps & OwnProps;

const MessageFrame = ({ messageId, programId }: MessageFrameProps) => {
  const iframeMessageSrc = `/embed/messages/${messageId}?program=${programId}`;

  return (
    <div className="message-frame">
      <iframe allowFullScreen src={iframeMessageSrc} title={messageId} />
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  programId: programSelectors.getProgramId(state),
});

export default connect(mapStateToProps, null)(MessageFrame);
