import { Key } from 'ts-keycode-enum';

export function manageIsTabbingOnBody() {
  window.addEventListener('keydown', (e) => {
    if (e.type === 'keydown' && e.keyCode === Key.Tab)
      document.body.classList.add('is-tabbing');
  });

  window.addEventListener('click', (e) => {
    if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
      document.body.classList.remove('is-tabbing');
    }
  });
}
