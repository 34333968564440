import useProgramTheme from '../../common/use-program-theme';
import { NavLink, StyleTag } from '../ui';
import './secondary-nav.scss';

const SecondaryNav = ({ navItems }) => {
  const { accentColor } = useProgramTheme();

  return (
    <>
      <StyleTag>{`
        .secondary-nav__link.is-active {
          color: ${accentColor};
        }

        .secondary-nav__link::after {
          background-color: ${accentColor};
        }
      `}</StyleTag>

      <nav className="secondary-nav">
        {navItems.map((item) => (
          <div className="secondary-nav__item" key={item.title}>
            <NavLink
              href={item.url}
              exact
              className="secondary-nav__link"
              activeClassName="is-active"
            >
              {item.title}
            </NavLink>
          </div>
        ))}
      </nav>
    </>
  );
};

export default SecondaryNav;
