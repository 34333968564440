import { useParams } from 'react-router';
import useTopicAboutPageFetcher from '../../../../../common/use-topic-about-page-fetcher';
import { Spinner } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import useIframeResize from '../../../../../common/useIframeResize';

export function TopicPageAbout({ isHidden }: TopicPageTabComponent) {
  const { channelId } = useParams<{ channelId: string }>();
  const { aboutPage, isFetching, isError } = useTopicAboutPageFetcher({
    topicId: channelId,
  });

  const { t } = useTranslation();
  const errorMessage = t('screens.topics.about.error');

  const iframeStyles = `
  body {
    margin: 0;
    padding: 1px 0;
  }
  img {
    max-width: 100%;
  }
`;

  const { height, ref: iframeRef } = useIframeResize({
    styles: iframeStyles,
    iframeIsVisible: isHidden,
  });

  return (
    <div className="content-card">
      {isError && !isFetching && <div>{errorMessage}</div>}
      {isFetching && <Spinner />}
      {!isFetching && (
        <iframe
          srcDoc={aboutPage}
          ref={iframeRef}
          title="about-page"
          className="about-page-iframe"
          style={height !== undefined ? { height: `${height}px` } : undefined}
          height={height}
        />
      )}
    </div>
  );
}
