import { useTranslation } from 'react-i18next';

import FeedHeader, { FeedHeaderProps } from './feed-header';
import { ContentCard, ContentCardProps } from '../content-card/content-card';
import { Content } from '../../../models/content/types';
import './content-layouts.scss';

type ContentLayoutHeroProps = FeedHeaderProps & {
  contents: Content[];
  description: string;
  feedUrl: string;
  viewAllText: string;
  isTopic: boolean;
  contentTitleTag?: ContentCardProps['titleTag'];
};

const ContentLayoutHero = ({
  id,
  contents,
  analyticsData,
  title,
  description,
  feedUrl,
  viewAllText,
  isTopic = false,
  contentTitleTag = 'h2',
}: ContentLayoutHeroProps) => {
  const heroContent = contents[0];
  const sidecardContents = contents.slice(1, 4);
  const { t } = useTranslation();

  return (
    <>
      <FeedHeader
        id={id}
        title={title}
        description={description}
        viewAllLink={feedUrl}
        viewAllText={viewAllText || `${t('assistant.view_all')} ${title}`}
        analyticsData={analyticsData}
        isTopic={isTopic}
      />
      <div className={'content-layout__hero'}>
        <div className={'content-layout__hero-card'}>
          <ContentCard.Vertical
            titleTag={contentTitleTag}
            content={heroContent}
            analyticsData={analyticsData}
            inlineVideo={true}
            largeTitleFont={true}
          />
        </div>
        <div className={'content-layout__hero-sidecards'}>
          {sidecardContents.map((content) => (
            <ContentCard.Horizontal
              titleTag={contentTitleTag}
              content={content}
              analyticsData={analyticsData}
              key={content.id}
              compact={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ContentLayoutHero;
