import useSWR from 'swr';
import api from '../../config/api';
import { ResourceObject } from '../../lib/types/json-api';
import { Content } from '../../models/content/types';

export type CustomSlug = {
  id: number;
  program_id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  content_id: number;
  content: Pick<Content, 'title'>;
};

export type CustomSlugsResponse = {
  data: ResourceObject<'content_custom_slug', CustomSlug>[];
};

const fetcher = (url: string) =>
  api.get<CustomSlugsResponse>(url).then((res) => res.data);

type UseCustomSlugOptions = {
  enabled: boolean;
};
const defaultUseCustomSlugOptions: UseCustomSlugOptions = {
  enabled: true,
};
export function useCustomSlug(
  slug: CustomSlug['slug'],
  options: UseCustomSlugOptions = defaultUseCustomSlugOptions
) {
  const { enabled } = options;
  return useSWR(enabled ? ['/custom_slugs', slug] : null, ([endpoint, slug]) =>
    fetcher(`${endpoint}?slug=${slug}`)
  );
}
