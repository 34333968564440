import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackCommentEvent } from '../../../../../models/comments/analytics';
import { Comment } from '../../../../../models/comments/types';
import {
  likeComment as likeCommentService,
  unlikeComment as unlikeCommentService,
} from '../../../../../services/comment';

type CommentActionLikeProps = {
  comment: Comment;
};

export function CommentActionLike({ comment }: CommentActionLikeProps) {
  const { t } = useTranslation();

  const [liked, setLiked] = useState(comment.isLiked);
  const [canLike, setCanLike] = useState(comment.canLike);
  const [likeCount, setLikeCount] = useState(comment.likeCount);

  const likeComment = () => {
    if (!canLike) return;

    trackCommentEvent('like', comment, !!comment.replyToId);

    likeCommentService(comment.contentId, comment.id);

    setLiked(true);
    setCanLike(false);
    setLikeCount(likeCount + 1);
  };

  const unlikeComment = () => {
    trackCommentEvent('unlike', comment, !!comment.replyToId);

    unlikeCommentService(comment.contentId, comment.id);

    setLiked(false);
    setCanLike(true);
    setLikeCount(Math.max(0, likeCount - 1));
  };

  const emptyLikes = likeCount == 0;

  return (
    <Button
      size="compact"
      variant="text"
      selected={liked}
      label={`${emptyLikes ? '' : likeCount}`}
      leftIcon={<Icon size={20}>thumb_up</Icon>}
      aria-label={
        canLike
          ? t(liked ? 'content.like_remove' : 'content.like_one')
          : undefined
      }
      onClick={liked ? unlikeComment : likeComment}
    />
  );
}
