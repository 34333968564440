import React from 'react';
import cx from 'classnames';

import ChannelCard from '../../components/channel-card';
import ContentList from '../../components/content-list';
import ContentCardPlaceholder from '../../components/placeholders/content-card-placeholder';

const HomeChannelFeed = ({
  title,
  channelId,
  contents,
  minLength,
  maxLength,
  isFetching,
  analyticsData,
}) => {
  const contentIds = contents.map((c) => c.id);

  const className = cx('home-channel-feed', {
    'home-channel-feed--loading': isFetching,
  });

  if (!isFetching && contents.length < minLength) return null;

  return (
    <div className={className}>
      <div className="section-heading">
        <h2 className="section-title">{title}</h2>
      </div>

      <div className="channel-content">
        <ChannelCard channelId={channelId} analyticsData={analyticsData} />

        {isFetching ? (
          <div className="content-list content-list--landscape">
            {[...Array(maxLength)].map((_, idx) => (
              <ContentCardPlaceholder key={idx} />
            ))}
          </div>
        ) : (
          <ContentList
            contentIds={contentIds}
            limit={maxLength}
            analyticsData={analyticsData}
          />
        )}
      </div>
    </div>
  );
};

export default HomeChannelFeed;
