import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { advocateSelectors } from '../../models/advocate';
import { trackProfileLoad } from '../../models/profile/analytics';

import { Avatar, Spinner } from '../../components/ui';
import Page, { PageSidebar, PageMain } from '../../components/ui/page';
import ProfileSidebar from './profile-sidebar';
import ProfileBookmarks from './profile-bookmarks';
import ProfileSubmitted from './profile-submitted';
import ProfileChannels from './profile-channels';
import ProfileEdit from './profile-edit';
import ProfilePrivateCard from '../../components/profile-card/profile-card-private';
import NotFound from '../not-found';

import withRerenderPrevent from '../../common/with-rerender-prevent';
import useScreenTitle from '../../common/use-screen-title';
import useProfileFetcher from '../../common/use-profile-fetcher';

import './profile.scss';
import ProfileView from './profile-view';
import ProfileOrganization from './profile-organization';
import useScreenSize from '../../common/use-screen-size';
import { Label } from '../../components/ui/text-input';
import { AdvocateProfile } from '../../models/advocate/types';
import { RootPatronState } from '../../common/use-patron-selector';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = RouteComponentProps<{ profileId: string; page: string }>;

type ProfileProps = StateProps & OwnProps;

const Profile = ({ advocateId, match: { params } }: ProfileProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  useScreenTitle(t('screens.account.profile.title'));

  const profileId = parseInt(params.profileId, 10) || advocateId;

  const isOwner = profileId === advocateId;

  const analyticsData = {
    advocate_id: profileId,
    is_owner: isOwner,
    location: 'profile',
  };

  const { profile, profileGroups, isFetching, isNotFound, syncAdvocate } =
    useProfileFetcher({ profileId });
  const isPrivate = !!profile?.privateProfile;

  useEffect(() => {
    trackProfileLoad(analyticsData);
  }, [profileId]);

  if (isNotFound) return <NotFound />;

  return (
    <Page className="user-profile">
      {!isFetching && profile ? (
        <>
          <PageSidebar>
            <ProfileSidebar
              profile={profile}
              isOwner={isOwner}
              isEditing={params.page === 'edit'}
              analyticsData={analyticsData}
            />
          </PageSidebar>
          <PageMain>
            {isPrivate && !isOwner ? (
              <ProfilePrivateCard />
            ) : (
              <>
                {(params.page === 'view' && (
                  <>
                    {isMobile && profile && (
                      <ProfileMobileCard profile={profile} />
                    )}
                    <ProfileView
                      profileId={profileId}
                      profileGroups={profileGroups}
                    />
                  </>
                )) ||
                  (params.page === 'bookmarks' && isOwner && (
                    <ProfileBookmarks
                      profileId={profileId}
                      isOwner={isOwner}
                      analyticsData={analyticsData}
                    />
                  )) ||
                  (params.page === 'submitted' && (
                    <ProfileSubmitted
                      profileId={profileId}
                      isOwner={isOwner}
                      analyticsData={analyticsData}
                    />
                  )) ||
                  (params.page === 'channels' && (
                    <ProfileChannels
                      profileId={profileId}
                      isOwner={isOwner}
                      analyticsData={analyticsData}
                    />
                  )) ||
                  (params.page === 'edit' && isOwner && (
                    <ProfileEdit
                      profileId={profileId}
                      analyticsData={analyticsData}
                      syncAdvocate={syncAdvocate}
                    />
                  )) ||
                  (params.page === 'organization' && (
                    <ProfileOrganization profileId={profileId} />
                  ))}
              </>
            )}
          </PageMain>
        </>
      ) : (
        <Spinner center />
      )}
    </Page>
  );
};

const ProfileMobileCard = ({ profile }: { profile: AdvocateProfile }) => {
  return (
    <div className="mobile-profile-card fields-group">
      <div className="mobile-profile-card__header fields-group__header">
        <div className="content" id={DEFAULT_SKIP_TO_CONTENT_ID}>
          <h2 className="mobile-profile-card__name ">{profile.name}</h2>
          {profile.pronouns && (
            <div className={'mobile-profile-card__pronouns'}>
              {profile.pronouns}
            </div>
          )}
          <hr />
        </div>
      </div>
      <div className="mobile-profile-card__content">
        <div className="mobile-profile-card__avatar-container">
          <Avatar
            src={profile.avatar.url || undefined}
            alt={profile.name}
            bgColor={profile.avatar.color}
          />
        </div>
        <div className="mobile-profile-card__fields">
          <div className="mobile-profile-card__status">
            {profile.statusMessage}
          </div>
          <div className={'mobile-profile-card__about'}>
            {profile.profileFields.map((field) => (
              <div key={field.label}>
                {/* @ts-ignore */}
                <Label className="label" htmlFor={field.label}>
                  {field.label}
                </Label>
                <div className={`field-value`} id={field.label}>
                  {field.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  advocateId: advocateSelectors.getAdvocateId(state),
});

export default compose(
  withRerenderPrevent,
  connect(mapStateToProps, null)
)(Profile);
