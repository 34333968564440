import React, { MouseEventHandler } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button } from '../../ui';
import { trackContentCardComment } from '../../../models/content/analytics';
import { Chat } from '../../ui/icons_google';
import Tooltip from '../../tooltip/tooltip';
import { contentSelectors } from '../../../models/content';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { ForwardActions } from '../../../config/history';

interface IContentActionComment {
  showText?: boolean;
  commentCount: number;
  onClick: MouseEventHandler | undefined;
  contentTitle: string;
}

// Pure Component
const ContentActionComment: React.FC<IContentActionComment> = ({
  showText = false,
  commentCount = 0,
  onClick,
  contentTitle,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const className = cx('content__action', 'content__action--comment', {
    'content__action--empty': !commentCount,
  });

  const actionLabel = t('content.comment_action', { title: contentTitle });

  return (
    <div className={className}>
      {showText ? (
        <Button
          theme="text"
          size="compact"
          onClick={onClick}
          aria-label={actionLabel}
        >
          <Chat />
          <span>{t('content.comment')}</span>
          <span className="count">&nbsp;({commentCount})</span>
        </Button>
      ) : (
        <Tooltip aria-hidden text={t('content.comment')}>
          <Button
            theme="text"
            size="compact"
            onClick={onClick}
            aria-label={actionLabel}
          >
            <Chat />
            <span className="count">{commentCount}</span>
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

type ContentActionCommentWrapperProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  showText?: boolean;
  onClickAction?: ForwardActions;
};

// Data Wrapper
const ContentActionCommentWrapper = ({
  contentId,
  analyticsData,
  showText,
  onClickAction = 'push',
}: ContentActionCommentWrapperProps) => {
  const commentCount = usePatronSelector((s) =>
    contentSelectors.getContentCommentCount(s, { contentId })
  );
  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, { contentId })
  );
  const history = useHistory();

  const handleClick = () => {
    const commentsHash = '#comments';
    const targetPath = `/contents/${contentId}`;

    history[onClickAction]({
      pathname: targetPath,
      hash: commentsHash,
    });

    trackContentCardComment(contentId, analyticsData);
  };

  return (
    <ContentActionComment
      commentCount={commentCount}
      onClick={handleClick}
      contentTitle={contentTitle}
      showText={showText}
    />
  );
};

export { ContentActionComment };

export default ContentActionCommentWrapper;
