import { ComponentProps } from 'react';
import { ContentFrame } from '../../../components/content';

type ContentTypeArticleProps = ComponentProps<typeof ContentFrame>;

const ContentTypeArticle = ({
  contentId,
  analyticsData,
  onResize,
  locale,
}: ContentTypeArticleProps) => (
  <ContentFrame
    contentId={contentId}
    analyticsData={analyticsData}
    onResize={onResize}
    locale={locale}
  />
);

export default ContentTypeArticle;
