import { ContentType } from '../../../models/content/types';
import article from './article-monitor';
import video from './video-monitor';
import { Monitor } from './types';

const monitors: {
  [type in ContentType]?: new (
    onCompleteCallback: (monitor: Monitor) => void
  ) => Monitor;
} = {
  article,
  video,
};

export default monitors;
