import React from 'react';
import cx from 'classnames';

interface AssistantContentProps {
  children: React.ReactNode;
  className?: string;
}

export default function AssistantContent({
  children,
  className,
}: AssistantContentProps) {
  const classNames = cx('assistant__flex-content', className);

  return <div className={classNames}>{children}</div>;
}
