import { Button } from '../../models/assistant/interfaces/Button';
import { Icon } from '../ui';
import AssistantAction from './assistant-action';
import './assistant.scss';
import { Card } from '../../models/assistant/interfaces/subject/Summary';
import { AssistantLink } from './assistant-link';

interface AssistantApproveContentProps {
  onAcknowledge?: (button: Button) => void;
  subject: Card;
}

function AssistantApproveContent({
  subject,
  onAcknowledge,
}: AssistantApproveContentProps) {
  const onButtonClick = (button: Button) => {
    onAcknowledge && onAcknowledge(button);
  };

  return (
    <div className="assistant__content-approval">
      <AssistantLink
        to={`/approvals/${subject.id}`}
        className="assistant__content-approval__card"
      >
        <div
          className="assistant__content-approval__card-image"
          style={{ backgroundColor: subject.image_background_color }}
        >
          <img src={subject.image_url} alt={subject.title + ' image'} />
        </div>
        <div className="assistant__content-approval__card-text">
          <h3 className="title">{subject.title}</h3>
          <p className="description">{subject.description}</p>
        </div>
      </AssistantLink>
      <div className="assistant__content-approval__buttons">
        {subject.buttons &&
          subject.buttons.map((button, index) => (
            <AssistantAction
              key={button.button_text}
              action={button.action!}
              onClick={() => onButtonClick(button)}
            >
              <Icon type={index === 0 ? 'done' : 'clear'} />
            </AssistantAction>
          ))}
      </div>
    </div>
  );
}

export default AssistantApproveContent;
