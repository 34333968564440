import defaultLocalStorageLib, { LocalStorageLib } from './localstorage';

type GenericObject = Record<string, unknown>;

type Namespace = {
  advocate_id: string;
  program_id: string;
};

type NamespacedValue<T extends GenericObject = GenericObject> = Namespace & {
  value: T;
};

// The prefix will be used at the start of the Patron app to process namespaced values
const NAMESPACED_LOCAL_STORAGE_PREFIX = '_patron_namespaced_';

const namespaceValue = <T extends GenericObject>(
  namespace: Namespace,
  value: T
): NamespacedValue<T> => {
  return {
    ...namespace,
    value,
  };
};

const extractNamespacedValue = <T extends GenericObject>(
  value: NamespacedValue<T>
) => {
  const { value: containedValue } = value;
  return containedValue;
};

const isValueAccessible = (
  namespace: Namespace,
  value: GenericObject
): value is NamespacedValue => {
  const { advocate_id, program_id } = namespace;
  return value.advocate_id === advocate_id && value.program_id === program_id;
};

export default class NamespacedLocalStorage implements LocalStorageLib {
  constructor(readonly namespace: Namespace) {}

  static isNamespacedEntry(key: string): boolean {
    return key.startsWith(NAMESPACED_LOCAL_STORAGE_PREFIX);
  }

  getStorageKey(key: string) {
    return `${NAMESPACED_LOCAL_STORAGE_PREFIX}${key}`;
  }

  isKeyAccessible(key: string, hasPrefix = true): boolean {
    const storedValue = defaultLocalStorageLib.getStorageItem(
      hasPrefix ? key : this.getStorageKey(key),
      {}
    );
    return isValueAccessible(this.namespace, storedValue);
  }

  setStorageItem(key: string, value: Record<string, unknown>) {
    return defaultLocalStorageLib.setStorageItem(
      this.getStorageKey(key),
      namespaceValue(this.namespace, value)
    );
  }

  getStorageItem(key: string, defaultVal: Record<string, unknown>) {
    const storedValue = defaultLocalStorageLib.getStorageItem(
      this.getStorageKey(key),
      defaultVal
    );

    if (isValueAccessible(this.namespace, storedValue)) {
      return extractNamespacedValue(storedValue);
    }
    return defaultVal;
  }

  removeStorageItem(key: string): boolean {
    const storedValue = defaultLocalStorageLib.getStorageItem(
      this.getStorageKey(key),
      {}
    );

    if (!isValueAccessible(this.namespace, storedValue)) {
      return false;
    }
    return defaultLocalStorageLib.removeStorageItem(this.getStorageKey(key));
  }
}
