import { useLocation } from 'react-router';
import useContentFeed from '../../common/use-content-feed';
import { Spinner } from '../../components/ui';
import { ContentCard } from '../../components/v2/content-card/content-card';
import './content-error.scss';

export function ContentError({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  const location = useLocation();
  const { feedItems, fetching } = useContentFeed({
    filter: 'featured',
    perPage: 4,
  });

  if (fetching) {
    return <Spinner />;
  }

  return (
    <section className="content-error">
      <div className="content-error__container">
        <div className="content-error__header">
          <h2 className="content-error__title">{title}</h2>
          {description && (
            <p className="content-error__description">{description}</p>
          )}
        </div>

        <div className="content-error__related-content">
          <ul className="content-error__related-content__list">
            {feedItems.map((content) => (
              <li key={content.id}>
                <ContentCard.Horizontal
                  content={content}
                  analyticsData={{
                    location: location.pathname,
                  }}
                  compact
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
