import {
  ComponentProps,
  Dispatch,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactChild,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { Modifier, usePopper } from 'react-popper';
import './tooltip.scss';

const HOVER_DURATION_MS = 500;

type TooltipProps = ComponentProps<'div'> & {
  text: string;
  disabled?: boolean;
  options?: Parameters<typeof usePopper>[2];
  referenceElement?: HTMLElement | null;
  children: ElementRenderFunc | ReactChild;
};

const Tooltip = ({
  text,
  children,
  disabled = false,
  options = {},
  referenceElement: referenceElementProp,
  ...divProps
}: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  //refs stored in state as per 'usePopper' docs
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  // https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
  const sanitizedText = useMemo(
    () => text.replace(/(<([^>]+)>)/gi, ''),
    [text]
  );

  const applyWidthPropsModifier = useMemo<Modifier<'applyWidthProps'>>(
    () => ({
      name: 'applyWidthProps',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = 'auto';
        state.styles.popper.minWidth = 'max-content';
      },
    }),
    []
  );

  const { styles, attributes } = usePopper(
    referenceElementProp !== undefined
      ? referenceElementProp
      : referenceElement,
    popperRef,
    {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
        applyWidthPropsModifier,
      ],
      ...options,
    }
  );

  const onMouseEnter = () => {
    setTimer(setTimeout(() => setShowTooltip(true), HOVER_DURATION_MS));
    return () => timer && clearTimeout(timer);
  };
  const onMouseLeave = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowTooltip(false);
  };

  const onFocus = () => {
    setShowTooltip(true);
  };

  const onBlur = () => {
    setShowTooltip(false);
  };

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      setShowTooltip(false);
    }
  };

  const childrenIsRenderProp = typeof children === 'function';

  if (disabled) {
    return <>{children}</>;
  }
  return (
    <>
      {/**
       * Use conditional rendering to avoid excessive re-renders of popper style
       **/}
      {showTooltip && (
        <div
          {...divProps}
          role="tooltip"
          ref={setPopperRef}
          className="tooltip"
          style={styles.popper}
          {...attributes.popper}
        >
          <p>{sanitizedText}</p>
        </div>
      )}

      {childrenIsRenderProp ? (
        <Tooltip.Element
          tooltipRef={setReferenceElement}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          render={children}
        />
      ) : (
        // wrapping in a div could cause issues with styling.
        // chose this route because popper needs you to store refs in useState instead of useRef
        // decided passing in children is easier than asking the caller to pass in a 'stated' ref as a prop, and hoping they do
        // (there are various ways to work around this, if the styling issues are a problem, we can handle it in the future)
        // personally, I like the idea of adding a prop named 'targetRef___storedInUseStateNotRef' if we need to resolve this downside, and then ignoring 'children' if its present
        // alternatively, maybe popper stops needing this by the time this becomes a problem for us, and we can just accept a normal ref
        <div
          ref={setReferenceElement}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        >
          {children}
        </div>
      )}
    </>
  );
};

type ElementRenderProps = {
  tooltipRef: Dispatch<SetStateAction<HTMLElement | null>>;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
  onKeyDown: KeyboardEventHandler;
};

type ElementRenderFunc = (props: ElementRenderProps) => ReactElement;

type ElementProps = ElementRenderProps & {
  render: ElementRenderFunc;
};

const Element = ({
  tooltipRef,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  onKeyDown,
  render,
}: ElementProps) => {
  return render({
    tooltipRef,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onKeyDown,
  });
};

Element.displayName = 'Tooltip.Element';
Tooltip.Element = Element;

export default Tooltip;
