import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { trackChannelCardView } from '../../../models/channels/analytics';
import useInview from '../../../common/use-inview';
import { Button, GroupIcon } from '../../ui';
import { ChannelImage } from '../../channel';
import ChannelFollowButton from '../channel/channel-follow-button/channel-follow-button';
import { trackChannelViewClick } from '../../../models/analytics';
import useChannel from '../../../common/use-channel';

type IChannelCardV2 = {
  channelId: string;
  analyticsData: IAnalyticsData;
  shouldTrackView?: boolean;
};

export const ChannelCardV2 = ({
  channelId,
  analyticsData,
  shouldTrackView = true,
}: IChannelCardV2) => {
  const { channel } = useChannel({ channelId });
  const { t } = useTranslation();
  const ref = useRef();

  const handleView = () => {
    if (shouldTrackView) trackChannelCardView(channelId, analyticsData);
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  return (
    channel && (
      <header className="header-content">
        <div className="header-content__container">
          <ChannelImage
            channel={channel}
            analyticsData={analyticsData}
            noLink
          />
          <div className="event-content">
            <h3 className="event-name">{channel.name}</h3>
            <div className="event-following">
              <GroupIcon />
              <span className="count">
                {t('compact_number', { value: channel.follower_count })}
              </span>
            </div>
            <h4 className="event-description">{channel.description}</h4>
          </div>
        </div>
        <div className="event-actions">
          <div className="button-group">
            <ChannelFollowButton
              channelId={channelId}
              analyticsData={analyticsData}
            />

            <Button
              theme="secondary"
              size="compact"
              action="push"
              href={`/channels/${channelId}`}
              onClick={() => {
                trackChannelViewClick(analyticsData);
              }}
            >
              {t('content.view')}
            </Button>
          </div>
        </div>
      </header>
    )
  );
};
