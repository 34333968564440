import React from 'react';

import {
  ContentSourceAvatar,
  ContentSourceName,
  ContentSourceDate,
} from '../../../components/content/content-source-info';

const ContentInfoSource = ({ contentId, disableLinks }) => (
  <div className="content-info__source">
    <div className="content-info__source-avatar">
      <ContentSourceAvatar contentId={contentId} disableLink={disableLinks} />
    </div>
    <div className="content-info__source-text">
      <ContentSourceName contentId={contentId} disableLink={disableLinks} />
      <ContentSourceDate contentId={contentId} />
    </div>
  </div>
);

export default ContentInfoSource;
