import './message-details.scss';

const MessageDetailsNotFound = () => (
  <div className="message-not-found">
    <div className="message-not-found__image">
      <img src="/images/feed-empty.svg" alt="Message not found" />
    </div>

    <div className="message-not-found__title">Message Not Found</div>
    <p className="message-not-found__text">
      Please check the URL and try again.
    </p>
  </div>
);

export default MessageDetailsNotFound;
