import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import ViewAllLink from '../../components/view-all-link';
import ContentList from '../../components/content-list';
import ContentCardPlaceholder from '../../components/placeholders/content-card-placeholder';

const HomeFilteredFeed = ({
  title,
  seeAllText,
  feedUrl,
  contents,
  minLength,
  maxLength,
  isFetching,
  analyticsData,
}) => {
  const { t } = useTranslation();

  const contentIds = contents.map((c) => c.id);

  const className = cx('home-filtered-feed', {
    'home-filtered-feed--loading': isFetching,
  });

  if (!isFetching && contents.length < minLength) return null;

  return (
    <div className={className}>
      <div className="section-heading">
        <h2 className="section-title">{title}</h2>

        {feedUrl ? (
          <ViewAllLink href={feedUrl}>
            {seeAllText || `${t('assistant.view_all')} ${title}`}
          </ViewAllLink>
        ) : null}
      </div>

      {isFetching ? (
        <div className="content-list content-list--landscape">
          {[...Array(maxLength)].map((_, idx) => (
            <ContentCardPlaceholder key={idx} />
          ))}
        </div>
      ) : (
        <ContentList
          contentIds={contentIds}
          limit={maxLength}
          analyticsData={analyticsData}
        />
      )}
    </div>
  );
};

export default HomeFilteredFeed;
