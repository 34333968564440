import * as types from './keys';
import { track } from '../../analytics/helpers';

export const trackSubmitClick = (traits) => {
  track(types.SUBMIT_CLICK, traits);
};

export const trackAddLinkClick = (traits) => {
  track(types.SUBMIT_ADD_LINK_CLICK, traits);
};

export const trackAddImageClick = (traits) => {
  track(types.SUBMIT_ADD_IMAGE_CLICK, traits);
};

export const trackAddVideoClick = (traits) => {
  track(types.SUBMIT_ADD_VIDEO_CLICK, traits);
};

export const trackAddNoteClick = (traits) => {
  track(types.SUBMIT_ADD_NOTE_CLICK, traits);
};

export const trackAddArticleClick = (traits) => {
  track(types.SUBMIT_ADD_ARTICLE_CLICK, traits);
};

export const trackAddLinkAddLinkClick = (traits) => {
  track(types.SUBMIT_ADD_LINK_ADD_LINK_CLICK, traits);
};

export const trackAddLinkPreviewRemoveClick = (traits) => {
  track(types.SUBMIT_LINK_PREVIEW_REMOVE_CLICK, traits);
};

export const trackAddLinkCancel = (traits) => {
  track(types.SUBMIT_ADD_LINK_CANCEL_CLICK, traits);
};

export const trackAddImageCancel = (traits) => {
  track(types.SUBMIT_ADD_IMAGE_CANCEL_CLICK, traits);
};

export const trackAddVideoCancel = (traits) => {
  track(types.SUBMIT_ADD_VIDEO_CANCEL_CLICK, traits);
};

export const trackAddNoteCancel = (traits) => {
  track(types.SUBMIT_ADD_NOTE_CANCEL_CLICK, traits);
};

export const trackAddArticleCancel = (traits) => {
  track(types.SUBMIT_ADD_ARTICLE_CANCEL_CLICK, traits);
};

export const trackAddImageAddImageClick = (traits) => {
  track(types.SUBMIT_ADD_IMAGE_ADD_IMAGE_CLICK, traits);
};

export const trackLoadImagePreviewRemoveClick = (traits) => {
  track(types.SUBMIT_IMAGE_PREVIEW_REMOVE_CLICK, traits);
};

export const trackAddImageAddImageError = (traits) => {
  track(types.SUBMIT_ADD_IMAGE_ADD_IMAGE_ERROR, traits);
};

export const trackAddVideoAddVideoClick = (traits) => {
  track(types.SUBMIT_ADD_VIDEO_ADD_VIDEO_CLICK, traits);
};

export const trackLoadVideoPreviewRemoveClick = (traits) => {
  track(types.SUBMIT_VIDEO_PREVIEW_REMOVE_CLICK, traits);
};

export const trackAddVideoAddVideoError = (traits) => {
  track(types.SUBMIT_ADD_VIDEO_ADD_VIDEO_ERROR, traits);
};

export const trackEditClick = (traits) => {
  track(types.EDIT_CLICK, traits);
};
