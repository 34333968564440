import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useNotificationFeed } from './use-notification-feed';
import { Checkbox, NotificationCard } from '@socialchorus/shared-ui-components';
import { Spinner } from '../../components/ui';
import { Notification } from '../../models/notifications/types';
import ViewTrigger from '../../components/view-trigger';
import styles from './notification-center.module.scss';
import { NotificationContext } from './context';
import { useTranslation } from 'react-i18next';

const NotificationCardWrapper: React.FC<{ notification: Notification }> = ({
  notification,
}) => {
  //todo: click action of the card, need to use something similar to the assistant action handler, making sure to render an anchor for the notification.
  //todo: clicking also marks the card as read.

  const {
    selectAll: [selectAll, setSelectAll],
    multiSelectEnabled: [multiSelectEnabled],
    multiSelectedItems: [multiSelectItems, setMultiSelectItems],
  } = useContext(NotificationContext);

  const selected = useMemo(
    () => multiSelectItems.some((item) => item.id === notification.id),
    [multiSelectItems, notification.id]
  );

  const setSelected = useCallback(
    (checked: boolean) => {
      if (checked && !selected) {
        setMultiSelectItems((prev) => [...prev, notification]);
      }
      if (!checked && selected) {
        setMultiSelectItems((prev) =>
          prev.filter((item) => item.id !== notification.id)
        );
      }
    },
    [selected, notification, setMultiSelectItems]
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      setSelected(checked);
      if (!checked && selectAll === true) {
        setSelectAll('indeterminate');
      }
    },
    [selectAll, setSelected, setSelectAll]
  );

  useEffect(() => {
    if (multiSelectEnabled && selectAll === true) {
      handleCheckedChange(true);
    }
  }, [multiSelectEnabled, selectAll, handleCheckedChange]);

  return (
    <div className={styles.notificationCardContainer}>
      {multiSelectEnabled && (
        <Checkbox
          checked={selected}
          className={styles.checkbox}
          onCheckedChange={(checked) => handleCheckedChange(!!checked)}
        />
      )}
      <NotificationCard.Root markUnread={!notification.attributes.read}>
        <NotificationCard.Avatar />
        <NotificationCard.Body
          title={notification.attributes.text || ''}
          // quotation={quotation}
          // description={description}
        >
          {/* {tags && (
          <NotificationCard.TagsContainer>
            {tags}
          </NotificationCard.TagsContainer>
        )} */}
        </NotificationCard.Body>
        <NotificationCard.Footer
          timestamp={new Date(notification.attributes.created_at)}
        >
          {/* {footerIcons} */}
        </NotificationCard.Footer>
      </NotificationCard.Root>
    </div>
  );
};

export const NotificationFeed: React.FC = () => {
  const { t } = useTranslation();
  const { feed, isLoading, canLoadMore, isLoadingMore, fetchNextPage } =
    useNotificationFeed();

  const feedEmpty = feed.length === 0;

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : feedEmpty ? (
        <>
          <div className={styles.emptyFeed}>
            <div
              className={cx(styles.emptyFeedLabel, 'text-body-1 text-black-90')}
            >
              {t('notification_center.empty_feed')}
            </div>
          </div>
          <hr className="bg-black-10" />
        </>
      ) : (
        <>
          {feed.map((notification) => (
            <NotificationCardWrapper
              key={notification.id}
              notification={notification}
            />
          ))}
          {isLoadingMore ? (
            <Spinner />
          ) : (
            canLoadMore && (
              <ViewTrigger offset="200px" onInview={() => fetchNextPage()} />
            )
          )}
        </>
      )}
    </div>
  );
};
