import { ComponentProps, useContext, useEffect, useState } from 'react';
import { Spinner } from '../../ui';
import ViewTrigger from '../../view-trigger';
import CommentList from './comment-list';
import { Button } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { Feature } from '../../../models/features/features';
import useFeatureFlag from '../../../common/use-feature-flag';
import {
  trackSeeOriginalAllComments,
  trackTranslateAllComments,
} from '../../../models/comment-translations';
import { CommentTranslationContext } from './translations/context';

type CommentsMainProps = ComponentProps<typeof CommentList> & {
  hasNextPage?: boolean;
  isFetching: boolean;
  onNextPage: (reset?: boolean) => void;
};

const CommentsMain = ({
  comments,
  context,
  hasNextPage,
  isFetching,
  onNextPage,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
}: CommentsMainProps) => {
  const { translateAllLoading, translateAllActive, toggleTranslateAll } =
    useContext(CommentTranslationContext);

  const {
    t,
    i18n: { language: userLanguage },
  } = useTranslation();

  const defaultTranslateAllLabel = t('comments.translate_all');
  const [translateAllLabel, setTranslateAllLabel] = useState(
    defaultTranslateAllLabel
  );

  const translationEnabled = useFeatureFlag(
    Feature.COMMENT_TRANSLATION_ENABLED
  );

  const translationAvailable =
    translationEnabled && comments.some((c) => c.isTranslatable);

  //translate all label swaps when translate all status changes and loading is done
  useEffect(() => {
    if (translateAllLoading) return;

    if (translateAllActive) {
      setTranslateAllLabel(t('content.see_original'));
    } else {
      setTranslateAllLabel(t('comments.translate_all'));
    }
  }, [translateAllActive, translateAllLoading, t]);

  const onTranslateAllClick = () => {
    const analyticsPayload = {
      translated_language: userLanguage,
      content_id: context.contentId,
    };

    if (!translateAllActive) {
      trackTranslateAllComments(analyticsPayload);
    } else {
      trackSeeOriginalAllComments(analyticsPayload);
    }
    toggleTranslateAll();
  };

  return (
    <div className="comments__main">
      {!isFetching || comments.length ? (
        <>
          {translationAvailable ? (
            <div className="translate-all-container">
              <Button
                variant="text"
                size="compact"
                label={translateAllLabel}
                onClick={onTranslateAllClick}
                isLoading={translateAllLoading}
              />
            </div>
          ) : null}

          <CommentList
            comments={comments}
            context={context}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
            onReport={onReport}
            onReplies={onReplies}
            onHighlight={onHighlight}
          />

          {hasNextPage ? (
            !isFetching && context.action !== 'reply' ? (
              <ViewTrigger onInview={() => onNextPage()} />
            ) : (
              <Spinner />
            )
          ) : null}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CommentsMain;
