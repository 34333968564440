import {
  Button,
  Icon,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { Comment } from '../../../../models/comments/types';

type CommentActionTranslateProps = {
  comment: Comment;
  onClick: (comment: Comment) => void;
  isTranslated?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

export function CommentActionTranslate({
  comment,
  onClick,
  isTranslated,
  disabled,
  isLoading,
  hasError,
}: CommentActionTranslateProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(comment);
  };

  let label = isTranslated
    ? t('content.see_original')
    : t('content.see_translation');

  if (hasError) {
    label = t('content.retry_translation');
  }

  return (
    <>
      <Button
        size="compact"
        variant="text"
        label={label}
        disabled={disabled}
        onClick={handleClick}
        isLoading={isLoading}
      />
      {hasError && (
        <TooltipRoot delayDuration={0}>
          <TooltipTrigger asChild>
            <Icon size={20}>info</Icon>
          </TooltipTrigger>
          <TooltipContent
            className="tooltip-content"
            description={t('errors.unknown_tooltip')}
            sideOffset={5}
            side="right"
          />
        </TooltipRoot>
      )}
    </>
  );
}
