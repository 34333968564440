import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Container } from '@socialchorus/shared-ui-components';
import { BaseTab } from '../BaseTab';
import { ChannelTabType } from '../../../../../models/channels/types';
import { Spinner } from '../../../../ui';
import { Follower, useMembers } from './hooks';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ViewTrigger from '../../../../view-trigger';
import EmptyPlaceholder from '../../common/EmptyPlaceholder';

interface Props {
  topicId: string;
}

function NewTopicPlaceholder() {
  const { t } = useTranslation();
  return (
    <EmptyPlaceholder
      title={t('screens.topic_page.members_tab.placeholder.title')}
      description={t('screens.topic_page.members_tab.placeholder.text')}
    />
  );
}

interface MembersListProps {
  members: Follower[];
  loading: boolean;
  total: number;
  loadMore: () => void;
  page: number;
  totalPages: number;
}

function MembersList({
  members,
  loading,
  total,
  loadMore,
  page,
  totalPages,
}: MembersListProps) {
  const { t } = useTranslation();

  return (
    <Container className={styles.container} shadow="extra-light" fullWidth>
      <div className={styles.memberCount}>
        {t('screens.topic_page.members_tab.count', { count: total })}
      </div>
      <div className={styles.memberList}>
        {members?.map((member) => (
          <Container
            key={member.id}
            className={styles.member}
            tag={Link}
            to={`/profiles/${member.id}`}
            variant="border"
            fullWidth
          >
            <Avatar
              size="large"
              shape="circle"
              alt={member.name}
              imgSrc={member.avatarUrl}
            />
            <span className={styles.name}>{member.name}</span>
          </Container>
        ))}
      </div>
      {page < totalPages && (
        <ViewTrigger key={page} offset="400px" onInview={loadMore} />
      )}
      {loading && <Spinner />}
    </Container>
  );
}

const MembersTab: FC<Props> = ({ topicId }) => {
  const { t } = useTranslation();
  const { data, isLoading, error, loadMore, total, page, totalPages } =
    useMembers(topicId);

  let content;
  if (error) {
    content = (
      <div className={styles.placeholder}>
        <h2>{t('errors.default')}</h2>
      </div>
    );
  } else if (!data.length && !isLoading) {
    content = <NewTopicPlaceholder />;
  } else if (!data.length && isLoading) {
    content = <Spinner />;
  } else {
    content = (
      <MembersList
        members={data}
        loading={isLoading}
        total={total}
        loadMore={loadMore}
        page={page}
        totalPages={totalPages}
      />
    );
  }

  return (
    <BaseTab currentTab={ChannelTabType.Members} topicId={topicId}>
      {content}
    </BaseTab>
  );
};

export default MembersTab;
