import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import store from '../config/store';
import AssistantService from '../services/assistant';
import { CommandButton } from '../models/assistant/interfaces/Command';

type Command = Pick<CommandButton, 'button_text' | 'action'>;

export default function useAssistantCommand() {
  const history = useHistory();

  const runCommandInAssistant = useCallback(
    (command: Command, analyticsData: unknown) => {
      history.push('/assistant/commands', { command, analyticsData });
    },
    [history]
  );

  const buildCommandAction = useCallback(
    (integrationId: string, commandName: string) => {
      const state = store.getState();

      return {
        type: 'request',
        request: {
          endpoint: `/api/v1/programs/${state.program.id}/assistant/service/${integrationId}/command/${commandName}`,
          method: 'POST',
        },
      };
    },
    []
  );

  const fetchCommandAction = useCallback(
    async (integrationId: string, commandName: string) => {
      const commandData = await new AssistantService().fetchCommandDetail(
        integrationId,
        commandName
      );

      return commandData.action;
    },
    []
  );

  return {
    runCommandInAssistant,
    buildCommandAction,
    fetchCommandAction,
  };
}
