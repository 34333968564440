import { createSelector } from 'reselect';
import unescape from 'lodash/unescape';

import { programSelectors } from '../program';
import { advocateSelectors } from '../advocate';
import { channelSelectors } from '../channels';

const allChannels = (state, props) =>
  channelSelectors.getChannelsWithoutHome(state, props);
const allColors = (state, props) => [
  '#ba5858',
  '#c5be7b',
  '#646692',
  '#655163',
  '#c29b72',
  '#4a93c6',
  '#494949',
];
const submission = (state, props) => state.contentSubmission;
const contentType = (state, props) => state.contentSubmission.contentType;
const channelIds = (state, props) => state.contentSubmission.channelIds;
const id = (state, props) => state.contentSubmission.id;
const authorId = (state, props) => state.contentSubmission.authorId;
const url = (state, props) => state.contentSubmission.url;
const title = (state, props) => state.contentSubmission.title;
const description = (state, props) => state.contentSubmission.description;
const imageUrl = (state, props) => state.contentSubmission.imageUrl;
const media = (state, props) => state.contentSubmission.media;
const mediaUrl = (state, props) => state.contentSubmission.mediaUrl;
const noteColor = (state, props) => state.contentSubmission.noteColor;
const article = (state, props) => state.contentSubmission.article;
const submitProgress = (state, props) => state.contentSubmission.submitProgress;
const publicationState = (state, props) =>
  state.contentSubmission.publicationState;
const isFetching = (state, props) => state.contentSubmission.isFetching;
const isSubmitting = (state, props) => state.contentSubmission.isSubmitting;
const isDraft = (state, props) => state.contentSubmission.isDraft;
const error = (state, props) => state.contentSubmission.error;

const getAllChannels = createSelector(
  [allChannels],
  (allChannels) => allChannels
);

const getContributorChannels = createSelector([getAllChannels], (allChannels) =>
  allChannels.filter((c) => c.can_publish_as_owner)
);

const getPublishableChannels = createSelector([getAllChannels], (allChannels) =>
  allChannels.filter((c) => !c.can_publish_as_owner && c.auto_publish)
);

const getSubmittableChannels = createSelector([getAllChannels], (allChannels) =>
  allChannels.filter(
    (c) => !c.can_publish_as_owner && !c.auto_publish && c.can_submit
  )
);

const getAllColors = createSelector([allColors], (allColors) => allColors);

const getContentId = createSelector([id], (id) => id);

const getContentType = createSelector(
  [contentType],
  (contentType) => contentType
);

const getChannelIds = createSelector([channelIds], (channelIds) => channelIds);

const getChannels = createSelector(
  [getAllChannels, channelIds],
  (allChannels, channelIds) =>
    allChannels.filter((c) => channelIds.includes(c.id))
);

const getAuthorId = createSelector([authorId], (authorId) => authorId);

const getUrl = createSelector([url], (url) => url);

const getTitle = createSelector([title], (title) => title);

const getDescription = createSelector(
  [description],
  (description) => description
);

const getDecodedTitle = createSelector([title], (title) => unescape(title));

const getDecodedDescription = createSelector([description], (description) =>
  unescape(description)
);

const getImageUrl = createSelector([imageUrl], (imageUrl) => imageUrl);

const getMedia = createSelector([media], (media) => media);

const getMediaUrl = createSelector([mediaUrl], (mediaUrl) => mediaUrl);

const getNoteColor = createSelector([noteColor], (noteColor) => noteColor);

const getArticle = createSelector([article], (article) => article);

const getSubmitProgress = createSelector(
  [submitProgress],
  (submitProgress) => submitProgress || 0
);

const getSubmitShouldPublish = createSelector(
  [
    advocateSelectors.getAdvocateIsMember,
    advocateSelectors.getAdvocateIsContributor,
    getChannelIds,
    getContributorChannels,
  ],
  (isMember, isContributor, selectedChannelIds, contributorChannels) =>
    isMember
      ? false
      : isContributor
      ? contributorChannels
          .map((c) => c.id)
          .filter((id) => selectedChannelIds.includes(id)).length
        ? true // contributor w/owned channels
        : false
      : true // super admin, admin, publisher
);

const getError = createSelector([error], (error) => error);

const hasLinkPreview = createSelector(
  [getTitle, getDescription, getImageUrl],
  (title, description, imageUrl) => !!(title || description || imageUrl)
);

const isValid = createSelector(
  [
    contentType,
    getChannelIds,
    getUrl,
    getTitle,
    getDescription,
    getImageUrl,
    getMedia,
    getNoteColor,
    getArticle,
  ],
  (
    type,
    channelIds,
    url,
    title,
    description,
    media,
    imageUrl,
    noteColor,
    article
  ) => {
    switch (type) {
      case 'link':
        return !!(url && title && url.match(/\S/));
      case 'image':
      case 'video':
        return !!(media || imageUrl);
      case 'note':
        return !!(noteColor && description && description.match(/\S/));
      case 'article':
        return !!(
          article &&
          article.replace(/<[^>]+>/g, '').match(/\S/) && // strip html tags
          title &&
          title.match(/\S/)
        );
      default:
        return false;
    }
  }
);

const isEdit = createSelector([getContentId], (id) => !!id);

const isPublished = createSelector(
  [publicationState],
  (state) => state === 'published'
);

const isError = createSelector([getError], (error) => !!error);

const isCommentable = createSelector(
  [
    submission,
    programSelectors.getSubmissionsCommentDefaultEnabled,
    advocateSelectors.getCanCommentSubmission,
  ],
  (submission, isDefault, canComment) =>
    !!(typeof submission.isCommentable !== 'undefined'
      ? submission.isCommentable
      : isDefault && canComment) // default
);

const isShareable = createSelector(
  [
    submission,
    getContentType,
    advocateSelectors.getCanShareSubmission,
    advocateSelectors.getAdvocateIsContributor,
  ],
  (submission, contentType, canShare, isContributor) =>
    !!(typeof submission.isShareable !== 'undefined'
      ? submission.isShareable
      : contentType === 'link' && canShare && isContributor) // default
);

const isTranslatable = createSelector(
  [submission, advocateSelectors.getCanTranslateSubmission],
  (submission, canTranslate) =>
    !!(typeof submission.isTranslatable !== 'undefined'
      ? submission.isTranslatable
      : canTranslate) // default
);

export default {
  getAllChannels,
  getContributorChannels,
  getPublishableChannels,
  getSubmittableChannels,
  getAllColors,
  getContentId,
  getContentType,
  getChannelIds,
  getChannels,
  getAuthorId,
  getUrl,
  getTitle,
  getDescription,
  getDecodedTitle,
  getDecodedDescription,
  getImageUrl,
  getMedia,
  getMediaUrl,
  getNoteColor,
  getArticle,
  getSubmitProgress,
  getSubmitShouldPublish,
  getError,
  hasLinkPreview,
  isValid,
  isFetching,
  isSubmitting,
  isDraft,
  isEdit,
  isPublished,
  isError,
  isCommentable,
  isShareable,
  isTranslatable,
};
