import { atom, selector, selectorFamily, useRecoilCallback } from 'recoil';
import unescape from 'lodash/unescape';

import store from '../../config/store';
import { programSelectors } from '../program';
import { advocateSelectors } from '../advocate';
import { channelSelectors } from '../channels';
import { usePatronSelector } from '../../common/use-patron-selector';
import { useEffect, useState } from 'react';

export const useInitializeSubmissionState = (valMap) => {
  const setOrDefault = (key, val) => {
    valMap.set(key, valMap.has(key) ? valMap.get(key) : val);
  };
  const [initialized, setInitialized] = useState(false);
  const storeState = usePatronSelector((state) => state);
  const contentType = valMap.get(submissionType);
  const canComment = advocateSelectors.getCanCommentSubmission(storeState);
  const canTranslate = advocateSelectors.getCanTranslateSubmission(storeState);
  const commentDefaultEnabled =
    programSelectors.getSubmissionsCommentDefaultEnabled(storeState);

  // Settings
  const isCommentable = canComment && commentDefaultEnabled;
  const isShareable = contentType === 'link';
  const isTranslatable = canTranslate;

  valMap = valMap instanceof Map ? valMap : new Map();

  setOrDefault(submissionType);
  setOrDefault(submissionId);
  setOrDefault(submissionChannelIds, []);
  setOrDefault(submissionTitle);
  setOrDefault(submissionDescription);
  setOrDefault(submissionImageUrls, []);
  setOrDefault(submissionImages, []);
  setOrDefault(submissionVideoUrl);
  setOrDefault(submissionVideoUuid);
  setOrDefault(submissionVideo);
  setOrDefault(submissionUrl);
  setOrDefault(submissionArticle);
  setOrDefault(submissionNoteColor);
  setOrDefault(submissionIsCommentable, isCommentable);
  setOrDefault(submissionIsShareable, isShareable);
  setOrDefault(submissionIsTranslatable, isTranslatable);
  setOrDefault(submissionIsFetching);
  setOrDefault(submissionChannelsVisible);
  setOrDefault(submissionError);

  const initializeState = useRecoilCallback(
    ({ set }) =>
      () => {
        valMap.forEach((val, atom) => {
          set(atom, val);
        });
        setInitialized(true);
      },
    [contentType]
  );

  useEffect(() => {
    initializeState();
  }, [initializeState]);

  return {
    initialized,
  };
};

export const submissionId = atom({
  key: 'submissionId',
});

export const submissionType = atom({
  key: 'submissionType',
});

export const submissionChannelIds = atom({
  key: 'submissionChannelIds',
});

export const submissionChannels = selector({
  key: 'submissionChannels',
  get: ({ get }) => {
    const allChannels = get(submissionAllChannels);
    const channelIds = get(submissionChannelIds);

    return allChannels.filter((c) => channelIds.includes(c.id));
  },
});

export const submissionAllChannels = selector({
  key: 'submissionAllChannels',
  get: () => channelSelectors.getChannelsWithoutHome(store.getState()),
});

export const submissionContributorChannels = selector({
  key: 'submissionContributorChannels',
  get: ({ get }) =>
    get(submissionAllChannels).filter((c) => c.can_publish_as_owner),
});

export const submissionPublishableChannels = selector({
  key: 'submissionPublishableChannels',
  get: ({ get }) =>
    get(submissionAllChannels).filter(
      (c) => !c.can_publish_as_owner && c.auto_publish
    ),
});

export const submissionSubmittableChannels = selector({
  key: 'submissionSubmittableChannels',
  get: ({ get }) =>
    get(submissionAllChannels).filter(
      (c) => !c.can_publish_as_owner && !c.auto_publish && c.can_submit
    ),
});

export const submissionNoteColors = atom({
  key: 'submissionNoteColors',
  default: [
    '#ba5858',
    '#c5be7b',
    '#646692',
    '#655163',
    '#c29b72',
    '#4a93c6',
    '#494949',
  ],
});

export const submissionTitle = atom({
  key: 'submissionTitle',
});

export const submissionDescription = atom({
  key: 'submissionDescription',
});

export const submissionImages = atom({
  key: 'submissionImages',
  default: [],
});

export const submissionVideo = atom({
  key: 'submissionVideo',
});

export const submissionImageUrls = atom({
  key: 'submissionImageUrls',
});

export const submissionSetImageUrl = selectorFamily({
  key: 'submissionSetImageUrl',
  get:
    (index) =>
    ({ get }) =>
      get(submissionImageUrls)[index],
  set:
    (index) =>
    ({ set, get }, value) => {
      const newImageUrls = [...get(submissionImageUrls)];

      newImageUrls[index] = value;

      set(submissionImageUrls, newImageUrls);
    },
});

export const submissionVideoUrl = atom({
  key: 'submissionVideoUrl',
});

export const submissionUrl = atom({
  key: 'submissionUrl',
});

export const submissionArticle = atom({
  key: 'submissionArticle',
});

export const submissionNoteColor = atom({
  key: 'submissionNoteColor',
});

export const submissionPublicationStatus = atom({
  key: 'submissionPublicationStatus',
});

export const submissionVideoUuid = atom({
  key: 'submissionVideoUuid',
});

export const submissionIsCommentable = atom({
  key: 'submissionIsCommentable',
});

export const submissionIsShareable = atom({
  key: 'submissionIsShareable',
});

export const submissionIsTranslatable = atom({
  key: 'submissionIsTranslatable',
});

export const submissionIsEdit = selector({
  key: 'submissionIsEdit',
  get: ({ get }) => !!get(submissionId),
});

export const submissionIsDraft = selector({
  key: 'submissionIsDraft',
  get: ({ get }) => get(submissionPublicationStatus) === 'draft',
});

export const submissionIsPending = selector({
  key: 'submissionIsPending',
  get: ({ get }) => get(submissionPublicationStatus) === 'for_review',
});

export const submissionIsArchived = selector({
  key: 'submissionIsArchived',
  get: ({ get }) => get(submissionPublicationStatus) === 'archived',
});

export const submissionIsTitleOverLimit = atom({
  key: 'submissionIsTitleOverLimit',
  default: false,
});

export const submissionIsDescriptionOverLimit = atom({
  key: 'submissionIsDescriptionOverLimit',
  default: false,
});

export const submissionIsValid = selector({
  key: 'submissionIsValid',
  get: ({ get }) => {
    const channelIds = get(submissionChannelIds);
    const canDraft = get(submissionContentIsValid);
    const noOverLimit =
      !get(submissionIsTitleOverLimit) &&
      !get(submissionIsDescriptionOverLimit);

    return !!channelIds.length && canDraft && noOverLimit;
  },
});

export const submissionContentIsValid = selector({
  key: 'submissionContentIsValid',
  get: ({ get }) => {
    const contentType = get(submissionType);
    const title = get(submissionTitle);
    const description = get(submissionDescription);
    const imageUrls = get(submissionImageUrls);
    const videoUrl = get(submissionVideoUrl);
    const url = get(submissionUrl);
    const article = get(submissionArticle);
    const noteColor = get(submissionNoteColor);

    switch (contentType) {
      case 'link':
        return !!(url && title && url.match(/\S/) && title.match(/\S/));
      case 'image':
        return !!(
          imageUrls.length &&
          imageUrls.length === imageUrls.filter((url) => url).length
        );
      case 'video':
        return !!videoUrl;
      case 'note':
        return !!(noteColor && description && description.match(/\S/));
      case 'article':
        return !!(
          article &&
          article.replace(/<[^>]+>/g, '').match(/\S/) && // strip html tags
          title &&
          title.match(/\S/)
        );
      default:
        return false;
    }
  },
});

export const submissionHasInput = selector({
  key: 'submissionHasInput',
  get: ({ get }) =>
    !!(
      get(submissionChannelIds).length ||
      get(submissionTitle) ||
      get(submissionDescription) ||
      get(submissionArticle) ||
      get(submissionImageUrls).length ||
      get(submissionVideoUrl)
    ),
});

export const submissionShouldPublish = selector({
  key: 'submissionShouldPublish',
  get: ({ get }) => {
    const storeState = store.getState();
    const isMember = advocateSelectors.getAdvocateIsMember(storeState);
    const isContributor =
      advocateSelectors.getAdvocateIsContributor(storeState);
    const isAnalyst = advocateSelectors.getAdvocateIsAnalyst(storeState);

    const selectedChannelIds = get(submissionChannelIds);
    const contributorChannels = get(submissionContributorChannels);

    return isMember || isAnalyst
      ? false
      : isContributor
      ? contributorChannels
          .map((c) => c.id)
          .filter((id) => selectedChannelIds.includes(id)).length
        ? true // contributor w/owned channels
        : false
      : true; // super admin, admin, publisher
  },
});

export const submissionCanEditChannels = selector({
  key: 'submissionCanEditChannels',
  get: ({ get }) => {
    const storeState = store.getState();
    const isMember = advocateSelectors.getAdvocateIsMember(storeState);
    const isContributor =
      advocateSelectors.getAdvocateIsContributor(storeState);
    const isAnalyst = advocateSelectors.getAdvocateIsAnalyst(storeState);

    return !(isMember || isContributor || isAnalyst);
  },
});

export const submissionState = selector({
  key: 'submissionState',
  get: ({ get }) => {
    return {
      type: get(submissionType),
      contentId: get(submissionId),
      channelIds: get(submissionChannelIds),
      title: unescape(get(submissionTitle)),
      description: unescape(get(submissionDescription)),
      url: get(submissionUrl),
      imageUrls: get(submissionImageUrls),
      images: get(submissionImages),
      videoUrl: get(submissionVideoUrl),
      videoUuid: get(submissionVideoUuid),
      noteColor: get(submissionNoteColor),
      article: get(submissionArticle),
      isCommentable: get(submissionIsCommentable),
      isShareable: get(submissionIsShareable),
      isTranslatable: get(submissionIsTranslatable),
      publicationStatus: get(submissionPublicationStatus),
    };
  },
});

export const submissionSaveStatus = atom({
  key: 'submissionSaveStatus',
  default: 'unsaved',
});

export const submissionError = atom({
  key: 'submissionError',
});

export const submissionReset = selector({
  key: 'submissionReset',
  set: ({ set }) => {
    set(submissionTitle, '');
    set(submissionDescription, '');
    set(submissionImages, []);
    set(submissionImageUrls, []);
    set(submissionVideo, null);
    set(submissionVideoUrl, null);
    set(submissionUrl, '');
    set(submissionArticle, '');
    set(submissionNoteColor, null);
  },
});

// UI State
export const submissionIsFetching = atom({
  key: 'submissionIsFetching',
});

export const submissionIsSubmitting = selector({
  key: 'submissionIsSubmitting',
  get: ({ get }) => get(submissionSaveStatus) === 'saving',
});

export const submissionChannelsVisible = atom({
  key: 'submissionChannelsVisible',
});
