import { ComponentProps } from 'react';
import { ContentFrame } from '../../../components/content';

type ContentTypeVideoProps = ComponentProps<typeof ContentFrame>;

const ContentTypeVideo = ({
  contentId,
  analyticsData,
  onResize,
}: ContentTypeVideoProps) => (
  <ContentFrame
    contentId={contentId}
    analyticsData={analyticsData}
    onResize={onResize}
  />
);

export default ContentTypeVideo;
