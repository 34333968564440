import { useEffect } from 'react';
import { connect } from 'react-redux';

import { ID as OnboardingDialogID } from '../onboarding-dialog';

import { uiOperations } from '../../models/ui';
import OnboardingService from '../../services/onboarding';
import AuthService from '../../services/auth';
import useSkipToContent from '../../common/use-skip-to-content';

const signOut = () => {
  new AuthService().destroySession();
};

const OnboardingController = ({ addOverlay, removeOverlay }) => {
  const { skipToContent } = useSkipToContent();

  const handleStepComplete = () => {
    removeOverlay({ id: OnboardingDialogID });

    // Wait until next tick so that we can focus on the element properly after focus-lock has been removed
    // for this dialog
    setTimeout(() => {
      skipToContent();
    }, 0);
  };

  useEffect(() => {
    const getStepData = (data) => {
      const type = data[0].type;
      const step =
        type !== 'question'
          ? data[0].attributes
          : { questions: data.map((s) => s.attributes) };

      return { type, step };
    };

    const fetchNextStep = async () => {
      try {
        const res = await new OnboardingService().getNextStep();
        handleFetchSuccess(res);
      } catch (err) {
        if (err.response) {
          handleFetchFailure(err);
        } else {
          throw err;
        }
      }
    };

    const handleFetchSuccess = ({ data }) => {
      if (!data.onboarding || !data.onboarding.length) return;

      const { type, step } = getStepData(data.onboarding);
      const canSkip = type === 'question';
      const canSignout = type === 'onboarding';

      addOverlay(OnboardingDialogID, {
        continue: handleStepComplete,
        signout: canSignout ? () => signOut() : null,
        cancel: canSkip ? handleStepComplete : null,
        close: canSkip ? null : () => false, // prevent close on overlay click
        type,
        step,
      });
    };

    const handleFetchFailure = (err) => {
      console.error(err);
    };

    fetchNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(null, mapDispatchToProps)(OnboardingController);
