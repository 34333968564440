import { usePrependProgramPath } from './use-prepend-program-path';

/**
 * Returns whether the assistant is active or not, based on the given location.
 *
 * @param location - The location object to check. Defaults to `window.location`.
 */
export function useIsAssistantActive(location: Location = window.location) {
  const prefix = usePrependProgramPath('/assistant');
  return location.pathname.startsWith(prefix);
}
