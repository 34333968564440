import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ContentSubmission from './content-submission';
import {
  submissionArticle,
  submissionChannelIds,
  submissionDescription,
  submissionImages,
  submissionImageUrls,
  submissionIsCommentable,
  submissionIsShareable,
  submissionIsTranslatable,
  submissionNoteColor,
  submissionNoteColors,
  submissionPublicationStatus,
  submissionTitle,
  submissionType,
  submissionUrl,
  submissionVideoUrl,
  submissionVideoUuid,
} from '../../models/content-submission/atoms';
import { ID as ResumeAutoSaveDialogID } from './resume-dialog';
import { uiOperations } from '../../models/ui';
import { useLocalStorage } from '../../common/use-local-storage';
import { SUBMISSION_AUTOSAVE_PREFIX } from './constant';
import LoadingOverlay from '../../components/loading-overlay';
import { useRecoilValue } from 'recoil';

const ContentSubmissionNew = ({
  match: {
    params: { contentType },
  },
  addOverlay,
  removeOverlay,
}) => {
  const noteColors = useRecoilValue(submissionNoteColors);

  const {
    storeEnabled: autoSaveEnabled,
    value: autoSavedValue,
    remove: removeAutoSavedValue,
  } = useLocalStorage(`${SUBMISSION_AUTOSAVE_PREFIX}_${contentType}`);

  const [initialFormState, setInitialFormState] = useState();
  const [hasCheckedAutoSave, setHasCheckedAutoSave] = useState(false);

  const promptResumeAutoSave = useCallback(() => {
    if (!autoSaveEnabled) {
      setHasCheckedAutoSave(true);
      return;
    }

    addOverlay(ResumeAutoSaveDialogID, {
      onDiscard: () => {
        removeAutoSavedValue();
        setHasCheckedAutoSave(true);
        removeOverlay({ id: ResumeAutoSaveDialogID });
      },
      onResume: () => {
        setInitialFormState(
          new Map([
            [submissionType, autoSavedValue.type],
            [submissionChannelIds, autoSavedValue.channelIds],
            [submissionTitle, autoSavedValue.title],
            [submissionDescription, autoSavedValue.description],
            [submissionImageUrls, autoSavedValue.imageUrls],
            [submissionImages, autoSavedValue.images],
            [submissionVideoUrl, autoSavedValue.videoUrl],
            [submissionVideoUuid, autoSavedValue.videoUuid],
            [submissionUrl, autoSavedValue.url],
            [submissionArticle, autoSavedValue.article],
            [submissionNoteColor, autoSavedValue.noteColor],
            [submissionPublicationStatus, autoSavedValue.publicationStatus],
            [submissionIsCommentable, autoSavedValue.isCommentable],
            [submissionIsShareable, autoSavedValue.isShareable],
            [submissionIsTranslatable, autoSavedValue.isTranslatable],
          ])
        );
        setHasCheckedAutoSave(true);
        removeOverlay({ id: ResumeAutoSaveDialogID });
      },
    });

    return false;
  }, [
    addOverlay,
    autoSaveEnabled,
    autoSavedValue,
    removeOverlay,
    removeAutoSavedValue,
  ]);

  const isFormStateEmpty = useCallback(
    (formState) =>
      !formState ||
      (formState.channelIds?.length === 0 &&
        !formState.title &&
        !formState.description &&
        !formState.url &&
        (!formState.noteColor || formState.noteColor === noteColors[0]) && // consider untouched if set as initial value
        (!formState.article || formState.article === '<p><br></p>') && // default value for article body
        !formState.videoUrl &&
        formState.images?.length === 0),
    [noteColors]
  );

  useEffect(() => {
    if (hasCheckedAutoSave) return;

    if (
      !autoSavedValue ||
      autoSavedValue?.contentId || // ignore auto saved value with contentId since this is a creation page
      autoSavedValue.type !== contentType ||
      isFormStateEmpty(autoSavedValue)
    ) {
      setHasCheckedAutoSave(true);
      return;
    }

    promptResumeAutoSave();
  }, [
    autoSavedValue,
    contentType,
    hasCheckedAutoSave,
    isFormStateEmpty,
    promptResumeAutoSave,
  ]);

  return hasCheckedAutoSave ? (
    <ContentSubmission
      initialState={initialFormState}
      contentType={contentType}
      onSubmitSuccess={removeAutoSavedValue}
    />
  ) : (
    <LoadingOverlay />
  );
};

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(undefined, mapDispatchToProps)(ContentSubmissionNew);
