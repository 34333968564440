import useSWRInfinite from 'swr/infinite';
import MojoApiFactory from '../../config/api-factory';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import {
  Notification,
  NotificationCenterTabs,
  NotificationMeta,
} from '../../models/notifications/types';
import { useSetRecoilState } from 'recoil';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import { NotificationContext } from './context';

export enum NotificationFeedTypes {
  MESSAGES = 'message',
  ACTIVITY = 'activity',
  ARCHIVED = 'archived',
}

const PAGE_SIZE = 10;

const mapTabToFeedType = (tab: NotificationCenterTabs) => {
  return {
    [NotificationCenterTabs.MESSAGES]: NotificationFeedTypes.MESSAGES,
    [NotificationCenterTabs.ACTIVITY]: NotificationFeedTypes.ACTIVITY,
    [NotificationCenterTabs.ARCHIVE]: NotificationFeedTypes.ARCHIVED,
  }[tab];
};

export const useNotificationFeed = () => {
  const {
    activeTab: [activeTab],
    filterTags: [filterTags],
    selectAll: [, setSelectAll],
  } = useContext(NotificationContext);

  const setNotificationCounts = useSetRecoilState(notificationCountsState);

  const feedType = useMemo(() => mapTabToFeedType(activeTab), [activeTab]);

  const {
    data: pages,
    error,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite(
    (
      pageIndex: number,
      previousPageData: AxiosResponse<{
        data: Notification[];
        meta: NotificationMeta;
      }>
    ) => {
      // check if there are more pages to fetch by looking at whether there was any data in the prev page
      // not using 'totalPages' since we cant trust the api to return consistently across all pages over time
      // (notifications can be added, server max page size can be changed, ect. It would be an appropriate technique if this was not infinite loading)
      if (previousPageData && !previousPageData.data.data.length) return null;

      return `${feedType}=true${filterTags
        .map((tag) => `&${tag}=true`)
        .join('')}&page=${pageIndex + 1}&page_size=${PAGE_SIZE}`;
    },
    async (urlParams) => {
      return (await MojoApiFactory.notifications.get(
        `notifications/?${urlParams}`
      )) as AxiosResponse<{
        data: Notification[];
        meta: NotificationMeta;
      }>;
    }
  );

  const fetchNextPage = useCallback(() => {
    setSelectAll((prev) => (prev === true ? 'indeterminate' : prev));
    setSize((prev) => prev + 1);
  }, [setSize, setSelectAll]);

  const canLoadMore = useMemo(() => {
    const lastPage = pages && pages[pages.length - 1];
    const lastPageIsFull = lastPage?.data.data.length === PAGE_SIZE;

    return lastPageIsFull;
  }, [pages]);

  //this is the 2nd place ive used this pattern, the other is in use-content-likes.ts. If we keep using SWR, it might be worth making an abstraction for this.
  //idea: isLoading is always false for any page other than the first.
  //solution taken from https://swr.vercel.app/examples/infinite-loading, which is the example from the swr docs.
  const isLoadingMore = useMemo(
    () =>
      isLoading ||
      (size > 0 && pages && typeof pages[size - 1] === 'undefined'),
    [isLoading, size, pages]
  );

  useEffect(() => {
    if (pages && pages[0].data.meta.counts) {
      setNotificationCounts(pages[0].data.meta.counts);
    }
  }, [pages, setNotificationCounts]);

  return {
    feed: pages ? pages.map((page) => page.data.data).flat() : [],
    error,
    isLoading,
    canLoadMore,
    isLoadingMore,
    fetchNextPage,
  };
};
