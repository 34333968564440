import { useMemo } from 'react';
import { Spinner } from '../ui';
import ContentItem from '../content-item';
import ViewTrigger from '../view-trigger';
import EndOfFeed from './end-of-feed';
import { ContentCard } from '../v2/content-card/content-card';
import { Feature } from '../../models/features/features';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../skip-to-content/skip-to-content';
import { Content } from '../../models/content/types';
import useFeatureFlag from '../../common/use-feature-flag';
import EmptyFeed from './empty-feed';
import { TopicPageEmptyState } from '../v2/topic-page/empty-states/topic-page-empty-state';
import Icon from '../ui/icon';
import { useTranslation } from 'react-i18next';

type FeedProps = {
  items: Content[];
  page: number;
  fetching: boolean;
  contentTitleTag: keyof HTMLElementTagNameMap;
  canLoadMore: boolean;
  fetchFeed: () => void;
  analyticsData: IAnalyticsData;
  emptyFeed?: JSX.Element;
};

const Feed = ({
  items,
  page,
  fetching: isFetching,
  contentTitleTag,
  canLoadMore,
  fetchFeed,
  analyticsData,
  emptyFeed = <EmptyFeed />,
}: FeedProps) => {
  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);
  const classes = newUIEnabled ? 'feed-v2' : 'feed';

  const renderContentItems = useMemo(() => {
    return items.map((content, idx) => (
      <ContentItem
        contentId={content.id}
        index={idx}
        shouldTrackView={true}
        analyticsData={analyticsData}
        key={content.id}
        titleTag={contentTitleTag}
      />
    ));
  }, [items, analyticsData, contentTitleTag]);

  const renderContentItemsV2 = useMemo(() => {
    return items.map((content) => (
      <ContentCard.Horizontal
        content={content}
        titleTag={contentTitleTag}
        analyticsData={analyticsData}
        key={content.id}
        toolbarMax={4}
        compact={true}
      />
    ));
  }, [items, contentTitleTag, analyticsData]);

  return (
    <section className={classes} id={DEFAULT_SKIP_TO_CONTENT_ID}>
      {page ? (newUIEnabled ? renderContentItemsV2 : renderContentItems) : null}
      {isFetching ? (
        <Spinner />
      ) : canLoadMore ? (
        <ViewTrigger offset="600px" onInview={() => fetchFeed()} />
      ) : items.length ? (
        <EndOfFeed />
      ) : (
        emptyFeed
      )}
    </section>
  );
};

export default Feed;
