import React from 'react';
import isEqual from 'lodash/isEqualWith';

const withRerenderPrevent = (Component) =>
  React.memo(Component, (prevProps, nextProps) => {
    const { match: { params = {} } = {} } = prevProps || {};
    const { match: { params: nextParams = {} } = {} } = nextProps || {};

    return isEqual(params, nextParams);
  });

export default withRerenderPrevent;
