import { useCallback, useLayoutEffect, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

// from usehooks-ts lib
// https://usehooks-ts.com/react-hook/use-element-size

interface Size {
  width: number;
  height: number;
  scrollHeight: number;
  scrollWidth: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
  T | null
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
    scrollHeight: 0,
    scrollWidth: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
      scrollHeight: ref?.scrollHeight || 0,
      scrollWidth: ref?.scrollWidth || 0,
    });
  }, [
    ref?.offsetHeight,
    ref?.offsetWidth,
    ref?.scrollHeight,
    ref?.scrollWidth,
  ]);

  useEventListener('resize', handleSize);

  useLayoutEffect(() => {
    handleSize();
  }, [
    handleSize,
    ref?.offsetHeight,
    ref?.offsetWidth,
    ref?.scrollHeight,
    ref?.scrollWidth,
  ]);

  return [setRef, size, ref];
}

export default useElementSize;
