import { createSelector } from 'reselect';

import i18n from '../../config/i18n';
import { timeAgoOrDate } from '../../lib/date-formatter';
import { hashVal } from '../../lib/array-utils';
import type { RootPatronState } from '../../common/use-patron-selector';
import * as helpers from './helpers';

interface ContentIdParam {
  id?: number | undefined;
  contentId?: number | undefined;
}

const allContents = (state: RootPatronState) => state.contents;
const getContentId = (_: unknown, props: ContentIdParam) =>
  props.id || props.contentId || -1;

export const getContentById = createSelector(
  [allContents, getContentId],
  (contents, id) => contents[id]
);

export const getContentType = createSelector(
  [getContentById],
  (content) => content.content_type
);

export const getContentIsPublished = createSelector(
  [getContentById],
  (content) => content.publication_state === 'published'
);

export const getContentIsPending = createSelector(
  [getContentById],
  (content) => content.publication_state === 'for_review'
);

export const getContentIsArchived = createSelector(
  [getContentById],
  (content) => content.publication_state === 'archived'
);

export const getContentIsProcessing = createSelector(
  [getContentById],
  (content) => content.processing_state !== 'completed'
);

export const getContentTags = createSelector(
  [getContentById],
  (content) => content.buttons || []
);

export const getContentTitle = createSelector(
  [getContentById],
  (content) => content.title
);

export const getContentSummary = createSelector(
  [getContentById],
  (content) => content.description
);

//applies the logic for deriving what to display for titling a piece of content
export const getContentFunctionalTitle = createSelector(
  [getContentTitle, getContentSummary],
  (title, summary) => title || summary
);

export const getContentTitleOrSummary = createSelector(
  [getContentTitle, getContentSummary],
  (title, summary) => title || summary || i18n.t('content.no_description')
);

export const getContentImages = createSelector(
  [getContentById],
  (content) => content.shareable_image_urls && content.shareable_image_urls
);

export const getContentImageUrls = createSelector(
  [getContentById],
  (content) =>
    content.shareable_image_urls &&
    content.shareable_image_urls.map((img) => img.url)
);

export const getContentImageUrl = createSelector(
  [getContentById, getContentImageUrls],
  (content, imageUrls) =>
    imageUrls ? imageUrls[0] : content.shareable_image_url
);

export const getContentImageAlt = createSelector(
  [getContentById],
  (content) => content.source_image_alt
);

export const getContentThumbnailUrls = createSelector(
  [getContentById],
  (content) =>
    content.background_image_urls &&
    content.background_image_urls.map((img) => img.url)
);

export const getContentThumbnailUrl = createSelector(
  [getContentById, getContentThumbnailUrls],
  (content, imageUrls) =>
    imageUrls ? imageUrls[0] : content.background_image_url
);

export const getContentExternalUrl = createSelector(
  [getContentById],
  (content) =>
    content.external_content_url.length ? content.external_content_url : null
);

export const getContentInternalUrl = createSelector(
  [getContentById],
  (content) =>
    content.internal_content_url ? content.internal_content_url : null
);

export const getContentPublishedAt = createSelector(
  [getContentById, getContentIsPublished],
  (content, isPublished) => isPublished && content.published_at
);

export const getContentReadTime = createSelector(
  [getContentById, getContentIsPublished],
  (content, isPublished) => isPublished && content.read_time_in_seconds
);

export const getContentUuid = createSelector(
  [getContentById],
  (content) => content.uuid
);

export const getContentPublishedDate = createSelector(
  [getContentPublishedAt],
  (publishedAt) => publishedAt && timeAgoOrDate(publishedAt)
);

export const getContentLiked = createSelector(
  [getContentById],
  (content) => content.liked
);

export const getContentLikeCount = createSelector(
  [getContentById],
  (content) => (content.reaction_counts && content.reaction_counts.likes) || 0
);

export const getContentViewCount = createSelector(
  [getContentById],
  (content) => (content.reaction_counts && content.reaction_counts.views) || 0
);

export const getContentBookmarked = createSelector(
  [getContentById],
  (content) => content.bookmarked
);

export const getContentCommentCount = createSelector(
  [getContentById],
  (content) => content.comment_count || 0
);

export const getContentShared = createSelector([getContentById], (content) =>
  content
    ? Boolean(
        content.shared ||
          content.shared_to_social_networks.length ||
          content.scheduled_engagements.length
      )
    : false
);

export const getContentIsUserSubmitted = createSelector(
  [getContentById],
  helpers.getContentIsUserSubmitted
);

export const getContentAuthorId = createSelector(
  [getContentById],
  (content) => content.author_id
);

export const getContentAuthorName = createSelector(
  [getContentById],
  helpers.getContentAuthorName
);

export const getContentAuthorAvatarSrc = createSelector(
  [getContentById],
  helpers.getContentAuthorAvatarSrc
);

export const getContentAuthorAvatarColor = createSelector(
  [getContentById],
  helpers.getContentAuthorAvatarColor
);

export const getContentIsLink = createSelector(
  [getContentType],
  (contentType) => contentType === 'link'
);

export const getContentIsImage = createSelector(
  [getContentType],
  (contentType) => contentType === 'image'
);

export const getContentIsVideo = createSelector(
  [getContentType],
  (contentType) => contentType === 'video'
);

export const getContentIsNote = createSelector(
  [getContentType],
  (contentType) => contentType === 'note'
);

export const getContentIsArticle = createSelector(
  [getContentType],
  (contentType) => contentType === 'article'
);

export const getContentIsPortrait = createSelector(
  [getContentById],
  (content) => content.orientation === 'portrait'
);

export const getContentIsSquare = createSelector(
  [getContentById],
  (content) => content.orientation === 'square'
);

export const getContentIsMultiImage = createSelector(
  [getContentImageUrls, getContentIsImage],
  (imageUrls: string[] | false, isImage: boolean) =>
    imageUrls && imageUrls.length > 1 && isImage
);

export const getContentIsExternal = createSelector(
  [getContentIsNote, getContentInternalUrl],
  (isNote, url) => !(isNote || url)
);

export const getContentVideoEmbed = createSelector(
  [getContentById, getContentIsVideo, getContentIsExternal],
  (content, isVideo) => (isVideo ? content.video.embed_html : null)
);

export const getContentVideoUuid = createSelector(
  [getContentById, getContentIsVideo],
  (content, isVideo) => (isVideo ? content.video.uuid : null)
);

export const getContentDetailUrl = createSelector(
  [getContentId],
  helpers.getContentDetailPath
);

export const getContentBorderColor = createSelector(
  [getContentById],
  (content) => content.border_color
);

export const getContentIsHtmlNote = createSelector(
  [getContentById, getContentIsNote],
  (content, isNote) => isNote && content?.note_settings?.text_format === 'html'
);

export const getContentIsCompleted = createSelector(
  [
    getContentById,
    getContentIsArticle,
    getContentIsLink,
    getContentIsMultiImage,
    getContentIsVideo,
  ],
  (content, isArticle, isLink, isImage, isVideo) => {
    if (isArticle || isLink || isImage || isVideo)
      return !!content.is_completed;
    else return true;
  }
);

// TODO: Fill out content.note_settings type
export const getContentNoteSettings = createSelector(
  [getContentById, getContentIsNote],
  (content, isNote): any => (isNote && content.note_settings) || {}
);

export const getContentTitleOrSummaryPlain = createSelector(
  [getContentId, getContentIsHtmlNote, getContentTitleOrSummary],
  (content, isHtmlNote, titleOrSummary) => {
    return isHtmlNote
      ? new DOMParser().parseFromString(titleOrSummary, 'text/html').body
          .textContent || ''
      : titleOrSummary;
  }
);

export const getContentChannels = createSelector(
  [getContentById],
  (content) => content.content_channels
);

export const getContentChannelIds = createSelector(
  [getContentById],
  (content) => content.content_channel_ids
);

export const getContentPermalinkUrl = createSelector(
  [getContentById],
  (content) => content.permalink_url
);

export const getContentShareText = createSelector(
  [getContentById, getContentTitle],
  (content, title) => content.prepopulated_text || title
);

export const getContentShareHashtag = createSelector(
  [getContentById],
  (content) => content.appended_hashtag
);

export const getContentCanLike = createSelector(
  [getContentIsPublished],
  (isPublished) => isPublished
);

export const getContentCanBookmark = createSelector(
  [getContentIsPublished],
  (isPublished) => isPublished
);

export const getContentCanComment = createSelector(
  [getContentById, getContentIsPublished],
  (content, isPublished) => content.is_commentable && isPublished
);

export const getContentCanShare = createSelector(
  [getContentById, getContentIsPublished],
  (content, isPublished) => content.can_share && isPublished
);

export const getContentCanEdit = createSelector(
  [getContentById, getContentIsArchived, getContentIsProcessing],
  (content, isArchived, isProcessing) =>
    content.is_editable && !isArchived && !isProcessing
);

export const getContentCanTranslate = createSelector(
  [getContentById, getContentIsPublished],
  (content, isPublished) => content.is_translatable && isPublished
);

export const getContentIsTranslated = createSelector(
  [getContentById, getContentIsPublished],
  (content) => content.display_language === i18n.language
);

export const getContentLang = createSelector(
  [getContentById],
  (content) => content.display_language || content.language
);

export const getContentAcknowledgement = createSelector(
  [getContentById],
  (content) => content.acknowledgement
);

export const getContentUseCoverTextFromContent = createSelector(
  [getContentById],
  (content) => content.use_cover_text_from_content
);

export const getContentCanAcknowledge = createSelector(
  [getContentAcknowledgement],
  (acknowledgement) => !!acknowledgement
);

export const getContentCanFlag = createSelector(
  [getContentById],
  (content) => !!content.is_flaggable
);

export const getContentDefaultImage = createSelector(
  [getContentUuid],
  (uuid) => {
    const fallbackImageUrls = [
      '/images/content_default0.png',
      '/images/content_default1.png',
      '/images/content_default2.png',
      '/images/content_default3.png',
    ];

    const fallbackIndex = hashVal(uuid || '', fallbackImageUrls.length);
    return fallbackImageUrls[fallbackIndex];
  }
);

export default {
  getContentById,
  getContentType,
  getContentTags,
  getContentTitle,
  getContentFunctionalTitle,
  getContentSummary,
  getContentTitleOrSummary,
  getContentImages,
  getContentImageUrls,
  getContentImageUrl,
  getContentImageAlt,
  getContentThumbnailUrls,
  getContentThumbnailUrl,
  getContentExternalUrl,
  getContentInternalUrl,
  getContentDetailUrl,
  getContentVideoEmbed,
  getContentVideoUuid,
  getContentPublishedDate,
  getContentLiked,
  getContentLikeCount,
  getContentViewCount,
  getContentBookmarked,
  getContentCommentCount,
  getContentShared,
  getContentAuthorId,
  getContentAuthorName,
  getContentAuthorAvatarSrc,
  getContentAuthorAvatarColor,
  getContentIsLink,
  getContentIsImage,
  getContentIsVideo,
  getContentIsNote,
  getContentIsArticle,
  getContentIsPortrait,
  getContentIsSquare,
  getContentIsMultiImage,
  getContentIsExternal,
  getContentIsPublished,
  getContentIsPending,
  getContentIsArchived,
  getContentIsProcessing,
  getContentIsHtmlNote,
  getContentIsCompleted,
  getContentTitleOrSummaryPlain,
  getContentBorderColor,
  getContentChannels,
  getContentChannelIds,
  getContentPermalinkUrl,
  getContentShareText,
  getContentShareHashtag,
  getContentCanLike,
  getContentCanBookmark,
  getContentCanComment,
  getContentCanShare,
  getContentCanEdit,
  getContentCanTranslate,
  getContentCanFlag,
  getContentIsTranslated,
  getContentLang,
  getContentNoteSettings,
  getContentReadTime,
  getContentPublishedAt,
  getContentUuid,
  getContentAcknowledgement,
  getContentCanAcknowledge,
  getContentDefaultImage,
  getContentUseCoverTextFromContent,
};
