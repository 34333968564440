import React from 'react';
import EmojiPickerCategories from './EmojiPickerCategories';
import EmojiPickerList from './EmojiPickerList';
import './emoji-picker.scss';

class EmojiPicker extends React.Component {
  state = {
    category: 'People',
  };

  render() {
    return (
      <div className="emoji-picker">
        <EmojiPickerCategories
          activeCategory={this.state.category}
          onCategoryClick={this.handleCategoryClick}
        />
        <EmojiPickerList
          category={this.state.category}
          onEmojiClick={this.handleEmojiClick}
        />
      </div>
    );
  }

  handleEmojiClick = (emoji) => {
    this.props.onClick(emoji);
  };

  handleCategoryClick = (category) => {
    this.setState({ category });
  };
}

export default EmojiPicker;
