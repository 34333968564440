import * as types from './keys';
import { track } from './helpers';

export const trackHomeLoad = (traits) => {
  track(types.HOME_LOAD, traits);
};

export const trackHomeClick = (traits) => {
  track(types.HOME_CLICK, traits);
};

export const trackQuickLinksClick = (traits) => {
  track(types.QUICK_LINKS_CLICK, traits);
};

export const trackInviteClick = (traits) => {
  track(types.INVITE_USER_CTA_CLICK, traits);
};

export const trackProfileView = (traits) => {
  track(types.PROFILE_VIEW, traits);
};

export const trackProfileClick = (traits) => {
  track(types.PROFILE_CLICK, traits);
};

export const trackShortcutClick = (traits) => {
  track(types.SHORTCUT_CLICK, traits);
};

export const trackShortcutView = (traits) => {
  track(types.SHORTCUT_VIEW, traits);
};

export const trackDocumentClick = (traits) => {
  track(types.DOCUMENT_CLICK, traits);
};

export const trackDocumentView = (traits) => {
  track(types.DOCUMENT_VIEW, traits);
};

//
// Assistant
//

export const trackSearchClick = (traits) => {
  track(types.SEARCH_CLICK, traits);
};

export const trackSearchLoad = (traits) => {
  track(types.SEARCH_LOAD, traits);
};

export const trackSearchSuccess = (traits) => {
  track(types.SEARCH_SUCCESS, traits);
};

export const trackSearchError = (traits) => {
  track(types.SEARCH_ERROR, traits);
};

export const trackRecentSearchClick = (traits) => {
  track(types.RECENT_SEARCH_CLICK, traits);
};

export const trackFeedViewAllClick = (traits) => {
  track(types.FEED_VIEW_BUTTON_CLICK, traits);
};

export const trackChannelViewClick = (traits) => {
  track(types.CHANNEL_VIEW_BUTTON_CLICK, traits);
};

export const trackContentCardTopicPillClick = (traits) => {
  track(types.CONTENT_CARD_TOPIC_PILL_CLICK, traits);
};

export const trackContentCardMoreTopicsClick = (traits) => {
  track(types.CONTENT_CARD_MORE_TOPICS_CLICK, traits);
};

export const trackPreviousSearchClick = (traits) => {
  track(types.SEARCH_PREVIOUS_SEARCH_CLICK, traits);
};

export const trackTrendingSearchClick = (traits) => {
  track(types.SEARCH_TRENDING_SEARCH_CLICK, traits);
};

export const trackSearchSortClick = (traits) => {
  track(types.SEARCH_SORT_CLICK, traits);
};

export const trackSearchFilterClick = (traits) => {
  track(types.SEARCH_FILTER_CLICK, traits);
};

export const trackSearchClearFilterClick = (traits) => {
  track(types.SEARCH_CLEAR_FILTER_CLICK, traits);
};

export const trackSearchCategoryClick = (traits) => {
  track(types.SEARCH_CATEGORY_CLICK, traits);
};

export const trackSearchPreviewResultClick = (traits) => {
  track(types.SEARCH_PREVIEW_RESULT_CLICK, traits);
};

export const trackSearchPreviewViewAllClick = (traits) => {
  track(types.SEARCH_PREVIEW_VIEW_ALL_CLICK, traits);
};

export const trackSearchContentCardView = (traits) => {
  track(types.SEARCH_CONTENT_CARD_VIEW, traits);
};

export const trackSearchChannelCardView = (traits) => {
  track(types.SEARCH_CHANNEL_CARD_VIEW, traits);
};
