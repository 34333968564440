import { Trackable } from '../Trackable';
import { Author } from '../Author';
import { Button } from '../Button';
import { Actionable } from '../Actionable';
import { InboxItem } from '../InboxItem';
import { ResultItem } from '../ResultItem';

export const subjectTypes = {
  html_blob: 'html_blob',
  notification: 'notification',
  poll: 'poll',
  content_approval: 'content_approval',
  content_acknowledgement: 'content_acknowledgement',
  summary: 'summary',
  link: 'link',
  button_list: 'button_list',
} as const;

export type SubjectType = keyof typeof subjectTypes;

//carousel: items
//summary: title, desc, data_table

export interface Card extends Trackable {
  id?: string;
  type: SubjectType;
  expired_at?: string;
  title?: string; //do not send html here
  data_table?: string[][];
  description?: string; //do not send html here
  background_color?: string;
  html_blob?: string;
  image_url?: string; //deprecate in favor of image object
  image_background_color?: string; //deprecate in favor of image object
  image?: {
    url: string;
    background_color?: string;
  };
  banner_url?: string;
  max_selections?: number;
  selection?: string;
  selection_type?: string;
  author?: Author;
  buttons?: Button[];
  action?: Actionable;
  inbox_items?: InboxItem[];
  poll_result?: ResultItem[];
  // NOTE: Below is only here for now to support v3.9.0 notifications
  deeplink_url?: string;
  link?: { [key: string]: any };
  // ^^^
}
