import { ChangeEventHandler, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import useFeatureFlag from '../../../common/use-feature-flag';
import TextInput, { Textarea } from '../../../components/ui/text-input';
import {
  submissionDescription,
  submissionIsDescriptionOverLimit,
  submissionIsSubmitting,
} from '../../../models/content-submission/atoms';
import { Feature } from '../../../models/features/features';
import {
  PlainEditor,
  PlainEditorProps,
} from '../../../components/ui/plain-editor';

import './description.scss';

type DescriptionInputProps = ComponentProps<typeof Textarea>;

const MAXIMUM_CHAR_LENGTH = 250;

const trackingContext: PlainEditorProps['trackingContext'] = {
  contentSection: 'description',
};

const DescriptionInput = (props: DescriptionInputProps) => {
  const tagUserEnabled = useFeatureFlag(Feature.TAG_USER_ENABLED);

  const [description, setDescription] = useRecoilState(submissionDescription);
  const [, setIsDescriptionOverLimit] = useRecoilState(
    submissionIsDescriptionOverLimit
  );
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setDescription(e.target.value);
  };

  const onTextChange = (text: string) => {
    setDescription(text);
    setIsDescriptionOverLimit(text.length > MAXIMUM_CHAR_LENGTH);
  };

  if (!tagUserEnabled) {
    return (
      <TextInput
        className="content-submission-input-description"
        name="description"
        value={description || ''}
        placeholder={t('content_submission.field_description')}
        multiline
        maxRows={15}
        maxLength={500}
        isDisabled={isSubmitting}
        onChange={onTextAreaChange}
        {...props}
      />
    );
  }

  return (
    <PlainEditor
      className="content-submission-input-description"
      defaultValue={description || ''}
      maximumCharacter={MAXIMUM_CHAR_LENGTH}
      onChange={onTextChange}
      placeholder={t('content_submission.field_description')}
      trackingContext={trackingContext}
    />
  );
};

export default DescriptionInput;
