import { lazy, Suspense } from 'react';
import { Props } from './types';
import { Spinner } from '../index';

const ImageCarouselLazy = lazy(() => import('./ImageCarouselLazy'));

export function ImageCarousel({ images, onSelect }: Props) {
  return (
    <Suspense fallback={<Spinner />}>
      <ImageCarouselLazy images={images} onSelect={onSelect} />
    </Suspense>
  );
}
