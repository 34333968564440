import { useEffect, useState } from 'react';
import cx from 'classnames';

import { Content } from '../../../models/content/types';
import ContentCardPlaceholder from '../../../components/placeholders/content-card-placeholder';
import FeedHeader, { FeedHeaderProps } from './feed-header';
import { ContentCard, ContentCardProps } from '../content-card/content-card';

import './content-layouts.scss';

interface IGridLayoutList {
  contentTitleTag?: ContentCardProps['titleTag'];
  contents: Content[];
  limit: number;
  analyticsData: ContentCardProps['analyticsData'];
}

const GridLayoutList = ({
  contentTitleTag = 'h2',
  contents,
  limit,
  analyticsData,
}: IGridLayoutList) => {
  const className = cx('content-layout__grid');
  const mediaQuery = '(max-width: 500px)';

  const [narrowViewport, setNarrowViewport] = useState(
    window.matchMedia(mediaQuery).matches
  );

  contents = limit ? contents.slice(0, limit) : contents;
  useEffect(() => {
    window
      .matchMedia(mediaQuery)
      //we need to keep using this deprecated method for safari 13 support. even though its in our browserlist, this isnt being polyfilled for some reason.
      //this method is essentially a proxy for the proper method, addEventListener('change', ...), but we can trust it exists whereas we cant for addEventListener
      //https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
      .addListener((e) => setNarrowViewport(e.matches));
  }, []);

  const CardComponent = narrowViewport
    ? ContentCard.Vertical
    : ContentCard.Horizontal;

  return (
    <div className={className}>
      {contents.map((content) => (
        <CardComponent
          titleTag={contentTitleTag}
          content={content}
          toolbarMax={2}
          analyticsData={analyticsData}
          key={content.id}
          compact={true}
        />
      ))}
    </div>
  );
};

type ContentLayoutGridProps = FeedHeaderProps & {
  description: string;
  viewAllText: string;
  feedUrl: string;
  contents: any;
  minLength: number;
  maxLength: number;
  isFetching: boolean;
  isTopic: boolean;
  contentTitleTag?: keyof HTMLElementTagNameMap;
};

const ContentLayoutGrid = ({
  id,
  title,
  description,
  viewAllText,
  feedUrl,
  contents,
  minLength,
  maxLength,
  isFetching,
  analyticsData,
  isTopic = false,
  contentTitleTag = 'h2',
}: ContentLayoutGridProps) => {
  const className = cx('home-filtered-feed', 'grid-layout-bkgd', {
    'home-filtered-feed--loading': isFetching,
  });

  if (!isFetching && contents.length < minLength) return null;

  return (
    <div className={className}>
      <FeedHeader
        id={id}
        title={title}
        description={description}
        viewAllLink={feedUrl}
        viewAllText={viewAllText}
        isTopic={isTopic}
        analyticsData={analyticsData}
      />
      {isFetching ? (
        <div className="content-list content-list--landscape">
          {[...Array(maxLength)].map((_, idx) => (
            <ContentCardPlaceholder key={idx} />
          ))}
        </div>
      ) : (
        <GridLayoutList
          contentTitleTag={contentTitleTag}
          contents={contents}
          limit={maxLength}
          analyticsData={analyticsData}
        />
      )}
    </div>
  );
};

export default ContentLayoutGrid;
