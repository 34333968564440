import { useTranslation } from 'react-i18next';

import { Link } from '../ui';

const ViewAllLink = ({ href, onClick, children }) => {
  const { t } = useTranslation();

  return (
    <div className="view-all-link">
      <Link href={href} action="push" onClick={onClick}>
        {children || t('assistant.view_all')}
      </Link>
    </div>
  );
};
export default ViewAllLink;
