import actions from './actions';

const displayOverlay = actions.displayOverlay;
const addOverlay = actions.addOverlay;
const removeOverlay = actions.removeOverlay;
const addFlashMessage = actions.addFlashMessage;
const removeFlashMessage = actions.removeFlashMessage;
const hideHeader = actions.hideHeader;
const showHeader = actions.showHeader;
const minimizeHeader = actions.minimizeHeader;
const maximizeHeader = actions.maximizeHeader;
const logoOnlyHeader = actions.logoOnlyHeader;
const defaultHeader = actions.defaultHeader;
const hideLoading = actions.hideLoading;
const showLoading = actions.showLoading;

export default {
  displayOverlay,
  addOverlay,
  removeOverlay,
  addFlashMessage,
  removeFlashMessage,
  hideHeader,
  showHeader,
  minimizeHeader,
  maximizeHeader,
  logoOnlyHeader,
  defaultHeader,
  hideLoading,
  showLoading,
};
