import { ComponentProps, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { contentSelectors } from '../../../models/content/index.js';

import ContentSourceInfo from '../../../components/content/content-source-info';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { RootPatronState } from '../../../common/use-patron-selector';

type OwnProps = Pick<ComponentProps<typeof ContentSourceInfo>, 'contentId'> & {
  onResize?: () => void;
};

type ContentTypeNoteProps = ReturnType<typeof mapStateToProps> & OwnProps;

const ContentTypeNote = ({
  contentId,
  contentBorderColor,
  settings,
  description,
  onResize,
}: ContentTypeNoteProps) => {
  useEffect(() => {
    onResize?.();
  }, [onResize]);

  const fontMap = {
    serif: "'Merriweather', serif",
    sans: "'Rubik', sans-serif",
    sans_condensed: "'Roboto Condensed', sans-serif",
    sans_heavy: "'Montserrat', sans-serif",
    slab: "'BioRhyme', serif",
    mono: "'Space Mono', monospace",
    handwriting: "'Mali', cursive",
    cursive: "'Pacifico', cursive",
  };

  const newDetailPage = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  const backgroundColor =
    (settings.background && settings.background.color) || 'white';

  const borderColor = settings.border
    ? settings.border.color
    : settings.background
    ? 'transparent'
    : contentBorderColor;

  const color = settings.text_color || 'black';
  const fontFamily = fontMap[settings.text_font_family as keyof typeof fontMap];
  const textAlign = settings.text_justification;
  const fontSize =
    settings.text_size &&
    `${settings.text_size.value}${settings.text_size.unit}`;

  return (
    <div className="content-frame" style={{ backgroundColor: borderColor }}>
      <div className="content-frame__inner" style={{ backgroundColor }}>
        <div
          className="note-description"
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ color, fontFamily, textAlign, fontSize }}
        ></div>

        {!newDetailPage && <ContentSourceInfo contentId={contentId} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  contentBorderColor: contentSelectors.getContentBorderColor(state, ownProps),
  settings: contentSelectors.getContentNoteSettings(state, ownProps),
  description: contentSelectors.getContentSummary(state, ownProps),
});

export default connect(mapStateToProps)(ContentTypeNote);
