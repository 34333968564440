import SearchBar from '../search-bar';
import './search-header.scss';

const SearchHeader = ({ title, baseUrl = '/search/contents', term }) => (
  <header className="search-header pt-wallpaper-bg--gradient">
    <h1 className="search-header__title">{title}</h1>

    <SearchBar baseUrl={baseUrl} term={term} />
  </header>
);

export default SearchHeader;
