import { HappyInbox } from '../ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  AssistantBootstrapEmptyInboxAction,
  getBootstrapEmptyInboxActions,
} from '../../models/assistant/selectors';
import { Spinner } from '../ui';
import { ActionNavigation, NavigationReferer } from './assistant-action';
import './assistant.scss';
import useRandomId from '../../common/use-random-id';

const AssistantInboxEmptyAction = ({
  action,
}: {
  action: AssistantBootstrapEmptyInboxAction;
}) => {
  const { id: titleId } = useRandomId();
  const { id: descriptionId } = useRandomId();

  return (
    <ActionNavigation
      navigation={action.action.navigation}
      pushState={{
        referer: NavigationReferer.INBOX,
      }}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <div className={'empty-inbox-action'}>
        <img
          src={action.icon}
          alt={action.title}
          className={'empty-inbox-action__icon'}
          role="presentation"
        />
        <div className={'empty-inbox-action__body'}>
          <div id={titleId} className={'empty-inbox-action__title'}>
            {action.title}
          </div>
          <div id={descriptionId} className={'empty-inbox-action__description'}>
            {action.description}
          </div>
        </div>
      </div>
    </ActionNavigation>
  );
};

type AssistantInboxEmptyProps = {
  id?: string;
};

const AssistantInboxEmpty = ({ id }: AssistantInboxEmptyProps) => {
  const { t } = useTranslation();
  const emptyInboxActions = useSelector(getBootstrapEmptyInboxActions);

  return (
    <div id={id} className="assistant__inbox-empty">
      <div className={'assistant__inbox-empty-inbox'}>
        <HappyInbox role="presentation" />
        <p>{t('assistant.empty_inbox')}</p>
      </div>
      <div
        aria-busy={!emptyInboxActions}
        className={'assistant__inbox-empty-actions-container'}
      >
        {emptyInboxActions ? (
          emptyInboxActions.map((item, index) => (
            <AssistantInboxEmptyAction key={index} action={item} />
          ))
        ) : (
          <Spinner center />
        )}
      </div>
    </div>
  );
};

export default AssistantInboxEmpty;
