import cx from 'classnames';
import { ComponentProps, useState } from 'react';
import { ChannelFollowersResponse } from '../../../models/channels/types';
import { Tooltip } from '../index';
import styles from './styles.module.scss';

const defaultSrc =
  'https://lib.socialchorus.com/mojo/public/default_avatar_new.png';

type AvatarProps = ComponentProps<'div'> & {
  src?: string;
  bgColor?: string;
  className?: string;
  id?: string;
  isInList?: boolean;
  alt?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

function Avatar({ src, isInList, id, alt, bgColor, ...props }: AvatarProps) {
  const className = cx(props.className, styles.avatar);
  const padding = isInList ? '0' : undefined;
  const avatarSrc = src || defaultSrc;

  return (
    <div
      style={{
        backgroundImage: `url(${avatarSrc})`,
        borderRadius: '50%',
        padding,
      }}
      {...props}
      className={className}
    >
      <img
        src={avatarSrc}
        alt={alt ?? `User ${id || ''} avatar`}
        className={styles.image}
      />
    </div>
  );
}

interface HoverableAvatarProps {
  isInList?: boolean;
  advocate: Advocate;
  className?: string;
}

export function HoverableAvatar({
  advocate,
  isInList,
  className,
}: HoverableAvatarProps) {
  const [isActive, setIsActive] = useState(false);

  const handleMouseOver = () => {
    setIsActive(true);
  };

  const handleMouseOut = () => {
    setIsActive(false);
  };

  return (
    <>
      <Avatar
        className={className}
        isInList={isInList}
        src={advocate.avatar.url}
        alt={advocate.full_name}
        id={advocate.id}
        bgColor={advocate.avatar.color}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
      />

      {isActive ? (
        <Tooltip>
          <div className={styles.hoverableAvatar}>
            <div
              className={styles.hoverableAvatar__avatar}
              style={{ padding: '7px' }}
            >
              <Avatar
                className={className}
                src={advocate.avatar.url}
                alt={advocate.full_name}
                id={advocate.id}
                bgColor={advocate.avatar.color}
              />
            </div>
            <div className={styles.hoverableAvatar__text}>
              <div className={styles.hoverableAvatar__name}>
                {advocate.full_name || advocate.display_name}
              </div>
              <div className={styles.hoverableAvatar__title}>
                {advocate.job_title}
              </div>
            </div>
          </div>
        </Tooltip>
      ) : null}
    </>
  );
}

type ChannelFollowersAttributes =
  ChannelFollowersResponse['followers']['data'][number]['attributes'];

interface HoverableFollowerAvatarProps {
  follower: Pick<ChannelFollowersResponse['followers']['data'][number], 'id'> &
    ChannelFollowersAttributes;
}

interface Advocate {
  id: string;
  full_name: string;
  display_name: string;
  job_title?: string;
  avatar: {
    url: string;
    color: string;
  };
}

export function HoverableFollowerAvatar({
  follower,
}: HoverableFollowerAvatarProps) {
  const advocate: Advocate = {
    avatar: {
      url: follower.avatar_url!,
      color: follower.avatar_color,
    },
    id: follower.id.toString(),
    full_name: follower.full_name,
    display_name: follower.display_name!,
  };

  return <HoverableAvatar advocate={advocate} />;
}

export default Avatar;
