import { Button } from '@socialchorus/shared-ui-components';
import { MouseEventHandler, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useScreenTitle from '../../../common/use-screen-title';
import { uiOperations } from '../../../models/ui';
import styles from './styles.module.scss';

interface Props {
  errorMessage: string;
}

export function BoxNotFound() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<Props>();
  const isIosWebview = /advomobo/.test(navigator.userAgent);
  const isAndroidWebview = /SocialChorus|advodroid/.test(navigator.userAgent);
  const isMobileWebview = isIosWebview || isAndroidWebview;
  const errorMessage =
    (location?.state?.errorMessage as string) ??
    t('screens.box.not_found.heading');

  useScreenTitle('Not Found');

  const onClickCta: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      history.goBack();
    },
    [history]
  );

  useEffect(() => {
    localStorage.removeItem('lastLocation'); // Make sure users won't be sent here again
  }, []);

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  return (
    <div className={styles.NotFound__Container}>
      <div className={styles.NotFound}>
        <h2>{errorMessage}</h2>
        {!isMobileWebview && (
          <Button
            className={styles.NotFound__Cta}
            variant="text"
            label={t('screens.box.not_found.cta')}
            onClick={onClickCta}
          />
        )}
      </div>
    </div>
  );
}
