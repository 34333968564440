import { useState, useEffect } from 'react';
import AdvocateService from '../services/advocate';

const useProfileOrganizationFetcher = ({ profileId }) => {
  const [organization, setOrganization] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryDone, setQueryDone] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isNotFound, setIsNotFound] = useState();
  const [isError, setIsError] = useState();

  const advocateService = new AdvocateService(profileId);

  const fetchProfileOrganization = async () => {
    if (queryDone) return;

    setIsFetching(true);

    try {
      const res = await advocateService.fetchProfileOrganization({
        page: pageNumber,
      });
      handleFetchSuccess(res);
    } catch (err) {
      if (err.response) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res) => {
    setOrganization([...organization, ...res.data.data]);

    if (res.data.links.next) {
      setPageNumber(pageNumber + 1);
    } else {
      setQueryDone(true);
    }

    setIsFetching(false);
  };

  const handleFetchFailure = (err) => {
    if (err.response.status === 403 || err.response.status === 404) {
      setIsNotFound(true);
    } else {
      setIsError(true);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    setPageNumber(undefined);
    fetchProfileOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return {
    organization,
    getNextPage: fetchProfileOrganization,
    nextPageAvailable: !queryDone,
    isFetching,
    isNotFound,
    isError,
  };
};

export default useProfileOrganizationFetcher;
