/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentSelectors } from '../../../models/content';

import ContentTopics from '../content-topics';

import ContentInfoTitle from '../../../screens/content-details/content-info/content-info-title';
import ContentInfoSummary from '../../../screens/content-details/content-info/content-info-summary';

import {
  ContentSourceAvatar,
  ContentSourceName,
} from '../../../components/content/content-source-info';
import { localizeDate } from '../../../lib/date-formatter';
import { t } from 'i18next';
import { ContentType } from '../../../models/content/types';

interface InfoHeaderProps {
  contentId: number;
  analyticsData: { [key: string]: unknown };
  emailContent: boolean;
  contentType: ContentType;
}

export const InfoHeader: React.FC<InfoHeaderProps> = ({
  contentId,
  emailContent,
  analyticsData,
  contentType,
}) => {
  const topics = usePatronSelector((state) =>
    contentSelectors.getContentChannels(state, { contentId })
  );
  const publishDate = usePatronSelector((state) => {
    const dateVal = contentSelectors.getContentPublishedAt(state, {
      contentId,
    });
    return dateVal && localizeDate(dateVal);
  });
  const readTime =
    usePatronSelector((state) =>
      contentSelectors.getContentReadTime(state, { contentId })
    ) || 60;
  const readTimeIsHours = readTime > 60 * 60; // 60 seconds * 60 minutes
  const readTimeString = t(
    `content.time_to_read.${readTimeIsHours ? 'hours' : 'minutes'}`,
    {
      count: Math.max(
        Math.round(readTimeIsHours ? readTime / 60 / 60 : readTime / 60),
        1
      ),
    }
  );

  const getContentUseCoverTextFromContent = usePatronSelector((state) =>
    contentSelectors.getContentUseCoverTextFromContent(state, { contentId })
  );

  // Determine whether to hide the title. If the option to use content for cover text is enabled, hide for articles. (Its definition implies v2 content. If its false we always show)
  // If its undefined, then hide for note, article and link, as those types will also have the title in the content for legacy content.
  let hideTitle = false;
  if (getContentUseCoverTextFromContent === false) {
    hideTitle = false;
  } else if (getContentUseCoverTextFromContent === true) {
    if (contentType === 'article') {
      hideTitle = true;
    } else {
      hideTitle = false;
    }
  } else {
    if (
      contentType === 'note' ||
      contentType === 'article' ||
      contentType === 'link'
    ) {
      hideTitle = true;
    }
  }

  return (
    <div className="info-header">
      <div className="info-header__source">
        <div className="info-header__source--publish-context">
          <ContentSourceAvatar
            // @ts-ignore (component is written in js and ts cant infer the props for some reason)
            contentId={contentId}
            disableLink={emailContent}
          />
          <ContentSourceName
            /* @ts-ignore */
            contentId={contentId}
            disableLink={emailContent}
            includeBy={false}
          />
          <div className="content__source--date">{publishDate}</div>
          {readTime && (
            <>
              ·
              <div className="content__source--read-time">{readTimeString}</div>
            </>
          )}
        </div>
        <div className="info-header__source--topics">
          <ContentTopics
            contentId={contentId}
            topics={topics}
            analyticsData={analyticsData}
            wideMode
          />
        </div>
      </div>
      {!hideTitle && (
        <div className="info-header__summary">
          <ContentInfoTitle contentId={contentId} />
          <ContentInfoSummary contentId={contentId} />
        </div>
      )}

      <div className="info-header__source--topics-mobile">
        <ContentTopics
          contentId={contentId}
          topics={topics}
          analyticsData={analyticsData}
          wideMode
        />
      </div>
    </div>
  );
};
