import types from './types';
import utils from './utils';

const initialState = {
  flashMessages: [],
  overlays: [],
  hideHeader: false,
  minimizeHeader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_FLASH_MESSAGE:
      return utils.addFlashMessage(state, action.message);

    case types.REMOVE_FLASH_MESSAGE:
      return utils.removeLastFlashMessage(state);

    case types.SET_TOAST_MESSAGE_SHIM:
      return {
        ...state,
        toastMessageShim: action.payload,
      };

    case types.DISPLAY_OVERLAY:
      return utils.replaceOverlay(
        state,
        action.id,
        action.key || action.id,
        action.props || {}
      );

    case types.ADD_OVERLAY:
      return utils.addOverlay(
        state,
        action.id,
        action.key || action.id,
        action.props || {}
      );

    case types.REMOVE_OVERLAY:
      return utils.removeOverlay(state, { key: action.key, id: action.id });

    case types.SET_ITEM:
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      return state;
  }
};

export default reducer;
