import axios, { AxiosRequestConfig } from 'axios';

const DefaultHeaders: Record<string, string> = {
  'X-Encode-I18n': 'false',
  'x-api-version': '3.5.0',
  'x-sc-platform': 'web',
  'x-sc-client': 'patron/1.0.0',
};

export function createApiClient(options?: AxiosRequestConfig) {
  return axios.create({
    ...options,
    headers: {
      ...DefaultHeaders,
      ...options?.headers,
    },
  });
}
