import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useOutsideClick from '../../../common/use-outside-click';

import { Button, Spinner } from '../../../components/ui';
import ContentInfoLike from './content-info-like';

import { trackContentCardLikesSeeAll } from '../../../models/content/analytics';
import { useContentLikes } from '../../../common/use-content-likes';

const ContentDetailLikes = ({ contentId }) => {
  const SHOW_LIMIT = 10;

  const { likes, isLoading } = useContentLikes(contentId, true); // when cleaning up this file, make sure to remove the second argument from useContentLikes

  const [showFullList, setShowFullList] = useState(false);

  const { t } = useTranslation();

  const handleShowAllToggle = () => {
    setShowFullList(!showFullList);

    if (!showFullList) trackContentCardLikesSeeAll(contentId);
  };

  const ref = useOutsideClick({
    disabled: !showFullList,
    onClick: () => {
      setShowFullList(false);
    },
  });

  const likesLimitButtonText = showFullList
    ? t('common.close')
    : t('common.expand');

  if (!likes.length) return null;

  return !isLoading ? (
    <div className="content-info__likes" ref={ref}>
      <div className="section-title section-title--small">
        {t('content.like_list')}
      </div>
      <ul
        className="content-info__likes-list"
        aria-label={t('content.like_list')}
      >
        {likes.slice(0, SHOW_LIMIT).map((like) => (
          <ContentInfoLike like={like} key={like.id} isInList={true} />
        ))}
      </ul>

      {showFullList ? (
        <ul className="content-info__likes-list content-info__likes-list--full">
          {likes.map((like, idx) => (
            <ContentInfoLike like={like} key={like.id} isInList={true} />
          ))}
        </ul>
      ) : null}

      {likes.length > SHOW_LIMIT ? (
        <Button
          icon={showFullList ? 'close' : 'expand_more'}
          className="content-info__likes-toggle"
          onClick={handleShowAllToggle}
          aria-label={likesLimitButtonText}
        />
      ) : null}
    </div>
  ) : (
    <Spinner />
  );
};

export default ContentDetailLikes;
