import cx from 'classnames';

import { LaptopRobot } from '../../components/ui';
import { useTranslation } from 'react-i18next';
import { ErrorBoundaryState } from '../../components/error-boundary';
import { useEffect, useRef } from 'react';

type AssistantErrorProps = ErrorBoundaryState & {
  inline?: boolean;
};

export function AssistantError({ error, inline = false }: AssistantErrorProps) {
  const ref = useRef<HTMLParagraphElement>(null);

  const { t } = useTranslation();
  const className = cx('assistant__error', {
    assistant__container: !inline,
  });

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className={className}>
      <div className="assistant__error-image">
        <LaptopRobot role="presentation" />
      </div>
      <p ref={ref} className="error-text" tabIndex={-1}>
        {error || t('errors.unknown')}
      </p>
    </div>
  );
}

export default AssistantError;
