import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { uiOperations } from '../../models/ui';

import { Dialog } from '../ui';

const CommentDeleteConfirmDialog = ({ onDeleteConfirm, removeOverlay }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('content.comment', { count: 2 })}
      accept={{
        text: t('common.confirm'),
        theme: 'danger',
        icon: 'delete',
        onClick: onDeleteConfirm,
      }}
      cancel={{
        text: t('common.cancel'),
        onClick: () => {
          removeOverlay({ id: ID });
        },
      }}
      close={() => {
        removeOverlay({ id: ID });
      }}
    >
      {t('comments.actions.confirm.delete')}
    </Dialog>
  );
};
export const ID = 'Overlays.COMMENT_DELETE_CONFIRM';

export default connect(null, {
  removeOverlay: uiOperations.removeOverlay,
})(CommentDeleteConfirmDialog);
