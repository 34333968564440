import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { paths as pathsState } from '../models/path-history/recoil-state';

//this is a standalone component instead of a helper method or hook just becasue I dont want to refactor patron-routes into a functional component.
/**
 * @deprecated This component is preserved ONLY to track paths used by `AssistantButton.canAutoOpenAssistant` function. It should no
 * longer be relied on to be used with the History API.
 */
const RouterHistoryTracker = () => {
  const [, setPaths] = useRecoilState(pathsState);
  const { pathname } = useLocation();

  useEffect(() => {
    setPaths((p) => [pathname].concat(p)); //intended to contain every path visited, even if the back button is used.
  }, [pathname, setPaths]);

  return <></>;
};

export default RouterHistoryTracker;
