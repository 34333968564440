import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { submissionImageUrls } from '../../models/content-submission/atoms';

import { Button, Icon } from '../../components/ui';

type ImagePreviewProps = {
  onReset?: () => void;
};

const ImagePreview = ({ onReset }: ImagePreviewProps) => {
  const [imageUrl] = useRecoilValue(submissionImageUrls);
  const { t } = useTranslation();

  return (
    <div
      role="img"
      aria-label={t('common.preview')}
      className="content-submission-image-preview"
      style={{ backgroundImage: `url('${imageUrl}')` }}
    >
      {!imageUrl && <Icon type="panorama" />}

      {onReset && (
        <Button onClick={onReset} aria-label={t('common.delete')}>
          <Icon type="delete">delete</Icon>
        </Button>
      )}
    </div>
  );
};

export default ImagePreview;
