import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import useChannelDetails from '../../../../../common/channel/use-channel-details';
import Spinner from '../../../../ui/spinner';
import { localizeDate, timeAgoOrDate } from '../../../../../lib/date-formatter';
import { usePatronSelector } from '../../../../../common/use-patron-selector';
import { getChannelById } from '../../../../../models/channels/selectors';

interface ITopicPageStats {
  channelId: string;
  createdAt: string;
}

const TopicStat = ({
  label,
  children,
}: PropsWithChildren<{
  label: string;
}>) =>
  children ? (
    <div className="channel-details-v2__stat">
      <dt className="channel-details-v2__stat-title">{label}</dt>
      <dd className="channel-details-v2__stat-body">{children}</dd>
    </div>
  ) : null;

const TopicPageStats = ({ channelId, createdAt }: ITopicPageStats) => {
  const { t } = useTranslation();
  const { fetching, channelStats } = useChannelDetails(channelId);
  const topic = usePatronSelector((state) => getChannelById(state, channelId));

  return fetching || !topic ? (
    <Spinner />
  ) : (
    <div className="channel-details-v2__stats">
      <dl className="channel-details-v2__stats-list">
        {createdAt && (
          <TopicStat label={t('channel_details.created')}>
            <time dateTime={createdAt}>{localizeDate(createdAt)}</time>
          </TopicStat>
        )}
        {channelStats?.lastUpdated && (
          <TopicStat label={t('channel_details.last_updated')}>
            <time dateTime={channelStats?.lastUpdated}>
              {timeAgoOrDate(channelStats.lastUpdated)}
            </time>
          </TopicStat>
        )}
        <TopicStat label={t('channel_details.avg_posts_per_week')}>
          {t('channel_details.number_post', {
            count: channelStats?.postsPerWeek,
          })}
        </TopicStat>
        <TopicStat label={t('channel_details.members')}>
          {t('channel_details.number_joined', {
            count: channelStats?.followerCount,
          })}
        </TopicStat>
        <TopicStat label={t('channel_details.contributors')}>
          {t('channel_details.number_contributor', {
            count: channelStats?.contributorCount,
          })}
        </TopicStat>
      </dl>
    </div>
  );
};

export default TopicPageStats;
