import { useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { contentSelectors } from '../../models/content/index.js';

import { Icon } from '../ui';
import ContentLink from './content-link';

const ContentFeatureImage = ({
  contentId,
  imageUrl,
  imageUrls,
  title,
  isMultiImage,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [isPortrait, setIsPortrait] = useState();

  const className = cx('content__feature-image', {
    'content__feature-image--loading': isFetching,
    'content__feature-image--portrait': isPortrait,
  });

  const handleImageLoad = (e) => {
    if (e.target.naturalHeight > e.target.naturalWidth) setIsPortrait(true);

    setIsFetching(false);
  };

  return (
    <div className={className}>
      <svg
        height="570"
        width="570"
        viewBox="0 0 570 570"
        preserveAspectRatio="xMidYMid slice"
        className="background"
      >
        <image
          xlinkHref={imageUrl}
          width="100%"
          height="100%"
          filter="url(#blur)"
        />

        <filter id="blur">
          <feGaussianBlur stdDeviation="25" />
        </filter>
      </svg>

      <ContentLink contentId={contentId} ignoreLink={!isMultiImage}>
        <img
          src={imageUrl}
          alt={title}
          className="image"
          onLoad={handleImageLoad}
        />
      </ContentLink>

      {isMultiImage ? (
        <div className="image-count">
          <Icon className="multi-image" type="image" />
          {imageUrls.length}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  imageUrl: contentSelectors.getContentImageUrl(state, ownProps),
  imageUrls: contentSelectors.getContentImageUrls(state, ownProps),
  title: contentSelectors.getContentTitleOrSummary(state, ownProps),
  borderColor: contentSelectors.getContentBorderColor(state, ownProps),
  isMultiImage: contentSelectors.getContentIsMultiImage(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentFeatureImage);
