import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Avatar } from '../../components/ui';
import { AssistantBackBar } from '../../components/assistant';
import useAssistantSearch from '../../common/use-assistant-search';
import { isSubjectCarouselType } from '../../models/assistant/interfaces/AssistantResponse';
import { AssistantLink } from '../../components/assistant/assistant-link';

function AssistantProfiles({ location }: RouteComponentProps) {
  const params = queryString.parse(location.search);

  const { searchResults } = useAssistantSearch(
    'people',
    params.ids as string[]
  );

  const profiles =
    searchResults?.data?.length && isSubjectCarouselType(searchResults.data[0])
      ? searchResults.data[0].subject.items
      : undefined;

  return (
    <div className="assistant__filtered-list">
      <AssistantBackBar />

      <div className="assistant__panel">
        {profiles &&
          profiles.length &&
          profiles.map((profile) => (
            <div
              className="assistant__history-item-profile assistant__history-item-tile"
              key={profile.id}
            >
              <AssistantLink to={`/profiles/${profile.id}`}>
                <Avatar
                  src={profile.image_url}
                  bgColor={profile.background_color}
                />
                <div className="profile-text">
                  <p className="profile-title">{profile.title}</p>
                  <p className="profile-description">{profile.description}</p>
                </div>
              </AssistantLink>
            </div>
          ))}
      </div>
    </div>
  );
}

export default AssistantProfiles;
