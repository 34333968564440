import { useState, useEffect } from 'react';
import cx from 'classnames';
import { Key } from 'ts-keycode-enum';
import { trackContentCardMultiImageClick } from '../../models/content/analytics';

const Thumbnail = ({
  imageUrl,
  imageAlt,
  onClick,
  active,
}: {
  imageUrl: string;
  imageAlt: string;
  onClick: () => void;
  active: boolean;
}) => {
  const className = cx('gallery-thumbnails__thumb', {
    'gallery-thumbnails__thumb--active': active,
  });

  return (
    <button
      className={'gallery-thumbnails__wrapper'}
      aria-pressed={active}
      onClick={onClick}
      aria-label={imageAlt || 'No Description'}
    >
      <img className={className} src={imageUrl} alt={imageAlt} />
      {active && <span className={'gallery-thumbnails--active'} />}
    </button>
  );
};

const GalleryThumbnails = ({
  images,
  onSelect,
  className: propClass,
  selected = 0,
  disableKeys,
}: {
  images: Array<Record<string, string>>;
  onSelect: (index: number) => void;
  className?: string;
  selected?: number;
  disableKeys?: boolean;
}) => {
  const [activeIndex, setActiveIndex] = useState(selected);
  const className = cx('gallery-thumbnails', propClass);

  const selectIndex = (index: number) => {
    setActiveIndex(index);
    onSelect(index);
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === Key.RightArrow)
        if (activeIndex + 1 <= images.length - 1) selectIndex(activeIndex + 1);

      if (e.keyCode === Key.LeftArrow)
        if (activeIndex - 1 >= 0) selectIndex(activeIndex - 1);
    };

    !disableKeys
      ? document.addEventListener('keydown', handleKeyPress)
      : document.removeEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, images, disableKeys]);

  return (
    <div className={className}>
      {images.map((image, index) => (
        <Thumbnail
          imageUrl={image.url}
          imageAlt={image.alt}
          key={image.url}
          onClick={() => {
            selectIndex(index);
          }}
          active={index === activeIndex}
        />
      ))}
    </div>
  );
};

export default GalleryThumbnails;
