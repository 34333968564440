import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import ChannelService from '../../services/channel';
import { timeAgoOrDate, localizeDate } from '../../lib/date-formatter';
import { Link, Spinner } from '../ui';
import ChannelImage from '../channel/channel-image';
import ChannelTitle from '../channel/channel-title';
import ChannelFollowButton from '../channel/channel-follow-button';
import ChannelDescription from '../channel/channel-description';
import { Avatar, HoverableFollowerAvatar } from '../ui';
import './channel-details.scss';
import { getChannelById } from '../../models/channels/selectors';
import { ChannelDetailsLinks } from './channel-details-links';
import { getFeatureFlag, Feature } from '../../models/features/features';
import {
  ChannelContributor,
  ChannelDetailsProps,
  ChannelStats,
} from '../../models/assistant/interfaces/Channel';
import { ChannelFollowersResponse } from '../../models/channels/types';
import { Resolved } from '../../lib/utility-types';
import { RootPatronState } from '../../common/use-patron-selector';

export const ChannelDetails = ({
  channelId,
  analyticsData,
}: ChannelDetailsProps) => {
  const [fetching, setFetching] = useState(true);

  const [channelStats, setChannelStats] = useState<ChannelStats>();
  const [channelContributors, setChannelContributors] = useState<
    ChannelContributor[]
  >([]);
  const [channelFollowers, setChannelFollowers] = useState<
    ChannelFollowersResponse['followers']['data']
  >([]);

  const channel = useSelector((state: RootPatronState) =>
    getChannelById(state, channelId)
  );
  const channelLinksEnabled = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.EXPERIMENTAL_TARGETED_CHANNEL_LINKS)
  );

  const { t } = useTranslation();

  useEffect(() => {
    const channelService = new ChannelService(channelId);

    const fetchChannelStats = () => {
      setFetching(true);

      Promise.all([
        channelService.fetchStats(),
        channelService.fetchContributors(),
        channelService.fetchFollowers(),
      ])
        .then(fetchChannelDetailsSuccess)
        .catch(fetchChannelDetailsFailure);
    };

    const fetchChannelDetailsSuccess = ([stats, contributors, followers]: [
      Resolved<typeof channelService.fetchStats>,
      Resolved<typeof channelService.fetchContributors>,
      AxiosResponse<ChannelFollowersResponse>
    ]) => {
      const {
        last_updated: lastUpdated,
        posts_count: postsCount,
        posts_per_week: postsPerWeek,
      } = stats.data.stats;

      setChannelStats({
        postsCount,
        postsPerWeek,
        lastUpdated: lastUpdated ? timeAgoOrDate(lastUpdated) : '-',
      });

      setChannelContributors(
        contributors.data.top_contributors.filter((c) => c.display_name)
      );
      setChannelFollowers(followers.data.followers.data);
      setFetching(false);
    };

    const fetchChannelDetailsFailure = () => {
      setFetching(false);
    };

    fetchChannelStats();
  }, [channelId]);

  return (
    <div className="channel-details">
      <header className="channel-details__header">
        <ChannelImage channel={channel} analyticsData={analyticsData} />

        <ChannelTitle
          channel={channel}
          analyticsData={analyticsData}
          titleTag="h1"
        />

        <ChannelFollowButton
          channelId={channelId}
          analyticsData={analyticsData}
        />
      </header>

      <div className="channel-details__about">
        <ChannelDescription channel={channel} />

        <div className="channel-details__created-at">
          {t('common.published')} {localizeDate(channel?.created_at)}
        </div>
      </div>

      {channelLinksEnabled ? (
        <ChannelDetailsLinks channelId={channelId} />
      ) : null}

      <Spinner loading={fetching}>
        <div className="channel-details__stats">
          <dl className="channel-details__stats-list">
            <div className="channel-details__stat">
              <dt className="channel-details__stat-title">
                {t('channel_details.all_posts')}
              </dt>
              <dd className="channel-details__stat-body">
                {channelStats?.postsCount}
              </dd>
            </div>
            <div className="channel-details__stat">
              <dt className="channel-details__stat-title">
                {t('channel_details.posts_per_week')}
              </dt>
              <dd className="channel-details__stat-body">
                {channelStats?.postsPerWeek}
              </dd>
            </div>
            <div className="channel-details__stat">
              <dt className="channel-details__stat-title">
                {t('channel_details.last_updated')}
              </dt>
              <dd className="channel-details__stat-body">
                {channelStats?.lastUpdated}
              </dd>
            </div>
          </dl>
        </div>

        {channelContributors.length ? (
          <div className="channel-details__contributors">
            <h2 className="channel-details__subtitle">
              {t('channel_details.contributors')}
            </h2>

            <div className="channel-details__contributors-list">
              {channelContributors.map((contributor) => (
                <div
                  className="channel-details__contributor"
                  key={contributor.id}
                >
                  <Link href={`/profiles/${contributor.id}`} action="push">
                    <div className="channel-details__contributor-avatar">
                      <Avatar
                        src={contributor.avatar_url || undefined}
                        bgColor={contributor.avatar_color}
                        alt={contributor.display_name || undefined}
                      />
                    </div>
                    <div className="channel-details__contributor-details">
                      <div className="channel-details__contributor-name">
                        {contributor.display_name}
                      </div>
                      <div className="channel-details__contributor-post_count">
                        {t('channel_details.number_post', {
                          count: contributor.post_count,
                        })}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {channelFollowers.length ? (
          <div className="channel-details__followers">
            <h2 className="channel-details__subtitle">
              {t('channel_details.number_joined', {
                count: channel?.follower_count,
              })}
            </h2>

            <div className="channel-details__followers-list">
              {channelFollowers.map((follower) => (
                <div className="channel-details__follower" key={follower.id}>
                  <Link href={`/profiles/${follower.id}`} action="push">
                    <HoverableFollowerAvatar
                      follower={{ id: follower.id, ...follower.attributes }}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </Spinner>
    </div>
  );
};

export default ChannelDetails;
