import { useState, useEffect } from 'react';
import AdvocateService from '../services/advocate';

const useProfileFetcherEdit = ({
  profileId,
  syncAdvocate: preWrappedSyncAdvocate,
}) => {
  const [profileGroups, setGroups] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isNotFound, setIsNotFound] = useState();
  const [isError, setIsError] = useState();

  const advocateService = new AdvocateService(profileId);

  const fetchProfile = async () => {
    setIsFetching(true);

    try {
      const res = await advocateService.fetchProfileEditData();
      handleFetchSuccess(res);
    } catch (err) {
      if (err.response) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res) => {
    setGroups(res.data.data.groups);
    setIsFetching(false);
  };

  const handleFetchFailure = (err) => {
    if (err.response.status === 403 || err.response.status === 404) {
      setIsNotFound(true);
    } else {
      setIsError(true);
    }

    setIsFetching(false);
  };

  const syncAdvocate = () => {
    preWrappedSyncAdvocate();

    advocateService.fetchProfileEditData().then(handleFetchSuccess);
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return {
    profileGroups,
    isFetching,
    isNotFound,
    isError,
    syncAdvocate,
  };
};

export default useProfileFetcherEdit;
