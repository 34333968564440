import { useState, useEffect } from 'react';
import ChannelService from '../services/channel';

const useChannelFeed = ({ search }) => {
  const [channels, setChannels] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState();
  const [page, setPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState();

  const resetState = () => {
    setChannels([]);
    setIsFetching(true);
    setIsError();
    setPage(0);
    setCanLoadMore();
  };

  const channelService = new ChannelService();

  const fetchChannels = async () => {
    setIsFetching(true);

    try {
      const res = await channelService.search(search, page + 1);
      handleFetchSuccess(res);
    } catch (err) {
      if (err.response) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res) => {
    const items = res.data.content_channels;
    const [curPage] =
      res.data.meta && res.data.meta.page
        ? [
            res.data.meta.page.page || res.data.meta.page.number,
            res.data.meta.page.size,
          ]
        : [1, 0];

    setChannels([...channels, ...items]);

    // the endpoint returns all results, regardless of page size
    setCanLoadMore(false);
    setPage(curPage);
    setNumPages(res.data.meta.page.total);
    setPageSize(res.data.meta.page.size);
    setIsFetching(false);
  };

  const handleFetchFailure = () => {
    setIsError(true);
    setIsFetching(false);
  };

  useEffect(() => {
    resetState();

    fetchChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    channels,
    page,
    numPages,
    pageSize,
    isFetching,
    isError,
    canLoadMore,
    onLoadMore: fetchChannels,
  };
};

export default useChannelFeed;
