// This library is taken from draft-js-export-html and modified slightly.
//   First used flow-remove-types to get rid of the flow-annotations.
//   see here for source https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-export-html

// Lifted from: https://github.com/facebook/react/blob/master/src/renderers/dom/shared/HTMLDOMPropertyConfig.js
const ATTR_NAME_MAP = {
  acceptCharset: 'accept-charset',
  className: 'class',
  htmlFor: 'for',
  httpEquiv: 'http-equiv',
};

function normalizeAttributes(attributes) {
  if (attributes == null) {
    return attributes;
  }
  let normalized = {};
  let didNormalize = false;
  for (let name of Object.keys(attributes)) {
    let newName = name;
    // TODO: refactor for eslint
    // eslint-disable-next-line no-prototype-builtins
    if (ATTR_NAME_MAP.hasOwnProperty(name)) {
      newName = ATTR_NAME_MAP[name];
      didNormalize = true;
    }
    normalized[newName] = attributes[name];
  }
  return didNormalize ? normalized : attributes;
}

export default normalizeAttributes;
