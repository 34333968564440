import { AssistantResponse } from '../../models/assistant/interfaces/AssistantResponse';
import { Card } from '../../models/assistant/interfaces/subject/Summary';
import AssistantAction from './assistant-action';
import './assistant.scss';

interface AssistantNotificationSubjectProps {
  onClick: () => void;
  data: AssistantResponse;
}

function AssistantNotificationSubject(
  props: AssistantNotificationSubjectProps
) {
  const subject = props.data.subject as Card;

  return (
    <AssistantAction
      onClick={props.onClick}
      className="assistant__notification-subject"
      action={subject.action!}
    >
      <div className="flex-wrapper">
        <div
          className="assistant__notification-subject-image"
          style={{
            backgroundColor: subject.image_background_color,
            backgroundImage: `url(${subject.image_url})`,
          }}
        ></div>

        <div className="flex-fill">
          {subject.title ? <p>{subject.title}</p> : null}

          {!subject.title && subject.description ? (
            <p>{subject.description}</p>
          ) : null}
        </div>
      </div>
    </AssistantAction>
  );
}

export default AssistantNotificationSubject;
