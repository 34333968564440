import {
  BigIconButton,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../../../models/ui';
import {
  ShortcutModalProps,
  ID as ShortcutsModalId,
} from './modal/shortcut-modal';

export const DEFAULT_SHORTCUT_ICON =
  'https://lib.socialchorus.com/assistant/public/services/icons/QuickLink.png';

export type ShortcutProp = {
  description?: string;
  links: { url: string; label: string }[];
  label: string;
  imgSrc?: string;
};

export function Shortcut({ label, description, imgSrc, links }: ShortcutProp) {
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    dispatch(
      uiOperations.displayOverlay(ShortcutsModalId, {
        title: label,
        description,
        items: links,
      } as ShortcutModalProps)
    );
  }, [dispatch, label, description, links]);

  const buttonProps =
    links.length === 1
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
          label,
          imgSrc: imgSrc || DEFAULT_SHORTCUT_ICON,
          href: links[0].url,
        }
      : {
          label,
          imgSrc: imgSrc || DEFAULT_SHORTCUT_ICON,
          onClick: openModal,
        };

  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <BigIconButton {...buttonProps} />
      </TooltipTrigger>
      <TooltipContent description={description} sideOffset={5} />
    </TooltipRoot>
  );
}
