import types, { BoxIntegrationReducer } from './types';
import { getTokenDataKey } from './utils';

const reducer: BoxIntegrationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TOKEN_DATA:
      return {
        ...state,
        tokenData: {
          ...state.tokenData,
          [getTokenDataKey(action.resourceType, action.resourceId)]:
            action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
