import api from '../config/api';
import apiFactory from '../config/api-factory';
import {
  ChannelFetchAllResponse,
  ChannelFollowersResponse,
  ChannelFollowResponse,
  ChannelMembersResponse,
  ChannelSearchResponse,
  ChannelStatsResponse,
  ChannelTopContributorsResponse,
  TopicShortcutsResponse,
} from '../models/channels/types';

class ChannelService {
  id: string;
  constructor(id: string) {
    this.id = id;
  }

  fetchAll = () => {
    return api.get<ChannelFetchAllResponse>(`/content_channels`);
  };

  fetchMostContributed = (limit?: number) => {
    return api.get<ChannelFetchAllResponse>(
      `/content_channels/most_contributed`,
      {
        params: {
          page_size: limit,
        },
      }
    );
  };

  search = (term: string, page: number) => {
    return api.get<ChannelSearchResponse>(`/content_channels/search`, {
      params: {
        q: term,
        page,
      },
    });
  };

  fetch = () => {
    return api.get(`/content_channels/${this.id}`);
  };

  fetchAboutPage = () => {
    return api.get(`/content_channels/${this.id}/about_page`);
  };

  fetchStats = () => {
    return api.get<ChannelStatsResponse>(`/content_channels/${this.id}/stats`);
  };

  fetchContributors = () => {
    return api.get<ChannelTopContributorsResponse>(
      `/content_channels/${this.id}/top_contributors`
    );
  };

  fetchFollowers = (pageSize?: number, page?: number) => {
    return api.get<ChannelFollowersResponse>(
      `/content_channels/${this.id}/followers`,
      {
        params: {
          page_size: pageSize,
          page,
        },
      }
    );
  };

  fetchMembers = (pageSize?: number, page?: number) => {
    return apiFactory.v2.get<ChannelMembersResponse>(
      `/content_channels/${this.id}/members`,
      {
        params: {
          page_size: pageSize,
          page,
        },
      }
    );
  };

  follow = () => {
    return api.put<ChannelFollowResponse>(
      `/content_channels/${this.id}/follow`
    );
  };

  unfollow = () => {
    return api.put<ChannelFollowResponse>(
      `/content_channels/${this.id}/unfollow`
    );
  };

  fetchShortcuts = () => {
    return apiFactory.v3.get<TopicShortcutsResponse>(
      `/channels/${this.id}/shortcuts`
    );
  };
}

export default ChannelService;
