import useAssistantFetcher, {
  AssistantDataType,
} from '../../../../common/use-assistant-fetcher';

//Responsible for initializing the integration modal by calling the assistant fetcher.
//This cannot be put in patron-data, as the fetcher is a hook that is tightly coupled with the global state already being pre-populated. (program id, ect)
//This used to be in the home page, but now that shortcuts are in search results, the home page might not be navigated to before the search results are shown (direct navigation to search results)
export const IntegrationModalInit = () => {
  //fetches all integrations
  useAssistantFetcher({
    itemTypes: [AssistantDataType.INTEGRATIONS],
  });

  return null;
};
