import React from 'react';
import cx from 'classnames';
import { findDOMNode } from 'react-dom';

import { Icon } from '../../components/ui';

import './flash-message.scss';

class FlashMessage extends React.Component {
  componentDidMount() {
    setTimeout(this.animateIn, 50);
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  handleKeyPress(ev) {
    if (ev.key === 'Space') {
      this.animateOut();
    }
  }

  render() {
    const { text, success, notice, warning, error } = this.props;

    const className = cx('flash-message', {
      'is-success': success,
      'is-warning': warning,
      'is-notice': notice,
      'is-error': error,
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div
        className={className}
        onClick={this.animateOut}
        onKeyDown={this.handleKeyPress}
        role="alert"
      >
        <div className="container">
          <div className="flash-message__body">
            {success ? <Icon type="check_circle" /> : null}
            {warning ? <Icon type="info" /> : null}
            {notice ? <Icon type="info" /> : null}
            {error ? <Icon type="cancel" /> : null}

            {text}
          </div>
        </div>
      </div>
    );
  }

  animateIn = () => {
    // eslint-disable-next-line react/no-find-dom-node
    findDOMNode(this).classList.add('is-active');
    this.timeout = setTimeout(this.animateOut, this.props.delay || 5000);
  };

  animateOut = () => {
    // eslint-disable-next-line react/no-find-dom-node
    findDOMNode(this).classList.remove('is-active');
    setTimeout(this.close, 500);
  };

  close = () => {
    this.props.close && this.props.close();
  };
}

export default FlashMessage;
