import cx from 'classnames';

type CurtainProps = {
  onClick: () => void;
  className?: string;
  enabled?: boolean;
};

const Curtain: React.FC<CurtainProps> = ({
  onClick,
  className,
  enabled = true,
}) => {
  const handleKeyPress = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Space') {
      onClick();
    }
  };

  if (!enabled) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx('curtain', className)}
      onClick={onClick}
      onKeyDown={handleKeyPress}
    />
  );
};

export default Curtain;
