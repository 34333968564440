import { Icon } from '.';

export const Globe = (props) => <Icon type="language" {...props} />;

export const Inbox = (props) => <Icon type="inbox" {...props} />;

export const Chat = (props) => <Icon type="chat_bubble" {...props} />;

export const Notifications = (props) => (
  <Icon type="notifications" {...props} />
);

export const Compass = (props) => <Icon type="explore" {...props} />;

export const LightBulb = (props) => <Icon type="tips_and_updates" {...props} />;

export const Shield = (props) => <Icon type="privacy_tip" {...props} />;

export const Profile = (props) => <Icon type="account_circle" {...props} />;

export const SideBarProfileIcon = (props) => (
  <Icon type="account_circle" {...props} />
);

export const ChevronRight = (props) => <Icon type="chevron_right" {...props} />;

export const ChevronLeft = (props) => <Icon type="chevron_left" {...props} />;

export const VideoIcon = (props) => <Icon type="videocam" {...props} />;

export const VideoPostIcon = (props) => (
  <Icon
    type="videocam"
    style={{ marginRight: '15px', ...(props.style ?? {}) }}
    {...props}
  />
);

export const ImageIcon = (props) => (
  <Icon
    type="image"
    style={{ marginRight: '15px', ...(props.style ?? {}) }}
    {...props}
  />
);

export const NoteIcon = (props) => (
  <Icon
    type="notes"
    style={{ marginRight: '15px', ...(props.style ?? {}) }}
    {...props}
  />
);

export const ArticleIcon = (props) => (
  <Icon
    type="description"
    style={{ marginRight: '15px', ...(props.style ?? {}) }}
    {...props}
  />
);

export const LinkIcon = (props) => (
  <Icon
    type="link"
    style={{ marginRight: '15px', ...(props.style ?? {}) }}
    {...props}
  />
);

export const VideoPlayIcon = (props) => <Icon type="play_circle" {...props} />;

export const FeaturedIcon = (props) => <Icon type="outbound" {...props} />;

export const StarFeaturedIcon = (props) => <Icon type="outbound" {...props} />;

export const TrendingIcon = (props) => <Icon type="trending_up" {...props} />;

export const SwitchIcon = (props) => (
  <Icon aria-hidden="true" type="swap_horiz" {...props} />
);

export const BookmarkIcon = (props) => (
  <Icon aria-hidden="true" type="bookmark" {...props} />
);

export const BookmarkedIcon = (props) => (
  <Icon aria-hidden="true" className="filled" type="bookmark" {...props} />
);

export const PostIcon = (props) => (
  <Icon aria-hidden="true" type="article" {...props} />
);

export const ChannelsIcon = (props) => (
  <Icon aria-hidden="true" type="check_circle" {...props} />
);

export const EditProfileIcon = (props) => (
  <Icon aria-hidden="true" type="edit" {...props} />
);

export const ResetPasswordIcon = (props) => (
  <Icon aria-hidden="true" type="lock" {...props} />
);

export const ShareIcon = (props) => (
  <Icon aria-hidden="true" type="share" {...props} />
);

export const SubmitCommentIcon = (props) => (
  <Icon aria-hidden="true" type="send" {...props} />
);

export const PersonIcon = (props) => (
  <Icon aria-hidden="true" type="person" {...props} />
);

export const LatestIcon = (props) => (
  <Icon aria-hidden="true" type="new_releases" {...props} />
);

export const ResourcesIcon = (props) => (
  <Icon aria-hidden="true" type="menu_book" {...props} />
);

export const InviteIcon = (props) => (
  <Icon aria-hidden="true" type="person_add" {...props} />
);

export const HomeIcon = (props) => <Icon type="home" {...props} />;

export const JoinedChannelsIcon = (props) => (
  <Icon type="check_circle" {...props} />
);

export const JoinChannelsIcon = (props) => (
  <Icon type="add_circle_outline" {...props} />
);

export const SettingsIcon = (props) => <Icon type="settings" {...props} />;

export const DotsVerticalIcon = (props) => <Icon type="more_vert" {...props} />;

export const NewContentIcon = (props) => (
  <Icon type="create" aria-label="submit content" {...props} />
);

export const GroupIcon = (props) => (
  <Icon type="group" aria-label="event follow count" {...props} />
);
