import { Button } from './Button';
import { Avatar } from './Avatar';
import { Profile } from './Profile';
import { Card } from './subject/Summary';
import { Carousel } from './subject/Carousel';
import { Inbox } from './subject/Inbox';
import { Service } from './subject/Service';
import { ButtonList } from './subject/ButtonList';

type ResponseAuthor = 'bot' | 'user' | 'none' | 'service' | 'system';

export interface AssistantResponse<
  T extends Card | Carousel | Inbox | Service | ButtonList =
    | Card
    | Carousel
    | Inbox
    | Service
    | ButtonList
> {
  id: string;
  author_type: 'system';
  author: ResponseAuthor;
  name: string;
  avatar?: Avatar | null;
  profile?: Profile;
  created_at: string;
  updated_at: string;
  text: string;
  attribution_text?: string;
  subject: T;
  buttons?: Button[];
}

export function isSubjectCarouselType(
  response: AssistantResponse
): response is AssistantResponse & { subject: Carousel } {
  return 'items' in response.subject;
}
