import { useCallback, useContext, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  IconButton,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
  Icon,
} from '@socialchorus/shared-ui-components';
import styles from './notification-center.module.scss';
import { NotificationContext } from './context';

export enum FilterTags {
  ACTION_REQUIRED = 'action_required',
  IMPORTANT = 'important',
  READ = 'read',
  UNREAD = 'unread',
}

export const mapFilterTagsToVariant = (tag: FilterTags) => {
  switch (tag) {
    case FilterTags.ACTION_REQUIRED:
      return 'warning';
    case FilterTags.IMPORTANT:
      return 'negative';
    case FilterTags.READ:
      return 'primary';
    case FilterTags.UNREAD:
      return 'primary';
  }
};

type FilterTagToIconProps = {
  tag: FilterTags;
  colored?: boolean;
  compact?: boolean;
};
export const mapFilterTagToIcon = ({
  tag,
  colored = false,
  compact = false,
}: FilterTagToIconProps) => {
  switch (tag) {
    case FilterTags.ACTION_REQUIRED:
      return (
        <Icon
          size={compact ? 16 : 24}
          className={cx(colored && 'text-orange-full-value')}
        >
          ads_click
        </Icon>
      );
    case FilterTags.IMPORTANT:
      return (
        <Icon
          size={compact ? 16 : 24}
          className={cx(colored && 'text-red-full-value')}
        >
          error
        </Icon>
      );
    case FilterTags.READ:
      return <Icon size={compact ? 16 : 24}>drafts</Icon>;
    case FilterTags.UNREAD:
      return <Icon size={compact ? 16 : 24}>mark_email_unread</Icon>;
  }
};

type FilterDropdownProps = {
  container: HTMLElement | null | undefined;
  allowedOptions: FilterTags[];
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  container,
  allowedOptions,
}) => {
  const { t } = useTranslation();

  const {
    filterTags: [selectedOptions, setSelectedOptions],
  } = useContext(NotificationContext);

  const handleOptionClick = useCallback(
    (tag: FilterTags) => {
      if (selectedOptions.includes(tag)) {
        setSelectedOptions(selectedOptions.filter((t) => t !== tag));
      } else {
        setSelectedOptions([...selectedOptions, tag]);
      }
    },
    [selectedOptions, setSelectedOptions]
  );

  useEffect(() => {
    //if allowedOptions changes, remove any selectedOptions that are no longer allowed
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((tag) => allowedOptions.includes(tag))
    );
  }, [allowedOptions, setSelectedOptions]);

  return (
    <TooltipRoot>
      <TooltipContent
        description={t('notification_center.filter_by')}
        sideOffset={5}
        container={container}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <IconButton iconName="filter_list" />
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={styles.dropdownMenu}
          portalProps={{ container }}
        >
          <div
            className={cx(
              styles.menuTitle,
              'text-body-1 text-black-90 font-semibold'
            )}
          >
            {t('notification_center.filter_by')}
          </div>
          {allowedOptions.map((tag) => (
            <DropdownMenuCheckboxItem
              key={tag}
              checked={selectedOptions.includes(tag)}
              onCheckedChange={() => handleOptionClick(tag)}
            >
              <div className={styles.dropdownLabel}>
                {mapFilterTagToIcon({ tag, colored: true })}
                <span className="text-body-1 text-black-90">
                  {t(`notification_center.tags.${tag}`)}
                </span>
              </div>
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </TooltipRoot>
  );
};
