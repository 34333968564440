import i18n from '../config/i18n';

type TextValidatorChecks = {
  preventHtml?: boolean;
  preventWhiteSpaceOnly?: boolean;
  minLength?: number;
  maxLength?: number;
};

class TextValidator {
  text: string;
  checks: TextValidatorChecks;
  resourceType: string;
  resourceName: string;

  constructor(checks: TextValidatorChecks = {}, resourceType = 'Text') {
    this.text = '';
    this.checks = checks;
    this.resourceType = resourceType;
    this.resourceName = i18n.t(
      `text_validator.resources.${this.resourceType.toLowerCase()}`,
      {
        defaultValue: this.resourceType,
      }
    );
  }

  validate(text = '') {
    this.text = text;

    const errors: (string | undefined)[] = [];

    if (this.checks.preventHtml === true) {
      errors.push(this.preventHtml());
    }

    if (this.checks.preventWhiteSpaceOnly === true) {
      errors.push(this.preventWhiteSpaceOnly());
    }

    this.text = this.text.replace(/\s+/g, ' ').trim();

    if (this.checks.minLength !== undefined) {
      errors.push(this.minLength(this.checks.minLength));
    }

    if (this.checks.maxLength !== undefined) {
      errors.push(this.maxLength(this.checks.maxLength));
    }

    return errors.filter((e) => !!e) as string[];
  }

  preventHtml() {
    if (this.text.match(/(.*?)<.*?>(.*?)/))
      return i18n.t(`text_validator.html.${this.resourceType.toLowerCase()}`, {
        defaultValue: i18n.t('text_validator.html.default', {
          resource: this.resourceName,
        }),
      });
  }

  preventWhiteSpaceOnly() {
    if (this.text.match(/^\s+$/))
      return i18n.t(
        `text_validator.white_space.${this.resourceType.toLowerCase()}`,
        {
          defaultValue: i18n.t('text_validator.white_space.default', {
            resource: this.resourceName,
          }),
        }
      );
  }

  minLength(length: number) {
    if (Number.isInteger(length) && this.text.length < length)
      return i18n.t(
        `text_validator.min_length.${this.resourceType.toLowerCase()}`,
        {
          length,
          defaultValue: i18n.t('text_validator.min_length.default', {
            resource: this.resourceName,
            length,
          }),
        }
      );
  }

  maxLength(length: number) {
    if (Number.isInteger(length) && this.text.length > length)
      return i18n.t(
        `text_validator.max_length.${this.resourceType.toLowerCase()}`,
        {
          length,
          defaultValue: i18n.t('text_validator.max_length.default', {
            resource: this.resourceName,
            length,
          }),
        }
      );
  }
}

export default TextValidator;
