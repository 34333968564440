import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import useDocumentListeners from '../../common/use-document-listeners';
import { Button } from '../ui';
import FocusLock from 'react-focus-lock';
import './content-topics.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import useProgramTheme from '../../common/use-program-theme';
import {
  trackContentCardMoreTopicsClick,
  trackContentCardTopicPillClick,
} from '../../models/analytics';
import { contentTraits } from '../../models/content/analytics';
import useRandomId from '../../common/use-random-id';

const TopicButton = ({
  id,
  name,
  tabIndex,
  onClick,
  analyticsCallback,
  className,
}) => {
  const handleClick = (e) => {
    analyticsCallback && analyticsCallback();
    onClick && onClick(e);
  };

  return (
    <Button
      shape="pill"
      theme="tag"
      href={onClick ? undefined : `/channels/${id}`}
      action={onClick ? undefined : 'push'}
      tabIndex={tabIndex}
      onClick={(e) => handleClick(e)}
      className={className}
    >
      {name}
    </Button>
  );
};

/**
 * @param contentId
 * @param topics
 * @param analyticsData
 * @param wideMode - True: Display up to 3 pills, allow "+X topics" to be clickable.
 *                   False: Display 1 pill, clickable if multiple topics exist
 * @returns {JSX.Element|null}
 */
const ContentTopics = ({
  contentId,
  topics,
  analyticsData,
  wideMode = false,
}) => {
  const { t } = useTranslation();
  const { id: popoverId } = useRandomId();

  const [isOpen, setIsOpen] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(false);

  //refs stored in state as per 'usePopper' docs
  const [referenceElement, setReferenceElement] = useState();
  const [popperRef, setPopperRef] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperRef, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          enabled: false,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const topicsEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );

  const { accentColor } = useProgramTheme();

  const togglePopover = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      trackContentCardMoreTopicsClick(contentTraits(contentId, analyticsData));
    }

    setIsOpen((open) => !open);
  };

  const enterKeyTogglePopover = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsOpen((open) => !open);
    }
  };

  const escapeKeyClosesPopover = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setIsOpen(false);
      setShouldFocus(true);
    }
  };

  //waiting to focus after render completes due to FocusLock
  useEffect(() => {
    if (shouldFocus) {
      referenceElement.focus();
      setShouldFocus(false);
    }
  }, [referenceElement, shouldFocus]);

  const handleDocClick = (e) => {
    const tarInShare = popperRef && popperRef.contains(e.target);
    const tarIsButton = referenceElement && referenceElement === e.target;

    if (!tarInShare || tarIsButton) {
      setIsOpen(false);
    }
  };
  useDocumentListeners('click, wheel', isOpen, handleDocClick);

  if (topics.length < 1) {
    return null;
  }

  const topicsToDisplay = wideMode ? Math.min(topics.length, 3) : 1;
  const remainingTopicsCount = topics.length - topicsToDisplay;

  // If only one topic or wideMode, act as link
  const actAsLink = topics.length <= 1 || wideMode;

  return (
    <>
      <div
        ref={setReferenceElement}
        className="content-topics"
        onClick={actAsLink ? undefined : togglePopover}
        onKeyDown={actAsLink ? undefined : enterKeyTogglePopover}
        tabIndex={actAsLink ? undefined : 0}
        role={actAsLink ? undefined : 'button'}
        aria-controls={actAsLink ? undefined : popoverId}
        aria-expanded={actAsLink ? undefined : isOpen}
      >
        {topics.slice(0, topicsToDisplay).map((topic, index) => (
          <TopicButton
            key={topic.id}
            {...topic}
            tabIndex={-1}
            onClick={actAsLink ? undefined : togglePopover}
            analyticsCallback={() =>
              trackContentCardTopicPillClick(
                contentTraits(contentId, {
                  position: index,
                  ...analyticsData,
                })
              )
            }
          />
        ))}

        {remainingTopicsCount > 0 && (
          <div
            className="content-topics__more-items"
            style={{ color: accentColor }}
            onClick={wideMode ? togglePopover : undefined}
            onKeyDown={wideMode ? enterKeyTogglePopover : undefined}
            tabIndex={wideMode ? 0 : undefined}
            role={wideMode ? 'button' : undefined}
            aria-controls={wideMode ? popoverId : undefined}
            aria-expanded={wideMode ? isOpen : undefined}
          >
            {`+${remainingTopicsCount} ${t('common.more')}`}
          </div>
        )}
      </div>

      {isOpen && (
        <div
          ref={setPopperRef}
          className="content-topics-all__popper"
          style={styles.popper}
          {...attributes.popper}
        >
          {/* autoFocus here is a prop of the component and not the standard one. linter thinks its the standard one. */}
          {/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */}
          {/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */}
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <FocusLock autoFocus={false}>
            <div
              id={popoverId}
              className="content-topics-all__container"
              tabIndex="0"
              role="dialog"
              aria-labelledby={`${popoverId}--title`}
              onKeyDown={escapeKeyClosesPopover}
            >
              <h4 id={`${popoverId}--title`} className="title">
                {topicsEnabled
                  ? t('content.posted_in_topics')
                  : t('content.posted_in_channels')}
              </h4>
              {topics.map((topic, i) => (
                <div key={topic.id}>
                  <TopicButton
                    {...topic}
                    analyticsCallback={() =>
                      trackContentCardTopicPillClick(
                        contentTraits(contentId, {
                          position: i || -1,
                          ...analyticsData,
                        })
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </FocusLock>
        </div>
      )}
    </>
  );
};

export default ContentTopics;
