import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProgram } from '../../common/use-program';
import {
  EXIT_DEEPLINK,
  getDeepLinkRedirect,
} from '../../lib/mobile-deeplinks/es';
import { NativePlatform, Program } from '../../models/program/types';

const IOS_DEEPLINK_VER = '4.0.2';

type NativeRedirectFunction = (program: Program, path: string) => void;

// To properly support the ios redirect, we first close the current webview
// Then in a timeout, we open the webview to the proper deeplink.
// This serves to alter the history so that hitting "back" from the content
// deeplink returns the user to the proper place
const redirectIos: NativeRedirectFunction = (program, path) => {
  const match = window.navigator.userAgent.match(
    /(advomobo|advodroid)\/(\d.+)\s\(/
  );
  const version = (match && match[1]) || '1.0.0';

  // Only close the webview using the sc:// link if the app version supports that
  if (version >= IOS_DEEPLINK_VER) {
    (window as Window).location = EXIT_DEEPLINK;
  }

  setTimeout(() => {
    // Redirect to a universal link
    (
      window as Window
    ).location = `${program.root_url}/sc4?program=${program.id}#${path}`;
  }, 100);
};

// When an android webview detects the location change, it instead
// opens a new screen to the proper place within the app
// This timeout continues in the background and brings the webview back one item in the history
// This serves to alter the history so that hitting "back" from the content
// deeplink returns the user to the proper place
const redirectAndroid: NativeRedirectFunction = (program, path) => {
  // Redirect to an sc:// deeplink
  (window as Window).location = `sc://${path}?program=${program.id}`;

  setTimeout(() => {
    window.history.back();
  }, 500);
};

function useRedirectToNative(platform: NativePlatform) {
  const loc = useLocation();
  const path = getDeepLinkRedirect(loc.pathname) || loc.pathname;
  const program = useProgram();

  useEffect(() => {
    switch (platform) {
      case 'ios':
        redirectIos(program, path);
        break;
      case 'android':
        redirectAndroid(program, path);
        break;
    }
  }, [path, platform, program]);
}

type NativeWebviewRedirectProps = {
  platform: NativePlatform;
};

const NativeWebviewRedirect = ({ platform }: NativeWebviewRedirectProps) => {
  const { t } = useTranslation();
  useRedirectToNative(platform);

  return (
    <p style={{ textAlign: 'center', marginTop: '200px' }}>
      {t('messages.webview_redirect')}
    </p>
  );
};

export default NativeWebviewRedirect;
