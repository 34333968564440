import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { appConfigSelectors } from '../../models/app-config';
import { programSelectors } from '../../models/program';
import {
  contentSelectors,
  contentOperations,
} from '../../models/content/index.js';
import { trackContentVideoPlay } from '../../models/content/analytics';

import contentCompletion from './content-completion';
import {
  RootPatronState,
  usePatronSelector,
} from '../../common/use-patron-selector';
import { ValueType } from '../../lib/utility-types';
import { Dispatch } from 'redux';
import useIframeResize from '../../common/useIframeResize';
import { uiOperations } from '../../models/ui';
import { ID as ExternalLinkModalId } from '../external-link-modal/external-link-modal';
import { shouldShowExternalLinkWarning } from '../../lib/teams-helper';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { Monitor } from './content-completion/types';
import cx from 'classnames';

const isLegacyLink = (url: URL) => {
  const path = url.pathname.split('/');
  return path.pop() === 'sc4' && url.hash.length > 0; // e.g. /sc4?query=2#contents/123
};

const isInternalLink = (url: URL, rootPath: string) => {
  return (
    (url.hostname === window.location.hostname &&
      url.pathname.startsWith(rootPath)) ||
    isLegacyLink(url)
  );
};

const getElementTagName = (obj: unknown) => {
  if (typeof obj !== 'object' || obj === null || !('tagName' in obj)) {
    return undefined;
  }

  const tagName = (obj as { tagName: unknown }).tagName;

  if (typeof tagName === 'string') return tagName;
  return undefined;
};

const isAnchorElement = (obj: unknown): obj is HTMLAnchorElement => {
  const tagName = getElementTagName(obj);

  if (!tagName) return false;
  return tagName.toUpperCase() === 'A';
};

const getParentElement = (obj: EventTarget | null) => {
  if (obj === null || !('parentElement' in obj)) return undefined;

  return (obj as { parentElement: unknown }).parentElement;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  contentId: number;
  analyticsData: unknown;
  autoPlayVideo?: boolean;
  onResize?: () => void;
  locale?: string;
};

type ContentFrameProps = StateProps & DispatchProps & OwnProps;

type VideoMessageEvent = MessageEvent<{
  event: 'start_play' | 'timeupdate' | 'paused' | 'ended';
}>;

const ContentFrame = ({
  contentId,
  programId,
  contentType,
  programPath,
  isVideo,
  videoEmbed,
  videoUuid,
  isContentCompleted,
  setIsContentCompleted,
  openExternalLinkModal,
  analyticsData,
  autoPlayVideo,
  onResize,
  locale,
}: ContentFrameProps) => {
  const iframeContentSrc = useRef<string>();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const history = useHistory();

  const newContentPage = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const handleIframeRefChange = (node: HTMLIFrameElement | null) => {
    iframeRef.current = node;
  };

  const program = usePatronSelector((state) => state.program);

  const completionMonitor = useMemo(() => {
    if (isContentCompleted) return undefined;

    const handleContentCompleted = (monitor: Monitor) => {
      if (iframeRef.current) {
        monitor.detach();
      }
      setIsContentCompleted(contentId);
    };

    const monitor = contentCompletion[contentType];
    return monitor ? new monitor(handleContentCompleted) : undefined;
  }, [contentId, contentType, isContentCompleted, setIsContentCompleted]);

  const handleInternalLinkClick = useCallback(
    (e: MouseEvent, url: URL) => {
      if (e.defaultPrevented) return;
      e.preventDefault();

      const isLegacy = isLegacyLink(url);

      const path = isLegacy
        ? `/sc4${url.hash}${url.search}` // appends provided search (query) parameter to the new path
        : url.href.split(
            [window.location.origin, programPath].filter((s) => s).join('')
          )[1];

      history.push(path);
    },
    [history, programPath]
  );

  const handleLinkClick = useCallback(
    (e: MouseEvent, target: HTMLAnchorElement) => {
      const url = new URL(target.href);
      const newTab = target.target === '_blank';
      const internalLink = isInternalLink(url, program.root_path);

      if (!internalLink || newTab) {
        if (shouldShowExternalLinkWarning()) {
          openExternalLinkModal(target.href);
          e.preventDefault();
        }

        // We don't want to capture newTab links
        return;
      }

      handleInternalLinkClick(e, url);
    },
    [handleInternalLinkClick, openExternalLinkModal]
  );

  const handleIframeClick = useCallback(
    (e: MouseEvent) => {
      // for attachments, the <a> tag contains span and other elements which may be the target
      let target: HTMLAnchorElement | undefined = undefined;

      // `instanceof` doesn't work with event targets coming from the iframe for some reason
      if (isAnchorElement(e.target)) {
        target = e.target;
      } else {
        const parentElement = getParentElement(e.target);

        if (isAnchorElement(parentElement)) {
          target = parentElement;
        }
      }

      if (!target) return;

      // only handle non-attachment clicks
      if (
        !(
          target.hasAttribute('data-filetype') ||
          (target.hasAttribute('rel') && target.rel === 'attachment')
        )
      ) {
        handleLinkClick(e, target);
      }
    },
    [handleLinkClick]
  );

  useEffect(() => {
    if (!isIframeLoaded) return;

    const events: Record<string, VideoMessageEvent['data'][]> = {};

    const persistEvent = (event: VideoMessageEvent) => {
      const key = eventKey(event);
      Object.prototype.hasOwnProperty.call(events, key)
        ? events[key].push(event.data)
        : (events[key] = [event.data]);
    };

    const lastEventTriggeredSend = (eventList: ValueType<typeof events>) => {
      const lastEventType = eventList[eventList.length - 1].event;
      return ['start_play', 'ended', 'paused'].includes(lastEventType);
    };

    const eventKey = (event: MessageEvent) => {
      return `${event.data.videoId} - ${event.data.instanceId}`;
    };

    const selfOrigin = `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`;
    const handleMessage = (e: MessageEvent) => {
      const iframe = iframeRef.current;
      if (e.origin !== selfOrigin) return;
      if (e.data.source !== 'videojs') return;
      //There could be multiple player instances, only react to message from descendant
      if (!iframe || iframe.contentWindow !== e.source) return;

      switch (e.data.event) {
        case 'start_play':
          persistEvent(e);
          trackContentVideoPlay(
            contentId,
            contentType,
            events[eventKey(e)],
            analyticsData
          );
          break;
        case 'timeupdate':
          persistEvent(e);
          break;
        case 'paused':
        case 'ended': {
          const send = !lastEventTriggeredSend(events[eventKey(e)]); // prevent double-submit from end and pause
          persistEvent(e);
          if (send)
            trackContentVideoPlay(
              contentId,
              contentType,
              events[eventKey(e)],
              analyticsData
            );
          break;
        }
      }
    };

    const iframe = iframeRef.current;
    // attach event listeners to any nested iframes (e.x. attachments)
    const allNestedFrames = Array.from(
      iframe?.contentDocument?.getElementsByTagName('iframe') ?? []
    );
    // check to ensure we can read the contentDocument
    const nestedFrames = allNestedFrames.filter((frame) => {
      try {
        return frame.contentDocument != null;
      } catch (err) {
        return false;
      }
    });

    const handleIframeLoad = () => {
      iframe?.contentDocument?.body.addEventListener(
        'click',
        handleIframeClick
      );
      nestedFrames.forEach((frame) => {
        if (frame.contentDocument?.body) {
          frame.contentDocument.body.addEventListener(
            'click',
            handleIframeClick
          );
        } else {
          frame.contentDocument?.addEventListener('load', () => {
            frame.contentDocument?.body?.addEventListener(
              'click',
              handleIframeClick
            );
          });
        }
      });
    };

    // Reattaches the event listeners within the content when the content changes
    iframe?.addEventListener('load', handleIframeLoad);
    handleIframeLoad();

    if (completionMonitor && iframe) {
      completionMonitor.attach(iframe);
    }

    if (isVideo) {
      window.addEventListener('message', handleMessage, false);
    }

    return () => {
      // Verify presence of iframe document, as internal navigation revokes access
      if (iframe?.contentDocument && iframe.contentDocument.body) {
        iframe.contentDocument.body.removeEventListener(
          'click',
          handleIframeClick
        );
        nestedFrames.forEach((frame) =>
          frame.contentDocument?.body?.removeEventListener(
            'click',
            handleIframeClick
          )
        );
      }

      iframe?.removeEventListener('load', handleIframeLoad);

      if (completionMonitor) {
        completionMonitor.detach();
      }

      if (isVideo) {
        window.removeEventListener('message', handleMessage, false);
        Object.values(events).forEach((event_list) => {
          // if the last event is 'ended' or 'paused', we already sent this analytics payload
          if (!lastEventTriggeredSend(event_list)) {
            trackContentVideoPlay(
              contentId,
              contentType,
              event_list,
              analyticsData
            );
          }
        });
      }
    };
  }, [
    analyticsData,
    completionMonitor,
    contentId,
    contentType,
    handleIframeClick,
    isIframeLoaded,
    isVideo,
  ]);

  const contentUrl = useMemo(() => {
    if (isVideo) {
      return `/embed/video/${videoUuid}?autoplay=${!!autoPlayVideo}`;
    }

    let articleUrl = `/embed/article/${contentId}?program=${programId}`;
    if (locale) {
      articleUrl += `&locale=${locale}`;
    }

    return articleUrl;
  }, [autoPlayVideo, contentId, isVideo, locale, programId, videoUuid]);

  const getVideoEmbedHtml = () => {
    const div = document.createElement('div');
    div.innerHTML = videoEmbed ?? '';

    const el = div.firstChild;

    if (!el || !(el instanceof HTMLElement)) return '';

    const src = el.getAttribute('src')?.replace('http://', 'https://');
    el.setAttribute('src', src ?? '');

    return el.outerHTML;
  };

  const handleFrameLoad = () => {
    setIsIframeLoaded(true);
  };

  //disabled on old content page to insulate against regressions.
  //do not resize for videos, css forces aspect ratio and handles precise sizing
  const disableIframeResize = isVideo || !newContentPage;

  const {
    height,
    ref: resizedIframeRef,
    hasResized,
  } = useIframeResize({
    onRefChange: handleIframeRefChange,
    disabled: disableIframeResize,
  });

  const onResizeRef = useRef(onResize);

  useEffect(() => {
    if (hasResized || disableIframeResize) {
      onResizeRef.current?.();
    }
  }, [hasResized, disableIframeResize]);

  // The contentUrl will primarily (though perhaps not exclusively) change
  // if the "locale" should change via the "Translate" button. In such a case,
  // we want to reload the iframe to ensure the content is updated.
  useEffect(() => {
    iframeRef.current?.contentWindow?.location?.replace(contentUrl);
  }, [contentUrl]);

  useEffect(() => {
    if (!isIframeLoaded) {
      iframeContentSrc.current = contentUrl;
    }
  }, [contentUrl, isIframeLoaded]);

  return videoEmbed ? (
    <div
      className="content-frame"
      dangerouslySetInnerHTML={{ __html: getVideoEmbedHtml() }}
    />
  ) : (
    <div
      className={cx('content-frame', {
        loading: !isIframeLoaded,
      })}
    >
      {/**
       * Using `scrolling="no"` to disable scrollbar on the iframe - ignore the deprecation warning since CSS
       * doesn't work unless `overflow:hidden` is added to the content body tag
       * @see https://support.moonpoint.com/network/web/html/css/iframe-obsolete-elements.php
       */}
      <iframe
        allowFullScreen
        src={iframeContentSrc.current}
        onLoad={handleFrameLoad}
        ref={resizedIframeRef}
        title={String(contentId)}
        style={
          !disableIframeResize && height !== undefined
            ? { height: `${height}px` }
            : undefined
        }
        height={disableIframeResize ? undefined : height}
        scrolling={newContentPage ? 'no' : undefined}
      />
    </div>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  programPath: appConfigSelectors.getProgramPath(state),
  programId: programSelectors.getProgramId(state),
  contentType: contentSelectors.getContentType(state, ownProps),
  isVideo: contentSelectors.getContentIsVideo(state, ownProps),
  videoEmbed: contentSelectors.getContentVideoEmbed(state, ownProps),
  videoUuid: contentSelectors.getContentVideoUuid(state, ownProps),
  isContentCompleted: contentSelectors.getContentIsCompleted(state, ownProps),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsContentCompleted: (id: number) =>
    contentOperations.completeContent(id)(dispatch),
  openExternalLinkModal: (linkUrl: string) =>
    dispatch(uiOperations.displayOverlay(ExternalLinkModalId, { linkUrl })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentFrame);
