import React, { useState, useEffect } from 'react';

import ContentService from '../../../services/content';

import { Spinner } from '../../ui';

import './content-stats.scss';

const ContentInfoStats = ({ contentId }) => {
  const [stats, setStats] = useState([]);
  const [error, setError] = useState();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const contentService = new ContentService(contentId);

    const fetchStats = () => {
      contentService
        .fetchStats()
        .then(handleFetchSuccess)
        .catch(handleFetchFailure);
    };

    const handleFetchSuccess = (res) => {
      const { stats } = res.data;

      setStats(stats);
      setIsFetching(false);
    };

    const handleFetchFailure = (err) => {
      console.error(err);

      setError(err);
      setIsFetching(false);
    };

    fetchStats();
  }, [contentId]);

  return (
    <div className="content-stats">
      {!isFetching ? (
        !error ? (
          stats.length ? (
            <div className="content-stats__table">
              {stats.map((stat) => (
                <div className="content-stats__stat" key={stat.label}>
                  <span className="content-stats__label">{stat.label}</span>
                  <span className="content-stats__value">
                    {stat.value || 0}
                  </span>
                </div>
              ))}
            </div>
          ) : null
        ) : (
          <div className="error">There was an error loading stats</div>
        )
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ContentInfoStats;
