import {
  Button,
  Icon,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSWRConfig } from 'swr';
import { usePatronSelector } from '../../../../../../common/use-patron-selector';
import {
  contentOperations,
  contentSelectors,
} from '../../../../../../models/content';
import {
  trackContentCardLike,
  trackContentCardUnlike,
} from '../../../../../../models/content/analytics';
import { getContentLikesDataKey } from '../../../../../../common/use-content-likes';
import { FeedCardActionsProps } from './type';

export function FeedCardActionLike({
  content,
  analyticsData,
}: FeedCardActionsProps) {
  const isLiked = usePatronSelector((s) =>
    contentSelectors.getContentLiked(s, { contentId: content.id })
  );
  const likeCount = usePatronSelector((s) =>
    contentSelectors.getContentLikeCount(s, { contentId: content.id })
  );
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const { t } = useTranslation();

  const likeContent = (id: number) =>
    dispatch(contentOperations.likeContent(id));
  const unlikeContent = (id: number) =>
    dispatch(contentOperations.unlikeContent(id));

  const handleClick = () => {
    if (isLiked) {
      trackContentCardUnlike(content.id, analyticsData);
      unlikeContent(content.id);
    } else {
      trackContentCardLike(content.id, analyticsData);
      likeContent(content.id);
    }
    // Trigger revalidation on the content likes list
    void mutate(getContentLikesDataKey(content.id));
  };

  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, { contentId: content.id })
  );

  const likeActionKey = isLiked
    ? 'content.like_remove_action'
    : 'content.like_action';
  const actionLabel = t(likeActionKey, { title: contentTitle });

  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <Button
          aria-label={actionLabel}
          label={String(likeCount)}
          leftIcon={<Icon size={20}>thumb_up</Icon>}
          size="compact"
          variant="text"
          selected={isLiked}
          onClick={handleClick}
        />
      </TooltipTrigger>
      <TooltipContent
        description={
          isLiked ? t('content.like_remove') : t('content.like', { count: 1 })
        }
      />
    </TooltipRoot>
  );
}
