import { debounce } from 'lodash';
import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Comment, CommentWithReplies } from '../../../../models/comments/types';

type CommentTranslationContext = {
  translateAllLoading: boolean;
  translateAllActive: boolean;
  toggleTranslateAll: () => void;
  batchToTranslateAllComments: (
    comment: Comment,
    onError?: ErrorCallback
  ) => void;
  translateComment: (
    comment: Comment,
    translate: boolean,
    onError?: ErrorCallback
  ) => void;
};

export const CommentTranslationContext =
  createContext<CommentTranslationContext>({} as CommentTranslationContext);

type ProviderComponentProps = {
  comments: (Comment | CommentWithReplies)[];
  batchTranslateComments: (
    comments: Comment[],
    translate: boolean
  ) => Promise<void>;
  translateAllActive: boolean;
  setTranslateAllActive: (value: SetStateAction<boolean>) => void;
  updateCommentData: (comment: Comment, data: Partial<Comment>) => void;
  children: ReactNode;
};

const MAX_BATCH_SIZE = 20;

type ErrorCallback = () => void;

export function CommentTranslationContextProvider({
  comments,
  batchTranslateComments,
  translateAllActive,
  setTranslateAllActive,
  updateCommentData,
  children,
}: ProviderComponentProps) {
  const [translateAllRequested, setTranslateAllRequested] = useState(false);
  const [, setRequestTranslationComments] = useState<Comment[]>([]);
  const translateErrorCallbacks = useRef<ErrorCallback[]>([]);

  const debouncedBatchTranslateComments = useMemo(
    () =>
      debounce(async (comments: Comment[], translate: boolean) => {
        setRequestTranslationComments([]);

        try {
          await batchTranslateComments(comments, translate);
        } catch (error) {
          translateErrorCallbacks.current.forEach((callback) => callback());
        } finally {
          translateErrorCallbacks.current = [];
        }
      }, 500),
    [batchTranslateComments]
  );

  const toggleTranslateAll = () => {
    let translateAllActive = false;

    setTranslateAllActive((active) => {
      translateAllActive = !active;
      return translateAllActive;
    });
    setRequestTranslationComments([]);

    translateErrorCallbacks.current = [
      // revert the state if the batch request fails
      () => setTranslateAllActive((active) => !active),
    ];

    debouncedBatchTranslateComments([], translateAllActive);
  };

  const batchToTranslateAllComments = useCallback(
    async (comment: Comment, onError?: ErrorCallback) => {
      updateCommentData(comment, {
        translationInProgress: true,
      });

      translateErrorCallbacks.current = translateErrorCallbacks.current.concat(
        onError || []
      );

      setRequestTranslationComments((prevComments) => {
        // immediately execute the batch request if the batch size is reached
        if (prevComments.length >= MAX_BATCH_SIZE) {
          debouncedBatchTranslateComments.flush();
          prevComments = [];
        }
        const newComments = prevComments.concat(comment);
        debouncedBatchTranslateComments(newComments, translateAllActive);
        return newComments;
      });
    },
    [debouncedBatchTranslateComments, translateAllActive, updateCommentData]
  );

  const translateComment = useCallback(
    async (comment: Comment, translate: boolean, onError?: ErrorCallback) => {
      updateCommentData(comment, {
        translationInProgress: true,
      });

      try {
        await batchTranslateComments([comment], translate);
      } catch {
        onError?.();
      } finally {
        updateCommentData(comment, {
          translationInProgress: false,
        });
      }
    },
    [batchTranslateComments, updateCommentData]
  );

  const hasCommentTranslationInProgress = comments.some(
    (c) =>
      c.translationInProgress ||
      ('replies' in c && c.replies?.some((r) => r.translationInProgress))
  );
  const translateAllLoading =
    translateAllRequested && hasCommentTranslationInProgress;

  useEffect(() => {
    // this sets translateAllRequested to false as soon as the first batch of translation requests are completed
    // and will prevent further loading states on the "translate all" button until the user requests it again
    setTranslateAllRequested((alreadyRequested) => {
      if (!alreadyRequested) {
        return false;
      }
      return hasCommentTranslationInProgress;
    });
  }, [hasCommentTranslationInProgress]);

  useEffect(() => {
    return () => {
      debouncedBatchTranslateComments.cancel();
    };
  }, [debouncedBatchTranslateComments]);

  return (
    <CommentTranslationContext.Provider
      value={{
        translateAllLoading,
        translateAllActive,
        toggleTranslateAll,
        batchToTranslateAllComments,
        translateComment,
      }}
    >
      {children}
    </CommentTranslationContext.Provider>
  );
}
