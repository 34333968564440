import api from '../config/api';
import store from '../config/store';
import { redirectToLogin, redirectToSSOLogout } from '../lib/auth';
import { programSelectors } from '../models/program';

class AuthService {
  destroySession = ({ ignoreSSOLogout = false } = {}) => {
    console.log('Cleaning up session');
    localStorage.removeItem('lastLocation');

    //There are cases where we dont want to log out from the SSO provider when logging out of the program.
    //at time of writing, this is only for the case where you are switching communities.
    //ignoreSSOLogout is a flag that forces the logout process to just use the mojo session destroy endpoint.
    //it will also skip redirecting to the login page, which is important just for the community switch case. refactor later if needed.
    if (ignoreSSOLogout) {
      console.log('log out initiated');
      return api.post(`/sessions/logout`);
    } else {
      const isSLOEnabled = programSelectors.getProgramSLOEnabled(
        store.getState()
      );
      const isSSO = programSelectors.getProgramSessionIsSSO(store.getState());

      //feature flag enabled, and user sesssion is sso
      if (isSLOEnabled && isSSO) {
        return new Promise(() => redirectToSSOLogout());
      } else {
        return api.post(`/sessions/logout`).then(() => redirectToLogin(true));
      }
    }
  };

  fetchEntitlements = (email) => {
    const {
      appConfig: { api_url: apiUrl },
    } = store.getState();
    const encodedEmail = encodeURIComponent(email);

    return api.get(`/authentications/entitlements?email=${encodedEmail}`, {
      baseURL: `${apiUrl}/v2`,
    });
  };
}

export default AuthService;
