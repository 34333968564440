import { Avatar, FeedPreviewCard } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { useLinkNavigation } from '../../../../../common/use-link-navigation';
import { timeAgoOrDate } from '../../../../../lib/date-formatter';
import { Content } from '../../../../../models/content/types';
import {
  getContentAuthorAvatarSrc,
  getContentAuthorProfilePath,
  getContentDetailPath,
} from '../../../../../models/content/helpers';
import styles from './styles.module.css';

type PostPreviewProps = {
  content: Content;
};

export function PostPreview({ content }: PostPreviewProps) {
  const getLinkNavigation = useLinkNavigation();
  const { t } = useTranslation();

  const authorName = content.source_name;
  const avatarSrc = getContentAuthorAvatarSrc(content);

  const publishedAt = content.published_at;

  return (
    <FeedPreviewCard
      className={styles.PostPreview}
      authorAvatarComponent={
        <Avatar size="small" shape="circle" imgSrc={avatarSrc || undefined} />
      }
      authorName={authorName}
      authorHref={getLinkNavigation(
        getContentAuthorProfilePath(content.author_id)
      )}
      datetime={timeAgoOrDate(publishedAt)}
      pinned={content.pinned}
      href={getLinkNavigation(getContentDetailPath(content.id))}
      content={
        content.title || content.description || t('content.no_description')
      }
    />
  );
}
