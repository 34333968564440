import TextInput from '../../../components/ui/text-input';
import React from 'react';

const ProfileTextInput = ({
  name,
  value = '',
  placeholder,
  onChange,
  onBlur,
  disabled,
}) => (
  <TextInput
    name={name}
    value={value || ''}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    isDisabled={disabled}
  />
);

export default ProfileTextInput;
