import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { channelOperations } from '../models/channels';
import { getChannelById } from '../models/channels/selectors';
import ChannelService from '../services/channel';
import { usePushToastOrFlashMessage } from '../components/v2/toaster/deprecation-helper';

//Normally the list of all channels are always loaded into state before any component that uses them is rendered.
//This hook is to make sure that any edge cases are caught. Where before we would throw an error for a missing channel id, now we just load it on-demand.
//(example: assistant response shows newest channels, and a new channel was created in the time since the list of channels was loaded client-side,
//or before the cache for all channels was expired server-side)
const useChannel = ({ channelId }) => {
  const channel = useSelector((state) => getChannelById(state, channelId));
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const channelService = new ChannelService(channelId);

  const fetchChannel = async () => {
    setIsFetching(true);
    try {
      const res = await channelService.fetch();
      handleFetchSuccess(res);
    } catch (err) {
      handleFetchFailure(err);
    }
  };

  const handleFetchSuccess = (res) => {
    dispatch(channelOperations.setChannels([res.data.content_channel]));
    setIsFetching(false);
  };

  const handleFetchFailure = (err) => {
    console.error(`channel fetching error: ${err}`);
    setIsError(true);
    pushMessage({
      text: t('errors.default'),
      type: 'error',
    });
    setIsFetching(false);
  };

  useEffect(() => {
    if (!channel && channelId) {
      fetchChannel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, channelId]);

  return {
    channel,
    isFetching,
    isError,
  };
};

export default useChannel;
