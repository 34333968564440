import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../ui';

import { trackContentCardEdit } from '../../models/content/analytics';
import { trackEditClick } from '../../models/content-submission/analytics';

import './content.scss';

const ContentActionEdit = ({ contentId, noText, analyticsData }) => {
  const history = useHistory();

  const handleClick = () => {
    const editRoute = `/contents/${contentId}/edit`;
    history.push(editRoute);

    trackContentCardEdit(contentId, analyticsData);
    trackEditClick({ content_id: contentId });
  };

  const { t } = useTranslation();

  return (
    <div className="content__action content__action--edit">
      <Button
        icon="edit"
        onClick={handleClick}
        aria-label={t('common.edit')}
        title={noText ? t('common.edit') : null}
      >
        {noText ? null : <span className="text">{t('common.edit')}</span>}
      </Button>
    </div>
  );
};

export default ContentActionEdit;
