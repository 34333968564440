import cx from 'classnames';

import ContentItem from '../content-item';

import './content-list.scss';

const ContentList = ({
  contents,
  contentIds,
  limit,
  orientation,
  analyticsData,
}) => {
  const className = cx('content-list', `content-list--${orientation}`);

  contentIds = contentIds || contents.map((c) => c.id);
  contentIds = limit ? contentIds.slice(0, limit) : contentIds;

  return (
    <div className={className}>
      {contentIds.map((contentId) => (
        <ContentItem
          contentId={contentId}
          orientation={orientation}
          size="small"
          shouldTrackView={true}
          analyticsData={analyticsData}
          key={contentId}
        />
      ))}
    </div>
  );
};

ContentList.defaultProps = {
  orientation: 'landscape',
};

export default ContentList;
