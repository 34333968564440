import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button, Icon } from '../../ui';

import {
  trackContentCardBookmark,
  trackContentCardUnbookmark,
} from '../../../models/content/analytics';
import ContentService from '../../../services/content';
import { contentOperations, contentSelectors } from '../../../models/content';
import Tooltip from '../../tooltip/tooltip';
import { useDispatch } from 'react-redux';
import { usePatronSelector } from '../../../common/use-patron-selector';

interface IContentActionBookmark {
  isBookmarked: boolean;
  onClick: MouseEventHandler | undefined;
  contentTitle: string;
  showText?: boolean;
  wideMode?: boolean;
}

const ContentActionBookmark: React.FC<IContentActionBookmark> = ({
  isBookmarked,
  onClick,
  contentTitle,
  showText = false,
  wideMode = false,
}) => {
  const className = cx('content__action', 'content__action--bookmark', {
    'content__action--bookmarked': isBookmarked,
    'content__action--active': isBookmarked,
  });

  const { t } = useTranslation(undefined, { useSuspense: false });
  const bookmarkKey = isBookmarked
    ? 'content.bookmark_remove'
    : 'content.bookmark';
  const bookmarkActionKey = isBookmarked
    ? 'content.bookmark_remove_action'
    : 'content.bookmark_action';

  const displayText = showText || wideMode;

  const actionLabel = t(bookmarkActionKey, { title: contentTitle });

  return (
    <div className={className}>
      {displayText ? (
        <Button
          onClick={onClick}
          aria-label={actionLabel}
          theme={displayText ? 'text' : 'icon-only'}
          size={displayText ? 'compact' : '32'}
        >
          <div className="icon-animation-container">
            <Icon type="bookmark" />
            <Icon type="bookmark" className="bookmark filled" />
          </div>
          <span>{t(bookmarkKey)}</span>
        </Button>
      ) : (
        <Tooltip aria-hidden text={t(bookmarkKey)}>
          <Button
            onClick={onClick}
            aria-label={actionLabel}
            theme={displayText ? 'text' : 'icon-only'}
            size={displayText ? 'compact' : '32'}
          >
            <div className="icon-animation-container">
              <Icon type="bookmark" />
              <Icon type="bookmark" className="bookmark filled" />
            </div>
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const ContentActionBookmarkWrapper = ({
  contentId,
  showText,
  analyticsData,
  wideMode,
}: {
  showText?: boolean;
  wideMode?: boolean;
  contentId: number | string;
  analyticsData: IAnalyticsData;
}) => {
  const isBookmarked = usePatronSelector((s) =>
    contentSelectors.getContentBookmarked(s, { contentId: Number(contentId) })
  );
  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, {
      contentId: Number(contentId),
    })
  );
  const dispatch = useDispatch();
  const bookmarkContent = (id: string | number) =>
    dispatch(contentOperations.bookmarkContent(id));
  const unbookmarkContent = (id: string | number) =>
    dispatch(contentOperations.unbookmarkContent(id));

  const handleClick = () => {
    if (isBookmarked) {
      trackContentCardUnbookmark(contentId, analyticsData);
      new ContentService(contentId).unbookmark().then(() => {
        unbookmarkContent(contentId);
      });
    } else {
      trackContentCardBookmark(contentId, analyticsData);
      new ContentService(contentId).bookmark().then(() => {
        bookmarkContent(contentId);
      });
    }
  };

  return (
    <ContentActionBookmark
      isBookmarked={isBookmarked}
      showText={showText}
      onClick={handleClick}
      contentTitle={contentTitle}
      wideMode={wideMode}
    />
  );
};

export { ContentActionBookmark };

export default ContentActionBookmarkWrapper;
