import { Button } from '../ui';
import './icon-button.scss';
import cx from 'classnames';
import type { Dispatch, KeyboardEventHandler, SetStateAction } from 'react';
import useRandomId from '../../common/use-random-id';
import { ForwardActions } from '../../config/history';

//this is the "big icon button" component in our design system.
//not to be confused with a normal button that has just an icon and no text.

export interface IconButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  iconImg?: string;
  iconAlt: string;
  iconLabel: string;
  href?: string;
  onClick?: () => void;
  action?: ForwardActions;
  target?: string;
  tooltipRef?: Dispatch<SetStateAction<HTMLElement | null>>;
}

export default function IconButton({
  onClick,
  iconImg,
  iconAlt,
  iconLabel,
  className,
  href,
  action,
  target,
  tooltipRef,
  ...args
}: IconButtonProps) {
  const { id } = useRandomId();
  const keyPressHandler: KeyboardEventHandler = (event) => {
    if (['Enter', 'Space'].indexOf(event.key) !== -1) {
      onClick?.();
    }
  };
  const classes = cx('icon-button', className);
  return (
    <Button
      className={classes}
      onClick={onClick}
      onKeyPress={keyPressHandler}
      href={href}
      action={action}
      target={target}
      ref={tooltipRef}
      aria-labelledby={id}
      {...args}
    >
      <img src={iconImg} alt={iconAlt} />
      <span id={id} className="label">
        {iconLabel}
      </span>
    </Button>
  );
}
