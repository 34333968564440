import { useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { AssistantBackBar } from '../../components/assistant';
import { trackAssistantCommandDetailViewLoad } from '../../models/assistant/analytics';

function queryStringToObject(search: string): Record<string, string> {
  const params = new URLSearchParams(search);
  return Object.fromEntries(params.entries());
}

function AssistantSummaryDetail({ location }: RouteComponentProps) {
  const ref = useRef<HTMLIFrameElement>(null);
  const history = useHistory();
  const showBackButton = history.length > 1;

  useEffect(() => {
    const stateFromParams = queryStringToObject(location.search);
    const locationState = location.state ?? stateFromParams;
    const renderUrl = get(locationState, 'renderUrl');

    if (renderUrl) {
      ref.current!.src = renderUrl;

      trackAssistantCommandDetailViewLoad(get(locationState, 'analyticsData'));
    }
  }, [location]);

  return (
    <div className="assistant__summary-detail">
      {showBackButton && <AssistantBackBar />}
      <iframe title="assistant-summary-detail" ref={ref} />
    </div>
  );
}

export default AssistantSummaryDetail;
