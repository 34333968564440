import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FocusLock from 'react-focus-lock';

import { Button } from '../ui';
import ContentMore from './content-more';

import useOutsideClick from '../../common/use-outside-click';

import './content.scss';

const ContentActionMore = ({ contentId }) => {
  const [isActive, setIsActive] = useState();

  const { t } = useTranslation();

  const ref = useOutsideClick({
    disabled: !isActive,
    onClick: () => {
      setIsActive(false);
    },
  });

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const buttonLabel = isActive ? t('common.close') : t('content.more');

  return (
    <FocusLock disabled={!isActive}>
      <div className="content__action content__action--more" ref={ref}>
        <Button
          icon={isActive ? 'close' : 'more_vert'}
          aria-label={buttonLabel}
          onClick={handleClick}
        />

        {isActive ? <ContentMore contentId={contentId} /> : null}
      </div>
    </FocusLock>
  );
};

export default ContentActionMore;
