import { matchPath } from 'react-router-dom';

const legacyRedirectMap = {
  '/contents/:id/details': '/contents/:id',
  '/contents/:id/detail': '/contents/:id',
  '/feed/:id': '/feed/:id',
  '/feed/:id/**': '/feed/:id',
  '/feedfilter/:filtername': '/feed/:filtername',
  '/highlights': '/feed/featured',
  '/bookmarks': '/account/bookmarks',
  '/recent-activity': '/account/submitted',
  '/channel/:id': '/channels/:id',
  '/explore/:id': '/channels/:id',
  '/explore': '/discover',
  '/welcome': '/',
  '/feed': '/',
  '/profiles/:id/recent-activity': '/profiles/:id/submitted',
  '/profile/:id': '/profiles/:id',
  '/profile': '/account/submitted',
  '/search/:type\\::term': '/search/:type/:term',
  '/search/:term': '/search/contents/:term',
  '/search': 'discover',
  '/submit': '/submit/link',
  '/activities': '/account/submitted',

  '/resources': '/feed/resources',
  '/todos': '/assistant/todos',
  '/todos/:id': '/assistant/todos/:id',
  '/service/:id': '/assistant/service/:id',
  '/service/:id/command/:command': '/assistant/service/:id/command/:command',
  '/notifications': '/assistant/notifications',

  '/approval/:id': '/approvals/:id',
};

export const getLegacyRedirect = (hash) => {
  let route = hash.replace('#', '/');

  Object.keys(legacyRedirectMap).forEach((path) => {
    const { params } = matchPath(route, { path, exact: true }) || {};

    if (params) {
      route = legacyRedirectMap[path];

      Object.keys(params).forEach((param) => {
        route = route.replace(`:${param}`, params[param]);
      });
    }
  });

  //due to how advo owns login, and then forwards all "sc4" routes to patron with the route intact,
  //this introduces an edge case for the legacy '/sc4#login' route, as the '/login' route on patron does not exist.
  //(if the user isnt logged in for any route, it will redirect to the advo login uri)
  //So, to support '/sc4#login' on web, the login route should just be the home page, and leave the rest to the default behavior of patron.
  //example supported mobile deeplink that hits this: https://advocate.fup.dev/wayne/batmaninfo/sc4?programId=3#login
  //this specific redirect code here is only to handle the case where the user does not accept the app install and proceeds to the web
  //main use case for that is QR code invites.
  if (route === '/login') {
    route = '/';
  }

  return route;
};
