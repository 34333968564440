import { AxiosRequestConfig } from 'axios';
import api from '../config/api';
import {
  ContentSubmissionDTO,
  ContentSubmissionFetchLinkPreviewResponse,
  ContentSubmissionFetchResponse,
  ContentSubmissionsFetchPrivateMediaUploadUrlsResponse,
  ContentSubmissionUpdateResponse,
} from '../models/content-submission/types';

export const publicationStateMap = {
  draft: 'draft',
  archive: 'archived',
  publish: 'published',
} as const;

export type PublicationState =
  typeof publicationStateMap[keyof typeof publicationStateMap];

export type ContentSubmissionTypes = keyof typeof publicationStateMap;

export type SubmissionContentType =
  | 'link'
  | 'image'
  | 'video'
  | 'note'
  | 'article';

// ideally we would extract this from submissionNoteColors or vice versa but as of now its not TS
export type NoteColor =
  | '#ba5858'
  | '#c5be7b'
  | '#646692'
  | '#655163'
  | '#c29b72'
  | '#4a93c6'
  | '#494949';

type MentionFormat =
  | 'human_readable' // @John Doe
  | 'display_name' // @john.doe123
  | 'with_metadata' // @{ref:user::id:1234::name:John Doe}
  | 'raw'; // @{ref:user:1234}
export interface SubmissionParams {
  mention_format?: MentionFormat;
}

class ContentSubmissionService {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  fetch = (params: SubmissionParams = {}) => {
    return api.get<ContentSubmissionFetchResponse>(`/submissions/${this.id}`, {
      params,
    });
  };

  fetchLinkPreview = (url: string) => {
    return api.post<ContentSubmissionFetchLinkPreviewResponse>(
      '/link_previews',
      { url }
    );
  };

  // unused
  fetchMediaUploadUrl = (filename: string) => {
    return api.get(`/submissions/image_upload_url/${filename}`);
  };

  fetchPrivateMediaUploadUrls = (filename: string) => {
    return api.get<ContentSubmissionsFetchPrivateMediaUploadUrlsResponse>(
      `/submissions/file_upload_urls/${filename}`
    );
  };

  create = (data: ContentSubmissionDTO, canPublish: boolean) => {
    const path = canPublish ? '/contents' : '/submissions';

    return api.post<null>(path, data, {
      baseURL: canPublish ? this.v3baseUrl : api.defaults.baseURL,
    });
  };

  update = (data: ContentSubmissionDTO, canPublish: boolean) => {
    const path = canPublish
      ? `/contents/${this.id}`
      : `/submissions/${this.id}`;

    return api.put<ContentSubmissionUpdateResponse>(path, data, {
      baseURL: canPublish ? this.v3baseUrl : api.defaults.baseURL,
    });
  };

  updateState = (saveAs: ContentSubmissionTypes, canPublish: boolean) => {
    const path = canPublish
      ? `/contents/${this.id}/${saveAs}`
      : `/submissions/${this.id}/${saveAs}`;

    // Because submission routes don't support anything except archiving
    // and because the submissions and content APIs are an absolute trash fire,
    // we're just going to return a resolved Promise for submissions that
    // aren't archived.
    if (!canPublish && saveAs !== 'archive') return Promise.resolve();

    return api.post<null>(path, null, {
      baseURL: canPublish ? this.v3baseUrl : api.defaults.baseURL,
    });
  };

  createMediaAsset = (
    url: string,
    data: string,
    config: AxiosRequestConfig = {}
  ) => {
    config.headers = {
      'Content-Type': 'multipart/form-data',
      'x-amz-acl': ' bucket-owner-full-control',
    };
    config.transformRequest = [
      (data, headers) => delete headers.Authorization && data,
    ];

    return api.put<null>(url, data, config);
  };

  // unused
  persistMediaAsset = (url: string, config: AxiosRequestConfig = {}) => {
    config.headers = { 'x-amz-acl': ' bucket-owner-full-control' };
    return api.post(
      '/submissions/content_images/persist',
      { url: url },
      config
    );
  };

  get v3baseUrl() {
    return api?.defaults?.baseURL?.replace('v2', 'v3');
  }
}

export default ContentSubmissionService;
