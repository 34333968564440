import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { advocateSelectors, advocateOperations } from '../../models/advocate';
import AdvocateService from '../../services/advocate';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const AvatarFormContainer = (props) => {
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const advocateId = useSelector((state) =>
    advocateSelectors.getAdvocateId(state, props)
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  useEffect(() => {
    if (file) {
      return () => URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  const handleDropAccepted = (files) => {
    if (isSubmitting) return false;

    if (file) URL.revokeObjectURL(file.preview);

    const newFile = Object.assign(files[0], {
      preview: URL.createObjectURL(files[0]),
    });

    setFile(newFile);
    setIsRejected(false);
  };

  const handleDropRejected = () => setIsRejected(true);

  const handleDelete = () => {
    setIsSubmitting(true);
    setError(false);

    new AdvocateService(advocateId)
      .deleteAvatar()
      .then(handleSubmitSuccess)
      .catch(handleSubmitFailure);
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append('avatar', file);

    setIsSubmitting(true);
    setError(false);

    new AdvocateService(advocateId)
      .updateAvatar(data)
      .then(handleSubmitSuccess)
      .catch(handleSubmitFailure);
  };

  const handleSubmitSuccess = () => {
    setIsSubmitting(false);

    dispatch(advocateOperations.syncAdvocate());

    pushMessage({
      text: t('screens.profile.edit_profile.avatar_update_success'),
      type: 'success',
    });

    props.close();
  };

  const handleSubmitFailure = (err) => {
    const error =
      err.response &&
      err.response.data &&
      err.response.data.errors &&
      err.response.data.errors[0] &&
      err.response.data.errors[0].details;

    setIsSubmitting(false);
    setError(error);

    pushMessage({
      text: error || t('screens.profile.edit_profile.avatar_update_error'),
      type: 'error',
    });
  };

  return props.children({
    file,
    error,
    isFocused,
    isRejected,
    isSubmitting,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    onSubmit: handleSubmit,
    onDelete: handleDelete,
  });
};

export default AvatarFormContainer;
