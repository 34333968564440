import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../models/assistant/interfaces/Button';
import { Icon } from '../ui';

interface AssistantSuggestedCommandsProps {
  commands: Button[];
  onSelect: (command: Button) => void;
  onToggle?: (ref: HTMLDivElement | null) => void;
}

function AssistantSuggestedCommands({
  commands,
  onSelect,
  onToggle,
}: AssistantSuggestedCommandsProps) {
  const MAX_LENGTH = 1;
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    onToggle && onToggle(ref.current);
  };

  return (
    <div className="assistant__suggested-commands" ref={ref}>
      {commands && commands.length ? (
        <>
          <ul>
            {commands
              .slice(0, showAll ? undefined : MAX_LENGTH)
              .map((command) => (
                <li key={command.button_text}>
                  <button onClick={() => onSelect(command)}>
                    {command.button_text}
                  </button>
                </li>
              ))}
          </ul>
          {commands.length > MAX_LENGTH ? (
            <span className="assistant__suggested-commands__expand">
              <button
                onClick={toggleShowAll}
                aria-label={
                  showAll ? t('assistant.show_all') : t('assistant.show_all')
                }
              >
                {!showAll ? (
                  <span className="count">{commands.length}</span>
                ) : null}
                <Icon
                  type={showAll ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                />
              </button>
            </span>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default AssistantSuggestedCommands;
