import React, { useState, Fragment } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { programSelectors } from '../../models/program';
import { channelSelectors } from '../../models/channels';

import withRerenderPrevent from '../../common/with-rerender-prevent';
import useScreenTitle from '../../common/use-screen-title';

import { Link } from '../../components/ui';
import SearchHeader from '../../components/search-header';
import { DiscoverChannelCard } from '../../components/channel-card';
import ChannelList from '../../components/channel-list';
import ChannelFilters from '../../components/channel-filters';
import ChannelContentFilters from '../../components/channel-content-filters';

import './discover.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const Discover = ({
  programName,
  channelsFollowed,
  channelsRecommended,
  channelsPopular,
  channelsNewest,
  searchBarInHeaderEnabled,
}) => {
  const [channelIds] = useState({
    followed: channelsFollowed.map((c) => c.id),
    recommended: channelsRecommended.map((c) => c.id),
    popular: channelsPopular.map((c) => c.id),
    newest: channelsNewest.map((c) => c.id),
  });

  const channelsFollowedTotal = channelIds.followed.length;
  const channelsFollowedLimit = searchBarInHeaderEnabled ? 6 : 7;
  const channelsFollowedIds = channelIds.followed.slice(
    0,
    channelsFollowedLimit
  );

  const { t } = useTranslation();

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );

  const pageDescriptionTranslationKey = newTopicsString
    ? 'discover.page_description'
    : 'discover.page_description_channels';

  const joinedChannelsTranslationKey = newTopicsString
    ? 'discover.joined_topics'
    : 'discover.joined_channels';

  useScreenTitle(t('nav_links.discover'));

  const discoverHeaderStandard = (
    <SearchHeader
      title={<>{t('discover.discover_program', { name: programName })}</>}
    />
  );

  const discoverHeaderSearchHeader = (
    <section className="discover__header">
      <h1 className="discover__title">
        {t('discover.discover_program', { name: programName })}
      </h1>
      <p className="discover__page-desc">{t(pageDescriptionTranslationKey)}</p>
    </section>
  );

  const discoverAllClassnames = cx('discover__all', {
    'container--medium': searchBarInHeaderEnabled,
  });

  const discoverHeader = searchBarInHeaderEnabled
    ? discoverHeaderSearchHeader
    : discoverHeaderStandard;

  return (
    <div className="discover page">
      {discoverHeader}
      <section className="discover__followed">
        <div
          className="container container--medium"
          id={DEFAULT_SKIP_TO_CONTENT_ID}
        >
          <div className="discover__heading">
            <h2 className="section-title">{t(joinedChannelsTranslationKey)}</h2>

            {channelsFollowedTotal > channelsFollowedLimit ? (
              <div className="view-all-link">
                <Link
                  href="/account/channels"
                  action="push"
                  className="pt-secondary-color"
                >
                  {t('discover.view_all_number', {
                    count: channelsFollowedTotal,
                  })}
                </Link>
              </div>
            ) : null}
          </div>

          <ChannelList
            channelIds={channelsFollowedIds}
            analyticsData={{ location: 'discover' }}
            render={({ channelId, direction, analyticsData }) => (
              <DiscoverChannelCard
                channelId={channelId}
                orientation={
                  direction === 'vertical' ? 'landscape' : 'portrait'
                }
                size={direction === 'vertical' ? 'large' : 'small'}
                analyticsData={analyticsData}
                key={channelId}
              />
            )}
          />
        </div>
      </section>

      <section className={discoverAllClassnames}>
        <ChannelFilters analyticsData={{ location: 'discover' }} />
      </section>

      <div className="discover__filters">
        <ChannelContentFilters
          randomize
          analyticsData={{ location: 'discover' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  programName: programSelectors.getProgramName(state, ownProps),
  channelsFollowed: channelSelectors.getChannelsFollowed(state, ownProps),
  channelsRecommended: channelSelectors.getChannelsRecommended(state, ownProps),
  channelsPopular: channelSelectors.getChannelsPopular(state, ownProps),
  channelsNewest: channelSelectors.getChannelsNewest(state, ownProps),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default compose(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(Discover);
