export enum ActionNavigationType {
  DEEPLINK = 'deeplink',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  AUTHORIZATION = 'authorization',
}

export interface Navigation {
  url: string;
  title: string;
  command?: string;
  type: ActionNavigationType;
  label?: string;
  relative_url?: Record<'web' | 'mobile', string>;
}
