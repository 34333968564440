import { NavLink } from '../ui';
import { HomeIcon } from '../ui/icons';
import { trackHomeClick } from '../../models/analytics';

const SiteHeaderHomeIcon = ({ hoverColor, style, isMinimized }) => {
  const handleClickHomeLink = () => {
    trackHomeClick();
  };

  const options = isMinimized ? { tabIndex: -1 } : {};

  return (
    <NavLink
      className="site-header-home__link"
      href="/"
      action="push"
      exact
      onClick={handleClickHomeLink}
      hoverColor={hoverColor}
      style={style}
      {...options}
    >
      <HomeIcon />
    </NavLink>
  );
};

export default SiteHeaderHomeIcon;
