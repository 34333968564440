import React, { FC, MouseEventHandler } from 'react';
import ContentService from '../../../services/content';
import i18n from '../../../config/i18n';
import { trackContentCardTranslate } from '../../../models/content/analytics';
import { useDispatch } from 'react-redux';
import { contentOperations, contentSelectors } from '../../../models/content';

import { usePatronSelector } from '../../../common/use-patron-selector';
import { usePushToastOrFlashMessage } from '../toaster/deprecation-helper';

export interface ITranslateChildrenProps {
  canTranslate: boolean;
  isTranslated: boolean;
  handleClick: MouseEventHandler;
  contentTitle: string;
}

type ContentActionTranslateWrapperProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  children: FC<ITranslateChildrenProps>;
};

const ContentActionTranslateWrapper: React.FC<
  ContentActionTranslateWrapperProps
> = ({ contentId, analyticsData, children }) => {
  const isTranslated = usePatronSelector((s) =>
    contentSelectors.getContentIsTranslated(s, { contentId })
  );
  const canTranslate = usePatronSelector((s) =>
    contentSelectors.getContentCanTranslate(s, { contentId })
  );
  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, {
      contentId: Number(contentId),
    })
  );

  const dispatch = useDispatch();
  const { pushMessage } = usePushToastOrFlashMessage();
  const translateContent: typeof contentOperations.addContent = (content) =>
    dispatch(contentOperations.addContent(content));

  const handleClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();

    try {
      const res = await new ContentService(contentId).fetch({
        translate: !isTranslated,
      });
      const content = {
        ...res.data.feed[0].attributes,
        // If not translated already use browser language to translate
        ...(isTranslated ? {} : { display_language: i18n.language }),
      };

      translateContent(content);
    } catch (err: any) {
      if (err?.response) {
        pushMessage({
          text: 'There was an error translating content',
          type: 'error',
        });
      } else {
        throw err;
      }
    }

    trackContentCardTranslate(contentId, analyticsData);
  };

  return children({
    canTranslate,
    isTranslated,
    handleClick,
    contentTitle,
  });
};

export default ContentActionTranslateWrapper;
