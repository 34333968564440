import { EditorPlugin } from '@draft-js-plugins/editor';
import createTriggerDecorator from './createTriggerDecorator';
import createTagDecorator from './createTagDecorator';
import TagPluginStore, { TrackingContext } from './TagPluginStore';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import InternalSuggestionPortal from './InternalSuggestionPortal';
import { useOnClickOutside } from 'usehooks-ts';

interface SuggestionsPortalProps {
  trackingContext: TrackingContext;
}

interface PluginState {
  searchText: string;
  triggerElement: HTMLSpanElement | null;
}

interface UserTagPlugin extends EditorPlugin {
  store: TagPluginStore;
  SuggestionPortal: (props: SuggestionsPortalProps) => JSX.Element;
}

export type { TrackingContext };

/**
 * Creates a user tag plugin instance, this should be only called once, when the editor is mounted.
 * @param containerRef - a ref to the editor container element used to detect clicks outside the editor and hide the
 * suggestions. Suggestion dropdown must be located within the editor container.
 */
export function createUserTagPlugin(
  containerRef: RefObject<HTMLElement>
): UserTagPlugin {
  const store = new TagPluginStore();
  return {
    get store() {
      return store;
    },
    /**
     * SuggestionPortal component must be rendered inside the editor component, it will render the suggestions for tag
     */
    SuggestionPortal: ({ trackingContext }): JSX.Element => {
      const [state, setState] = useState<PluginState>({
        searchText: '',
        triggerElement: null,
      });
      useEffect(() => {
        store.trackingContext = trackingContext;
      }, [trackingContext]);
      useEffect(() => {
        return store.on('search', (searchText, triggerRef) => {
          setState({ searchText, triggerElement: triggerRef?.current || null });
        });
      }, []);

      const handleClickOutside = useCallback(() => {
        store.setEditorFocused(false);
      }, []);
      useOnClickOutside(containerRef, handleClickOutside);

      const handleSelect = useCallback((id: string, label: string) => {
        store.createTag(id, label);
      }, []);

      return (
        <InternalSuggestionPortal
          {...state}
          store={store}
          onSelect={handleSelect}
        />
      );
    },

    initialize: (fns) => {
      store.initialize(fns);
    },

    decorators: [createTagDecorator(), createTriggerDecorator(store)],

    onChange: (editorState) => {
      const selection = editorState.getSelection();
      if (selection.getHasFocus()) {
        store.setEditorFocused(true);
      }
      store.calculateSearchText();
      return editorState;
    },

    keyBindingFn: (event) => {
      // If plugin is not active, do not handle key bindings
      if (!store.isActive) {
        return;
      }
      switch (event.key) {
        case 'ArrowDown':
          store.emit('keyPress', event);
          return 'handled';
        case 'ArrowUp':
          store.emit('keyPress', event);
          return 'handled';
        case 'Tab':
          store.emit('keyPress', event);
          return 'handled';
        case 'Escape':
          store.exitTagging();
          return 'handled';
      }
      return;
    },
    /**
     * Enter key pressed, if active mark as handled, so editor does not insert a new line. This fired before editor's
     * handleReturn, * so we can intercept and handle it.
     */
    handleReturn(event: React.KeyboardEvent) {
      if (store.isActive) {
        store.emit('keyPress', event);
        return 'handled';
      }
    },
  };
}
