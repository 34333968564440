import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '../ui';
import {
  trackAssistantInboxItemView,
  trackAssistantSmartNotifClick,
  trackAssistantSmartNotifDismiss,
} from '../../models/assistant/analytics';
import { AssistantAction } from './assistant-action';
import { useInview } from '../../common/use-inview';
import { AssistantInboxItem } from '../../models/assistant/selectors';

import './assistant.scss';

import { assistantOperations } from '../../models/assistant';
import useAssistantActionHandlers from '../../common/use-assistant-action-handlers';

interface AssistantLandingItemProps {
  item: AssistantInboxItem;
  onClick?: () => void;
  onDismissClick?: () => void;
  analyticsData?: Record<string, unknown>;
}

export const AssistantLandingItem: React.FC<AssistantLandingItemProps> = ({
  item,
  onClick,
  onDismissClick,
  analyticsData,
}) => {
  const { handleRequest } = useAssistantActionHandlers();

  const [dismissing, setDismissing] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const dismissText = dismissing ? t('common.dismissing') : t('common.dismiss');

  const dismissMessage = () => {
    const id = item.id;
    dispatch(assistantOperations.dismissMessage(id));
  };

  const onItemClick = () => {
    trackAssistantSmartNotifClick({
      ...analyticsData,
      category: item.tracking_context.category,
    });

    onClick?.();

    if (item.open_request) {
      void handleRequest(item.open_request);
    }
  };

  const handleDismissClick = () => {
    if (!item.dismiss_request) return;

    trackAssistantSmartNotifDismiss({
      ...analyticsData,
      category: item.tracking_context.category,
    });

    setDismissing(true);
    handleRequest(item.dismiss_request)
      .then(() => {
        setDismissing(false);
        dismissMessage();
        onDismissClick && onDismissClick();
      })
      .catch(() => {
        setDismissing(false);
      });
  };

  const handleView = () => {
    trackAssistantInboxItemView({
      ...analyticsData,
      category: item.tracking_context.category,
    });
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  if (!(item && item.action)) {
    return null;
  }

  return (
    <li className="assistant__landing-item">
      <img
        alt="Assistant Landing"
        className="assistant__landing-item-image"
        src={item?.button_image_url}
      />
      <AssistantAction
        className="assistant__landing-item-data"
        ref={ref}
        action={item?.action}
        onClick={onItemClick}
        asCommand={false}
        actionTitle=""
      >
        <div className="assistant__landing-item-text">{item?.text}</div>
        <div className="assistant__landing-item-attribution">
          {item?.attribution}
        </div>
      </AssistantAction>
      {item?.dismiss_request ? (
        <div className="assistant__landing-item-actions">
          <Button
            onClick={handleDismissClick}
            icon={dismissing ? 'spinner' : 'highlight_off'}
            title={dismissText}
          />
        </div>
      ) : null}
    </li>
  );
};

export default AssistantLandingItem;
