import { useState, useEffect } from 'react';
import styled, { css, Keyframes, keyframes } from 'styled-components';
import * as fonts from '../../styles/fonts';
import * as colors from '../../styles/colors';
import useFeatureFlag from '../../common/use-feature-flag';
import { Feature } from '../../models/features/features';
import { createToastMessage, useToastMessages } from '../v2/toaster/toaster';

//animation out inspired by: https://czaplinski.io/blog/super-easy-animation-with-react-hooks/

const animateIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const animateOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const MessageContainer = styled.div<MessageContainerProps>`
  padding: 15px;
  color: white;
  font-size: ${fonts.sizeSm};
  line-height: 1.2;
  background-color: ${colors.blueFullValue};
  animation: ${(props) => props.animation} 0.2s;

  ${(props) =>
    props.type &&
    props.type === 'success' &&
    css`
      background-color: ${colors.greenFullValue};
    `}

  ${(props) =>
    props.type &&
    props.type === 'warning' &&
    css`
      background-color: ${colors.yellowFullValue};
    `}

  ${(props) =>
    props.type &&
    props.type === 'error' &&
    css`
      background-color: ${colors.redFullValue};
    `}
`;

type MessageContainerProps = {
  type: 'success' | 'warning' | 'error';
  animation: Keyframes;
};

export type FlashMessage = {
  type: MessageContainerProps['type'];
  text: string;
  timeout?: number;
};

type CommentsFlashMessageProps = {
  message?: FlashMessage;
};

//this component should be removed once we finally deprecate the old ui.
//we should use the toaster component instead, but due to differences in its interface this is probably the best place to put a feature flag for it.
//once we remove the flag, we should refactor this component's parents to use the toaster directly.
const CommentsFlashMessage = ({ message }: CommentsFlashMessageProps) => {
  const [, setTimeoutId] = useState<NodeJS.Timeout>();
  const [shouldRender, setRender] = useState(false);
  const [show, setShow] = useState(false);

  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);
  const { setToastMessage } = useToastMessages();

  useEffect(() => {
    if (message) {
      if (newUIEnabled) {
        setToastMessage(createToastMessage(message));
        return;
      }
      setShow(true);
      setTimeoutId((prevId) => {
        if (prevId) {
          clearTimeout(prevId);
        }

        return setTimeout(() => setShow(false), message?.timeout ?? 1000 * 6);
      });
    }
  }, [message, setToastMessage, newUIEnabled]);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    (shouldRender && !!message && (
      <MessageContainer
        type={message.type}
        animation={show ? animateIn : animateOut}
        onAnimationEnd={onAnimationEnd}
        role="status"
        aria-label="Status message"
      >
        {message.text}
      </MessageContainer>
    )) ||
    null
  );
};

export default CommentsFlashMessage;
