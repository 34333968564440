import { RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

function useEscapeToClose(element: RefObject<HTMLElement>, close: () => void) {
  const handleKeypress = (e: KeyboardEvent) => {
    if (!element.current) return;

    const elementIsTarget = element.current.contains(e.target as HTMLElement);

    if (!elementIsTarget) {
      return;
    }

    if (e.key === 'Escape') {
      close && close();
    }
  };

  useEventListener('keydown', handleKeypress);
}

export default useEscapeToClose;
