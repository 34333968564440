import { useRecoilState } from 'recoil';

import { submissionChannelsVisible } from '../../models/content-submission/atoms';

import useOutsideClick from '../../common/use-outside-click';

import ChannelsSelected from './channels-selected';
import ChannelsPopover from './channels-popover/channels-popover';
import FocusLock from 'react-focus-lock';
import useSuggestedChannels from '../../common/channel/use-suggested-channels';

const Channels = () => {
  const [isActive, setIsActive] = useRecoilState(submissionChannelsVisible);
  const suggestions = useSuggestedChannels();

  const handleTriggerClick = () => {
    setIsActive(!isActive);
  };

  const escToClose = (e) => {
    if (e.key === 'Escape') {
      setIsActive(false);
      containerRef.current?.focus();
    }
  };

  const containerRef = useOutsideClick({
    disabled: !isActive,
    onClick: handleTriggerClick,
  });

  const popoverId = 'content-submission-channels-popover';

  return (
    // keyDown is to capture bubbled events from nested focued elements.
    // not sure how to do this well without disabling linter complaint or large refactor.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="content-submission-channels"
      onKeyDown={escToClose}
      ref={containerRef}
    >
      <ChannelsSelected
        onClick={handleTriggerClick}
        aria-controls={popoverId}
        aria-expanded={Boolean(isActive)}
      />

      {isActive ? (
        <FocusLock>
          <ChannelsPopover
            id={popoverId}
            suggestedChannels={suggestions.data}
            onClose={() => setIsActive(false)}
          />
        </FocusLock>
      ) : null}
    </div>
  );
};

export default Channels;
