import {
  ChangeEventHandler,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react';
import { RadioButton, Checkbox, Button } from '../ui';
import './assistant.scss';
import { Card } from '../../models/assistant/interfaces/subject/Summary';
import { Actionable } from '../../models/assistant/interfaces/Actionable';
import { Button as IButton } from '../../models/assistant/interfaces/Button';

type OnClickEvent<T> = Parameters<MouseEventHandler<T>>[0];

type AssistantPollAnswersProps = {
  subject: Card;
  handleSubmit: (action: Actionable, answers: Array<string>) => void;
};

const AssistantPollAnswers = ({
  subject,
  handleSubmit,
}: AssistantPollAnswersProps): ReactElement | null => {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [answers, setAnswers] = useState<Array<string>>([]);
  const [subj] = useState(subject);
  const [submitting, setSubmitting] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newSelected = {
      ...selected,
      [e.target.name]: e.target.checked,
    };
    setSelected(newSelected);
    setAnswers(Object.keys(newSelected).filter((text) => newSelected[text]));
  };

  const handleRadioChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelected({
      [e.target.id]: e.target.checked,
    });
    setAnswers([e.target.value]);
  };

  const handleImageChange = (
    e: OnClickEvent<HTMLButtonElement>,
    value: string
  ) => {
    setSelected({
      [(e.target as HTMLButtonElement).name]: true,
    });
    setAnswers([value]);
  };

  const onSubmit = (
    event: OnClickEvent<HTMLButtonElement>,
    action: Actionable
  ) => {
    event.currentTarget.disabled = true;

    setSubmitting(true);
    handleSubmit(action, answers);
    setSubmitting(false);
  };

  const getPollBody = (type: string | undefined) => {
    switch (type) {
      case 'star':
        return starPollBody();
      case 'image':
        return imagePollBody();
      default:
        return pollBody();
    }
  };

  const pollBody = () => {
    if (!subj.buttons) return null;

    return subj.buttons.map((answer, index) => {
      const answerId = `${subj.id}-${index}`;
      return answer.type === 'poll_option' ? (
        subj.selection === 'multiple' ? (
          <Checkbox
            key={answer.button_text}
            checked={selected[answer.button_text] || false}
            name={answer.button_text}
            onChange={handleChange}
            label={answer.button_text}
          />
        ) : (
          <RadioButton
            key={answer.button_text}
            checked={selected[answerId] || false}
            id={answerId}
            name={'poll-' + subj.id}
            onChange={handleRadioChange}
            label={answer.button_text}
          />
        )
      ) : (
        <div className="assistant__poll__submit" key={answer.button_text}>
          <Button
            theme="action"
            disabled={!answers.length || submitting}
            onClick={(event: OnClickEvent<HTMLButtonElement>) => {
              answer.action && onSubmit(event, answer.action);
            }}
          >
            {answer.button_text}
          </Button>
        </div>
      );
    });
  };

  const imagePollOptions = (buttons: IButton[], filled: boolean) => {
    const choices: JSX.Element[] = [];
    let submit;
    const numSelected = answers[0] ? answers[0].length / 2 : 0;

    buttons.forEach((answer, index) => {
      if (answer.type !== 'poll_option') {
        submit = (
          <div className="assistant__poll__submit" key={answer.button_text}>
            <Button
              theme="action"
              disabled={!answers.length || submitting}
              aria-label={answer.button_text}
              onClick={(event: OnClickEvent<HTMLButtonElement>) => {
                answer.action && onSubmit(event, answer.action);
              }}
            >
              {answer.button_text}
            </Button>
          </div>
        );
      } else {
        const isSelected = filled
          ? index < numSelected
          : answer.image?.alt_text && selected[answer.image.alt_text];
        choices.push(
          <button
            key={answer.button_text}
            name={filled ? answer.button_text : answer.image?.alt_text}
            className="poll__answer-image-container"
            onClick={(e) => handleImageChange(e, answer.button_text)}
            aria-label={filled ? answer.button_text : answer.image?.alt_text}
          >
            <img
              src={isSelected ? answer.selected?.image.url : answer.image?.url}
              className="poll__answer-image"
              alt={isSelected ? answer.image?.alt_text : answer.image?.alt_text}
            />
          </button>
        );
      }
    });

    return (
      <>
        <div className={'assistant__poll__answers-images'}>{choices}</div>
        {submit}
      </>
    );
  };

  const imagePollBody = () => {
    if (!subj.buttons) return null;
    const buttons = imagePollOptions(subj.buttons, false);

    return (
      <>
        <div className={'assistant__poll__answers-selection-text'}>
          {Object.keys(selected)[0]}
        </div>
        {buttons}
      </>
    );
  };

  const starPollBody = () => {
    if (!subj.buttons) return null;
    const numSelected = answers[0] ? answers[0].length / 2 : 0;
    const buttons = imagePollOptions(subj.buttons, true);

    const selectionText =
      numSelected > 0 && subj.buttons[numSelected - 1]
        ? subj.buttons?.[numSelected - 1].image?.alt_text
        : '';

    return (
      <>
        <div className={'assistant__poll__answers-selection-text'}>
          {selectionText}
        </div>
        {buttons}
      </>
    );
  };

  return <>{getPollBody(subj.selection_type)}</>;
};

export default AssistantPollAnswers;
