import React, { createContext } from 'react';
const defaultThinkWarning = {
  showThinkWarning: false,
  thinkWarningHtml: undefined,
  thinkWarningLink: undefined,
};

export const ThinkWarningContext = createContext();
const ThinkWarningProvider = ({ thinkWarning, children }) => {
  thinkWarning = thinkWarning || defaultThinkWarning;

  return (
    <ThinkWarningContext.Provider value={{ thinkWarning }}>
      {children}
    </ThinkWarningContext.Provider>
  );
};
export default ThinkWarningProvider;
