import { useContext } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '../../ui';

import EmojiPicker from '../../emoji-picker/EmojiPicker';
import MentionPicker from '../../mention-picker/MentionPicker';

import { ThinkWarningContext } from '../../../config/context';
import { CommentsThinkWarning } from './comments-think-warning';

type CommentFormHeaderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  showEmojis: boolean;
  mentionText: string | undefined;
  onEmojiClick: (emoji: string) => void;
  onMentionClick: (mention: string) => void;
  onCancelClick: () => void;
};

export function CommentFormHeader({
  context,
  showEmojis,
  mentionText,
  onEmojiClick,
  onMentionClick,
  onCancelClick,
}: CommentFormHeaderProps) {
  const { t } = useTranslation();

  const contextClassName = cx('comment-context', {
    [`comment-context--${context.action}`]: !!context.action,
  });

  const contextData = useContext(ThinkWarningContext);

  return (
    <header className="comment-form-header">
      {contextData?.thinkWarning?.showThinkWarning ? (
        <CommentsThinkWarning
          thinkWarningHtml={contextData.thinkWarning.thinkWarningHtml}
          thinkWarningLink={contextData.thinkWarning.thinkWarningLink}
        />
      ) : null}
      {context.action === 'edit' || context.action === 'reply' ? (
        <div className={contextClassName}>
          <div className="comment-context__title">
            {t('comments.in_response')}
          </div>

          <div className="comment-context__body">
            {context.replyAuthor ? context.replyAuthor.name : null}
          </div>

          <div className="comment-context__reset">
            <Button onClick={onCancelClick} aria-label={t('common.cancel')}>
              {t('common.cancel')}
            </Button>
          </div>
        </div>
      ) : null}

      {showEmojis ? <EmojiPicker onClick={onEmojiClick} /> : null}

      {mentionText ? (
        <MentionPicker matchString={mentionText} onClick={onMentionClick} />
      ) : null}
    </header>
  );
}
