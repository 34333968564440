import {
  Button,
  Icon,
  IconButton,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePatronSelector } from '../../../../../../common/use-patron-selector';
import {
  contentOperations,
  contentSelectors,
} from '../../../../../../models/content';
import {
  trackContentCardBookmark,
  trackContentCardUnbookmark,
} from '../../../../../../models/content/analytics';
import ContentService from '../../../../../../services/content';
import styles from './styles.module.scss';
import { FeedCardActionsProps } from './type';

export function FeedCardActionBookmark({
  content,
  analyticsData,
  isHidden,
}: FeedCardActionsProps) {
  const isBookmarked = usePatronSelector((s) =>
    contentSelectors.getContentBookmarked(s, { contentId: content.id })
  );
  const contentTitle = usePatronSelector((s) =>
    contentSelectors.getContentFunctionalTitle(s, {
      contentId: content.id,
    })
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bookmarkContent = (id: string | number) =>
    dispatch(contentOperations.bookmarkContent(id));
  const unbookmarkContent = (id: string | number) =>
    dispatch(contentOperations.unbookmarkContent(id));

  const handleClick = () => {
    if (isBookmarked) {
      trackContentCardUnbookmark(content.id, analyticsData);
      new ContentService(content.id).unbookmark().then(() => {
        unbookmarkContent(content.id);
      });
    } else {
      trackContentCardBookmark(content.id, analyticsData);
      new ContentService(content.id).bookmark().then(() => {
        bookmarkContent(content.id);
      });
    }
  };

  const bookmarkKey = isBookmarked
    ? 'content.bookmark_remove'
    : 'content.bookmark';
  const bookmarkActionKey = isBookmarked
    ? 'content.bookmark_remove_action'
    : 'content.bookmark_action';
  const actionLabel = t(bookmarkActionKey, { title: contentTitle });

  const label = t(bookmarkKey);

  if (isHidden) {
    return (
      <Button
        variant="text"
        selected={isBookmarked}
        label={label}
        leftIcon={<Icon size={20}>bookmark</Icon>}
        onClick={handleClick}
      />
    );
  }

  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <IconButton
          aria-label={actionLabel}
          className={classNames({
            [styles.IconFilled]: isBookmarked,
          })}
          iconFilled={isBookmarked}
          iconName="bookmark"
          size="compact"
          onClick={handleClick}
        />
      </TooltipTrigger>
      <TooltipContent description={label} />
    </TooltipRoot>
  );
}
