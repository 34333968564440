import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import sampleSize from 'lodash/sampleSize';
import keyBy from 'lodash/keyBy';
import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantHistoryItem } from './interfaces/AssistantHistoryItem';
import { CommandButton } from './interfaces/Command';
import { Navigation } from './interfaces/Navigation';
import { AssistantResponse } from './interfaces/AssistantResponse';
import { Service } from './interfaces/subject/Service';
import { Inbox } from './interfaces/subject/Inbox';
import { InboxItem } from './interfaces/InboxItem';

export enum AssistantActionType {
  Navigation = 'navigation',
}

export interface AssistantNavigationAction {
  type: AssistantActionType.Navigation;
  navigation: Navigation;
  request: string;
  title: string;
}

export type AssistantInboxItem = InboxItem;
export type AssistantIntegration<
  Config extends Record<string, unknown> = Record<string, unknown>
> = AssistantResponse<Service<Config>>;

export interface AssistantBootstrapEmptyInboxAction {
  icon: string;
  title: string;
  description: string;
  action: AssistantNavigationAction;
}

interface AssistantBootstrap {
  assets: any;
  assistant_bootstrap: any;
  emptyInboxActions: AssistantBootstrapEmptyInboxAction[];
}

export type AssistantInbox = AssistantResponse<Inbox>;

export interface AssistantState {
  bootstrap: AssistantBootstrap;
  commandText?: string;
  commands?: CommandButton[];
  dismissedMessages: any;
  trackedContent: Record<string, true>;
  history: AssistantHistoryItem[];
  inbox?: AssistantInbox[];
  inboxCount?: number;
  integrations?: AssistantIntegration[];
  integrationsById: Record<string | number, AssistantIntegration>;
  quickActions?: AssistantResponse<Service>[];
}

const assistant = (state: RootPatronState) => state.assistant;
const commandText = (state: RootPatronState) => assistant(state).commandText;

const integrationId = (
  state: RootPatronState,
  props: {
    integrationId?: string;
    id?: string;
    match?: {
      params: {
        integrationId?: string;
      };
    };
  }
) => props.integrationId ?? props.id ?? props.match?.params.integrationId ?? -1;

const inboxCount = (state: RootPatronState) => assistant(state).inboxCount;
const inbox = (state: RootPatronState) => assistant(state).inbox;
const getBootstrap = (state: RootPatronState) => assistant(state).bootstrap;
const getDismissedMessages = (state: RootPatronState) =>
  assistant(state).dismissedMessages;

export const getViewTrackingStatus =
  (itemId: string) => (state: RootPatronState) =>
    !!assistant(state).trackedContent[itemId];

export const getCommands = createSelector(
  [assistant],
  (assistant) => assistant?.commands
);

export const getIntegrations = createSelector([assistant], (assistant) =>
  assistant ? assistant.integrations || [] : null
);

export const getQuickActions = createSelector(
  [assistant],
  (assistant) => assistant.quickActions
);

export const getIntegration = createSelector(
  [integrationId, assistant],
  (id, assistant) => assistant?.integrationsById[id]
);

export const getCommandsByName = createSelector(
  [commandText, getCommands],
  (commandText, commands) => {
    if (!commandText) return [];

    const userText = commandText.toLowerCase();

    // Don't match semantic search when suggesting commands
    commands = (commands || []).filter((command) => {
      return !command.id.includes('document_search');
    });
    // Check if user search matches command with leading/trailing wildcards
    const fullMatchCommands = (commands || []).filter((command) => {
      return command.button_text.toLowerCase().includes(userText);
    });

    if (fullMatchCommands.length) return fullMatchCommands;

    // If no direct match, find matches by multi-word match
    const userWords = userText.split(/\s+/);

    // display all commands that contain some of those words, sorted by number of characters matched
    return (commands || [])
      .reduce<{ matchCount: number; command: CommandButton }[]>(
        (matched, command) => {
          const commandWords = command.button_text.toLowerCase().split(/\s+/);
          const matchCount = intersection(userWords, commandWords).join(
            ''
          ).length;

          return matchCount > 0
            ? [...matched, { matchCount, command }]
            : matched;
        },
        []
      )
      .sort((a, b) => b.matchCount - a.matchCount)
      .map((c) => c.command);
  }
);

export const getSearchCommand = createSelector(
  [getCommands],
  (commands): CommandButton | null => {
    const fullMatchCommands = (commands || []).filter((command) => {
      return command.id.includes('document_search');
    });

    if (fullMatchCommands.length) return fullMatchCommands[0];
    else return null;
  }
);

export const getHistory = createSelector(
  [assistant],
  (assistant) => assistant?.history
);

export const getServiceHistory = createSelector([getHistory], (history) =>
  history?.filter((historyItem) => historyItem.author === 'system')
);

export const getExampleCommands = createSelector(
  [getCommands],
  // get three random items from available commands, excluding semantic search
  (commands) => {
    const regularCommands = (commands || []).filter((command) => {
      return !command.id.includes('document_search');
    });
    return sampleSize(regularCommands, 3);
  }
);

export const getLastHistoryItem = createSelector(
  [getServiceHistory],
  (history) => history && history.length && history[history.length - 1]
);

export const getLastSuggestedCommands = createSelector(
  [getLastHistoryItem],
  (item) =>
    item && item.subject && item.subject.buttons ? item.subject.buttons : []
);

export const getInboxItems = createSelector(
  [inbox],
  (inbox) => inbox?.[0]?.subject?.inbox_items
);

export const getMessageItem = createSelector(
  [getInboxItems],
  (items) =>
    items &&
    [...items].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0]
);

export const getBootstrapScreens = createSelector(
  [getBootstrap],
  (bootstrap) => {
    return bootstrap && bootstrap.assistant_bootstrap
      ? keyBy(bootstrap.assistant_bootstrap, (item) => item.type)
      : null;
  }
);

export const getBootstrapAssets = createSelector(
  [getBootstrap],
  (bootstrap) => {
    return bootstrap && bootstrap.assets
      ? keyBy(bootstrap.assets, (item) => item.name)
      : null;
  }
);

export const getBootstrapEmptyInboxActions = createSelector(
  [getBootstrap],
  (bootstrap) => {
    return bootstrap ? bootstrap.emptyInboxActions || [] : null;
  }
);

export const getAssistantMascotAsset = createSelector(
  [getBootstrapAssets],
  (assets) => assets && assets.assistant_avatar && assets.assistant_avatar.value
);

export const getLabels = createSelector(
  [getBootstrapScreens, getBootstrapAssets],
  (screens, assets) => {
    return screens && assets
      ? {
          needs_attention: screens.todos && screens.todos.title,
          apps: screens.services && screens.services.title,
          notifications: screens.notifications && screens.notifications.title,
          command_prompt:
            assets.assistant_command_prompt_label &&
            assets.assistant_command_prompt_label.value,
        }
      : {};
  }
);

export default {
  commandText,
  inbox,
  inboxCount,
  getDismissedMessages,
  getCommands,
  getIntegrations,
  getQuickActions,
  getIntegration,
  getHistory,
  getCommandsByName,
  getExampleCommands,
  getSearchCommand,
  getLastSuggestedCommands,
  getMessageItem,
  getBootstrapScreens,
  getBootstrapAssets,
  getAssistantMascotAsset,
  getLabels,
  getViewTrackingStatus,
};
