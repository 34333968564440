import useContentFeed from '../../common/use-content-feed';
import { Spinner } from '../ui';
import ChannelCard from '../channel-card';
import ContentList from '../content-list';
import './channel-content.scss';

const ChannelContent = ({ channelId, analyticsData }) => {
  const contentItemLimit = 4;

  const { fetching, feedItems } = useContentFeed({
    channelId,
    perPage: contentItemLimit,
  });

  let feedItemIds = feedItems.map((item) => item.id);

  return (
    <div className="channel-content">
      <Spinner loading={fetching}>
        <ChannelCard channelId={channelId} analyticsData={analyticsData} />

        <ContentList
          contentIds={feedItemIds}
          limit={contentItemLimit}
          analyticsData={analyticsData}
        />
      </Spinner>
    </div>
  );
};

export default ChannelContent;
