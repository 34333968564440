import { useEffect, useState } from 'react';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import AssistantService from '../services/assistant';

function useNotificationsFetcher(ids: string[]) {
  const [notifications, setNotifications] = useState<Array<AssistantResponse>>(
    []
  );
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);

  async function fetchNotifications(reset = false) {
    setIsFetching(true);

    const params = {
      page: (reset ? 0 : page) + 1,
      ...(ids && { ids }),
    };

    try {
      const res = await new AssistantService().fetchNotifications(params);

      setNotifications([...notifications, ...res.data.data]);
      setPage(res.data.meta.page.number);
      setCanLoadMore(res.data.meta.page.number < res.data.meta.page.total);
      setIsError(false);
      setIsFetching(false);
    } catch (e) {
      setIsError(true);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchNotifications(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  return {
    notifications,
    fetchNotifications,
    isFetching,
    isError,
    canLoadMore,
  };
}

export default useNotificationsFetcher;
