import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentSelectors } from '../../../models/content/index.js';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { programSelectors } from '../../../models/program';

import { Link, Icon } from '../../../components/ui';

import ContentDetailArticle from './content-detail-article';
import ContentDetailImage from './content-detail-image';
import ContentDetailVideo from './content-detail-video';
import ContentTypeNote from './content-detail-note';
import ContentDetailDefault from './content-detail-default';

import './content-detail.scss';

const ContentDetail = ({
  contentId,
  contentType,
  v2NotesEnabled,
  onClose,
  emailContent,
  backButtonDisabled,
}) => {
  const contentCanTranslate = usePatronSelector((state) =>
    contentSelectors.getContentCanTranslate(state, { contentId })
  );
  const contentLocale = usePatronSelector((state) =>
    contentSelectors.getContentLang(state, { contentId })
  );

  const { t } = useTranslation();

  const CONTENT_TYPE_MAP = {
    article: ContentDetailArticle,
    image: ContentDetailImage,
    video: ContentDetailVideo,
    note: v2NotesEnabled ? ContentTypeNote : ContentDetailDefault,
  };

  const ContentComponent =
    CONTENT_TYPE_MAP[contentType] || ContentDetailDefault;
  const classType = contentType || 'default';
  const v2Note = contentType === 'note' && v2NotesEnabled;

  const className = cx(
    'content-detail',
    `content-detail--${v2Note ? 'notev2' : classType}`,
    { 'content-detail--email': emailContent }
  );

  const articleEl = useRef(null);

  useEffect(() => {
    articleEl.current.focus();
  }, []);

  return (
    <article ref={articleEl} tabIndex={-1} className={className}>
      {!emailContent && !backButtonDisabled ? (
        <Link
          className="content-detail__link--back"
          onClick={onClose}
          aria-label={t('common.back')}
          tabIndex="0"
          onKeyDown={(e) => {
            if (['Enter', 'Spacebar', ' '].indexOf(e.key) !== -1) {
              e.stopPropagation();
              onClose();
            }
          }}
        >
          <Icon type="arrow_back" />
        </Link>
      ) : null}

      <ContentComponent
        contentId={contentId}
        analyticsData={{ location: 'content_detail' }}
        locale={contentCanTranslate ? contentLocale : undefined}
      />
    </article>
  );
};

const mapStateToProps = (state, ownProps) => ({
  contentType: contentSelectors.getContentType(state, ownProps),
  v2NotesEnabled: programSelectors.getV2NotesEnabled(state, ownProps),
  isActive: !!state.ui.overlays.length,
  backButtonDisabled: getFeatureFlag(
    state,
    Feature.CONTENT_BACK_BUTTON_REMOVED
  ),
});

export default connect(mapStateToProps, null)(ContentDetail);
