export const SUBMIT_CLICK = 'w:Submit:click';
export const SUBMIT_SUBMIT_CLICK = 'w:Submit:Submit:click';
export const SUBMIT_SAVE_CLICK = 'w:Submit:Save:click';
export const SUBMIT_SUBMIT_SUCCESS = 'w:Submit:Submit:success';
export const SUBMIT_SUBMIT_ERROR = 'w:Submit:Submit:error';
export const SUBMIT_ADD_LINK_CLICK = 'w:Submit:AddLink:click';
export const SUBMIT_ADD_LINK_ADD_LINK_CLICK = 'w:Submit:AddLink:AddLink:click';
export const SUBMIT_ADD_LINK_ADD_LINK_ERROR = 'w:Submit:AddLink:AddLink:error';
export const SUBMIT_LOAD_LINK_PREVIEW_SUCCESS =
  'w:Submit:LoadLinkPreview:success';
export const SUBMIT_LINK_PREVIEW_REMOVE_CLICK =
  'w:Submit:LinkPreview:Remove:click';
export const SUBMIT_ADD_LINK_CANCEL_CLICK = 'w:Submit:AddLink:Cancel:click';
export const SUBMIT_ADD_IMAGE_CLICK = 'w:Submit:AddImage:click';
export const SUBMIT_ADD_IMAGE_ADD_IMAGE_CLICK =
  'w:Submit:AddImage:AddImage:click';
export const SUBMIT_ADD_IMAGE_ADD_IMAGE_ERROR =
  'w:Submit:AddImage:AddImage:error';
export const SUBMIT_LOAD_IMAGE_PREVIEW_SUCCESS =
  'w:Submit:LoadImagePreview:success';
export const SUBMIT_IMAGE_PREVIEW_REMOVE_CLICK =
  'w:Submit:ImagePreview:Remove:click';
export const SUBMIT_ADD_IMAGE_CANCEL_CLICK = 'w:Submit:AddImage:Cancel:click';
export const SUBMIT_ADD_VIDEO_CLICK = 'w:Submit:AddVideo:click';
export const SUBMIT_ADD_VIDEO_ADD_VIDEO_CLICK =
  'w:Submit:AddVideo:AddVideo:click';
export const SUBMIT_ADD_VIDEO_ADD_VIDEO_ERROR =
  'w:Submit:AddVideo:AddVideo:error';
export const SUBMIT_LOAD_VIDEO_PREVIEW_SUCCESS =
  'w:Submit:LoadVideoPreview:success';
export const SUBMIT_VIDEO_PREVIEW_REMOVE_CLICK =
  'w:Submit:VideoPreview:Remove:click';
export const SUBMIT_ADD_VIDEO_CANCEL_CLICK = 'w:Submit:AddVideo:Cancel:click';
export const SUBMIT_ADD_NOTE_CLICK = 'w:Submit:AddNote:click';
export const SUBMIT_ADD_NOTE_CANCEL_CLICK = 'w:Submit:AddNote:Cancel:click';
export const SUBMIT_ADD_ARTICLE_CLICK = 'w:Submit:AddArticle:click';
export const SUBMIT_ADD_ARTICLE_CANCEL_CLICK =
  'w:Submit:AddArticle:Cancel:click';
export const EDIT_CLICK = 'w:Edit:click';

/**
 * User starts member tag search
 */
export const TAG_SEARCH_TYPE = 'w:Submit:TagSearch:type';
/**
 * User selects suggestion from dropdown
 */
export const TAG_SEARCH_CLICK = 'w:Submit:TagSearch:click';
