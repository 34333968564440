import { useMemo } from 'react';
import { darken, lighten, rgba } from 'polished';
import useProgramTheme from '../../common/use-program-theme';
import { StyleTag } from '../ui';
import { generateContrastingColor } from '../../lib/colors';
import { useCssVariableOverride } from '@socialchorus/shared-ui-components';

const ProgramTheme = () => {
  const {
    themeColor,
    primaryTextColor,
    accentColor,
    wallpaperUrl,
    fontCssValue,
  } = useProgramTheme();

  let outlineColor = useMemo(
    () => generateContrastingColor(themeColor),
    [themeColor]
  );

  useCssVariableOverride({
    primaryColor: themeColor,
    secondaryColor: accentColor,
    applyToDocumentRoot: true,
  });

  // https://stackoverflow.com/a/39077686
  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const themeColorRGB = hexToRgb(themeColor);
  const primaryTextColorRGB = hexToRgb(primaryTextColor);
  const accentColorRGB = hexToRgb(accentColor);

  // +primary_color+ Maps to Studio UI "Primary Color" can only be black or White
  // +secondary_color+ Maps to "Accent Color" in Studio UI Description: Brand complementary color, used for buttons and accent elements.
  //                   When it is not set, we set our default accent color.
  // +theme_color+ Maps to "Theme Color" in Studio UI Banner color for the web experience.
  //               When it is not set, we set our default theme color.
  return (
    <StyleTag>{`
      :root {
        --theme-color: ${themeColor};
        --primary-color: ${primaryTextColor};
        --secondary-color: ${accentColor};

        --theme-color-r: ${themeColorRGB[0]};
        --theme-color-g: ${themeColorRGB[1]};
        --theme-color-b: ${themeColorRGB[2]};

        --primary-color-r: ${primaryTextColorRGB[0]};
        --primary-color-g: ${primaryTextColorRGB[1]};
        --primary-color-b: ${primaryTextColorRGB[2]};

        --secondary-color-r: ${accentColorRGB[0]};
        --secondary-color-g: ${accentColorRGB[1]};
        --secondary-color-b: ${accentColorRGB[2]};
      }

      a { color: ${accentColor}; }

      .pt-theme-color { color: ${themeColor}; }
      .pt-primary-color { color: ${primaryTextColor}; }
      .pt-secondary-color { color: ${accentColor}; }
      .pt-theme-light-color { color: ${lighten(0.1, themeColor)}; }

      .pt-primary-fill { fill: ${primaryTextColor}; }
      .pt-secondary-fill { fill: ${accentColor}; }

      .pt-hover-primary-color:hover { color: ${primaryTextColor} !important; }
      .pt-hover-secondary-color:hover { color: ${accentColor} !important; }

      .pt-hover-primary-fill:hover { fill: ${primaryTextColor}; }
      .pt-hover-secondary-fill:hover { fill: ${accentColor}; }

      .pt-theme-bgcolor { background-color: ${themeColor}; }
      .pt-primary-bgcolor { background-color: ${primaryTextColor}; }
      .pt-secondary-bgcolor { background-color: ${accentColor}; }
      .pt-theme-light-bgcolor { background-color: ${lighten(0.1, themeColor)}; }
      .pt-theme-light-bordercolor { border-color: ${lighten(0.1, themeColor)}; }
      .pt-secondary-light-bordercolor { border-color: ${lighten(
        0.1,
        accentColor
      )} !important; }

      .pt-theme-button--primary { background-color: ${primaryTextColor}; border-color: ${primaryTextColor}; }
      .button--v2-primary { background-color: ${themeColor};  }
      .button--link-color { color: ${accentColor}; }
      .pt-theme-button--secondary { background-color: ${accentColor}; border-color: ${accentColor}; }
      .content__action--active .icon:not(.heart) {
        color: ${accentColor};
      }

      .pt-wallpaper-bg { background-image: url(${wallpaperUrl}); }
      .pt-wallpaper-bg--gradient {
        background-image: linear-gradient(to bottom, ${rgba(
          themeColor,
          0.9
        )}, ${rgba(themeColor, 0.5)}),
                          url(${wallpaperUrl});
      }

      body:not(.is-tabbing) .text-input input:focus,
      body:not(.is-tabbing) .text-input textarea:focus {
        border-color: ${lighten(0.1, themeColor)};
      }

      .checkbox input[type='checkbox']:checked + .input {
        color: ${themeColor};
      }
      .toggle input[type='checkbox']:checked + .input {
        background-color: ${themeColor};
        border-color: ${darken(0.1, themeColor)};
      }

      body.is-tabbing .pt-theme-bgcolor *:focus,
      body.is-tabbing .pt-theme-bgcolor *.focused,
      body.is-tabbing .pt-theme-bgcolor *.is-focused,
      body.is-tabbing .assistant__entry *:focus,
      body.is-tabbing .assistant__entry *.focused,
      body.is-tabbing .assistant__entry *.is-focused,
      body.is-tabbing .pt-wallpaper-bg--gradient *:focus,
      body.is-tabbing .pt-wallpaper-bg--gradient *.focused,
      body.is-tabbing .pt-wallpaper-bg--gradient *.is-focused {
        outline: 2px solid ${outlineColor};
        outline-offset: 2px;
      }

      body.is-tabbing .home__community *:focus,
      body.is-tabbing .home__community *.focused,
      body.is-tabbing .home__community *.is-focused {
        outline: 2px solid #BDBDFF;
        outline-offset: 2px;
      }

      :root {
        --custom-font: ${
          fontCssValue ? fontCssValue : "'Inter', 'Open Sans', Arial"
        };
      }

    }`}</StyleTag>
  );
};

export default ProgramTheme;
