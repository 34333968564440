import { AxiosResponse } from 'axios';
import useSWRInfinite from 'swr/infinite';
import { User } from '../models/advocate/types';
import { useMemo } from 'react';
import MojoApiFactory from '../config/api-factory';

type ContentLike = Omit<User, 'id'> & {
  user_id: string;
  details: string;
};

type ContentLikesMeta = {
  page: number;
  total: number;
};

const PAGE_SIZE = 50;

export const getContentLikesDataKey =
  (contentId: number) =>
  (
    page: number,
    prevData: {
      reactions: ContentLike[];
      meta: ContentLikesMeta;
    }
  ) => {
    if (prevData && prevData.meta.total <= prevData.meta.page) {
      return null;
    }
    //since we only have access to the key in the page fetcher, we either need to give the url for the api, or would need to parse the key to get the page number
    //electing to use our api url since that seems to be how the SWR docs want this to be used.
    const apiPath = `/contents/${contentId}/reactions/like?page=${page}`;
    return apiPath;
  };

// deprecatedUsage is specifically for old UI components that are deprecated but still technically supported. (feature flag not yet cleaned up)
// will make page limit be equal to page max in mojo, essentially no limit, allowing the deprecated UI components to behave the same way they did before.
// (the flag for the deprecated components is EE.ContentDetail.New, remove the param/usage when the flag is removed)
export function useContentLikes(contentId: number, deprecatedUsage?: boolean) {
  const pageSize = useMemo(
    () => (deprecatedUsage ? 1000 : PAGE_SIZE),
    [deprecatedUsage]
  );

  const { data, error, isLoading, size, setSize } = useSWRInfinite(
    getContentLikesDataKey(contentId),
    async (key) => {
      return (
        (await MojoApiFactory.v2.get(
          `${key}&page_size=${pageSize}`
        )) as AxiosResponse<{
          reactions: ContentLike[];
          meta: ContentLikesMeta;
        }>
      ).data;
    }
  );

  const fetchNextPage = () => setSize(() => size + 1);

  const canLoadMore = useMemo(() => {
    const lastPage = data && data[data.length - 1];
    const lastPageIsFull = lastPage?.reactions.length === pageSize;

    return lastPageIsFull;
  }, [data, pageSize]);

  //isLoading is always false for any page other than the first.
  //solution taken from https://swr.vercel.app/examples/infinite-loading, which is the example from the swr docs.
  const isLoadingMore = useMemo(
    () =>
      isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    [isLoading, size, data]
  );

  return {
    likes: data ? data.map((datum) => datum.reactions).flat() : [],
    error,
    isLoading,
    canLoadMore,
    isLoadingMore,
    fetchNextPage,
  };
}
