import { useTranslation } from 'react-i18next';
import { Dialog } from '../ui';
import TextInput from '../ui/text-input';
import PasswordFormContainer from './password-form-container';
import PasswordFormRules from './password-form-rules';
import './password-form-dialog.scss';

const PasswordFormDialog = ({ close }) => {
  const { t } = useTranslation();

  return (
    <PasswordFormContainer close={close}>
      {({
        values,
        passwordValidations,
        validationErrors,
        error,
        isValid,
        isSubmitting,
        onChange,
        onSubmit,
      }) => (
        <Dialog
          className="password-form"
          title={t('screens.profile.edit_profile.change_password')}
          accept={{
            icon: 'check',
            text: t('common.done'),
            disabled: !isValid || isSubmitting,
            loading: isSubmitting,
            onClick: onSubmit,
          }}
          cancel={{
            text: t('common.cancel'),
            onClick: close,
          }}
        >
          <>
            <div className="form">
              {error ? <div className="form-error">{error}</div> : null}

              <div className="form-fields">
                <div className="form-fields-group">
                  <TextInput
                    label={t('screens.profile.edit_profile.old_password')}
                    name="old_password"
                    value={values['old_password'] || ''}
                    type="password"
                    onChange={onChange}
                  />
                </div>

                <div className="form-fields-group">
                  <TextInput
                    label={t('screens.profile.edit_profile.new_password')}
                    name="password"
                    value={values['password'] || ''}
                    type="password"
                    onChange={onChange}
                  />
                </div>

                <div className="form-fields-group">
                  <TextInput
                    label={t('screens.profile.edit_profile.retype_password')}
                    name="confirm_password"
                    value={values['confirm_password'] || ''}
                    type="password"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <PasswordFormRules
              passwordValidations={passwordValidations}
              validationErrors={validationErrors}
            />
          </>
        </Dialog>
      )}
    </PasswordFormContainer>
  );
};
export const ID = 'Overlays.PASSWORD_FORM_DIALOG';

export default PasswordFormDialog;
