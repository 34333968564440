import { ComponentProps } from 'react';

/**
 * A component that visually hides its children from the screen, but keeps them accessible to screen readers.
 *
 * This component is useful for hiding content that is not visually relevant, but is still important for screen reader users.
 *
 * @example
 * ```tsx
 * <VisuallyHidden>Screen reader only text</VisuallyHidden>
 * ```
 */
export function VisuallyHidden(props: ComponentProps<'span'>) {
  return (
    <span
      style={{
        position: 'absolute',
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
      }}
      {...props}
    >
      {props.children}
    </span>
  );
}
