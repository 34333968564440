import cx from 'classnames';
import {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useFeatureFlag from '../../../common/use-feature-flag';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../common/use-skip-to-content';
import { getChannelById } from '../../../models/channels/selectors';
import { ChannelTabType } from '../../../models/channels/types';
import { Feature } from '../../../models/features/features';
import ChannelFollowButton from '../../channel/channel-follow-button';
import ChannelFollowButtonV2 from '../channel/channel-follow-button/channel-follow-button';
import { Link } from '../../ui';
import { TabButton, TabList, TabPanel } from '../../ui/tab';
import { TopicPagePosts } from './tabs/topic-page-posts/topic-page-posts';
import TopicPageStats from './preview-cards/topic-page-stats/topic-page-stats';
import './topic-page.scss';
import { TopicPageAbout } from './tabs/topic-page-about/topic-page-about';
import { TopicPageSidebarCard } from './topic-page-sidebar-card';
import { TopicPagePostsPreview } from './preview-cards/topic-page-posts-preview/topic-page-posts-preview';
import NotFound from '../../../screens/not-found';
import AboutPagePreview from './preview-cards/about-page-preview/about-page-preview';
import { useRecoilState } from 'recoil';
import { aboutPageByChannelId } from '../../../models/about-page/recoil-state';
import { TopicPageProps } from './types';

const TAB_COMPONENTS: Partial<
  Record<ChannelTabType, JSXElementConstructor<TopicPageTabComponent>>
> = {
  [ChannelTabType.About]: TopicPageAbout,
  [ChannelTabType.Posts]: TopicPagePosts,
};
function isSupportedTab(
  tabType: ChannelTabType
): tabType is keyof typeof TAB_COMPONENTS {
  return tabType in TAB_COMPONENTS;
}

const TopicPage = ({ analyticsData }: TopicPageProps) => {
  const history = useHistory();
  const { channelId, tabType: activeTabType } = useParams<{
    channelId: string;
    tabType?: ChannelTabType;
  }>();
  const { t } = useTranslation();
  const topic = usePatronSelector((state) => getChannelById(state, channelId));
  const [aboutPage] = useRecoilState(aboutPageByChannelId(channelId));
  const landingPageTabs =
    topic?.landing_page_tabs?.filter((t) => isSupportedTab(t.tab_type)) || [];
  const firstTabType = landingPageTabs[0]?.tab_type;

  const onChangeTab = useCallback(
    (tabType: ChannelTabType) => {
      history.replace(`/channels/${channelId}/${tabType}`);
    },
    [channelId, history]
  );

  useEffect(() => {
    if (activeTabType !== undefined) {
      if (
        !topic?.landing_page_tabs.map((e) => e.tab_type).includes(activeTabType)
      ) {
        // clear tab type path parameter if it's not defined in the tab list
        history.replace(`/channels/${channelId}`);
      }
      return;
    }

    if (firstTabType) {
      onChangeTab(firstTabType);
    }
  }, [
    activeTabType,
    channelId,
    firstTabType,
    history,
    onChangeTab,
    topic?.landing_page_tabs,
  ]);

  const shouldDisplaySidebar = activeTabType === firstTabType;

  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);

  const FollowButton = newUIEnabled
    ? ChannelFollowButtonV2
    : ChannelFollowButton;

  if (!topic) {
    return <NotFound />;
  }

  const renderTabPreviews = (): ReactElement | null => {
    if (!activeTabType) return null;

    return (
      <>
        {activeTabType !== ChannelTabType.Posts && (
          <TopicPageSidebarCard
            title={t('screens.topic_page.sidebar.posts_preview.title')}
            action={
              <Link
                href={`/channels/${channelId}/${ChannelTabType.Posts}`}
                action="push"
              >
                {t('screens.topic_page.sidebar.posts_preview.view_all')}
              </Link>
            }
          >
            <TopicPagePostsPreview channelId={channelId} count={3} />
          </TopicPageSidebarCard>
        )}
        {activeTabType !== ChannelTabType.About && aboutPage?.previewContent && (
          <TopicPageSidebarCard
            title={t('screens.topic_page.sidebar.about_preview.title')}
            action={
              <Link
                href={`/channels/${channelId}/${ChannelTabType.About}`}
                action="push"
              >
                {t('screens.topic_page.sidebar.posts_preview.view_all')}
              </Link>
            }
          >
            <AboutPagePreview channelId={channelId} />
          </TopicPageSidebarCard>
        )}
      </>
    );
  };

  return (
    topic && (
      <div className={'topic-page'}>
        <div className={'topic-page__container'}>
          <header className={'topic-page-header'}>
            {topic?.background_image_url && (
              <div className={'topic-page-header__image-row'}>
                <img
                  className={'topic-image'}
                  src={topic?.background_image_url}
                  alt={t('screens.topic_page.thumbnail_alt')}
                />
              </div>
            )}
            <div className={'topic-page-header__title-row'}>
              <h1 className={'topic-title'}>{topic?.name}</h1>
              <div className={'topic-page-header__buttons'}>
                <FollowButton
                  channelId={channelId}
                  analyticsData={analyticsData}
                />
              </div>
            </div>
            <div className={'topic-page-header__description-row'}>
              {topic?.description && (
                <p className={'topic-description'}>{topic.description}</p>
              )}
            </div>
            {landingPageTabs.length > 1 && (
              <TabList className="topic-page-header__tab-list" hideBorder>
                {landingPageTabs.map((tab) => (
                  <TabButton
                    key={`tab-${tab.tab_type}`}
                    id={`tab-${tab.tab_type}`}
                    aria-controls={`panel-${tab.tab_type}`}
                    active={tab.tab_type === activeTabType}
                    onClick={() => onChangeTab(tab.tab_type)}
                  >
                    {tab.title}
                  </TabButton>
                ))}
              </TabList>
            )}
          </header>
          <section className={'topic-page-body__container'}>
            <div
              className={cx('topic-page-body__container-main', {
                'full-width': !shouldDisplaySidebar,
              })}
              id={DEFAULT_SKIP_TO_CONTENT_ID}
            >
              {landingPageTabs.map(({ tab_type }) => {
                const TabComponent = TAB_COMPONENTS[tab_type];
                if (!TabComponent) return null;
                const isHidden = tab_type !== activeTabType;

                return (
                  <TabPanel
                    key={`panel-${tab_type}`}
                    id={`panel-${tab_type}`}
                    aria-labelledby={`tab-${tab_type}`}
                    hidden={isHidden}
                  >
                    <TabComponent
                      channelId={channelId}
                      analyticsData={analyticsData}
                      isHidden={isHidden}
                    />
                  </TabPanel>
                );
              })}
              {/* Fallback content when there are no tabs defined */}
              {!landingPageTabs.length && (
                <TopicPagePosts
                  channelId={channelId}
                  analyticsData={analyticsData}
                  isHidden={false}
                />
              )}
            </div>
            <div
              className={'topic-page-body__container-sidebar'}
              hidden={!shouldDisplaySidebar}
            >
              {renderTabPreviews()}

              <TopicPageSidebarCard
                title={t('screens.topic_page.sidebar.topic_statistics.title')}
              >
                <TopicPageStats
                  channelId={channelId}
                  createdAt={topic.created_at}
                />
              </TopicPageSidebarCard>
            </div>
          </section>
        </div>
      </div>
    )
  );
};
export default TopicPage;
