import { HTMLProps, KeyboardEventHandler } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';

import {
  submissionVideo,
  submissionVideoUrl,
} from '../../../models/content-submission/atoms';

import { VideoPostIcon } from '../../../components/ui';
import MediaUploader from '../media-uploader';

import {
  trackAddVideoAddVideoClick,
  trackAddVideoAddVideoError,
} from '../../../models/content-submission/analytics';

type VideoInputProps = {
  inputProps?: HTMLProps<HTMLInputElement>;
  openFileBrowser: () => void;
  errorMessage?: string;
};

const VideoInput = ({
  inputProps,
  openFileBrowser,
  errorMessage,
}: VideoInputProps) => {
  const setVideoUrl = useSetRecoilState(submissionVideoUrl);
  const video = useRecoilValue(submissionVideo);

  const { t } = useTranslation();

  const handleClick = () => {
    openFileBrowser();
    trackAddVideoAddVideoClick();
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') handleClick();
  };

  const handleUpload = (url: string) => {
    setVideoUrl(url);
  };

  const handleError = () => {
    trackAddVideoAddVideoError();
  };

  const dropClassName = cx('content-submission-input-video__dropzone', {
    'content-submission-input-video__dropzone--error': !!errorMessage,
  });

  return (
    <div className="content-submission-input-video">
      {inputProps ? <input {...inputProps} /> : null}

      {!video ? (
        <button
          type="button"
          className={dropClassName}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          aria-labelledby="content-submission-input-video--title"
        >
          {!errorMessage ? (
            <>
              <VideoPostIcon />

              <div id="content-submission-input-video--title" className="title">
                {t('content_submission.upload_video')}
              </div>

              <div className="text">
                <Trans i18nKey="content_submission.drag_and_drop_video">
                  Drag &amp; drop to upload video or{' '}
                  <span className="underline">upload file</span>
                </Trans>
              </div>
            </>
          ) : (
            <div className="text">{errorMessage}</div>
          )}
        </button>
      ) : (
        <MediaUploader
          file={video}
          onComplete={handleUpload}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default VideoInput;
