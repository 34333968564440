import React, { useRef, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Icon } from '../../ui';
import { ToastMessageType } from './toaster';

export const ToastMessage: React.FC<{
  message: ToastMessageType;
  dismiss: ({ text }: Pick<ToastMessageType, 'text'>) => void;
}> = ({ message, dismiss }) => {
  const [cssShowing, setCssShowing] = useState(false);
  const [hideTimeoutId, setHideTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [remainingTime, setRemainingTime] = useState<number>(
    message.timeout || 5000
  );
  const startTime = useRef<number>(Date.now());
  const isHovering = useRef<boolean>(false);

  const dismissMessage = useCallback(() => {
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
    }
    setCssShowing(false);

    setTimeout(() => {
      dismiss({ text: message.text });
    }, 300);
  }, [dismiss, hideTimeoutId, message.text]);

  const boundDismissRef = useRef(dismissMessage);
  boundDismissRef.current = dismissMessage;

  useEffect(() => {
    setCssShowing(true);
  }, [setCssShowing]);

  useEffect(() => {
    if (message.timeout === false) return;
    if (isHovering.current) return;

    const timeoutId = setTimeout(
      () => boundDismissRef.current(),
      remainingTime
    );
    setHideTimeoutId(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [boundDismissRef, message.timeout, remainingTime]);

  const handleMouseEnter = () => {
    isHovering.current = true;
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
      setRemainingTime(remainingTime - (Date.now() - startTime.current));
    }
  };

  const handleMouseLeave = () => {
    startTime.current = Date.now();
    const timeoutId = setTimeout(
      () => boundDismissRef.current(),
      remainingTime
    );
    setHideTimeoutId(timeoutId);
  };

  return (
    <CSSTransition
      in={cssShowing}
      timeout={300}
      classNames={{
        enter: 'slidingEnter',
        enterActive: 'slidingEnterActive',
        enterDone: 'slidingEnterDone',
        exit: 'slidingExit',
        exitActive: 'slidingExitActive',
        exitDone: 'slidingExitDone',
      }}
      unmountOnExit
    >
      <div
        id="toast-message-wrapper"
        className={cx('messageWrapper', message.type)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          {message.type === 'success' && (
            <Icon type="check_circle" aria-hidden="true" />
          )}
          {message.type === 'error' && <Icon type="error" aria-hidden="true" />}
        </div>
        <div className="text">
          <div className="message">{message.text}</div>
          {message.details && <div className="details">{message.details}</div>}
        </div>
        <div className="buttonContainer">
          <button type="button" onClick={dismissMessage} aria-label="Close">
            <Icon type="close" size="sm" />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
