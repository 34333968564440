import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTopicShortcutsFetcher } from '../../../../../common/use-topic-shortcuts-fetcher';
import { Spinner } from '../../../../ui';
import { CardProps } from '../../sidebar';
import { TopicPageShortcut } from '../../tabs/ShortcutsTab/shortcut';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import styles from './styles.module.css';

const PREVIEW_SHORTCUTS_COUNT = 6;

const ShortcutsPreview: FC<CardProps> = ({ topicId }: { topicId: string }) => {
  const { t } = useTranslation();
  const { shortcuts, isFetching } = useTopicShortcutsFetcher(topicId);

  const hasShortcuts = !!shortcuts && shortcuts.length > 0;

  return (
    <PreviewCard
      title={t('screens.topic_page.sidebar.shortcuts_preview.title')}
      link={
        <CardLink
          label={t('screens.topic_page.sidebar.shortcuts_preview.view_all')}
          to={`/channels/${topicId}/shortcuts`}
        />
      }
    >
      {isFetching && <Spinner />}
      {!isFetching && hasShortcuts ? (
        <div className={styles.ShortcutsGrid}>
          {shortcuts?.slice(0, PREVIEW_SHORTCUTS_COUNT).map((shortcut) => (
            <TopicPageShortcut shortcut={shortcut} key={shortcut.id} />
          ))}
        </div>
      ) : (
        <CardPlaceholder>
          {t('screens.topics.shortcuts.empty.description')}
        </CardPlaceholder>
      )}
    </PreviewCard>
  );
};
export default ShortcutsPreview;
