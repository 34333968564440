import cx from 'classnames';
import { ComponentProps, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

type TabListProps = Omit<ComponentProps<'div'>, 'role' | 'ref'> & {
  narrow?: boolean;
  hideBorder?: boolean;
};

export function TabList({
  className,
  narrow,
  hideBorder,
  ...props
}: TabListProps) {
  const tabListRef = useRef<HTMLDivElement>(null);

  // Adapted from https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#example
  useEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      const tabs = Array.from(
        tabListRef.current?.querySelectorAll('[role="tab"]') ?? []
      );
      if (tabs.length === 0) return;

      const activeTabIndex = tabs.findIndex(
        (e) => e.getAttribute('tabindex') === '0'
      );

      if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        tabs[activeTabIndex].setAttribute('tabindex', '-1');

        let newTabIndex = activeTabIndex + (e.key === 'ArrowRight' ? 1 : -1);

        // If we're at the end, go to the start
        if (newTabIndex >= tabs.length) {
          newTabIndex = 0;
        }

        // If we're at the start, move to the end
        if (newTabIndex < 0) {
          newTabIndex = tabs.length - 1;
        }

        const focusingTab = tabs[newTabIndex];

        focusingTab.setAttribute('tabindex', '0');
        if (focusingTab instanceof HTMLElement) {
          focusingTab.focus();
        }
      }
    },
    tabListRef
  );

  return (
    <div
      {...props}
      ref={tabListRef}
      className={cx('tab-list', className, {
        'tab-list--narrow': narrow,
        'tab-list--hide-border': hideBorder,
      })}
      role="tablist"
    />
  );
}

type TabButtonProps = Omit<
  ComponentProps<'button'>,
  'role' | 'aria-selected' | 'tabIndex'
> & {
  active?: boolean;
  compact?: boolean;
};

export function TabButton({
  active,
  compact,
  className,
  ...props
}: TabButtonProps) {
  return (
    <div
      className={cx('tab-button--container', {
        'tab-button--container-active': active,
      })}
    >
      <button
        {...props}
        role="tab"
        aria-selected={Boolean(active)}
        tabIndex={active ? 0 : -1}
        className={cx('tab-button', className, {
          'tab-button-active': active,
          'tab-button--compact': compact,
        })}
      />
    </div>
  );
}

type TabPanelProps = Omit<ComponentProps<'div'>, 'role'>;

export function TabPanel({ className, ...props }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      tabIndex={0}
      className={cx('tab-panel', className)}
      {...props}
    />
  );
}
