import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button, Icon } from '../../ui';

import Tooltip from '../../tooltip/tooltip';

interface IContentActionTranslate {
  isTranslated: boolean;
  handleClick?: MouseEventHandler;
  contentTitle: string;
  showText?: boolean;
  wideMode?: boolean;
}

const ContentActionTranslate: React.FC<IContentActionTranslate> = ({
  isTranslated,
  handleClick,
  contentTitle,
  showText = false,
  wideMode = false,
}) => {
  const className = cx('content__action', 'content__action--translate', {
    'content__action--translated': isTranslated,
    'content__action--active': isTranslated,
  });

  const { t } = useTranslation(undefined, { useSuspense: false });
  const translation_key = isTranslated
    ? 'content.see_original'
    : 'content.see_translation';

  const displayText = showText || wideMode;

  const actionLabel = t(translation_key);

  return (
    <div className={className}>
      {displayText ? (
        <Button
          onClick={handleClick}
          aria-label={actionLabel}
          theme={'text'}
          size={'compact'}
        >
          <div className="icon-animation-container">
            <Icon type="translate" />
            <Icon type="translate" className="translate filled" />
          </div>
          <span>{t(translation_key)}</span>
        </Button>
      ) : (
        <Tooltip aria-hidden text={t(translation_key)}>
          <Button
            onClick={handleClick}
            aria-label={actionLabel}
            theme={'icon-only'}
            size={'32'}
          >
            <div className="icon-animation-container">
              <Icon type="translate" />
              <Icon type="translate" className="translate filled" />
            </div>
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export { ContentActionTranslate };
