import { AxiosResponse } from 'axios';
import {
  ChannelContributors,
  ChannelFollowers,
  ChannelFollowersResponse,
  ChannelStat,
  ChannelStatsResponse,
  ChannelTopContributorsResponse,
} from '../../models/channels/types';
import ChannelService from '../../services/channel';
import { useEffect, useState } from 'react';

const useChannelDetails = (channelId: string) => {
  const [fetching, setFetching] = useState(true);

  const [channelStats, setChannelStats] = useState<ChannelStat>();
  const [channelContributors, setChannelContributors] =
    useState<ChannelContributors>([]);
  const [channelFollowers, setChannelFollowers] = useState<ChannelFollowers>(
    []
  );

  useEffect(() => {
    const channelService = new ChannelService(channelId);

    const fetchChannelStats = () => {
      setFetching(true);

      Promise.all([
        channelService.fetchStats(),
        channelService.fetchContributors(),
        channelService.fetchFollowers(),
      ])
        .then(fetchChannelDetailsSuccess)
        .catch(fetchChannelDetailsFailure)
        .finally(() => {
          setFetching(false);
        });
    };

    const fetchChannelDetailsSuccess = ([stats, contributors, followers]: [
      AxiosResponse<ChannelStatsResponse>,
      AxiosResponse<ChannelTopContributorsResponse>,
      AxiosResponse<ChannelFollowersResponse>
    ]) => {
      const {
        last_updated: lastUpdated,
        posts_count: postsCount,
        posts_per_week: postsPerWeek,
        follower_count: followerCount,
        contributor_count: contributorCount,
      } = stats.data.stats;

      setChannelStats({
        postsCount,
        postsPerWeek,
        lastUpdated,
        followerCount,
        contributorCount,
      });

      setChannelContributors(
        contributors.data.top_contributors.filter((c) => c.display_name)
      );

      setChannelFollowers(followers.data.followers.data);
    };

    const fetchChannelDetailsFailure = (err: unknown) => {
      console.error(err, 'Error fetching channel details');
    };

    fetchChannelStats();
  }, [channelId]);

  return {
    fetching,
    channelContributors,
    channelFollowers,
    channelStats,
  };
};

export default useChannelDetails;
