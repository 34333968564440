import cx from 'classnames';
import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Modifier } from 'react-popper';

import ContentCardToolbarActions from './content-card-toolbar-actions';
import PopperWrapper from '../../../../common/popper-wrapper';
import { Button } from '../../../ui';
import Tooltip from '../../../tooltip/tooltip';
import { Content } from '../../../../models/content/types';

interface ContentCardToolbarProps {
  content: Content;
  wideMode?: boolean;
  max?: number;
  analyticsData: IAnalyticsData;
}

export const MoreActionsModalContext = createContext<{
  setModalVisibility: (isVisible: boolean) => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalVisibility: () => {},
});

const observePopperChildrenResizeModifier: Modifier<'observePopperChildrenResize'> =
  {
    name: 'observePopperChildrenResize',
    enabled: true,
    phase: 'main',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fn: () => {},
    effect: ({ state, instance }) => {
      const RO_PROP = '__popperjsRO__';
      const { children } = state.elements.popper;

      type ElementWithResizeObserver = Element & {
        [key in typeof RO_PROP]?: ResizeObserver;
      };

      Array.from(children).forEach((el: ElementWithResizeObserver) => {
        el[RO_PROP] = new ResizeObserver(async () => {
          await instance.update();
        });

        el[RO_PROP]?.observe(el);
      });

      return () => {
        Array.from(children).forEach((el: ElementWithResizeObserver) => {
          el[RO_PROP]?.disconnect();
          delete el[RO_PROP];
        });
      };
    },
  };

const ContentCardToolbar: React.FC<ContentCardToolbarProps> = ({
  content,
  max = 0,
  wideMode = false,
  analyticsData,
}) => {
  const showMax = max > 0 ? max : 0;
  const [isShowingMenu, setIsShowingMenu] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );

  const popoverClasses = cx('dropdown__popover content-card__toolbar-popover', {
    hidden: !isShowingMenu,
  });

  const buttonClasses = cx('overflow-button', {
    open: isShowingMenu,
  });

  const { t } = useTranslation(undefined, { useSuspense: false });

  const buildIcon = ({
    action,
    showText,
    analyticsData,
  }: {
    action: string;
    showText: boolean;
    analyticsData: IAnalyticsData;
  }) => {
    const ActionComponent = ContentCardToolbarActions[action]?.element;
    return (
      <ActionComponent
        contentId={content.id}
        analyticsData={analyticsData}
        key={action}
        showText={showText}
        wideMode={wideMode}
      />
    );
  };

  const buildIcons = () => {
    const visibleActions: string[] = [];
    const menuActions: string[] = [];
    let index = 1;

    for (const action in ContentCardToolbarActions) {
      if (!ContentCardToolbarActions[action]?.canShow(content)) {
        continue;
      }

      if (index > showMax) {
        menuActions.push(action);
      } else {
        visibleActions.push(action);
      }
      index++;
    }

    //if there is only 1 icon in the menu, then instead of showing the menu button, show the icon itself
    if (menuActions.length === 1) {
      const action = menuActions.pop();
      action && visibleActions.push(action);
    }

    return {
      visibleIcons: visibleActions.map((action) =>
        buildIcon({ action, analyticsData, showText: false })
      ),
      menuIcons: menuActions.map((action) =>
        buildIcon({ action, analyticsData, showText: true })
      ),
    };
  };

  const handleClick = () => {
    setIsShowingMenu((v) => !v);
  };

  const handleClose = () => {
    setIsShowingMenu(false);
  };

  const buttonLabel = t('content.more');
  const icons = buildIcons();

  return (
    <MoreActionsModalContext.Provider
      value={{
        setModalVisibility: (isVisible: boolean) => {
          setIsShowingMenu(isVisible);
        },
      }}
    >
      <div role="toolbar" className={'content-card__toolbar'}>
        {icons.visibleIcons}
        {icons.menuIcons.length > 0 ? (
          <div>
            <Tooltip aria-hidden text={buttonLabel}>
              <Button
                aria-label={buttonLabel}
                className={buttonClasses}
                ref={setReferenceElement}
                onClick={handleClick}
                icon={'more_vert'}
              />
            </Tooltip>

            <PopperWrapper
              buttonRef={referenceElement}
              className={popoverClasses}
              isOpen={isShowingMenu}
              closeCallback={handleClose}
              options={{
                modifiers: [observePopperChildrenResizeModifier],
              }}
            >
              {icons.menuIcons}
            </PopperWrapper>
          </div>
        ) : null}
      </div>
    </MoreActionsModalContext.Provider>
  );
};

export default ContentCardToolbar;
