import { useTranslation } from 'react-i18next';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { contentSelectors } from '../../../../models/content';
import { trackContentCardCopyLink } from '../../../../models/content/analytics';
import Tooltip from '../../../tooltip/tooltip';
import { Icon } from '../../../ui';
import Button from '../../../ui/button';
import { usePushToastOrFlashMessage } from '../../toaster/deprecation-helper';
import { uiOperations } from '../../../../models/ui';
import { ID as CopyLinkModalID } from '../copy-link-modal/copy-link-modal';
import { useDispatch } from 'react-redux';

type ContentActionCopyLinkProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  wideMode?: boolean;
};

export function ContentActionCopyLink({
  contentId,
  analyticsData,
  wideMode,
}: ContentActionCopyLinkProps) {
  const dispatch = useDispatch();
  const { pushMessage } = usePushToastOrFlashMessage();
  const { t } = useTranslation();

  const permalinkUrl = usePatronSelector((state) =>
    contentSelectors.getContentPermalinkUrl(state, { contentId })
  );

  const openCopyLinkModal = (contentURL: any) =>
    dispatch(
      uiOperations.displayOverlay(CopyLinkModalID, {
        contentURL,
      })
    );

  const onClick = () => {
    trackContentCardCopyLink(contentId, analyticsData);

    navigator.clipboard
      .writeText(permalinkUrl)
      .then(() => {
        pushMessage({
          text: t('content.copy_link_success'),
          type: 'success',
        });
      })
      .catch((e) => {
        openCopyLinkModal(permalinkUrl);
      });
  };

  const label = t('content.copy_link');

  if (wideMode) {
    return (
      <Button
        onClick={onClick}
        aria-label={label}
        theme="text"
        size="compact"
        icon="content_copy"
      >
        <span>{label}</span>
      </Button>
    );
  }

  return (
    <Tooltip aria-hidden text={label}>
      <Button onClick={onClick} aria-label={label} theme="icon-only" size="32">
        <Icon type="link" />
      </Button>
    </Tooltip>
  );
}
