import { t } from 'i18next';
import {
  DropdownFilter,
  DropdownFilterContent,
  DropdownFilterItem,
  DropdownFilterTrigger,
} from '../primitives/dropdown-filter';
import { FilterBarButton } from '../filter-bar';
import styles from '../filter-bar.module.scss';
import * as Checkbox from '../primitives/checkbox-filter';
import { useSearchQueryParams } from '../../query-params';
import { Icon } from '@socialchorus/shared-ui-components';
import { DateRangeableFilter } from './daterangeable';
import { SearchableFilter } from './searchable';

import { useState } from 'react';

/**
 * Filter is a union type of all the filter types that can be used in the filter bar.
 */
export type Filter =
  | SelectableFilter
  | SearchableFilter
  | ListableFilter
  | DateRangeableFilter;

/**
 * OptionBehaviour is a union type of all the possible behaviours that a filter's options can have.
 * It is used to define how the options of a filter should be rendered and interacted with.
 */
type OptionBehaviour =
  | 'listable'
  | 'searchable'
  | 'selectable'
  | 'daterangeable';

export type StaticFilterOption = {
  id: string | number;
  name: string;
};

/**
 * SearchFilter is a helper that builds the base type for all the filter types.
 */
export type SearchFilter<T> = {
  id: string;
  behaviour: OptionBehaviour;
  label: string;
} & T;

/**
 * SelectableFilter represents a filter that has a list of options to choose from where multiple options can be selected at a time.
 * The options are static and are defined in the options property.
 **/
export type SelectableFilter = SearchFilter<{
  id: string;
  behaviour: 'selectable';
  parameter: string;
  options: StaticFilterOption[];
}>;

type SelectableFilterProps = {
  filter: SelectableFilter;
  appliedFilters: string[];
  applyFilter: (values: string[]) => void;
};

/**
 * SelectableFilter is a component that renders a SelectableFilter item.
 * It is used to render a filter that has a list of options to choose from where multiple options can be selected at a time.
 **/
export function SelectableFilter({
  filter,
  applyFilter,
  appliedFilters,
}: SelectableFilterProps) {
  const [open, setOpen] = useState(false);

  const handleOptionSelect = (parameter: string, value: string) => {
    if (appliedFilters.includes(value)) {
      applyFilter(appliedFilters.filter((o) => o !== value));
    } else {
      applyFilter([...appliedFilters, value]);
    }
  };

  return (
    <Checkbox.CheckboxFilter
      key={filter.parameter}
      open={open}
      onOpenChange={(openChange) => setOpen(openChange)}
    >
      <div className={styles.FilterBar__filterButtonWrapper}>
        <Checkbox.CheckboxFilterTrigger asChild>
          <FilterBarButton
            label={filter.label}
            appliedCount={appliedFilters.length}
          />
        </Checkbox.CheckboxFilterTrigger>
      </div>
      <Checkbox.CheckboxFilterContent
        handleClear={() => {
          applyFilter([]);
        }}
      >
        {filter.options.map(({ id, name }) => (
          <Checkbox.CheckboxFilterItem
            key={id}
            label={name}
            value={id}
            checked={appliedFilters.includes(String(id))}
            onCheckedChange={() =>
              handleOptionSelect(filter.parameter, String(id))
            }
          />
        ))}
      </Checkbox.CheckboxFilterContent>
    </Checkbox.CheckboxFilter>
  );
}

/**
 * ListableFilter represents a filter that has a list of options to choose from where only one option can be selected at a time.
 * The options are static and are defined in the options property.
 */
export type ListableFilter = SearchFilter<{
  behaviour: 'listable';
  parameter: string;
  options: StaticFilterOption[];
}>;

type ListableFilterProps = {
  filter: ListableFilter;
  appliedFilters: string[];
  handleSelect: (parameter: string, value: string) => void;
};

/**
 * ListableFilter is a component that renders a ListableFilter item.
 * It is used to render a filter that has a list of options to choose from where only one option can be selected at a time.
 */
export function ListableFilter({
  filter,
  appliedFilters,
  handleSelect,
}: ListableFilterProps) {
  const appliedFilterValue = appliedFilters[0];
  const appliedFiltersCount = appliedFilters.length;
  const hideAppliedCount = filter.parameter === 'sort';
  const filterBarLabel =
    filter.parameter === 'sort'
      ? filter.options.find(({ id }) => {
          return appliedFilterValue === id;
        })?.name || filter.label
      : filter.label;

  return (
    <DropdownFilter key={filter.parameter}>
      <div className={styles.FilterBar__filterButtonWrapper}>
        <DropdownFilterTrigger asChild>
          <FilterBarButton
            label={filterBarLabel}
            appliedCount={appliedFiltersCount}
            hideAppliedCount={hideAppliedCount}
          />
        </DropdownFilterTrigger>
      </div>
      <DropdownFilterContent>
        {filter.options.map(({ id, name }) => {
          const isApplied = appliedFilterValue === id;
          return (
            <DropdownFilterItem
              key={id}
              onSelect={() => handleSelect(filter.parameter, String(id))}
            >
              <div className={`${styles.FilterBar__dropdownFilterItemInner}`}>
                <span>{name}</span>
                <span>{isApplied && <Icon size={16}>check</Icon>}</span>
              </div>
            </DropdownFilterItem>
          );
        })}
      </DropdownFilterContent>
    </DropdownFilter>
  );
}
