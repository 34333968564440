import React, { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import {
  Button,
  DropdownMenuCheckboxItem,
  Container,
  Icon,
} from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { useSearchRoute } from '../search-screen';
import { useLocalizedSearchType } from '../locale';
import styles from './filter-bar.module.scss';
import {
  DropdownFilterContent,
  DropdownFilter,
  DropdownFilterTrigger,
} from './primitives/dropdown-filter';
import { useSearchQueryParams } from '../query-params';
import { Filter, ListableFilter, SelectableFilter } from './filters/filters';
import { DateRangeFilter } from './filters/daterangeable';
import { VisuallyHidden } from '../../visually-hidden';
import { SearchableFilter } from './filters/searchable';
import {
  trackSearchSortClick,
  trackSearchFilterClick,
  trackSearchClearFilterClick,
} from '../../../../models/analytics';
import { LoadingSkeleton } from '../../loading-skeleton';
import { useFilters } from '../filter-context';

function useSelectedFilters() {
  const [query, setQuery] = useSearchQueryParams({
    disabled: false, // or provide a condition if needed
  });
  const { filters: allFilters, defaultFilters } = useFilters();

  const matchingFilters = allFilters.filter((filter) => {
    if (filter.behaviour === 'daterangeable') {
      return filter.parameters.every((param) => query[param] !== undefined);
    }
    return query[filter.parameter] !== undefined;
  });

  const defaultSelectedFilters = allFilters.filter((filter) =>
    defaultFilters?.includes(filter.id)
  );
  const [selectedFilters, setSelectedFilters] = React.useState<Filter[]>([]);

  useEffect(() => {
    if (
      selectedFilters.length > 0 &&
      defaultSelectedFilters.every((el) => selectedFilters.includes(el))
    ) {
      return;
    }
    const combinedFilters = Array.from(
      new Set([...matchingFilters, ...defaultSelectedFilters])
    );
    setSelectedFilters(combinedFilters);
    // We need to use JSON.stringify to avoid infinite re-renders https://stackoverflow.com/a/59468261
  }, [JSON.stringify(defaultSelectedFilters), JSON.stringify(matchingFilters)]);

  return [selectedFilters, setSelectedFilters] as const;
}

const isSort = (filter: Filter) =>
  filter.behaviour === 'listable' && filter.parameter === 'sort';

export function FilterBar() {
  const { searchType } = useSearchRoute();
  const localizedSearchType = useLocalizedSearchType(searchType);
  const [query, setQuery] = useSearchQueryParams({
    disabled: false, // or provide a condition if needed
  });
  const { filters, defaultFilters } = useFilters();

  const [selectedFilters, setSelectedFilters] = useSelectedFilters();

  const handleToggleFilter = (filter: Filter) => {
    // If the filter is already selected, remove it
    if (selectedFilters.includes(filter)) {
      if (filter.behaviour === 'daterangeable') {
        removeFilter(filter.parameters[0]);
        removeFilter(filter.parameters[1]);
      } else {
        removeFilter(filter.parameter);
      }
    } else {
      setSelectedFilters((prev) => [...prev, filter]);
    }
  };

  // Visible filters will always include sort
  const visibleFilters = filters.filter((filter) => {
    const isSelected = selectedFilters.includes(filter);

    const isSortFilterForOverviewOrPosts =
      (searchType === 'overview' || searchType === 'posts') && isSort(filter);

    return isSelected || isSortFilterForOverviewOrPosts;
  });

  // Selectable filters will not include sort
  const selectableFilters = filters.filter((filter) => {
    return !isSort(filter);
  });

  function applyQueryParam(param: string, value: string | string[]) {
    trackQueryParam(param, value);
    if (Array.isArray(value) && value.length === 0) {
      return setQuery({ [param]: undefined });
    }
    return setQuery({ [param]: value }, 'replaceIn');
  }

  function handleRemoveQueryParam(param: string) {
    setQuery({ [param]: undefined });
  }

  function removeFilter(filterParameter: string) {
    // re-add the removed filter to the unselected filters list
    const filter = selectedFilters.find((selectedFilter) => {
      if (selectedFilter.behaviour === 'daterangeable') {
        return selectedFilter.parameters.includes(filterParameter);
      }
      return selectedFilter.parameter === filterParameter;
    });

    if (filter) {
      setSelectedFilters((prev) => prev.filter((f) => f !== filter));
    }

    handleRemoveQueryParam(filterParameter);
  }

  function handleClearFilters() {
    trackSearchClearFilterClick({
      result_type: selectedFilters.map((filter) => filter.label),
    });
    setSelectedFilters([]);

    setQuery((prev) => {
      return {
        query: prev.query,
      };
    }, 'replace');
  }

  function trackQueryParam(param: string, value: string | string[]) {
    if (param === 'sort') {
      return trackSearchSortClick({ sort_type: value });
    }
    return trackSearchFilterClick({ result_type: value, filter_type: param });
  }

  const showAllFilters =
    selectableFilters.length > 0 &&
    selectableFilters.length > defaultFilters.length;

  const appliedFilters = (parameter: string) => {
    const parameterValues = query[parameter as keyof typeof query];
    const appliedFilters = [];

    if (
      parameterValues &&
      Array.isArray(parameterValues) &&
      parameterValues.length > 0
    ) {
      for (const value of parameterValues) {
        if (!value) {
          continue;
        }
        appliedFilters.push(value);
      }
    } else if (parameterValues) {
      appliedFilters.push(parameterValues);
    }

    return appliedFilters;
  };

  return (
    <Container shape="medium" shadow="light" className={styles.FilterBar}>
      <>
        <div className={styles.FilterBar__results}>
          <Heading>{localizedSearchType}</Heading>
          {showAllFilters && (
            <AllFiltersDropdown handleClearFilters={handleClearFilters}>
              <div className={styles.FilterBar__allFiltersDropdownList}>
                {selectableFilters.map((filter) => (
                  <DropdownMenuCheckboxItem
                    key={filter.label}
                    label={filter.label}
                    checked={selectedFilters.includes(filter)}
                    onCheckedChange={() => {
                      handleToggleFilter(filter);
                    }}
                  />
                ))}
              </div>
            </AllFiltersDropdown>
          )}
        </div>
        <div className={styles.FilterBar__filters}>
          {visibleFilters.map((filter) => {
            switch (filter.behaviour) {
              case 'selectable':
                return (
                  <SelectableFilter
                    key={filter.parameter}
                    filter={filter}
                    appliedFilters={appliedFilters(filter.parameter)}
                    applyFilter={(values) => {
                      applyQueryParam(filter.parameter, values);
                    }}
                  />
                );
              case 'listable':
                return (
                  <ListableFilter
                    appliedFilters={appliedFilters(filter.parameter)}
                    key={filter.parameter}
                    filter={filter}
                    handleSelect={(parameter, value) => {
                      applyQueryParam(parameter, value);
                    }}
                  />
                );
              case 'searchable':
                return (
                  <SearchableFilter
                    key={filter.parameter}
                    appliedFilters={appliedFilters(filter.parameter)}
                    filter={filter}
                    applyFilter={(values) => {
                      applyQueryParam(filter.parameter, values);
                    }}
                  />
                );
              case 'daterangeable':
                return (
                  <DateRangeFilter
                    key={filter.parameters.join('')}
                    filter={filter}
                    handleSelect={applyQueryParam}
                  />
                );
              default:
                exhaustiveFilterCheck(filter);
            }
          })}
        </div>
      </>
    </Container>
  );
}

function exhaustiveFilterCheck(filter: never) {
  throw new Error(`Unknown filter option behaviour: ${filter}`);
}

function AllFiltersDropdown({
  children,
  handleClearFilters,
}: {
  children: React.ReactNode;
  handleClearFilters: () => void;
}) {
  const { t } = useTranslation();
  const label = t('search.all_filters');
  const [open, setOpen] = React.useState(false);

  return (
    <DropdownFilter open={open} onOpenChange={setOpen}>
      <DropdownFilterTrigger asChild>
        <Button
          variant="text"
          size="compact"
          label={label}
          className={styles.FilterBar__filtersListTrigger}
          leftIcon={
            <Icon size={20} aria-hidden>
              filter_list
            </Icon>
          }
        />
      </DropdownFilterTrigger>
      <DropdownFilterContent>
        {children}
        <footer className={styles.CheckboxFilter__footer}>
          <div className={styles.CheckboxFilter__footerButtons}>
            <Button
              variant="text"
              label={t('common.clear')}
              onClick={handleClearFilters}
            />
          </div>
        </footer>
      </DropdownFilterContent>
    </DropdownFilter>
  );
}

function Heading({ children }: { children: React.ReactNode }) {
  return <span className={styles.FilterBar__heading}>{children}</span>;
}

type BadgeProps = {
  children: React.ReactNode;
} & ComponentPropsWithoutRef<'div'>;

function Badge({ children, ...badgeProps }: BadgeProps) {
  return (
    <Container
      variant="default"
      shape="small"
      className={styles.Badge}
      {...badgeProps}
    >
      {children}
    </Container>
  );
}

export const FilterBarButton = React.forwardRef<
  HTMLButtonElement,
  {
    label: string;
    appliedCount?: number;
    hideAppliedCount?: boolean;
  }
>((props, ref) => {
  const { appliedCount, hideAppliedCount, ...btnProps } = props;
  const { t } = useTranslation();
  const optionsAppliedLabel = t('search.options_applied', {
    count: appliedCount,
  });
  const hasCount = appliedCount && appliedCount > 0;
  return (
    <Button
      ref={ref}
      variant="text"
      size="compact"
      className={styles.FilterBar__filterButton}
      rightIcon={
        <>
          {!hideAppliedCount && hasCount ? (
            <Badge aria-label={optionsAppliedLabel}>{appliedCount}</Badge>
          ) : (
            <VisuallyHidden>{optionsAppliedLabel}</VisuallyHidden>
          )}
          <Icon size={18} aria-hidden>
            expand_more
          </Icon>
        </>
      }
      {...btnProps}
    />
  );
});
FilterBarButton.displayName = 'FilterBarButton';

export function RemoveFilterButton(props: ComponentPropsWithoutRef<'button'>) {
  return (
    <button {...props} className={styles.FilterBar__removeFilterButton}>
      <Icon size={13} aria-hidden>
        close
      </Icon>
    </button>
  );
}

export function FilterBarSkeleton() {
  return (
    <Container shape="medium" shadow="light" className={styles.FilterBar}>
      <div className={styles.filterBarSkeletonPills}>
        <LoadingSkeleton width={'13%'} />
        <div className={styles.filterBarSkeletonPillsEnd}>
          <LoadingSkeleton width={'30%'} />
          <LoadingSkeleton width={'30%'} />
          <LoadingSkeleton width={'30%'} />
        </div>
      </div>
    </Container>
  );
}
