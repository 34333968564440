import { useRef, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import { assistantSelectors } from '../../models/assistant';

import { AssistantHistoryItem, AssistantSuggestedCommands } from '.';
import { Button } from '../../models/assistant/interfaces/Button';
import { RootPatronState } from '../../common/use-patron-selector';

type StateProps = {
  assistantHistory: ReturnType<typeof assistantSelectors.getHistory>;
  suggestedCommands: ReturnType<
    typeof assistantSelectors.getLastSuggestedCommands
  >;
};

type OwnProps = {
  onSelect: (command: Button) => void;
};

type AssistantDialogueProps = StateProps & OwnProps;

function AssistantDialogue({
  assistantHistory,
  suggestedCommands,
  onSelect,
}: AssistantDialogueProps) {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const lastItem = useRef<HTMLDivElement>(null);

  const scrollTo = (
    ele: HTMLDivElement | null,
    config: ScrollIntoViewOptions = {}
  ) => {
    setTimeout(() => {
      ele &&
        ele.scrollIntoView({
          behavior: 'smooth',
          ...config,
        });
    }, 100);
  };

  const onCommandToggle = (ele: HTMLDivElement | null) => {
    scrollTo(ele, { block: 'end' });
  };
  //Initially jump to the end of the list
  useLayoutEffect(() => {
    const panel = scrollContainer.current!;
    panel.scrollTop = panel.scrollHeight;
  }, []);
  //On next updates scroll smoothly
  useEffect(() => {
    scrollTo(lastItem.current); // Get latest ref
  }, [assistantHistory]);

  return (
    <div className="assistant__search-pane" ref={scrollContainer}>
      {assistantHistory &&
        assistantHistory.map((historyItem, index) => (
          <AssistantHistoryItem
            item={historyItem}
            key={historyItem.id}
            ref={index === assistantHistory.length - 1 ? lastItem : null}
          />
        ))}

      {suggestedCommands && suggestedCommands.length ? (
        <AssistantSuggestedCommands
          onSelect={onSelect}
          onToggle={onCommandToggle}
          commands={suggestedCommands}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  assistantHistory: assistantSelectors.getHistory(state),
  suggestedCommands: assistantSelectors.getLastSuggestedCommands(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantDialogue);
