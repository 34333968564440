import React, { ErrorInfo } from 'react';

import { Icon } from './ui';
import { datadogRum } from '@datadog/browser-rum';

export type ErrorBoundaryState = {
  error?: Error | string;
  info?: ErrorInfo | string;
};

class ErrorBoundary extends React.Component<
  {
    fallbackComponent: React.FunctionComponent<ErrorBoundaryState>;
  },
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {};

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info });

    datadogRum.addError({
      error,
      params: { info },
    });
  }

  render() {
    const { fallbackComponent: FallbackComponent } = this.props;

    const { error, info } = this.state;

    if (error)
      return FallbackComponent ? (
        <FallbackComponent error={error} info={info} />
      ) : (
        <div className="flash-message is-error is-active">
          <div className="container">
            <div className="flash-message__body">
              <Icon type="cancel" />

              {error.toString()}
            </div>
          </div>
        </div>
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
