import { Monitor } from './types';

/**
 * Handles detection of whether an article has been completed in a ui sense. Completion means that
 * the user has "seen" the entire article by scrolling to the bottom.
 */
export default class ArticleMonitor implements Monitor {
  private attachedElement?: HTMLIFrameElement;
  private readonly frameScrollHandler: (event: Event) => void;
  private readonly windowScrollHandler: () => void;

  constructor(readonly onCompleteCallback: (monitor: ArticleMonitor) => void) {
    this.frameScrollHandler = this.handleFrameScroll.bind(this);
    this.windowScrollHandler = this.handleWindowScroll.bind(this);
  }

  attach(el: HTMLIFrameElement) {
    if (this.attachedElement) {
      this.detach();
    }

    this.attachedElement = el;

    const setupScrollHandlers = () => {
      if (isIframeHigherThanViewport(el)) {
        window.addEventListener('scroll', this.windowScrollHandler);
        this.windowScrollHandler();
      } else if (el.contentDocument && !this.detect(el.contentDocument)) {
        el.contentDocument.addEventListener('scroll', this.frameScrollHandler);
      }
      // re-attach the handler in case the iframe height changes
      el.contentWindow?.addEventListener('resize', this.attach.bind(this, el));
    };

    if (
      el.contentWindow &&
      el.contentWindow.document.readyState === 'complete'
    ) {
      setupScrollHandlers();
    } else {
      el.addEventListener('load', setupScrollHandlers);
    }
  }

  detach() {
    if (!this.attachedElement) return;

    if (isIframeHigherThanViewport(this.attachedElement)) {
      window.removeEventListener('scroll', this.windowScrollHandler);
    } else {
      this.attachedElement.contentDocument?.removeEventListener(
        'scroll',
        this.frameScrollHandler
      );
    }

    this.attachedElement = undefined;
  }

  protected detect(document: unknown) {
    if (!isDocument(document)) return;

    const viewportHeight = Math.min(
      window.innerHeight,
      document.documentElement.clientHeight
    );
    const contentEl =
      document.querySelector('body .base-template-container') || // v1 content
      document.querySelector('body > div'); // v2 content

    //adding 5px to account for rounding errors when the contentEl is flush with the end of the page.
    if (
      contentEl &&
      contentEl.getBoundingClientRect().bottom <= viewportHeight + 5
    ) {
      this.onCompleteCallback(this);
      return true;
    }
  }

  protected handleFrameScroll(event: Event) {
    this.detect(event.target);
  }

  protected handleWindowScroll() {
    const viewportHeight = window.innerHeight;

    if (
      this.attachedElement?.contentDocument &&
      this.attachedElement.getBoundingClientRect().bottom <= viewportHeight + 5
    ) {
      this.onCompleteCallback(this);
      return true;
    }
  }
}

function isDocument(doc: unknown): doc is Document {
  return typeof doc === 'object' && doc?.toString() === '[object HTMLDocument]';
}

function isIframeHigherThanViewport(el: HTMLIFrameElement) {
  if (!el.contentWindow) return false;
  return el.contentWindow.innerHeight >= window.innerHeight;
}
