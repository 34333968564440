import { useState } from 'react';
import { usePopper } from 'react-popper';
import { Icon } from '.';
import Button from './button';

const DropdownList = ({ options, onSelectionChange, disabled }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  //refs stored in state as per 'usePopper' docs
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const handleDropdownClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleItemClick = (optionIndex) => {
    const itemChanged = optionIndex !== selectedIndex;

    setOpen(false);
    setSelectedIndex(optionIndex);

    if (itemChanged && onSelectionChange) {
      onSelectionChange(options[optionIndex]);
    }
  };

  let menuBlurTimeoutID;
  const handleBlur = () => {
    //only close menu after next tick, giving chance for other focus event handlers to cancel timeout within same tick.
    menuBlurTimeoutID = setTimeout(() => setOpen(false), 0);
  };
  const handleFocus = () => {
    clearTimeout(menuBlurTimeoutID);
  };

  return (
    <div className="dropdown" onBlur={handleBlur} onFocus={handleFocus}>
      <Button
        ref={setReferenceElement}
        className="dropdown__button"
        onClick={handleDropdownClick}
        disabled={disabled}
      >
        <span>{options[selectedIndex].name}</span>
        <Icon type={open ? 'expand_less' : 'expand_more'} />
      </Button>

      {open ? (
        <div
          className="dropdown__popover"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <ul className="dropdown__list">
            {options.map(({ name, description }, index) => (
              <li className="dropdown__list-item" key={name}>
                <Button
                  onClick={() => handleItemClick(index)}
                  style={{ width: '100%' }}
                >
                  <div
                    className="dropdown__list-item__content"
                    style={{ width: '100%' }}
                  >
                    <div className="dropdown__list-item__name">{name}</div>
                    <div
                      className="dropdown__list-item__description"
                      style={{ width: '100%' }}
                    >
                      {description}
                    </div>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DropdownList;
