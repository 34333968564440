import cx from 'classnames';

interface AssistantTitleProps {
  children: string;
  className?: string;
}

export default function AssistantTitle({
  children,
  className,
}: AssistantTitleProps) {
  const classNames = cx('assistant__title', className);

  return <div className={classNames}>{children}</div>;
}
