import types from './types.js';

const acknowledgedContent = (id) => ({
  type: types.ACKNOWLEDGE,
  id,
});

const acknowledgedContentComplete = (id, attributes) => ({
  type: types.ACKNOWLEDGE_COMPLETE,
  id,
  attributes,
});

const unacknowledgedContent = (id) => ({
  type: types.UNACKNOWLEDGE,
  id,
});

const completedContent = (id) => ({
  type: types.COMPLETED,
  id,
});

const addContent = (content) => ({
  type: types.ADD,
  content,
});

const addContents = (contents) => ({
  type: types.ADD_BULK,
  contents,
});

const likedContent = (id) => ({
  type: types.LIKE,
  id,
});

const unlikedContent = (id) => ({
  type: types.UNLIKE,
  id,
});

const bookmarkedContent = (id) => ({
  type: types.BOOKMARK,
  id,
});

const unbookmarkedContent = (id) => ({
  type: types.UNBOOKMARK,
  id,
});

const sharedContent = (id) => ({
  type: types.SHARE,
  id,
});

const unsharedContent = (id) => ({
  type: types.UNSHARE,
  id,
});

const incrementCommentCount = (id) => ({
  type: types.INCREMENT_COMMENT_COUNT,
  id,
});

const decrementCommentCount = (id, by = 1) => ({
  type: types.DECREMENT_COMMENT_COUNT,
  id,
  by,
});

export default {
  acknowledgedContent,
  acknowledgedContentComplete,
  unacknowledgedContent,
  completedContent,
  addContent,
  addContents,
  likedContent,
  unlikedContent,
  bookmarkedContent,
  unbookmarkedContent,
  sharedContent,
  unsharedContent,
  incrementCommentCount,
  decrementCommentCount,
};
