import {
  Button,
  FeedCardGrid,
  FeedCardGridItem,
  Icon,
  TextInput,
} from '@socialchorus/shared-ui-components';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../../../common/use-skip-to-content';
import { ChannelTabType } from '../../../../../models/channels/types';
import useContentFeed from '../../../../../common/use-content-feed';
import EmptyFeed from '../../../../feed/empty-feed';
import EndOfFeed from '../../../../feed/end-of-feed';
import { Spinner } from '../../../../ui';
import ViewTrigger from '../../../../view-trigger';
import { BaseTab } from '../BaseTab';
import { FeedCard } from './feed-card';
import styles from './styles.module.css';
import EmptyPlaceholder from '../../common/EmptyPlaceholder';

interface Props {
  topicId: string;
  analyticsData: IAnalyticsData;
}

const PostsTab: FC<Props> = ({ topicId, analyticsData }) => {
  const { t } = useTranslation();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const resetSearch = () => {
    setSearchQuery('');
    searchInputRef.current?.focus();
  };

  const { fetching, feedItems, total, canLoadMore, fetchFeed } = useContentFeed(
    {
      channelId: topicId,
      // If the search query has been cleared, use the empty value to prevent delay
      query: searchQuery.length === 0 ? searchQuery : debouncedSearchQuery,
      pinned: true,
    }
  );

  useEffect(() => {
    if (!hasLoaded && !fetching) {
      setHasLoaded(true);
    }
  }, [fetching, hasLoaded]);

  // If the search query has been cleared, set this variable to `false` instead of waiting for the debounced value
  const hasQuery = searchQuery.length > 0 && debouncedSearchQuery.length > 0;
  const hasNoPostsInTopic = !hasQuery && !fetching && feedItems.length === 0;

  if (hasNoPostsInTopic) {
    return (
      <BaseTab currentTab={ChannelTabType.Posts} topicId={topicId}>
        <EmptyPlaceholder
          title={t('screens.topic_page.no_posts.title')}
          description={t('screens.topic_page.no_posts.description')}
        />
      </BaseTab>
    );
  }

  return (
    <BaseTab currentTab={ChannelTabType.Posts} topicId={topicId}>
      {/* Hide search box before first load and only display when there's content in the topic */}
      {hasLoaded && (
        <div className={styles.SearchBox__Wrapper}>
          <TextInput
            type="search"
            ref={searchInputRef}
            leftWidget={<Icon size={14}>search</Icon>}
            placeholder={t('screens.topic_page.searchbox_placeholder')}
            size="compact"
            value={searchQuery}
            onChange={(val) => setSearchQuery(val || '')}
          />
          {hasQuery && !fetching && (
            <span
              role="status"
              aria-live="polite"
              className="search-box--result-status"
            >
              {t('screens.topic_page.search_results.count', { count: total })}
            </span>
          )}
        </div>
      )}

      {!fetching && hasQuery && total === 0 ? (
        <EmptyPlaceholder
          title={t('screens.topic_page.search_results.no_results.title')}
          description={t(
            'screens.topic_page.search_results.no_results.description',
            {
              query: debouncedSearchQuery,
            }
          )}
          cta={
            <Button
              variant="primary"
              label={t('content.clear_search')}
              onClick={resetSearch}
            />
          }
        />
      ) : (
        <section
          className={styles.FeedCardList}
          id={DEFAULT_SKIP_TO_CONTENT_ID}
        >
          <FeedCardGrid columns={1}>
            {feedItems.map((content) => (
              <FeedCardGridItem key={content.id}>
                <FeedCard content={content} analyticsData={analyticsData} />
              </FeedCardGridItem>
            ))}
          </FeedCardGrid>
          {fetching ? (
            <Spinner />
          ) : canLoadMore ? (
            <ViewTrigger offset="600px" onInview={() => fetchFeed()} />
          ) : feedItems.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </section>
      )}
    </BaseTab>
  );
};

export default PostsTab;
