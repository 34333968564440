import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqualWith';

type CompareFn<T> = (value: T, target: T) => boolean;

export default function usePropMemo<T>(
  prop: T,
  compareFn: CompareFn<T> = isEqual
) {
  const [storedProp, setStoredProp] = useState<T>(prop);

  useEffect(() => {
    setStoredProp((storedProp) => {
      if (!compareFn(storedProp, prop)) return prop;
      return storedProp;
    });
  }, [prop, compareFn]);

  return storedProp;
}
