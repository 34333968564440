import { useSelector } from 'react-redux';
import * as svgIcons from './icons_svg';
import * as googleIcons from './icons_google';
import { getFeatureFlag, Feature } from '../../models/features/features';
import Icon from './icon';

// Note: When possible, use the Icon component directly
// This class serves to enable feature-flagged legacy-logic to deliver old icons
// New components should use the default Icon behaviour (Material Symbols)

export const Globe = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Globe(props) : svgIcons.Globe(props);
};

export const Inbox = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Inbox(props) : svgIcons.Inbox(props);
};

export const Chat = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Chat(props) : svgIcons.Chat(props);
};

export const Notifications = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.Notifications(props)
    : svgIcons.Notifications(props);
};

export const Compass = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Compass(props) : svgIcons.Compass(props);
};

export const LightBulb = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.LightBulb(props) : svgIcons.LightBulb(props);
};

export const AssistantIcon = (props) => {
  const assistantCompassMode = useSelector((state) =>
    getFeatureFlag(state, Feature.ASSISTANT_ICON_IS_COMPASS)
  );
  return assistantCompassMode ? (
    <Compass style={{ fontSize: '32px' }} {...props} />
  ) : (
    <LightBulb className="filled" {...props} />
  );
};

export const DiscoverIcon = (props) => {
  const assistantCompassMode = useSelector((state) =>
    getFeatureFlag(state, Feature.ASSISTANT_ICON_IS_COMPASS)
  );
  //opposite of assistant
  return !assistantCompassMode ? (
    <Compass {...props} />
  ) : (
    <LightBulb className="filled" {...props} />
  );
};

export const HappyInbox = (props) => {
  return svgIcons.HappyInbox(props);
};

export const Shield = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Shield(props) : svgIcons.Shield(props);
};

export const LaptopRobot = (props) => {
  return svgIcons.LaptopRobot(props);
};

export const Profile = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.Profile(props) : svgIcons.Profile(props);
};

export const SideBarProfileIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.SideBarProfileIcon(props)
    : svgIcons.SideBarProfileIcon(props);
};

export const ChevronRight = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ChevronRight(props)
    : svgIcons.ChevronRight(props);
};

export const ChevronLeft = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ChevronLeft(props)
    : svgIcons.ChevronLeft(props);
};

export const VideoIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.VideoIcon(props) : svgIcons.VideoIcon(props);
};

export const VideoPostIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.VideoPostIcon(props)
    : svgIcons.VideoPostIcon(props);
};

export const ImageIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.ImageIcon(props) : svgIcons.ImageIcon(props);
};

export const NoteIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.NoteIcon(props) : svgIcons.NoteIcon(props);
};

export const ArticleIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ArticleIcon(props)
    : svgIcons.ArticleIcon(props);
};

export const LinkIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.LinkIcon(props) : svgIcons.LinkIcon(props);
};

export const FeaturedIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.FeaturedIcon(props)
    : svgIcons.FeaturedIcon(props);
};

export const StarFeaturedIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.StarFeaturedIcon(props)
    : svgIcons.StarFeaturedIcon(props);
};

export const TrendingIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.TrendingIcon(props)
    : svgIcons.TrendingIcon(props);
};

export const SwitchIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.SwitchIcon(props) : svgIcons.SwitchIcon(props);
};

export const BookmarkIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.BookmarkIcon(props)
    : svgIcons.BookmarkIcon(props);
};

export const BookmarkedIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.BookmarkedIcon(props)
    : svgIcons.BookmarkedIcon(props);
};

export const PostIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.PostIcon(props) : svgIcons.PostIcon(props);
};

export const ChannelsIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ChannelsIcon(props)
    : svgIcons.ChannelsIcon(props);
};

export const EditProfileIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.EditProfileIcon(props)
    : svgIcons.EditProfileIcon(props);
};

export const ResetPasswordIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ResetPasswordIcon(props)
    : svgIcons.ResetPasswordIcon(props);
};

export const ShareIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.ShareIcon(props) : svgIcons.ShareIcon(props);
};

export const SubmitCommentIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.SubmitCommentIcon(props)
    : svgIcons.SubmitCommentIcon(props);
};

export const PersonIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.PersonIcon(props) : svgIcons.PersonIcon(props);
};

export const LatestIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.LatestIcon(props) : svgIcons.LatestIcon(props);
};

export const ResourcesIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.ResourcesIcon(props)
    : svgIcons.ResourcesIcon(props);
};

export const InviteIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.InviteIcon(props) : svgIcons.InviteIcon(props);
};

export const HomeIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType ? googleIcons.HomeIcon(props) : svgIcons.HomeIcon(props);
};

export const JoinedChannelsIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.JoinedChannelsIcon(props)
    : svgIcons.JoinedChannelsIcon(props);
};

export const JoinChannelsIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.JoinChannelsIcon(props)
    : svgIcons.JoinChannelsIcon(props);
};

export const LikeIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.LIKE_ICON_TYPE)
  );
  return iconType ? (
    <Icon type="thumb_up" className="no-animation filled" {...props} />
  ) : (
    <Icon type="favorite" className="heart filled" {...props} />
  );
};

export const LikeIconOutline = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.LIKE_ICON_TYPE)
  );
  return iconType ? (
    <Icon type="thumb_up" className="no-animation" {...props} />
  ) : (
    <Icon type="favorite_border" className="heart old" {...props} />
  );
};

export const SettingsIcon = (props) => {
  const iconType = useSelector((state) =>
    getFeatureFlag(state, Feature.ICON_TYPE)
  );
  return iconType
    ? googleIcons.SettingsIcon(props)
    : svgIcons.SettingsIcon(props);
};

export const NewContentIcon = (props) => {
  return googleIcons.NewContentIcon(props);
};

export const VideoPlayIcon = (props) => {
  return googleIcons.VideoPlayIcon(props);
};
export const GroupIcon = (props) => {
  return googleIcons.GroupIcon(props);
};
