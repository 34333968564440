import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import './topic-page-posts.scss';
import useContentFeed from '../../../../../common/use-content-feed';
import { TopicPageEmptyState } from '../../empty-states/topic-page-empty-state';
import { Button, Icon } from '../../../../ui';
import { Textbox } from '../../../../ui/text-input';
import Feed from '../../../../feed';

export function TopicPagePosts({
  channelId,
  analyticsData,
}: TopicPageTabComponent) {
  const { t } = useTranslation();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const resetSearch = () => {
    setSearchQuery('');
    searchInputRef.current?.focus();
  };

  const { fetching, feedItems, page, total, canLoadMore, fetchFeed } =
    useContentFeed({
      channelId,
      // If the search query has been cleared, use the empty value to prevent delay
      query: searchQuery.length === 0 ? searchQuery : debouncedSearchQuery,
      pinned: true,
    });

  useEffect(() => {
    if (!hasLoaded && !fetching) {
      setHasLoaded(true);
    }
  }, [fetching, hasLoaded]);

  // If the search query has been cleared, set this variable to `false` instead of waiting for the debounced value
  const hasQuery = searchQuery.length > 0 && debouncedSearchQuery.length > 0;
  const hasNoPostsInTopic = !hasQuery && !fetching && feedItems.length === 0;

  if (hasNoPostsInTopic) {
    return (
      <TopicPageEmptyState
        icon={<Icon type="article" />}
        heading={t('screens.topic_page.no_posts.title')}
        details={t('screens.topic_page.no_posts.description')}
      />
    );
  }

  return (
    <>
      {/* Hide search box before first load and only display when there's content in the topic */}
      {hasLoaded && (
        <div className="search-box--wrapper">
          <div className="search-box">
            <Icon type="search" />
            <Textbox
              type="search"
              ref={searchInputRef}
              placeholder={t('screens.topic_page.searchbox_placeholder')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value || '')}
            />
          </div>
          {hasQuery && !fetching && (
            <span
              role="status"
              aria-live="polite"
              className="search-box--result-status"
            >
              {t('screens.topic_page.search_results.count', { count: total })}
            </span>
          )}
        </div>
      )}

      {!fetching && hasQuery && total === 0 ? (
        <TopicPageEmptyState
          icon={<Icon className="icon" type="search" />}
          heading={t('screens.topic_page.search_results.no_results.title')}
          details={t(
            'screens.topic_page.search_results.no_results.description',
            {
              query: debouncedSearchQuery,
            }
          )}
          button={
            <Button theme="primary" onClick={resetSearch}>
              {t('content.clear_search')}
            </Button>
          }
        />
      ) : (
        <Feed
          items={feedItems}
          page={page}
          fetching={fetching}
          canLoadMore={canLoadMore}
          fetchFeed={fetchFeed}
          analyticsData={analyticsData}
          contentTitleTag="h2"
        />
      )}
    </>
  );
}
