import React, { useCallback, useRef } from 'react';
import { atom, useRecoilState } from 'recoil';
import { ToastMessage } from './toast-message';

import './toaster.scss';
//trying something a little different here: state, hook and component all in the same file.
//seems cleaner, as underlying state can be private and managed.
//to "dispatch" a toast message, just call setToastMessage with the message you want to display, and you can create it with message.

const toastMessagesState = atom<ToastMessageType[]>({
  key: 'toastMessagesState',
  default: [],
});

export type ToastMessageType = {
  type: 'none' | 'success' | 'warning' | 'error';
  text: string;
  details?: string;
  timeout?: number | false;
};

//creates a message with some nice defaults
export function createToastMessage({
  text,
  details = '',
  timeout = 5000,
  type = 'none',
}: {
  text: ToastMessageType['text'];
  details?: ToastMessageType['details'];
  timeout?: ToastMessageType['timeout'];
  type?: ToastMessageType['type'];
}): ToastMessageType {
  return { details, text, type, timeout };
}

export const useToastMessages = () => {
  const [toastMessages, setToastMessages] = useRecoilState(toastMessagesState);

  const latest = useRef(toastMessages);
  latest.current = toastMessages;

  const setToastMessage = useCallback(
    (message: ToastMessageType) => {
      if (latest.current.find(({ text: key }) => key === message.text)) {
        return;
      }
      setToastMessages((currentMessages) => [...currentMessages, message]);
    },
    [latest, setToastMessages]
  );

  const dismiss = useCallback(
    ({ text: key }: Pick<ToastMessageType, 'text'>) => {
      if (!latest.current.find(({ text }) => text === key)) return;
      setToastMessages((messages) =>
        messages.filter(({ text }) => text !== key)
      );
    },
    [latest, setToastMessages]
  );

  return { toastMessages, setToastMessage, dismiss };
};

/**
 * @deprecated, to be replaced by solar toasts
 */
export const Toaster: React.FC = () => {
  const { toastMessages, dismiss } = useToastMessages();
  return toastMessages.length > 0 ? (
    <div className="messagesWrapper" aria-live="polite" role="alert">
      {toastMessages.map((message) => (
        <ToastMessage key={message.text} dismiss={dismiss} message={message} />
      ))}
    </div>
  ) : null;
};
