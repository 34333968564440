import * as types from './keys';
import { track } from '../../analytics/helpers';
import { memoize } from 'lodash';

export const trackProfileLoad = (traits) => {
  track(types.PROFILE_LOAD, traits);
};

export const trackProfileEditClick = (traits) => {
  track(types.PROFILE_EDIT_CLICK, traits);
};

export const trackProfileEditCancel = (traits) => {
  track(types.PROFILE_EDIT_CANCEL, traits);
};

export const trackProfileEditSave = (traits) => {
  track(types.PROFILE_EDIT_SAVE, traits);
};

export const trackProfileEditError = (traits) => {
  track(types.PROFILE_EDIT_ERROR, traits);
};

export const trackProfileView = (traits) =>
  memoize((profileId) => {
    track(types.PROFILE_VIEW, {
      advocate_id: profileId,
    });
  })(traits.advocate_id);
