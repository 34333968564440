import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionIsDraft,
  submissionSaveStatus,
  submissionImageUrls,
  submissionImages,
} from '../../../models/content-submission/atoms';

import Dropzone from '../inputs/dropzone';
import Description from '../inputs/description';
import MultiImage from '../inputs/multi-image';
import DropzoneOverlay from '../inputs/dropzone-overlay';
import ImagePreview from '../image-preview';

const ImageForm = () => {
  const images = useRecoilValue(submissionImages);
  const imageUrls = useRecoilValue(submissionImageUrls);
  const setSaveStatus = useSetRecoilState(submissionSaveStatus);
  const disabled = useRecoilValue(submissionIsDraft);

  const { t } = useTranslation();

  useEffect(() => {
    if (images.length === imageUrls.length) {
      setSaveStatus(images.length > 0 ? 'saved' : 'unsaved');
    } else {
      setSaveStatus('saving');
    }
  }, [images, imageUrls, setSaveStatus]);

  return (
    <Dropzone
      accept="image/jpeg, image/png"
      maxSize={10485760} // 10mb
      maxLength={10}
      multiple
    >
      {({
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        draggedFilesErrorMessage,
        droppedFilesErrorMessage,
      }) => {
        return (
          <div
            className="content-submission-form content-submission-form--image"
            {...getRootProps()}
            role="none"
            tabIndex={-1}
            onClick={null}
            onKeyDown={null}
          >
            <div className="content-submission-form__header">
              <div className="content-submission-form__text">
                <Description
                  aria-label={t(
                    'content_submission.field_description_optional'
                  )}
                  placeholder={t(
                    'content_submission.field_description_optional'
                  )}
                />
              </div>
              <div className="content-submission-form__cover-image">
                <ImagePreview />
              </div>
            </div>
            <MultiImage
              inputProps={getInputProps()}
              openFileBrowser={open}
              disabled={disabled}
              errorMessage={droppedFilesErrorMessage}
            />

            {isDragActive ? (
              <DropzoneOverlay
                placeholderText={t(
                  'content_submission.field_image_placeholder'
                )}
                errorText={draggedFilesErrorMessage}
              />
            ) : null}
          </div>
        );
      }}
    </Dropzone>
  );
};

export default ImageForm;
