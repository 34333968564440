import ChannelService from '../../../../../services/channel';
import { ChannelMember } from '../../../../../models/channels/types';
import useInfiniteFetch, {
  FetchFn,
} from '../../../../../hooks/useInfiniteFetch';
import { useCallback } from 'react';

const DEFAULT_AVATAR =
  'https://lib.socialchorus.com/mojo/public/default_avatar_new.png';
export interface Follower {
  id: number;
  avatarUrl: string;
  avatarColor: string;
  name: string;
}

function mapMember(item: ChannelMember): Follower {
  return {
    id: parseInt(item.id, 10),
    avatarUrl: item.attributes.avatar.url || DEFAULT_AVATAR,
    avatarColor: item.attributes.avatar.color,
    name: item.attributes.name,
  };
}

export function useMembers(topicId: string, pageSize = 40) {
  const fetchData = useCallback<FetchFn<Follower>>(
    async (page, pageSize) => {
      const channel = new ChannelService(topicId);
      const res = await channel.fetchMembers(pageSize, page);
      return {
        data: res.data.data.map(mapMember),
        meta: {
          total: res.data.meta.total_records,
          pageSize: res.data.meta.page_size,
          currentPage: res.data.meta.current_page,
        },
      };
    },
    [topicId]
  );
  return useInfiniteFetch<Follower>(fetchData, pageSize);
}
