import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ContentCard, ContentCardProps } from '../content-card/content-card';
import ContentCardThumbnail from '../content-card/content-card-thumbnail/content-card-thumbnail';

import './content-layouts.scss';
import FeedHeader, { FeedHeaderProps } from './feed-header';
import { useTranslation } from 'react-i18next';
import { Button } from '../../ui';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { Content } from '../../../models/content/types';
import { RootPatronState } from '../../../common/use-patron-selector';

type ContentLayoutGalleryProps = FeedHeaderProps & {
  contents: Content[];
  analyticsData: ContentCardProps['analyticsData'];
  description: string;
  feedUrl: string;
  viewAllText: string;
  isTopic: boolean;
  contentTitleTag?: ContentCardProps['titleTag'];
};

const ContentLayoutGallery = ({
  id,
  contents,
  analyticsData,
  title,
  description,
  feedUrl,
  viewAllText,
  isTopic = false,
  contentTitleTag = 'h2',
}: ContentLayoutGalleryProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { t } = useTranslation();
  const useFillCrop = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.TEST_NEW_UI_FILL_CROP)
  );

  const renderThumbnails = (contentArray: Content[]) => {
    return contentArray.map((content, i) => (
      <Button
        className={'gallery-thumbnail' + (i === selectedIndex ? ' active' : '')}
        key={content.id}
        onClick={() => {
          setSelectedIndex(i);
        }}
      >
        <ContentCardThumbnail
          imgSrc={
            (useFillCrop
              ? content.feed_preview_image_urls?.[0]
              : content.background_image_urls?.[0]?.url) ||
            content.background_image_url
          }
          imgSizing={'cover'}
          label={''}
          contentProperties={{ contentType: 'image' }}
          aspectRatio={'1:1'}
        />
      </Button>
    ));
  };

  return (
    <>
      <FeedHeader
        id={id}
        title={title}
        description={description}
        viewAllLink={feedUrl}
        viewAllText={viewAllText || `${t('assistant.view_all')} ${title}`}
        isTopic={isTopic}
        analyticsData={analyticsData}
      />
      <div className={'content-layout__gallery'}>
        <div className={'content-layout__gallery-card'}>
          <ContentCard.Horizontal
            titleTag={contentTitleTag}
            content={contents[selectedIndex]}
            analyticsData={analyticsData}
            key={selectedIndex}
          />
        </div>
        <div className={'content-layout__gallery-thumbnails'}>
          {renderThumbnails(contents)}
        </div>
      </div>
    </>
  );
};

export default ContentLayoutGallery;
