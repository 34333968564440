import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import {
  FilterDropdown,
  FilterTags,
  mapFilterTagToIcon,
  mapFilterTagsToVariant,
} from './filter-dropdown';
import {
  Button,
  Icon,
  TextInput,
  IconButton,
  Tag,
  Checkbox,
  TooltipRoot,
  TooltipContent,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import styles from './notification-center.module.scss';
import { useTranslation } from 'react-i18next';
import { NotificationCenterTabs } from '../../models/notifications/types';
import { useBulkEdit } from './use-bulk-edit';
import { NotificationContext } from './context';

// this could be maybe better represented as a series of booleans, but i personally like the enum for readability. (maybe separate toolbar components for each mode?)
// normal can be thought of as the default state. from normal you can move to archive or multi-select-normal and back.
// archive can then move to multi-select-archive and back.
// trying to keep this all in the same component for now, its going to be easier to maintain search and filter context between them imo if we do. That could be wrong.
enum Modes {
  NORMAL = 'normal',
  ARCHIVE = 'archive',
  MULTI_SELECT_NORMAL = 'multi-select-normal',
  MULTI_SELECT_ARCHIVE = 'multi-select-archive',
}

type ToolbarProps = {
  body?: HTMLDivElement | null;
};

export const Toolbar: React.FC<ToolbarProps> = ({ body }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(Modes.NORMAL);
  const [filterOptions, setFilterOptions] = useState<FilterTags[]>([]);

  const {
    tabs: [, setTabList],
    multiSelectEnabled: [, setMultiSelectEnabled],
    selectAll: [selectAll, setSelectAll],
    multiSelectedItems: [multiSelectItems, setMultiSelectItems],
    filterTags: [filterTags, setFilterTags],
    activeTab: [activeTab, setActiveTab],
  } = useContext(NotificationContext);

  const {
    archivableItems,
    unArchivableItems,
    markableAsReadItems,
    markableAsUnreadItems,
    allSelectedItemsNotArchivable,
    markItemsAsRead,
    markItemsAsUnread,
  } = useBulkEdit();

  const showMarkArchiveButton =
    archivableItems.length > 0 || allSelectedItemsNotArchivable;
  const showMarkUnArchiveButton = unArchivableItems.length > 0;
  const showMarkReadButton = markableAsReadItems.length > 0;
  const showMarkUnReadButton = markableAsUnreadItems.length > 0;

  const selectAllLabel = useMemo(() => {
    if (selectAll === true) {
      return t('common.selected_all_with_count', {
        count: multiSelectItems.length,
      });
    } else if (selectAll === 'indeterminate') {
      return t('common.selected_with_count', {
        count: multiSelectItems.length,
      });
    } else return t('common.select_all');
  }, [selectAll, multiSelectItems, t]);

  const onSelectAllChange = useCallback(
    (checked) => {
      if (checked) {
        setSelectAll(true);
      } else {
        setMultiSelectItems([]);
        setSelectAll(false);
      }
    },
    [setSelectAll, setMultiSelectItems]
  );

  const multiSelectEnabled = [
    Modes.MULTI_SELECT_NORMAL,
    Modes.MULTI_SELECT_ARCHIVE,
  ].includes(mode);
  useEffect(() => {
    if (!multiSelectEnabled) {
      setSelectAll(false);
      setMultiSelectItems([]);
    }
    setMultiSelectEnabled(multiSelectEnabled);
  }, [
    multiSelectEnabled,
    setMultiSelectEnabled,
    setSelectAll,
    setMultiSelectItems,
  ]);

  useEffect(() => {
    if (mode === Modes.ARCHIVE || mode === Modes.MULTI_SELECT_ARCHIVE) {
      setTabList([NotificationCenterTabs.ARCHIVE]);
    } else if (mode === Modes.MULTI_SELECT_NORMAL) {
      setTabList([activeTab]);
    } else {
      setTabList([
        NotificationCenterTabs.MESSAGES,
        NotificationCenterTabs.ACTIVITY,
      ]);
    }
  }, [setTabList, mode, activeTab]);

  //since this runs on every activeTab change, it is important to only modify it here when it is an invalid state.
  //this is mostly here to force the tabs component to have the only valid tab in the selected state when the validity list changes.
  useEffect(() => {
    if (mode === Modes.ARCHIVE || mode === Modes.MULTI_SELECT_ARCHIVE) {
      //archive is the only valid tab in archive mode.
      setActiveTab(NotificationCenterTabs.ARCHIVE);
    } else if (activeTab === NotificationCenterTabs.ARCHIVE) {
      //archive cannot be an active tab in normal mode.
      setActiveTab(NotificationCenterTabs.MESSAGES);
    }
  }, [mode, activeTab, setActiveTab]);

  useEffect(() => {
    if (mode === Modes.ARCHIVE || mode === Modes.MULTI_SELECT_ARCHIVE) {
      setFilterOptions([FilterTags.READ, FilterTags.UNREAD]);
    } else {
      setFilterOptions([
        FilterTags.ACTION_REQUIRED,
        FilterTags.IMPORTANT,
        FilterTags.READ,
        FilterTags.UNREAD,
      ]);
    }
  }, [mode]);

  return (
    <>
      <div
        className={cx(
          styles.toolbar,
          mode !== Modes.NORMAL && styles.contextModeEnabled
        )}
      >
        {mode === Modes.ARCHIVE && (
          <Button
            variant="text"
            label={t('common.back')}
            leftIcon={<Icon>arrow_back</Icon>}
            onClick={() => setMode(Modes.NORMAL)}
          />
        )}
        {multiSelectEnabled && (
          <Checkbox
            label={selectAllLabel}
            checked={selectAll}
            onCheckedChange={onSelectAllChange}
          />
        )}

        {/* Search Bar */}
        {!multiSelectEnabled ? (
          <TextInput
            fullWidth
            leftWidget={<Icon size={16}>search</Icon>}
            size="compact"
            // placeholder={t('notification_center.search_placeholder')}
            placeholder="Not Implemented"
            disabled //disabled as the search API is not yet implemented.
          />
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}

        {/* Filter Button */}
        <div className={styles.buttonGroup}>
          <FilterDropdown container={body} allowedOptions={filterOptions} />
        </div>

        {/* Archive Mode Button */}
        {mode === Modes.NORMAL && (
          <div className={styles.buttonGroup}>
            <TooltipRoot>
              <TooltipContent
                description={t('notification_center.view_archive')}
                sideOffset={5}
                container={body}
              />
              <TooltipTrigger asChild>
                <IconButton
                  label={t('notification_center.view_archive')}
                  iconName="inventory_2"
                  onClick={() => setMode(Modes.ARCHIVE)}
                />
              </TooltipTrigger>
            </TooltipRoot>
          </div>
        )}

        {/* Multi-Select Action Buttons */}
        {multiSelectEnabled && (
          <>
            {(showMarkArchiveButton || showMarkUnArchiveButton) && (
              <div className={styles.buttonGroup}>
                {showMarkArchiveButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={
                        allSelectedItemsNotArchivable
                          ? t(
                              'notification_center.archive_blocked_explaination'
                            )
                          : t('notification_center.move_to_archive')
                      }
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.move_to_archive')}
                        iconName="inventory_2"
                        disabled={allSelectedItemsNotArchivable}
                        onClick={() =>
                          alert(`archive: ${archivableItems.map((n) => n.id)}`)
                        }
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
                {showMarkUnArchiveButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.remove_from_archive')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.remove_from_archive')}
                        iconName="unarchive"
                        onClick={() =>
                          alert(
                            `un-archive: ${unArchivableItems.map((n) => n.id)}`
                          )
                        }
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
              </div>
            )}
            {(showMarkReadButton || showMarkUnReadButton) && (
              <div className={styles.buttonGroup}>
                {showMarkReadButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.mark_as_read')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.mark_as_read')}
                        className={styles.customIconButton}
                        iconName="drafts"
                        onClick={markItemsAsRead}
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
                {showMarkUnReadButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.mark_as_unread')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.mark_as_unread')}
                        className={styles.customIconButton}
                        iconName="mark_email_unread"
                        onClick={markItemsAsUnread}
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
              </div>
            )}
          </>
        )}

        {/* Multiselect Button / Back Button */}
        {!multiSelectEnabled ? (
          <div className={styles.buttonGroup}>
            <TooltipRoot>
              <TooltipContent
                description={t('notification_center.enable_multi_select')}
                sideOffset={5}
                container={body}
              />
              <TooltipTrigger asChild>
                <IconButton
                  iconName="edit_note"
                  //todo: re-enable for the multi-select ticket
                  disabled
                  onClick={() =>
                    mode === Modes.ARCHIVE
                      ? setMode(Modes.MULTI_SELECT_ARCHIVE)
                      : setMode(Modes.MULTI_SELECT_NORMAL)
                  }
                />
              </TooltipTrigger>
            </TooltipRoot>
          </div>
        ) : (
          <div className={styles.buttonGroup}>
            <Button
              variant="text"
              label={t('common.done')}
              onClick={() =>
                mode === Modes.MULTI_SELECT_ARCHIVE
                  ? setMode(Modes.ARCHIVE)
                  : setMode(Modes.NORMAL)
              }
            />
          </div>
        )}
      </div>

      {/* Filter Tags */}
      <div className={styles.filterTagContainer}>
        {filterTags.map((filter) => (
          <Tag
            key={filter}
            compact
            variant={mapFilterTagsToVariant(filter)}
            icon={mapFilterTagToIcon({ tag: filter, compact: true })}
            onDelete={() =>
              setFilterTags(filterTags.filter((f) => f !== filter))
            }
            label={t(`notification_center.tags.${filter}`)}
          />
        ))}
      </div>
    </>
  );
};
