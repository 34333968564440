import React from 'react';
import EndOfFeed from '../../feed/end-of-feed';
import { Spinner } from '../../ui';
import ViewTrigger from '../../view-trigger';
import { ErrorBanner } from './error/error';
import useSWRInfinite from 'swr/infinite';
import type {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from 'swr/infinite';
import type { Arguments, BareFetcher } from 'swr';

export function InfiniteSearchResults({
  isLoadingMore,
  isReachingEnd,
  onViewTrigger,
  errorFetching,
  handleRetry,
  children,
}: {
  isLoadingMore: boolean;
  isReachingEnd: boolean;
  onViewTrigger: () => void;
  errorFetching?: boolean;
  handleRetry: () => void;
  children: React.ReactNode;
}) {
  const [showErrorBanner, setShowErrorBanner] = React.useState(true);

  function hideErrorBanner() {
    setShowErrorBanner(false);
  }

  return (
    <>
      {children}
      {errorFetching && showErrorBanner && (
        <ErrorBanner handleRetry={handleRetry} handleClose={hideErrorBanner} />
      )}
      {(() => {
        if (isLoadingMore) {
          return <Spinner />;
        }

        if (isReachingEnd) {
          return <EndOfFeed />;
        }

        return (
          <ViewTrigger
            onInview={() => {
              onViewTrigger();
            }}
          />
        );
      })()}
    </>
  );
}

type InfiniteSearchKeyLoader<
  Data = unknown,
  Args extends Arguments = Arguments
> = (index: number, previousPageData: Data | null) => Args[];

type InfiniteSearchFetcher<TArgs extends Arguments, Data> = (
  ...args: TArgs[]
) => Promise<Data>;

// This is a workaround for the issue described in
// https://firstup-io.atlassian.net/browse/FE-7837
// For some reason, when the key is a string, SWRInfinite will
// sometimes not have the correct page number in the key.
// This function enforces that the key is an array, which
// seems to prevent the issue.

/**
 * A wrapper around `useSWRInfinite` that enforces the `getKey`
 * returns an array as the key.
 *
 * This is to prevent [FE-7837](https://firstup-io.atlassian.net/browse/FE-7837)
 * from reoccurring.
 **/
export function useInfiniteSearch<
  Data,
  GetKey extends InfiniteSearchKeyLoader,
  Error
>(
  getKey: GetKey,
  fetcher: InfiniteSearchFetcher<ReturnType<GetKey>, Data> | null,
  config:
    | SWRInfiniteConfiguration<Data, Error, BareFetcher<Data>>
    | undefined = {}
): SWRInfiniteResponse<Data, Error> {
  return useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
    ...config,
  });
}
