import { connect } from 'react-redux';
import cx from 'classnames';

import {
  contentSelectors,
  contentOperations,
} from '../../models/content/index.js';

import { Button, Icon } from '../ui';

import './content.scss';

const ContentActionAcknowledgement = ({
  contentId,
  acked,
  acknowledgement,
  acknowledgeContent,
  prereqsCompleted,
}) => {
  const className = cx('content__acknowledge', {
    'content__acknowledge--completed': acked,
  });

  return (
    <div className={className}>
      {acked
        ? ackedDiv(acknowledgement)
        : unackedDiv(
            contentId,
            acked,
            acknowledgement,
            acknowledgeContent,
            prereqsCompleted
          )}
    </div>
  );
};

const ackedDiv = (acknowledgement) => {
  return (
    <div>
      <span className="icons">
        <Icon type="done" />
      </span>
      <span className="text">&nbsp;{acknowledgement.acked_label}</span>
    </div>
  );
};

const unackedDiv = (
  contentId,
  acked,
  acknowledgement,
  acknowledgeContent,
  prereqsCompleted
) => {
  const handleClick = () => {
    acknowledgeContent(contentId);
  };

  const ackedButtonText = acked
    ? acknowledgement.acked_label
    : acknowledgement.label;

  return (
    <div>
      <Button
        className="button--large button--upcase button--block button--primary pt-theme-button--secondary"
        onClick={handleClick}
        disabled={!prereqsCompleted}
        aria-label={ackedButtonText}
      >
        {ackedButtonText}
      </Button>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  acked: !!contentSelectors.getContentAcknowledgement(state, props)
    .acknowledged_at,
  prereqsCompleted: !!contentSelectors.getContentIsCompleted(state, props),
  acknowledgement: contentSelectors.getContentAcknowledgement(state, props),
});

export default connect(mapStateToProps, {
  acknowledgeContent: contentOperations.acknowledgeContent,
})(ContentActionAcknowledgement);
