import React, { Ref } from 'react';
import cx from 'classnames';
import { Avatar } from '../ui';
import AssistantResponseCard from './assistant-responses';
import AssistantCommandLoading from './assistant-command-loading';
import './assistant.scss';
import { AssistantHistoryItem as IAssistantHistoryItem } from '../../models/assistant/interfaces/AssistantHistoryItem';

interface AssistantHistoryItemProps {
  item: IAssistantHistoryItem;
}

const AssistantHistoryItem = React.forwardRef(
  ({ item }: AssistantHistoryItemProps, ref: Ref<HTMLDivElement>) => {
    const className = cx('assistant__history-item', {
      'assistant__history-item--self': item.author === 'self',
    });

    return (
      <div className={className} ref={ref}>
        <div className="assistant__history-item-response">
          <div className="history-item__avatar-container">
            <Avatar
              className="history-item__avatar"
              src={item.avatar?.url}
              bgColor={item.avatar?.color}
            />
          </div>
          {item.loading ? (
            <AssistantCommandLoading />
          ) : (
            <div className="text">{item.text}</div>
          )}
        </div>
        {item.subject ? (
          <AssistantResponseCard
            response={item.subject}
            analyticsData={item.analyticsData}
            historyItemId={item.id}
          />
        ) : null}
      </div>
    );
  }
);

AssistantHistoryItem.displayName = 'AssistantHistoryItem';

export default AssistantHistoryItem;
