import './assistant.scss';

const AssistantCommandLoading = () => (
  <div className="assistant__command-loading">
    <div className="dot1"></div>
    <div className="dot2"></div>
    <div className="dot3"></div>
  </div>
);

export default AssistantCommandLoading;
