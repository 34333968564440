import './empty-state.scss';

type TopicPageEmptyStateProps = {
  heading: string;
  details: string;
  icon: JSX.Element;
  button?: JSX.Element;
};

export function TopicPageEmptyState({
  heading,
  details,
  icon,
  button,
}: TopicPageEmptyStateProps) {
  return (
    <div className="empty-state" role="status" aria-live="polite">
      {icon && icon}
      <div className="empty-state__text-block">
        <h3 className="empty-state__text-block__heading">{heading}</h3>
        <p className="empty-state__text-block__details">{details}</p>
      </div>
      {button && button}
    </div>
  );
}
