import { Button, Modal } from '../ui';
import './authentication-error-modal.scss';
import { redirectToLogin } from '../../lib/auth';

const AuthenticationErrorModal = () => {
  return (
    <Modal className="authentication-error-modal">
      <div className="authentication-error-modal__title">
        There was an error loading content.
      </div>
      <p className="authentication-error-modal__body">
        <Button theme="link" onClick={() => redirectToLogin()}>
          Please try logging in again.
        </Button>
        <br />
        If problem persists, please contact support.
      </p>
    </Modal>
  );
};

export const ID = 'Overlays.AUTHENTICATION_ERROR_MODAL';

export default AuthenticationErrorModal;
