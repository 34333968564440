import { ContentState, Modifier, SelectionState } from 'draft-js';
import { TagEntityData, TagMetadata } from './types';

function parseMetadata(metadataStr: string): TagMetadata {
  const fields = metadataStr.split('::');
  return fields.reduce((acc, field) => {
    const [key, value] = field.split(':');
    acc[key] = value;
    return acc;
  }, {} as any);
}

function replaceTagsWithEntities(contentState: ContentState): ContentState {
  let currentContent = contentState;
  const matchesToReplace: Array<{
    selection: SelectionState;
    data: TagEntityData;
  }> = [];
  contentState.getBlockMap().forEach((contentBlock) => {
    const regex = /@\{ref:(\w+)::([^}]+)}/gi;
    const text = contentBlock?.getText() || '';
    const key = contentBlock?.getKey();
    if (!key) {
      return;
    }
    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
      const [, refType, metadataStr] = matchArr;
      start = matchArr.index;
      end = start + matchArr[0].length;
      const selection = SelectionState.createEmpty(key).merge({
        anchorOffset: start,
        focusOffset: end,
      });
      matchesToReplace.push({
        selection,
        data: { refType, ...parseMetadata(metadataStr) },
      });
    }
  });
  matchesToReplace.reverse().forEach(({ selection, data }) => {
    currentContent = currentContent.createEntity(
      'REFERENCE',
      'IMMUTABLE',
      data
    );
    const entityKey = currentContent.getLastCreatedEntityKey();
    currentContent = Modifier.replaceText(
      currentContent,
      selection,
      `@${data.name}`,
      undefined,
      entityKey
    );
  });
  return currentContent;
}

export default replaceTagsWithEntities;
