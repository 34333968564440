import types from './types';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...action.advocate,
      };
    case types.SET_ENTITLEMENTS:
      return {
        ...state,
        entitlements: action.entitlements,
      };
    default:
      return state;
  }
};

export default reducer;
