import { useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { contentSelectors } from '../../models/content/index.js';
import { trackContentCardAvatarClick } from '../../models/content/analytics';
import { getContentAuthorProfilePath } from '../../models/content/helpers';

import { Avatar, Link } from '../ui';

import useTruncate from '../../common/use-truncate';

import './content.scss';

const AuthorLink = connect(
  (state, ownProps) => ({
    authorId: contentSelectors.getContentAuthorId(state, ownProps),
  }),
  null
)(({ authorId, onClick, children, disableLink }) =>
  authorId && !disableLink ? (
    <Link
      href={getContentAuthorProfilePath(authorId)}
      onClick={onClick}
      action="push"
    >
      {children}
    </Link>
  ) : (
    children
  )
);

const trackAvatarClick = (contentId, authorId) => {
  trackContentCardAvatarClick(contentId, { author_id: authorId });
};

export const ContentSourceAvatar = connect(
  (state, ownProps) => ({
    authorId: contentSelectors.getContentAuthorId(state, ownProps),
    authorName: contentSelectors.getContentAuthorName(state, ownProps),
    avatarSrc: contentSelectors.getContentAuthorAvatarSrc(state, ownProps),
    avatarColor: contentSelectors.getContentAuthorAvatarColor(state, ownProps),
  }),
  null
)(
  ({
    contentId,
    authorId,
    authorName,
    avatarSrc,
    avatarColor,
    disableLink,
  }) => (
    <div className="content__source-avatar">
      <AuthorLink
        contentId={contentId}
        onClick={() => {
          trackAvatarClick(contentId, authorId);
        }}
        disableLink={disableLink}
      >
        <Avatar
          className="content-info__avatar"
          src={avatarSrc}
          alt={authorName}
          bgColor={avatarColor}
        />
      </AuthorLink>
    </div>
  )
);

export const ContentSourceName = connect(
  (state, ownProps) => ({
    authorId: contentSelectors.getContentAuthorId(state, ownProps),
    authorName: contentSelectors.getContentAuthorName(state, ownProps),
  }),
  null
)(({ contentId, authorName, disableLink, includeBy = true }) => {
  const ref = useRef();
  const { t } = useTranslation();

  useTruncate({ ref, lines: 1 });

  return (
    <div className="content__source-name" ref={ref}>
      {includeBy ? <span>{t('common.by')}</span> : null}{' '}
      <AuthorLink contentId={contentId} disableLink={disableLink}>
        {authorName}
      </AuthorLink>
    </div>
  );
});

export const ContentSourceDate = connect(
  (state, ownProps) => ({
    publishedAt: contentSelectors.getContentPublishedDate(state, ownProps),
  }),
  null
)(({ publishedAt }) => {
  const { t } = useTranslation();

  return (
    publishedAt && (
      <div className="content__source-date">
        <span>{t('common.published')}</span> {publishedAt}
      </div>
    )
  );
});

const ContentSourceInfo = ({ contentId }) => (
  <div className="content__source">
    <ContentSourceAvatar contentId={contentId} />

    <div className="content__source-text">
      <ContentSourceName contentId={contentId} />
      <ContentSourceDate contentId={contentId} />
    </div>
  </div>
);

export default ContentSourceInfo;
