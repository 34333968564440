import cx from 'classnames';
import { KeyboardEvent } from 'react';
import { Avatar } from '../ui';
import { User } from '../../models/advocate/types';

type MentionPickerListItemProps = {
  user: User;
  isSelf: boolean;
  onClick: (mention: string) => void;
  selected: boolean;
};

const MentionPickerListItem = ({
  user,
  isSelf,
  onClick,
  selected,
}: MentionPickerListItemProps) => {
  const className = cx('mention-picker__list-item', {
    'mention-picker__list-item--selected': selected,
  });

  const handleKeyPress = (display_name: string, ev: KeyboardEvent) => {
    if (ev.key === 'Space') {
      onClick(display_name);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={className}
      onClick={() => {
        onClick(`@${user.display_name}`);
      }}
      onKeyDown={(ev) => handleKeyPress(`@${user.display_name}`, ev)}
    >
      <div className="mention-picker__list-item__avatar-container">
        <Avatar
          className="mention-picker__list-item__avatar"
          src={user.avatar.url ?? undefined}
          bgColor={user.avatar.color}
        />
      </div>

      <div className="title">
        {!isSelf ? user.name : `${user.name} (you)`}

        <small>@{user.display_name}</small>
        <small>{user.job_title}</small>
      </div>
    </li>
  );
};

export default MentionPickerListItem;
