import { ChangeEventHandler, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import useFeatureFlag from '../../../common/use-feature-flag';
import {
  PlainEditor,
  PlainEditorProps,
} from '../../../components/ui/plain-editor';
import TextInput, { Textarea } from '../../../components/ui/text-input';
import {
  submissionTitle,
  submissionIsSubmitting,
  submissionIsTitleOverLimit,
} from '../../../models/content-submission/atoms';
import { Feature } from '../../../models/features/features';

type TitleInputProps = ComponentProps<typeof Textarea>;

const MAXIMUM_CHAR_LENGTH = 250;

const trackingContext: PlainEditorProps['trackingContext'] = {
  contentSection: 'title',
};

const TitleInput = (props: TitleInputProps) => {
  const tagUserEnabled = useFeatureFlag(Feature.TAG_USER_ENABLED);

  const [title, setTitle] = useRecoilState(submissionTitle);
  const [, setIsTitleOverLimit] = useRecoilState(submissionIsTitleOverLimit);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setTitle(e.target.value);
  };

  const onTextChange = (text: string) => {
    setTitle(text);
    setIsTitleOverLimit(text.length > MAXIMUM_CHAR_LENGTH);
  };

  if (!tagUserEnabled) {
    return (
      <TextInput
        className="content-submission-input-title"
        name="title"
        value={title || ''}
        placeholder={t('content_submission.field_title')}
        required
        multiline
        maxRows={15}
        maxLength={150}
        isDisabled={isSubmitting}
        onChange={onTextAreaChange}
        {...props}
      />
    );
  }

  return (
    <PlainEditor
      defaultValue={title || ''}
      maximumCharacter={MAXIMUM_CHAR_LENGTH}
      onChange={onTextChange}
      placeholder={t('content_submission.field_title')}
      trackingContext={trackingContext}
    />
  );
};

export default TitleInput;
