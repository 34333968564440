import types from './types';

// Replaces all currently-displayed overlays
const displayOverlay = (id, props, key) => ({
  type: types.DISPLAY_OVERLAY,
  id,
  props,
  key: key || id,
});

const addOverlay = (id, props, key) => ({
  type: types.ADD_OVERLAY,
  id,
  props,
  key: key,
});

const removeOverlay = ({ id, key }) => ({
  type: types.REMOVE_OVERLAY,
  id,
  key,
});

const addFlashMessage = (message) => ({
  type: types.ADD_FLASH_MESSAGE,
  message,
});

const removeFlashMessage = (id) => ({
  type: types.REMOVE_FLASH_MESSAGE,
  id,
});

const setToastMessage = (payload) => ({
  type: types.SET_TOAST_MESSAGE_SHIM,
  payload,
});

const hideHeader = () => ({
  type: types.SET_ITEM,
  key: 'hideHeader',
  value: true,
});

const showHeader = () => ({
  type: types.SET_ITEM,
  key: 'hideHeader',
  value: false,
});

const minimizeHeader = () => ({
  type: types.SET_ITEM,
  key: 'minimizeHeader',
  value: true,
});

const maximizeHeader = () => ({
  type: types.SET_ITEM,
  key: 'minimizeHeader',
  value: false,
});

const logoOnlyHeader = () => ({
  type: types.SET_ITEM,
  key: 'logoOnlyHeader',
  value: true,
});

const defaultHeader = () => ({
  type: types.SET_ITEM,
  key: 'logoOnlyHeader',
  value: false,
});

const showLoading = () => ({
  type: types.SET_ITEM,
  key: 'showLoading',
  value: true,
});

const hideLoading = () => ({
  type: types.SET_ITEM,
  key: 'showLoading',
  value: false,
});

export default {
  displayOverlay,
  addOverlay,
  removeOverlay,
  addFlashMessage,
  removeFlashMessage,
  setToastMessage,
  hideHeader,
  minimizeHeader,
  maximizeHeader,
  showHeader,
  logoOnlyHeader,
  defaultHeader,
  showLoading,
  hideLoading,
};
