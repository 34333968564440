import { useTranslation } from 'react-i18next';

import { Dialog } from '../../components/ui';

type Props = {
  onDiscard: () => void;
  onResume: () => void;
};

const ResumeAutoSaveDialog = ({ onDiscard, onResume }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('content_submission.resume_title')}
      accept={{
        text: t('common.discard'),
        theme: 'danger',
        onClick: onDiscard,
      }}
      cancel={{
        text: t('common.continue'),
        theme: 'secondary',
        onClick: onResume,
      }}
    >
      {t('content_submission.resume_confirm')}
    </Dialog>
  );
};

export const ID = 'Overlays.SUBMISSION_RESUME_AUTO_SAVE_DIALOG';

export default ResumeAutoSaveDialog;
