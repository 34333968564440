import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getStorageItem,
  setStorageItem,
  localStorageEnabled,
} from '../lib/localstorage';
import { assistantSelectors } from '../models/assistant';

const storeEnabled = localStorageEnabled();
const READ_MESSAGES_KEY = '_scp_read_messages';

const writeReadMessagesToStorage = (messages) => {
  setStorageItem(READ_MESSAGES_KEY, messages);
};

const getStoredMessages = () => {
  let result = getStorageItem(READ_MESSAGES_KEY, {});
  if (Array.isArray(result)) {
    // Previously, this used to be stored as an array
    // If we find an array, coerce it to pojo and re-write it
    const now = new Date().toISOString();
    result = result.reduce((accum, value, index) => {
      // Remove nulls
      if (value) {
        if (Number(value)) {
          //was an ID
          accum[value] = now;
        } else {
          //was probably a date. Use index instead and retain the value.
          accum[index] = value;
        }
      }
      return accum;
    }, {});

    writeReadMessagesToStorage(result);
  }

  return result;
};

const removeStoredMessage = (id) => {
  const storedMessages = getStoredMessages();
  delete storedMessages[id];

  writeReadMessagesToStorage(storedMessages);
};

export const markMessageAsRead = (id, updatedAt) => {
  const storedMessages = getStoredMessages();
  storedMessages[id] = updatedAt;

  writeReadMessagesToStorage(storedMessages);
};

const useAssistantMessages = () => {
  const message = useSelector((state) =>
    assistantSelectors.getMessageItem(state)
  );

  const [isRead, setIsRead] = useState(true); // To stop things from opening before load
  const [isDismissed, setIsDismissed] = useState(false);

  const readMessage = useCallback(() => {
    markMessageAsRead(message.id, message.updated_at);
  }, [message]);

  useEffect(() => {
    if (message && message.state) {
      const readMessages = getStoredMessages();

      switch (message.state) {
        case 'active':
          // If the most current message is already read, keep track of that
          if (readMessages[message.id] === message.updated_at) {
            setIsRead(true);
          } else {
            removeStoredMessage(message.id);
            setIsRead(false);
            setIsDismissed(false);
          }
          break;
        case 'opened':
          setIsRead(true);
          readMessage();
          setIsDismissed(false);
          break;
        case 'dismissed':
        case 'inactive':
          setIsRead(true);
          setIsDismissed(true);
          break;
        default:
          break;
      }
    }
  }, [message, readMessage]);

  const dismissMessage = () => {
    removeStoredMessage(message.id);
  };

  return {
    storeEnabled,
    message,
    isRead,
    readMessage,
    isDismissed,
    dismissMessage,
  };
};

export default useAssistantMessages;
