import { ReactElement, useState } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import Dropzone, {
  DropzoneProps,
  DropzoneState,
  ErrorCode,
  FileRejection,
} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fromEvent as getFilesFromEvent } from 'file-selector';

import {
  submissionType,
  submissionImages,
  submissionVideo,
  submissionIsDraft,
  submissionIsEdit,
} from '../../../models/content-submission/atoms';

import {
  trackAddImageAddImageError,
  trackAddVideoAddVideoError,
} from '../../../models/content-submission/analytics';
import { programSelectors } from '../../../models/program';

type DropzoneInputProps = DropzoneProps & {
  maxLength: number;
  children: (
    props: DropzoneState & {
      draggedFilesError?: DroppedFilesError;
      draggedFilesErrorMessage?: string;
      droppedFilesError?: DroppedFilesError;
      droppedFilesErrorMessage?: string;
      fileLimitReached: boolean;
    }
  ) => ReactElement;
};

type DroppedFilesError =
  | {
      code: ErrorCode.FileInvalidType;
    }
  | {
      code: ErrorCode.TooManyFiles;
      meta: { remainingSlotCount: number };
    };

const DropzoneInput = ({
  children,
  maxLength,
  ...props
}: DropzoneInputProps) => {
  const [images, setImages] = useRecoilState(
    submissionImages as unknown as RecoilState<File[]>
  );
  const [video, setVideo] = useRecoilState(submissionVideo);
  const contentType = useRecoilValue(submissionType);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isDraft = useRecoilValue(submissionIsDraft);
  const videoMaxUploadBytes = useSelector(
    programSelectors.getMaxVideoUploadSizeInBytes
  );

  const { t } = useTranslation();

  const [draggedFilesCount, setDraggedFilesCount] = useState(0);
  const [droppedFilesError, setDroppedFilesError] =
    useState<DroppedFilesError>();

  const videoCount = Number(!!video); // videoCount will be 1 if the video state is set, 0 otherwise

  const addedFilesCount = images.length + videoCount;
  const remainingSlotCount = maxLength - addedFilesCount;
  const fileLimitReached = remainingSlotCount <= 0;

  const onDrop = () => {
    setDroppedFilesError(undefined);
  };

  const handleChange = (files: File[]) => {
    const [file] = files;

    if (file.type.match(/video/)) {
      setVideo(file);
    } else {
      setImages([...images, ...files].slice(0, maxLength));
    }
  };

  const handleReject: DropzoneProps['onDropRejected'] = (rejectedFiles) => {
    if (!fileLimitReached) {
      const filesWithoutError: FileRejection[] = [];
      const filesWithError: FileRejection[] = [];

      rejectedFiles.forEach((file) => {
        // If the file only has the `TooManyFiles` error, it is considered "valid" in this context
        if (file.errors.every((e) => e.code === ErrorCode.TooManyFiles)) {
          filesWithoutError.push(file);
        } else {
          filesWithError.push(file);
        }
      });

      const validFiles = filesWithoutError.slice(0, maxLength);
      const exceededFiles = filesWithoutError.slice(maxLength);

      // if there are valid files available in the rejected files,
      // we upload the first available n files
      if (validFiles.length > 0) {
        handleChange(validFiles.map((file) => file.file));
      }

      // inform the user about the missing files as well as the existing invalid files
      rejectedFiles = filesWithError.concat(exceededFiles);
    }

    updateDroppedFileError(rejectedFiles);

    if (contentType === 'video') {
      trackAddVideoAddVideoError();
    } else {
      trackAddImageAddImageError();
    }
  };

  const onDragEnter: DropzoneProps['onDragEnter'] = (event) => {
    getFilesFromEvent(event).then((files) =>
      setDraggedFilesCount(files.length)
    );
  };

  const onDragLeave: DropzoneProps['onDragLeave'] = () => {
    setDraggedFilesCount(0);
  };

  const updateDroppedFileError = (rejectedFiles: FileRejection[]) => {
    const errorCode = rejectedFiles?.[0]?.errors[0]?.code;

    if (!errorCode) {
      setDroppedFilesError(undefined);
      return;
    }

    setDroppedFilesError(() => {
      switch (errorCode) {
        case ErrorCode.TooManyFiles:
          return {
            code: ErrorCode.TooManyFiles,
            meta: { remainingSlotCount },
          };

        default:
          return { code: ErrorCode.FileInvalidType };
      }
    });
  };

  const getDraggedFilesError = (
    isDragReject: boolean
  ): DroppedFilesError | undefined => {
    // Regardless of what react-dropzone indicates, we always return an error if the
    // dragged files exceed the remaining limit
    if (draggedFilesCount > remainingSlotCount) {
      return {
        code: ErrorCode.TooManyFiles,
        meta: { remainingSlotCount },
      };
    }

    // If react-dropzone does not reject the drag and the dragged files
    // do not exceed the limit, then we have no dragged files errors
    if (isDragReject) {
      return { code: ErrorCode.FileInvalidType };
    }

    return undefined;
  };

  const getVideoUploadError = (bytes: number) => {
    const megabytes = bytes / 1_000_000;
    const gigabytes = megabytes / 1000;

    return megabytes > 1000
      ? t('content_submission.field_video_error.gb', {
          fileSize: parseFloat(gigabytes.toFixed(2)),
        })
      : t('content_submission.field_video_error.mb', {
          fileSize: parseFloat(megabytes.toFixed(2)),
        });
  };

  const getErrorMessage = (error?: DroppedFilesError) => {
    if (!error) return undefined;

    if (error.code === ErrorCode.TooManyFiles) {
      const { remainingSlotCount = 1 } = error.meta ?? {};

      if (remainingSlotCount < 1) {
        return t('content_submission.field_too_many_files_error.none');
      }
      if (remainingSlotCount === 1) {
        return t('content_submission.field_too_many_files_error.singular');
      }
      return t('content_submission.field_too_many_files_error.plural', {
        remainingSlotCount,
      });
    }

    if (contentType === 'video' && !video) {
      return getVideoUploadError(Number(videoMaxUploadBytes));
    }
    return t('content_submission.field_image_error');
  };

  return (
    <Dropzone
      disabled={isEdit && !isDraft}
      onDrop={onDrop}
      onDropAccepted={handleChange}
      onDropRejected={handleReject}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      maxFiles={remainingSlotCount}
      {...props}
    >
      {(dzProps) => {
        const draggedFilesError = getDraggedFilesError(dzProps.isDragReject);

        return children({
          ...dzProps,
          draggedFilesError,
          draggedFilesErrorMessage: getErrorMessage(draggedFilesError),
          droppedFilesError,
          droppedFilesErrorMessage: getErrorMessage(droppedFilesError),
          fileLimitReached,
        });
      }}
    </Dropzone>
  );
};

DropzoneInput.defaultProps = {
  maxLength: 1,
  multiple: false,
};

export default DropzoneInput;
