import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { AssistantResponseContent } from '../../components/assistant/assistant-responses';
import { AssistantBackBar } from '../../components/assistant';

function AssistantContents({ location }: RouteComponentProps) {
  const params = queryString.parse(location.search);
  const contentIds = params.ids ? (params.ids as string).split(',') : [];

  return (
    <div className="assistant__filtered-list">
      <AssistantBackBar />

      <div className="assistant__panel">
        {contentIds && contentIds.length
          ? contentIds.map((contentId) => (
              <AssistantResponseContent
                analyticsData={{ location: 'assistant_contents' }}
                response={{ id: contentId }}
                key={contentId}
              />
            ))
          : null}
      </div>
    </div>
  );
}

export default AssistantContents;
