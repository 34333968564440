import { FC } from 'react';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import { CardProps } from '../../sidebar';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@socialchorus/shared-ui-components';
import { Follower, useMembers } from '../../tabs/MembersTab/hooks';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

function NoMembersPartial() {
  const { t } = useTranslation();
  return (
    <CardPlaceholder>
      {t('screens.topic_page.members_tab.placeholder.text')}
    </CardPlaceholder>
  );
}

interface PreviewProps {
  topicId: string;
  members: Follower[];
  total: number;
}

function Preview({ topicId, members, total }: PreviewProps) {
  const { t } = useTranslation();
  if (!members?.length) {
    return <NoMembersPartial />;
  }

  return (
    <div className={styles.avatarList}>
      {members.map((member) => (
        <div key={member.id} className={styles.avatarItem}>
          <Link
            to={`/profiles/${member.id}`}
            title={member.name}
            className={styles.avatarImg}
          >
            <Avatar
              alt={member.name}
              size="medium"
              shape="circle"
              imgSrc={member.avatarUrl}
            />
          </Link>
        </div>
      ))}
      {total > members.length && (
        <CardLink
          label={t('screens.topic_page.sidebar.members_preview.more', {
            count: total - members.length,
          })}
          to={`/channels/${topicId}/members`}
          className={styles.moreLink}
        />
      )}
    </div>
  );
}

const MembersPreview: FC<CardProps> = ({ topicId }: { topicId: string }) => {
  const { t } = useTranslation();
  const { data, total, isLoading } = useMembers(topicId, 6);
  if (isLoading) {
    return null;
  }

  return (
    <PreviewCard
      title={t('screens.topic_page.sidebar.members_preview.title')}
      link={
        <CardLink
          label={t('screens.topic_page.sidebar.members_preview.view_all')}
          to={`/channels/${topicId}/members`}
        />
      }
    >
      <Preview topicId={topicId} members={data} total={total} />
    </PreviewCard>
  );
};
export default MembersPreview;
