import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/ui';
import ViewTrigger from '../../components/view-trigger';
import useEnterpriseSearch from '../../common/use-enterprise-search';
import EndOfFeed from '../../components/feed/end-of-feed';
import EmptyFeed from '../../components/feed/empty-feed';
import { trackSearchSuccess, trackSearchError } from '../../models/analytics';
import { localizeDate } from '../../lib/date-formatter';

import { programSelectors } from '../../models/program';

import { SearchResults } from './search';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../common/use-skip-to-content';

/**
 * @param {SearchResult} item
 */
function SearchResult({ item }) {
  return (
    <a
      className="enterprise-search-item"
      href={item.action.navigation.url}
      target="_blank"
      rel="noreferrer"
    >
      {item.image ? (
        <div
          className="item-image"
          role="img"
          aria-label={item.title}
          style={{ backgroundImage: `url(${item.image})` }}
        />
      ) : null}
      <div>{item.title}</div>
      <div className="item-details">
        {item.author ? <strong>{item.author}</strong> : null}
        {item.date ? (item.author ? ', ' : '') + localizeDate(item.date) : null}
      </div>
      <p>{item.snippet}</p>
    </a>
  );
}

class SearchAuthentication extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    if (this.cbWindow) {
      window.removeEventListener('message', this.handleCallback, false);
    }
  }

  handleCallback = (e) => {
    if (!e.data || e.data.callback_key !== this.props.data.key) {
      return;
    }
    const { success } = e.data;
    //Callback resulted in success
    if (success) {
      this.props.onSuccess();
    } else if (this.props.onError) {
      this.props.onError();
    }
    this.cbWindow.close();
    window.removeEventListener('message', this.handleCallback, false);
    delete this.cbWindow;
  };

  handleClick = () => {
    window.addEventListener('message', this.handleCallback, false);
    this.cbWindow = window.open(this.props.data.url);
  };

  render() {
    const { data } = this.props;
    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (['Enter', 'Spacebar', ' '].indexOf(e.key) !== -1) {
            e.stopPropagation();
            this.handleClick(e);
          }
        }}
        onClick={this.handleClick}
        className="enterprise-search-item"
      >
        <img
          className="item-image"
          src="/images/search_auth_required.png"
          alt=""
        />
        <div>{data.label}</div>
        <p>{data.message}</p>
      </div>
    );
  }
}

SearchAuthentication.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired, //Always equal 'auth'
    message: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

const SearchEnterpriseStandard = ({ term, searchParams }) => {
  const { integrationId, command } = searchParams;
  const {
    result,
    items,
    isFetching,
    isError,
    canLoadMore,
    onReload,
    onLoadMore,
  } = useEnterpriseSearch({ integrationId, command, query: term });
  const analyticsData = {
    location: 'search_sharepoint',
    searchType: 'sharepoint',
    searchTerm: term,
    integrationId,
    command,
  };

  useEffect(() => {
    if (!isError) {
      trackSearchSuccess(analyticsData);
    } else {
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  if (isError) {
    return <SearchEnterpriseError term={term} />;
  }

  if (!result) {
    return <Spinner center />;
  }

  let content = null;
  switch (result.type) {
    case 'result': {
      content = items.map((item, index) => (
        <SearchResult key={index} item={item} />
      ));
      break;
    }
    case 'auth': {
      if (!isFetching) {
        content = <SearchAuthentication data={result} onSuccess={onReload} />;
      }
      break;
    }
    case 'error': {
      if (!isFetching) {
        content = (
          <SearchEnterpriseError term={term} message={result.message} />
        );
      }
      break;
    }
  }

  return (
    <section className="enterprise-search" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        {content}
        {isFetching ? (
          <Spinner center={!result} />
        ) : canLoadMore ? (
          <ViewTrigger key={items.length} onInview={onLoadMore} />
        ) : items.length ? (
          <EndOfFeed />
        ) : (
          result?.type === 'result' && <EmptyFeed />
        )}
      </div>
    </section>
  );
};

const SearchEnterpriseSearchHeader = ({ term, searchParams }) => {
  const { integrationId, command } = searchParams;
  const {
    result,
    items,
    isFetching,
    isError,
    canLoadMore,
    onReload,
    onLoadMore,
  } = useEnterpriseSearch({ integrationId, command, query: term });
  const analyticsData = {
    location: 'search_sharepoint',
    searchType: 'sharepoint',
    searchTerm: term,
    integrationId,
    command,
  };

  useEffect(() => {
    if (!isError) {
      trackSearchSuccess(analyticsData);
    } else {
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  if (isError) {
    return <SearchEnterpriseError term={term} />;
  }

  if (!result) {
    return <Spinner center />;
  }

  let content = null;
  switch (result.type) {
    case 'result': {
      content = items.map((item, index) => (
        <SearchResult key={index} item={item} />
      ));
      break;
    }
    case 'auth': {
      if (!isFetching) {
        content = <SearchAuthentication data={result} onSuccess={onReload} />;
      }
      break;
    }
    case 'error': {
      if (!isFetching) {
        content = (
          <SearchEnterpriseError term={term} message={result.message} />
        );
      }
      break;
    }
  }

  // result.total is the total number of results, not all services provide it
  const resultsCount = result?.type === 'result' ? result.total : null;

  return (
    <section className="enterprise-search" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      {!isFetching && resultsCount ? (
        <div className="search-component__header">
          <SearchResults count={resultsCount} />
        </div>
      ) : null}
      <div className="container container--slim">
        {content}
        {isFetching ? (
          <Spinner center={!result} />
        ) : canLoadMore ? (
          <ViewTrigger key={items.length} onInview={onLoadMore} />
        ) : items.length ? (
          <EndOfFeed />
        ) : (
          result?.type === 'result' && <EmptyFeed />
        )}
      </div>
      {/* If result successful, but no results returned, then show "Try later" message */}
      {!isFetching && !items.length && result.type === 'result' ? (
        <div className="search-component__footer">
          <SearchResults count={items.length} />
        </div>
      ) : null}
    </section>
  );
};

const SearchEnterpriseError = ({ term, message }) => {
  const { t } = useTranslation();

  return (
    <section className="enterprise-search" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        <div className="error">
          {message || t('assistant.error_searching', { term })}
        </div>
      </div>
    </section>
  );
};

const SearchEnterprise = ({ term, searchBarInHeaderEnabled, searchParams }) => {
  return searchBarInHeaderEnabled ? (
    <SearchEnterpriseSearchHeader term={term} searchParams={searchParams} />
  ) : (
    <SearchEnterpriseStandard term={term} searchParams={searchParams} />
  );
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps, null)(SearchEnterprise);
