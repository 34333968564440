import { matchPath } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { deepLinkRedirectMap, EXIT_DEEPLINK } = require('../constant');

const getDeepLinkRedirect = (patronPath: string) => {
  const patronPaths = Object.keys(deepLinkRedirectMap);

  // Loop through redirect paths until we find a match
  const found = patronPaths.find((e) =>
    matchPath(patronPath, { path: e, exact: true })
  );

  if (found) {
    let deeplinkPath = deepLinkRedirectMap[found];

    const match = matchPath(patronPath, {
      path: found,
      exact: true,
    });

    const params: Record<string, string> = match?.params || {};

    // Copy params from patron url into equivalent deeplink url
    Object.keys(params).forEach((param) => {
      deeplinkPath = deeplinkPath.replace(`:${param}`, params[param]);
    });
    deeplinkPath = deeplinkPath.replace('/**', '');
    return deeplinkPath.substring(1);
  }
};

// Calculates the deeplink based on the patron path and adds the protocol, program and org_slug so that
// the url is ready to be used
const getDeeplinkUrl = (path: string, programId: string, orgSlug: string) => {
  const deeplinkPath = getDeepLinkRedirect(path);
  if (deeplinkPath) {
    return `sc://${deeplinkPath}?program=${programId}&org_slug=${orgSlug}`;
  }
};

export {
  deepLinkRedirectMap,
  getDeepLinkRedirect,
  getDeeplinkUrl,
  EXIT_DEEPLINK,
};
