import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useChannelFeed from '../../common/use-channel-feed';
import { channelOperations } from '../../models/channels';
import { programSelectors } from '../../models/program';

import { Spinner } from '../../components/ui';
import ChannelList from '../../components/channel-list';
import ViewTrigger from '../../components/view-trigger';
import EndOfFeed from '../../components/feed/end-of-feed';
import EmptyFeed from '../../components/feed/empty-feed';

import { trackSearchSuccess, trackSearchError } from '../../models/analytics';
import { SearchResults } from './search';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const SearchChannelsDefault = ({ term }) => {
  const { channels, page, isFetching, isError, canLoadMore, onLoadMore } =
    useChannelFeed({ search: term });
  const dispatch = useDispatch();

  const analyticsData = {
    location: 'search_channels',
    searchType: 'channels',
    searchTerm: term,
  };

  const channelIds = channels.map((c) => c.id);

  useEffect(() => {
    if (!isError) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  useEffect(() => {
    dispatch(channelOperations.setChannels(channels));
  }, [channels]);

  if (isError) return <SearchChannelsError term={term} />;

  return (
    <section className="search-channels" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        <>
          {page ? (
            <ChannelList
              channelIds={channelIds}
              direction="vertical"
              analyticsData={analyticsData}
              key={term}
            />
          ) : null}

          <div className="flex-break" />

          {isFetching ? (
            <Spinner center={!channels.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={onLoadMore} />
          ) : channels.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </>
      </div>
    </section>
  );
};

const SearchChannelsSearchHeader = ({ term }) => {
  const { t } = useTranslation();
  const { channels, page, isFetching, isError, canLoadMore, onLoadMore } =
    useChannelFeed({ search: term });
  const dispatch = useDispatch();

  const analyticsData = {
    location: 'search_channels',
    searchType: 'channels',
    searchTerm: term,
  };

  const channelIds = channels.map((c) => c.id);

  useEffect(() => {
    if (!isError) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  useEffect(() => {
    dispatch(channelOperations.setChannels(channels));
  }, [channels]);

  const resultsCount = channels.length; // pagination not supported on API so total is just all responses.

  if (isError) return <SearchChannelsError term={term} />;

  return (
    <section className="search-channels" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      {!isFetching && resultsCount ? (
        <div className="search-component__header">
          <SearchResults count={resultsCount} />
        </div>
      ) : null}
      <div className="container container--slim">
        <>
          {page ? (
            <ChannelList
              channelIds={channelIds}
              direction="vertical"
              analyticsData={analyticsData}
              key={term}
            />
          ) : null}

          <div className="flex-break" />

          {isFetching ? (
            <Spinner center={!channels.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={onLoadMore} />
          ) : channels.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </>
        {!isFetching && resultsCount == 0 ? (
          <div className="search-component__footer">
            <SearchResults count={resultsCount} />
          </div>
        ) : null}
      </div>
    </section>
  );
};

const SearchChannels = ({ term, searchBarInHeaderEnabled }) => {
  return searchBarInHeaderEnabled ? (
    <SearchChannelsSearchHeader term={term} />
  ) : (
    <SearchChannelsDefault term={term} />
  );
};

const SearchChannelsError = ({ term }) => {
  const { t } = useTranslation();

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const seachingChannelsErrorString = newTopicsString
    ? 'assistant.error_searching_topics'
    : 'assistant.error_searching_channels';

  return (
    <section className="search-people" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        <div className="error">{t(seachingChannelsErrorString, { term })}</div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps, null)(SearchChannels);
