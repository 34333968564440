import React, { useMemo } from 'react';
import Autolinker from 'autolinker';
import { Link } from '.';
import { mentionRegex } from '../../common/use-plaintext-mentions';

type ParsedMentionElement = React.ReactElement | string;

interface MentionProps {
  text: string;
}

const linker = new Autolinker({
  className: 'content__title-link',
  phone: false,
  mention: false,
  hashtag: false,
  sanitizeHtml: true,
});

const parseMentions = (text: string): ParsedMentionElement[] => {
  const chunks: ParsedMentionElement[] = [];
  let lastIndex = 0;

  text.replace(mentionRegex, (match, userId, userName, index) => {
    // Add the text from the last index to the start of the mention as HTML
    const rawText = text.slice(lastIndex, index);
    if (rawText) {
      chunks.push(rawText);
    }
    // Add the Link component for the mention
    chunks.push(
      <Link
        key={userId}
        href={`/profiles/${userId}`}
        action="push"
        className="mention"
      >
        @{userName}
      </Link>
    );

    // Update the last index to be the end of the mention
    lastIndex = index + match.length;
    return match; // This is required for the replace function, but isn't used
  });

  // Add the text after the last mention as HTML
  if (lastIndex < text.length) {
    chunks.push(text.slice(lastIndex));
  }

  return chunks;
};

//DOES NOT HANDLE SANITIZING OF THE TEXT
//this could be added to the auto-linker, but keeping scope small for now. this was always trusting the text coming in.
export const DangerousHTMLWithMentionsAndAutolinks: React.FC<MentionProps> = ({
  text,
}) => {
  const parsedMentions = useMemo(() => {
    return parseMentions(text).map((chunk, index) =>
      typeof chunk === 'string' ? (
        <span
          key={index}
          dangerouslySetInnerHTML={{ __html: linker.link(chunk) }}
        />
      ) : (
        chunk
      )
    );
  }, [text]);

  return <>{parsedMentions}</>;
};

//does handles santitzing of the text
export const TextWithMentions: React.FC<MentionProps> = ({ text }) => {
  const parsedMentions = useMemo(() => {
    return parseMentions(text).map((chunk, index) =>
      typeof chunk === 'string' ? <span key={index}>{chunk}</span> : chunk
    );
  }, [text]);

  return <>{parsedMentions}</>;
};
