import { createSelector } from 'reselect';

const appConfig = (state) => state.appConfig;

export const getProgramPath = createSelector(
  [appConfig],
  (config) => config.program_path
);

export const getSegmentKey = createSelector(
  [appConfig],
  (config) => config.segment_key
);

export const getFacebookKey = createSelector(
  [appConfig],
  (config) => config.fb_key
);

export default {
  getProgramPath,
  getSegmentKey,
  getFacebookKey,
};
