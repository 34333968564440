const addFlashMessage = (state, message) => ({
  ...state,
  flashMessages: [
    ...state.flashMessages,
    { ...message, timestamp: new Date().getTime() },
  ],
});

const removeLastFlashMessage = (state) => ({
  ...state,
  flashMessages: state.flashMessages.slice(1),
});

const addOverlay = (state, id, key, props) => ({
  ...state,
  overlays: [
    ...(state.overlays.find((o) => o.key === key)
      ? removeOverlay(state, key).overlays
      : state.overlays),
    { id, key, props },
  ],
});

const replaceOverlay = (state, id, key, props) => ({
  ...state,
  overlays: [{ id, props, key }],
});

const removeOverlay = (state, { key, id }) => {
  return {
    ...state,
    overlays: state.overlays.filter(
      (overlay) => overlay.key !== key && overlay.id !== id
    ),
  };
};

export default {
  addFlashMessage,
  removeLastFlashMessage,
  replaceOverlay,
  addOverlay,
  removeOverlay,
};
