import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { CommentAssistantSuggestionSubtype } from '../../../../../models/comments/types';
import { CommentAssistantMenuItem } from './menu-item';

const SUBMENU_ITEMS: CommentAssistantSuggestionSubtype[] = [
  'professional',
  'casual',
  'straightforward',
  'confident',
  'friendly',
];

type CommentAssistantSubmenuProps = {
  onSelected: (name: CommentAssistantSuggestionSubtype) => void;
};

export function CommentAssistantDropdownSubmenuContent({
  onSelected,
}: CommentAssistantSubmenuProps) {
  return (
    <DropdownMenu.SubContent
      className={cx(
        'comment-assistant-dropdown',
        'comment-assistant-menu__list',
        'comment-assistant-menu__sublist'
      )}
    >
      {SUBMENU_ITEMS.map((submenu) => {
        return (
          <DropdownMenu.Item
            key={submenu}
            className="comment-assistant-menu__list-item__wrapper"
          >
            <CommentAssistantMenuItem
              name={submenu}
              onItemSelected={onSelected}
            />
          </DropdownMenu.Item>
        );
      })}
    </DropdownMenu.SubContent>
  );
}

export function CommentAssistantSubmenuContent({
  onSelected,
}: CommentAssistantSubmenuProps) {
  return (
    <ul
      className={cx(
        'comment-assistant-menu__list',
        'comment-assistant-menu__sublist'
      )}
    >
      {SUBMENU_ITEMS.map((submenu) => {
        return (
          <li key={submenu}>
            <CommentAssistantMenuItem
              name={submenu}
              onItemSelected={onSelected}
            />
          </li>
        );
      })}
    </ul>
  );
}
