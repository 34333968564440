import types from './types';

import advocateSelectors from './selectors';
import AdvocateService from '../../services/advocate';

const setAdvocate = (advocate) => ({
  type: types.SET,
  advocate,
});

const setAdvocateEntitlements = (entitlements) => ({
  type: types.SET_ENTITLEMENTS,
  entitlements,
});

const syncAdvocate = () => (dispatch, getState) => {
  const advocateId = advocateSelectors.getAdvocateId(getState());

  return new AdvocateService(advocateId).fetch().then((res) => {
    dispatch(setAdvocate(res.data.data.profile));
    return res;
  });
};

export default {
  setAdvocate,
  setAdvocateEntitlements,
  syncAdvocate,
};
