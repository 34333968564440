import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentSelectors } from '../../../models/content/index.js';
import { programSelectors } from '../../../models/program';

import { Icon, Link } from '../../../components/ui';

import ContentDetailArticle from '../../../screens/content-details/content-detail/content-detail-article';
import ContentDetailImage from '../../../screens/content-details/content-detail/content-detail-image';
import ContentDetailVideo from '../../../screens/content-details/content-detail/content-detail-video';
import ContentDetailDefault from '../../../screens/content-details/content-detail/content-detail-default';

import ContentComments from '../comments/content-comments';
import './content-detail.scss';
import { InfoHeader } from './info-header';
import { InfoFooter } from './info-footer';
import { useRouteMatchOneOf } from '../../../common/use-route-match-one-of';
import { contentCommentsRoutePaths } from '../../../patron-routes-constants';
import { RelatedContentsWidget } from './related-contents/related-contents-widget';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';

interface ContentDetailProps {
  contentId: number;
  navigateBack: () => void;
  emailContent?: boolean;
}

export const ContentDetail: React.FC<ContentDetailProps> = ({
  contentId,
  navigateBack,
  emailContent = false,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const contentType = usePatronSelector((state) =>
    contentSelectors.getContentType(state, { contentId })
  );
  const relatedContentEnabled = usePatronSelector((state) =>
    programSelectors.getRelatedContentEnabled(state)
  );
  const acknowledgementActive = usePatronSelector((state) =>
    contentSelectors.getContentCanAcknowledge(state, { contentId })
  );
  const contentCanTranslate = usePatronSelector((state) =>
    contentSelectors.getContentCanTranslate(state, { contentId })
  );
  const contentLocale = usePatronSelector((state) =>
    contentSelectors.getContentLang(state, { contentId })
  );

  const backButtonDisabled = useFeatureFlag(
    Feature.CONTENT_BACK_BUTTON_REMOVED
  );

  const [isContentResized, setIsContentResized] = useState(false);
  const [isRelatedContentReady, setIsRelatedContentReady] = useState(false);

  const commentsRef = useRef<HTMLDivElement>(null);
  const commentPathMatch = useRouteMatchOneOf(contentCommentsRoutePaths);
  const hasCommentHash = location.hash === '#comments';
  const shouldScrollToComments = commentPathMatch || hasCommentHash;

  useEffect(() => {
    if (
      !isContentResized ||
      (relatedContentEnabled && !isRelatedContentReady) ||
      !shouldScrollToComments ||
      !commentsRef.current
    ) {
      return;
    }

    const commentTop = commentsRef.current.getBoundingClientRect().top;

    const headerCSSHeight = getComputedStyle(
      document.documentElement
    ).getPropertyValue(
      backButtonDisabled ? '--header-height' : '--header-height-min'
    );
    const headerHeight = parseInt(headerCSSHeight, 10) || 0;

    const offsetY = commentTop - headerHeight - 15;

    window.scrollBy({ top: offsetY, behavior: 'smooth' });

    if (hasCommentHash) {
      history.replace({ ...location, hash: '' });
    }
  }, [
    isContentResized,
    isRelatedContentReady,
    location,
    commentPathMatch,
    history,
    contentId,
    shouldScrollToComments,
    hasCommentHash,
  ]);

  const CONTENT_TYPE_MAP = {
    article: ContentDetailArticle,
    image: ContentDetailImage,
    video: ContentDetailVideo,
    note: ContentDetailDefault,
  };

  const ContentComponent =
    CONTENT_TYPE_MAP[contentType as keyof typeof CONTENT_TYPE_MAP] ||
    ContentDetailDefault;
  const classType = contentType || 'default';

  const classNameContainer = cx('responsive-content-page', {
    'tall-footer': acknowledgementActive && !emailContent,
    'full-nav-bar': backButtonDisabled,
  });
  const classNameDetail = cx(
    'content-detail-v2',
    `content-detail-v2--${classType}`,
    { 'content-detail-v2--email': emailContent }
  );

  const analyticsData = {
    location: 'content_info',
  };
  const reactionsDisabled = useFeatureFlag(Feature.REACTIONS_DISABLED);

  return (
    <div className={classNameContainer}>
      {!backButtonDisabled && (
        <Link
          className="content-detail__link--back"
          onClick={navigateBack}
          aria-label={t('common.back')}
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (['Enter', 'Spacebar', ' '].indexOf(e.key) !== -1) {
              e.stopPropagation();
              navigateBack();
            }
          }}
        >
          <Icon type="arrow_back" />
        </Link>
      )}

      <InfoHeader
        contentType={contentType}
        contentId={contentId}
        emailContent={emailContent}
        analyticsData={analyticsData}
      />

      <span className={'content-divider'} />
      <article tabIndex={-1} className={classNameDetail}>
        <ContentComponent
          contentId={contentId}
          analyticsData={{ location: 'content_detail' }}
          onResize={() => setIsContentResized(true)}
          locale={contentCanTranslate ? contentLocale : undefined}
        />
      </article>

      {relatedContentEnabled && (
        <RelatedContentsWidget
          content_id={contentId}
          onReady={() => setIsRelatedContentReady(true)}
        />
      )}

      <ContentComments ref={commentsRef} contentId={contentId} />

      {!reactionsDisabled && (
        <InfoFooter
          contentId={contentId}
          emailContent={emailContent}
          analyticsData={analyticsData}
        />
      )}
    </div>
  );
};
