import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  submissionType,
  submissionIsSubmitting,
  useInitializeSubmissionState,
} from '../../models/content-submission/atoms';
import useHeaderMinimizer from '../../common/use-header-minimizer';
import { Link, Icon } from '../../components/ui';
import Page, { PageMain, PageSidebar } from '../../components/ui/page';
import Form from './form';
import LinkForm from './forms/link';
import ImageForm from './forms/image';
import NoteForm from './forms/note';
import VideoForm from './forms/video';
import ArticleForm from './forms/article';
import Sidebar from './sidebar';
import {
  trackAddLinkCancel,
  trackAddImageCancel,
  trackAddVideoCancel,
  trackAddNoteCancel,
  trackAddArticleCancel,
} from '../../models/content-submission/analytics';
import './content-submission.scss';
import useFeatureFlag from '../../common/use-feature-flag';
import { Feature } from '../../models/features/features';
import { ContentType } from '../../models/content/types';
import { JSXElementConstructor, useEffect } from 'react';
import { ContentSubmissionDTO } from '../../models/content-submission/types';

const FORM_COMPONENT_MAP: Record<
  ContentType,
  JSXElementConstructor<Record<string, unknown>>
> = {
  link: LinkForm,
  image: ImageForm,
  note: NoteForm,
  video: VideoForm,
  article: ArticleForm,
  poll: () => null,
};

type BackButtonProps = {
  onClick: () => void;
};

const BackButton = ({ onClick }: BackButtonProps) => {
  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();
  const classnames = cx({
    'content-submission__link--back-legacy': !newUIEnabled,
    'content-submission__link--back': newUIEnabled,
    'content-submission__link--disabled': isSubmitting,
  });

  return (
    <Link
      className={classnames}
      onClick={onClick}
      aria-label={t('common.back')}
      tabIndex={0}
      onKeyDown={(e) => {
        if (['Enter', 'Spacebar', ' '].indexOf(e.key) !== -1) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <Icon type="arrow_back" />
    </Link>
  );
};

type ContentSubmissionProps = {
  contentType: ContentType;
  initialState: ContentSubmissionDTO | undefined;
};
const ContentSubmission = ({
  contentType,
  initialState,
}: ContentSubmissionProps) => {
  useHeaderMinimizer(true);
  const { t } = useTranslation();
  const history = useHistory();

  const { initialized } = useInitializeSubmissionState(
    initialState || new Map([[submissionType, contentType]])
  );

  const handleCancelClick = () => {
    history.goBack();

    if (contentType === 'link') trackAddLinkCancel();
    if (contentType === 'image') trackAddImageCancel();
    if (contentType === 'video') trackAddVideoCancel();
    if (contentType === 'note') trackAddNoteCancel();
    if (contentType === 'article') trackAddArticleCancel();
  };

  const FormComponent = FORM_COMPONENT_MAP[contentType];

  const className = cx(
    'content-submission',
    `content-submission--${contentType}`
  );

  if (!initialized) return null;

  return (
    <FocusLock>
      <div className={'content-submission-overlay-backdrop'} />

      <Form>
        {({ onSubmit }) => (
          <Page className={className}>
            <BackButton onClick={handleCancelClick} />
            <PageMain aria-label={t('content_submission.main_area')}>
              <FormComponent />
            </PageMain>

            <PageSidebar aria-label={t('content_submission.sidebar_area')}>
              <Sidebar onSubmit={onSubmit} />
            </PageSidebar>
          </Page>
        )}
      </Form>
    </FocusLock>
  );
};

export default ContentSubmission;
