import React from 'react';
import styled from 'styled-components';
import { Item } from '../models';

const StyledThumbnail = styled.div<{ playicon_size?: number }>`
  position: relative;
  .thumbnail_image {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-size: ${(props) => props.playicon_size}px;
    line-height: 1;
    text-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.1s ease 0s;
    &::after {
      content: '';
      position: absolute;
      top: ${(props) => props.playicon_size! * 0.1375}px;
      left: ${(props) => props.playicon_size! * 0.1375}px;
      height: ${(props) => props.playicon_size! * 0.75}px;
      width: ${(props) => props.playicon_size! * 0.75}px;
      z-index: -1;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
    }
  }
`;

interface ThumbnailProps {
  item: Item;
  playicon_size?: number;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ item, playicon_size = 40 }) => {
  return (
    <StyledThumbnail
      className="image"
      role="img"
      aria-label={item.title ?? item.summary ?? 'No Description'}
      tabIndex={0}
      playicon_size={playicon_size}
    >
      <div
        className="thumbnail_image"
        style={{ backgroundImage: `url(${item.image})` }}
      />
      {item.content_type === 'video' && (
        <span className="material-icons play">play_circle_outline</span>
      )}
    </StyledThumbnail>
  );
};

export default Thumbnail;
