import { Button } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { trackCommentEvent } from '../../../../../models/comments/analytics';
import { Comment } from '../../../../../models/comments/types';

type CommentActionReplyProps = {
  comment: Comment;
  onClick: (comment: Comment) => void;
};

export function CommentActionReply({
  comment,
  onClick,
}: CommentActionReplyProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(comment);

    trackCommentEvent('replyClick', comment);
  };

  return (
    <Button
      size="compact"
      variant="text"
      label={t('comments.actions.reply')}
      onClick={handleClick}
    />
  );
}
