import React from 'react';
import cx from 'classnames';

interface AssistantContainerProps {
  children: React.ReactNode;
  flex?: boolean;
  className?: string;
}

export default function AssistantContainer({
  children,
  flex = false,
  className,
}: AssistantContainerProps) {
  const classNames = cx(className, {
    assistant__container: !flex,
    'assistant__flex-container': flex,
  });

  return <ul className={classNames}>{children}</ul>;
}
