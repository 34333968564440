import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AdvocateService from '../services/advocate';
import { channelOperations } from '../models/channels';

const useProfileChannelsFetcher = ({ profileId }) => {
  const [channels, setChannels] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const advocateService = new AdvocateService(profileId);
    const addChannelsToStore = (channels) =>
      dispatch(channelOperations.setChannels(channels));

    const fetchProfileChannels = async () => {
      try {
        const res = await advocateService.fetchChannels();
        handleFetchSuccess(res);
      } catch (err) {
        if (err.response) {
          handleFetchFailure(err);
        } else {
          throw err;
        }
      }
    };

    const handleFetchSuccess = (res) => {
      const channels = res.data.content_channels.filter((c) => !c.default);

      addChannelsToStore(channels);

      setChannels(channels);
      setIsFetching(false);
    };

    const handleFetchFailure = () => {
      setIsError(true);
      setIsFetching(false);
    };

    fetchProfileChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return {
    channels,
    isFetching,
    isError,
  };
};

export default useProfileChannelsFetcher;
