import { useTranslation } from 'react-i18next';
import { timeAgoOrDate } from '../../../../lib/date-formatter';
import { ChannelStat } from '../../../../models/channels/types';

type ChannelStatsProps = Partial<ChannelStat>;

const ChannelStats = ({
  postsCount,
  postsPerWeek,
  lastUpdated,
}: ChannelStatsProps) => {
  const { t } = useTranslation();

  const renderStat = (label: string, value?: string | number) => (
    <div className="channel-details-v2__stat">
      <dt className="channel-details-v2__stat-title">{label}</dt>
      <dd className="channel-details-v2__stat-body">{value}</dd>
    </div>
  );

  return (
    <div className="channel-details-v2__stats">
      <dl className="channel-details-v2__stats-list">
        {renderStat(t('channel_details.all_posts'), postsCount)}
        {renderStat(t('channel_details.posts_per_week'), postsPerWeek)}
        {lastUpdated &&
          renderStat(
            t('channel_details.last_updated'),
            timeAgoOrDate(lastUpdated)
          )}
      </dl>
    </div>
  );
};

export default ChannelStats;
