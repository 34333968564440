import { Container } from '@socialchorus/shared-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTab } from '../BaseTab';
import { useTopicShortcutsFetcher } from '../../../../../common/use-topic-shortcuts-fetcher';
import { ChannelTabType } from '../../../../../models/channels/types';
import { Spinner } from '../../../../ui';
import EmptyPlaceholder from '../../common/EmptyPlaceholder';
import { TopicPageShortcut } from './shortcut';
import styles from './styles.module.scss';

interface Props {
  topicId: string;
}

const ShortcutsTab: FC<Props> = ({ topicId }) => {
  const { t } = useTranslation();
  const { shortcuts, isError, isFetching } = useTopicShortcutsFetcher(topicId);

  return (
    <BaseTab currentTab={ChannelTabType.Shortcuts} topicId={topicId}>
      {isError && !isFetching && (
        <Container className={styles.Container} shadow="extra-light" fullWidth>
          {t('screens.topics.shortcuts.error')}
        </Container>
      )}
      {isFetching && <Spinner />}
      {!isFetching &&
        (shortcuts?.length ? (
          <Container className={styles.Container} shadow="extra-light">
            <span className="text-body text-black-60">
              {t('screens.topics.shortcuts.header', {
                count: shortcuts.length,
              })}
            </span>
            <div className={styles.ShortcutsGrid}>
              {shortcuts.map((shortcut) => (
                <TopicPageShortcut shortcut={shortcut} key={shortcut.id} />
              ))}
            </div>
          </Container>
        ) : (
          <EmptyPlaceholder
            title={t('screens.topics.shortcuts.empty.title')}
            description={t('screens.topics.shortcuts.empty.description')}
          />
        ))}
    </BaseTab>
  );
};

export default ShortcutsTab;
