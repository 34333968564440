import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from '../../../../ui';
import { RelatedChannel } from '../models';
import Follow from './follow';
import { TrackableElement } from './view-tracker';

const StyledLayout = styled.div`
  padding: 20px;
  .moreFrom {
    font-size: 0.7rem;
    color: #999;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .channelHeader {
    display: flex;
    > a.channelName {
      color: black;
      word-break: initial;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      text-decoration: underline;
      border: none;
      background: transparent;
      padding-left: 0;
    }

    > a:hover {
      text-decoration: none;
    }
  }
  @media screen and (min-width: 500px) {
    .channelHeader {
      > a.channelName {
        font-size: 32px;
      }
    }
  }
`;

interface ChannelHeaderProps {
  item: RelatedChannel;
  channelLink: string;
  follow: (id: number) => void;
  unfollow: (id: number) => void;
  onOpenChannel: (id: number) => void;
  trackableClassName?: string;
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ item, ...props }) => {
  const { t } = useTranslation();

  return (
    <TrackableElement
      trackingId={item.id}
      trackableClassName={props.trackableClassName}
    >
      <StyledLayout className="content-channel">
        <div className="moreFrom">{t('content.more_from')}</div>
        <div className="channelHeader">
          <Link
            href={props.channelLink}
            action="push"
            className="channelName"
            aria-label={item.name}
            onClick={() => props.onOpenChannel(item.id)}
          >
            {item.name}
          </Link>
          {!item.default && (
            <Follow {...item} {...props} channelName={item.name} />
          )}
        </div>
      </StyledLayout>
    </TrackableElement>
  );
};

export default ChannelHeader;
