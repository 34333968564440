import { Monitor } from './types';

/**
 * Handles detection of whether a video has been completed in a ui sense. Completion means that
 * the user has watched the video past 95%.
 */
export default class VideoMonitor implements Monitor {
  private messageHandler?: (event: MessageEvent) => void;

  constructor(readonly onCompleteCallback: (monitor: VideoMonitor) => void) {}

  attach() {
    this.messageHandler = this.handleMessage.bind(this);
    window.addEventListener('message', this.messageHandler);
  }

  detach() {
    if (this.messageHandler) {
      window.removeEventListener('message', this.messageHandler);
    }
  }

  protected handleMessage(event: MessageEvent) {
    if (event.data.event === 'timeupdate') {
      const p = Math.round(
        (event.data.currentTime / event.data.duration) * 100
      );
      if (p >= 95) this.onCompleteCallback(this);
    }
  }
}
