import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import unescape from 'lodash/unescape';

import {
  submissionArticle,
  submissionChannelIds,
  submissionDescription,
  submissionId,
  submissionImages,
  submissionImageUrls,
  submissionIsCommentable,
  submissionIsShareable,
  submissionIsTranslatable,
  submissionNoteColor,
  submissionPublicationStatus,
  submissionTitle,
  submissionType,
  submissionUrl,
  submissionVideoUrl,
  submissionVideoUuid,
} from '../../models/content-submission/atoms';

import { uiOperations } from '../../models/ui';

import ContentSubmissionService from '../../services/content-submission';

import LoadingOverlay from '../../components/loading-overlay';
import ContentSubmission from './';
import { ID as ContentErrorModalID } from '../../components/content-error-modal/content-error-modal';

const ContentEditScreen = ({
  displayOverlay,
  removeOverlay,
  dispatch, // ignore
  ...props
}) => {
  const contentId = parseInt(props.match.params.id, 10);

  const [initialState, setInitialState] = useState();
  const [contentType, setContentType] = useState();
  const [isError, setIsError] = useState();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchContentSubmission = async () => {
      try {
        const res = await new ContentSubmissionService(contentId).fetch({
          mention_format: 'with_metadata',
        });

        handleFetchSuccess(res);
      } catch (err) {
        handleFetchFailure(err);
      }
    };

    const handleFetchSuccess = (res) => {
      const {
        content_type: type,
        content_channels: channels,
        title,
        summary,
        images,
        videos,
        url,
        body: article,
        display_settings: { message_card_color: noteColor },
        publication_state: publicationStatus,
        is_commentable: isCommentable,
        is_shareable: isShareable,
        is_translatable: isTranslatable,
      } = res.data.attributes;

      if (publicationStatus === 'archived') setIsError(true);

      setContentType(type);

      setInitialState(
        new Map([
          [submissionType, type],
          [submissionId, contentId],
          [submissionChannelIds, channels.map((c) => c.id)],
          [submissionTitle, unescape(title)],
          [submissionDescription, unescape(summary)],
          [
            submissionImageUrls,
            images && images.length ? images.map((image) => image.url) : [],
          ],
          [
            submissionImages,
            images && images.length ? images.map((image) => image) : [],
          ],
          [
            submissionVideoUrl,
            videos && videos.length > 0 && videos[0].uploaded_video_url,
          ],
          [submissionVideoUuid, videos && videos.length && videos[0].uuid],
          [submissionUrl, url],
          [submissionArticle, article],
          [submissionNoteColor, noteColor],
          [submissionPublicationStatus, publicationStatus],
          [submissionIsCommentable, isCommentable],
          [submissionIsShareable, isShareable],
          [submissionIsTranslatable, isTranslatable],
        ])
      );

      setIsFetching(false);
    };

    const handleFetchFailure = (err) => {
      setIsFetching(false);
      setIsError(true);
    };

    fetchContentSubmission(contentId);
  }, [contentId]);

  useEffect(() => {
    if (isError) displayError();
  }, [isFetching, isError]);

  const displayError = () => {
    displayOverlay(ContentErrorModalID, {
      close: () => {
        removeOverlay({ id: ContentErrorModalID });
        props.history.goBack();
      },
    });
  };

  return !isFetching ? (
    !isError ? (
      <ContentSubmission
        match={props.match}
        contentType={contentType}
        initialState={initialState}
      />
    ) : null
  ) : (
    <LoadingOverlay />
  );
};

const mapDispatchToProps = {
  displayOverlay: uiOperations.displayOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(null, mapDispatchToProps)(ContentEditScreen);
