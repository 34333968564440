export function waitFor(predicate: () => boolean, timeout: number) {
  return new Promise((resolve, reject) => {
    const check = () => {
      if (!predicate()) return;
      clearInterval(interval);
      resolve(true);
    };
    const interval = setInterval(check, 100);
    check();

    if (!timeout) return;
    setTimeout(() => {
      clearInterval(interval);
      const error = new Error('Timeout expired');
      reject(error);
    }, timeout);
  });
}
