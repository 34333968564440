export const CONTENT_COMMENT_SUBMIT = 'w:ContentCard:Comment:click';
export const CONTENT_COMMENT_EDIT = 'w:ContentCard:Comment:Edit:click';
export const CONTENT_COMMENT_DELETE = 'w:ContentCard:Comment:Delete:click';
export const CONTENT_COMMENT_REPORT = 'w:ContentCard:Comment:Report:click';
export const CONTENT_COMMENT_LIKE = 'w:ContentCard:Comment:Reaction:Like:click';
export const CONTENT_COMMENT_UNLIKE =
  'w:ContentCard:Comment:Reaction:Unlike:click';
export const CONTENT_COMMENT_HIGHLIGHT =
  'w:ContentCard:Comment:Highlight:click';
export const CONTENT_COMMENT_UNHIGHLIGHT =
  'w:ContentCard:Comment:Unhighlight:click';
export const CONTENT_COMMENT_AVATAR_CLICK =
  'w:ContentCard:Comment:Avatar:click';
export const CONTENT_COMMENT_REPLY_CLICK = 'w:ContentCard:Reply:click';
export const CONTENT_COMMENT_REPLY_SUBMIT = 'w:ContentCard:Comment:Reply:click';
export const CONTENT_COMMENT_REPLY_EDIT =
  'w:ContentCard:Comment:Reply:Edit:click';
export const CONTENT_COMMENT_REPLY_DELETE =
  'w:ContentCard:Comment:Reply:Delete:click';
export const CONTENT_COMMENT_REPLY_REPORT =
  'w:ContentCard:Comment:Reply:Report:click';
export const CONTENT_COMMENT_REPLY_LIKE =
  'w:ContentCard:Comment:Reply:Reaction:Like:click';
export const CONTENT_COMMENT_REPLY_UNLIKE =
  'w:ContentCard:Comment:Reply:Reaction:Unlike:click';
export const CONTENT_COMMENT_REPLY_AVATAR_CLICK =
  'w:ContentCard:Comment:Reply:Avatar:click';
