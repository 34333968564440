import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { programSelectors } from '../../models/program';
import { RootPatronState } from '../../common/use-patron-selector';

type EmptyFeedProps = ReturnType<typeof mapStateToProps>;

function EmptyFeed({ searchBarInHeaderEnabled }: EmptyFeedProps) {
  const { t } = useTranslation();

  return (
    <div className="empty-feed" aria-describedby="empty-feed">
      <div className="empty-feed__image">
        <img src="/images/feed-empty.svg" alt="Empty feed" id="empty-feed" />
      </div>

      {!searchBarInHeaderEnabled ? (
        <>
          <div className="empty-feed__title">{t('amplifier.nothing_new')}</div>
          <p className="empty-feed__text">{t('amplifier.check_later')}</p>
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps)(EmptyFeed);
