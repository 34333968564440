import Editor from '@draft-js-plugins/editor';
import cx from 'classnames';
import {
  ContentState,
  DraftHandleValue,
  EditorState,
  Modifier,
} from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import {
  KeyboardEventHandler,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import './plain-editor.scss';
import {
  createUserTagPlugin,
  TrackingContext,
} from './rich-editor-plugins/user-tag-plugin';
import replaceTagsWithEntities from './rich-editor-plugins/user-tag-plugin/replaceTagsWithEntities';
import stateToTextWithRefs from './rich-editor-plugins/user-tag-plugin/stateToTextWithRefs';

type WordMetaProps = {
  maximumCharacter: number;
  val: number;
};

const WordMeta = ({ maximumCharacter, val }: WordMetaProps) => {
  return (
    <div role="status" className="plain-editor-meta">
      <div
        className={cx('plain-editor-charcount', {
          'plain-editor-charcount--over': val > maximumCharacter,
        })}
      >
        {val} / {maximumCharacter}
      </div>
    </div>
  );
};

export type PlainEditorProps = {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  maximumCharacter?: number;
  multiline?: boolean;
  onChange: (content: string) => void;
  placeholder?: string;
  trackingContext: TrackingContext;
};

function useEditorState(
  defaultValue = '',
  onChange: PlainEditorProps['onChange']
) {
  const lastChangedContent = useRef<ContentState | null>(null);
  const lastEmittedValue = useRef<string>(defaultValue);
  // We only want to create the initial state once when the component mounts
  const initialState = useMemo<EditorState>(() => {
    if (!defaultValue) {
      return EditorState.createEmpty();
    }
    const content = replaceTagsWithEntities(stateFromHTML(defaultValue));
    return EditorState.createWithContent(content);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [editorState, setEditorState] = useState<EditorState>(initialState);
  const content = editorState.getCurrentContent();
  const transformedTextLength = useMemo(
    () => stateToTextWithRefs(content).length,
    [content]
  );

  useEffect(() => {
    // If there is external value change, we need to update the editor state
    if (defaultValue !== lastEmittedValue.current) {
      const content = replaceTagsWithEntities(stateFromHTML(defaultValue));
      setEditorState((prevState) => {
        // Preserve the decorator as during initialization state may be changed multiple times
        const decorator = prevState.getDecorator();
        return EditorState.createWithContent(content, decorator);
      });
    }
  }, [defaultValue]);

  const handleChange = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState);
      const contentState = editorState.getCurrentContent();
      // Only emit change if the content has changed
      if (lastChangedContent.current === contentState) {
        return;
      }
      lastChangedContent.current = contentState;
      const plainText = stateToTextWithRefs(contentState);
      lastEmittedValue.current = plainText;
      onChange(plainText);
    },
    [onChange]
  );
  return { editorState, setEditorState, handleChange, transformedTextLength };
}

export function PlainEditor({
  className,
  defaultValue,
  disabled,
  maximumCharacter,
  multiline,
  onChange,
  placeholder,
  trackingContext,
}: PlainEditorProps): ReactElement {
  const editorContainer = useRef<HTMLDivElement>(null);
  const [editorEl, setEditorEl] = useState<Editor | null>(null);
  const { userTagPlugin, plugins } = useMemo(() => {
    const userTagPlugin = createUserTagPlugin(editorContainer);
    const plugins = [userTagPlugin];
    return { userTagPlugin, plugins };
  }, []);
  const { SuggestionPortal, store: userTagStore } = userTagPlugin;
  const { editorState, setEditorState, handleChange, transformedTextLength } =
    useEditorState(defaultValue, onChange);

  // Hack to handle backspace with Android virtual keyboards, since normal backspace keystrokes removes characters
  // instead of entities - most likely caused by weird keycodes sent by Android virtual keyboards
  // Even better if we can switch from draft-js to something that has good support on mobile devices
  useEffect(() => {
    if (!editorEl || !navigator.userAgent.includes('Android')) return;

    const editableContentEl = editorEl.getEditorRef().editor;

    const eventCallback = (e: Event) => {
      if (!('inputType' in e)) return;

      // checking for InputEvent.inputType is the most reliable way to detect backspace on Android
      if ((e as InputEvent).inputType === 'deleteContentBackward') {
        setEditorState((editorState) => {
          const selection = editorState.getSelection();

          const block = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());

          let selectedEntityRange: { start: number; end: number } | undefined;

          // Find the entities with the `REFERENCE` type (mentions) in the current block
          block.findEntityRanges(
            (entity) => {
              const key = entity.getEntity();
              return (
                !!key &&
                editorState.getCurrentContent().getEntity(key).getType() ===
                  'REFERENCE'
              );
            },
            (start, end) => {
              // If the cursor is within or at the end of the entity, store the range of the entity
              if (
                start < selection.getStartOffset() &&
                end >= selection.getStartOffset()
              ) {
                selectedEntityRange = { start, end };
              }
            }
          );

          if (!selectedEntityRange) return editorState;

          // Use the range of the entity as the selection range for deletion
          const selectionForDelete = selection.merge({
            anchorOffset: selectedEntityRange.start,
            focusOffset: selectedEntityRange.end,
          });

          // Prevent the default behavior of the backspace key because we want to remove the whole range
          e.preventDefault();

          return EditorState.push(
            editorState,
            Modifier.removeRange(
              editorState.getCurrentContent(),
              selectionForDelete,
              'backward'
            ),
            'remove-range'
          );
        });
      }
    };

    editableContentEl.addEventListener('beforeinput', eventCallback);

    return () => {
      editableContentEl.removeEventListener('beforeinput', eventCallback);
    };
  }, [editorEl, setEditorState]);

  const handleClick = useCallback(
    (e?: MouseEvent<HTMLDivElement>) => {
      if (disabled) return;
      if (
        e?.target instanceof Element &&
        !!e.target.closest('.DraftEditor-root') &&
        !e.target.className.includes('DraftEditor-root') && // allow focus on clicking the wrapping editor div
        !e.target.closest('.public-DraftEditorPlaceholder-root') // allow focus on clicking the placeholder
      ) {
        // prevent IE11 flicker from double focus
        return;
      }
      editorEl?.focus();
    },
    [disabled, editorEl]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      if (ev.key === 'Space') {
        handleClick();
      }
    },
    [handleClick]
  );

  /**
   * Prevent newlines from being added when pressing enter, unless the user tag plugin is active, which would insert tag
   * @see https://github.com/facebookarchive/draft-js/issues/657
   */
  const preventNewlineOnReturn = (): DraftHandleValue =>
    userTagStore.isActive ? 'not-handled' : 'handled';

  /**
   * Strip newlines from pasted content
   * @see https://github.com/facebookarchive/draft-js/issues/657
   */
  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): DraftHandleValue => {
    handleChange(
      EditorState.push(
        editorState,
        Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          !multiline ? text.replace(/\n/g, ' ') : text
        ),
        'insert-characters'
      )
    );
    return 'handled';
  };

  return (
    <div ref={editorContainer} className={cx('plain-editor', className)}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div role="application" onClick={handleClick} onKeyDown={handleKeyDown}>
        {/**
         * For better accessibility support, we should migrate draft-js editor
         * TODO: https://firstup-io.atlassian.net/browse/EE-17809
         */}
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          readOnly={disabled}
          handleReturn={multiline ? undefined : preventNewlineOnReturn}
          handlePastedText={handlePastedText}
          stripPastedStyles
          onChange={handleChange}
          plugins={plugins}
          ref={setEditorEl}
        />
        {maximumCharacter && (
          <WordMeta
            val={transformedTextLength}
            maximumCharacter={maximumCharacter}
          />
        )}
      </div>
      <SuggestionPortal trackingContext={trackingContext} />
    </div>
  );
}
