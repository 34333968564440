import React from 'react';
import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Link, Spinner } from '../../components/ui';

import useScreenTitle from '../../common/use-screen-title';
import usePoliciesFetcher from '../../common/use-policies-fetcher';

import '../privacy-policy/policies.scss';

const TermsOfService = () => {
  const { t } = useTranslation();

  useScreenTitle(t('sidebar.terms'));

  const { policies, isFetching } = usePoliciesFetcher();

  return (
    <div className="page policies">
      {isFetching ? (
        <Spinner />
      ) : (
        <div className="container">
          <h1>{t('sidebar.terms')}</h1>

          {policies.terms_format === 'text' ? (
            Parser(policies.terms)
          ) : policies.terms_link ? (
            <Link href={policies.terms_link} target="_blank">
              {policies.terms_link}
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TermsOfService;
