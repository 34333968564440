import { useEffect } from 'react';

const addDocumentListeners = (events, callback) => {
  events.forEach((event) => {
    document.addEventListener(event, callback);
  });
};

const removeDocumentListeners = (events, callback) => {
  events.forEach((event) => {
    document.removeEventListener(event, callback);
  });
};

const useDocumentListeners = (events = '', comparer, callback) => {
  events = events.split(',').map((event) => event.trim());

  useEffect(() => {
    comparer
      ? addDocumentListeners(events, callback)
      : removeDocumentListeners(events, callback);

    return () => {
      removeDocumentListeners(events, callback);
    };
  }, [events, comparer, callback]);
};

export default useDocumentListeners;
