import { ComponentProps, forwardRef, Ref } from 'react';
import CommentsFlashMessage from './comment-flash-message';
import CommentForm from './comment-form';

type CommentsFooterProps = ComponentProps<typeof CommentsFlashMessage> &
  Omit<ComponentProps<typeof CommentForm>, 'disabled'> & {
    isDisabled?: boolean;
  };

const CommentsFooter = (
  {
    context,
    message,
    isDisabled,
    onSubmit,
    onReset,
    onError,
    onFormFocus,
    onFormBlur,
  }: CommentsFooterProps,
  ref: Ref<HTMLDivElement>
) => (
  <div ref={ref} className="comments__footer">
    <CommentsFlashMessage message={message} />
    <CommentForm
      onFormFocus={onFormFocus}
      onFormBlur={onFormBlur}
      context={context}
      disabled={isDisabled}
      onSubmit={onSubmit}
      onReset={onReset}
      onError={onError}
    />
  </div>
);

export default forwardRef(CommentsFooter);
