import types from './types';

const setAppConfig = (config) => ({
  type: types.SET,
  config,
});

export default {
  setAppConfig,
};
