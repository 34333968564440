import { Action } from './actions';
import types from './types';
import utils from './utils';

const initialState = {};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.SET:
      return utils.addChannels(state, action.channels);

    case types.FOLLOW:
      return utils.followChannel(state, action.id);

    case types.UNFOLLOW:
      return utils.unfollowChannel(state, action.id);

    default:
      return state;
  }
};

export default reducer;
