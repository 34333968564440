import useSWRImmutable from 'swr';
import useSWRInfinite from 'swr/infinite';
import { useDebounce } from 'usehooks-ts';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';
import { useProgram } from '../../../../common/use-program';
import { contentCacheKey, searchContent } from '../content/content';
import { documentsCacheKey, searchDocuments } from '../documents/documents';
import {
  CategoryMetadata,
  getSearchMetadata,
  queryFilterOptionSource,
} from '../http';
import { peopleCacheKey, searchPeople } from '../people/people';
import { useSearchQueryParams } from '../query-params';
import { SearchType, useSearchRoute } from '../search-screen';
import { searchShortcuts, shortcutsCacheKey } from '../shortcuts/shortcuts';
import { topicsCacheKey, searchTopics } from '../topics/topics-search';
import { Filter } from './filters/filters';
import useSWR from 'swr';

export function useSearchFilterOptions(
  query: string,
  { url, perPage }: { url: string; perPage: number }
) {
  const debouncedSearchQuery = useDebounce(query, 500);
  const {
    data: filterOptions,
    isLoading,
    size,
    mutate,
    ...rest
  } = useSWRInfinite(
    (pageIndex) => [debouncedSearchQuery, url, pageIndex + 1, perPage],
    ([query, url, page, perPage]) =>
      queryFilterOptionSource(query, { url: url, page: page, perPage: perPage })
  );

  const isLoadingMore = Boolean(
    isLoading ||
      (size > 0 &&
        filterOptions &&
        typeof filterOptions[size - 1] === 'undefined')
  );
  const isEmpty = filterOptions?.[0]?.data?.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (filterOptions &&
        filterOptions[filterOptions.length - 1]?.data?.length < Number(perPage))
  );

  return {
    filterOptions,
    isLoading,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    mutate,
    incrementPage: () => {
      rest.setSize((prev) => prev + 1);
    },
    ...rest,
  };
}

export function useResultsFound() {
  const isAssistantActive = useIsAssistantActive();
  const { searchType } = useSearchRoute();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const cacheKey = cacheKeyFor(searchType);
  const searcher = queryFnFor(searchType);
  const programId = useProgram().id;
  const { data } = useSWRInfinite(
    () => cacheKey(query),
    () =>
      searcher &&
      (searcher(programId, query) as Promise<{
        meta: {
          page: number;
          perPage: number;
          totalPages: number;
          totalResults: number;
        };
      }>)
  );

  return data?.[0]?.meta?.totalResults || 0;
}

export function useCategoryMetadata() {
  const programId = useProgram().id;
  const { data, ...rest } = useSWR(
    'search-metadata',
    async () => {
      const metadata = await getSearchMetadata(programId);
      const categoriesMetadata = new Map<string, CategoryMetadata>();
      for (const category of metadata.categories) {
        categoriesMetadata.set(category.name, category);
      }

      return categoriesMetadata;
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    items: data,
    ...rest,
  };
}

function cacheKeyFor(searchType: SearchType) {
  switch (searchType) {
    case 'people':
      return peopleCacheKey;
    case 'topics':
      return topicsCacheKey;
    case 'posts':
      return contentCacheKey;
    case 'enterprise':
      return peopleCacheKey;
    case 'overview':
      return contentCacheKey;
    case 'documents':
      return documentsCacheKey;
    case 'shortcuts':
      return shortcutsCacheKey;
    default:
      throw new Error(`Unknown search type: ${searchType}`);
  }
}

function queryFnFor(searchType: SearchType) {
  switch (searchType) {
    case 'people':
      return searchPeople;
    case 'topics':
      return searchTopics;
    case 'posts':
      return searchContent;
    case 'documents':
      return searchDocuments;
    case 'shortcuts':
      return searchShortcuts;
    case 'enterprise':
      // noop
      return () => Promise.resolve();
    case 'overview':
      // TODO: implement
      return searchContent;
    case 'documents':
      //TODO: implement
      return searchContent;
  }
}
