import types, {
  BoxIntegrationReducerActions,
  BoxResourceId,
  BoxResourceType,
  BoxTokenData,
} from './types';

export const setTokenData = (
  tokenData: BoxTokenData,
  resourceType: BoxResourceType,
  resourceId: BoxResourceId
): BoxIntegrationReducerActions => ({
  type: types.SET_TOKEN_DATA,
  resourceType,
  resourceId,
  payload: tokenData,
});
