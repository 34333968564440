import { useTranslation } from 'react-i18next';
import { programSelectors } from '../../models/program';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { Icon } from '../ui';

const CommentsEmpty = () => {
  const { t } = useTranslation();
  const v2CommentPlaceholderEnabled = useSelector(
    programSelectors.getV2CommentPlaceholderEnabled
  );
  const newContentPage = useSelector((state) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  return newContentPage ? (
    <div className="comments-empty">
      <Icon type="chat_bubble_outline" />
      <div className="comments-empty__title">{t('comments.no_comments')}</div>
      <div className="comments-empty__text">
        {t('comments.leave_first_comment')}
      </div>
    </div>
  ) : (
    <div className="comments-empty">
      <div className="comments-empty__image">
        {v2CommentPlaceholderEnabled ? (
          <img src="/images/comments-empty-v2.svg" alt="No comments" />
        ) : (
          <img src="/images/comments-empty.svg" alt="No comments" />
        )}
      </div>
      <div className="comments-empty__title">
        {t('comments.conversation_starter')}
      </div>
      <div className="comments-empty__text">
        {t('comments.leave_first_comment')}
      </div>
    </div>
  );
};

export default CommentsEmpty;
