import React, { useEffect, useRef, useState } from 'react';
import { isElementVisible } from '../../../../../lib/dom-utils';

interface TrackableElementProps {
  trackingId: any;
  className?: string;
  trackableClassName?: string;
  children: React.ReactElement;
}

export const TrackableElement: React.FC<TrackableElementProps> = (props) =>
  React.cloneElement(React.Children.only(props.children), {
    className: `${props.trackableClassName} ${props.className || ''}`,
    'data-tracking-id': props.trackingId,
  });

interface ViewTrackerProps {
  onView: (id: any) => void;
  trackableClassName: string;
  windowSelector?: string;
  children?: React.ReactNode;
}

const ViewTracker: React.FC<ViewTrackerProps> = ({
  onView,
  trackableClassName,
  windowSelector = '#view-tracker-window',
  children,
}) => {
  const [trackedIds, setTrackedIds] = useState<{ [key: string]: boolean }>({});
  const trackingRef = useRef(false);
  const abortRef = useRef(false);

  useEffect(() => {
    const windowEl = document.querySelector(windowSelector);
    if (!windowEl) {
      return console.error(`
        ViewTracker expected to find an element:
          - #view-tracker-window
          - css height 100vh
          - css width 100%`);
    }

    const poller = setInterval(() => {
      if (!abortRef.current && !trackingRef.current) {
        trackingRef.current = true;
        window.requestAnimationFrame(() => {
          for (const el of Array.from(
            windowEl.querySelectorAll(`.${trackableClassName}`)
          )) {
            if (!trackedIds[el.getAttribute('data-tracking-id')!]) {
              if (isElementVisible(el)) {
                const trackingId = el.getAttribute('data-tracking-id')!;
                setTrackedIds((prevTrackedIds) => ({
                  ...prevTrackedIds,
                  [trackingId]: true,
                }));
                onView(trackingId);
              }
            }
          }
          trackingRef.current = false;
        });
      }
    }, 250);

    return () => {
      abortRef.current = true;
      clearInterval(poller);
    };
  }, [onView, trackableClassName, windowSelector, trackedIds]);

  return <>{children}</>;
};

export default ViewTracker;
