import { useState, useEffect } from 'react';
import ContentSubmissionService from '../services/content-submission';

const useMediaUploader = ({ file, onStatusChange, onComplete, onError }) => {
  const [status, setStatus] = useState('idle');
  const [mediaUrl, setMediaUrl] = useState();
  const [progress, setProgress] = useState(0);
  const [service] = useState(new ContentSubmissionService());

  const fetchUploadUrls = async () => {
    setStatus('fetching');

    try {
      const filename = file.name.replace(/[^\w-_.]/g, '');

      const res = await service.fetchPrivateMediaUploadUrls(filename);
      const uploadUrl = res.data.upload_url;
      const viewUrl = res.data.view_url;

      return Promise.resolve({ uploadUrl, viewUrl });
    } catch (err) {
      handleSubmitFailure(err);
    }
  };

  const submitMedia = async ({ uploadUrl, viewUrl }) => {
    setStatus('uploading');

    try {
      await service.createMediaAsset(uploadUrl, file, {
        onUploadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded * 100) / total);
          setProgress(progress);
        },
      });

      return Promise.resolve(viewUrl); // url with auth -- private to the user
    } catch (err) {
      handleSubmitFailure(err);
    }
  };

  const handleSubmitSuccess = (url) => {
    setStatus('complete');
    setMediaUrl(url);

    if (typeof onComplete === 'function') onComplete(url);
  };

  const handleSubmitFailure = (err) => {
    setStatus('error');

    if (typeof onError === 'function') onError(err);
  };

  useEffect(() => {
    fetchUploadUrls().then(submitMedia).then(handleSubmitSuccess);
  }, [file.name, file.lastModified]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof onStatusChange === 'function') onStatusChange(status);
  }, [status, onStatusChange]);

  return {
    status,
    progress,
    mediaUrl,
  };
};

export default useMediaUploader;
