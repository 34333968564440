import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useContentFeed from '../../common/use-content-feed';
import { programSelectors } from '../../models/program';

import Feed from '../../components/feed';

import { trackSearchSuccess, trackSearchError } from '../../models/analytics';
import { SearchResults } from './search';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const SearchPostsStandard = ({ term }) => {
  const { fetching, page, feedItems, error, canLoadMore, fetchFeed, numPages } =
    useContentFeed({ query: term });

  const analyticsData = {
    location: 'search_posts',
    searchType: 'contents',
    searchTerm: term,
  };

  useEffect(() => {
    if (!error) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError({ ...analyticsData, error });
    }
  }, [term, error]);

  const { t } = useTranslation();

  // numpages can be 0 or undefined, TODO fix response from Mojo
  const firstOrLastPage = numPages === page || !numPages;
  const resultsCount = firstOrLastPage ? feedItems.length : `${pageSize}+`;

  return (
    <section className="search-content" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        {!error ? (
          <Feed
            items={feedItems}
            page={page}
            fetching={fetching}
            canLoadMore={canLoadMore}
            fetchFeed={fetchFeed}
            analyticsData={analyticsData}
          />
        ) : (
          <div className="container">
            {t('assistant.error_searching_content', { term })}
          </div>
        )}
      </div>
      {!fetching && resultsCount == 0 ? (
        <div className="search-component__footer">
          <SearchResults count={resultsCount} />
        </div>
      ) : null}
    </section>
  );
};

const SearchPostsSearchHeader = ({ term }) => {
  const { fetching, page, feedItems, error, canLoadMore, total, fetchFeed } =
    useContentFeed({ query: term });

  const analyticsData = {
    location: 'search_posts',
    searchType: 'contents',
    searchTerm: term,
  };

  useEffect(() => {
    if (!error) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError({ ...analyticsData, error });
    }
  }, [term, error]);

  const { t } = useTranslation();

  return (
    <section className="search-content" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      {!fetching && total ? (
        <div className="search-component__header">
          <SearchResults count={total} />
        </div>
      ) : null}
      <div className="container container--slim">
        {!error ? (
          <Feed
            items={feedItems}
            page={page}
            fetching={fetching}
            canLoadMore={canLoadMore}
            fetchFeed={fetchFeed}
            analyticsData={analyticsData}
          />
        ) : (
          <div className="container">
            {t('assistant.error_searching_content', { term })}
          </div>
        )}
      </div>
      {!fetching && total == 0 ? (
        <div className="search-component__footer">
          <SearchResults count={total} />
        </div>
      ) : null}
    </section>
  );
};

const SearchPosts = ({ term, searchBarInHeaderEnabled }) => {
  return searchBarInHeaderEnabled ? (
    <SearchPostsSearchHeader term={term} />
  ) : (
    <SearchPostsStandard term={term} />
  );
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps, null)(SearchPosts);
