function nodeIsHTMLElement(node: Node): node is HTMLElement {
  return node instanceof HTMLElement;
}

// https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775
export function findFirstScrollableParent(
  node: Node | null,
  depth = 5
): HTMLElement | undefined {
  if (!node || depth <= 0) return undefined;

  const isElement = nodeIsHTMLElement(node);
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (isElement && isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return findFirstScrollableParent(node.parentNode, depth - 1);
}

export function isElementVisible(el: Element) {
  let rect = el.getBoundingClientRect();
  const top = rect.top;
  const left = rect.left;
  const height = rect.height;
  const width = rect.width;
  // Check if bottom/right of the element is off the top/left of the page
  if (rect.bottom < 0) return false;
  if (rect.right < 0) return false;
  // Check it is 50% within the document viewport
  // various crappy heuristics that seem to work for embedded feeds templates
  if ((top + height) / 2 >= document.documentElement.clientHeight) return false;
  if ((left + width) / 2 >= document.documentElement.clientWidth) return false;
  el = el.parentNode as Element;
  do {
    rect = el.getBoundingClientRect();
    if (top <= rect.bottom === false) return false;
    if (left <= rect.right === false) return false;
    // Check if the element is out of view due to a container scrolling
    if (top + height <= rect.top) return false;
    if (left + width <= rect.left) return false;
    el = el.parentNode as Element;
  } while (el !== document.body);
  return true;
}
