import types from './types';

const setProgramMembership = (programMembership) => ({
  type: types.SET,
  programMembership,
});

const updateProgramMembershipStatus = (status) => ({
  type: types.UPDATE_STATUS,
  status,
});

const hidePrivateProfileWarning = () => ({
  type: types.HIDE_PRIVATE_WARNING,
});

export default {
  setProgramMembership,
  updateProgramMembershipStatus,
  hidePrivateProfileWarning,
};
