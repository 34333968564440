import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import { programSelectors } from '../../models/program';
import { Dialog } from '../ui';
import OnboardingService from '../../services/onboarding';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const deprotocolMarkup = (str) => {
  const div = document.createElement('div');
  div.innerHTML = str.trim();
  div.firstChild.src = div.firstChild.src.replace(/^(http|https):/, '');
  return div.innerHTML;
};

const OnboardingDialogVideo = (props) => {
  const [isSaving, setIsSaving] = useState();

  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const videoData = props.step.video_metadata;
  const videoAspectRatio = `${(videoData.height / videoData.width) * 100}%`;
  const videoMarkup = deprotocolMarkup(props.step.internal_content);

  const handleAccept = () => {
    setIsSaving(true);

    submit();
  };

  const submit = async () => {
    try {
      const res = await new OnboardingService().submitWelcomeVideoWatched();

      handleSubmitSuccess(res);
    } catch (err) {
      if (err.response) {
        handleSubmitFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaving(false);

    props.continue();
  };

  const handleSubmitFailure = (err) => {
    console.error('onboarding dialog video  -- submission error', err);

    setIsSaving(false);

    pushMessage({
      text: t('errors.default'),
      type: 'error',
    });
  };

  return (
    <Dialog
      title={t('screens.welcome.watch_video_text', { name: props.programName })}
      accept={{
        text: t('common.close'),
        icon: 'check',
        disabled: isSaving,
        loading: isSaving,
        onClick: handleAccept,
      }}
      className="onboarding-dialog"
    >
      <div
        style={{ paddingTop: videoAspectRatio }}
        className="onboarding-dialog__video"
      >
        {Parser(videoMarkup)}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  programName: programSelectors.getProgramName(state, ownProps),
});

export default connect(mapStateToProps)(OnboardingDialogVideo);
