import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  contentSelectors,
  contentOperations,
} from '../models/content/index.js';
import type { Content } from '../models/content/types';
import ContentService from '../services/content';
import { usePatronSelector } from './use-patron-selector';
import { AxiosResponse } from 'axios';

type MentionFormat = 'raw' | 'with_metadata';

interface UseContentFetcherProps {
  contentId: number;
  feedItemId?: string;
  isApproval?: boolean;
  mentionFormat?: MentionFormat;
}

function useContentFetcher({
  contentId,
  feedItemId,
  isApproval,
  mentionFormat,
}: UseContentFetcherProps) {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(true);
  const [content, setContent] = useState<Content>();
  const [error, setError] = useState<unknown>();

  const contentItem = usePatronSelector((state) =>
    contentSelectors.getContentById(state, { contentId })
  );

  const addContentToStore = (c: Content) =>
    dispatch(contentOperations.addContent(c));

  useEffect(() => {
    if (contentItem) {
      setContent(contentItem);
      setFetching(false);
    } else {
      fetchContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, feedItemId]);

  const fetchContent = () => {
    setFetching(true);

    (feedItemId
      ? new ContentService().fetchByFeedItemId(feedItemId)
      : isApproval
      ? new ContentService(contentId).fetchApproval()
      : new ContentService(contentId).fetch({
          mention_format: mentionFormat,
        })
    )
      .then(handleFetchContentSuccess)
      .catch(handleFetchContentError);
  };

  const handleFetchContentSuccess = (res: AxiosResponse) => {
    const contentItem = res.data.feed
      ? res.data.feed[0].attributes
      : res.data.attributes;

    addContentToStore(contentItem);

    setTimeout(() => {
      setContent(contentItem);
      setFetching(false);
    }, 500);
  };

  const handleFetchContentError = (err: unknown) => {
    console.error(err);

    setError(err);
    setFetching(false);
  };

  return {
    fetching,
    content,
    error,
  };
}

export default useContentFetcher;
