import {
  CSSProperties,
  forwardRef,
  HTMLProps,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { connect } from 'react-redux';
import { Link } from '../ui';
import { contentSelectors } from '../../models/content/index.js';
import { trackContentCardClick } from '../../models/content/analytics';
import { RootPatronState } from '../../common/use-patron-selector';
import { useLocation } from 'react-router';
import { AssistantLink } from '../assistant/assistant-link';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  contentId?: number;
  ignoreLink?: boolean;
  followNestedLink?: boolean;
  style?: CSSProperties;
  analyticsData?: IAnalyticsData;
  onClick?: MouseEventHandler;
  classes?: string;
};

type ContentLinkProps = StateProps &
  Omit<HTMLProps<HTMLAnchorElement>, 'action' | 'ref'> &
  PropsWithChildren<OwnProps>;

const ContentLink = forwardRef<HTMLAnchorElement, ContentLinkProps>(
  (
    {
      contentId,
      url,
      ignoreLink,
      followNestedLink,
      style,
      analyticsData,
      children,
      onClick,
      classes,
      ...args
    },
    ref
  ) => {
    const location = useLocation();
    const isAssistant = location.pathname.startsWith('/assistant');
    const handleClick: MouseEventHandler = (e) => {
      trackContentCardClick(contentId, analyticsData);

      if (onClick) onClick(e);
    };

    if (isAssistant) {
      return (
        <AssistantLink
          to={url}
          onClick={handleClick}
          variant={ignoreLink ? 'disabled' : 'default'}
          className={`content__link ${classes ? classes : ''}`}
          style={style}
          ref={ref}
          {...args}
        >
          {children}
        </AssistantLink>
      );
    }

    return !ignoreLink ? (
      <Link
        href={url}
        action="push"
        className={`content__link ${classes ? classes : ''}`}
        style={style}
        ref={ref}
        onClick={handleClick}
        followNestedLink={followNestedLink}
        {...args}
      >
        {children}
      </Link>
    ) : (
      <span className={`content__link ${classes ? classes : ''}`} ref={ref}>
        {children}
      </span>
    );
  }
);

const mapStateToProps = (
  state: RootPatronState,
  ownProps: OwnProps & { id?: undefined }
) => ({
  url: contentSelectors.getContentDetailUrl(state, ownProps),
});

ContentLink.displayName = 'ContentLink';
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ContentLink
);
