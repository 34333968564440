import { Button, Container } from '@socialchorus/shared-ui-components';
import cx from 'classnames';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

interface CardProps {
  title: string;
  link?: JSX.Element;
  className?: string;
}

export const PreviewCard: FC<CardProps> = ({
  title,
  link,
  className,
  children,
}) => {
  return (
    <Container
      className={cx(styles.previewCard, className)}
      shadow="extra-light"
      fullWidth
    >
      <div className={styles.cardHeading}>
        <h3 className={styles.cardTitle}>{title}</h3>
        {link}
      </div>
      {children}
    </Container>
  );
};

export const CardPlaceholder: FC = ({ children }) => (
  <div className={styles.cardPlaceholder}>{children}</div>
);

interface CardLinkProps {
  label: string;
  to: string;
  className?: string;
  onClick?: () => void;
}
export const CardLink = ({
  className,
  label,
  to,
  onClick,
}: CardLinkProps): JSX.Element => {
  const history = useHistory();
  const handleClick = useCallback(() => {
    onClick && onClick();
    history.push(to);
  }, [history, to, onClick]);
  return (
    <Button
      className={cx(styles.cardLink, className)}
      variant="text"
      size="compact"
      onClick={handleClick}
      label={label}
    />
  );
};
