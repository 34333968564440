import { useTranslation } from 'react-i18next';
import { contentSelectors } from '../../../../models/content';
import { usePatronSelector } from '../../../../common/use-patron-selector';

type ContentCardViewsProps = {
  viewCount: number;
  showText?: boolean;
};

const ContentCardViews = ({
  viewCount,
  showText = true,
}: ContentCardViewsProps) => {
  const { t } = useTranslation();
  const viewWord = t('content.view', { count: viewCount });
  const displayString =
    t('compact_number', { value: viewCount }) + (showText && ` ${viewWord}`);

  return <span className={'content__views'}>{displayString}</span>;
};

type ContentCardViewsWrapperProps = {
  contentId: number;
  showText?: boolean;
};

const ContentCardViewsWrapper = ({
  contentId,
  showText = true,
}: ContentCardViewsWrapperProps) => {
  const viewCount = usePatronSelector((s) =>
    contentSelectors.getContentViewCount(s, { contentId })
  );

  return <ContentCardViews viewCount={viewCount} showText={showText} />;
};

export default ContentCardViewsWrapper;
