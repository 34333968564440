import { AxiosResponse } from 'axios';
import api from '../config/api';
import { Content } from '../models/content/types';

interface FeedServiceOptions {
  query?: string;
  filter?: string;
  channelId?: string;
  advocateId?: string;
  advocateFilter?: string;
  includeTypes?: string[];
  initiativeId?: string;
  mention_format?: 'raw' | 'human_readable' | 'display_name' | 'with_metadata';
  useLegacyMetadata?: boolean;
}

type FeedData = {
  type: string;
  attributes: Content;
};

type LegacyMetadata = {
  page: {
    number: number;
    size: number;
  };
  total: number;
};

type Metadata = {
  page: number;
  total_results: number;
  total_pages: number;
  per_page: number;
};

type RawServiceResponse =
  | {
      feed: FeedData[];
      contents?: never;
      meta: Metadata | LegacyMetadata;
    }
  | { feed?: never; contents: FeedData[]; meta: Metadata | LegacyMetadata };

type FeedServiceMetadata = {
  page: {
    number: number;
    size: number;
  };
  total: number;
  total_pages: number;
};

export type FeedServiceResponse =
  | {
      feed: FeedData[];
      contents?: never;
      meta: FeedServiceMetadata;
    }
  | { feed?: never; contents: FeedData[]; meta: FeedServiceMetadata };

class FeedService {
  readonly query?: string;
  readonly filter?: string;
  readonly channelId?: string;
  readonly advocateId?: string;
  readonly advocateFilter?: string;
  readonly includeTypes?: string[];
  readonly initiativeId?: string;
  readonly mention_format?: FeedServiceOptions['mention_format'];
  readonly useLegacyMetadata: boolean;

  constructor({
    query,
    filter,
    channelId,
    advocateId,
    advocateFilter,
    includeTypes,
    initiativeId,
    mention_format,
    useLegacyMetadata = true,
  }: FeedServiceOptions = {}) {
    this.query = query;
    this.filter = filter;
    this.channelId = channelId;
    this.initiativeId = initiativeId;
    this.advocateId = advocateId;
    this.advocateFilter = advocateFilter;
    this.includeTypes = includeTypes;
    this.mention_format = mention_format;
    this.useLegacyMetadata = useLegacyMetadata;
  }

  fetch = ({
    page,
    perPage,
    pinned,
  }: { page?: number; perPage?: number; pinned?: boolean } = {}) => {
    return api
      .get<RawServiceResponse>(this.url, {
        params: {
          query: this.query,
          filter_type: this.filter,
          content_channel: this.channelId,
          initiative_tag_id: this.initiativeId,
          include_types: this.includeTypes,
          width: 768,
          page_size: perPage,
          page,
          pinned,
          mention_format: this.mention_format,
        },
      })
      .then(this.transformResponse);
  };

  transformResponse = (
    res: AxiosResponse<RawServiceResponse>
  ): FeedServiceResponse => {
    // Only the search endpoint uses the new metadata style at the moment
    if (this.useLegacyMetadata || !this.query) {
      const meta = res.data.meta as LegacyMetadata;
      const transformedMeta: FeedServiceMetadata = {
        page: meta.page,
        total: meta.total,
        total_pages: Math.ceil((meta.total || 0) / (meta.page.size || 20)),
      };

      return res.data.feed
        ? { feed: res.data.feed, meta: transformedMeta }
        : { contents: res.data.contents, meta: transformedMeta };
    } else {
      const meta = res.data.meta as Metadata;
      const transformedMeta: FeedServiceMetadata = {
        page: {
          number: meta.page,
          size: meta.per_page,
        },
        total: meta.total_results,
        total_pages: meta.total_pages,
      };

      return res.data.feed
        ? { feed: res.data.feed, meta: transformedMeta }
        : { contents: res.data.contents, meta: transformedMeta };
    }
  };

  get url() {
    if (this.query) return '/feed/search';

    if (this.advocateId)
      return `/profiles/${this.advocateId}/contents/${this.advocateFilter}`;

    return '/feed';
  }
}

export default FeedService;
