import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import cx from 'classnames';

import useInview from '../../common/use-inview';

import { contentSelectors } from '../../models/content/index.js';
import { programSelectors } from '../../models/program';

import {
  ContentImage,
  ContentFrame,
  ContentFrameLink,
  ContentTitle,
  ContentSummary,
  ContentTags,
  ContentSourceInfo,
  ContentActionLike,
  ContentActionComment,
  ContentActionBookmark,
  ContentActionShare,
  ContentActionEdit,
  ContentActionTranslate,
} from '../content';

import { trackContentCardView } from '../../models/content/analytics';

import { Icon } from '../ui';

import './content-item.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import useFeatureFlag from '../../common/use-feature-flag';

const ContentItem = ({
  contentId,
  orientation,
  size,
  index,
  titleTag,
  truncate,
  className,
  contentType,
  isVideo,
  canLike,
  canComment,
  canBookmark,
  canShare,
  canEdit,
  canTranslate,
  externalUrl,
  contentLang,
  shouldTrackView,
  analyticsData,
  playInline,
  onClick,
  viewsEnabled,
  viewCount,
}) => {
  const ref = useRef();
  const [showVideo, setShowVideo] = useState();
  const history = useHistory();

  const isSmall = size === 'small';

  const newDetailPage = useSelector((state) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  if (index === 0 && orientation === 'landscape' && !size) {
    orientation = 'portrait';
    size = 'large';
  }

  const inlineVideo = isVideo && (playInline || size === 'large');

  className = cx(
    className,
    'content-item',
    `content-item--${contentType}`,
    `content-item--${orientation}`,
    { [`content-item--${size}`]: size }
  );

  const imageClassName = cx('content-item__image', {
    'content-item__image--playing': showVideo,
  });

  const titleLines = truncate !== undefined && truncate !== true ? truncate : 2;

  const summaryLines =
    truncate !== undefined && truncate !== true
      ? truncate
      : size === 'default'
      ? false
      : 2;

  const handleView = () => {
    if (shouldTrackView) trackContentCardView(contentId, analyticsData);
  };

  const handleImageClick = (e) => {
    if (inlineVideo && isVideo) {
      e.preventDefault(); // prevent content modal open

      setShowVideo(true);
    }

    onClick && onClick();
  };

  // Reset video state on location change
  useEffect(() => {
    let historyUnlisten;

    if (inlineVideo && showVideo) {
      historyUnlisten = history.listen(() => {
        setShowVideo(false);
      });
    }
    return historyUnlisten;
  }, [showVideo]); // eslint-disable-line react-hooks/exhaustive-deps

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  const reactionsDisabled = useFeatureFlag(Feature.REACTIONS_DISABLED);

  return (
    <div className={className} ref={ref} lang={contentLang}>
      {orientation === 'portrait' ? (
        <ContentTags contentId={contentId} />
      ) : null}

      <div className={imageClassName}>
        <ContentImage
          contentId={contentId}
          orientation={orientation === 'landscape' ? 'portrait' : 'landscape'}
          analyticsData={analyticsData}
          onClick={handleImageClick}
          ignoreLink={truncate !== undefined && !truncate}
        />

        {showVideo ? (
          <ContentFrame contentId={contentId} analyticsData={analyticsData} />
        ) : null}

        {!reactionsDisabled && (
          <div className="content-item__actions">
            {canBookmark && (
              <ContentActionBookmark
                contentId={contentId}
                analyticsData={analyticsData}
              />
            )}
            {canShare && (
              <ContentActionShare
                contentId={contentId}
                analyticsData={analyticsData}
              />
            )}
          </div>
        )}
      </div>

      <div className="content-item__body">
        <div className="content-item__text">
          {orientation === 'landscape' ? (
            <ContentTags contentId={contentId} />
          ) : null}

          <ContentTitle
            contentId={contentId}
            truncate={titleLines}
            analyticsData={analyticsData}
            onClick={onClick}
            ignoreLink={truncate !== undefined && !truncate}
            titleTag={titleTag}
          />
          <ContentSummary contentId={contentId} truncate={summaryLines} />
        </div>

        {contentType === 'link' ? (
          <ContentFrameLink
            contentId={contentId}
            url={externalUrl}
            analyticsData={analyticsData}
          />
        ) : null}

        {!newDetailPage && <ContentSourceInfo contentId={contentId} />}

        {!reactionsDisabled && (
          <div className="content-item__actions">
            {canLike && (
              <ContentActionLike
                contentId={contentId}
                analyticsData={analyticsData}
                noText={isSmall}
              />
            )}
            {canComment && (
              <ContentActionComment
                contentId={contentId}
                analyticsData={analyticsData}
                noText={isSmall}
              />
            )}
            {canTranslate && (
              <ContentActionTranslate
                contentId={contentId}
                analyticsData={analyticsData}
                noText={isSmall}
              />
            )}
            {viewsEnabled && (
              <div className="content__action content__action--views">
                <span>
                  <Icon type="visibility" className="visibility" />
                  <span className="count">{viewCount}</span>
                </span>
              </div>
            )}
            {canEdit && (
              <ContentActionEdit
                contentId={contentId}
                analyticsData={analyticsData}
                noText={isSmall}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ContentItem.defaultProps = {
  orientation: 'landscape',
};

const mapStateToProps = (state, ownProps) => ({
  contentType: contentSelectors.getContentType(state, ownProps),
  contentLang: contentSelectors.getContentLang(state, ownProps),
  isNote: contentSelectors.getContentIsNote(state, ownProps),
  isVideo: contentSelectors.getContentIsVideo(state, ownProps),
  canLike: contentSelectors.getContentCanLike(state, ownProps),
  canComment: contentSelectors.getContentCanComment(state, ownProps),
  canBookmark: contentSelectors.getContentCanBookmark(state, ownProps),
  canShare: contentSelectors.getContentCanShare(state, ownProps),
  canEdit: contentSelectors.getContentCanEdit(state, ownProps),
  canTranslate: contentSelectors.getContentCanTranslate(state, ownProps),
  externalUrl: contentSelectors.getContentExternalUrl(state, ownProps),
  viewsEnabled: programSelectors.getFeedCardViewsEnabled(state, ownProps),
  viewCount: contentSelectors.getContentViewCount(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentItem);
