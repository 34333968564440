import './feed-header.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/button';
import {
  trackChannelViewClick,
  trackFeedViewAllClick,
} from '../../../models/analytics';

export type FeedHeaderProps = {
  id: string;
  title: string;
  viewAllLink?: string;
  viewAllText?: string;
  description?: string;
  isTopic: boolean;
  analyticsData: IAnalyticsData;
};

export default function FeedHeader({
  id,
  title,
  viewAllLink,
  viewAllText,
  description,
  analyticsData,
  isTopic,
}: FeedHeaderProps) {
  const { t } = useTranslation();
  const handleClick = () => {
    if (isTopic) {
      trackChannelViewClick(analyticsData);
    } else {
      trackFeedViewAllClick(analyticsData);
    }
  };

  return (
    <header className="feed-header">
      <div className="feed-header__first-row">
        <h2 id={`${id}--title`} className="feed-header__title">
          {title}
        </h2>
        {viewAllLink && (
          <Button
            theme="secondary"
            href={viewAllLink}
            action="push"
            size="compact"
            onClick={handleClick}
          >
            {viewAllText || t('landing.see_all')}
          </Button>
        )}
      </div>
      {description && (
        <h3 id={`${id}--description`} className="feed-header__description">
          {description}
        </h3>
      )}
    </header>
  );
}
