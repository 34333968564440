import { useSetRecoilState } from 'recoil';
import { NotificationCounts } from '../../models/notifications/types';
import useSWR from 'swr';
import { AxiosResponse } from 'axios';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import MojoApiFactory from '../../config/api-factory';
import { useEffect } from 'react';

export const useNotificationCountsLoader = () => {
  const setNotificationCounts = useSetRecoilState(notificationCountsState);

  const { data } = useSWR(
    'notifications/counts',
    async () => {
      return (await MojoApiFactory.notifications.get(
        'notifications/counts'
      )) as AxiosResponse<NotificationCounts>;
    },
    {
      onError: () => {
        console.error('Error fetching notification counts');
      },
    }
  );

  useEffect(() => {
    if (data) {
      setNotificationCounts(data.data);
    }
  }, [data, setNotificationCounts]);

  return null;
};
