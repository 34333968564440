import { useTranslation } from 'react-i18next';

import { Button } from '../ui';

import { trackCommentEvent } from '../../models/comments/analytics';

const CommentActionReply = ({ comment, onClick }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(comment);

    trackCommentEvent('replyClick', comment);
  };

  return (
    <div className="comment__action comment__action--reply">
      <Button onClick={handleClick} aria-label={t('comments.actions.reply')}>
        {t('comments.actions.reply')}
      </Button>
    </div>
  );
};

export default CommentActionReply;
