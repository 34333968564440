import React, { Fragment } from 'react';

import { Spinner } from '../../components/ui';
import ChannelList from '../../components/channel-list';
import EmptyFeed from '../../components/feed/empty-feed';
import EndOfFeed from '../../components/feed/end-of-feed';

import useProfileChannelsFetcher from '../../common/use-profile-channels-fetcher';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const ProfileChannels = ({ profileId, isOwner, analyticsData }) => {
  const { channels, isFetching } = useProfileChannelsFetcher({ profileId });

  const analyticsDataList = {
    ...analyticsData,
    location: 'profile_channels',
  };

  const channelIds = channels.map((c) => c.id);

  return (
    <div className="user-profile-channels" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <Spinner center loading={isFetching}>
        {channelIds.length ? (
          <>
            <ChannelList
              channelIds={channelIds}
              direction="vertical"
              analyticsData={analyticsDataList}
            />
            <EndOfFeed />
          </>
        ) : (
          <EmptyFeed />
        )}
      </Spinner>
    </div>
  );
};

export default ProfileChannels;
