import { RootPatronState } from '../../common/use-patron-selector';
import { Channel } from './types';

type ChannelState = RootPatronState['channels'];

const addChannel = (state: ChannelState, channel: Channel): ChannelState => ({
  ...state,
  [channel.id]: channel,
});

const addChannels = (
  state: ChannelState,
  channels: Channel[]
): ChannelState => {
  const newChannels = channels.reduce((obj, channel, originalIndex) => {
    const channelId = Number(channel.id);
    return {
      ...obj,
      [channelId]: {
        ...channel,
        originalIndex: state[channelId]
          ? state[channelId].originalIndex
          : originalIndex,
      },
    };
  }, {});

  return {
    ...state,
    ...newChannels,
  };
};

const followChannel = (
  state: ChannelState,
  id: string | number
): ChannelState => {
  const followerCount = state[Number(id)].follower_count + 1;

  return updateChannel(state, id, {
    following: true,
    follower_count: followerCount,
  });
};

const unfollowChannel = (
  state: ChannelState,
  id: string | number
): ChannelState => {
  const followerCount = state[Number(id)].follower_count - 1;

  return updateChannel(state, id, {
    following: false,
    follower_count: followerCount,
  });
};

const updateChannel = (
  state: ChannelState,
  id: string | number,
  attrs = {}
): ChannelState => {
  const channelId = Number(id);
  return {
    ...state,
    [channelId]: {
      ...state[channelId],
      ...attrs,
    },
  };
};

export default {
  addChannel,
  addChannels,
  followChannel,
  unfollowChannel,
  updateChannel,
};
