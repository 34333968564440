import { useRef } from 'react';
import { connect } from 'react-redux';
import {
  trackAssistantNotificationsHistoryCardView,
  trackAssistantNotificationsHistoryCardClick,
} from '../../models/assistant/analytics';
import useInview from '../../common/use-inview';
import { timeAgoOrDate } from '../../lib/date-formatter';
import { programSelectors } from '../../models/program';
import { Avatar } from '../ui';
import AssistantNotificationSubject from './assistant-notification-subject';
import './assistant.scss';
import { AssistantResponse } from '../../models/assistant/interfaces/AssistantResponse';
import { AnalyticsData } from '../../models/assistant/interfaces/AnalyticsData';
import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantLink } from './assistant-link';

type StateProps = {
  programThemeIconUrl: ReturnType<typeof programSelectors.getProgramThemeIcon>;
};

type OwnProps = {
  data: AssistantResponse;
  analyticsData?: AnalyticsData;
};

type NotificationItemProps = StateProps & OwnProps;

function NotificationItem({
  data: item,
  analyticsData,
  programThemeIconUrl,
}: NotificationItemProps) {
  const handleClick = () => {
    trackAssistantNotificationsHistoryCardClick({
      ...analyticsData,
      notification_id: item.id,
      ...(item.subject && item.subject.id && { subject_id: item.subject.id }),
    });
  };

  const handleInview = () => {
    trackAssistantNotificationsHistoryCardView({
      ...analyticsData,
      notification_id: item.id,
      ...(item.subject && item.subject.id && { subject_id: item.subject.id }),
    });
  };

  const ref = useRef<HTMLDivElement>(null);

  useInview({
    ref,
    once: true,
    onInview: handleInview,
  });

  const avatar = (
    <Avatar
      src={(item.avatar && item.avatar.url) || programThemeIconUrl || undefined}
      bgColor={item.avatar?.color}
    />
  );

  return (
    <div className="assistant__notification-item" ref={ref}>
      <div className="assistant__notification-item__avatar">
        {item.profile ? (
          <AssistantLink to={`/profiles/${item.profile.id}`}>
            {avatar}
          </AssistantLink>
        ) : (
          avatar
        )}
      </div>

      <div className="assistant__notification-item__content">
        <p className="assistant__notification-item__description">{item.text}</p>
        <p className="assistant__notification-item__date">
          {timeAgoOrDate(item.created_at)}
        </p>
        {item.subject ? (
          <AssistantNotificationSubject data={item} onClick={handleClick} />
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  programThemeIconUrl: programSelectors.getProgramThemeIcon(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(NotificationItem);
