import React from 'react';
import useScreenSize from '../../common/use-screen-size';
import './home-section.scss';
import { ChannelCardV2 } from './channel-card/channel-card';
import ContentCardPlaceholder from '../placeholders/content-card-placeholder';
import { ContentCard } from './content-card/content-card';
import useContentFeed from '../../common/use-content-feed';
import ViewTrigger from '../view-trigger';
import { Content } from '../../models/content/types';

type IHomeSection = {
  title: string;
  channelId: string;
  contents: Array<Content>;
  minLength: number;
  maxLength: number;
  isFetching: boolean;
  analyticsData: IAnalyticsData;
};

export const HomeSection: React.FC<IHomeSection> = ({
  title,
  channelId,
  contents,
  minLength = 1,
  maxLength = 4,
  isFetching,
  analyticsData,
}) => {
  const { width } = useScreenSize();
  const needtoFetch = contents === undefined && channelId && !isFetching;

  const {
    loaded: channelLoaded,
    feedItems: feedContents,
    fetchFeed: fetchContents,
  } = useContentFeed({
    channelId: channelId,
    perPage: 4,
    manualFetch: true,
  });

  if (!needtoFetch && !isFetching && contents.length < minLength) return null;

  const topicsToShow = (needtoFetch ? feedContents : contents).slice(
    0,
    maxLength
  );
  const isVertical = width < 500;
  const TopicComponent = isVertical
    ? ContentCard.Vertical
    : ContentCard.Horizontal;

  const headingId = `${channelId}--title`;

  return (
    <section className="home-section" aria-labelledby={headingId}>
      {needtoFetch && !channelLoaded ? (
        <ViewTrigger offset="200px" onInview={fetchContents} />
      ) : null}
      {channelLoaded && (
        <>
          <div className="section-header">
            <h2 id={headingId} className="title">
              {title}
            </h2>
          </div>
          <ChannelCardV2 channelId={channelId} analyticsData={analyticsData} />
          <div className="topic-view">
            {isFetching
              ? [...Array(maxLength)].map((_, idx) => (
                  <ContentCardPlaceholder key={idx} />
                ))
              : topicsToShow.map((topic) => (
                  <TopicComponent
                    key={topic.id}
                    content={topic}
                    analyticsData={analyticsData}
                  />
                ))}
          </div>
        </>
      )}
    </section>
  );
};
