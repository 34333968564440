import type { String } from 'ts-toolbelt';

export const reactRouterRegex = {
  numerical: '([0-9]+)',
  alphanumeric: '([a-zA-Z0-9-]+)',
  alphanumericAtleastOneNonNumeric: '([a-zA-Z0-9-]*[a-zA-Z][a-zA-Z0-9-]*)',
} as const;

export type ExtractPathParams<TPath extends string> = String.Split<
  TPath,
  '/'
>[number] extends infer S
  ? S extends `:${string}`
    ? S
    : never
  : never;

export type StripRouterPathIdentifier<T extends string> =
  T extends `:${infer U}` ? U : never;

/**
 * Adds a regex to a React Router path parameter
 *
 * @example
 * addRegexToPath('/path/:id', ':id', 'numerical') // => '/path/:id([0-9]+)'
 **/
export const addRegexToPath = <TPath extends string>(
  path: TPath,
  param: ExtractPathParams<TPath>,
  regex: keyof typeof reactRouterRegex
) => {
  return path.replace(param, `${param}${reactRouterRegex[regex]}`);
};
