import React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionImages,
  submissionImageUrls,
  submissionIsSubmitting,
  submissionIsEdit,
} from '../../../models/content-submission/atoms';

import DropzoneInput from '../inputs/dropzone';
import TitleInput from '../inputs/title';
import DescriptionInput from '../inputs/description';
import ImageInput from '../inputs/image';
import BodyInput from '../inputs/body';
import DropzoneOverlay from '../inputs/dropzone-overlay';
import ImagePreview from '../image-preview';

import { trackLoadImagePreviewRemoveClick } from '../../../models/content-submission/analytics';

const ArticleForm = () => {
  const [imageUrls, setImageUrls] = useRecoilState(submissionImageUrls);
  const setImages = useSetRecoilState(submissionImages);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const isEdit = useRecoilValue(submissionIsEdit);

  const { t } = useTranslation();

  const handleImageReset = () => {
    trackLoadImagePreviewRemoveClick();

    setImages([]);
    setImageUrls([]);
  };

  const showImageInput = !imageUrls.length;

  return (
    <DropzoneInput
      accept="image/jpeg, image/png"
      maxSize={10485760} // 10mb
    >
      {({
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        draggedFilesErrorMessage,
        droppedFilesErrorMessage,
      }) => (
        <div
          className="content-submission-form content-submission-form--article"
          {...getRootProps()}
          role="none"
          tabIndex={-1}
          onClick={null}
          onKeyDown={null}
        >
          <div className="content-submission-form__header">
            <div className="content-submission-form__text">
              <TitleInput
                aria-label={t('content_submission.field_title')}
                placeholder={t('content_submission.field_title')}
              />
              <DescriptionInput
                aria-label={t('content_submission.field_description_optional')}
                placeholder={t('content_submission.field_description_optional')}
              />
            </div>
            <div className="content-submission-form__cover-image">
              {showImageInput ? (
                <ImageInput
                  inputProps={!isSubmitting && getInputProps()}
                  openFileBrowser={open}
                  errorMessage={droppedFilesErrorMessage}
                  isDisabled={isEdit}
                />
              ) : (
                <ImagePreview
                  onReset={!isSubmitting && !isEdit && handleImageReset}
                />
              )}
            </div>
          </div>

          <div className="content-submission-form__body">
            <BodyInput
              placeholder={t('content_submission.field_article_placeholder')}
            />
          </div>

          {isDragActive ? (
            <DropzoneOverlay
              placeholderText={t('content_submission.field_image_placeholder')}
              errorText={draggedFilesErrorMessage}
            />
          ) : null}
        </div>
      )}
    </DropzoneInput>
  );
};

export default ArticleForm;
