import api from '../config/api';

class MessageService {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  fetch = () => api.get(`/messages/${this.id}/`);
  view = () => api.post(`/messages/${this.id}/view/`);
}

export default MessageService;
