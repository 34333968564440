import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import withRerenderPrevent from '../../common/with-rerender-prevent';

import useScreenTitle from '../../common/use-screen-title';
import useContentFeed from '../../common/use-content-feed';

import Page, {
  PageSidebar,
  PageMain,
  PageTitle,
} from '../../components/ui/page';
import PageNav from '../../components/page-nav';
import Feed from '../../components/feed';
import NotFound from '../not-found';

import InitiativeTagService from '../../services/initiative';
import {
  FeaturedIcon,
  LatestIcon,
  Spinner,
  TrendingIcon,
} from '../../components/ui';
import { AttributesObject } from '../../lib/types/json-api';
import { InitiativeTag } from '../../models/initiative/types';

type InitiativeFeedProps = RouteComponentProps<{ initiativeId: string }>;

const InitiativeFeed = ({
  match: {
    params: { initiativeId },
  },
}: InitiativeFeedProps) => {
  const { t } = useTranslation();

  const [initiative, setInitiative] =
    useState<AttributesObject<InitiativeTag>>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    new InitiativeTagService({ ids: [initiativeId] })
      .fetch()
      .then((res) =>
        setInitiative(
          res.data.data.filter((tag) => tag.id === initiativeId)[0]?.attributes
        )
      )
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, [initiativeId, setInitiative]);

  const title = () => initiative?.name;

  useScreenTitle(title());

  const analyticsData = {
    initiative_tag_id: initiativeId,
    initiative_tag_name: title(),
    location: 'initiative_feed',
  };

  const { fetching, feedItems, page, canLoadMore, fetchFeed } = useContentFeed({
    filter: 'initiative',
    initiativeId,
  });

  if (isLoading) return <Spinner />;
  if (!initiative) return <NotFound />;

  return (
    <Page className="initiative-feed">
      <PageSidebar>
        <PageTitle>{title()}</PageTitle>

        <PageNav
          items={[
            {
              title: t('content.featured'),
              url: '/feed/featured',
              iconComponent: <FeaturedIcon />,
            },
            {
              title: t('landing.latest'),
              url: '/feed/latest',
              iconComponent: <LatestIcon />,
            },
            {
              title: t('landing.trending'),
              url: '/feed/trending',
              iconComponent: <TrendingIcon />,
            },
          ]}
        />
      </PageSidebar>

      <PageMain>
        <Feed
          items={feedItems}
          page={page}
          fetching={fetching}
          canLoadMore={canLoadMore}
          fetchFeed={fetchFeed}
          analyticsData={analyticsData}
          contentTitleTag="h2"
        />
      </PageMain>
    </Page>
  );
};

export default withRerenderPrevent(InitiativeFeed);
