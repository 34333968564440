import {
  ComponentProps,
  DetailedHTMLProps,
  ElementType,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  Ref,
} from 'react';

type TextProps<C extends ElementType> = DetailedHTMLProps<
  HTMLAttributes<C>,
  C
> & {
  as?: C;
  className?: string;
};

type TextPropsWithElementProps<C extends ElementType> = PropsWithChildren<
  TextProps<C>
> &
  Omit<ComponentProps<C>, keyof TextProps<C>>;

const Text = <ComponentType extends ElementType = 'span'>(
  {
    as,
    children,
    className,
    ...restProps
  }: TextPropsWithElementProps<ComponentType>,
  ref: Ref<ComponentType>
) => {
  const Component: ElementType = as || 'span';

  return (
    <Component ref={ref} className={className} {...restProps}>
      {children}
    </Component>
  );
};

const ForwardedRefText = forwardRef(Text);
ForwardedRefText.displayName = 'Text';
ForwardedRefText.defaultProps = {
  as: 'span',
};

export default ForwardedRefText;
