import { track } from '../analytics/helpers';
import {
  isIOSUIWebView,
  isAndroidWebView,
} from '../../lib/is-web-view-helpers';
import { androidEvents, iosEvents, webEvents } from './keys';

const detectEventGroup = () => {
  if (isIOSUIWebView()) return iosEvents;
  if (isAndroidWebView()) return androidEvents;
  return webEvents;
};

export const trackCommentSuggestionStart = (traits = {}) => {
  track(detectEventGroup().COMMENT_AI_SUGGESTION_START, traits);
};

export const trackCommentSuggestionCancel = (traits = {}) => {
  track(detectEventGroup().COMMENT_AI_SUGGESTION_CANCEL, traits);
};

export const trackCommentSuggestionApply = (traits = {}) => {
  track(detectEventGroup().COMMENT_AI_SUGGESTION_APPLY, traits);
};

export const trackCommentSuggestionRetry = (traits = {}) => {
  track(detectEventGroup().COMMENT_AI_SUGGESTION_RETRY, traits);
};
