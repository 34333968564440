import { Container } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../loading-skeleton';
import styles from './search-topics-v2.module.scss';

export function TopicsLoadingSkeleton() {
  return (
    <Container shape="large" shadow="light" className={styles.TopicCard}>
      <div className={styles.TopicCover}>
        <LoadingSkeleton height={'100%'} borderRadius={0} marginTop={0} />
      </div>
      <Container
        tag="div"
        shape="large"
        shadow="extra-light"
        className={styles.Avatar}
      />
      <div className={styles.CardContainer}>
        <div className={styles.TopicCardHeader}>
          <LoadingSkeleton width="33%" height={15} />
          <div style={{ width: '40%' }}>
            <div className={styles.TopicDetails}>
              <LoadingSkeleton width="40%" height={10} />
              <LoadingSkeleton
                width="50%"
                height={33}
                borderRadius={'0.375rem'}
              />
            </div>
          </div>
        </div>
        <div className={styles.TopicDescription}>
          <LoadingSkeleton width="100%" height={10} marginTop={8} />
          <LoadingSkeleton width="100%" height={10} marginTop={8} />
          <LoadingSkeleton width="75%" height={10} marginTop={8} />
        </div>
      </div>
    </Container>
  );
}
