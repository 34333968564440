import {
  Dispatch,
  JSXElementConstructor,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { programSelectors } from '../../models/program';
import useInview from '../../common/use-inview';
import {
  trackAssistantNeedsAttentionCardClick,
  trackAssistantNeedsAttentionCardView,
} from '../../models/assistant/analytics';
import { Avatar } from '../ui';
import AssistantApproveContent from './assistant-approve-content';
import AssistantPoll from './assistant-poll';
import AssistantAcknowledgeContent from './assistant-acknowledge-content';
import { AnalyticsData } from '../../models/assistant/interfaces/AnalyticsData';
import { AssistantResponse } from '../../models/assistant/interfaces/AssistantResponse';
import { Card } from '../../models/assistant/interfaces/subject/Summary';
import { SubjectType } from '../../models/assistant/interfaces/Subject';
import { Button } from '../../models/assistant/interfaces/Button';
import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantLink } from './assistant-link';

type SubjectComponentProps = {
  subject: Card;
  onAcknowledge: (button?: Button) => void;
  setItemLinkEnabled: Dispatch<SetStateAction<boolean>>;
};

const AssistantTodoSubjects: Partial<
  Record<SubjectType, JSXElementConstructor<SubjectComponentProps>>
> = {
  content_approval: AssistantApproveContent,
  content_acknowledgement: AssistantAcknowledgeContent,
  poll: AssistantPoll,
};

type StateProps = {
  programThemeIconUrl: ReturnType<typeof programSelectors.getProgramThemeIcon>;
};

type OwnProps = {
  item: AssistantResponse<Card>;
  onAcknowledge?: () => void;
  analyticsData?: AnalyticsData;
};

type AssistantTodoItemProps = StateProps & OwnProps;

function AssistantTodoItem({
  item,
  programThemeIconUrl,
  onAcknowledge,
  analyticsData,
}: AssistantTodoItemProps) {
  const [itemLinkEnabled, setItemLinkEnabled] = useState(true);

  const SubjectComponent = AssistantTodoSubjects[item.subject.type];

  const acknowledgeItem = (button?: Button) => {
    trackAssistantNeedsAttentionCardClick({
      ...analyticsData,
      type: item.subject.type,
      ...(item.subject as Card).tracking_context,
      ...(button && button.tracking_context),
    });
    onAcknowledge && onAcknowledge();
  };

  const handleTodoView = () => {
    trackAssistantNeedsAttentionCardView({
      ...analyticsData,
      type: item.subject.type,
      ...(item.subject as Card).tracking_context,
    });
  };

  const ref = useRef<HTMLDivElement>(null);
  useInview({
    ref,
    once: true,
    onInview: handleTodoView,
  });

  return (
    <div className="assistant__todo-item" ref={ref}>
      <div className="assistant__todo-item__attribution">
        <div className="assistant__todo-item__attribution__avatar">
          <Avatar
            src={
              (item.avatar && item.avatar.url) ||
              programThemeIconUrl ||
              undefined
            }
            bgColor={item.avatar?.color}
            aria-hidden //this is a decorative image, not providing infomration. hiding to screen readers rather than proviging an alt attribute for the img.
          />
        </div>
        <div className="assistant__todo-item__attribution__text">
          {itemLinkEnabled ? (
            <AssistantLink
              to={`/assistant/todos/${item.id}`}
              className="assistant__todo-item__attribution__link"
            >
              {item.attribution_text}
            </AssistantLink>
          ) : (
            <div className="assistant__todo-item__attribution__link">
              {item.attribution_text}
            </div>
          )}
        </div>
      </div>
      {SubjectComponent ? (
        <SubjectComponent
          subject={item.subject}
          onAcknowledge={acknowledgeItem}
          setItemLinkEnabled={setItemLinkEnabled}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  programThemeIconUrl: programSelectors.getProgramThemeIcon(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantTodoItem);
