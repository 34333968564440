import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Button from './button';
import Icon from './icon';

const ToolbarButton = ({ label, icon, name, title, active, onMouseDown }) => {
  const className = cx({ 'is-active': active });
  const { t } = useTranslation();

  return (
    <Button
      name={name}
      title={title}
      className={className}
      onMouseDown={onMouseDown}
      onKeyPress={(e) => {
        if (e.key !== 'Enter' && e.key !== ' ') return;

        onMouseDown(e);
      }}
      aria-label={t(label)}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const cmdBtn = window.navigator.platform.match(/Mac/i) ? 'Cmd' : 'Ctrl';

const INLINE_STYLES = [
  {
    label: 'content_submission.bold',
    icon: 'format_bold',
    name: 'BOLD',
    title: `${cmdBtn} + B`,
  },
  {
    label: 'content_submission.italic',
    icon: 'format_italic',
    name: 'ITALIC',
    title: `${cmdBtn} + I`,
  },
  {
    label: 'content_submission.underline',
    icon: 'format_underlined',
    name: 'UNDERLINE',
    title: `${cmdBtn} + U`,
  },
];

const BLOCK_STYLES = [
  {
    label: 'content_submission.bullet_list',
    icon: 'format_list_bulleted',
    name: 'unordered-list-item',
  },
  {
    label: 'content_submission.number_list',
    icon: 'format_list_numbered',
    name: 'ordered-list-item',
  },
];

const RichEditorToolbar = ({
  currentStyle,
  currentEntity,
  currentBlock,
  onStyleClick,
  onBlockClick,
  onLinkClick,
  onImageClick,
  showImageButton,
}) => (
  <>
    {INLINE_STYLES.map(({ label, icon, name, title }) => (
      <ToolbarButton
        label={label}
        icon={icon}
        name={name}
        title={title}
        active={currentStyle && currentStyle.has(name)}
        onMouseDown={onStyleClick}
        key={name}
      />
    ))}

    {BLOCK_STYLES.map(({ label, icon, name }) => (
      <ToolbarButton
        label={label}
        icon={icon}
        name={name}
        active={currentBlock && currentBlock.getType() === name}
        onMouseDown={onBlockClick}
        key={name}
      />
    ))}

    <ToolbarButton
      label="content_submission.link"
      icon="link"
      name="LINK"
      title={`${cmdBtn} + K`}
      active={currentEntity && currentEntity.getType() === 'LINK'}
      onMouseDown={onLinkClick}
    />

    {showImageButton ? (
      <ToolbarButton
        label="content_submission.image"
        icon="image"
        name="IMAGE"
        title={`${cmdBtn} + P`}
        active={currentBlock && currentBlock.getType() === 'IMAGE'}
        onMouseDown={onImageClick}
      />
    ) : null}
  </>
);

export default RichEditorToolbar;
