import { createSelector } from 'reselect';
import { RootPatronState } from '../../common/use-patron-selector';

const programMembership = (state: RootPatronState) => state.programMembership;

export const getProgramMembership = createSelector(
  [programMembership],
  (programMembership) => programMembership
);

export const getProgramMembershipId = createSelector(
  [getProgramMembership],
  (programMembership) => programMembership.id
);

export const getProgramMembershipStatus = createSelector(
  [getProgramMembership],
  (programMembership) => programMembership.status
);

export const getProgramMembershipIsActive = createSelector(
  [getProgramMembershipStatus],
  (status) => status === 'active'
);

export const getProgramMembershipHidePrivateWarning = createSelector(
  [getProgramMembership],
  (programMembership) => !!programMembership.hide_private_profile_warning
);

export const getProgramMembershipCanEditPassword = createSelector(
  [getProgramMembership],
  (programMembership) => !!programMembership.can_edit_password
);

export default {
  getProgramMembership,
  getProgramMembershipId,
  getProgramMembershipStatus,
  getProgramMembershipIsActive,
  getProgramMembershipHidePrivateWarning,
  getProgramMembershipCanEditPassword,
};
