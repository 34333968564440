import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  TopicShortcut,
  TopicShortcutsResponse,
} from '../models/channels/types';
import { shortcutsByTopicId } from '../models/topic/shortcuts/recoil-state';
import ChannelService from '../services/channel';
import _ from 'lodash';

export function useTopicShortcutsFetcher(topicId: string) {
  const [shortcuts, setShortcuts] = useRecoilState(shortcutsByTopicId(topicId));
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);

  const handleFetchSuccess = useCallback(
    (res: AxiosResponse<TopicShortcutsResponse>) => {
      setShortcuts(
        res.data.data
          .map((shortcut) => shortcut.attributes as TopicShortcut)
          .sort((a, b) => a.position - b.position)
      );
      setIsError(false);
      setIsFetching(false);
    },
    [setShortcuts]
  );

  const handleFetchFailure = useCallback(() => {
    setIsError(true);
    setIsFetching(false);
  }, []);

  const fetchShortcuts = useCallback(async () => {
    setIsFetching(true);

    try {
      const service = new ChannelService(topicId);
      const res = await service.fetchShortcuts();

      handleFetchSuccess(res);
    } catch (err) {
      if (_.get(err, 'response')) {
        handleFetchFailure();
      } else {
        throw err;
      }
    }
  }, [topicId, handleFetchFailure, handleFetchSuccess]);

  useEffect(() => {
    void fetchShortcuts();
  }, [fetchShortcuts]);

  return {
    shortcuts,
    isError,
    isFetching,
  };
}
