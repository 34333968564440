import React, { useCallback } from 'react';
import {
  ShortcutSearchResponse,
  ShortcutSearchResultItem,
} from '../shortcuts/shortcuts';
import { SearchType } from '../search-screen';
import ShortListContainer from './short-list-container';
import styles from './shortcuts-short-list.module.scss';
import { trackSearchPreviewResultClick } from '../../../../models/analytics';

interface ShortcutsShortListProps {
  shortcutsData: ShortcutSearchResponse[];
  error: boolean;
  viewAllUrl: string;
  searchType: SearchType;
}

const ShortcutsShortList: React.FC<ShortcutsShortListProps> = ({
  error,
  viewAllUrl,
  shortcutsData,
  searchType,
}) => {
  const handleItemClick = useCallback(() => {
    trackSearchPreviewResultClick({
      result_type: searchType,
    });
  }, [searchType]);
  const shortcutsShortList = shortcutsData?.[0]?.data?.slice(0, 6) || [];

  return (
    <ShortListContainer
      error={error}
      viewAllUrl={viewAllUrl}
      dataType={searchType}
      dataCount={shortcutsShortList.length}
    >
      <div className={styles.Shortcuts}>
        {shortcutsShortList.map((item) => (
          <ShortcutSearchResultItem
            key={item.id}
            item={item.attributes}
            onClick={handleItemClick}
          />
        ))}
      </div>
    </ShortListContainer>
  );
};

export default ShortcutsShortList;
