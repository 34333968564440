/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import {
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  RichUtils,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import addLinkPlugin from './rich-editor-plugins/addLinkPlugin';
import createImagePlugin from '@draft-js-plugins/image';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import { stateToHTML } from '../../lib/draft-export-html/stateToHTML';
import { stateFromHTML } from 'draft-js-import-html';
import { parse } from 'node-html-parser';
import moment from 'moment';

import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import alignmentToolStyles from './alignmentToolStyles.module.css';
import buttonStyles from './alignmentToolButtonStyles.module.css';

import RichEditorToolbar from './rich-editor-toolbar';
import { Spinner } from './index';
import ContentSubmissionService from '../../services/content-submission';
import { programSelectors } from '../../models/program';
import { createUserTagPlugin } from './rich-editor-plugins/user-tag-plugin';
import replaceTagsWithEntities from './rich-editor-plugins/user-tag-plugin/replaceTagsWithEntities';
import { Feature, getFeatureFlag } from '../../models/features/features';

export const WORD_COUNT_DATA_TEST_ID = 'text-word-count';
const WordMeta = ({ val }) => {
  const { t } = useTranslation();
  const wc = val
    ? val
        .trim()
        .split(/\s+/)
        .filter((w) => w).length
    : 0;

  // Average reading time is 265 wpm
  const duration = moment.duration(wc / 265, 'minutes');

  return (
    <div role="status" className="rich-editor-meta">
      <div
        className="rich-editor-wordcount"
        data-testid={WORD_COUNT_DATA_TEST_ID}
      >
        <Trans i18nKey="common.n_word" count={wc}>
          <strong>{{ wc }}</strong> words
        </Trans>
      </div>

      {!!wc && (
        <div className="rich-editor-readtime">
          {t('common.read_time')}
          &nbsp;
          <strong>
            {duration.asSeconds() < 60
              ? t('common.a_few_seconds')
              : t('common.n_minute', {
                  count: Math.round(duration.asMinutes()),
                })}
          </strong>
        </div>
      )}
    </div>
  );
};

const LinkComponent = (props) => {
  return (
    <a
      {...props}
      className="link"
      rel="noopener noreferrer"
      target="_blank"
      aria-label={props.href}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(props.href, '_blank');
      }}
    >
      {props.children}
    </a>
  );
};

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin({
  theme: {
    alignmentToolStyles,
    buttonStyles,
  },
});
const { AlignmentTool } = alignmentPlugin;

const importOptions = {
  customInlineFn: (element, inlineCreators) => {
    const { Entity } = inlineCreators;
    if (element.tagName === 'IMG') {
      const width = parseInt(element.getAttribute('width'));
      const style = element.style;
      let alignment;
      if (style.float) {
        alignment = style.float;
      }
      if (style.marginRight) {
        alignment = alignment || 'center';
      }
      const src = element.getAttribute('src');
      return Entity('IMAGE', {
        src: src,
        width: `${width}`,
        alignment: alignment || 'default',
      });
    }
  },
};

function getInitialState(content, tagUserEnabled) {
  if (!content) {
    return EditorState.createEmpty();
  }
  let root = parse(content);
  // ensure each <img> tag is wrapped in a <figure> for draft-js-import-HTML compatibility
  root.querySelectorAll('img').forEach((image) => {
    if (image.parentNode.rawTagName != null) {
      image.parentNode.rawTagName = 'figure';
    } else {
      let parentNode = image.parentNode;
      let wrapper = parse('<figure></figure>').childNodes[0];
      parentNode.exchangeChild(image, wrapper);
      wrapper.appendChild(image);
    }
  });
  let contentState = stateFromHTML(root.outerHTML, importOptions);
  if (tagUserEnabled) {
    contentState = replaceTagsWithEntities(contentState);
  }
  return EditorState.createWithContent(contentState);
}

class RichEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    onLinkClick: PropTypes.func,
    onChange: PropTypes.func,
    trackingContext: PropTypes.shape({
      contentSection: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.hiddenFileInput = createRef(null);
    this.editorEl = createRef(null);
    this.editorContainer = createRef(null);
    this.state = {
      editorState: getInitialState(props.defaultValue, props.tagUserEnabled),
      service: new ContentSubmissionService(),
      uploadingImage: false,
    };

    this.imagePlugin = createImagePlugin();

    this.plugins = [
      blockDndPlugin,
      focusPlugin,
      alignmentPlugin,
      resizeablePlugin,
      this.imagePlugin,
      createLinkifyPlugin({ component: LinkComponent }), // For the normal link text to show as link
      addLinkPlugin, // adding Link as Plugin
    ];

    if (props.tagUserEnabled) {
      this.userTagPlugin = createUserTagPlugin(this.editorContainer);
      this.plugins.push(this.userTagPlugin);
    }
  }

  keyBindingFn = (e) => {
    const result =
      e.key === 'k' && KeyBindingUtil.hasCommandModifier(e)
        ? this.handleToolbarLinkClick(e)
        : getDefaultKeyBinding(e);
    // Must return undefined if not handled, to let plugins handle it
    return result || undefined;
  };

  lastContent = null;

  handleChange = (editorState) => {
    this.setState({ editorState }, () => {
      // Draft.js will  trigger change even on navigation. Only trigger onChange if content has changed
      if (this.lastContent === editorState.getCurrentContent()) {
        return;
      }
      this.lastContent = editorState.getCurrentContent();
      const htmlOutput = stateToHTML(this.currentContent, this.exportOptions)
        .replace(/\n/g, '')
        .replace(/&nbsp;/g, ' ');
      this.props.onChange(htmlOutput);
    });
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handleReturn = (e) => {
    if (e.shiftKey) {
      const newState = RichUtils.insertSoftNewline(this.state.editorState);
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  //TODO: This event handler should allow to create nest lists, but it was commented out since 2019. What should we do with it?
  handleTab = (e) => {
    const isListType =
      this.currentBlock.getType() === 'unordered-list-item' ||
      this.currentBlock.getType() === 'ordered-list-item';

    if (isListType) {
      e.preventDefault();
      const maxDepth = 3;
      const newState = RichUtils.onTab(e, this.state.editorState, maxDepth);
      this.handleChange(newState);
    }
  };

  handleClick = (e) => {
    if (!!e.key || this.props.disabled)
      // check event detail to ignore keypress
      return;
    if (
      !!e.target.closest('.DraftEditor-root') &&
      !e.target.className.includes('DraftEditor-root') && // allow focus on clicking the wrapping editor div
      !e.target.closest('.public-DraftEditorPlaceholder-root') // allow focus on clicking the placeholder
    ) {
      // prevent IE11 flicker from double focus
      return;
    }
    this.editorEl.current?.focus();
  };

  handleToolbarStyleClick = (e) => {
    if (this.props.disabled) return;

    e.preventDefault();

    this.handleChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.currentTarget.name)
    );
  };

  handleToolbarBlockClick = (e) => {
    if (this.props.disabled) return;

    e.preventDefault();

    this.handleChange(
      RichUtils.toggleBlockType(this.state.editorState, e.currentTarget.name)
    );
  };

  handleToolbarLinkClick = (e) => {
    if (this.props.disabled) return;

    e.preventDefault();

    const selection = this.currentSelection;
    const hasSelection = !!(
      selection.getEndOffset() - selection.getStartOffset()
    );
    const isLink = !!(
      this.currentEntity && this.currentEntity.getType() === 'LINK'
    );

    if (!hasSelection) return;

    this.props.onLinkClick();

    !isLink ? this.addLink() : this.removeLink();
    return 'handled';
  };

  handleToolbarImageClick = (e) => {
    if (this.props.disabled) return;

    e.preventDefault();

    this.hiddenFileInput.current.click();
  };

  selectImage = async (e) => {
    this.setState({ uploadingImage: true });
    const file = e.target.files[0];
    try {
      const filename = file.name.replace(/[^\w-_.]/g, '');
      const res = await this.state.service.fetchPrivateMediaUploadUrls(
        filename
      );

      const uploadUrl = res.data.upload_url;
      const viewUrl = res.data.view_url;
      await this.state.service.createMediaAsset(uploadUrl, file);

      const persistRes = await this.state.service.persistMediaAsset(viewUrl);
      const persistUrl = persistRes.data.url;
      this.handleChange(
        this.imagePlugin.addImage(this.state.editorState, persistUrl, {
          width: 80,
        })
      );
    } catch (err) {
      console.error('Uploading the image failed');
    }

    this.setState({ uploadingImage: false });
  };

  customBlockStyleFn = (block) => {
    if (block.getType() === 'atomic') {
      const entityKey = block.getEntityAt(0);
      if (entityKey === null) return;
      const entity = this.currentContent.getEntity(entityKey);
      if (entity != null && entity.getType() === 'IMAGE') {
        const data = entity.getData();
        return {
          left: 'draftImgFloatLeft',
          right: 'draftImgFloatRight',
        }[data.alignment];
      }
    }
  };

  handleKeyPress = (ev) => {
    if (ev.key === 'Space') {
      this.handleClick();
    }
  };

  render() {
    // Nullify placeholder in scenarios where a block type has been chosen, but no text given.
    // https://draftjs.org/docs/api-reference-editor.html#placeholder
    const hidePlaceholder =
      !this.currentContent.hasText() &&
      this.currentContent.getBlockMap().first().getType() !== 'unstyled';

    const className = cx('rich-editor', this.props.className);

    let userTagSuggestions = null;
    if (this.userTagPlugin) {
      const SuggestionPortal = this.userTagPlugin.SuggestionPortal;
      userTagSuggestions = (
        <SuggestionPortal trackingContext={this.props.trackingContext} />
      );
    }

    return (
      <div className={className}>
        <div
          onClick={this.handleClick}
          onKeyPress={this.handleKeyPress}
          ref={this.editorContainer}
        >
          <WordMeta
            val={this.state.editorState.getCurrentContent().getPlainText()}
          />

          {/**
           * For better accessibility support, we should migrate draft-js editor
           * TODO: https://firstup-io.atlassian.net/browse/EE-17809
           */}
          <Editor
            placeholder={!hidePlaceholder ? this.props.placeholder : null}
            editorState={this.state.editorState}
            readOnly={this.props.disabled}
            stripPastedStyles
            keyBindingFn={this.keyBindingFn}
            handleKeyCommand={this.handleKeyCommand}
            handleReturn={this.handleReturn}
            onChange={this.handleChange}
            // onTab={this.handleTab}
            plugins={this.plugins}
            blockStyleFn={this.customBlockStyleFn}
            ref={this.editorEl}
          />
          {userTagSuggestions}
          <div className="rich-editor-toolbar">
            <Spinner loading={this.state.uploadingImage} center>
              <RichEditorToolbar
                currentBlock={this.currentBlock}
                currentEntity={this.currentEntity}
                currentStyle={this.currentStyle}
                showImageButton={this.props.canAddImage}
                onStyleClick={this.handleToolbarStyleClick}
                onBlockClick={this.handleToolbarBlockClick}
                onLinkClick={this.handleToolbarLinkClick}
                onImageClick={this.handleToolbarImageClick}
              />
            </Spinner>
          </div>

          <input
            type="file"
            accept="image/*"
            ref={this.hiddenFileInput}
            onChange={this.selectImage}
            style={{ visibility: 'hidden' }}
          />
          <AlignmentTool />
        </div>
      </div>
    );
  }

  addLink = () => {
    let url = window.prompt(
      this.props.t('content_submission.prompt_add_url'),
      ''
    ); // eslint-disable-line no-alert
    const hasUrl = !!(url && url.match(/\S/));
    const hasProtocol = !!(hasUrl && url.match(/[A-Za-z]*:\/\//));

    if (!hasUrl) return;

    if (!hasProtocol) url = `http://${url}`;

    const contentWithEntity = this.currentContent.createEntity(
      'LINK',
      'MUTABLE',
      { url }
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.push(
      this.state.editorState,
      contentWithEntity,
      'create-entity'
    );

    this.handleChange(
      RichUtils.toggleLink(newEditorState, this.currentSelection, entityKey)
    );
  };

  removeLink = () => {
    const editorState = this.state.editorState;
    const selection = this.currentSelection;

    if (!selection.isCollapsed())
      this.handleChange(RichUtils.toggleLink(editorState, selection, null));
  };

  get exportOptions() {
    return {
      defaultBlockTag: 'p',
      entityStyleFn: (entity) => {
        const entityType = entity.getType();
        if (entityType === 'LINK') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_top',
            },
          };
        }
        if (entityType === 'IMAGE') {
          const data = entity.getData();
          let margin_right, margin_left, float, display, padding_right;
          switch (data.alignment) {
            case 'center':
              margin_right = 'auto';
              margin_left = 'auto';
              display = 'block';
              break;
            case 'left':
              float = data.alignment;
              margin_right = '1em';
              padding_right = '1em';
              break;
            case 'right':
              float = data.alignment;
              margin_left = '1em';
              break;
          }
          const margin_bottom = '1em';

          const width = parseInt(data.width || 80);

          return {
            element: 'img',
            attributes: {
              src: data.src,
              width: `${width}%`,
            },
            style: {
              ...(data.alignment && {
                textAlign: data.alignment,
              }),
              ...(margin_right && {
                marginRight: margin_right,
              }),
              ...(margin_left && {
                marginLeft: margin_left,
              }),
              ...(margin_bottom && {
                marginBottom: margin_bottom,
              }),
              ...(padding_right && {
                paddingRight: padding_right,
              }),
              ...(float && {
                float: float,
              }),
              ...(display && {
                display: display,
              }),
            },
          };
        }
      },
    };
  }

  get currentContent() {
    return this.state.editorState.getCurrentContent();
  }

  get currentSelection() {
    return this.state.editorState.getSelection();
  }

  get currentBlock() {
    return this.state.editorState
      .getCurrentContent()
      .getBlockForKey(this.currentSelection.getStartKey());
  }

  get currentEntity() {
    const offset = this.currentSelection.getStartOffset();
    const entityKey = this.currentBlock.getEntityAt(offset);

    return entityKey ? this.currentContent.getEntity(entityKey) : undefined;
  }

  get currentStyle() {
    return this.state.editorState.getCurrentInlineStyle();
  }
}

const mapStateToProps = (state, ownProps) => ({
  canAddImage: programSelectors.getImagesInArticlesEnabled(state, ownProps),
  tagUserEnabled: getFeatureFlag(state, Feature.TAG_USER_ENABLED),
});

export default connect(mapStateToProps)(withTranslation()(RichEditor));
