import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { channelSelectors } from '../../models/channels';
import { programSelectors } from '../../models/program';

import withRerenderPrevent from '../../common/with-rerender-prevent';
import useScreenTitle from '../../common/use-screen-title';

import Page, { PageHeader } from '../../components/ui/page';
import SearchHeader from '../../components/search-header';
import SecondaryNav from '../../components/secondary-nav';
import ChannelCard from '../../components/channel-card';

import EmptyFeed from '../../components/feed/empty-feed';

import './channels-list.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';

const ChannelListDefault = ({ filter, channelIds, analyticsData }) => {
  const { t } = useTranslation();

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const allChannelsString = newTopicsString
    ? 'nav_links.all_topics'
    : 'nav_links.all_channels';

  const filterChannelsString = newTopicsString
    ? `nav_links.${filter}_topics`
    : `nav_links.${filter}_channels`;

  return (
    <Page className="channels-list">
      <PageHeader>
        <SearchHeader title={<Fragment>{t(filterChannelsString)}</Fragment>} />

        <SecondaryNav
          navItems={[
            { title: t(allChannelsString), url: '/channels' },
            { title: t('discover.recommended'), url: '/channels/recommended' },
            { title: t('discover.newest'), url: '/channels/newest' },
            { title: t('discover.popular'), url: '/channels/popular' },
          ]}
        />
      </PageHeader>

      <div className="container container--slim">
        <div className="channel-list channel-list--vertical">
          {channelIds.length ? (
            channelIds.map((channelId) => (
              <ChannelCard
                channelId={channelId}
                analyticsData={analyticsData}
                key={channelId}
              />
            ))
          ) : (
            <EmptyFeed />
          )}
        </div>
      </div>
    </Page>
  );
};

const ChannelListSearchHeader = ({ filter, channelIds, analyticsData }) => {
  const { t } = useTranslation();

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const allChannelsString = newTopicsString
    ? 'nav_links.all_topics'
    : 'nav_links.all_channels';

  const filterChannelsString = newTopicsString
    ? `nav_links.${filter}_topics`
    : `nav_links.${filter}_channels`;

  return (
    <Page className="channels-list">
      <PageHeader>
        <header className="search-header pt-wallpaper-bg--gradient">
          <h1 className="search-header__title">{t(filterChannelsString)}</h1>
        </header>

        <SecondaryNav
          navItems={[
            { title: t(allChannelsString), url: '/channels' },
            { title: t('discover.recommended'), url: '/channels/recommended' },
            { title: t('discover.newest'), url: '/channels/newest' },
            { title: t('discover.popular'), url: '/channels/popular' },
          ]}
        />
      </PageHeader>

      <div className="container container--slim">
        <div className="channel-list channel-list--vertical">
          {channelIds.length ? (
            channelIds.map((channelId) => (
              <ChannelCard
                channelId={channelId}
                analyticsData={analyticsData}
                key={channelId}
              />
            ))
          ) : (
            <EmptyFeed />
          )}
        </div>
      </div>
    </Page>
  );
};

const ChannelList = ({
  channelsAll,
  channelsRecommended,
  channelsPopular,
  channelsNewest,
  searchBarInHeaderEnabled,
  match: {
    params: { filter = 'all' },
  },
}) => {
  let channelIds;

  const capitalizeWord = (w) => w.charAt(0).toUpperCase() + w.slice(1);
  const filterTitle = capitalizeWord(filter);
  const { t } = useTranslation();

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const channelsString = newTopicsString ? 'Topics' : 'Channels';

  const filterChannelsString = newTopicsString
    ? `nav_links.${filter}_topics`
    : `nav_links.${filter}_channels`;

  useScreenTitle(t(filterChannelsString, `${filterTitle} ${channelsString}`));

  const analyticsData = {
    location: 'channels_all',
    content_channel_filter: filter,
  };

  if (filter === 'recommended')
    channelIds = channelsRecommended.map((c) => c.id);

  if (filter === 'popular') channelIds = channelsPopular.map((c) => c.id);

  if (filter === 'newest') channelIds = channelsNewest.map((c) => c.id);

  if (!channelIds) channelIds = channelsAll.map((c) => c.id);

  const EnabledChannelList = searchBarInHeaderEnabled
    ? ChannelListSearchHeader
    : ChannelListDefault;

  return (
    <EnabledChannelList
      filter={filter}
      channelIds={channelIds}
      analyticsData={analyticsData}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  channelsAll: channelSelectors.getChannelsWithoutHome(state, ownProps),
  channelsRecommended: channelSelectors.getChannelsRecommended(state, ownProps),
  channelsPopular: channelSelectors.getChannelsPopular(state, ownProps),
  channelsNewest: channelSelectors.getChannelsNewest(state, ownProps),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default compose(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(ChannelList);
