import { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

export const Bar = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => props.width}%;
    background-color: #d1043f;
    transition: width 400ms, opacity 1500ms;

    ${(props) =>
      props.width >= 100 &&
      css`
        opacity: 0;
      `};
  }
`;

class LoadingBar extends Component {
  state = {
    progress: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.status === 'active' && prevProps.status !== 'active')
      this.start();

    if (this.props.status === 'complete' && prevProps.status === 'active')
      this.stop();
  }

  render() {
    return <Bar width={this.state.progress} onClick={this.complete} />;
  }

  start() {
    this.setState({ progress: 0 }, this.delayIncreseProgress);
  }

  stop() {
    this.setState({ progress: 100 });
  }

  increaseProgress = () => {
    if (this.state.progress >= this.props.maxProgress) return;

    const newProgress = this.state.progress + this.randBetween(3, 15);
    const progress =
      newProgress > this.props.maxProgress
        ? this.props.maxProgress
        : newProgress;

    this.setState({ progress }, this.delayIncreseProgress);
  };

  delayIncreseProgress = () => {
    setTimeout(this.increaseProgress, this.randBetween(100, 800));
  };

  randBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
}

LoadingBar.defaultProps = {
  maxProgress: 98,
};

export default connect(
  (state) => ({
    status: state.ui.loadingStatus,
  }),
  null
)(LoadingBar);
