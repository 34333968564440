import { useTranslation } from 'react-i18next';
import { Avatar, Link } from '../../../ui';
import React from 'react';

const ChannelContributorsList = ({ channelContributors }) => {
  const { t } = useTranslation();

  return (
    <div className="channel-details-v2__contributors-list">
      {channelContributors.map((contributor) => (
        <Link
          href={`/profiles/${contributor.id}`}
          action="push"
          className={'channel-details-v2__contributor'}
        >
          <div className="channel-details-v2__contributor-avatar">
            <Avatar
              src={contributor.avatar_url}
              bgColor={contributor.avatar_color}
              alt={contributor.display_name}
            />
          </div>
          <div className="channel-details-v2__contributor-details">
            <div className="channel-details-v2__contributor-name">
              {contributor.display_name}
            </div>
            <div className="channel-details-v2__contributor-post_count">
              {t('channel_details.number_post', {
                count: contributor.post_count,
              })}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ChannelContributorsList;
