import { useTranslation } from 'react-i18next';

import { Content } from '../../../models/content/types';
import { ContentCard, ContentCardProps } from '../content-card/content-card';
import FeedHeader, { FeedHeaderProps } from './feed-header';

import './content-layouts.scss';

type ContentLayoutRow = FeedHeaderProps & {
  contents: Content[];
  description: string;
  feedUrl: string;
  viewAllText: string;
  isFetching: boolean;
  minLength: number;
  isTopic: boolean;
  contentTitleTag?: ContentCardProps['titleTag'];
};

const ContentLayoutRow = ({
  id,
  title,
  description,
  viewAllText,
  feedUrl,
  isFetching,
  minLength,
  contents,
  analyticsData,
  isTopic = false,
  contentTitleTag = 'h2',
}: ContentLayoutRow) => {
  const { t } = useTranslation();

  const MAX_ITEMS = 3;
  const displayedContents = contents.slice(0, MAX_ITEMS);

  if (!isFetching && displayedContents.length < minLength) return null;

  // Note: Placeholder not implimented when fetching

  return (
    <>
      <FeedHeader
        id={id}
        title={title}
        description={description}
        viewAllLink={feedUrl}
        viewAllText={viewAllText || `${t('assistant.view_all')} ${title}`}
        isTopic={isTopic}
        analyticsData={analyticsData}
      ></FeedHeader>
      <div className={'content-layout__row'}>
        {displayedContents.map((content, idx) => (
          <div className={'content-layout__row-card'} key={idx}>
            <ContentCard.Vertical
              titleTag={contentTitleTag}
              content={content}
              analyticsData={analyticsData}
              compact={true}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ContentLayoutRow;
