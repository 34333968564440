import { JSXElementConstructor } from 'react';
import ContentActionBookmark from '../../content-actions/content-action-bookmark';
import ContentActionComment from '../../content-actions/content-action-comment';
import ContentActionLike from '../../content-actions/content-action-like';
import ContentActionEdit from '../../content-actions/content-action-edit';
import ContentActionShare from '../../content-actions/content-action-share';

import '../content-card.scss';

interface ICCToolbarAction {
  contentId: number;
  analyticsData: IAnalyticsData;
  showText: boolean;
  wideMode?: boolean;
}

const CCToolbarActionLike = ({
  contentId,
  analyticsData,
  showText = false,
}: ICCToolbarAction) => {
  return (
    <ContentActionLike
      contentId={contentId}
      analyticsData={analyticsData}
      showText={showText}
    />
  );
};

const CCToolbarActionBookmark = ({
  contentId,
  analyticsData,
  showText = false,
  wideMode = false,
}: ICCToolbarAction) => {
  return (
    <ContentActionBookmark
      contentId={contentId}
      analyticsData={analyticsData}
      showText={showText}
      wideMode={wideMode}
    />
  );
};
const CCToolbarActionComment = ({
  contentId,
  analyticsData,
  showText = false,
}: ICCToolbarAction) => {
  return (
    <ContentActionComment
      contentId={contentId}
      analyticsData={analyticsData}
      showText={showText}
    />
  );
};
const CCToolbarActionShare = ({
  contentId,
  analyticsData,
  showText = false,
  wideMode = false,
}: ICCToolbarAction) => {
  return (
    <ContentActionShare
      contentId={contentId}
      analyticsData={analyticsData}
      showText={showText}
      wideMode={wideMode}
    />
  );
};
const CCToolbarActionEdit = ({
  contentId,
  analyticsData,
  showText = false,
  wideMode = false,
}: ICCToolbarAction) => {
  return (
    <ContentActionEdit
      contentId={contentId}
      analyticsData={analyticsData}
      showText={showText}
      wideMode={wideMode}
    />
  );
};

const ContentCardToolbarActions: {
  [key: string]: {
    canShow: (content: any) => boolean;
    element: JSXElementConstructor<ICCToolbarAction>;
  };
} = {
  like: {
    canShow: (content) => content.publication_state === 'published',
    element: CCToolbarActionLike,
  },
  comment: {
    canShow: (content) =>
      content.publication_state === 'published' && content.is_commentable,
    element: CCToolbarActionComment,
  },
  bookmark: {
    canShow: (content) => content.publication_state === 'published',
    element: CCToolbarActionBookmark,
  },
  share: {
    canShow: (content) => content.can_share,
    element: CCToolbarActionShare,
  },
  edit: {
    canShow: (content) =>
      content.is_editable &&
      content.publication_state !== 'archived' &&
      !(content.processing_state && content.processing_state !== 'completed'),
    element: CCToolbarActionEdit,
  },
};

export default ContentCardToolbarActions;
