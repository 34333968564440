// CURRENT location does not feel like the right location for this? Maybe in the integrations folder?
import { useEffect, useReducer } from 'react';
import AssistantService from '../services/assistant';
import {
  PaginationType,
  SearchResponse,
  SearchResult,
} from '../models/assistant/interfaces/SearchResponse';

interface SearchOptions {
  integrationId: string;
  command: string;
  query: string;
}

interface PaginationParams {
  page?: number;
  per_page: number;
  next_page_token?: string;
}

interface SearchState {
  result: SearchResponse | null;
  items: SearchResult[];
  isFetching: boolean;
  isError: boolean;
  params: PaginationParams;
  canLoadMore: boolean;
}

const initialState: SearchState = {
  result: null,
  items: [],
  isFetching: true,
  isError: false,
  params: {
    page: 1,
    per_page: 15,
  },
  canLoadMore: false,
};

type Action =
  | { type: 'FETCHING' }
  | { type: 'FETCH_SUCCESS'; payload: SearchResponse }
  | { type: 'FETCH_FAILURE' }
  | { type: 'RESET' };

function reducer(state: SearchState, action: Action): SearchState {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case 'FETCH_SUCCESS': {
      const { payload } = action;
      const newState: SearchState = {
        ...state,
        isFetching: false,
        isError: false,
        result: action.payload,
        canLoadMore: false,
      };
      if (payload.type === 'result') {
        newState.items = [...state.items, ...payload.results];
        if (payload.pagination_type === PaginationType.PAGE_NUMBER) {
          newState.params = {
            page: payload.page,
            per_page: payload.per_page,
          };
          const totalPages = Math.ceil(payload.total || 0 / payload.per_page);
          newState.canLoadMore = totalPages > (payload.page || 1);
        } else {
          newState.params = {
            next_page_token: payload.next_page_token,
            per_page: payload.per_page,
          };
          newState.canLoadMore = !!payload.next_page_token;
        }
      }
      return newState;
    }

    case 'FETCH_FAILURE':
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}

interface SearchReturn extends SearchState {
  /**
   * Reloads the search results
   */
  onReload: () => void;
  /**
   * Load next page of results
   */
  onLoadMore: () => void;
}

const useEnterpriseSearch = ({
  integrationId,
  command,
  query,
}: SearchOptions): SearchReturn => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchItems = (params?: PaginationParams) => {
    //Starting new search
    if (!params) {
      dispatch({ type: 'RESET' });
      params = { per_page: 15 };
    } else {
      dispatch({ type: 'FETCHING' });
    }
    const assistantService = new AssistantService();
    assistantService
      .search({
        category: 'enterprise',
        integration_id: parseInt(integrationId),
        command,
        query,
        per_page: params.per_page,
        page: params.page,
        next_page_token: params.next_page_token,
      })
      .then((result) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: result as SearchResponse });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({ type: 'FETCH_FAILURE' });
        } else {
          throw err;
        }
      });
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, integrationId, command]);

  return {
    ...state,
    onReload: () => {
      if (!state.isFetching) {
        fetchItems();
      }
    },
    onLoadMore: () => {
      if (!state.isFetching && state.canLoadMore) {
        const params: PaginationParams = {
          per_page: state.params.per_page,
        };
        if (state.params.page) {
          params.page = state.params.page + 1;
        } else {
          params.next_page_token = state.params.next_page_token;
        }
        fetchItems(params);
      }
    },
  };
};

export default useEnterpriseSearch;
