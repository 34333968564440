import React from 'react';
import styled from 'styled-components';
import { timeAgoOrDate } from '../../../../../lib/date-formatter';

const StyledLayout = styled.div`
  color: #757575;
  font-weight: normal;
  font-size: 11px;
  .sourceName::after {
    content: ' • ';
  }
`;

interface SourceAttributionProps {
  published_at?: string;
  source_name?: string;
  showSource?: boolean;
  label?: string;
}

const SourceAttribution: React.FC<SourceAttributionProps> = ({
  published_at,
  source_name,
  showSource,
  label,
}) => (
  <StyledLayout className="attribution">
    <div>
      {showSource ? <span className="sourceName">{source_name}</span> : ''}
      {label ? <span>{label} </span> : null}
      <span className="sourceDate">{timeAgoOrDate(published_at)}</span>
    </div>
  </StyledLayout>
);

export default SourceAttribution;
