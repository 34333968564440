import { debounce } from 'lodash';
import { PluginFunctions } from '@draft-js-plugins/editor';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { KeyboardEvent, MutableRefObject } from 'react';
import {
  ContentSection,
  trackTagSearchClick,
} from '../../../../models/content-submission/analytics/analytics-typed';

export type TriggerRef = MutableRefObject<HTMLSpanElement | null>;

interface Trigger {
  // Unique id of the trigger, that never changes
  id: string;
  // Whether the trigger is disabled (user pressed Esc) and should bbe treated as plain text
  disabled: boolean;
  // Key of the block where the trigger is located
  blockKey: string;
  // Start index of the trigger in the block
  start: number;
  // End index of the trigger in the block
  end: number;
  // Ref of the trigger HTML element, used to calculate position of suggestion dropdown
  ref: TriggerRef;
}

interface StoreEvents {
  search: (searchString: string, ref?: TriggerRef) => void;
  keyPress: (event: KeyboardEvent<Element>) => void;
  editorFocus: (hasFocus: boolean) => void;
}

export interface TrackingContext {
  contentSection: ContentSection;
}

/**
 * This class is responsible for managing the state of the plugin.
 * It connects Draft.js editor state with the plugin and React components that render user tags, suggestions, etc.
 */
class TagPluginStore {
  private getEditorState?: PluginFunctions['getEditorState'];
  private setEditorState?: PluginFunctions['setEditorState'];

  private triggers: Record<string, Trigger> = {};
  private editorHasFocus?: boolean;
  private searchText = '';
  private currentTrigger: Trigger | null = null;
  private listeners: Record<
    keyof StoreEvents,
    Array<StoreEvents[keyof StoreEvents]>
  > = {
    search: [],
    keyPress: [],
    editorFocus: [],
  };

  trackingContext?: TrackingContext;

  constructor() {
    this.calculateSearchText = debounce(
      this.calculateSearchText.bind(this),
      100
    );
  }

  /**
   * Whether the plugin is active, means cursor is inside a trigger and user entered some text after @
   */
  get isActive(): boolean {
    return this.searchText.length > 0 && !!this.currentTrigger;
  }

  get isEditorFocused(): boolean {
    return !!this.editorHasFocus;
  }

  /**
   * Trigger event when editor get or loses focus. Event is debounced to avoid
   * multiple sequential events when user clicks on a suggestion
   */
  setEditorFocused(hasFocus: boolean) {
    if (this.editorHasFocus !== hasFocus) {
      this.editorHasFocus = hasFocus;
      this.emit('editorFocus', hasFocus);
    }
  }

  /**
   * Current selection state of the editor
   */
  get currentSelection(): SelectionState | undefined {
    return this.getEditorState?.().getSelection();
  }

  initialize({ setEditorState, getEditorState }: PluginFunctions) {
    this.getEditorState = getEditorState;
    this.setEditorState = setEditorState;
    const state = getEditorState();
    const selection = state.getSelection();
    this.setEditorFocused(selection.getHasFocus());
  }

  /**
   * Called when new tag trigger component is added in the editor (user types @ followed by a letter)
   */
  registerTrigger(
    triggerId: string,
    blockKey: string,
    start: number,
    end: number,
    ref: TriggerRef
  ) {
    this.triggers[triggerId] = {
      id: triggerId,
      disabled: false,
      blockKey,
      start,
      end,
      ref,
    };
    this.calculateSearchText();
  }

  /**
   * Called when tag trigger component is removed from the editor (replaced with tag or deleted by user)
   * @param triggerId
   */
  unregisterTrigger(triggerId: string) {
    delete this.triggers[triggerId];
    this.calculateSearchText();
  }

  /**
   * Called trigger start/end position changes (user types or deletes characters in the trigger)
   * @param triggerId
   * @param blockKey
   * @param start
   * @param end
   * @param ref
   */
  updateTrigger(
    triggerId: string,
    blockKey: string,
    start: number,
    end: number,
    ref: TriggerRef
  ) {
    if (!this.triggers[triggerId]) {
      return;
    }
    const trigger = this.triggers[triggerId];
    trigger.blockKey = blockKey;
    trigger.start = start;
    trigger.end = end;
    trigger.ref = ref;
    this.calculateSearchText();
  }

  on<E extends keyof StoreEvents>(event: E, callback: StoreEvents[E]) {
    this.listeners[event].push(callback);
    return () => {
      this.off(event, callback);
    };
  }

  off<E extends keyof StoreEvents>(event: E, callback: StoreEvents[E]) {
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback
    );
  }

  emit<E extends keyof StoreEvents>(
    event: E,
    ...args: Parameters<StoreEvents[E]>
  ) {
    this.listeners[event].forEach((cb) => cb(...(args as [never])));
  }

  /**
   * Called on editor state change. If cursor is inside a trigger, it will calculate search text and trigger search.
   * Search text is text from trigger start to cursor position.
   */
  calculateSearchText(): void {
    const selection = this.currentSelection;
    if (!selection) {
      return this.triggerSearch('');
    }
    // Ignore selection if it's not collapsed
    if (
      selection.getAnchorKey() !== selection.getFocusKey() ||
      selection.getAnchorOffset() !== selection.getFocusOffset()
    ) {
      return this.triggerSearch('');
    }
    const blockKey = selection.getAnchorKey();
    const block = this.getEditorState?.()
      .getCurrentContent()
      .getBlockForKey(blockKey);
    const offset = selection.getAnchorOffset();
    const blockTriggers = Object.values(this.triggers).filter(
      (t) => t.blockKey === blockKey
    );
    // If there are no triggers in this block, ignore selection
    if (!blockTriggers.length) {
      return this.triggerSearch('');
    }
    const trigger = blockTriggers.find(
      ({ start, end }) => start <= offset && offset <= end
    );
    // If cursor is not in a trigger, ignore selection
    if (!trigger || trigger.disabled) {
      return this.triggerSearch('');
    }
    // Get text from trigger start to cursor, skip 1 char as it would be @
    const text = block
      ?.getText()
      .slice(trigger.start + 1, offset)
      .trim();
    this.triggerSearch(text || '', trigger);
  }

  /**
   * Will trigger search event if search text or trigger changed
   */
  triggerSearch(text: string, trigger: Trigger | null = null) {
    if (text === this.searchText && trigger === this.currentTrigger) {
      return;
    }
    this.searchText = text;
    this.currentTrigger = trigger;
    this.emit('search', text, trigger?.ref);
  }

  /**
   * Exit tag creation process, disable current trigger, so it would be treated as regular text
   */
  exitTagging() {
    if (!this.currentTrigger) {
      return;
    }
    this.currentTrigger.disabled = true;
    this.triggerSearch('');
  }

  /**
   * Replace current trigger with a tag
   * @param userId
   * @param name
   */
  createTag(userId: string, name: string) {
    if (!this.currentTrigger) {
      return;
    }
    const editorState = this.getEditorState?.();
    if (!editorState) {
      return;
    }
    const currentSelection = editorState.getSelection();
    let contentState = editorState.getCurrentContent();
    contentState = contentState.createEntity('REFERENCE', 'IMMUTABLE', {
      refType: 'user',
      id: userId,
      name,
    });
    const entityKey = contentState.getLastCreatedEntityKey();
    const selection = SelectionState.createEmpty(
      this.currentTrigger.blockKey
    ).merge({
      anchorOffset: this.currentTrigger.start,
      focusOffset: currentSelection.getEndOffset() || this.currentTrigger.end,
    });
    contentState = Modifier.replaceText(
      contentState,
      selection,
      '@' + name,
      undefined,
      entityKey
    );
    const updatedSelection = selection.merge({
      anchorOffset: selection.getAnchorOffset() + name.length + 1,
      focusOffset: selection.getAnchorOffset() + name.length + 1,
    });
    const updatedEditorState = EditorState.push(
      editorState,
      contentState,
      'apply-entity'
    );
    this.setEditorState?.(
      EditorState.forceSelection(updatedEditorState, updatedSelection)
    );
    if (this.trackingContext) {
      trackTagSearchClick({
        content_section: this.trackingContext.contentSection,
        selected: userId,
      });
    }
  }
}

export default TagPluginStore;
