import { ComponentProps, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../../ui';

type CommentAssistantDropdownProps = ComponentProps<'button'> & {
  disabled: boolean;
  onClick?: () => void;
};

const CommentAssistantButton = forwardRef<
  HTMLButtonElement,
  CommentAssistantDropdownProps
>((props, forwardedRef) => {
  const { t } = useTranslation();

  return (
    <Button
      ref={forwardedRef}
      className="comment-assistant-button"
      type="button"
      title={t('comments.actions.comment_assistant')}
      aria-label={t('comments.actions.comment_assistant')}
      {...(props as ComponentProps<typeof Button>)}
    >
      <Icon type={'auto_awesome'}>auto_awesome</Icon>
    </Button>
  );
});
CommentAssistantButton.displayName = 'CommentAssistantButton';

export default CommentAssistantButton;
