/* eslint-disable max-len */

// FEED
export const FEED_LOAD = 'w:Feed:load';
export const FEED_FETCH_ERROR = 'w:Feed:Fetch:error';
export const FEED_SEE_MORE_REQUEST = 'w:Feed:SeeMore:request';
export const FEED_SEE_MORE_LOAD = 'w:Feed:SeeMore:load';

// Home Page Feeds
export const CHANNEL_VIEW_BUTTON_CLICK = 'w:ViewChannel:click';
export const FEED_VIEW_BUTTON_CLICK = 'w:ViewAllFeed:click';
export const CONTENT_CARD_TOPIC_PILL_CLICK = 'w:ContentCard:TopicPill:click';
export const CONTENT_CARD_MORE_TOPICS_CLICK = 'w:ContentCard:MoreTopics:click';

// CONTENT CARD MENU
export const CONTENT_CARD_MENU_EDIT_CLICK = 'w:ContentCard:Menu:Edit:click';
export const CONTENT_CARD_MENU_COPY = 'w:ContentCard:Menu:copylink';
export const CONTENT_CARD_LIKES_SEEALL = 'w:ContentCard:Likes:seeall';
export const CONTENT_CARD_LIKES_PROFILE_CLICK =
  'w:ContentCard:Likes:Profile:click';

// PROFILES
export const PROFILE_VIEW = 'w:Profile:view';
export const PROFILE_CLICK = 'w:Profile:click';

// ASSISTANT
export const SEARCH_LOAD = 'w:SearchTab:load';
export const SEARCH_CLICK = 'w:Search:Search:click';
export const SEARCH_SUCCESS = 'w:Search:Search:success';
export const SEARCH_ERROR = 'w:Search:Search:error';
export const SEARCH_CHANNEL_CARD_VIEW = 'w:ChannelCard:view';
export const SEARCH_CHANNEL_CARD_CLICK = 'w:ChannelCard:click';
export const SEARCH_CONTENT_CARD_VIEW = 'w:ContentCard:view';
export const SEARCH_CONTENT_CARD_CLICK = 'w:ContentCard:click';
export const SEARCH_MESSAGE_VIEW = 'w:Message:view';
export const SEARCH_CATEGORY_PEOPLE_VIEW = 'w:Category:People:view';
export const SEARCH_CATEGORY_PEOPLE_CLICK = 'w:Category:People:click';
export const SEARCH_CATEGORY_CHANNEL_VIEW = 'w:Category:Channel:view';
export const SEARCH_CATEGORY_CHANNEL_CLICK = 'w:Category:Channel:click';
export const RECENT_SEARCH_CLICK = 'w:RecentSearch:click';
export const SEARCH_CATEGORY_POSTS_CLICK = 'w:Category:Posts:click';
export const SEARCH_PREVIOUS_SEARCH_CLICK = 'w:Search:PreviousSearch:click';
export const SEARCH_TRENDING_SEARCH_CLICK = 'w:Search:TrendingSearch:click';
export const SEARCH_SORT_CLICK = 'w:Search:Sort:click';
export const SEARCH_FILTER_CLICK = 'w:Search:Filter:click';
export const SEARCH_CLEAR_FILTER_CLICK = 'w:Search:ClearFilter:click';
export const SEARCH_CATEGORY_CLICK = 'w:Search:Category:click';
export const SEARCH_PREVIEW_RESULT_CLICK = 'w:Search:PreviewResult:click';
export const SEARCH_PREVIEW_VIEW_ALL_CLICK = 'w:Search:PreviewViewAll:click';

// MISC
export const QUICK_LINKS_CLICK = 'w:QuickLinks:click';
export const INVITE_USER_CTA_CLICK = 'w:Invite:click';
export const AUTH_LOGIN_REDIR = 'w:Auth:redirect';
export const AUTH_SUCCESS = 'w:Auth:success';
export const HOME_LOAD = 'w:Home:load';
export const HOME_CLICK = 'w:Home:click';
export const EMAIL_LINK_CLICK = 'w:EmailLink:click';
export const SHORTCUT_CLICK = 'w:Shortcut:click';
export const SHORTCUT_VIEW = 'w:Shortcut:view';
export const DOCUMENT_CLICK = 'w:Document:click';
export const DOCUMENT_VIEW = 'w:Document:view';
