import React from 'react';

import { Label } from '../../components/ui/text-input';

import { trackProfileView } from '../../models/profile/analytics';
import NotFound from '../not-found';
import ProfileSectionHeader from './profile-section-header';
import { connect } from 'react-redux';
import { programSelectors } from '../../models/program';
import EmptyFeed from '../../components/feed/empty-feed';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const ProfileFields = ({ fields }) =>
  fields.map((fieldProps) => {
    const { name, label, displayValue, value, type } = fieldProps;

    const valueToShow = displayValue || value;
    const href =
      type === 'tel'
        ? `tel:${valueToShow}`
        : type === 'email'
        ? `mailto:${valueToShow}`
        : undefined;

    return (
      <div key={name}>
        <Label htmlFor={name}>{label}</Label>
        {href ? (
          <a href={href} className={`field-value`} id={name}>
            {valueToShow}
          </a>
        ) : (
          <div className={`field-value`} id={name}>
            {valueToShow}
          </div>
        )}
      </div>
    );
  });

const ProfileView = ({
  profileId,
  profileGroups,

  advancedProfileEnabled,
}) => {
  if (!advancedProfileEnabled) return <NotFound />;

  trackProfileView({
    advocate_id: profileId,
  });

  return (
    <div className="user-profile-view">
      <div className="user-profile-view__body" id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {profileGroups.length > 0 ? (
          profileGroups.map(({ label, fields, iconUrl, name }, idx) =>
            !!fields.length ? (
              <div className="fields-group" key={name}>
                <ProfileSectionHeader title={label} iconUrl={iconUrl} />
                <ProfileFields fields={fields} />
              </div>
            ) : null
          )
        ) : (
          <EmptyFeed />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    advancedProfileEnabled: programSelectors.getAdvancedProfileEnabled(state),
  };
};

export default connect(mapStateToProps)(ProfileView);
