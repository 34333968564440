const RESET = 'pulp/contentSubmit/RESET';
const RESET_WITH_CHANNELS = 'pulp/contentSubmit/RESET_WITH_CHANNELS';
const SET_ID = 'pulp/contentSubmit/SET_ID';
const SET_AUTHOR_ID = 'pulp/contentSubmit/SET_AUTHOR_ID';
const SET_CONTENT_TYPE = 'pulp/contentSubmit/SET_CONTENT_TYPE';
const SET_CHANNEL_IDS = 'pulp/contentSubmit/SET_CHANNEL_IDS';
const SET_URL = 'pulp/contentSubmit/SET_URL';
const SET_TITLE = 'pulp/contentSubmit/SET_TITLE';
const SET_DESCRIPTION = 'pulp/contentSubmit/SET_DESCRIPTION';
const SET_MEDIA = 'pulp/contentSubmit/SET_MEDIA';
const SET_MEDIA_URL = 'pulp/contentSubmit/SET_MEDIA_URL';
const SET_NOTE_COLOR = 'pulp/contentSubmit/SET_NOTE_COLOR';
const SET_ARTICLE = 'pulp/contentSubmit/SET_ARTICLE';
const SET_IMAGE_URL = 'pulp/contentSubmit/SET_IMAGE_URL';
const SET_SUBMIT_PROGRESS = 'pulp/contentSubmit/SET_SUBMIT_PROGRESS';
const SET_PUBLICATION_STATE = 'pulp/contentSubmit/SET_PUBLICATION_STATE';
const FETCH_LINK_PREVIEW_BEGIN = 'pulp/contentSubmit/FETCH_LINK_PREVIEW_BEGIN';
const FETCH_LINK_PREVIEW_SUCCESS =
  'pulp/contentSubmit/FETCH_LINK_PREVIEW_SUCCESS';
const FETCH_LINK_PREVIEW_FAILURE =
  'pulp/contentSubmit/FETCH_LINK_PREVIEW_FAILURE';
const UPLOAD_VIDEO_BEGIN = 'pulp/contentSubmit/UPLOAD_VIDEO_BEGIN';
const UPLOAD_VIDEO_SUCCESS = 'pulp/contentSubmit/UPLOAD_VIDEO_SUCCESS';
const UPLOAD_VIDEO_FAILURE = 'pulp/contentSubmit/UPLOAD_VIDEO_FAILURE';
const FETCH_CONTENT_SUBMISSION_BEGIN =
  'pulp/contentSubmit/FETCH_CONTENT_SUBMISSION_BEGIN';
const FETCH_CONTENT_SUBMISSION_SUCCESS =
  'pulp/contentSubmit/FETCH_CONTENT_SUBMISSION_SUCCESS';
const FETCH_CONTENT_SUBMISSION_FAILURE =
  'pulp/contentSubmit/FETCH_CONTENT_SUBMISSION_FAILURE';
const SUBMIT_CONTENT_BEGIN = 'pulp/contentSubmit/SUBMIT_CONTENT_BEGIN';
const SUBMIT_CONTENT_SUCCESS = 'pulp/contentSubmit/SUBMIT_CONTENT_SUCCESS';
const SUBMIT_CONTENT_FAILURE = 'pulp/contentSubmit/SUBMIT_CONTENT_FAILURE';

export default {
  RESET,
  RESET_WITH_CHANNELS,
  SET_ID,
  SET_AUTHOR_ID,
  SET_CONTENT_TYPE,
  SET_CHANNEL_IDS,
  SET_URL,
  SET_TITLE,
  SET_DESCRIPTION,
  SET_MEDIA,
  SET_MEDIA_URL,
  SET_NOTE_COLOR,
  SET_ARTICLE,
  SET_IMAGE_URL,
  SET_SUBMIT_PROGRESS,
  SET_PUBLICATION_STATE,
  FETCH_LINK_PREVIEW_BEGIN,
  FETCH_LINK_PREVIEW_SUCCESS,
  FETCH_LINK_PREVIEW_FAILURE,
  UPLOAD_VIDEO_BEGIN,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  FETCH_CONTENT_SUBMISSION_BEGIN,
  FETCH_CONTENT_SUBMISSION_SUCCESS,
  FETCH_CONTENT_SUBMISSION_FAILURE,
  SUBMIT_CONTENT_BEGIN,
  SUBMIT_CONTENT_SUCCESS,
  SUBMIT_CONTENT_FAILURE,
};
