import types from './types';

const initialState = {
  channelIds: [],
  isFetching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET:
      return initialState;

    case types.RESET_WITH_CHANNELS:
      return {
        ...initialState,
        contentType: state.contentType,
        channelIds: state.channelIds,
      };

    case types.SET_ID:
      return {
        ...state,
        id: action.id,
      };

    case types.SET_AUTHOR_ID:
      return {
        ...state,
        authorId: action.authorId,
      };

    case types.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType,
      };

    case types.SET_CHANNEL_IDS:
      return {
        ...state,
        isDraft: true,
        channelIds: action.channelIds,
      };

    case types.SET_URL:
      return {
        ...state,
        isDraft: true,
        url: action.url,
      };

    case types.SET_TITLE:
      return {
        ...state,
        isDraft: true,
        title: action.title,
      };

    case types.SET_DESCRIPTION:
      return {
        ...state,
        isDraft: true,
        description: action.description,
      };

    case types.SET_MEDIA:
      return {
        ...state,
        isDraft: true,
        media: action.media,
      };

    case types.SET_MEDIA_URL:
      return {
        ...state,
        mediaUrl: action.url,
      };

    case types.SET_NOTE_COLOR:
      return {
        ...state,
        noteColor: action.noteColor,
      };

    case types.SET_ARTICLE:
      return {
        ...state,
        isDraft: true,
        article: action.article,
      };

    case types.SET_IMAGE_URL:
      return {
        ...state,
        imageUrl: action.imageUrl,
      };

    case types.SET_SUBMIT_PROGRESS:
      return {
        ...state,
        submitProgress: action.progress,
      };

    case types.SET_PUBLICATION_STATE:
      return {
        ...state,
        publicationState: action.publicationState,
      };

    case types.SUBMIT_CONTENT_BEGIN:
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };

    case types.SUBMIT_CONTENT_SUCCESS:
      return {
        ...state,
        isDraft: false,
        isSubmitting: false,
      };

    case types.SUBMIT_CONTENT_FAILURE:
      return {
        ...state,
        error: true,
        isSubmitting: false,
      };

    case types.FETCH_LINK_PREVIEW_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case types.FETCH_LINK_PREVIEW_SUCCESS:
      return {
        ...state,
        url: action.url,
        title: action.title,
        description: action.description,
        imageUrl: action.imageUrl,
        isFetching: false,
      };

    case types.FETCH_LINK_PREVIEW_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    case types.FETCH_CONTENT_SUBMISSION_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case types.FETCH_CONTENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case types.FETCH_CONTENT_SUBMISSION_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
