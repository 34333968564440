import { EXTERNAL_LINK_IGNORE_WARNING_KEY } from '../components/external-link-modal/external-link-modal';
export function _isInMSTeams() {
  const isTeamsDesktop =
    !!window.navigator.userAgent.match(/(Teams).+(Electron)/i);
  const isTeamsMobile =
    !!window.navigator.userAgent.match(/(TeamsMobile)/i) || false;

  if (isTeamsDesktop || isTeamsMobile) {
    // If we match on user agent, we always want to assume we're in a teams tab
    return true;
  } else if (window.location.ancestorOrigins) {
    // If the ancestorOrigins API is available, use it to determine if our top-level ancestor is teams.microsoft.com or teams.cloud.microsoft
    if (window.location.ancestorOrigins[0] === 'https://teams.microsoft.com') {
      return true; // ancestor origin belongs teams.microsoft.com
    } else {
      // ancestor origin belongs teams.cloud.microsoft
      return (
        window.location.ancestorOrigins[0] === 'https://teams.cloud.microsoft'
      );
    }
  } else if (
    // If none of the above are sufficient, check against window name and/or window.nativeInterface
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame' ||
    (window.parent === window.self && window.nativeInterface)
  ) {
    return true;
  }

  // We have not detected that we are in a teams tab
  return false;
}

export function _isInMSTeamsMobile() {
  const isTeamsMobile =
    !!window.navigator.userAgent.match(/(TeamsMobile)/i) || false;
  return isTeamsMobile;
}

export function shouldShowExternalLinkWarning() {
  const userIgnoredWarning =
    localStorage.getItem(EXTERNAL_LINK_IGNORE_WARNING_KEY) === 'true';
  return isInMSTeams && !userIgnoredWarning;
}

export const isInMSTeams = _isInMSTeams();
export const isInMSTeamsMobile = _isInMSTeamsMobile();
