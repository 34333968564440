import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  contentOperations,
  contentSelectors,
} from '../../../models/content/index.js';

import { GalleryThumbnails } from '../../../components/ui';
import { RootPatronState } from '../../../common/use-patron-selector';
import { ImageCarousel } from '../../../components/ui/ImageCarousel';
import { trackContentCardMultiImageClick } from '../../../models/content/analytics';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';

type OwnProps = {
  contentId?: number;
  onResize?: () => void;
};

type ContentTypeImageProps = ReturnType<typeof mapStateToProps> & OwnProps;

interface State {
  index: number;
  viewed: Set<number>;
}

const ContentTypeImage = ({
  contentId,
  images,
  isMultiImage,
  defaultImageUrl,
  onResize,
}: ContentTypeImageProps) => {
  const newGallery = useFeatureFlag(Feature.TESCO_Q3);
  const dispatch = useDispatch();

  const [state, setState] = useState<State>({
    index: 0,
    viewed: new Set([0]),
  });

  useEffect(() => {
    onResize?.();
  }, [onResize]);

  const selectImage = useCallback(
    (index: number) => {
      let viewed = state.viewed;
      if (!state.viewed.has(index)) {
        viewed = new Set(state.viewed);
        viewed.add(index);
        if (viewed.size === images.length && contentId) {
          dispatch(contentOperations.completeContent(contentId));
        }
      }
      if (contentId) {
        trackContentCardMultiImageClick(contentId, { position: index + 1 }); //1-indexed position
      }
      setState({ index, viewed });
    },
    [contentId, state]
  );

  useEffect(() => {
    const tempImages = [];

    if (!isMultiImage) return;

    const preloadImages = () => {
      images.forEach((img) => {
        const tempImage = new Image();
        tempImage.src = img.url;
        tempImages.push(tempImage);
      });
    };

    const gcImages = () => {
      for (let i = 0; i < tempImages.length; i++) {
        tempImages[i] = null;
      }
    };

    preloadImages();
    selectImage(state.index);

    return gcImages;
  }, [contentId]);

  if (newGallery && isMultiImage) {
    return <ImageCarousel images={images} onSelect={selectImage} />;
  }

  const imageUrl =
    (images && images[state.index] && images[state.index].url) ||
    defaultImageUrl;

  return (
    <>
      <div className="content-frame">
        <img className="content-detail__image" src={imageUrl} alt="" />
      </div>

      {isMultiImage ? (
        <GalleryThumbnails
          className="content-detail__thumbs"
          images={images}
          onSelect={selectImage}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  images: contentSelectors.getContentImages(state, ownProps),
  isMultiImage: contentSelectors.getContentIsMultiImage(state, ownProps),
  title: contentSelectors.getContentTitleOrSummary(state, ownProps),
  defaultImageUrl: contentSelectors.getContentDefaultImage(state, ownProps),
});

export default connect(mapStateToProps)(ContentTypeImage);
