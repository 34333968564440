import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { ChannelAboutPageResponse } from '../models/channels/types';
import ChannelService from '../services/channel';
import { useRecoilState } from 'recoil';
import { aboutPageByChannelId } from '../models/about-page/recoil-state';

type UseCommentFetcherProps = {
  topicId: string;
};

const useTopicAboutPageFetcher = ({ topicId }: UseCommentFetcherProps) => {
  const [aboutPage, setAboutPage] = useRecoilState(
    aboutPageByChannelId(topicId)
  );
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);

  const handleFetchSuccess = useCallback(
    (res: AxiosResponse<ChannelAboutPageResponse>) => {
      const { page_content: pageContent, preview_content: previewContent } =
        res.data;

      setAboutPage({ pageContent, previewContent });
      setIsError(false);
      setIsFetching(false);
    },
    []
  );

  const handleFetchFailure = useCallback(() => {
    setIsError(true);
    setIsFetching(false);
  }, []);

  const fetchPage = useCallback(async () => {
    setIsFetching(true);

    try {
      const service = new ChannelService(topicId);
      const res = await service.fetchAboutPage();

      handleFetchSuccess(res);
    } catch (err) {
      if (_.get(err, 'response')) {
        handleFetchFailure();
      } else {
        throw err;
      }
    }
  }, [topicId, handleFetchFailure, handleFetchSuccess]);

  useEffect(() => {
    void fetchPage();
  }, [fetchPage]);

  return {
    aboutPage: aboutPage?.pageContent,
    aboutPreview: aboutPage?.previewContent,
    isError,
    isFetching,
  };
};

export default useTopicAboutPageFetcher;
