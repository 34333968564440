import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import { Spinner } from '../../components/ui';
import {
  AssistantTodoItem,
  AssistantBackBar,
} from '../../components/assistant';
import EmptyFeed from '../../components/feed/empty-feed';
import ViewTrigger from '../../components/view-trigger';
import AssistantError from './assistant-error';
import AssistantContainer from './assistant-container';
import AssistantContent from './assistant-content';
import AssistantTitle from './assistant-title';

import useTodosFetcher from '../../common/use-todos-fetcher';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';

function AssistantTodos({ match }: RouteComponentProps) {
  const { t } = useTranslation();

  const { todos, fetchTodos, isFetching, canLoadMore, isError } =
    useTodosFetcher(get(match.params, 'todoId'));

  const { fetchAssistantData } = useAssistantFetcher({
    itemTypes: [AssistantDataType.INBOX_COUNT],
  });

  const refetchData = () => {
    fetchAssistantData(); // Update count
  };

  return (
    <AssistantContainer flex className="assistant__todos">
      <AssistantBackBar />
      <AssistantContent>
        <AssistantTitle>
          {t('screens.subscriptions.needs_attention')}
        </AssistantTitle>
        <div className="assistant__todo-list">
          {todos && todos.length ? (
            todos.map((todo, position) => (
              <AssistantTodoItem
                analyticsData={{ position }}
                onAcknowledge={() => {
                  refetchData();
                }}
                item={todo}
                key={todo.id}
              />
            ))
          ) : isError ? (
            <AssistantError
              inline
              error={t('screens.subscriptions.not_found')}
            />
          ) : null}

          {isFetching ? (
            <Spinner center={!todos.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={() => fetchTodos()} />
          ) : todos && todos.length ? null : !isError ? (
            <EmptyFeed />
          ) : null}
        </div>
      </AssistantContent>
    </AssistantContainer>
  );
}

export default AssistantTodos;
