import { ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { useEventListener } from 'usehooks-ts';

import { RootPatronState } from '../../../common/use-patron-selector';
import { Button, Icon } from '../../../components/ui';
import { contentSelectors } from '../../../models/content/index.js';

import ContentInfoTitle from './content-info-title';
import ContentInfoSummary from './content-info-summary';
import ContentInfoSource from './content-info-source';
import ContentInfoActions from './content-info-actions';
import ContentInfoLikes from './content-info-likes';
import ContentInfoComments from './content-info-comments';

import './content-info.scss';

const ContentInfoHeader = ({ children }: { children: ReactNode }) => (
  <header className="content-info__header">{children}</header>
);

type OwnProps = {
  contentId: number;
  emailContent?: boolean;
};

type ContentDetailInfoProps = OwnProps & ReturnType<typeof mapStateToProps>;

const ContentDetailInfo = ({
  contentId,
  isPending,
  emailContent,
}: ContentDetailInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const className = cx(
    'content-info',
    {
      'content-info--open': isOpen,
      'content-info--email': emailContent,
    },
    'heartsplosion-container'
  );

  const analyticsData = {
    location: 'content_info',
  };

  const { sidebarEnabled } = useSidebarEnabled();

  return (
    <aside className={className}>
      <FocusLock disabled={!sidebarEnabled || !isOpen}>
        <Button
          iconComponent={
            isOpen ? (
              <Icon type="chevron_right" />
            ) : (
              <Icon type="chevron_left" />
            )
          }
          className="content-info__toggle"
          onClick={handleToggle}
          aria-label={isOpen ? t('content.hide_info') : t('content.show_info')}
        />

        <div className="content-info__container">
          <ContentInfoHeader>
            <div className="content-info__text">
              <ContentInfoTitle contentId={contentId} />
              <ContentInfoSummary contentId={contentId} />
            </div>

            <ContentInfoSource
              contentId={contentId}
              disableLinks={emailContent}
            />
            <ContentInfoActions
              contentId={contentId}
              analyticsData={analyticsData}
              emailContent={emailContent}
            />
          </ContentInfoHeader>

          {!isPending && !emailContent ? (
            <>
              <ContentInfoLikes contentId={contentId} />
              <ContentInfoComments contentId={contentId} />
            </>
          ) : null}
        </div>
      </FocusLock>
    </aside>
  );
};

const useSidebarEnabled = () => {
  const testSidebarEnabled = () =>
    window.matchMedia('only screen and (max-width: 799px)').matches;

  const [sidebarEnabled, setSidebarEnabled] = useState(testSidebarEnabled());
  const debouncedSetSidebarEnabled = debounce(
    () => setSidebarEnabled(testSidebarEnabled()),
    250
  );

  useEventListener('resize', debouncedSetSidebarEnabled);

  return {
    sidebarEnabled,
  };
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  isPending: contentSelectors.getContentIsPending(state, ownProps),
});

export default connect(mapStateToProps)(ContentDetailInfo);
