import React from 'react';
import styles from './loading-skeleton.module.css';

type SkeletonProps = Pick<
  React.CSSProperties,
  'width' | 'height' | 'borderRadius' | 'padding' | 'marginTop'
>;

export const LoadingSkeleton = ({
  width = '100%',
  height = 16,
  marginTop = 10,
  borderRadius = 24,
  padding = 0,
}: SkeletonProps) => (
  <div
    style={{ width, height, marginTop, padding }}
    aria-hidden
    className={styles.Skeleton}
  >
    <div style={{ borderRadius }} className={styles.SkeletonItem} />
  </div>
);
