import { useCallback, useEffect, useState } from 'react';

interface State<T> {
  data: Array<T>;
  total: number;
  totalPages: number;
  page: number;
  pageSize: number;
  hasMore: boolean;
  isLoading: boolean;
  error: string | null;
}

const defaultState: State<never> = {
  data: [],
  total: 0,
  totalPages: 0,
  page: 1,
  pageSize: 40,
  hasMore: false,
  isLoading: false,
  error: null,
};

interface FetchResponse<T> {
  data: Array<T>;
  meta: {
    total: number;
    pageSize: number;
    currentPage: number;
  };
}

export type FetchFn<T> = (
  page: number,
  pageSize: number
) => Promise<FetchResponse<T>>;

function mapSuccess<T>(
  currentState: State<T>,
  { data, meta }: FetchResponse<T>
): State<T> {
  const total = Number(meta.total);
  const pageSize = Number(meta.pageSize);
  const totalPages = Math.ceil(total / pageSize);
  return {
    data: [...currentState.data, ...data],
    total,
    totalPages,
    page: Number(meta.currentPage),
    pageSize,
    hasMore: totalPages > meta.currentPage,
    isLoading: false,
    error: null,
  };
}

function mapError<T>(currentState: State<T>, error: Error): State<T> {
  return {
    ...currentState,
    isLoading: false,
    error: error.message,
  };
}

/**
 * Hook to fetch data in an infinite scroll fashion. Note that changing fetchFn or pageSize at runtime will reset hook state.
 * To avoid this, use useCallback to memoize fetchFn.
 * @param fetchFn - function to fetch data, receives page and pageSize as params, returns a promise with data and meta
 * @param pageSize - page size, defaults to 40, changing this at runtime will reset hook state
 */
export function useInfiniteFetch<T>(fetchFn: FetchFn<T>, pageSize = 40) {
  const [state, setState] = useState<State<T>>({
    ...defaultState,
    pageSize,
  });

  useEffect(() => {
    setState({ ...defaultState, isLoading: true });
    fetchFn(1, pageSize)
      .then((res) => {
        setState(mapSuccess(defaultState, res));
      })
      .catch((err) => {
        setState(mapError(defaultState, err));
      });
  }, [pageSize, fetchFn]);

  const loadMore = useCallback(() => {
    if (state.totalPages <= state.page) {
      return;
    }
    if (state.isLoading) {
      return;
    }
    setState({
      ...state,
      isLoading: true,
    });
    fetchFn(state.page + 1, state.pageSize)
      .then((res) => {
        setState(mapSuccess(state, res));
      })
      .catch((err) => {
        setState(mapError(state, err));
      });
  }, [fetchFn, state]);

  return {
    ...state,
    loadMore,
  };
}

export default useInfiniteFetch;
