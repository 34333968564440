import { ResourceObject } from '../../lib/types/json-api';

export interface Channel {
  name: string;
  description: string;
  background_image_url: string | null;
  cropped_background_image: string | null;
  uploaded_image: string | null;
  cropped_uploaded_image: string | null;
  last_published_at: string | null;
  cover_image_url: string | null;
  use_cover_image: boolean;
  default: boolean;
  feed_filters: unknown[];
  can_publish_as_owner: boolean;
  can_submit: boolean;
  background_color: string;
  auto_publish: boolean;
  originalIndex: number;
  id: string;
  following: boolean;
  follower_count: number;
  created_at: string;
  last_content_published_at?: string | null;
  is_recommended?: boolean;
  landing_page_tabs: ChannelTab[];
  permalink_url: string | null;
}

export type NormalizedChannel = Omit<Channel, 'id'> & {
  id: number;
};

export enum ChannelTabType {
  About = 'about',
  Posts = 'posts',
  Shortcuts = 'shortcuts',
  Members = 'members',
}

export type ChannelTab = {
  title: string;
  position: number;
  tab_type: ChannelTabType;
};

export type ChannelFetchAllResponse = { content_channels: Array<Channel> };

export type ChannelSearchResponse = {
  content_channels: Array<Channel>;
  meta: {
    page: {
      size: number;
      number: number;
      total: number;
    };
    total: number;
  };
};

export type ChannelStatsResponse = {
  stats: {
    posts_count: number;
    last_updated: string;
    posts_per_week: number;
    follower_count: number;
    contributor_count: number;
  };
};

export type ChannelAboutPageResponse = {
  content_channel_id: number;
  page_content: string;
  preview_content: string;
};

type ChannelContributor = {
  id: number;
  display_name: string;
  avatar_url: string | null;
  avatar_color: string;
  post_count: number;
  full_name: string;
};

export type ChannelTopContributorsResponse = {
  top_contributors: Array<
    Pick<
      ChannelContributor,
      'avatar_color' | 'avatar_url' | 'display_name' | 'id' | 'post_count'
    >
  >;
};

export interface ChannelFollower {
  id: string;
  type: string;
  attributes: Pick<
    ChannelContributor,
    'avatar_color' | 'avatar_url' | 'display_name' | 'full_name'
  >;
}

export interface ChannelMember {
  id: string;
  type: string;
  attributes: ChannelContributor & {
    name: string;
    department: string;
    avatar: {
      color: string;
      url: string;
      initials: string;
    };
    action: {
      type: string;
      navigation: {
        url: string;
        type: string;
      };
    };
  };
}

export type ChannelFollowersResponse = {
  followers: {
    data: Array<ChannelFollower>;
  };
  meta: {
    current_page: number;
    page_size: number;
    total_records: number;
  };
};

export type ChannelMembersResponse = {
  data: ChannelMember[];
  meta: {
    current_page: number;
    page_size: number;
    total_records: number;
    total_pages: number;
  };
  links: {
    first: string;
    last: string;
    next: string;
    prev: string;
    self: string;
  };
};

export type ChannelFollowResponse = {
  content_channel: Channel;
};

export type ChannelStat = {
  postsCount: number;
  postsPerWeek: number;
  lastUpdated: string | null;
  followerCount: number;
  contributorCount: number;
};

export type ChannelContributors =
  ChannelTopContributorsResponse['top_contributors'];
export type ChannelFollowers = ChannelFollowersResponse['followers']['data'];

export type TopicShortcut = {
  id: string;
  name: string;
  description?: string;
  icon_url?: string;
  position: number;
  links: {
    name: string;
    url: string;
  }[];
};

export type TopicShortcutsResponse = {
  data: ResourceObject<'content_channel_shortcut', TopicShortcut>[];
};

enum ActionTypes {
  SET = 'patron/channels/SET',
  FOLLOW = 'patron/channels/FOLLOW',
  UNFOLLOW = 'patron/channels/UNFOLLOW',
}

export default ActionTypes;
