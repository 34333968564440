import { Container } from '@socialchorus/shared-ui-components';
import styles from './search-topics-v2.module.scss';
import FollowButton from '../../topic-page-v2/common/FollowButton';
import useProgramTheme from '../../../../common/use-program-theme';
import { Channel } from '../../../../models/channels/types';
import { useMemberCount } from './topics-search';
import { useTranslation } from 'react-i18next';

export function TopicCardV2({
  topic,
  analyticsData,
}: {
  topic: Channel;
  analyticsData: IAnalyticsData;
}) {
  const { t } = useTranslation();
  const { themeColor } = useProgramTheme();
  const topicNameElementId = `topic-name-${topic.id}`;
  const topicDescriptionElementId = `topic-description-${topic.id}`;
  const { memberCount, handleFollowUnfollow } = useMemberCount(
    topic.follower_count
  );

  return (
    <Container
      shape="large"
      shadow="light"
      className={styles.TopicCard}
      aria-labelledby={topicNameElementId}
      aira-describedby={topicDescriptionElementId}
    >
      {topic.use_cover_image && topic.cover_image_url ? (
        <img
          className={styles.TopicCover}
          src={topic.cover_image_url}
          alt="cover"
        />
      ) : (
        <div className={styles.TopicCover} style={{ background: themeColor }} />
      )}
      {topic.background_image_url ? (
        <Container
          tag="img"
          src={topic.background_image_url}
          alt="avatar"
          className={styles.Avatar}
        />
      ) : (
        <Container
          tag="div"
          className={styles.Avatar}
          style={{ background: topic.background_color }}
        />
      )}
      <div className={styles.CardContainer}>
        <div className={styles.TopicCardHeader}>
          <h2 id={topicNameElementId}>
            <a href={topic.permalink_url ?? '#'} className={styles.TopicName}>
              {topic.name}
            </a>
          </h2>
          <div className={styles.TopicDetails}>
            <span className={styles.MemberCount}>
              {t('channel_details.number_joined', { count: memberCount })}
            </span>
            <FollowButton
              topicId={topic.id}
              analyticsData={analyticsData}
              onFollowUnfollow={handleFollowUnfollow}
            />
          </div>
        </div>
        <p id={topicDescriptionElementId} className={styles.TopicDescription}>
          {topic.description}
        </p>
      </div>
    </Container>
  );
}
