import axios, { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { useState, useEffect, useMemo, ReactNode } from 'react';
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import {
  submissionId,
  submissionType,
  submissionShouldPublish,
  submissionSaveStatus,
  submissionIsEdit,
  submissionHasInput,
  submissionState,
  submissionReset,
  submissionIsSubmitting,
} from '../../models/content-submission/atoms';
import { uiOperations } from '../../models/ui';
import { advocateSelectors } from '../../models/advocate';
import { programMembershipSelectors } from '../../models/program-membership';
import submissionActions from '../../models/content-submission/actions';
import ContentSubmissionService, {
  ContentSubmissionTypes,
  publicationStateMap,
} from '../../services/content-submission';
import { ID as NameRequiredDialogID } from '../../components/name-required-dialog';
import { ID as PrivateProfileDialogID } from '../../components/private-profile-dialog';
import { ID as DiscardChangesDialogID } from '../../components/discard-changes-dialog/discard-changes-dialog';
import { programSelectors } from '../../models/program';
import { useLocalStorage } from '../../common/use-local-storage';
import { SUBMISSION_AUTOSAVE_PREFIX } from './constant';
import {
  ContentFormState,
  ContentSubmissionDTO,
} from '../../models/content-submission/types';
import { RootPatronState } from '../../common/use-patron-selector';
import { usePushToastOrFlashMessage } from '../../components/v2/toaster/deprecation-helper';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = {
  children: ({
    onSubmit,
  }: {
    onSubmit: (saveAs: ContentSubmissionTypes) => void;
  }) => ReactNode;
};

type ContentSubmissionFormProps = StateProps & DispatchProps & OwnProps;
const ContentSubmissionForm = ({
  advocateId,
  advocateNameMissing,
  advocateIsPrivate,
  advocateHidPrivateWarning,
  profileEditNameEnabled,
  addOverlay,
  removeOverlay,
  submitContentBegin,
  children,
}: ContentSubmissionFormProps) => {
  const [navigateAway, setNavigateAway] = useState(false);
  const setSaveStatus = useSetRecoilState(submissionSaveStatus);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const contentId = useRecoilValue(submissionId);
  const contentType = useRecoilValue(submissionType);
  const shouldPublish = useRecoilValue(submissionShouldPublish);
  const isEdit = useRecoilValue(submissionIsEdit);
  const hasInput = useRecoilValue(submissionHasInput);
  const resetSubmissionState = useResetRecoilState(submissionReset);
  const formState = useRecoilValue<ContentFormState>(submissionState);

  const { pushMessage } = usePushToastOrFlashMessage();

  const {
    storeEnabled: autoSaveEnabled,
    update: setAutoSavedValue,
    remove: removeAutoSavedValue,
  } = useLocalStorage(`${SUBMISSION_AUTOSAVE_PREFIX}_${contentType}`);

  const { t } = useTranslation();
  const history = useHistory();

  const getSubmissionPayload = (): ContentSubmissionDTO => {
    const newVideoUrl = formState.videoUrl
      ? formState.videoUrl?.split(/[?#]/)[0] ?? // remove auth data from videoUrl
        null
      : '';

    return {
      content_type: formState.type,
      content_channel_ids_list: formState.channelIds.join(','),
      title: formState.title,
      description: formState.description,
      url: formState.url,
      thumbnail_url: formState.imageUrls[0],
      color: formState.noteColor,
      body: formState.article,
      uploaded_video_url: newVideoUrl,
      images: formState.imageUrls?.map((url) => ({ url })) ?? null,
      is_commentable: formState.isCommentable,
      is_shareable: formState.isShareable,
      is_translatable: formState.isTranslatable,
    };
  };

  const handleSubmit = (saveAs: ContentSubmissionTypes) => {
    if (isSubmitting) return;
    const presubmitDialog =
      !saveAs || saveAs === 'publish'
        ? advocateNameMissing && profileEditNameEnabled
          ? NameRequiredDialogID
          : advocateIsPrivate && !advocateHidPrivateWarning
          ? PrivateProfileDialogID
          : null
        : null;

    if (presubmitDialog) {
      addOverlay(presubmitDialog, {
        advocateId: advocateId,
        continue:
          presubmitDialog === NameRequiredDialogID
            ? () => handleSubmit(saveAs) // run submit again to check profile conditions
            : () => submitContent(saveAs),
      });

      return;
    }

    submitContent(saveAs);
  };

  const submitContent = async (saveAs: ContentSubmissionTypes) => {
    submitContentBegin({ isEdit, contentType });
    setSaveStatus('saving');

    const submissionPayload = getSubmissionPayload();

    const submissionService = new ContentSubmissionService(contentId);
    const submitFn = contentId
      ? submissionService.update
      : submissionService.create;

    // Sometimes payload needs publication_state, sometimes status action
    // should be in the endpoint. Logic for both cases are split between
    // here and the ContentSubmission service because nothing makes sense.
    if (!contentId && (shouldPublish || saveAs === publicationStateMap.draft)) {
      submissionPayload.publication_state = publicationStateMap[saveAs]; // resolve status action w/ persisted status
    }

    try {
      if (contentId) await updateContentState(saveAs);

      await submitFn(submissionPayload, shouldPublish);

      handleSubmitContentSuccess({ archived: saveAs === 'archive' });
    } catch (err) {
      if (err instanceof Error) {
        handleSubmitContentFailure(err);
      }
    }
  };

  const updateContentState = async (saveAs: ContentSubmissionTypes) => {
    const submissionService = new ContentSubmissionService(contentId);

    return submissionService.updateState(saveAs, shouldPublish);
  };

  const handleSubmitContentSuccess = ({ archived }: { archived: boolean }) => {
    removeAutoSavedValue();

    setSaveStatus('saved');
    setNavigateAway(true);

    const text = isEdit
      ? archived
        ? t('messages.post_archived')
        : t('messages.post_updated')
      : t('messages.thanks_for_submitting');

    pushMessage({
      text,
      type: 'success',
    });

    resetSubmissionState();

    // TODO: Analytics
  };

  const handleSubmitContentFailure = (err: Error | AxiosError) => {
    console.error('form submission error', err);
    setSaveStatus('error');

    if (axios.isAxiosError(err) && err?.response?.status === 403) {
      pushMessage({
        text: t('errors.content_permissions_error'),
        type: 'error',
      });
      return;
    }
    // TODO: Analytics

    pushMessage({
      text: t('errors.default'),
      type: 'error',
    });
  };

  const handleDiscardChanges = () => {
    addOverlay(DiscardChangesDialogID, {
      onContinue: () => {
        removeAutoSavedValue();
        removeOverlay({ id: DiscardChangesDialogID, key: undefined });
        setNavigateAway(true);
      },
      onCancel: () => {
        removeOverlay({ id: DiscardChangesDialogID, key: undefined });
      },
    });

    return false;
  };

  const debouncedAutoSave = useMemo(
    () => debounce(setAutoSavedValue, 300), // debounce for 300 milliseconds
    [setAutoSavedValue]
  );

  useEffect(() => {
    if (!autoSaveEnabled || navigateAway) return;
    debouncedAutoSave(formState);
  }, [autoSaveEnabled, debouncedAutoSave, formState, navigateAway]);

  useEffect(() => {
    if (navigateAway) history.goBack();
  }, [navigateAway]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Prompt message={handleDiscardChanges} when={hasInput && !navigateAway} />

      <form
        aria-label={t(`content_submission.${contentType}`)}
        onSubmit={(e) => e.preventDefault()}
      >
        {children({ onSubmit: handleSubmit })}
      </form>
    </>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  advocateId: advocateSelectors.getAdvocateId(state),
  advocateNameMissing: advocateSelectors.getAdvocateNameMissing(state),
  advocateIsPrivate: advocateSelectors.getAdvocateIsPrivate(state),
  advocateHidPrivateWarning:
    programMembershipSelectors.getProgramMembershipHidePrivateWarning(state),
  profileEditNameEnabled: programSelectors.getProfileEditNameEnabled(state),
});

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
  submitContentBegin: submissionActions.submitContentBegin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentSubmissionForm);
