import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { toDate } from '../../lib/date-formatter';
import { programMembershipOperations } from '../../models/program-membership';
import { DateInput, Dialog, Icon, SelectInput, TextInput } from '../ui';
import OnboardingService from '../../services/onboarding';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const initValsFormQuestions = (questions) =>
  questions.reduce((obj, question) => {
    obj[question.id] = '';
    return obj;
  }, {});

const OnboardingDialogQuestions = (props) => {
  const questions = props.step.questions;

  const [formVals, setFormVals] = useState(initValsFormQuestions(questions));
  const [error, setError] = useState();
  const [isValidated, setIsValidated] = useState();
  const [isSaving, setIsSaving] = useState();

  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const isVerification = props.type === 'onboarding';

  const title = isVerification
    ? t('content.almost_done')
    : t('screens.register.answer_questions');

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    setFormVals({ ...formVals, [name]: val });
  };

  const handleAccept = () => {
    if (!isValidated || isSaving) return;

    setError(null);
    setIsSaving(true);

    submit();
  };

  const handleCancel = () => {
    props.cancel();
  };

  const handleSignout = () => {
    props.signout();
  };

  const submit = async () => {
    const responses = Object.entries(formVals).map(([k, v]) => ({
      question_id: k,
      answer_text: v,
    }));
    const submitFn = isVerification
      ? 'submitVerificationResponses'
      : 'submitQuestionResponses';

    try {
      const res = await new OnboardingService()[submitFn]({ responses });

      handleSubmitSuccess(res);
    } catch (err) {
      if (err.response) {
        handleSubmitFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleSubmitSuccess = (res) => {
    if (res.data && res.data.membership_status) {
      props.updateMembershipStatus(res.data.membership_status);
    }

    // Work around not seeing updated (merged) user data after
    // the UV process until a user-initiated refresh by... doing
    // a non-user-initiated refresh.
    if (res.data && res.data.force_refresh) {
      window.location.reload();
    } else {
      setIsSaving(false);
      props.continue();
    }
  };

  const handleSubmitFailure = (err) => {
    console.error('onboarding dialog questions -- submission error', err);
    if (err.response.status === 429) {
      pushMessage({
        text: err.response.data.errors[0].detail[0].error,
        type: 'error',
      });

      return props.continue();
    }

    if (err.response.data && err.response.data.errors) {
      setError(err.response.data.errors[0].detail[0].error);
    } else {
      pushMessage({
        text: t('errors.default'),
        type: 'error',
      });
    }

    setIsSaving(false);
  };

  useEffect(() => {
    const validateValues = () => {
      const hasEmptyVals = questions.some((q) => q.required && !formVals[q.id]);

      setIsValidated(!hasEmptyVals);
    };

    const debouncedValidateValues = debounce(validateValues, 250);

    debouncedValidateValues();
  }, [formVals, questions]);

  const cancelButton = props.cancel
    ? { text: t('common.skip'), disabled: isSaving, onClick: handleCancel }
    : props.signout
    ? { text: t('common.sign_out'), disabled: isSaving, onClick: handleSignout }
    : null;

  return (
    <Dialog
      title={title}
      accept={{
        text: t('common.continue'),
        icon: 'check',
        disabled: !isValidated || isSaving,
        loading: isSaving,
        onClick: handleAccept,
      }}
      cancel={cancelButton}
      className="onboarding-dialog"
    >
      <div className="onboarding-dialog__body">
        {isVerification ? (
          <div className="help-text">{t('content.validate_account')}</div>
        ) : null}

        <div className="onboarding-dialog__form form">
          {error ? (
            <div className="error">
              <Icon type="warning" /> {error}
            </div>
          ) : null}

          {questions.map((question) => (
            <Question
              {...question}
              value={formVals[question.id]}
              onChange={handleChange}
              key={question.id}
            />
          ))}
        </div>
      </div>
    </Dialog>
  );
};

const Question = ({
  id: name,
  question_type: type,
  question_text: label,
  help_text: placeholder,
  choices: options,
  value = '',
  required,
  isDisabled,
  onChange,
}) => {
  label = required ? label + ' *' : label;

  if (type === 'dropdown')
    return (
      <SelectInput
        name={name}
        label={label}
        options={options.map((option) => ({ label: option }))}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChange={onChange}
      />
    );

  if (type === 'date_picker')
    return (
      <DateInput
        name={name}
        label={label}
        placeholder={placeholder}
        minDate={toDate('1920-01-01')}
        maxDate={toDate()}
        onChange={onChange}
        customInput
      />
    );

  if (type !== 'dropdown' && type !== 'date_picker')
    return (
      <TextInput
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    );
};

const mapDispatchToProps = {
  updateMembershipStatus:
    programMembershipOperations.updateProgramMembershipStatus,
};

export default connect(null, mapDispatchToProps)(OnboardingDialogQuestions);
