import React, {
  useEffect,
  useState,
  useRef,
  MouseEventHandler,
  useCallback,
  KeyboardEventHandler,
} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Icon } from '../ui';
import isString from 'lodash/isString';
import cx from 'classnames';
import {
  assistantSelectors,
  assistantOperations,
} from '../../models/assistant';
import {
  trackAssistantSearchClick,
  trackAssistantSearchType,
  trackAssistantExampleClick,
  trackAssistantSearchSuccess,
  trackAssistantSearchFail,
} from '../../models/assistant/analytics';
import { Key } from 'ts-keycode-enum';
import { RootPatronState } from '../../common/use-patron-selector';
import { CommandButton } from '../../models/assistant/interfaces/Command';

const DEBOUNCE_DELAY = 450;
const MAX_LENGTH = 5;

function sanitizeSearchText(searchText: unknown) {
  return isString(searchText) ? searchText.trim().substring(0, 100) : '';
}

const debouncedHandleTypeEvent = debounce((searchText) => {
  if (searchText) {
    trackAssistantSearchType({ search_text: sanitizeSearchText(searchText) });
  }
}, DEBOUNCE_DELAY);

const debouncedHandleSearchEvent = debounce((commandText, filteredCommands) => {
  if (commandText && filteredCommands.length) {
    trackAssistantSearchSuccess({
      search_text: sanitizeSearchText(commandText),
      result_count: filteredCommands.length,
    });
  } else if (commandText && !filteredCommands.length) {
    trackAssistantSearchFail({
      search_text: sanitizeSearchText(commandText),
    });
  }
}, DEBOUNCE_DELAY);

interface AssistantCommandProps {
  commandText?: ReturnType<typeof assistantSelectors.commandText>;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  onKeyDown: KeyboardEventHandler;
  command: CommandButton;
  tabIndex?: number;
}

const AssistantCommand: React.FC<AssistantCommandProps> = ({
  commandText,
  command,
  onSelect,
  onKeyDown,
  tabIndex = undefined,
}) => (
  <li className="assistant__command">
    <button
      role="link"
      onClick={onSelect}
      onKeyDown={onKeyDown}
      aria-label={command.button_text}
      tabIndex={tabIndex}
    >
      {/* If the button_image_url begins with http, show the img from Pythia, else show the Material Icon */}
      {command.button_image_url &&
      command.button_image_url.startsWith('http') ? (
        <img
          role="presentation"
          src={command.button_image_url}
          alt={`${command.button_text} Button`}
        />
      ) : (
        <Icon type={command.button_image_url || 'article'} />
      )}
      <span>{commandText || command.button_text}</span>
    </button>
  </li>
);

interface AssistantCommandSelectorProps {
  onSelect: (command: CommandButton) => void;
  filteredCommands: ReturnType<typeof assistantSelectors.getCommandsByName>;
  exampleCommands: ReturnType<typeof assistantSelectors.getExampleCommands>;
  searchCommand: ReturnType<typeof assistantSelectors.getSearchCommand>;
  setCommandText: typeof assistantOperations.setCommandText;
  commandText: ReturnType<typeof assistantSelectors.commandText>;
  labels: ReturnType<typeof assistantSelectors.getLabels>;
}

const AssistantCommandSelector = ({
  onSelect,
  filteredCommands,
  exampleCommands,
  searchCommand,
  setCommandText,
  commandText,
  labels,
}: AssistantCommandSelectorProps) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const handleTextChange = useCallback(
    (e) => {
      debouncedHandleTypeEvent(e.target.value);
      setCommandText(e.target.value);
    },
    [setCommandText]
  );

  const handleCommandSelect = useCallback(
    (command, position) => {
      setCommandText('');
      trackAssistantExampleClick({
        position,
        search_text: commandText,
        ...command.tracking_context,
      });

      onSelect({
        ...command,
        action: {
          ...command.action,
          request: {
            ...command.action.request,
            payload_string: JSON.stringify({ commandText }),
          },
        },
      });
      setShowPopup(false);
    },
    [setCommandText, commandText, onSelect]
  );

  const handleClick = useCallback(() => {
    trackAssistantSearchClick();
  }, []);

  const handleFocus = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const handleBlur = useCallback(
    (e) => {
      // Don't blur if target we're moving focus to is within currentTarget
      const newTargetElement = e.relatedTarget || document.activeElement;
      if (!e.currentTarget.contains(newTargetElement)) {
        setShowPopup(false);
      }
    },
    [setShowPopup]
  );

  const searchTabIndex = ref.current?.tabIndex || 1;

  let currentTabIndex = searchTabIndex;

  const commands =
    (filteredCommands?.length ? filteredCommands : exampleCommands).slice?.(
      0,
      MAX_LENGTH
    ) || [];

  const handleKeyDown = useCallback(
    (ev) => {
      const lastTabIndex = searchTabIndex + commands.length;
      let tabIndex =
        (ev.target.tabIndex || searchTabIndex) +
        (ev.keyCode === Key.DownArrow ? 1 : -1);
      let element: HTMLInputElement | HTMLButtonElement | null = ref.current; // Fall back to input

      switch (ev.keyCode) {
        case Key.Escape:
          setShowPopup(false);
          ref.current?.blur?.();
          break;
        case Key.UpArrow:
        case Key.DownArrow:
          if (tabIndex < searchTabIndex) {
            // We were on the input. Focus on last command instead.
            tabIndex = lastTabIndex;
          }
          if (tabIndex >= searchTabIndex && tabIndex <= lastTabIndex) {
            element = document.querySelector<HTMLButtonElement>(
              `[tabIndex="${tabIndex}"]`
            );
          }
          element?.focus?.();
          break;
        case Key.Enter:
          if (commandText && ev.target.tabIndex === 0) {
            // We were on the input. Use semantic search if it is enabled.
            if (searchCommand) {
              handleCommandSelect(searchCommand, searchCommand.index);
            }
          } else {
            // We were on a command. Select it.
            element?.click?.();
          }
          break;
      }
    },
    [
      searchTabIndex,
      commands.length,
      commandText,
      searchCommand,
      handleCommandSelect,
    ]
  );

  useEffect(() => {
    debouncedHandleSearchEvent(commandText, filteredCommands);
  }, [commandText, filteredCommands]);

  return (
    <div
      role="search"
      aria-label={t('assistant.command_selector_search_form.form_label')}
      className="assistant__search-input"
      onFocus={handleFocus}
      onBlur={handleBlur}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={
        0 /* This must be provided for Safari to have event.relatedTarget on blur */
      }
    >
      {showPopup && (
        <div
          id="assistant--command-selector"
          className="assistant__command-selector"
          role="dialog"
          aria-label={
            !commandText
              ? t('assistant.command_selector_search_form.examples')
              : t('assistant.command_selector_search_form.results')
          }
        >
          {!commandText && (
            <div className="title">{t('assistant.examples')}</div>
          )}
          {commands.length && (
            <ul>
              {commands.map((command, index) => (
                <AssistantCommand
                  command={command}
                  tabIndex={++currentTabIndex}
                  onKeyDown={handleKeyDown}
                  onSelect={() => handleCommandSelect(command, index)}
                  key={`${command.index || command.button_text}`}
                />
              ))}
              {commandText && searchCommand && (
                <AssistantCommand
                  commandText={commandText}
                  command={searchCommand}
                  onKeyDown={handleKeyDown}
                  onSelect={() =>
                    handleCommandSelect(searchCommand, searchCommand.index)
                  }
                  key={`${commandText}`}
                />
              )}
            </ul>
          )}
          {!commandText && (
            <div className="text">{t('assistant.and_more')}</div>
          )}
        </div>
      )}
      <input
        className={cx({ 'examples-present': showPopup })}
        type="text"
        value={commandText}
        placeholder={labels.command_prompt} //maybe we can get a re-design to give this a proper label, one also available to sighted users
        onChange={handleTextChange}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="combobox"
        aria-haspopup="dialog"
        aria-controls="assistant--command-selector"
        aria-expanded={showPopup}
      />
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  filteredCommands: assistantSelectors.getCommandsByName(state),
  exampleCommands: assistantSelectors.getExampleCommands(state),
  searchCommand: assistantSelectors.getSearchCommand(state),
  labels: assistantSelectors.getLabels(state),
  commandText: assistantSelectors.commandText(state),
});

const mapDispatchToProps = {
  setCommandText: assistantOperations.setCommandText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistantCommandSelector);
