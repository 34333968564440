import React, { Fragment } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';

export const PageHeader = ({ children }) => (
  <header className="page__header">{children}</header>
);

export const PageSidebar = ({ children, ...props }) => {
  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );
  const className = cx('page__sidebar', {
    'page__sidebar-v2': newUIEnabled,
  });
  return (
    <aside className={className} {...props}>
      {children}
    </aside>
  );
};

export const PageMain = ({ children, ...props }) => {
  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );
  const classes = newUIEnabled ? 'page__main-v2' : 'page__main';

  return (
    <main className={classes} {...props}>
      {children}
    </main>
  );
};

export const PageTitle = ({ children }) => (
  <h1 className="page__title">{children}</h1>
);

const Page = ({ className, children }) => {
  const flattenedChildren =
    children.type === Fragment ? children.props.children : children;
  const childrenMap = React.Children.map(
    flattenedChildren,
    (child) => child.type
  );
  const hasSidebar = childrenMap.includes(PageSidebar);

  className = cx(
    'page',
    {
      'page--with-sidebar': hasSidebar,
    },
    className
  );

  return <section className={className}>{children}</section>;
};

export default Page;
