import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './short-list.module.scss';
import { stringify, useSearchQueryParams } from '../query-params';
import { UnableToLoadError } from '../error/error';
import { useLocalizedSearchType } from '../locale';
import { SearchType, useSearchRoute } from '../search-screen';
import { trackSearchPreviewViewAllClick } from '../../../../models/analytics';
import { CardLink, PreviewCard } from '../../topic-page-v2/preview/common';
import { capitalize } from 'lodash';

interface ShortListContainerProps {
  error: boolean;
  viewAllUrl: string;
  dataType: SearchType;
  dataCount: number;
}

const ShortListContainer: React.FC<ShortListContainerProps> = ({
  error,
  viewAllUrl,
  dataType,
  dataCount,
  children,
}) => {
  const { searchType } = useSearchRoute();
  const localizedType = useLocalizedSearchType(
    dataType ? dataType : searchType
  );
  const [query] = useSearchQueryParams({});
  const { t } = useTranslation();

  let viewAllLink;
  let content;
  if (error) {
    content = <UnableToLoadError entity={localizedType} />;
  } else if (dataCount < 1) {
    content = (
      <div className={styles.ChildrenContainer}>
        <h2 className={styles.NoResults}>
          {t('search.no_results_type.title', {
            type: t(`search.types.${dataType}`),
          })}
        </h2>
      </div>
    );
    capitalize();
  } else {
    viewAllLink = (
      <CardLink
        label={t('search.view_all')}
        to={`${viewAllUrl}?${stringify(query)}`}
        onClick={() =>
          trackSearchPreviewViewAllClick({ result_type: dataType })
        }
      />
    );
    content = <div className={styles.ChildrenContainer}>{children}</div>;
  }

  return (
    <PreviewCard
      title={localizedType}
      link={viewAllLink}
      className={styles.Container}
    >
      {content}
    </PreviewCard>
  );
};

export default ShortListContainer;
