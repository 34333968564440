export const webEvents = {
  COMMENT_AI_SUGGESTION_START: 'w:Comment:AISuggestion',
  COMMENT_AI_SUGGESTION_CANCEL: 'w:Comment:AISuggestion:cancel:click',
  COMMENT_AI_SUGGESTION_APPLY: 'w:Comment:AISuggestion:apply:click',
  COMMENT_AI_SUGGESTION_RETRY: 'w:Comment:AISuggestion:regenerate:click',
};

export const iosEvents: Record<keyof typeof webEvents, string> = {
  COMMENT_AI_SUGGESTION_START: 'iOS:Comment:AISuggestion',
  COMMENT_AI_SUGGESTION_CANCEL: 'iOS:Comment:AISuggestion:cancel:tap',
  COMMENT_AI_SUGGESTION_APPLY: 'iOS:Comment:AISuggestion:apply:tap',
  COMMENT_AI_SUGGESTION_RETRY: 'iOS:Comment:AISuggestion:regenerate:tap',
};

export const androidEvents: Record<keyof typeof webEvents, string> = {
  COMMENT_AI_SUGGESTION_START: 'ADV:Comment:AISuggestion',
  COMMENT_AI_SUGGESTION_CANCEL: 'ADV:Comment:AISuggestion:cancel:tap',
  COMMENT_AI_SUGGESTION_APPLY: 'ADV:Comment:AISuggestion:apply:tap',
  COMMENT_AI_SUGGESTION_RETRY: 'ADV:Comment:AISuggestion:regenerate:tap',
};
