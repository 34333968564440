import { connect } from 'react-redux';

import { contentSelectors } from '../../../models/content/index.js';
import { languageDirection } from '../../../lib/language-helper';
import { RootPatronState } from '../../../common/use-patron-selector';
import { DangerousHTMLWithMentionsAndAutolinks } from '../../../components/ui/mention-text';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  contentId: number;
};

type ContentInfoSummaryProps = StateProps & OwnProps;

const ContentInfoSummary = ({
  title,
  summary,
  language,
}: ContentInfoSummaryProps) => {
  // Do not display summary if:
  // - No summary is defined for the content
  // - The title is not defined for the content, in which case the summary will take the title's place
  if (!summary || !title) return null;

  return (
    <p dir={languageDirection(language)} className="content-info__summary">
      <DangerousHTMLWithMentionsAndAutolinks text={summary} />
    </p>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  title: contentSelectors.getContentTitle(state, ownProps),
  summary: contentSelectors.getContentSummary(state, ownProps),
  language: contentSelectors.getContentLang(state, ownProps),
});

export default connect(mapStateToProps)(ContentInfoSummary);
