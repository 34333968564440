import { Location } from 'history';
import { PropsWithChildren, useEffect, useState } from 'react';
import {
  matchPath as routerMatchPath,
  MemoryRouter,
  useLocation,
} from 'react-router-dom';

type NestedMemoryRouterProps = InnerRouterProps;

export default function NestedMemoryRouter({
  matchPath,
  children,
  onRouteExit,
}: NestedMemoryRouterProps) {
  const location = useLocation();

  return (
    <MemoryRouter initialEntries={[location]} initialIndex={0}>
      <InnerRouter matchPath={matchPath} onRouteExit={onRouteExit}>
        {children}
      </InnerRouter>
    </MemoryRouter>
  );
}

type InnerRouterProps = PropsWithChildren<{
  matchPath: string;
  onRouteExit: (nextLocation: Location, currentLocation: Location) => void;
}>;

function InnerRouter({ matchPath, children, onRouteExit }: InnerRouterProps) {
  const location = useLocation();
  const isRouteMatch = routerMatchPath(location.pathname, {
    path: matchPath,
    exact: false,
  });

  const [, setLastLocation] = useState<Location>(location);

  useEffect(() => {
    setLastLocation((lastLocation) => {
      if (isRouteMatch) return location;

      onRouteExit(location, lastLocation);
      return location;
    });
  }, [isRouteMatch, location, onRouteExit]);

  return <>{children}</>;
}
