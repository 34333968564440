export const RTL_LANGUAGES = ['fa', 'he', 'iw'];

export function isRTLLanguage(language_code) {
  return RTL_LANGUAGES.includes(language_code);
}

export function languageDirection(language_code) {
  return isRTLLanguage(language_code) ? 'rtl' : 'ltr';
}

export function normalizeLocale(languageCode) {
  return languageCode.toLowerCase().replace(/[_-].+/, '');
}
