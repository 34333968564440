import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EndOfFeed from '../../../../feed/end-of-feed';
import { Spinner } from '../../../../ui';
import ViewTrigger from '../../../../view-trigger';
import { UnableToLoadError } from '../../error/error';
import { FilterBarButton } from '../filter-bar';
import {
  CheckboxFilter,
  CheckboxFilterContent,
  CheckboxFilterItem,
  CheckboxFilterTrigger,
} from '../primitives/checkbox-filter';
import { useSearchFilterOptions } from '../queries';
import { SearchFilter } from './filters';
import styles from '../filter-bar.module.scss';

/**
 * SearchableFilter represents a filter that has a search input to filter the options.
 * The options are dynamic and are fetched from the source property.
 */
export type SearchableFilter = SearchFilter<{
  behaviour: 'searchable';
  parameter: string;
  source: string;
}>;

type SearchableFilterProps = {
  filter: SearchableFilter;
  applyFilter: (values: string[]) => void;
  appliedFilters: string[];
};

/**
 * SearchableFilter is a component that renders a SearchableFilter item.
 * It is used to render a filter that has a search input to filter the options.
 */
export function SearchableFilter({
  filter,
  applyFilter,
  appliedFilters,
}: SearchableFilterProps) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleOptionSelect = (parameter: string, value: string) => {
    if (appliedFilters.includes(value)) {
      applyFilter(appliedFilters.filter((o) => o !== value));
    } else {
      applyFilter([...appliedFilters, value]);
    }
  };

  const [searchQuery, setSearchQuery] = React.useState('');

  const { filterOptions, error, incrementPage, isLoadingMore, isReachingEnd } =
    useSearchFilterOptions(searchQuery, {
      url: filter.source,
      perPage: 10,
    });

  const hasFilterOptions =
    filterOptions !== undefined && filterOptions[0].data?.length > 0;

  return (
    <CheckboxFilter
      open={open}
      onOpenChange={(openChange) => setOpen(openChange)}
    >
      <div className={styles.FilterBar__filterButtonWrapper}>
        <CheckboxFilterTrigger asChild>
          <FilterBarButton
            label={filter.label}
            appliedCount={appliedFilters.length}
          />
        </CheckboxFilterTrigger>
      </div>
      <CheckboxFilterContent
        variant="searchable"
        handleClear={() => applyFilter([])}
        handleInputChange={setSearchQuery}
        value={searchQuery}
      >
        <>
          {hasFilterOptions &&
            filterOptions.map((filterOpts) =>
              filterOpts.data.map(({ attributes }) => (
                <CheckboxFilterItem
                  key={attributes.id}
                  label={attributes.name}
                  value={String(attributes.id)}
                  checked={appliedFilters.includes(String(attributes.id))}
                  onCheckedChange={() =>
                    handleOptionSelect(filter.parameter, String(attributes.id))
                  }
                />
              ))
            )}
          {error && (
            <div
              style={{
                padding: '0px 31px',
              }}
            >
              <UnableToLoadError
                entity={t('search.common.filters')}
                spacing="compact"
              />
            </div>
          )}
          {isLoadingMore ? (
            <Spinner />
          ) : isReachingEnd ? (
            <EndOfFeed />
          ) : (
            <ViewTrigger onInview={incrementPage} />
          )}
        </>
      </CheckboxFilterContent>
    </CheckboxFilter>
  );
}
