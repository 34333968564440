import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import loadMomentLocale from '../lib/load-moment-locale';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',

      detection: {
        caches: [''],
      },

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      react: {
        wait: true,
      },

      backend: {
        loadPath: '/locales/{{lng}}.json',
        // loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
        // crossDomain: true
      },
    },
    (err) => {
      if (!err) {
        loadMomentLocale(i18n.language);
      }
    }
  );

export default i18n;
