import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { uiOperations } from '../../models/ui';

import { Dialog } from '../ui';

const CommentReportConfirmDialog = ({ onReportConfirm, removeOverlay }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('content.comment', { count: 2 })}
      accept={{
        text: t('common.confirm'),
        theme: 'danger',
        onClick: onReportConfirm,
      }}
      cancel={{
        text: t('common.cancel'),
        onClick: () => {
          removeOverlay({ id: ID });
        },
      }}
      close={() => {
        removeOverlay({ id: ID });
      }}
    >
      {t('comments.actions.confirm.report')}
    </Dialog>
  );
};

export const ID = 'Overlays.COMMENT_REPORT_CONFIRM';

export default connect(null, {
  removeOverlay: uiOperations.removeOverlay,
})(CommentReportConfirmDialog);
