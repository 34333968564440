import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import ViewAllLink from '../../components/view-all-link';
import ContentItem from '../../components/content-item';
import ContentList from '../../components/content-list';
import EmptyFeed from '../../components/feed/empty-feed';

const HomeFeaturedFeed = ({
  title,
  seeAllText,
  contents,
  feedUrl,
  minLength,
  maxLength,
  analyticsData,
}) => {
  const { t } = useTranslation();

  const contentIds = contents.map((c) => c.id);

  if (contents.length < minLength) return <EmptyFeed />;

  const isMobileMode = window.matchMedia(
    'only screen and (max-width: 799px)'
  ).matches;
  const itemSize = isMobileMode ? 'small' : 'large';
  const itemOrientation = isMobileMode ? 'landscape' : 'portrait';
  const headerClasses = cx({ 'section-title': !isMobileMode });

  return (
    <div className="home-featured">
      <div className="section-heading">
        <h2 className={headerClasses}>{title}</h2>

        <ViewAllLink href={feedUrl}>
          {seeAllText || `${t('assistant.view_all')} ${title}`}
        </ViewAllLink>
      </div>

      <ContentItem
        contentId={contentIds[0]}
        size={itemSize}
        analyticsData={analyticsData}
        shouldTrackView={true}
        className="featured-content"
      />

      <ContentList
        contentIds={contentIds.slice(1, 4)}
        orientation={itemOrientation}
        size="small"
        shouldTrackView={true}
        analyticsData={analyticsData}
      />
    </div>
  );
};

export default HomeFeaturedFeed;
