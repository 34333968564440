import { useTranslation } from 'react-i18next';
import { Button, Modal } from '../../../ui';
import './copy-link-modal.scss';
import React from 'react';

type CopyLinkModalProps = {
  close: () => void;
  contentURL: string;
};

export function CopyLinkModal({ close, contentURL }: CopyLinkModalProps) {
  const { t } = useTranslation();
  const handleInteraction = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputElement = document.getElementById(
      'content-link-input'
    ) as HTMLInputElement | null;
    inputElement?.select();
  };

  return (
    <Modal
      className="copy-link-modal"
      lockScroll
      onClose={close}
      onFocus={handleInteraction}
    >
      <header className="copy-link-modal__header">
        <div className="copy-link-modal__header--headings">
          <h2 className="title">{t('content.copy_link')}</h2>
          <h3>{t('content.copy_link_modal_description')} </h3>
        </div>
        <div className="copy-link-modal__header--actions">
          <Button theme="icon-only" icon="close" onClick={close} />
        </div>
      </header>
      <div className="copy-link-modal__body">
        <h2 className="title">URL</h2>
        <div>
          <input
            id="content-link-input"
            type="text"
            readOnly={true}
            className="content-link__input"
            defaultValue={contentURL}
          />
        </div>
      </div>
      <footer className="copy-link-modal__footer">
        <Button theme="secondary" onClick={close}>
          {t('common.close')}
        </Button>
      </footer>
    </Modal>
  );
}

export const ID = 'Overlays.COPY_LINK_MODAL';
