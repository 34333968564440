import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { advocateSelectors, advocateOperations } from '../../models/advocate';
import { Avatar, Dialog, TextInput } from '../ui';
import AdvocateService from '../../services/advocate';
import './name-required-dialog.scss';

const NameRequiredDialogHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="name-required-dialog__header">
      <img src="/images/name-required-header.svg" alt="Name required" />

      <div className="name-required-dialog__title">
        {t('comments.name_dialog.title')}
      </div>

      <div className="name-required-dialog__description">
        {t('comments.name_dialog.description')}
      </div>
    </div>
  );
};

const NameRequiredDialog = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSaving, setIsSaving] = useState();

  const { t } = useTranslation();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleAccept = () => {
    const nameData = {
      first_name: firstName,
      last_name: lastName,
    };

    setIsSaving(true);

    new AdvocateService(props.advocateId).update(nameData).then(() => {
      props.continue();
      props.close();
    });

    props.setAdvocate({
      ...props.advocate,
      ...nameData,
      hasFallbackNameValue: false,
    });
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <Dialog
      header={<NameRequiredDialogHeader />}
      accept={{
        text: t('common.done'),
        icon: 'check',
        disabled: !firstName || !lastName,
        loading: isSaving,
        onClick: handleAccept,
      }}
      cancel={{
        text: t('common.cancel'),
        disabled: isSaving,
        onClick: handleCancel,
      }}
      close={handleCancel}
      className={'name-required-dialog'}
    >
      <div className="name-required-dialog__avatar-container">
        <Avatar
          className="name-required-dialog__avatar"
          src={props.avatarUrl}
          bgColor={props.avatarColor}
        />
      </div>

      <div className="name-required-dialog__form form">
        <div className="form-fields">
          <div className="form-fields-group">
            <TextInput
              label={t('screens.profile.edit_profile.first_name')}
              name="first_name"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </div>

          <div className="form-fields-group">
            <TextInput
              label={t('screens.profile.edit_profile.last_name')}
              name="last_name"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  advocate: advocateSelectors.getAdvocate(state, ownProps),
  avatarUrl: advocateSelectors.getAdvocateAvatarUrl(state, ownProps),
  avatarColor: advocateSelectors.getAdvocateAvatarColor(state, ownProps),
});

const mapDispatchToProps = {
  setAdvocate: advocateOperations.setAdvocate,
};

export const ID = 'Overlays.NAME_REQUIRED_DIALOG';

export default connect(mapStateToProps, mapDispatchToProps)(NameRequiredDialog);
