import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useInview from '../../common/use-inview';

import { contentSelectors } from '../../models/content/index.js';

import { GalleryThumbnails } from '../../components/ui';
import {
  ContentFeatureImage,
  ContentTitle,
  ContentSourceInfo,
  ContentActionLike,
  ContentActionTranslate,
  ContentActionComment,
  ContentActionBookmark,
  ContentActionShare,
  ContentActionEdit,
} from '../../components/content';
import ViewAllLink from '../../components/view-all-link';
import useScreenSize from '../../common/use-screen-size';

//need to keep this outside of the community gallery component in order to avoid excessive re-rendering
const GalleryMainImage = ({ contentId, analyticsData }) => {
  const contentCanBookmark = useSelector((state) =>
    contentSelectors.getContentCanBookmark(state, { contentId })
  );
  const contentCanShare = useSelector((state) =>
    contentSelectors.getContentCanShare(state, { contentId })
  );
  const contentCanTranslate = useSelector((state) =>
    contentSelectors.getContentCanTranslate(state, { contentId })
  );

  return (
    <div className="home-community-gallery__image">
      <ContentFeatureImage contentId={contentId} key={contentId} />

      <div className="home-community-gallery__actions">
        {contentCanBookmark && (
          <ContentActionBookmark
            contentId={contentId}
            analyticsData={analyticsData}
          />
        )}
        {contentCanTranslate && (
          <ContentActionTranslate
            contentId={contentId}
            analyticsData={analyticsData}
          />
        )}
        {contentCanShare && (
          <ContentActionShare
            contentId={contentId}
            analyticsData={analyticsData}
          />
        )}
      </div>
    </div>
  );
};

const HomeCommunityGallery = ({
  contents,
  analyticsData,
  feedUrl,
  title,
  seeAllText,
}) => {
  const ref = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState();

  const { t } = useTranslation();

  const content = contents[activeIndex];
  const contentId = content.id;
  const contentCanLike = useSelector((state) =>
    contentSelectors.getContentCanLike(state, { contentId })
  );
  const contentCanComment = useSelector((state) =>
    contentSelectors.getContentCanComment(state, { contentId })
  );
  const contentIsPublished = useSelector((state) =>
    contentSelectors.getContentIsPublished(state, { contentId })
  );
  const contentCanEdit = useSelector((state) =>
    contentSelectors.getContentCanEdit(state, { contentId })
  );

  useInview({
    ref,
    onInview: () => setIsVisible(true),
    onOutview: () => setIsVisible(false),
  });

  const { isDesktop, isTabletOrSmaller: isTablet } = useScreenSize();

  const titleString = title || t('landing.community');

  const getImages = (contents) => {
    return contents
      .filter((c) => !!c.shareable_image_urls)
      .slice(0, 9)
      .map((c) => {
        return {
          url: c.shareable_image_urls[0].url,
          alt: c.title || c.description || 'No Description',
        };
      });
  };

  return (
    <div className="home-community-gallery" ref={ref}>
      <div className="home-community-gallery__body">
        <div className="section-heading">
          <h2 className="section-title">{titleString}</h2>
        </div>

        {isTablet ? (
          <ViewAllLink href={feedUrl}>{t('landing.see_all')}</ViewAllLink>
        ) : null}

        {isTablet ? (
          <GalleryMainImage
            contentId={contentId}
            analyticsData={analyticsData}
          />
        ) : null}

        <div className="home-community-gallery__content">
          <div className="home-community-gallery__title">
            <ContentTitle
              ignoreLink
              contentId={contentId}
              truncate={9}
              analyticsData={analyticsData}
              key={contentId}
            />
          </div>

          <div className="home-community-gallery__source">
            <ContentSourceInfo contentId={contentId} />
          </div>

          <div className="home-community-gallery__actions">
            {contentCanLike && (
              <ContentActionLike
                contentId={contentId}
                analyticsData={analyticsData}
              />
            )}
            {contentCanComment && (
              <ContentActionComment
                contentId={contentId}
                analyticsData={analyticsData}
              />
            )}
            {contentIsPublished && contentCanEdit && (
              <ContentActionEdit
                contentId={contentId}
                analyticsData={analyticsData}
              />
            )}
          </div>
        </div>
      </div>

      {isDesktop ? (
        <GalleryMainImage contentId={contentId} analyticsData={analyticsData} />
      ) : null}

      <div className="home-community-gallery__thumbnails">
        <GalleryThumbnails
          images={getImages(contents)}
          onSelect={setActiveIndex}
          disableKeys={!isVisible}
        />

        {isDesktop ? (
          <ViewAllLink href={feedUrl}>
            {seeAllText || `${t('assistant.view_all')} ${titleString}`}
          </ViewAllLink>
        ) : null}
      </div>
    </div>
  );
};

export default HomeCommunityGallery;
