import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styled, { keyframes } from 'styled-components';

import * as colors from '../../styles/colors';

import { Icon } from '../ui';

const love = keyframes`
  0% {
    top: 110%;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
`;

const Heart = styled(({ className }) => (
  <Icon className={className} type="favorite" />
))`
  position: absolute;
  top: 0%;
  opacity: 0;
  color: ${colors.redFullValue};
`;

const Heartsplosion = styled(({ className, onComplete }) => {
  const hearts = [];

  for (let i = 0; i < 7; i++) {
    hearts.push(<Heart key={i} />);
  }

  useEffect(() => {
    setTimeout(onComplete, 2000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return createPortal(
    <div className={className}>{hearts}</div>,
    document.querySelector('.heartsplosion-container')
  );
})`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;

  ${Heart}:nth-child(1) {
    left: 15%;
    animation: ${love} 1.5s 0.3s;
    font-size: 50px;
  }

  ${Heart}:nth-child(2) {
    left: 25%;
    animation: ${love} 1.7s 0.15s;
    font-size: 75px;
  }

  ${Heart}:nth-child(3) {
    left: 40%;
    animation: ${love} 2s;
    font-size: 125px;
  }

  ${Heart}:nth-child(4) {
    left: 60%;
    animation: ${love} 1.8s 0.25s;
    font-size: 100px;
  }

  ${Heart}:nth-child(5) {
    left: 75%;
    animation: ${love} 1.75s 0.3s;
    font-size: 50px;
  }

  ${Heart}:nth-child(6) {
    left: 30%;
    animation: ${love} 1.83s 0.31s;
    font-size: 62.5px;
  }

  ${Heart}:nth-child(7) {
    left: 50%;
    animation: ${love} 1.75s 0.36s;
    font-size: 50px;
  }
`;

export default Heartsplosion;
