import OnboardingDialogQuestions from './onboarding-dialog-questions';
import OnboardingDialogVideo from './onboarding-dialog-video';
import './onboarding-dialog.scss';

const OnboardingDialog = (props) => {
  const isWelcomeVideo = props.type === 'welcome_video';

  return isWelcomeVideo ? (
    <OnboardingDialogVideo {...props} />
  ) : (
    <OnboardingDialogQuestions {...props} />
  );
};

export const ID = 'Overlays.ONBOARDING_DIALOG';

export default OnboardingDialog;
