import { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  likeComment as likeCommentService,
  unlikeComment as unlikeCommentService,
} from '../../services/comment';

import { Button, LikeIcon, LikeIconOutline } from '../ui';
import Heartsplosion from './heartsplosion';

import { trackCommentEvent } from '../../models/comments/analytics';
import { getFeatureFlag, Feature } from '../../models/features/features';
import { Comment } from '../../models/comments/types';
import { RootPatronState } from '../../common/use-patron-selector';

const CommentActionLike = ({ comment }: { comment: Comment }) => {
  const [liked, setLiked] = useState(comment.isLiked);
  const [canLike, setCanLike] = useState(comment.canLike);
  const [likeCount, setLikeCount] = useState(comment.likeCount);
  const [isShowingHeartsplosion, setIsShowingHeartsplosion] = useState(false);
  const likeIconType = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.LIKE_ICON_TYPE)
  );

  const { t } = useTranslation();

  const empyLikes = likeCount == 0;

  const className = cx('comment__action', 'comment__action--like', {
    'comment__action--liked': liked,
    'comment__action--can-like': canLike || liked,
    'comment__action--like--empty': empyLikes,
  });

  const likeComment = () => {
    if (!canLike) {
      if (!likeIconType) showHeartsplosion();
      return;
    }

    trackCommentEvent('like', comment, !!comment.replyToId);

    likeCommentService(comment.contentId, comment.id);

    setLiked(true);
    setCanLike(false);
    setLikeCount(likeCount + 1);
  };

  const unlikeComment = () => {
    trackCommentEvent('unlike', comment, !!comment.replyToId);

    unlikeCommentService(comment.contentId, comment.id);

    setLiked(false);
    setCanLike(true);
    setLikeCount(Math.max(0, likeCount - 1));
  };

  const showHeartsplosion = () => {
    setIsShowingHeartsplosion(true);
  };

  const closeHeartsplosion = () => {
    setIsShowingHeartsplosion(false);
  };

  const handleClick = () => {
    liked ? unlikeComment() : likeComment();
  };

  return (
    <div className={className}>
      <Button
        onClick={handleClick}
        aria-label={t('content.like', { count: 1 })}
      >
        <span className="icons">
          <LikeIconOutline />
          <LikeIcon />
        </span>

        <span className="count">{empyLikes ? null : likeCount}</span>
      </Button>

      {isShowingHeartsplosion && (
        <Heartsplosion onComplete={closeHeartsplosion} />
      )}
    </div>
  );
};

export default CommentActionLike;
