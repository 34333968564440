import { Modal } from '../ui';
import './loading-error-modal.scss';

const LoadingErrorModal = () => {
  return (
    <Modal className="loading-error-modal">
      <div className="loading-error-modal__title">
        There was an error loading content.
      </div>
      <p className="loading-error-modal__body">
        Please check back shortly.
        <br />
        If problem persists, please contact support.
      </p>
    </Modal>
  );
};

export const ID = 'Overlays.LOADING_ERROR_MODAL';

export default LoadingErrorModal;
