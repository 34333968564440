import React from 'react';
import { Checkbox } from '../../../components/ui';

const ProfileBooleanInput = ({
  name,
  value,
  label,
  placeholder,
  onChange,
  onBlur,
  disabled,
  helpText,
}) => (
  <>
    <Checkbox
      label={label}
      name={name}
      checked={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={disabled}
      helpText={helpText}
    />
  </>
);

export default ProfileBooleanInput;
