import { SortOrder } from '../common/use-comment-feed';
import api from '../config/api';
import {
  CommentActionSuccess,
  CommentPostBatchResponse,
  CommentPostResponse,
} from '../models/comments/types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Generates the base URL for comment-related API endpoints.
 * @param contentId - The ID of the content associated with the comments.
 * @returns The base URL string for comment endpoints.
 */
const baseURL = (contentId: number): string =>
  `/contents/${contentId}/comments`;

/**
 * Fetches multiple comments for a given content.
 * @param params - Object containing contentId and optional request parameters.
 * @returns A promise resolving to an AxiosResponse containing CommentPostBatchResponse.
 */
export const fetchManyComments = ({
  contentId,
  params,
}: {
  contentId: number;
  params?: {
    page?: number;
    translate?: boolean;
    sort_order?: SortOrder;
    page_size?: number;
  };
}): Promise<AxiosResponse<CommentPostBatchResponse>> => {
  const url = baseURL(contentId);
  return api.get(url, { params });
};

/**
 * Fetches replies for a specific comment.
 * @param contentId - The ID of the content.
 * @param replyToId - The ID of the parent comment.
 * @param params - Optional request parameters.
 * @returns A promise resolving to an AxiosResponse containing CommentPostBatchResponse.
 */
export const fetchReplies = (
  contentId: number,
  replyToId: string,
  params?: {
    page?: number;
    translate?: boolean;
    sort_order?: SortOrder;
    page_size?: number;
  }
): Promise<AxiosResponse<CommentPostBatchResponse>> => {
  return api.get(`${baseURL(contentId)}/${replyToId}/replies`, { params });
};

/**
 * Fetches a single comment by its ID.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to fetch.
 * @returns A promise resolving to an AxiosResponse containing CommentPostResponse.
 */
export const fetchCommentById = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentPostResponse>> => {
  return api.get(`${baseURL(contentId)}/${commentId}`);
};

/**
 * Fetches multiple comments by their IDs.
 * @param contentId - The ID of the content.
 * @param commentIds - An array of comment IDs to fetch.
 * @param config - Optional Axios request configuration.
 * @returns A promise resolving to an AxiosResponse containing CommentPostBatchResponse.
 */
export const fetchCommentsByIds = (
  contentId: number,
  commentIds: string[],
  params?: { translate?: boolean }
): Promise<AxiosResponse<CommentPostBatchResponse>> => {
  return api.get(`${baseURL(contentId)}/filter`, {
    params: { comment_ids: commentIds, ...params },
  });
};

/**
 * Reports a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to report.
 * @returns A promise resolving to an AxiosResponse containing CommentActionSuccess.
 */
export const reportComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentActionSuccess>> => {
  return api.post<CommentActionSuccess>(
    `${baseURL(contentId)}/${commentId}/report`
  );
};

/**
 * Deletes a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to delete.
 * @returns A promise resolving to an AxiosResponse containing an empty object.
 */
export const destroyComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<Record<string, never>>> => {
  return api.delete<Record<string, never>>(
    `${baseURL(contentId)}/${commentId}`
  );
};

/**
 * Likes a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to like.
 * @returns A promise resolving to an AxiosResponse containing CommentActionSuccess.
 */
export const likeComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentActionSuccess>> => {
  return api.post<CommentActionSuccess>(
    `${baseURL(contentId)}/${commentId}/like`
  );
};

/**
 * Unlikes a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to unlike.
 * @returns A promise resolving to an AxiosResponse containing CommentActionSuccess.
 */
export const unlikeComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentActionSuccess>> => {
  return api.delete<CommentActionSuccess>(
    `${baseURL(contentId)}/${commentId}/like`
  );
};

/**
 * Highlights a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to highlight.
 * @returns A promise resolving to an AxiosResponse containing CommentActionSuccess.
 */
export const highlightComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentActionSuccess>> => {
  return api.post<CommentActionSuccess>(
    `${baseURL(contentId)}/${commentId}/highlight`
  );
};

/**
 * Removes the highlight from a comment.
 * @param contentId - The ID of the content.
 * @param commentId - The ID of the comment to unhighlight.
 * @returns A promise resolving to an AxiosResponse containing CommentActionSuccess.
 */
export const unhighlightComment = (
  contentId: number,
  commentId: string
): Promise<AxiosResponse<CommentActionSuccess>> => {
  return api.delete<CommentActionSuccess>(
    `${baseURL(contentId)}/${commentId}/highlight`
  );
};

/**
 * Saves a new comment or updates an existing one.
 * @param params - Object containing comment details (contentId, commentId, replyToId, body).
 * @returns A promise resolving to an AxiosResponse containing CommentPostResponse.
 */
export const saveComment = ({
  contentId,
  commentId,
  replyToId,
  body,
}: {
  contentId: number;
  commentId?: string;
  replyToId?: string;
  body: string;
}): Promise<AxiosResponse<CommentPostResponse>> => {
  const params = {
    raw_content: body,
    reply_to_id: replyToId,
  };

  const saveMethod = commentId ? api.put : api.post;

  const url = commentId
    ? `${baseURL(contentId)}/${commentId}`
    : baseURL(contentId);
  return saveMethod<CommentPostResponse>(url, params);
};
