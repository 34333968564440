import { useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { submissionChannelIds } from '../../../models/content-submission/atoms';
import { advocateSelectors } from '../../../models/advocate';

import { Button as OldButton, Icon, TextInput } from '../../../components/ui';
import { Button } from '@socialchorus/shared-ui-components';

import ChannelList from './channel-list';
import { Feature } from '../../../models/features/features';

import useFeatureFlag from '../../../common/use-feature-flag';
import { useProgram } from '../../../common/use-program';
import classNames from 'classnames';
import { useChannels } from './hooks';
import { useSelector } from 'react-redux';

const ChannelsPopover = ({ id, suggestedChannels, onClose }) => {
  const [selectedChannelIds, setSelectedChannelIds] =
    useRecoilState(submissionChannelIds);
  const [channelFilter, setChannelFilter] = useState();
  const { channels, disabled } = useChannels(
    suggestedChannels,
    channelFilter,
    selectedChannelIds
  );

  const hasElevatedRole = useSelector((state) =>
    advocateSelectors.getAdvocateHasElevatedRole(state)
  );
  const program = useProgram();
  const topicLimit = program?.user_generated_content_topic_limit || 0;
  const tescoQ3 = useFeatureFlag(Feature.TESCO_Q3);
  const topicLimitEnabled =
    useFeatureFlag(Feature.UGC_TOPIC_LIMITS) &&
    !!topicLimit &&
    !hasElevatedRole;
  const totalSelected = selectedChannelIds.length;
  const limitReached = topicLimitEnabled && totalSelected >= topicLimit;

  const { t } = useTranslation();

  const handleSearchChange = (e) => {
    setChannelFilter(e.target.value);
  };

  const handleClearAll = () => {
    setSelectedChannelIds([]);
  };

  const newTopicsString = useFeatureFlag(Feature.CHANNELS_TO_TOPICS_ENABLED);
  const publishToChannelString = newTopicsString
    ? 'content_submission.publish_to_topic'
    : 'content_submission.publish_to_channel';
  const searchForChannelsString = newTopicsString
    ? 'content_submission.search_for_topics'
    : 'content_submission.search_for_channels';
  const publishToYourChannelsString = newTopicsString
    ? 'content_submission.publish_to_your_topics'
    : 'content_submission.publish_to_your_channels';

  // need to grab the topic limit from governor: 'user_generated_content_topic_limit'
  let nChannelSelectedString = 'content_submission.n_channel_selected';
  if (newTopicsString) {
    if (topicLimitEnabled) {
      nChannelSelectedString = t(
        'content_submission.n_topic_limit_topic_selected',
        { topicLimit }
      );
    } else {
      nChannelSelectedString = 'content_submission.n_topic_selected';
    }
  }

  const publishableChannelsString = 'content_submission.publishable_channels';
  const submittableChannelsString = 'content_submission.submittable_channels';

  return (
    <div
      id={id}
      className="content-submission-channels-popover"
      role="dialog"
      aria-labelledby="content-submission-channels--title"
    >
      <div className="content-submission-channels-popover__header">
        <div className="content-submission-channels-popover__header__subheader">
          <div
            id="content-submission-channels--title"
            className="content-submission-channels__title"
          >
            {t(publishToChannelString)}
          </div>
          <OldButton
            aria-controls={id}
            aria-expanded
            theme="icon-only"
            onClick={() => onClose()}
          >
            <Icon>close</Icon>
          </OldButton>
        </div>
        {!!topicLimitEnabled && (
          <div className="content-submission-channels-popover__topicLimit">
            {t('content_submission.publish_to_topic_limit', { topicLimit })}
          </div>
        )}

        <div
          role="searchbox"
          aria-controls={[
            'contributor-channel-list',
            'publishable-channel-list',
            'submittable-channel-list',
          ].join(' ')}
          aria-label={t(searchForChannelsString)}
          className="content-submission-channels__search"
        >
          <Icon type="search" />

          <TextInput
            value={channelFilter || ''}
            placeholder={t(searchForChannelsString)}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div
        className={classNames(
          'content-submission-channels-popover__body',
          tescoQ3 && 'clear-all-spacing'
        )}
      >
        {tescoQ3 && (
          <div className="content-submission-channels-popover__toolbar">
            <Button
              label={t('content_submission.clear_all')}
              variant="text"
              size="compact"
              disabled={!totalSelected}
              onClick={handleClearAll}
            />
          </div>
        )}
        {channels.suggested ? (
          <ChannelListContainer
            idPrefix="suggested"
            higlighted
            label={t(
              `content_submission.suggested_${
                newTopicsString ? 'topics' : 'channels'
              }`
            )}
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={channels.suggested}
                disabled={disabled.suggested}
                isTopicLimitReached={limitReached}
                publicationTip
              />
            )}
          </ChannelListContainer>
        ) : null}
        {channels.contributor ? (
          <ChannelListContainer
            idPrefix="contributor"
            label={
              channels.publishable || channels.submittable
                ? t(publishToYourChannelsString)
                : t(publishableChannelsString)
            }
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={channels.contributor}
                disabled={disabled.contributor}
                isTopicLimitReached={limitReached}
              />
            )}
          </ChannelListContainer>
        ) : null}

        {channels.publishable ? (
          <ChannelListContainer
            idPrefix="publishable"
            label={t(publishableChannelsString)}
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={channels.publishable}
                disabled={disabled.publishable}
                isTopicLimitReached={limitReached}
              />
            )}
          </ChannelListContainer>
        ) : null}
        {channels.submittable ? (
          <ChannelListContainer
            idPrefix="submittable"
            label={t(submittableChannelsString)}
          >
            {({ idPrefix, onChange }) => (
              <ChannelList
                idPrefix={idPrefix}
                onChange={onChange}
                channels={channels.submittable}
                disabled={disabled.submittable}
                isTopicLimitReached={limitReached}
              />
            )}
          </ChannelListContainer>
        ) : null}
      </div>

      <div className="content-submission-channels-popover__footer">
        <div role="status" className="content-submission-channels__status">
          {t(nChannelSelectedString, {
            count: totalSelected,
          })}
        </div>
      </div>
    </div>
  );
};

const ChannelListContainer = ({ idPrefix, higlighted, label, children }) => {
  const ref = useRef();
  const [activeDescendantId, setActiveDescendantId] = useState();

  const handleKeyDown = (e) => {
    if (document.activeElement !== ref.current) {
      return;
    }

    if (e.key === 'ArrowUp') {
      const channels = ref.current?.getElementsByClassName(
        'content-submission-channel'
      );

      channels.length > 0 && channels[channels.length - 1]?.focus();
    }

    if (e.key === 'ArrowDown') {
      const channels = ref.current?.getElementsByClassName(
        'content-submission-channel'
      );

      channels.length > 0 && channels[0]?.focus();
    }
  };

  return (
    <div
      ref={ref}
      id={`${idPrefix}-channel-list`}
      role="listbox"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-multiselectable
      aria-labelledby={`${idPrefix}-channel-list--label`}
      aria-activedescendant={activeDescendantId}
      className={classNames(
        'content-submission-channels__container',
        higlighted && 'highlighted'
      )}
    >
      <div
        id={`${idPrefix}-channel-list--label`}
        className="content-submission-channels__subtitle"
      >
        {label}
      </div>
      {children({ idPrefix, onChange: setActiveDescendantId })}
    </div>
  );
};

export default ChannelsPopover;
