import { AxiosResponse } from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import AssistantService from '../services/assistant';
import { assistantOperations } from '../models/assistant';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import { Service } from '../models/assistant/interfaces/subject/Service';
import { AssistantInbox } from '../models/assistant/selectors';
import { CommandButton } from '../models/assistant/interfaces/Command';
import usePropMemo from './use-prop-memo';

export enum AssistantDataType {
  BOOTSTRAP = 'bootstrap',
  COMMANDS = 'commands',
  INBOX = 'inbox',
  INBOX_COUNT = 'inboxCount',
  INTEGRATIONS = 'integrations',
  QUICK_ACTIONS = 'quickActions',
}

interface AssistantFetcherBaseResult {
  fetchAssistantData: () => void;
  isFetching: boolean;
  isError: boolean;
}

interface AssistantFetcherCompleteResult<T extends AssistantDataType>
  extends AssistantFetcherBaseResult {
  isFetching: false;
  isError: false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assistantData: { [key in T]: any };
}

interface AssistantFetcherIncompleteResult extends AssistantFetcherBaseResult {
  isFetching: true;
  assistantData: null;
}

interface AssistantFetcherErrorResult extends AssistantFetcherBaseResult {
  isError: true;
  assistantData: null;
}

type AssistantFetcherResult<T extends AssistantDataType> =
  | AssistantFetcherCompleteResult<T>
  | AssistantFetcherIncompleteResult
  | AssistantFetcherErrorResult;

type UseAssistantFetcherProps<T extends AssistantDataType> = {
  itemTypes: T[];
  disabled?: boolean;
};

const useAssistantFetcher = <T extends AssistantDataType>({
  itemTypes: itemTypesProp,
  disabled,
}: UseAssistantFetcherProps<T>): AssistantFetcherResult<T> => {
  const dispatch = useDispatch();

  const [assistantData, setAssistantData] = useState<
    AssistantFetcherCompleteResult<T>['assistantData'] | null
  >(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const dataMap = useMemo(() => {
    const addInboxToStore = (inbox: AssistantInbox[]) =>
      dispatch(assistantOperations.setInbox(inbox));
    const addBootstrapToStore = (bootstrap: unknown) =>
      dispatch(assistantOperations.setBootstrap(bootstrap));
    const addCommandsToStore = (commands: CommandButton[]) =>
      dispatch(assistantOperations.setCommands(commands));
    const addIntegrationsToStore = (
      integrations: AssistantResponse<Service>[]
    ) => dispatch(assistantOperations.setIntegrations(integrations));
    const addQuickActionsToStore = (commands: AssistantResponse<Service>[]) =>
      dispatch(assistantOperations.setQuickActions(commands));
    const addInboxCountToStore = (count: number) =>
      dispatch(assistantOperations.setInboxCount(count));

    const service = new AssistantService();

    return {
      [AssistantDataType.BOOTSTRAP]: {
        serviceCall: service.fetchBootstrap,
        setData: addBootstrapToStore,
      },
      [AssistantDataType.INBOX]: {
        serviceCall: service.fetchInbox,
        setData: addInboxToStore,
      },
      [AssistantDataType.INBOX_COUNT]: {
        serviceCall: service.fetchInboxCount,
        setData: (inboxCount: AxiosResponse<{ count: number }>) =>
          addInboxCountToStore(inboxCount.data.count),
      },
      [AssistantDataType.INTEGRATIONS]: {
        serviceCall: service.fetchIntegrations,
        setData: addIntegrationsToStore,
      },
      [AssistantDataType.COMMANDS]: {
        serviceCall: service.fetchCommands,
        setData: addCommandsToStore,
      },
      [AssistantDataType.QUICK_ACTIONS]: {
        serviceCall: service.fetchQuickActions,
        setData: addQuickActionsToStore,
      },
    };
  }, [dispatch]);

  const itemTypes = usePropMemo(itemTypesProp);

  const handleFetchAssistantSuccess = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (results: any[]) => {
      const constructedData = {} as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key in T]: any;
      };

      itemTypes.forEach((type, index) => {
        constructedData[type] = results[index];

        const addToStore = dataMap[type].setData;
        addToStore && addToStore(results[index]);
      });

      setIsFetching(false);
      setIsError(false);
      setAssistantData(constructedData);
    },
    [dataMap, itemTypes]
  );

  const handleFetchAssistantError = () => {
    setIsError(true);
    setIsFetching(false);
  };

  const fetchAssistantData = useCallback(() => {
    if (disabled) return;

    setIsFetching(true);

    const promises = itemTypes.map((type) => dataMap[type].serviceCall());

    Promise.all(promises).then(
      handleFetchAssistantSuccess,
      handleFetchAssistantError
    );
  }, [dataMap, disabled, handleFetchAssistantSuccess, itemTypes]);

  useEffect(() => {
    fetchAssistantData();
  }, [fetchAssistantData]);

  return {
    assistantData,
    fetchAssistantData,
    isFetching,
    isError,
  } as AssistantFetcherResult<T>;
};

export default useAssistantFetcher;
