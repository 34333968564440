import React from 'react';
import { connect } from 'react-redux';
import { contentSelectors } from '../../../models/content/index.js';

import { getFeatureFlag, Feature } from '../../../models/features/features';

import { ContentImageNote, ContentImageDefault } from './';
import ContentCardThumbnail from '../../v2/content-card/content-card-thumbnail/content-card-thumbnail';

const ContentImage = ({
  contentId,
  newUIEnabled,
  contentType,
  bgColor,
  ...props
}) => {
  const CONTENT_TYPE_COMPONENT_MAP = {
    note: newUIEnabled ? ContentCardThumbnail : ContentImageNote,
  };

  const CONTENT_TYPE_PROPS_MAP = {
    note: newUIEnabled
      ? {
          imgSizing: 'fit',
          contentProperties: { contentType: 'note', backgroundColor: bgColor },
        }
      : {},
  };

  const ImageComponent =
    CONTENT_TYPE_COMPONENT_MAP[contentType] || ContentImageDefault;
  const componentProps = CONTENT_TYPE_PROPS_MAP[contentType] || {};

  return (
    <ImageComponent contentId={contentId} {...componentProps} {...props} />
  );
};

const mapStateToProps = (state, ownProps) => ({
  newUIEnabled: getFeatureFlag(state, Feature.NEW_UI_ENABLED),
  contentType: contentSelectors.getContentType(state, ownProps),
  bgColor: contentSelectors.getContentBorderColor(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentImage);
