import { useRef } from 'react';

import useInview from '../../common/use-inview';

interface ViewTriggerProps {
  offset?: string;
  onInview?: () => void;
}

function ViewTrigger({ offset, onInview }: ViewTriggerProps) {
  const ref = useRef<HTMLInputElement>(null);

  useInview({
    ref,
    offset,
    once: true,
    onInview,
  });

  return (
    <div
      style={{
        marginBottom: '1px',
        height: '1px',
      }}
      ref={ref}
    />
  );
}

export default ViewTrigger;
