import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const TABLET_BREAKPOINT = 1160; //this is the same as $tablet-breakpoint
const MOBILE_BREAKPOINT = 800; //this is equivalent to $mobile-breakpoint

const useScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const debouncedSetWidth = debounce(() => setWidth(window.innerWidth), 250);

    window.addEventListener('resize', debouncedSetWidth);

    return () => {
      window.removeEventListener('resize', debouncedSetWidth);
    };
  }, [setWidth]);

  return {
    isDesktop: width >= TABLET_BREAKPOINT,
    isTabletOrLarger: width >= MOBILE_BREAKPOINT,
    isTabletOrSmaller: width < TABLET_BREAKPOINT,
    isMobile: width < MOBILE_BREAKPOINT,
    width,
  };
};

export default useScreenSize;
