import cx from 'classnames';
import { HTMLAttributes } from 'react';
import { LooseAutocomplete } from '../../lib/utility-types';
import './ui.scss';

const conflictingClasses = ['image', 'link']; // Classnames which clash with other code/CSS

const iconSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '2rem',
} as const;

type IconSize = keyof typeof iconSizes;
type MaterialIcon =
  | 'article'
  | 'chevron_left'
  | 'chevron_right'
  | 'close'
  | 'lock'
  | 'push_pin'
  | 'search'
  | 'spinner'
  | 'star'
  | 'check_circle'
  | 'error'
  | 'open_in_new';

type IconProps = {
  /** The icon to display.
   *
   * Unfortunately we can't fully type this so we'll just have to add the icons we use as we go.
   *
   * If you want to use an icon that isn't here, you can add it to the list of types in the `MaterialIcon` type.
   *
   * You can search for icons [here](https://material.io/resources/icons/?style=baseline)
   */
  type: LooseAutocomplete<MaterialIcon>;
  size?: IconSize;
} & HTMLAttributes<HTMLElement>;

const Icon = ({ type, size, children, ...elementProps }: IconProps) => {
  const iconType = conflictingClasses.includes(type as string) ? '' : type;
  elementProps.style = {
    fontSize: size && iconSizes[size],
    ...elementProps.style,
  };
  elementProps.className = cx('icon', iconType, elementProps.className);

  return (
    <i role="img" {...elementProps}>
      {type === 'spinner' ? children : type || children}
    </i>
  );
};
export default Icon;
