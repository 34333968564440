import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useInview from '../../common/use-inview';
import { RootPatronState } from '../../common/use-patron-selector';
import { Avatar, Icon, Link } from '../../components/ui';
import { trackProfileView, trackProfileClick } from '../../models/analytics';
import { SearchedProfile } from '../../models/advocate/types';
import { programSelectors } from '../../models/program';
import './profile-card.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  profile: SearchedProfile;
  analyticsData: IAnalyticsData;
};

type ProfileCardStandardProps = OwnProps;

const ProfileCardStandard = ({
  profile,
  analyticsData,
}: ProfileCardStandardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleProfileClick = () => {
    trackProfileClick(analyticsDataProfile);
  };

  const handleView = () => {
    trackProfileView(analyticsDataProfile);
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  const profileId = profile.user_id;
  const isPrivate = profile.private_profile;
  const name = `${profile.name.trim()}`;
  const avatarUrl = profile.avatar.url;
  const avatarColor = profile.avatar.color;

  const analyticsDataProfile = {
    ...analyticsData,
    advocate_id: profileId,
  };

  return (
    <div className="profile-card" ref={ref}>
      <div className="profile-card__image">
        <Link
          href={`/profiles/${profileId}`}
          action="push"
          onClick={handleProfileClick}
        >
          <Avatar src={avatarUrl || undefined} bgColor={avatarColor} />

          {isPrivate ? <Icon type="lock" /> : null}
        </Link>
      </div>
      <div className="profile-card__body">
        <div className="profile-card__name">
          <Link href={`/profiles/${profileId}`} action="push">
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

type ProfileCardDirectoryExtraFieldsProps = Pick<
  StateProps,
  'orgChartEnabled'
> &
  OwnProps;

const ProfileCardDirectoryExtraFields = ({
  profile,
  analyticsData,
  orgChartEnabled,
}: ProfileCardDirectoryExtraFieldsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleProfileClick = () => {
    trackProfileClick(analyticsDataProfile);
  };

  const handleView = () => {
    trackProfileView(analyticsDataProfile);
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  const { work_location, job_title, display_name } = profile;
  const profileId = profile.user_id;
  const isPrivate = profile.private_profile;
  const name = `${profile.name.trim()}`;
  const avatarUrl = profile.avatar.url;
  const avatarColor = profile.avatar.color;
  const orgChartLink = `/profiles/${profileId}/organization`;

  const analyticsDataProfile = {
    ...analyticsData,
    advocate_id: profileId,
  };

  return (
    <div className="profile-card" ref={ref}>
      <div className="profile-card__image">
        <Link
          href={`/profiles/${profileId}`}
          action="push"
          onClick={handleProfileClick}
        >
          <Avatar src={avatarUrl || undefined} bgColor={avatarColor} />

          {isPrivate ? <Icon type="lock" /> : null}
        </Link>
      </div>
      <div className="profile-card__body">
        <div className="profile-card__name directory-extra-fields">
          <Link href={`/profiles/${profileId}`} action="push">
            {name}
          </Link>
        </div>
        {display_name ? (
          <div className="profile-card__dsplay-name">
            <Link href={`/profiles/${profileId}`} action="push">
              {display_name}
            </Link>
          </div>
        ) : null}
        {work_location ? (
          <div className="profile-card__work-location">
            <Link href={`/profiles/${profileId}`} action="push">
              {work_location}
            </Link>
          </div>
        ) : null}
        {job_title ? (
          <div className="profile-card__job-title">
            <Link href={`/profiles/${profileId}`} action="push">
              {job_title}
            </Link>
          </div>
        ) : null}
        {orgChartEnabled ? (
          <div className="profile-card__org-chart">
            <Link
              href={orgChartLink}
              action="push"
              className="pt-secondary-color"
            >
              {t('screens.profile.organization.title')}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

type ProfileCardProps = StateProps & OwnProps;

const ProfileCard = ({
  profile,
  analyticsData,
  directoryExtraFieldsEnabled,
  orgChartEnabled,
}: ProfileCardProps) => {
  return directoryExtraFieldsEnabled ? (
    <ProfileCardDirectoryExtraFields
      profile={profile}
      analyticsData={analyticsData}
      orgChartEnabled={orgChartEnabled}
    />
  ) : (
    <ProfileCardStandard profile={profile} analyticsData={analyticsData} />
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  directoryExtraFieldsEnabled:
    programSelectors.getDirectoryExtraFieldsEnabled(state),
  orgChartEnabled: programSelectors.getOrgChartEnabled(state),
});

export default connect(mapStateToProps)(ProfileCard);
