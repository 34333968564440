import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { programSelectors } from '../../models/program';
import { advocateSelectors } from '../../models/advocate';
import PasswordValidator from '../../lib/password-validator';
import AdvocateService from '../../services/advocate';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const PasswordFormContainer = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const strongPasswordRequired = useSelector((state) =>
    programSelectors.getProgramStrongPasswordRequired(state, props)
  );

  const passwordValidations = useSelector((state) =>
    programSelectors.getProgramPasswordValidations(state, props)
  );

  const advocateId = useSelector((state) =>
    advocateSelectors.getAdvocateId(state, props)
  );

  const passwordValidator = new PasswordValidator(strongPasswordRequired);

  const [validationErrors, setValidationErrors] = useState(
    passwordValidator.validateAll('')
  );
  const [values, setValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const isValid = !!(
    values.password &&
    values.password.length > 0 &&
    values.old_password &&
    values.old_password.length > 0 &&
    values.confirm_password === values.password &&
    !validationErrors.length
  );

  const handleChange = (e) => {
    const password =
      e.target.name === 'password' ? e.target.value : values.password || '';

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });

    setValidationErrors(passwordValidator.validateAll(password));
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    setIsSubmitting(true);
    setError(false);

    try {
      await new AdvocateService(advocateId).updatePassword(
        values.old_password,
        values.password
      );

      handleSubmitSuccess();
    } catch (err) {
      handleSubmitFailure(err);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSubmitting(false);

    pushMessage({
      text: t('screens.profile.edit_profile.password_update_success'),
      type: 'success',
    });

    props.close();
  };

  const handleSubmitFailure = (err) => {
    const error = err.response.data.errors && err.response.data.errors[0].title;

    setIsSubmitting(false);
    setError(error);

    if (err.response.status !== 401)
      pushMessage({
        text: t('screens.profile.edit_profile.password_update_error'),
        type: 'error',
      });
  };

  return children({
    values,
    passwordValidations,
    validationErrors,
    error,
    isSubmitting,
    isValid,
    onChange: handleChange,
    onSubmit: handleSubmit,
  });
};

export default PasswordFormContainer;
