import EmojiPickerListItem from './EmojiPickerListItem';
import emojis from '../../data/emojis';

const EmojiPickerList = ({ category, onEmojiClick }) => {
  const categoryEmojis = emojis.filter(
    (emoji) => !category || emoji.category === category
  );

  return (
    <div className="emoji-picker__list">
      {categoryEmojis.map((emoji, index) => (
        <EmojiPickerListItem {...emoji} onClick={onEmojiClick} key={index} />
      ))}
    </div>
  );
};

export default EmojiPickerList;
