import cx from 'classnames';
import React, { ComponentProps, MouseEvent, PropsWithRef } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { LocationDescriptor, Path } from 'history';
import { Link } from '../ui';
import { usePrependProgramPath } from '../../common/use-prepend-program-path';

type RouterTo<TState = unknown> = Path | LocationDescriptor<TState>;
type Props<TState> = (
  | {
      variant?: 'default' | 'disabled';
    }
  | { variant: 'nav'; exact?: boolean }
) & { to: RouterTo<TState> } & ComponentProps<'a'>;
type AssistantLinkProps<TState> = PropsWithRef<Props<TState>>;

function isModifiedEvent(event: MouseEvent<HTMLAnchorElement>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function addSearchParamsToPath(
  url: string,
  routerState: Record<string, string>
) {
  const searchParams = new URLSearchParams(routerState);
  if (!searchParams.toString()) {
    return url;
  }
  return `${url}?${searchParams.toString()}`;
}

function AssistantLinkWithoutRef<T>(
  props: AssistantLinkProps<T>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { children, to, onClick, variant = 'default', ...anchorProps } = props;

  const prependedPath = usePrependProgramPath(
    typeof to === 'string' ? to : to.pathname ?? ''
  );
  const routerState = (typeof to === 'string' ? undefined : to.state) ?? {};
  const href = routerState
    ? addSearchParamsToPath(prependedPath, routerState)
    : prependedPath;

  const history = useHistory();
  const handleOnClick: typeof props['onClick'] = (e) => {
    // https://github.com/remix-run/react-router/blob/v5/packages/react-router-dom/modules/Link.js
    try {
      if (onClick) onClick(e);
    } catch (ex) {
      e.preventDefault();
      throw ex;
    }

    if (
      !e.defaultPrevented && // onClick prevented default
      e.button === 0 && // ignore everything but left clicks
      (!anchorProps.target || anchorProps.target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(e) // ignore clicks with modifier keys
    ) {
      e.preventDefault();
      history.push(to);
    }
  };

  const matchPath = useRouteMatch({
    path: typeof to === 'string' ? to : to.pathname ?? '',
    exact: props.variant === 'nav' ? props.exact : undefined,
  });

  const { className } = anchorProps;

  switch (variant) {
    case 'disabled':
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          {...anchorProps}
          role="link"
          aria-disabled="true"
          style={{ pointerEvents: 'none' }}
        >
          {children}
        </a>
      );
    case 'nav':
      return (
        <Link
          onClick={handleOnClick}
          href={href.toString()}
          {...anchorProps}
          className={cx(className, matchPath ? 'active' : '')}
          ref={ref}
        >
          {children}
        </Link>
      );
    case 'default':
      return (
        <Link
          onClick={handleOnClick}
          href={href.toString()}
          {...anchorProps}
          ref={ref}
        >
          {children}
        </Link>
      );
  }
}

/**
 * This component is a wrapper around our UI Link component.
 * It prepends the program path to the url, and uses useHistory to push the
 * url to the history stack.
 *
 * This is required as we use a MemoryRouter in Assistant and need to handle
 * the history stack ourselves.
 *
 */
export const AssistantLink = React.forwardRef(AssistantLinkWithoutRef);
AssistantLink.displayName = 'AssistantLink';
