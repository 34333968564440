const SET_ITEM = 'patron/assistant/SET_ITEM';
const SET_COMMANDS = 'patron/assistant/SET_COMMANDS';
const SET_INTEGRATIONS = 'patron/assistant/SET_INTEGRATIONS';
const ADD_HISTORY_ITEM = 'patron/assistant/ADD_HISTORY_ITEM';
const UPDATE_HISTORY_ITEM = 'patron/assistant/UPDATE_HISTORY_ITEM';
const DISMISS_MESSAGE = 'patron/assistant/DISMISS_MESSAGE';
const ITEM_VIEWED = 'patron/assistant/ITEM_VIEWED';

export default {
  SET_ITEM,
  SET_COMMANDS,
  SET_INTEGRATIONS,
  ADD_HISTORY_ITEM,
  UPDATE_HISTORY_ITEM,
  DISMISS_MESSAGE,
  ITEM_VIEWED,
};
