import types from './types';

const setLocation = (location = null) => ({
  type: types.SET,
  location,
});

export default {
  setLocation,
};
