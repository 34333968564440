import api from '../config/api';
import store from '../config/store';

import { programMembershipSelectors } from '../models/program-membership';

class OnboardingService {
  getNextStep = () => {
    return api.get('/authorizations/onboarding/next_step', {
      params: {
        show_all: true,
      },
      baseURL: this.v1baseUrl,
    });
  };

  submitVerificationResponses = (data) => {
    return api.post('/authorizations/onboarding', data, {
      baseURL: this.v1baseUrl,
    });
  };

  submitWelcomeVideoWatched = () => {
    const state = store.getState();
    const membershipId = programMembershipSelectors.getProgramMembershipId(
      state,
      {}
    );

    return api.put(`/program_memberships/${membershipId}`, {
      welcome_video_watched: true,
    });
  };

  submitQuestionResponses = (data) => {
    return api.post('/responses', data);
  };

  get v1baseUrl() {
    return api.defaults.baseURL.replace('v2', 'v1');
  }
}

export default OnboardingService;
