import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAssistantActionHandlers from '../../common/use-assistant-action-handlers';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';
import { AssistantIntegration } from '../../models/assistant/selectors';
import { Spinner, Button } from '../ui';
import { Actionable } from '../../models/assistant/interfaces/Actionable';

const FEATURED_LINKS_TITLE_KEY = 'assistant.featured_links';
const MAX_LINKS_SHOWN = 5;

export interface ChannelDetailsLinksProps {
  channelId: string;
}

interface LinkBase {
  label: string;
}

interface ActionLink extends LinkBase {
  action: Actionable;
  url?: never;
}

interface UrlLink extends LinkBase {
  url: string;
  action?: never;
}

type Link = UrlLink | ActionLink;

const LinkTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="channel-details__links-list-title">
      {t(FEATURED_LINKS_TITLE_KEY)}
    </div>
  );
};

const LinkList: React.FC<{ links: Link[] | null }> = ({ links }) => {
  const { handleAction } = useAssistantActionHandlers();

  if (!links?.length) {
    return null;
  }

  return (
    <div className="channel-details__links">
      <LinkTitle />
      <dl className="channel-details__links-list">
        {links.map((link) => (
          <div
            className="channel-details__link"
            key={`${link.label}-${link.url ?? ''}`}
          >
            {link.action ? (
              <Button
                onClick={() => handleAction(link.action)}
                theme="link-color"
              >
                {link.label}
              </Button>
            ) : (
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.label}
              </a>
            )}
          </div>
        ))}
      </dl>
    </div>
  );
};

export const ChannelDetailsLinks: React.FC<ChannelDetailsLinksProps> = ({
  channelId,
}) => {
  const fetchedIntegrations = useAssistantFetcher({
    itemTypes: [AssistantDataType.INTEGRATIONS],
  });

  const linksToDisplay = useMemo(() => {
    if (fetchedIntegrations.isFetching) {
      return null;
    }

    const integrations: AssistantIntegration<{ channels?: string[] }>[] =
      fetchedIntegrations.assistantData?.integrations;

    if (fetchedIntegrations.isError || !integrations) {
      return null;
    }

    const linkIntegrationsForChannel = integrations.filter(
      (integration) =>
        integration.subject.integrationType === 'link' &&
        integration.subject.config?.channels?.includes?.(channelId)
    );

    const result: Link[] = [];

    for (const integration of linkIntegrationsForChannel) {
      result.push({
        label: integration.name,
        action: integration.subject.action,
      } as ActionLink);
      if (result.length >= MAX_LINKS_SHOWN) {
        break;
      }
    }

    return result;
  }, [
    fetchedIntegrations.isFetching,
    fetchedIntegrations.assistantData?.integrations,
    fetchedIntegrations.isError,
    channelId,
  ]);

  return (
    <Spinner loading={fetchedIntegrations.isFetching}>
      <LinkList links={linksToDisplay} />
    </Spinner>
  );
};
