import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import './content-card-translate-button.scss';

type ITranslateButton = {
  canTranslate: boolean;
  isTranslated: boolean;
  handleClick?: MouseEventHandler;
};

const ContentCardTranslateButton: React.FC<ITranslateButton> = ({
  handleClick,
  canTranslate = false,
  isTranslated = false,
}) => {
  const { t } = useTranslation();
  const translation_key = isTranslated
    ? 'content.see_original'
    : 'content.see_translation';

  return canTranslate ? (
    <button
      className={
        'content-card__translate-button pt-primary-color pt-secondary-color'
      }
      onClick={handleClick}
    >
      {t(translation_key)}
    </button>
  ) : null;
};

export default ContentCardTranslateButton;
