import React, { KeyboardEventHandler, useState } from 'react';
import Icon from './icon';
import cx from 'classnames';

const clickOnSpace: KeyboardEventHandler<HTMLElement> = (e) => {
  if (e.key === ' ') {
    // don't prevent default so it propagates to the label
    (e.target as HTMLElement)?.click();
  }
};
interface RadioButtonProps {
  name: string;
  checked: boolean;
  label: string;
  helpText?: string;
  error?: string;
  tabIndex?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}
export function RadioButton({
  name,
  checked,
  label,
  onChange,
  id,
  ...props
}: RadioButtonProps) {
  const [isFocused, setIsFocused] = useState<boolean>();

  //a note about the aria config here:
  //we rely on the <label> element surrounding everything, because the input will be hidden (translated) via css,
  //and browsers will interpret a click on the label as an input action.
  //screen readers dont like this, especially due to our use of material icons, which render the icon name as text in the browser.
  return (
    <label
      {...props}
      htmlFor={id}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={cx('radio-button', { 'is-selected': checked })}
      aria-label={label}
    >
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        value={label}
      />

      <Icon
        onKeyDown={clickOnSpace}
        className={cx('input', { 'is-focused': isFocused })}
        aria-hidden
        type={checked ? 'radio_button_checked' : 'radio_button_unchecked'}
      >
        {checked ? 'radio_button_checked' : 'radio_button_unchecked'}
      </Icon>

      <span className="label" aria-hidden>
        {label ? <span className="labelText">{label}</span> : null}
      </span>
    </label>
  );
}

/** @component */
export default RadioButton;
