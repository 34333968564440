import { forwardRef } from 'react';

import { Link } from '../ui';

import { trackChannelClick } from '../../models/channels/analytics';

const ChannelLink = forwardRef(
  ({ channelId, children, analyticsData, onClick }, ref) => {
    const handleClick = (e) => {
      trackChannelClick(channelId, analyticsData);

      onClick && onClick(e);
    };

    return (
      <Link
        href={`/channels/${channelId}`}
        action="push"
        className="channel__link"
        onClick={handleClick}
        ref={ref}
      >
        {children}
      </Link>
    );
  }
);

ChannelLink.displayName = 'ChannelLink';

export default ChannelLink;
