/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Notification,
  NotificationCenterTabs,
} from '../../models/notifications/types';
import { FilterTags } from './filter-dropdown';

type NotificationContextType = {
  tabs: [
    NotificationCenterTabs[],
    Dispatch<SetStateAction<NotificationCenterTabs[]>>
  ];
  activeTab: [
    NotificationCenterTabs,
    Dispatch<SetStateAction<NotificationCenterTabs>>
  ];
  filterTags: [FilterTags[], Dispatch<SetStateAction<FilterTags[]>>];
  multiSelectEnabled: [boolean, Dispatch<SetStateAction<boolean>>];
  multiSelectedItems: [
    Notification[],
    Dispatch<SetStateAction<Notification[]>>
  ];
  selectAll: [
    boolean | 'indeterminate',
    Dispatch<SetStateAction<boolean | 'indeterminate'>>
  ];
};

export const NotificationContext = createContext<NotificationContextType>({
  tabs: [[], () => {}],
  activeTab: [NotificationCenterTabs.MESSAGES, () => {}],
  filterTags: [[], () => {}],
  multiSelectEnabled: [false, () => {}],
  multiSelectedItems: [[], () => {}],
  selectAll: [false, () => {}],
});

export const NotificationContextProvider: React.FC = ({ children }) => {
  const [tabs, setTabs] = useState<NotificationCenterTabs[]>([
    NotificationCenterTabs.MESSAGES,
    NotificationCenterTabs.ACTIVITY,
  ]);
  const [activeTab, setActiveTab] = useState<NotificationCenterTabs>(
    NotificationCenterTabs.MESSAGES
  );
  const [filterTags, setFilterTags] = useState<FilterTags[]>([]);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(false);
  const [multiSelectedItems, setMultiSelectedItems] = useState<Notification[]>(
    []
  );
  const [selectAll, setSelectAll] = useState<boolean | 'indeterminate'>(false);

  //if filters change at all, reset multi select
  useEffect(() => {
    setMultiSelectedItems([]);
    setSelectAll(false);
  }, [filterTags]);

  return (
    <NotificationContext.Provider
      value={{
        tabs: [tabs, setTabs],
        activeTab: [activeTab, setActiveTab],
        filterTags: [filterTags, setFilterTags],
        multiSelectEnabled: [multiSelectEnabled, setMultiSelectEnabled],
        multiSelectedItems: [multiSelectedItems, setMultiSelectedItems],
        selectAll: [selectAll, setSelectAll],
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
