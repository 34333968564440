import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appConfigSelectors } from '../../models/app-config';
import { contentSelectors, contentOperations } from '../../models/content';

import { Button } from '../ui';

import ContentService from '../../services/content';

import './content.scss';
import './content-deeplink/content-deeplink.scss';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const ContentShare = ({ contentId, active }) => {
  const [shareUrls, setShareUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const shareWindow = useRef(null);
  const copyEl = useRef(null);
  const modalRef = useRef(null);
  const contentService = useRef(new ContentService(contentId));

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pushMessage } = usePushToastOrFlashMessage();

  const contentTitle = useSelector((state) =>
    encodeURIComponent(
      contentSelectors.getContentTitle(state, { contentId }) || ''
    )
  );
  const contentDescription = useSelector((state) =>
    encodeURIComponent(
      contentSelectors.getContentSummary(state, { contentId }) || ''
    )
  );
  const contentShareText = useSelector((state) =>
    encodeURIComponent(
      contentSelectors.getContentShareText(state, { contentId }) || ''
    )
  );
  const contentHashtag = useSelector((state) => {
    const tag = contentSelectors.getContentShareHashtag(state, { contentId });
    return tag && encodeURIComponent(tag);
  });
  const channelIds = useSelector((state) =>
    contentSelectors.getContentChannelIds(state, { contentId })
  );
  const facebookKey = useSelector((state) =>
    appConfigSelectors.getFacebookKey(state, { contentId })
  );

  const getContentUrl = (network) => {
    const url = shareUrls[network] || shareUrls.default;

    return encodeURIComponent(url);
  };

  useEffect(() => {
    if (active) {
      setContentUrl();
      setIsCopied(false);
    }
  }, [active]);

  const setContentUrl = () => {
    return Promise.resolve(contentService.current.fetchShareableUrl())
      .then((res) => ({
        x_social: res.data.trackable_links[0].uniquified_original_url,
        default: res.data.trackable_links[0].trackable_url,
      }))
      .then(
        (urls) =>
          new Promise((resolve) => {
            setShareUrls(urls);
            setIsLoading(false);
            resolve();
          })
      )
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  const handleCopyClick = (e) => {
    e.preventDefault();
    const input = copyEl.current;
    input.select();
    document.execCommand('copy');

    submitShareIntent('copy');

    const selection = document.getSelection();

    if (selection.removeAllRanges) {
      selection.removeAllRanges();
    } else if (selection.empty) {
      selection.empty();
    }

    input.blur();

    setIsCopied(true);
  };

  const handleFacebookClick = () => {
    shareAndCreateIntent('facebook');
  };

  const handleTwitterClick = () => {
    shareAndCreateIntent('x_social');
  };

  const handleLinkedInClick = () => {
    shareAndCreateIntent('linked_in');
  };

  const shareAndCreateIntent = (network) => {
    openShareWindow();
    updateShareWindowUrl(network);
    submitShareIntent(network);
  };

  const shareLabel = (service) => {
    return `${service} ${t('content.share')}`;
  };

  const openShareWindow = () => {
    const params =
      'menubar=no' +
      ', toolbar=no' +
      ', scrolling=yes' +
      ', scrollbars=yes' +
      ', width=600' +
      ', height=700' +
      ', left=' +
      (window.screen.width / 2 - 300) +
      ', top=' +
      (window.screen.height / 2 - 350);

    const _shareWindow = window.open('', '_blank', params);
    shareWindow.current = _shareWindow;

    const shareWindowEl = _shareWindow.document.createElement('div');
    shareWindowEl.style = 'padding-top: 320px; text-align: center;';
    shareWindowEl.appendChild(
      _shareWindow.document.createTextNode('Loading...')
    );

    _shareWindow.document.body.appendChild(shareWindowEl);
  };

  const getShareUrl = (network) => {
    if (network === 'facebook')
      return (
        'https://www.facebook.com/dialog/share' +
        `?app_id=${facebookKey}` +
        '&display=iframe' +
        `&href=${getContentUrl('facebook')}` +
        (contentHashtag ? `&hashtag=%23${contentHashtag}` : '') +
        (contentShareText ? `&quote=${contentShareText}` : '')
      );

    if (network === 'x_social')
      return (
        'https://x.com/intent/tweet' +
        `?url=${getContentUrl('x_social')}` +
        `&text=${contentShareText}` +
        (contentHashtag ? `&hashtags=${contentHashtag}` : '')
      );

    if (network === 'linked_in')
      return (
        'https://www.linkedin.com/shareArticle' +
        '?mini=true' +
        `&text=${contentShareText}` +
        `&url=${getContentUrl('linked_in')}` +
        `&title=${contentTitle}` +
        `&summary=${contentDescription}`
      );
  };

  const updateShareWindowUrl = (network) => {
    shareWindow.current.location = getShareUrl(network);
  };

  const submitShareIntent = (network) => {
    dispatch(
      contentOperations.shareContent({
        id: contentId,
        url: decodeURIComponent(getContentUrl(network)),
        network,
        channelId: channelIds[0],
      })
    );

    pushMessage({
      text: t('flash_messages.thank_you_for_sharing'),
      type: 'success',
    });
  };

  useEffect(() => {
    if (active && modalRef.current) {
      let elem = modalRef.current.getBoundingClientRect();
      if (elem.top < 260) {
        //this is the height of the navbar and the height of the header modal
        let style = modalRef.current.style;
        style.bottom = `-200px`;
      }
    }
  }, [active]);

  if (!active) return null;

  return (
    <div className="content-share" ref={modalRef}>
      <div className="content-share__title">{t('common.share')}</div>
      {isLoading ? (
        <>
          <p className="content-share__item">Loading...</p>
        </>
      ) : error ? (
        <p className="content-share__item">
          Unable to fetch sharing URLs. Please try again later.
        </p>
      ) : (
        <ul className="content-share__items">
          <li className="content-share__item">
            <Button
              onClick={handleFacebookClick}
              aria-label={shareLabel('Facebook')}
            >
              <img
                src="/images/icons/social-icon-facebook.svg"
                alt="Facebook"
              />
              {t('social_networks.facebook')}
            </Button>
          </li>
          <li className="content-share__item">
            <Button onClick={handleTwitterClick} aria-label={shareLabel('X')}>
              <img src="/images/icons/social-icon-x.svg" alt="X" />
              {t('social_networks.x_social')}
            </Button>
          </li>
          <li className="content-share__item">
            <Button
              onClick={handleLinkedInClick}
              aria-label={shareLabel('LinkedIn')}
            >
              <img
                src="/images/icons/social-icon-linkedin.svg"
                alt="LinkedIn"
              />
              {t('social_networks.linkedin')}
            </Button>
          </li>
          <li className="content-share__item">
            <div className="content-deeplink">
              <input
                type="text"
                value={shareUrls.default || 'fetching...'}
                readOnly
                className="content-deeplink__input"
                ref={copyEl}
              />

              <button
                className="content-deeplink__button pt-secondary-color"
                onClick={handleCopyClick}
              >
                {isCopied ? t('common.copied') : t('common.copy')}
              </button>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ContentShare;
