import Spinner from '../../../../ui/spinner';
import { useRecoilState } from 'recoil';
import { aboutPageByChannelId } from '../../../../../models/about-page/recoil-state';

import './about-page-preview.scss';

const AboutPagePreview = ({ channelId }: { channelId: string }) => {
  const [aboutPage] = useRecoilState(aboutPageByChannelId(channelId));

  return !aboutPage?.previewContent ? (
    <Spinner />
  ) : (
    <p className="topic-page-about-preview">{aboutPage?.previewContent}</p>
  );
};

export default AboutPagePreview;
