// Search result provided by eva and pythia for clients other than mobile
// Interfaces copied from src/api/pythia/enterprise_search.ts in Eva
import { Actionable } from './Actionable';

export enum PaginationType {
  PAGE_NUMBER = 'page_number',
  PAGE_TOKEN = 'page_token',
}

export interface SearchResult {
  id: string;
  title?: string;
  subtitle?: string;
  snippet?: string;
  icon?: string;
  image?: string;
  action: Actionable;
}

export interface SearchResultResponse {
  type: 'result';
  query: string;
  total?: number;
  page?: number;
  per_page: number;
  results: SearchResult[];
  next_page_token?: string;
  pagination_type: PaginationType;
}

export interface SearchAuthResponse {
  type: 'auth';
  message: string;
  label: string;
  key: string; // callback key passed in message sent by login window
  url: string; // login url for web or canvas command deeplink for mobile
}

export interface SearchErrorResponse {
  type: 'error';
  message: string;
}

export type SearchResponse =
  | SearchResultResponse
  | SearchAuthResponse
  | SearchErrorResponse;

export interface SearchProvider {
  integration_id: number;
  command: string;
  label: string;
  description: string;
}
