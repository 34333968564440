import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducers';
import { trackAction } from '../models/analytics/helpers';

const middlewares = applyMiddleware(thunk, trackAction);
const enhancers = compose(
  ...[
    middlewares,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  ].filter((e) => e) // filter out falsey enhancers
);

export const setupStore = (preloadedState) => {
  return createStore(reducer, preloadedState, enhancers);
};

const store = setupStore();

export default store;
