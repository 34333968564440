import cx from 'classnames';
import { useState } from 'react';
import { CommentAssistantSuggestionSubtype } from '../../../../models/comments/types';
import { Button, Icon } from '../../../ui';
import { CommentAssistantMainMenu } from './base/main-menu';
import { CommentAssistantMenuItem } from './base/menu-item';
import { CommentAssistantSubmenuContent } from './base/sub-menu';
import { SuggestionSelectedCallback } from './types';

type CommentAssistantMobileMenuProps = {
  className?: string;
  onClose?: () => void;
  onSuggestionSelected: SuggestionSelectedCallback;
};

export function CommentAssistantMobileMenu({
  className,
  onSuggestionSelected,
}: CommentAssistantMobileMenuProps) {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const onSubmenuSelected = (name: CommentAssistantSuggestionSubtype) => {
    onSuggestionSelected({
      suggestionType: 'change_tone',
      suggestionSubtype: name,
    });
  };

  return (
    <div className={cx('comment-assistant-mobile', className)}>
      {!isSubmenuOpen && (
        <CommentAssistantMainMenu onSuggestionSelected={onSuggestionSelected}>
          <CommentAssistantMenuItem
            name="change_tone"
            icon="mood"
            expandable={true}
            onItemSelected={() => setIsSubmenuOpen(true)}
          />
        </CommentAssistantMainMenu>
      )}
      {isSubmenuOpen && (
        <>
          <Button
            className="comment-assistant-menu__back-button"
            onClick={() => setIsSubmenuOpen(false)}
          >
            <Icon type="chevron_left" /> back
          </Button>
          <CommentAssistantSubmenuContent onSelected={onSubmenuSelected} />
        </>
      )}
    </div>
  );
}
