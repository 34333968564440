import i18n from '../../config/i18n';
import store from '../../config/store';
import { waitFor } from '../../lib/wait-for';
import { isInMSTeams } from '../../lib/teams-helper';

export const track = (name, traits = {}) => {
  const state = store.getState();
  const user_id = state.programMembership && state.programMembership.id;

  traits = {
    ...traits,
    locale: i18n.language,
    user_id,
  };

  if (isInMSTeams) {
    traits.platform = 'ms_teams';
  }

  if (!window.analytics) {
    waitFor(
      () => Object.prototype.hasOwnProperty.call(window, 'analytics'),
      2000
    )
      .then(() => {
        window.analytics.ready(() => {
          window.analytics.track(name, traits);
        });
      })
      .catch(() => {
        console.error(`Could not track analytics event: ${name} `, traits);
      });
    return;
  }

  window.analytics.ready(() => {
    window.analytics.track(name, traits);
  });
};

export const trackAction = (store) => (next) => (action) => {
  if (!action.meta || !action.meta.analytics) return next(action);

  const { name, traits } = action.meta.analytics;

  track(name, traits);

  return next(action);
};

export const trackable = (action, name, traits = {}) => {
  const analytics = { name, traits };

  return name
    ? {
        ...action,
        meta: { analytics },
      }
    : action;
};
