import { useRef, ReactEventHandler, RefObject } from 'react';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import CommentAuthorAvatar from './comment-author-avatar';
import CommentAuthorName from './comment-author-name';
import CommentActionLike from './comment-action-like';
import CommentActionReply from './comment-action-reply';
import CommentActionMore from './comment-action-more';
import CommentReplies from './comment-replies';
import { Icon } from '../ui';

import { timeAgoOrDate } from '../../lib/date-formatter';

import { trackCommentEvent } from '../../models/comments/analytics';
import { Comment, CommentWithReplies } from '../../models/comments/types';
import { CommentContext } from './types';

type CommentListItemProps = {
  comment: Comment | CommentWithReplies;
  context: CommentContext;
  readOnly?: boolean;
  onReply?: (comment: Comment, ref: RefObject<HTMLDivElement>) => void;
  onEdit?: (comment: Comment) => void;
  onDelete?: (comment: Comment) => void;
  onReport?: (comment: Comment) => void;
  onReplies?: (comment: CommentWithReplies) => void;
  onHighlight?: (comment: Comment) => void;
};

const CommentListItem = ({
  comment,
  context,
  readOnly,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
}: CommentListItemProps) => {
  const el = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const isActive = comment.id === context.activeCommentId;

  const formattedTime = timeAgoOrDate(comment.createdAt);

  const className = cx('comment-list-item comment', {
    'comment--active': isActive,
  });

  const handleAuthorClick = () => {
    trackCommentEvent('avatarClick', comment, !!comment.replyToId);
  };

  const hideImage: ReactEventHandler = (e) => {
    if (e.target instanceof HTMLImageElement) {
      e.target.style.display = 'none';
    }
  };

  // dynamicly override discourse-created comment structure
  const parseOpts: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.type === 'tag' &&
        domNode.name === 'img' &&
        domNode.attribs.class === 'preview-image-display'
      ) {
        return (
          <img
            onError={hideImage}
            className="preview-image-display"
            src={domNode.attribs.src}
            alt=""
          />
        );
      }
    },
  };

  return (
    <>
      <div className={className} ref={el}>
        <div className="comment__avatar">
          <CommentAuthorAvatar
            author={comment.author}
            onClick={handleAuthorClick}
          />
        </div>

        <div className="comment__body">
          <div className="comment__header">
            {comment.highlightedAt ? <Icon type="star">star</Icon> : null}

            <CommentAuthorName author={comment.author} />

            <div className="time-stamp">{formattedTime}</div>

            {!readOnly ? (
              <CommentActionMore
                comment={comment}
                onEdit={onEdit}
                onDelete={onDelete}
                onReport={onReport}
                onHighlight={onHighlight}
              />
            ) : null}
          </div>

          <div className="comment__text">
            {parse(comment.content, parseOpts)}
            {comment.isEdited ? (
              <small>{t('comments.state.edited')}</small>
            ) : null}
          </div>

          <div className="comment__actions">
            {!readOnly ? <CommentActionLike comment={comment} /> : null}
            {!readOnly && !comment.replyToId ? (
              <>
                {' '}
                •{' '}
                <CommentActionReply
                  comment={comment}
                  onClick={(comment: Comment) => onReply?.(comment, el)}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>

      {!readOnly && comment.replyCount ? (
        <CommentReplies
          comment={comment}
          context={context}
          onEdit={onEdit}
          onDelete={onDelete}
          onReport={onReport}
          onReplies={onReplies}
        />
      ) : null}
    </>
  );
};

export default CommentListItem;
