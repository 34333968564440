import { useMemo } from 'react';

export const mentionRegex = /@\{ref:user::id:(\d+)::name:([^}]+)\}/g;

interface UsePlainTextMentionsProps {
  text: string;
  disabled?: boolean;
}

export const usePlainTextMentions = ({
  text,
  disabled = false,
}: UsePlainTextMentionsProps): string => {
  return useMemo(() => {
    if (disabled) {
      return text; // If disabled, return the text as-is without transformation
    }

    return text.replace(mentionRegex, (match, userId, userName) => {
      return `@${userName}`; // Replace each mention with just the username
    });
  }, [text, disabled]); // Include disabled in the dependency array
};
