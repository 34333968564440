import { Container } from '@socialchorus/shared-ui-components';
import React from 'react';
import ShortListContainer from './short-list-container';
import styles from './documents-short-list.module.scss';
import { SearchType } from '../search-screen';
import { DocumentSearchResponse } from '../documents/documents';
import { useHistory } from 'react-router';
import { trackSearchPreviewResultClick } from '../../../../models/analytics';

interface DocumentsShortListProps {
  documentsData: DocumentSearchResponse[];
  error: boolean;
  viewAllUrl: string;
  searchType: SearchType;
}

const DocumentsShortList: React.FC<DocumentsShortListProps> = ({
  error,
  viewAllUrl,
  documentsData,
  searchType,
}) => {
  const history = useHistory();
  const documentsShortList =
    documentsData[0]?.data?.length > 0
      ? documentsData[0]?.data?.slice(0, 6)
      : [];

  const handleClick = (contentId: number) => {
    trackSearchPreviewResultClick({ result_type: searchType });
    history.push(`/contents/${contentId}`);
  };

  return (
    <ShortListContainer
      error={error}
      viewAllUrl={viewAllUrl}
      dataType={searchType}
      dataCount={documentsShortList.length}
    >
      {documentsShortList.map((document) => (
        <Container
          tag="button"
          shape="medium"
          className={[styles.Container, styles['Container--hoverable']]}
          key={document.id}
          onClick={() => handleClick(document.attributes.content_id)}
        >
          <div
            id={`document-filename-${document.id}`}
            className={`${styles.Name} ${styles.Text}`}
          >
            {document.attributes.attachment_name ||
              document.attributes.filename}
          </div>
          <span className={`${styles.Type} ${styles.Text}`}>
            {document.attributes.filetype.toUpperCase()}
          </span>
        </Container>
      ))}
    </ShortListContainer>
  );
};

export default DocumentsShortList;
