import { useEffect, useMemo, useRef } from 'react';
import { DraftDecorator } from 'draft-js';
import { uniqueId } from 'lodash';
import TagPluginStore from './TagPluginStore';
import { DecoratorComponentProps } from './types';
import { trackTagSearchType } from '../../../../models/content-submission/analytics/analytics-typed';

function TagTrigger({
  store,
  blockKey,
  start,
  end,
  children,
}: DecoratorComponentProps) {
  const triggerId = useMemo(() => uniqueId('tag-trigger-'), []);
  const ref = useRef<HTMLSpanElement>(null);
  // Register trigger when it is created and remove it when it is unmounted
  useEffect(() => {
    store.registerTrigger(triggerId, blockKey, start, end, ref);
    if (store.trackingContext) {
      trackTagSearchType({
        content_section: store.trackingContext.contentSection,
      });
    }
    return () => {
      store.unregisterTrigger(triggerId);
    };
  }, [triggerId]);
  // Update trigger when content changed
  useEffect(() => {
    store.updateTrigger(triggerId, blockKey, start, end, ref);
  }, [blockKey, start, end]);

  return <span ref={ref}>{children}</span>;
}

function createTriggerDecorator(store: TagPluginStore): DraftDecorator {
  return {
    strategy(block, callback) {
      const contentBlockText = block.getText();
      block.findEntityRanges(
        (character) => !character.getEntity(),
        (textStart, textEnd) => {
          const regex = /(^|\s+)@\w[\w\s\W]*/g;
          const text = contentBlockText.slice(textStart, textEnd);
          let matchArr, start;
          while ((matchArr = regex.exec(text)) !== null) {
            const atPosition = matchArr[0].indexOf('@');
            start = matchArr.index;
            callback(
              textStart + start + atPosition,
              textStart + start + matchArr[0].length
            );
          }
        }
      );
    },
    component: (props: DecoratorComponentProps) => (
      <TagTrigger {...props} store={store} />
    ),
  };
}

export default createTriggerDecorator;
