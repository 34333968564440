import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useContentFeed from '../../../../../common/use-content-feed';
import { Spinner } from '../../../../ui';
import { CardProps } from '../../sidebar';
import { CardLink, CardPlaceholder, PreviewCard } from '../common';
import { PostPreview } from './post-preview';

const PREVIEW_COUNT = 3;

const PostsPreview: FC<CardProps> = ({ topicId }: { topicId: string }) => {
  const { t } = useTranslation();

  const { fetching, feedItems } = useContentFeed({
    channelId: topicId,
    perPage: PREVIEW_COUNT,
    pinned: true,
  });

  return (
    <PreviewCard
      title={t('screens.topic_page.sidebar.posts_preview.title')}
      link={
        <CardLink
          label={t('screens.topic_page.sidebar.posts_preview.view_all')}
          to={`/channels/${topicId}/posts`}
        />
      }
    >
      {fetching ? (
        <Spinner />
      ) : feedItems.length === 0 ? (
        <CardPlaceholder>
          {t('screens.topic_page.no_posts.description')}
        </CardPlaceholder>
      ) : (
        feedItems.map((content) => (
          <PostPreview key={content.id} content={content} />
        ))
      )}
    </PreviewCard>
  );
};

export default PostsPreview;
