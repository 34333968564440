import { connect } from 'react-redux';

import { contentSelectors } from '../../models/content/index.js';
import { FeaturedIcon } from '../../components/ui';
import useProgramTheme from '../../common/use-program-theme';

const ContentTags = ({ tags, isProcessing }) => {
  const { accentColor } = useProgramTheme();

  const contentTagData = [...tags];
  if (isProcessing) contentTagData.unshift({ buttonText: 'Processing' });

  return (
    <ul className="content-tags">
      {contentTagData.map((tag, idx) => {
        return (
          <li style={{ color: accentColor }} className="content-tag" key={idx}>
            <FeaturedIcon color={accentColor} />
            {tag.buttonText}
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state, ownProps) => ({
  tags: contentSelectors.getContentTags(state, ownProps),
  isProcessing: contentSelectors.getContentIsProcessing(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentTags);
