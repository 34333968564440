import { useRef, useState } from 'react';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useEscapeToClose from '../../common/use-escape-to-close';
import { Avatar, Dialog, Modal } from '../ui';
import AvatarFormContainer from './avatar-form-container';
import styles from './avatar-form-dialog.module.scss';

const uploadSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138"
    height="116"
    viewBox="0 0 138 116"
    focusable="false"
  >
    <path
      fill="#DDDEDF"
      fillRule="nonzero"
      d="M131.1 0H6.9A6.905 6.905 0 0 0 0 6.905v102.19A6.905 6.905 0 0 0 6.9 116h124.2c3.809
         0 6.9-3.093 6.9-6.905V6.905A6.905 6.905 0 0 0 131.1 0zM41.4 22.095c7.618 0 13.8
         6.187 13.8 13.81s-6.182 13.81-13.8 13.81-13.8-6.187-13.8-13.81 6.182-13.81
         13.8-13.81zM22.08 88.381s4.306-28.503 17.609-28.503c11.316 0 15.18 20.88 26.385
         4.198 12.53-18.67 21.694-42.423 49.846 24.305H22.08z"
    />
  </svg>
);

const errorSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="127"
    height="127"
    viewBox="0 0 127 127"
    focusable="false"
  >
    <path
      fill="#DDDEDF"
      fillRule="nonzero"
      d="M63.5 0C28.499 0 0 28.499 0 63.5 0 98.501 28.499 127 63.5 127c35.001 0 63.5-28.499
         63.5-63.5C127 28.499 98.501 0 63.5 0zm26.111 84.582l-5.029 5.03L63.5 68.528 42.418
         89.611l-5.03-5.029L58.472 63.5 37.389 42.418l5.029-5.03L63.5 58.472l21.082-21.082
         5.03 5.029L68.528 63.5l21.082 21.082z"
    />
  </svg>
);

const HAS_NO_IMAGE = 0;
const HAS_PREV_IMG_UPLOAD = 1;
const IS_EDITING_IMG_UPLOAD = 2;

const EditUploadedImagePanel = ({
  avatarUrl,
  onCancel,
  onChange,
  onRemove,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const modal = useRef();
  useEscapeToClose(modal, onCancel);

  const imgStyle = { height: '14px' };

  if (isSubmitting) {
    imgStyle.visibility = 'hidden';
  }

  return (
    <Modal className={cx(styles.avatarForm, 'dialog')} ref={modal}>
      <div className="dialog__header dialog--avatar-form-section">
        <h2 className="dialog__title">
          {t('screens.profile.edit_profile.upload_your_photo')}
        </h2>
        <button
          style={{ border: 'none', position: 'relative', left: '1rem' }}
          onClick={onCancel}
        >
          <img src="/images/icons/cross-icon.svg" alt="Cross" />
        </button>
      </div>
      <div className="dialog__body">
        <Avatar src={avatarUrl} alt="Avatar preview" />
      </div>
      <div className="dialog__footer dialog--avatar-form-section">
        <button
          style={isSubmitting ? { backgroundColor: 'gray' } : null}
          className={cx('dialog--avatar-form-button', {
            'button--disabled': isSubmitting,
            'button--loading': isSubmitting,
          })}
          onClick={onRemove}
        >
          <img
            src="/images/icons/trashcan-icon.svg"
            alt="Trashcan"
            style={imgStyle}
          />
          <span>Remove</span>
        </button>
        <button
          className="button--primary dialog--avatar-form-button"
          onClick={onChange}
        >
          <img
            src="/images/icons/pencil-icon.svg"
            alt="Pencil"
            style={{ height: '13px' }}
          />
          <span>Change</span>
        </button>
      </div>
    </Modal>
  );
};

const AddImagePanel = ({
  file,
  close,
  isFocused,
  isRejected,
  isSubmitting,
  onFocus,
  onBlur,
  onDropAccepted,
  onDropRejected,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className={styles.avatarForm}
      title={t('screens.profile.edit_profile.upload_your_photo')}
      accept={{
        text: t('common.done'),
        icon: 'check',
        loading: isSubmitting,
        disabled: !file || isSubmitting,
        onClick: onSubmit,
      }}
      cancel={{
        text: t('common.cancel'),
        disabled: isSubmitting,
        onClick: close,
      }}
      close={close}
    >
      <Dropzone
        className={cx(styles.dropzone, { 'is-focused': isFocused })}
        onFocus={onFocus}
        onBlur={onBlur}
        accept="image/jpeg, image/png"
        maxSize={10485760} // 10mb
        multiple={false}
        acceptClassName={styles.dropzoneAccepted}
        rejectClassName={styles.dropzoneRejected}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
          <div
            {...getRootProps({
              onClick: (e) => isSubmitting && e.stopPropagation(),
            })}
            className={cx(styles.dropzone, {
              [styles.dropzoneAccepted]: isDragAccept,
              [styles.dropzoneRejected]: isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <div className={styles.dropzone__container}>
              {file ? (
                <Avatar
                  className={styles.dropzone__container__avatar}
                  src={file.preview}
                  alt="Avatar preview"
                />
              ) : (
                <>
                  {isRejected ? errorSvg : uploadSvg}
                  <label className="text">
                    {t('content_submission.field_image_placeholder')}
                  </label>
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>

      {isRejected && (
        <div
          className={cx(
            styles.avatarForm__text,
            styles.avatarForm__textRejected
          )}
          role="alert"
        >
          {t('content_submission.field_image_error')}
        </div>
      )}
    </Dialog>
  );
};

const AvatarFormDialog = ({ close, avatarUrl }) => {
  const [mode, setMode] = useState(
    avatarUrl ? HAS_PREV_IMG_UPLOAD : HAS_NO_IMAGE
  );

  return (
    <AvatarFormContainer close={close}>
      {({
        file,
        isFocused,
        isRejected,
        isSubmitting,
        onFocus,
        onBlur,
        onDropAccepted,
        onDropRejected,
        onSubmit,
        onDelete,
      }) => {
        let containerContents;

        switch (mode) {
          case HAS_PREV_IMG_UPLOAD:
            containerContents = (
              <EditUploadedImagePanel
                avatarUrl={avatarUrl}
                onChange={() => setMode(IS_EDITING_IMG_UPLOAD)}
                onRemove={() => onDelete()}
                isSubmitting={isSubmitting}
                onCancel={() => close()}
              />
            );
            break;
          case HAS_NO_IMAGE:
          case IS_EDITING_IMG_UPLOAD:
            containerContents = (
              <AddImagePanel
                file={file}
                close={close}
                isFocused={isFocused}
                isRejected={isRejected}
                isSubmitting={isSubmitting}
                onFocus={onFocus}
                onBlur={onBlur}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                onSubmit={onSubmit}
              />
            );
            break;
        }

        return containerContents;
      }}
    </AvatarFormContainer>
  );
};

export const ID = 'Overlays.AVATAR_FORM_DIALOG';

export default AvatarFormDialog;
