import { useState } from 'react';
import cx from 'classnames';
import { Icon, Button } from '../ui';
import './list-picker.scss';

const ListPickerItem = ({ item, isActive, onClick }) => {
  const className = cx('list-picker__item', {
    'list-picker__item--active': isActive,
  });

  return (
    <li className={className}>
      <Button
        transparent
        size="block"
        aria-label={item.text}
        onClick={() => {
          onClick(item);
        }}
      >
        {item.icon && <Icon type={item.icon} />}
        {item.iconComponent ? item.iconComponent : null}
        <span className="text">{item.text}</span>
      </Button>
    </li>
  );
};

const ListPicker = ({ items, activeItem: initActiveItem, onChange }) => {
  const [activeItem, setActiveItem] = useState(initActiveItem);

  const handleClick = (item) => {
    setActiveItem(item);

    if (onChange) onChange(item);
  };

  return (
    <div className="list-picker">
      <ul className="list-picker__list">
        {items.map((item, idx) => (
          <ListPickerItem
            item={item}
            isActive={activeItem && item.id === activeItem.id}
            onClick={handleClick}
            key={idx}
          />
        ))}
      </ul>
    </div>
  );
};

export default ListPicker;
