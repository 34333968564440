import { ComponentPropsWithoutRef, MouseEventHandler } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  submissionCanEditChannels,
  submissionChannels,
  submissionIsEdit,
  submissionIsSubmitting,
} from '../../models/content-submission/atoms';

import { Icon } from '../../components/ui';
import { useSelector } from 'react-redux';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { RootPatronState } from '../../common/use-patron-selector';

type ChannelsSelectedProps = ComponentPropsWithoutRef<'button'> & {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ChannelsSelected = ({
  onClick,
  ...buttonProps
}: ChannelsSelectedProps) => {
  const selectedChannels = useRecoilValue(submissionChannels);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const canEditChannels = useRecoilValue(submissionCanEditChannels);

  const { t } = useTranslation();

  const className = cx('content-submission-channels-selected', {
    'content-submission-channels-selected--disabled':
      isSubmitting || (isEdit && !canEditChannels),
  });

  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const publishToChannelString = newTopicsString
    ? 'content_submission.publish_to_topic'
    : 'content_submission.publish_to_channel';

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={isSubmitting || (isEdit && !canEditChannels)}
      tabIndex={0}
      aria-label={t(publishToChannelString)}
      aria-describedby="content-submission-channels--selected-channel-list"
      {...buttonProps}
      type="button"
    >
      <span id="content-submission-channels--selected-channel-list" hidden>
        {selectedChannels.map((channel) => channel.name).join(',')}
      </span>
      <span className="content-submission-channels-selected__channel">
        {selectedChannels.length
          ? selectedChannels[0].name
          : t(publishToChannelString)}
      </span>

      {selectedChannels.length > 1 ? (
        <span className="content-submission-channels-selected__count">
          +{selectedChannels.length - 1}
        </span>
      ) : null}

      <Icon type="add" />
    </button>
  );
};

export default ChannelsSelected;
