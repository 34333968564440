import { useState, useEffect } from 'react';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import AssistantService from '../services/assistant';
import { Card } from '../models/assistant/interfaces/subject/Summary';
import { AxiosResponse } from 'axios';

const useTodosFetcher = (todoId: string | undefined) => {
  const [todos, setTodos] = useState<AssistantResponse<Card>[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);

  function fetchTodos(id?: string) {
    setIsFetching(true);
    setIsError(false);

    const service = new AssistantService();

    if (id) {
      return service
        .fetchTodo(id)
        .then(handleFetchTodoSuccess, handleFetchhandleFetchTodoError);
    } else {
      return service
        .fetchTodos({ page: page + 1 })
        .then(handleFetchTodosSuccess, handleFetchhandleFetchTodoError);
    }
  }

  const handleFetchTodosSuccess = (res: AxiosResponse) => {
    setTodos([...todos, ...res.data.data]);
    setPage(res.data.meta.page.number);
    setCanLoadMore(res.data.meta.page.number < res.data.meta.page.total);
    setIsError(false);
    setIsFetching(false);
  };

  const handleFetchTodoSuccess = (res: AxiosResponse) => {
    setTodos([res.data.data]);
    setPage(1);
    setCanLoadMore(false);
    setIsError(false);
    setIsFetching(false);
  };

  const handleFetchhandleFetchTodoError = () => {
    setIsError(true);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchTodos(todoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todoId]);

  return {
    todos,
    fetchTodos,
    isFetching,
    isError,
    canLoadMore,
  };
};

export default useTodosFetcher;
