import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { contentSelectors } from '../../../models/content/index.js';
import { advocateSelectors } from '../../../models/advocate';

import ContentChannels from '../content-channels';
import ContentStats from '../content-stats';
import ContentDeeplink from '../content-deeplink';

import './content-more.scss';
import { Feature, getFeatureFlag } from '../../../models/features/features';

const ContentMore = ({
  contentId,
  advocateId,
  contentAuthorId,
  permalinkUrl,
  isPublished,
  analyticsData,
}) => {
  const { t } = useTranslation();

  const canViewStats = isPublished && contentAuthorId === advocateId;

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const channelsString = newTopicsString
    ? 'content.topics'
    : 'content.channels';
  return (
    <div className="content-more">
      <>
        <p className="section-title section-title--small">
          {t(channelsString)}
        </p>
        <ContentChannels contentId={contentId} analyticsData={analyticsData} />
      </>

      {canViewStats ? (
        <>
          <p className="section-title section-title--small">
            {t('content.stats')}
          </p>
          <ContentStats contentId={contentId} analyticsData={analyticsData} />
        </>
      ) : null}

      <label
        htmlFor="content-deeplink__input"
        className="section-title section-title--small"
      >
        {t('content.copy_link')}
      </label>
      <ContentDeeplink contentId={contentId} analyticsData={analyticsData} />
    </div>
  );
};

const mapsStateToProps = (state, ownProps) => ({
  advocateId: advocateSelectors.getAdvocateId(state, ownProps),
  contentAuthorId: contentSelectors.getContentAuthorId(state, ownProps),
  permalinkUrl: contentSelectors.getContentPermalinkUrl(state, ownProps),
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
});

export default connect(mapsStateToProps, null)(ContentMore);
