import { combineReducers } from 'redux';

import advocate from '../models/advocate';
import appConfig from '../models/app-config';
import assistant from '../models/assistant';
import boxIntegration from '../models/box-integration';
import contents from '../models/content';
import contentSubmission from '../models/content-submission';
import channels from '../models/channels';
import {
  featureFlagReducer,
  FeaturesKeyspace,
} from '../models/features/features';
import location from '../models/location';
import program from '../models/program';
import programMembership from '../models/program-membership';
import ui from '../models/ui';

export default combineReducers({
  advocate,
  appConfig,
  assistant: assistant as any,
  boxIntegration,
  channels,
  contents,
  contentSubmission,
  [FeaturesKeyspace]: featureFlagReducer,
  location,
  program,
  programMembership,
  ui,
});
