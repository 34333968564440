import {
  Button,
  Icon,
  IconButton,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { trackContentCardEdit } from '../../../../../../models/content/analytics';
import { trackEditClick } from '../../../../../../models/content-submission/analytics';
import { FeedCardActionsProps } from './type';

export function FeedCardActionEdit({
  content,
  analyticsData,
  isHidden,
}: FeedCardActionsProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    const editRoute = `/contents/${content.id}/edit`;
    history.push(editRoute);

    trackContentCardEdit(content.id, analyticsData);
    trackEditClick({ content_id: content.id });
  };

  const label = t('common.edit');

  if (isHidden) {
    return (
      <Button
        variant="text"
        label={label}
        leftIcon={<Icon size={20}>edit</Icon>}
        onClick={handleClick}
      />
    );
  }

  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <IconButton
          aria-label={t('content.edit_action', { title: content.title })}
          iconName="edit"
          size="compact"
          onClick={handleClick}
        />
      </TooltipTrigger>
      <TooltipContent description={label} />
    </TooltipRoot>
  );
}
