import {
  FeedCardGrid,
  FeedCardGridItem,
} from '@socialchorus/shared-ui-components';
import { stringify, useSearchQueryParams, SearchParams } from '../query-params';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../skip-to-content/skip-to-content';
import { NoResultsFound } from '../no-results-found';
import { Program } from '../../../../models/program/types';
import { ResourceObject } from '../../../../lib/types/json-api';
import type { Content } from '../../../../models/content/types';
import { useProgram } from '../../../../common/use-program';
import { InfiniteSearchResults, useInfiniteSearch } from '../infinite-search';
import { VisuallyHidden } from '../../visually-hidden';
import { useLocalizedScreenReaderLoadingMessage } from '../locale';
import { contentOperations } from '../../../../models/content';
import { useDispatch } from 'react-redux';
import { FeedCardLoading } from './feed-card-loading/feed-card-loading';
import { createCacheKey, optimus, parseCacheKey } from '../http';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { useTranslation } from 'react-i18next';
import { FeedCard } from '../../topic-page-v2/tabs/PostsTab/feed-card';
import React, { useEffect } from 'react';
import styles from './content.module.scss';
import { CompleteError } from '../overview/overview';
import { trackSearchContentCardView } from '../../../../models/analytics';
import { SearchResultState } from '../search-screen';
import { trackContentCardClick } from '../../../../models/content/analytics';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';

type ContentSearchResponse = {
  data: ResourceObject<'content', Content>[];
  meta: {
    page: number;
    perPage: number;
    totalPages: number;
    totalResults: number;
  };
};

export async function searchContent(
  programId: Program['id'],
  params: SearchParams
) {
  const query = stringify(params);
  return optimus
    .get<ContentSearchResponse>(
      `/v1/programs/${programId}/search/content?${query}`
    )
    .then((response) => response.data);
}
interface ContentProps {
  searchReturnedResults?: (searchResultState: SearchResultState) => void;
}
export const contentCacheKey = createCacheKey('posts');

export function useContentSearch(searchParams: SearchParams) {
  const programId = useProgram().id;
  const dispatch = useDispatch();
  const {
    data: contentSearchResponse,
    isLoading,
    size,
    mutate: retryContent,
    ...rest
  } = useInfiniteSearch(
    (page) => [
      contentCacheKey({
        ...searchParams,
        page: page + 1,
      }),
    ],
    async ([cacheKey]) => {
      return searchContent(programId, parseCacheKey(cacheKey).params);
    }
  );

  // Populate the Redux store with the content data. This is necessary because
  // we use Redux to store translations, reaction counts, and other state across
  // the app.
  // SWR onSuccess hook doesn't work here because it is not guaranteed to fire
  // with deduped requests.
  useEffect(() => {
    if (!contentSearchResponse) {
      return;
    }

    const contentsAttributes = contentSearchResponse
      ?.map((content) => content.data.map((c) => c.attributes))
      .flat();
    if (contentsAttributes) {
      dispatch(contentOperations.addContents(contentsAttributes));
    }
  }, [contentSearchResponse, dispatch]);

  const isLoadingMore = Boolean(
    isLoading ||
      (size > 0 &&
        contentSearchResponse &&
        typeof contentSearchResponse[size - 1] === 'undefined')
  );
  const isEmpty = contentSearchResponse?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (contentSearchResponse &&
        contentSearchResponse[contentSearchResponse.length - 1]?.data.length <
          Number(searchParams?.perPage))
  );

  return {
    contentData: contentSearchResponse,
    isLoadingMore,
    isReachingEnd,
    size,
    retryContent,
    ...rest,
  };
}

export function ContentSearch({ searchReturnedResults }: ContentProps) {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const analyticsData = {} as IAnalyticsData; // TODO: We might not be doing this through passing props - need a plan
  const { t } = useTranslation();
  const {
    contentData,
    setSize,
    size,
    error,
    isLoadingMore,
    isValidating,
    isReachingEnd,
    retryContent,
  } = useContentSearch(query);

  const contentsInReduxStore = usePatronSelector((s) => s.contents);
  const srOnlyLoadingMessage = useLocalizedScreenReaderLoadingMessage('posts');
  if (searchReturnedResults && !contentData) {
    searchReturnedResults(SearchResultState.Loading);
  }
  const retryContentSearch = () => {
    retryContent();
  };
  if (searchReturnedResults && contentData !== undefined && !error) {
    searchReturnedResults(SearchResultState.HasResults);
  }

  useEffect(() => {
    if (contentData !== undefined && contentData[0].data.length > 0) {
      trackSearchContentCardView();
    }
  }, [contentData]);

  if (contentData !== undefined && contentData[0].data.length > 0) {
    return (
      <ContentContainer>
        <InfiniteSearchResults
          isLoadingMore={isLoadingMore || isValidating}
          isReachingEnd={isReachingEnd}
          onViewTrigger={() => setSize(size + 1)}
          errorFetching={Boolean(error)}
          handleRetry={retryContentSearch}
        >
          <FeedCardGrid columns={1}>
            {contentData?.map((content) =>
              content.data.map((c) => {
                // FeedCard expects content to be in Redux store, so we skip rendering
                // if it's not there. This isn't ideal and we probably waste some
                // render cycles, but it's a dirty fix to avoid creating a new
                // component that's decoupled from Redux.
                const hasContentInStore = contentsInReduxStore[c.attributes.id];
                if (!hasContentInStore) {
                  return null;
                }
                return (
                  <FeedCardGridItem key={c.id}>
                    <FeedCard
                      content={c.attributes}
                      analyticsData={analyticsData}
                      onClick={() => trackContentCardClick(c.id, analyticsData)}
                    />
                  </FeedCardGridItem>
                );
              })
            )}
          </FeedCardGrid>
        </InfiniteSearchResults>
      </ContentContainer>
    );
  }

  if (contentData !== undefined && contentData[0].data.length === 0) {
    return (
      <ContentContainer>
        <NoResultsFound
          title={t('search.no_results_type.title', {
            type: t(`search.types.posts`),
          })}
          description={t('search.no_results_type.description')}
        />
      </ContentContainer>
    );
  }

  if (error) {
    return (
      <CompleteError
        query={query.query ? query.query : ''}
        handleRetry={retryContentSearch}
      />
    );
  }

  return (
    <ContentContainer>
      <VisuallyHidden id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {srOnlyLoadingMessage}
      </VisuallyHidden>
      <FeedCardGrid>
        <FeedCardLoading />
        <FeedCardLoading />
        <FeedCardLoading />
        <FeedCardLoading />
      </FeedCardGrid>
    </ContentContainer>
  );
}

function ContentContainer({ children }: { children: React.ReactNode }) {
  return <section className={styles.ContentScreen}>{children}</section>;
}
