import { ComponentProps, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommentAssistantSuggestionSubtype,
  CommentAssistantSuggestionType,
} from '../../../../../models/comments/types';
import { Button, Icon } from '../../../../ui';

type CommentAssistantMenuItemProps<
  T extends CommentAssistantSuggestionType | CommentAssistantSuggestionSubtype
> = {
  name: T;
  expandable?: boolean;
  icon?: ComponentProps<typeof Icon>['type'];
  onItemSelected?: (name: T) => void;
  ref?: ForwardedRef<HTMLButtonElement>;
};

export function CommentAssistantMenuItem<
  T extends CommentAssistantSuggestionType | CommentAssistantSuggestionSubtype
>({
  name,
  expandable = false,
  icon,
  onItemSelected,
}: CommentAssistantMenuItemProps<T>) {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => onItemSelected?.(name)}
      className="comment-assistant-menu__list-item"
    >
      <div className="comment-assistant-menu__list-item-content">
        <div className="comment-assistant-menu__list-item-left">
          {icon ? (
            <div className="comment-assistant-menu__list-item-icon">
              <Icon type={icon} />
            </div>
          ) : null}

          <div className="comment-assistant-menu__list-item-name">
            {t(`comments.comment_assistant.menus.${name}`)}
          </div>
        </div>
        {expandable ? (
          <div className="comment-assistant-menu__list-item-right">
            <Icon type="chevron_right">chevron_right</Icon>
          </div>
        ) : null}
      </div>
    </Button>
  );
}
