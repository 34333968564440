import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/advocate/types';
import AdvocateService from '../../services/advocate';
import MentionPickerList from './MentionPickerList';
import './mention-picker.scss';

type MentionPickerProps = {
  matchString: string;
  onClick: (mention: string) => void;
};

const suggestion = new AdvocateService();

export default function MentionPicker({
  matchString,
  onClick,
}: MentionPickerProps) {
  const { t } = useTranslation();

  const [, setIsFetching] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>();
  const [users, setUsers] = useState<Array<User>>();

  const onSuccess = (
    resp: Awaited<ReturnType<AdvocateService['getSuggestion']>>
  ) => {
    const users = resp.data.users;
    setUsers(users);
    setCursor(users.length - 1);
  };

  const fetchMentions = useCallback((mention: string) => {
    setIsFetching(true);
    suggestion
      .getSuggestion(mention)
      .then(onSuccess)
      .finally(() => setIsFetching(false));
  }, []);

  const resetMentions = () => {
    setUsers(undefined);
  };

  const fetchOrReset = useCallback(
    (mention: string) => {
      mention = mention.replace('@', '');

      if (!mention) {
        resetMentions();
      } else {
        fetchMentions(mention);
      }
    },
    [fetchMentions]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const usersState = users || [];
      const cursorState = cursor || 0;
      if (e.key === 'Tab' || e.key === 'Enter') {
        // Tab or Enter
        onClick('@' + usersState[cursorState].display_name);
        e.preventDefault();
      } else if (e.key === 'ArrowUp') {
        // Up
        setCursor(Math.max(cursorState - 1, 0));
        e.preventDefault();
      } else if (e.key === 'ArrowDown') {
        // Down
        setCursor(Math.min(cursorState + 1, usersState.length - 1));
        e.preventDefault();
      }
    },
    [cursor, onClick, users]
  );

  useEffect(() => {
    fetchOrReset(matchString);
  }, [fetchOrReset, matchString]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const shouldShowMentions = matchString.length !== 1;

  return (
    <div className="mention-picker">
      {shouldShowMentions ? (
        users ? (
          <MentionPickerList
            users={users}
            selected={cursor}
            onClick={onClick}
          />
        ) : null
      ) : (
        <div className="title">{t('comments.mentions.lookup')}</div>
      )}
    </div>
  );
}
