import React from 'react';
import { connect } from 'react-redux';

import { contentSelectors } from '../../../models/content/index.js';

import { Link } from '../../ui';

import './content-channels.scss';

const ContentChannels = ({ channels }) => (
  <ul className="content-channels">
    {channels.map(({ id, name }) => (
      <li className="content-channel" key={id}>
        <Link href={`/channels/${id}`} action="push">
          {name}
        </Link>
      </li>
    ))}
  </ul>
);

const mapsStateToProps = (state, ownProps) => ({
  channels: contentSelectors.getContentChannels(state, ownProps),
});

export default connect(mapsStateToProps, null)(ContentChannels);
