import { useProgram } from '../../../../common/use-program';
import { ResourceObject } from '../../../../lib/types/json-api';
import { ProgramMember } from '../../../../models/program-membership/types';
import { Avatar, Container } from '@socialchorus/shared-ui-components';
import styles from './people.module.scss';
import { Link } from 'react-router-dom';
import { stringify, useSearchQueryParams, SearchParams } from '../query-params';
import { Program } from '../../../../models/program/types';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../skip-to-content/skip-to-content';
import { LoadingSkeleton } from '../../loading-skeleton';
import { useLocalizedScreenReaderLoadingMessage } from '../locale';
import { VisuallyHidden } from '../../visually-hidden';
import { InfiniteSearchResults, useInfiniteSearch } from '../infinite-search';
import { NoResultsFound } from '../no-results-found';
import { createCacheKey, optimus, parseCacheKey } from '../http';
import { useTranslation } from 'react-i18next';
import { CompleteError } from '../overview/overview';
import {
  trackProfileClick,
  trackProfileView,
} from '../../../../models/analytics';
import { useEffect } from 'react';
import { SearchResultState } from '../search-screen';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';
import { Filter } from '../filter-bar/filters/filters';
import { useFilters } from '../filter-context';

export type PeopleSearchResponse = {
  data: ResourceObject<'user', ProgramMember>[];
  meta: {
    page: number;
    perPage: number;
    totalPages: number;
    totalResults: number;
  };
};

export async function searchPeople(
  programId: Program['id'],
  params: SearchParams
) {
  const query = stringify(params);
  return optimus
    .get<PeopleSearchResponse>(
      `/v1/programs/${programId}/search/people?${query}`
    )
    .then((response) => response.data);
}

export const peopleCacheKey = createCacheKey('people');

export function usePeopleSearch(searchParams: SearchParams) {
  const programId = useProgram().id;
  const {
    data: peopleSearchResponse,
    isLoading,
    size,
    ...rest
  } = useInfiniteSearch(
    (page) => [
      peopleCacheKey({
        ...searchParams,
        page: page + 1,
      }),
    ],
    ([cacheKey]) => {
      return searchPeople(programId, parseCacheKey(cacheKey).params);
    }
  );

  const isLoadingMore = Boolean(
    isLoading ||
      (size > 0 &&
        peopleSearchResponse &&
        typeof peopleSearchResponse[size - 1] === 'undefined')
  );
  const isEmpty = peopleSearchResponse?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (peopleSearchResponse &&
        peopleSearchResponse[peopleSearchResponse.length - 1]?.data.length <
          Number(searchParams?.perPage))
  );

  return {
    peopleData: peopleSearchResponse,
    isLoadingMore,
    isReachingEnd,
    size,
    ...rest,
  };
}

function PersonCard({ person }: { person: ProgramMember }) {
  const elementId = `person-${person['user-id']}`;

  return (
    <Container
      aria-labelledby={elementId}
      shape="medium"
      shadow="light"
      tag={Link}
      to={`/profiles/${person['user-id']}`}
      className={styles.PersonCardHoverable}
      onClick={() => {
        trackProfileClick();
      }}
    >
      <Avatar
        size="large"
        alt={person.name}
        imgSrc={person['avatar-url'] ?? undefined}
        shape="circle"
      />
      <div className={styles.PersonNameContainer}>
        <h2 className={styles.PersonName} id={elementId}>
          {person.name}
        </h2>
      </div>
      <div className={styles.RoleContainer}>
        <div className={styles.PersonRole}>{person['job-title']}</div>
      </div>
    </Container>
  );
}

export function PeopleSearch() {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const { t } = useTranslation();
  const srOnlyLoadingMessage = useLocalizedScreenReaderLoadingMessage('people');

  const {
    peopleData,
    setSize,
    size,
    error,
    isLoadingMore,
    isValidating,
    isReachingEnd,
    mutate: retry,
  } = usePeopleSearch(query);

  function retryPeopleSearch() {
    retry();
  }

  useEffect(() => {
    if (peopleData !== undefined && peopleData[0].data.length > 0) {
      trackProfileView();
    }
  }, [peopleData]);

  if (peopleData !== undefined && peopleData[0].data.length > 0) {
    return (
      <>
        <InfiniteSearchResults
          isLoadingMore={isLoadingMore || isValidating}
          isReachingEnd={isReachingEnd}
          onViewTrigger={() => setSize(size + 1)}
          errorFetching={Boolean(error)}
          handleRetry={retryPeopleSearch}
        >
          <ul
            className={styles.PeopleGrid}
            id={DEFAULT_SKIP_TO_CONTENT_ID}
            aria-busy="false"
          >
            {peopleData?.map((people) => {
              return people.data.map((p) => (
                <li key={p.attributes['user-id']}>
                  <PersonCard person={p.attributes} />
                </li>
              ));
            })}
          </ul>
        </InfiniteSearchResults>
      </>
    );
  }

  if (peopleData !== undefined && peopleData[0].data.length === 0) {
    return (
      <NoResultsFound
        title={t('search.no_results_type.title', {
          type: t(`search.types.people`),
        })}
        description={t('search.no_results_type.description')}
      />
    );
  }

  if (error) {
    return (
      <CompleteError
        query={query.query ? query.query : ''}
        handleRetry={retryPeopleSearch}
      />
    );
  }

  return (
    <>
      <VisuallyHidden id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {srOnlyLoadingMessage}
      </VisuallyHidden>
      <ul className={styles.PeopleGrid} aria-busy aria-live="polite">
        {Array.from({ length: 24 }).map((_, index) => (
          <li key={index}>
            <PeopleLoadingSkeleton />
          </li>
        ))}
      </ul>
    </>
  );
}

function PeopleLoadingSkeleton() {
  return (
    <Container
      aria-label="Loading person"
      shape="small"
      tag="li"
      className={styles.PersonCard}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingSkeleton
          width="80px"
          height="80px"
          borderRadius="50%"
          marginTop="0"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <LoadingSkeleton width="120px" height="20px" marginTop="0" />
        <LoadingSkeleton width="100px" height="15px" marginTop="0" />
      </div>
    </Container>
  );
}
