import * as types from './keys';
import { track } from '../../analytics/helpers';
import store from '../../../config/store';

export const boxKmTraits = (assignTraits) => {
  const state = store.getState();

  const advocate = state.advocate;

  return {
    advocate_id: advocate.id,
    ...assignTraits,
  };
};

export const trackBoxKmExplorerOpen = (traits) => {
  track(types.BOX_KM_EXPLORER_OPEN, boxKmTraits(traits));
};

export const trackBoxKmPreviewerOpen = (traits) => {
  track(types.BOX_KM_PREVIEW_OPEN, boxKmTraits(traits));
};
