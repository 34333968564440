import { useState, useEffect } from 'react';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import AssistantService from '../services/assistant';
import { Card } from '../models/assistant/interfaces/subject/Summary';

function usePollsFetcher() {
  const [polls, setPolls] = useState<Array<AssistantResponse<Card>>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);

  async function fetchPolls() {
    setIsFetching(true);
    setIsError(false);

    const service = new AssistantService();

    try {
      const res = await service.fetchAnsweredPolls({ page: page + 1 });

      setPolls([...polls, ...res.data.data]);
      setPage(res.data.meta.page.number);
      setCanLoadMore(res.data.meta.page.number < res.data.meta.page.total);
      setIsError(false);
      setIsFetching(false);
    } catch (e) {
      setIsError(true);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    polls,
    fetchPolls,
    isFetching,
    isError,
    canLoadMore,
  };
}

export default usePollsFetcher;
