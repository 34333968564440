import React, {
  ComponentProps,
  CSSProperties,
  ForwardedRef,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react';
import cx from 'classnames';
import { Link } from '../ui';
import { Actionable } from '../../models/assistant/interfaces/Actionable';
import useAssistantActionHandlers from '../../common/use-assistant-action-handlers';
import {
  ActionNavigationType,
  Navigation,
} from '../../models/assistant/interfaces/Navigation';
import useAssistantCommand from '../../common/use-assistant-command';
import { AssistantLink } from './assistant-link';
import { usePrependProgramPath } from '../../common/use-prepend-program-path';

export enum NavigationReferer {
  EXPLORER = 'explorer',
  INBOX = 'inbox',
}

interface NavigationDeeplinkProps
  extends Pick<ComponentProps<'a'>, 'onKeyPress' | 'onClick' | 'className'> {
  navigation: Navigation;
  pushState?: unknown;
}

const NavigationDeeplink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<NavigationDeeplinkProps>
>(
  (
    { navigation, children, pushState, onClick, onKeyPress, className },
    ref
  ) => {
    const { getDeeplink } = useAssistantActionHandlers();
    const deeplink = getDeeplink(navigation, pushState);
    const deeplinkHref = usePrependProgramPath(
      deeplink.to?.pathname ?? deeplink.url
    );

    return deeplink.isPush ? (
      <AssistantLink
        to={deeplink.to ?? deeplink.url}
        onKeyPress={onKeyPress}
        onClick={onClick}
        ref={ref}
        className={className}
      >
        {children}
      </AssistantLink>
    ) : (
      <Link
        href={deeplinkHref}
        onKeyPress={onKeyPress}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </Link>
    );
  }
);

NavigationDeeplink.displayName = 'NavigationDeeplink';

type ActionNavigationProps<PS = unknown, AD = unknown> = ComponentProps<'a'> & {
  navigation: Navigation;
  analyticsData?: AD;
  pushState?: PS;
  children?: ReactNode;
  className?: string;
  onKeyPress?: (e: React.KeyboardEvent) => void;
};

export const ActionNavigation = React.forwardRef<
  HTMLAnchorElement,
  ActionNavigationProps
>(({ navigation, analyticsData, ...props }, ref) => {
  switch (navigation?.type) {
    case ActionNavigationType.DEEPLINK:
      return (
        <NavigationDeeplink navigation={navigation} {...props} ref={ref} />
      );
    case ActionNavigationType.EXTERNAL:
      return (
        <Link href={navigation.url} target="_blank" {...props} ref={ref} />
      );
    case ActionNavigationType.INTERNAL:
      return (
        <AssistantLink
          to={{
            pathname: '/assistant/commands/internal',
            state: { renderUrl: navigation.url, analyticsData },
          }}
          {...props}
          ref={ref}
        />
      );
    default:
      return null;
  }
});

ActionNavigation.displayName = 'ActionNavigation';

interface ActionRequestProps {
  action: Actionable;
  analyticsData?: unknown;
  onClick: MouseEventHandler;
  asCommand: boolean;
  actionTitle: string;
  props?: unknown;
  className: string;
}
const ActionRequest = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ActionRequestProps>
>(
  (
    { action, analyticsData, onClick, asCommand, actionTitle, ...props },
    ref
  ) => {
    const { handleRequest } = useAssistantActionHandlers();
    const { runCommandInAssistant } = useAssistantCommand();

    const handleRequestClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      asCommand
        ? runCommandInAssistant(
            { button_text: actionTitle, action },
            analyticsData
          )
        : handleRequest(action.request);
      onClick && onClick(e);
    };

    return (
      <button ref={ref} onClick={handleRequestClick} {...props}>
        {props.children}
      </button>
    );
  }
);

ActionRequest.displayName = 'ActionRequest';

interface AssistantActionProps {
  action: Actionable;
  style?: CSSProperties;
  className?: string;
  asCommand?: boolean;
  actionTitle?: string;
  analyticsData?: unknown;
  pushState?: unknown;
  props?: unknown;
  onClick: MouseEventHandler;
}
export const AssistantAction = React.forwardRef<
  HTMLElement,
  React.PropsWithChildren<AssistantActionProps>
>(({ action, className, asCommand, actionTitle, ...props }, ref) => {
  const classNames = cx(className, 'assistant__action');
  return action.type === 'navigation' ? (
    <ActionNavigation
      className={cx(classNames, 'assistant__action-navigation')}
      navigation={action.navigation}
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      onKeyPress={(e) => {
        if (
          [' ', 'Spacebar'].includes(e.key) &&
          e.target instanceof HTMLElement
        ) {
          e.target?.click();
        }
      }}
      {...props}
    />
  ) : (
    <ActionRequest
      className={cx(classNames, 'assistant__action-request')}
      action={action}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      asCommand={asCommand!}
      actionTitle={actionTitle!}
      {...props}
    />
  );
});

AssistantAction.displayName = 'AssistantAction';

export default AssistantAction;
