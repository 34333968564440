import { BigIconButton } from '@socialchorus/shared-ui-components';
import { useLocalDateTime } from '../locale';
import { useTranslation } from 'react-i18next';
import { ContentAttachment } from '../../../../models/content/types';
import { useHistory } from 'react-router';
import { trackDocumentClick } from '../../../../models/analytics';

type DocumentProps = Pick<
  ContentAttachment,
  'filename' | 'filetype' | 'created_at' | 'content_id' | 'attachment_name'
>;

export function DocumentCard({
  document: { filename, created_at, filetype, content_id, attachment_name },
}: {
  document: DocumentProps;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    trackDocumentClick();
    history.push(`/contents/${content_id}`);
  };

  const imageSrc = `/images/icons/attachments/${
    filetype === 'pdf'
      ? 'pdf-file.svg'
      : filetype === 'doc'
      ? 'doc-file.svg'
      : filetype === 'xls'
      ? 'xls-file.svg'
      : filetype === 'ppt'
      ? 'ppt-file.svg'
      : filetype === 'ics'
      ? 'ics-file.svg'
      : '404.svg'
  }`;

  return (
    <BigIconButton
      label={attachment_name || filename}
      size="large"
      imgSrc={imageSrc}
      onClick={handleClick}
      secondaryLabels={[
        t([`search.documents.${filetype}`, 'search.documents.unknown']),
        useLocalDateTime(created_at),
      ]}
    />
  );
}
