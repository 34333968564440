import api from '../config/api';

class ProgramMembershipService {
  constructor(id) {
    this.id = id;
  }

  fetch = () => {
    return api.get(`/program_memberships`);
  };

  update = (data) => {
    return api.put(`/program_memberships/${this.id}`, data);
  };
}

export default ProgramMembershipService;
