import ProfileCard from '../../components/profile-card';
import './profile-list.scss';

const ProfileList = ({ profiles, analyticsData }) => {
  return (
    <div className="profile-list">
      {profiles.map((profile) => (
        <ProfileCard
          analyticsData={analyticsData}
          profile={profile}
          key={profile.user_id}
        />
      ))}
    </div>
  );
};

export default ProfileList;
