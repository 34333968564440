import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentOperations, contentSelectors } from '../../../models/content';
import { Button, Icon } from '../../ui';

interface AcknowledgementFooterProps {
  contentId: number;
}

export const AcknowledgementFooter: React.FC<AcknowledgementFooterProps> = ({
  contentId,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const prereqsCompleted = usePatronSelector(
    (state) => !!contentSelectors.getContentIsCompleted(state, { contentId })
  );
  const acknowledgement = usePatronSelector((state) =>
    contentSelectors.getContentAcknowledgement(state, { contentId })
  );

  const acked = !!acknowledgement?.acknowledged_at;

  const handleClick = () => {
    dispatch(contentOperations.acknowledgeContent(contentId));
  };

  const ackedButtonText = acked
    ? acknowledgement.acked_label
    : acknowledgement.label;

  return (
    <div className="acknowledgement-footer">
      <div className="acknowledgement-bar">
        <div className="acknowledgement-bar__message">
          <Icon type="emoji_objects" size="3xl" />
          <div>{t('content.acknowledgement')}</div>
        </div>
        <div className="acknowledgement-bar__action">
          <Button
            theme="primary"
            icon={acked ? 'done' : undefined}
            onClick={handleClick}
            disabled={!prereqsCompleted || acked}
            aria-label={ackedButtonText}
          >
            {ackedButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
