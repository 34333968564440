import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon } from '../ui';

interface AssistantBackBarProps {
  backText?: string;
}

const AssistantBackBar: React.FC<AssistantBackBarProps> = ({ backText }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    if (history.length <= 1) {
      history.push('/assistant');
      return;
    }

    history.goBack();
  };

  const buttonText = backText || t('common.back');

  return (
    <button
      className="assistant__nav-bar"
      onClick={handleOnClick}
      aria-label={buttonText}
    >
      <Icon type="arrow_back" />
      <span>{buttonText}</span>
    </button>
  );
};

export default AssistantBackBar;
