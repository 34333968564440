import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './channel-details.scss';
import { getChannelById } from '../../../../models/channels/selectors';
import { Feature, getFeatureFlag } from '../../../../models/features/features';
import { localizeDate } from '../../../../lib/date-formatter';
import ChannelImage from '../../../channel/channel-image';
import ChannelTitle from '../../../channel/channel-title';
import { ChannelDescription } from '../../../channel';
import { ChannelDetailsLinks } from '../channel-links/channel-links';
import { Spinner } from '../../../ui';
import ChannelStats from '../channel-stats/channel-stats';
import ChannelContributorsSection from '../channel-contributors/channel-contributors-section';
import ChannelFollowButton from '../channel-follow-button/channel-follow-button';
import ChannelMembersList from '../channel-members-list/channel-members-list';
import { RootPatronState } from '../../../../common/use-patron-selector';
import useChannelDetails from '../../../../common/channel/use-channel-details';

type ChannelDetailsProps = {
  channelId: string;
  analyticsData: {
    content_channel_id: string;
    content_channel_name: string;
    location: string;
  };
};

export const ChannelDetails = ({
  channelId,
  analyticsData,
}: ChannelDetailsProps) => {
  const channel = useSelector((state: RootPatronState) =>
    getChannelById(state, channelId)
  );
  const channelLinksEnabled = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.EXPERIMENTAL_TARGETED_CHANNEL_LINKS)
  );

  const { fetching, channelStats, channelContributors, channelFollowers } =
    useChannelDetails(channelId);

  const classes = 'channel-details-v2';

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <header className="channel-details-v2__header">
        <ChannelImage channel={channel} analyticsData={analyticsData} />

        <ChannelTitle
          channel={channel}
          analyticsData={analyticsData}
          titleTag="h1"
        />

        <ChannelFollowButton
          channelId={channelId}
          analyticsData={analyticsData}
        />
      </header>

      <hr className={'channel-details-v2__divider'} />

      <div className="channel-details-v2__about">
        <ChannelDescription channel={channel} />

        <div className="channel-details-v2__created-at">
          {t('common.published')} {localizeDate(channel?.created_at)}
        </div>
      </div>

      <hr className={'channel-details-v2__divider'} />

      {channelLinksEnabled ? (
        <>
          <ChannelDetailsLinks
            channelId={channelId}
            analyticsData={analyticsData}
          />
          <hr className={'channel-details-v2__divider'} />
        </>
      ) : null}

      <Spinner loading={fetching}>
        <ChannelStats
          postsCount={channelStats?.postsCount}
          postsPerWeek={channelStats?.postsPerWeek}
          lastUpdated={channelStats?.lastUpdated}
        />

        <hr className={'channel-details-v2__divider'} />

        {channelContributors.length ? (
          <ChannelContributorsSection
            channelContributors={channelContributors}
          />
        ) : null}

        <hr className={'channel-details-v2__divider'} />

        {channelFollowers.length ? (
          <ChannelMembersList
            followerCount={channel?.follower_count}
            channelFollowers={channelFollowers}
          />
        ) : null}
      </Spinner>
    </div>
  );
};

export default ChannelDetails;
