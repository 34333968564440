import types from './types';

const setProgram = (program) => ({
  type: types.SET,
  program,
});

export default {
  setProgram,
};
