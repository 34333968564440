import { createApiClient } from '../config/base-api';
import { Feature } from '../models/features/features';
const client = createApiClient();

type BooleanFeatureFlags = Omit<typeof Feature, 'INBOX_MAINTENANCE'>;
export type FeatureFlagFetchResponse = {
  [K in BooleanFeatureFlags[keyof BooleanFeatureFlags]]: boolean;
} & {
  [Feature.INBOX_MAINTENANCE]: string;
};

export class FeatureFlagsService {
  constructor(private readonly programId: string) {}

  public async fetch() {
    return (
      await client.get<FeatureFlagFetchResponse>(`/${this.programId}/features`)
    ).data;
  }
}
