import React, { ComponentProps } from 'react';
import {
  Button,
  Checkbox,
  CheckboxElementProps,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Icon,
  TextInput,
} from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../filter-bar.module.scss';

/* CheckboxFilter is a wrapper for the popover from shared-ui-components.
 * It should act as a container for the CheckboxFilterContent and CheckboxFilterTrigger.
 */
export function CheckboxFilter({
  children,
  open,
  onOpenChange,
}: { children: React.ReactNode } & Pick<
  ComponentProps<typeof DropdownMenu>,
  'open' | 'onOpenChange'
>) {
  return (
    <DropdownMenu open={open} onOpenChange={onOpenChange}>
      {children}
    </DropdownMenu>
  );
}

/* CheckboxFilterTrigger is a wrapper for the popover trigger from shared-ui-components.
 * It is the element that toggles the popover open and closed.
 * Should be used as the child of CheckboxFilter.
 */
export const CheckboxFilterTrigger = DropdownMenuTrigger;

type CheckboxFilterContentProps = {
  handleClear: () => void;
  children: React.ReactNode;
} & (
  | {
      variant?: 'selectable';
    }
  | {
      variant: 'searchable';
      handleInputChange: (e: string) => void;
      value: string;
    }
);

/* CheckboxFilterContent is a wrapper for the popover content from shared-ui-components.
 * It applies the correct styles for the filter bar popover panel.
 * Should be used as the child of CheckboxFilter.
 */
export function CheckboxFilterContent(props: CheckboxFilterContentProps) {
  const { children, handleClear, variant } = props;
  const isSearchable = variant === 'searchable';
  const { clear } = useLocalizedButtonLabels();
  return (
    <DropdownMenuContent
      className={classNames(
        styles.FilterBar__allFiltersDropdown,
        styles['FilterBar__allFiltersDropdown--spacing'],
        styles['FilterBar__allFiltersDropdown--maxHeight']
      )}
      align="start"
      sideOffset={15}
    >
      {isSearchable && (
        <header className={styles.CheckboxFilter__header}>
          <TextInput
            placeholder="Search filters"
            type="search"
            size="compact"
            fullWidth
            rightWidget={<Icon size={18}>search</Icon>}
            onChange={props.handleInputChange}
            value={props.value}
            className={styles.CheckboxFilter__search}
          />
        </header>
      )}
      <div className={styles.CheckboxFilter__content}>{children}</div>
      <footer className={styles.CheckboxFilter__footer}>
        <div className={styles.CheckboxFilter__footerButtons}>
          <Button variant="text" label={clear} onClick={handleClear} />
        </div>
      </footer>
    </DropdownMenuContent>
  );
}

/* CheckboxFilterItem is a wrapper for the checkbox from shared-ui-components.
 * It applies the correct styles for the filter bar checkbox items.
 */
export const CheckboxFilterItem = React.forwardRef<
  HTMLDivElement,
  CheckboxElementProps
>((props, ref) => {
  return (
    <div ref={ref} className={styles.CheckboxFilter__item}>
      <Checkbox {...props} />
    </div>
  );
});
CheckboxFilterItem.displayName = 'CheckboxFilterItem';

function useLocalizedButtonLabels() {
  const { t } = useTranslation();
  return {
    clear: t('common.clear'),
    done: t('common.done'),
  };
}
