import api from '../config/api';
import {
  AdvocateChannels,
  AdvocateOrganizationResponse,
  AdvocateProfileEditDataResponse,
  AdvocateProfileResponse,
  AdvocateSuggestionsSearchResponse,
  ProfilesSearchResponse,
  UpdateAdvocateAvatar,
  UpdateAdvocatePasswordResponse,
  UpdateAdvocateProfilePayload,
  UpdateProfileResponse,
} from '../models/advocate/types';

class AdvocateService {
  id?: number;

  constructor(id?: number) {
    this.id = id;
  }

  fetch = () => {
    return api.get<AdvocateProfileResponse>(`/profiles/${this.id}`, {
      baseURL: this.v3baseUrl,
    });
  };

  getSuggestion = (query: string) => {
    return api.get<AdvocateSuggestionsSearchResponse>(`/users/suggestions`, {
      params: {
        q: query,
      },
    });
  };

  fetchChannels = () => {
    return api.get<AdvocateChannels>(`/profiles/${this.id}/content_channels`);
  };

  // unused
  fetchSubmitted = () => {
    return api.get(`/profiles/${this.id}/contents/submitted`, {
      params: { width: 768 },
    });
  };

  fetchProfileEditData = () => {
    return api.get<AdvocateProfileEditDataResponse>(
      `/profiles/${this.id}/edit`,
      {
        baseURL: this.v3baseUrl,
      }
    );
  };

  fetchProfileOrganization = ({ page = 1 }) => {
    return api.get<AdvocateOrganizationResponse>(
      `/profiles/${this.id}/organization?page=${page}`,
      {
        baseURL: this.v3baseUrl,
      }
    );
  };

  // used in unused hook use-history-fetcher.js
  fetchSearchHistory = (category: string) => {
    return api.get(`/users/${this.id}/search_history`, {
      baseURL: this.v3baseUrl,
      params: {
        category,
        page_size: 10,
      },
    });
  };

  search = (term: string, page: number) => {
    return api.get<ProfilesSearchResponse>(`/profiles/search`, {
      params: {
        q: term,
        page,
      },
    });
  };

  update = (user: Partial<UpdateAdvocateProfilePayload>) => {
    const data = { user };
    return api.put<UpdateProfileResponse>(`/profiles/${this.id}`, data, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      baseURL: this.v3baseUrl,
    });
  };

  updatePassword = (oldPassword: string, password: string) => {
    return api.put<UpdateAdvocatePasswordResponse>(
      `/authentications/advocates/${this.id}/update_password`,
      {
        old_password: oldPassword,
        password,
      },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
      }
    );
  };

  updateAvatar = (data: UpdateAdvocateAvatar) => {
    return api.post<UpdateAdvocateAvatar>(`/profiles/${this.id}/avatar`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  deleteAvatar = () => {
    return api.put<UpdateProfileResponse>(
      `/profiles/${this.id}`,
      { user: { avatar_url: '' } },
      {
        baseURL: this.v3baseUrl,
      }
    );
  };

  get v3baseUrl() {
    return api?.defaults?.baseURL?.replace('v2', 'v3');
  }
}

export default AdvocateService;
