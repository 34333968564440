import { ComponentProps } from 'react';
import { Spinner } from '../ui';
import ViewTrigger from '../view-trigger';
import CommentList from './comment-list';

type CommentsMainProps = ComponentProps<typeof CommentList> & {
  hasNextPage?: boolean;
  isFetching: boolean;
  onNextPage: (reset?: boolean) => void;
};

const CommentsMain = ({
  comments,
  context,
  hasNextPage,
  isFetching,
  onNextPage,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
}: CommentsMainProps) => {
  return (
    <div className="comments__main">
      {!isFetching || comments.length ? (
        <>
          <CommentList
            comments={comments}
            context={context}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
            onReport={onReport}
            onReplies={onReplies}
            onHighlight={onHighlight}
          />

          {hasNextPage ? (
            !isFetching && context.action !== 'reply' ? (
              <ViewTrigger onInview={() => onNextPage()} />
            ) : (
              <Spinner />
            )
          ) : null}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CommentsMain;
