import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink as ReactNavLink, RouteComponentProps } from 'react-router-dom';

import { Spinner } from '../../components/ui';
import {
  AssistantAction,
  AssistantCommandSelector,
} from '../../components/assistant';
import ViewTrigger from '../../components/view-trigger';

import useAssistantCommand from '../../common/use-assistant-command';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';
import { assistantSelectors } from '../../models/assistant';
import { programSelectors } from '../../models/program';
import {
  trackAssistantExploreLoad,
  trackAssistantQuickActionClick,
  trackAssistantActionsClick,
  trackAssistantActionsView,
} from '../../models/assistant/analytics';
import AssistantTitle from './assistant-title';
import { RootPatronState } from '../../common/use-patron-selector';
import { Button } from '../../models/assistant/interfaces/Button';
import { AssistantIntegration } from '../../models/assistant/selectors';
import { AssistantLink } from '../../components/assistant/assistant-link';

type OwnProps = RouteComponentProps;

type StateProps = {
  integrations: ReturnType<typeof assistantSelectors.getIntegrations>;
  quickActions: ReturnType<typeof assistantSelectors.getQuickActions>;
  labels: ReturnType<typeof assistantSelectors.getLabels>;
  programThemeIconUrl: ReturnType<typeof programSelectors.getProgramThemeIcon>;
};

type AssistantExploreProps = StateProps & OwnProps;

function AssistantExplore({
  integrations,
  quickActions,
  programThemeIconUrl,
  labels,
}: AssistantExploreProps) {
  const { t } = useTranslation();
  const buttons =
    quickActions && quickActions[0] && quickActions[0].subject.buttons;
  const { isFetching } = useAssistantFetcher({
    itemTypes: [
      AssistantDataType.QUICK_ACTIONS,
      AssistantDataType.INTEGRATIONS,
    ],
  });
  const { runCommandInAssistant } = useAssistantCommand();

  useEffect(() => {
    trackAssistantExploreLoad();
  }, []);

  const onQuickActionClick = (button: Button, position: number) => {
    trackAssistantQuickActionClick({
      text: button.button_text,
      position,
    });
  };

  const onIntegrationView = (
    integration: AssistantIntegration,
    position: number
  ) => {
    trackAssistantActionsView({
      position,
      name: integration.subject.title,
      integration_id: integration.id,
    });
  };

  const onIntegrationClick = (
    integration: AssistantIntegration,
    position: number
  ) => {
    trackAssistantActionsClick({
      position,
      name: integration.subject.title,
      integration_id: integration.id,
    });
  };

  return (
    <div className="assistant__explore  assistant__search-view">
      <div className="assistant__search-pane">
        <AssistantTitle>{t('assistant.command_center')}</AssistantTitle>
        <Spinner center loading={isFetching}>
          <ul className="assistant__explore__action-links">
            {buttons &&
              buttons.map(
                (button, position) =>
                  button.action && (
                    <li
                      key={button.button_text}
                      className="assistant__explore__action-links__link"
                    >
                      <AssistantAction
                        onClick={() => {
                          onQuickActionClick(button, position);
                        }}
                        actionTitle={button.button_text}
                        action={button.action}
                        asCommand
                        pushState={{ referer: 'explorer' }}
                      >
                        <img
                          alt=""
                          width="28"
                          height="28"
                          className="button-image"
                          src={button.button_image_url}
                        />
                        <span className="text">{button.button_text}</span>
                      </AssistantAction>
                    </li>
                  )
              )}
          </ul>

          <hr className="assistant__break" />

          <div className="assistant__integrations-list">
            <h3 className="assistant__subtitle">{labels.apps}</h3>
            {integrations &&
              integrations.map((integration, position) => (
                <div key={integration.id} className="assistant__app">
                  <ViewTrigger
                    onInview={() => {
                      onIntegrationView(integration, position);
                    }}
                  />
                  <AssistantLink
                    className="assistant__app-subject"
                    onClick={() => {
                      onIntegrationClick(integration, position);
                    }}
                    to={{
                      pathname: `/assistant/service/${integration.id}`,
                      state: { referer: 'explorer' },
                    }}
                  >
                    <img
                      alt="Assistant App Logo"
                      width="40"
                      height="40"
                      className="assistant__app-logo"
                      src={
                        integration.subject.image_url ||
                        programThemeIconUrl ||
                        undefined
                      }
                    />
                    <div className="assistant__app-text">
                      <p className="assistant__app-title">
                        {integration.subject.title}
                      </p>
                      <p className="assistant__app-description">
                        {integration.subject.description}
                      </p>
                    </div>
                  </AssistantLink>
                </div>
              ))}
          </div>
        </Spinner>
      </div>
      <AssistantCommandSelector
        onSelect={(c) => {
          runCommandInAssistant(c, {
            location: 'explorer',
            ...c.tracking_context,
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  integrations: assistantSelectors.getIntegrations(state),
  quickActions: assistantSelectors.getQuickActions(state),
  labels: assistantSelectors.getLabels(state),
  programThemeIconUrl: programSelectors.getProgramThemeIcon(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantExplore);
