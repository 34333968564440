import {
  BrowserHistoryBuildOptions,
  createBrowserHistory,
  History,
} from 'history';

export type ForwardActions = 'push' | 'replace';

let history: History<unknown>;

export const createHistory = (args?: BrowserHistoryBuildOptions) => {
  history = createBrowserHistory(args);

  return history;
};
