import { useState, useEffect, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  homeFeedSections,
  homeFeedSectionsLoaded,
} from '../models/home-page/recoil-state';
import ProgramService from '../services/program';

const useHomeFeedSections = () => {
  const setFeedSections = useSetRecoilState(homeFeedSections);
  const [loaded, setLoaded] = useRecoilState(homeFeedSectionsLoaded);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchDone = useCallback(() => {
    setIsFetching(false);
    setLoaded(true);
  }, [setLoaded]);

  const handleFetchFeedSectionSuccess = useCallback(
    (res) => {
      setFeedSections(res.data.feed_sections);
    },
    [setFeedSections]
  );

  const handleFetchFeedSectionError = useCallback((err) => {
    console.error(err);
    setIsError(true);
  }, []);

  const fetchFeedSections = useCallback(() => {
    setIsFetching(true);

    new ProgramService()
      .fetchFeedSections()
      .then(handleFetchFeedSectionSuccess)
      .catch(handleFetchFeedSectionError)
      .then(fetchDone);
  }, [fetchDone, handleFetchFeedSectionError, handleFetchFeedSectionSuccess]);

  useEffect(() => {
    if (!loaded) {
      fetchFeedSections();
    }
  }, [fetchFeedSections, loaded]);

  return {
    isFetching: !loaded || isFetching,
    isError,
    refetch: fetchFeedSections,
  };
};

export default useHomeFeedSections;
