import { ChangeEventHandler, useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { assistantSelectors } from '../../models/assistant';
import {
  trackAssistantIntegrationLoad,
  trackAssistantIntegrationLinkClick,
} from '../../models/assistant/analytics';

import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantBackBar, AssistantAction } from '../../components/assistant';
import { Spinner } from '../../components/ui';
import { NavigationReferer } from '../../components/assistant/assistant-action';
import AssistantContainer from './assistant-container';
import AssistantContent from './assistant-content';
import AssistantTitle from './assistant-title';
import { Button } from '../../models/assistant/interfaces/Button';

type StateProps = {
  integration: ReturnType<typeof assistantSelectors.getIntegration>;
};

type OwnProps = RouteComponentProps &
  Parameters<typeof assistantSelectors.getIntegration>[1];

type AssistantIntegrationProps = StateProps & OwnProps;

function AssistantIntegration({
  integration,
  location,
}: AssistantIntegrationProps) {
  const { t } = useTranslation();
  const [showGoBack, setShowGoBack] = useState(false);
  const [commandFilter, setCommandFilter] = useState('');

  useEffect(() => {
    const referer: string = get(location.state, 'referer', 'deeplink');

    setShowGoBack(
      referer === NavigationReferer.EXPLORER ||
        referer === NavigationReferer.INBOX
    );
    setCommandFilter('');

    if (integration) {
      trackAssistantIntegrationLoad({
        service_name: integration.subject.title,
        integration_id: integration.id,
        location: referer,
      });
    }
  }, [location.state, integration]);

  // Integration hasn't loaded yet, return loading indicator.
  // This has to come after the useState and useEffect calls,
  // as hooks cannot be called conditionally
  if (!integration) {
    return <Spinner center />;
  }

  const handleIntegrationLinkClick = (button: Button, order: number) => {
    trackAssistantIntegrationLinkClick({
      service_id: integration.id,
      service_name: integration.subject.title,
      link_name: button.button_text,
      order,
    });
  };

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCommandFilter(e.target.value);
  };

  const filterCommands = (list: Button[], filterText: string) => {
    if (filterText == null || filterText == '') return list;
    const text = filterText.toLowerCase();
    return list.filter((button) => {
      return button.button_text.toLowerCase().indexOf(text) > -1;
    });
  };

  const commandsTotalCount = integration.subject.buttons?.length ?? 0;
  const commands = filterCommands(
    integration.subject.buttons ?? [],
    commandFilter
  );

  return (
    <AssistantContainer flex className="assistant__integration">
      {showGoBack ? <AssistantBackBar /> : null}
      <AssistantContent>
        <img
          alt={integration.subject.title}
          src={integration.subject.banner_url}
        />

        <AssistantContainer>
          <AssistantTitle>{integration.subject.title || ''}</AssistantTitle>
          <p className="assistant_description">
            {integration.subject.description}
          </p>
          <div className="assistant_filter">
            {commandsTotalCount > 20 && (
              <input
                type="text"
                value={commandFilter}
                placeholder={t('assistant.command_filter')}
                onChange={handleFilterChange}
              />
            )}
          </div>
          <ul className="assistant__button-list">
            {commands.map(
              (button, index) =>
                button.action && (
                  <li key={button.id}>
                    <AssistantAction
                      onClick={() => handleIntegrationLinkClick(button, index)}
                      action={button.action}
                      actionTitle={button.button_text}
                      asCommand
                      className="pt-theme-color"
                    >
                      {button.button_text}
                    </AssistantAction>
                  </li>
                )
            )}
          </ul>
        </AssistantContainer>
      </AssistantContent>
    </AssistantContainer>
  );
}

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  RootPatronState
> = (state, ownProps) => ({
  integration: assistantSelectors.getIntegration(state, ownProps),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantIntegration);
