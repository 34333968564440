import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  contentSelectors,
  contentOperations,
} from '../../models/content/index.js';

import { Button, Icon } from '../ui';

import {
  trackContentCardBookmark,
  trackContentCardUnbookmark,
} from '../../models/content/analytics';

import './content.scss';

const ContentActionBookmark = ({
  contentId,
  bookmarked,
  bookmarkContent,
  unbookmarkContent,
  analyticsData,
}) => {
  const className = cx('content__action', 'content__action--bookmark', {
    'content__action--bookmarked': bookmarked,
  });

  const { t } = useTranslation();

  const handleClick = () => {
    bookmarked ? unbookmarkContent(contentId) : bookmarkContent(contentId);

    bookmarked
      ? trackContentCardUnbookmark(contentId, analyticsData)
      : trackContentCardBookmark(contentId, analyticsData);
  };

  const bookmarkActionText = bookmarked
    ? t('content.bookmark_remove')
    : t('content.bookmark');

  return (
    <div className={className}>
      <Button onClick={handleClick} aria-label={bookmarkActionText}>
        <span className="icons">
          <Icon type="bookmark" />
          <Icon type="bookmark" className="bookmark filled" />
        </span>
      </Button>
    </div>
  );
};

export default connect(
  (state, props) => ({
    bookmarked: contentSelectors.getContentBookmarked(state, props),
  }),
  {
    bookmarkContent: contentOperations.bookmarkContent,
    unbookmarkContent: contentOperations.unbookmarkContent,
  }
)(ContentActionBookmark);
