import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { User } from '../models/advocate/types';
import AdvocateService from '../services/advocate';
import moment from 'moment';

const DATE_ATTRIBUTES = ['birthdate', 'start_date'];

export type UserSuggestion = Pick<User, 'id' | 'name' | 'avatar'> & {
  display_attributes: string[];
};

async function apiFetchSuggestions(
  searchText: string
): Promise<UserSuggestion[]> {
  const advocateService = new AdvocateService();
  const res = await advocateService.getSuggestion(searchText);
  return res.data.users.map(
    (user): UserSuggestion => ({
      id: user.id,
      name: user.name,
      avatar: user.avatar,
      display_attributes: user.search_attributes
        .map((attr) => {
          const value = user[attr] as string;
          if (value && DATE_ATTRIBUTES.includes(attr)) {
            return moment(value).format('L');
          }
          return user[attr];
        })
        .filter((attr): attr is string => typeof attr === 'string'),
    })
  );
}

export function useUserSuggestions(searchText: string): UserSuggestion[] {
  const deadQuery = useRef<string | null>(null);
  const [suggestions, setSuggestions] = useState<UserSuggestion[]>([]);

  const fetchSuggestions = useCallback(async (searchText: string) => {
    const suggestions = await apiFetchSuggestions(searchText);
    // If search returned 0 results, then remember query to ignore further typing
    deadQuery.current = !suggestions.length ? searchText : null;
    setSuggestions(suggestions);
  }, []);

  const debouncedFetchSuggestions = useMemo(
    () => _.debounce(fetchSuggestions, 500),
    [fetchSuggestions]
  );

  useEffect(() => {
    if (!searchText) {
      setSuggestions([]);
      return;
    }

    //If current search text starts with query that returned 0 results, then ignore it
    if (deadQuery.current && searchText.startsWith(deadQuery.current)) {
      return;
    }

    debouncedFetchSuggestions(searchText);
  }, [debouncedFetchSuggestions, searchText]);

  return suggestions;
}
