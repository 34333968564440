import cx from 'classnames';
import { Button } from '../ui';

const EmojiPickerCategory = ({ name, svg, onClick, active }) => {
  const className = cx('emoji-picker__category', {
    'emoji-picker__category--active': active,
  });

  return (
    <Button
      type="button"
      className={className}
      onClick={() => onClick(name)}
      aria-label={name}
    >
      {svg}
    </Button>
  );
};

export default EmojiPickerCategory;
