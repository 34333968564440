import { Container } from '@socialchorus/shared-ui-components';
import { LoadingSkeleton } from '../../loading-skeleton';
import styles from './documents.module.scss';

export function DocumentLoadingSkeleton() {
  return (
    <Container className={styles.DocumentCard}>
      <LoadingSkeleton height="80px" width="70px" />
      <LoadingSkeleton height={14} width="75%" />
      <LoadingSkeleton height={10} width="85%" />
      <LoadingSkeleton height={10} width="60%" />
    </Container>
  );
}
