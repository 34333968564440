import axios from 'axios';
import { useState, useEffect } from 'react';
import store from '../config/store';
import { advocateOperations } from '../models/advocate';
import { AdvocateGroup, AdvocateProfile } from '../models/advocate/types';
import AdvocateService from '../services/advocate';

const useProfileFetcher = ({ profileId }: { profileId: number }) => {
  const [profile, setProfile] = useState<AdvocateProfile>();
  const [profileGroups, setGroups] = useState<Array<AdvocateGroup>>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isError, setIsError] = useState(false);

  const advocateService = new AdvocateService(profileId);

  const fetchProfile = async () => {
    setIsFetching(true);

    try {
      const res = await advocateService.fetch();
      handleFetchSuccess(res);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (
    res: Awaited<ReturnType<typeof advocateService['fetch']>>
  ) => {
    setProfile(res.data.data.profile);
    setGroups(res.data.data.groups);
    setIsFetching(false);
  };

  const handleFetchFailure = (err: unknown) => {
    if (
      axios.isAxiosError(err) &&
      (err.response?.status === 403 || err.response?.status === 404)
    ) {
      setIsNotFound(true);
    } else {
      setIsError(true);
    }

    setIsFetching(false);
  };

  const syncAdvocate = () => {
    advocateOperations
      .syncAdvocate()(store.dispatch, store.getState)
      .then(handleFetchSuccess);
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return {
    profile,
    profileGroups,
    isFetching,
    isNotFound,
    isError,
    syncAdvocate,
  };
};

export default useProfileFetcher;
