import { useRouteMatch } from 'react-router';
import {
  contentsRoutePaths,
  webviewContentsRoutePaths,
} from '../patron-routes-constants';

/**
 * Returns the custom slug if the current route is a content route and the slug is not a number.
 */
export function useCustomSlugRoute() {
  const match = useRouteMatch<{ id: string }>([
    ...contentsRoutePaths,
    ...webviewContentsRoutePaths,
  ]);

  const numbersOnly = /^\d+$/;

  if (match && !numbersOnly.test(match.params.id)) {
    return match.params.id;
  }

  return undefined;
}
