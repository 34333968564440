import { TopicPageProps } from '../topic-page/types';
import {
  Container,
  TabsContentContainer,
  TabsList,
  TabsRootContainer,
} from '@socialchorus/shared-ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { getChannelById } from '../../../models/channels/selectors';
import styles from './styles.module.scss';
import { useHistory, useParams } from 'react-router';
import { ChannelTabType } from '../../../models/channels/types';
import { useCallback, useEffect } from 'react';
import MembersTab from './tabs/MembersTab';
import ShortcutsTab from './tabs/ShortcutsTab';
import PostsTab from './tabs/PostsTab';
import AboutTab from './tabs/AboutTab';
import FollowButton from './common/FollowButton';

function TopicPageV2({ analyticsData }: TopicPageProps): JSX.Element {
  const { channelId: topicId, tabType: activeTabType } = useParams<{
    channelId: string;
    tabType?: ChannelTabType;
  }>();

  const { t } = useTranslation();

  const history = useHistory();
  const topic = usePatronSelector((state) => getChannelById(state, topicId));
  const onChangeTab = useCallback(
    (tabType: string) => {
      history.replace(`/channels/${topicId}/${tabType}`);
    },
    [topicId, history]
  );
  const hasTabsDefined = topic && topic.landing_page_tabs.length > 0;
  const firstTabType = hasTabsDefined
    ? topic.landing_page_tabs[0].tab_type
    : undefined;

  useEffect(() => {
    if (activeTabType !== undefined) {
      if (
        !topic?.landing_page_tabs.map((e) => e.tab_type).includes(activeTabType)
      ) {
        // clear tab type path parameter if it's not defined in the tab list
        history.replace(`/channels/${topicId}`);
      }
      return;
    }

    if (firstTabType) {
      onChangeTab(firstTabType);
    }
  }, [
    activeTabType,
    topicId,
    firstTabType,
    history,
    onChangeTab,
    topic?.landing_page_tabs,
  ]);

  return (
    <div className={styles.topicPage}>
      {topic ? (
        <TabsRootContainer value={activeTabType} onValueChange={onChangeTab}>
          <div className={styles.Container}>
            <Container
              className={classNames(
                styles.HeaderSection,
                !topic.use_cover_image && styles.HeaderSection__NoCover
              )}
              fullWidth
              shadow="extra-light"
            >
              {topic.use_cover_image && (
                <div className={styles.CoverImage__Container}>
                  {topic.cover_image_url ? (
                    <img
                      className={styles.CoverImage}
                      src={topic.cover_image_url}
                      alt="Cover"
                    />
                  ) : (
                    <div className={styles.CoverImageDefault} />
                  )}
                </div>
              )}
              <div className={styles.HeaderInfo__Container}>
                <Container
                  variant="border"
                  className={styles.TopicImage__Container}
                  shape="small"
                >
                  {topic.background_image_url ? (
                    <img
                      className={styles.TopicImage}
                      src={topic.background_image_url}
                      width={140}
                      height={140}
                      alt=""
                    />
                  ) : (
                    <div
                      className={styles.TopicImageDefault}
                      style={{ backgroundColor: topic.background_color }}
                    />
                  )}
                </Container>

                <div className={styles.HeaderTexts}>
                  <span className="text-secondary-heading">{topic.name}</span>

                  <span className="text-body-1 font-medium-light text-black-60">
                    {topic.description}
                  </span>
                </div>

                <div className={styles.HeaderActions}>
                  <FollowButton
                    topicId={topicId}
                    analyticsData={analyticsData}
                  />
                </div>
              </div>

              <div className={styles.HeaderTabs}>
                {topic.landing_page_tabs.length > 1 && (
                  <TabsList
                    tabs={topic.landing_page_tabs.map((tab) => ({
                      label: t(`channel_details.${tab.tab_type}`),
                      value: tab.tab_type,
                    }))}
                  />
                )}
              </div>
            </Container>
            <TabsContentContainer value={ChannelTabType.About}>
              <AboutTab topicId={topicId} />
            </TabsContentContainer>
            <TabsContentContainer value={ChannelTabType.Posts}>
              <PostsTab topicId={topicId} analyticsData={analyticsData} />
            </TabsContentContainer>
            <TabsContentContainer value={ChannelTabType.Shortcuts}>
              <ShortcutsTab topicId={topicId} />
            </TabsContentContainer>
            <TabsContentContainer value={ChannelTabType.Members}>
              <MembersTab topicId={topicId} />
            </TabsContentContainer>
          </div>
        </TabsRootContainer>
      ) : (
        'Loading...'
      )}
    </div>
  );
}

export default TopicPageV2;
