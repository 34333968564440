import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { contentSelectors } from '../../models/content/index.js';

import { Button, Chat } from '../ui';

import { trackContentCardComment } from '../../models/content/analytics';

import './content.scss';

const ContentActionComment = ({
  contentId,
  noText,
  commentCount,
  analyticsData,
}) => {
  const history = useHistory();

  const { t } = useTranslation();

  const className = cx('content__action', 'content__action--comment', {
    'content__action--empty': !commentCount,
  });

  const handleClick = () => {
    const detailsRoute = `/contents/${contentId}`;
    history.push(detailsRoute, { focusComments: true });

    trackContentCardComment(contentId, analyticsData);
  };

  const commentActionTitle = noText
    ? commentCount + ' ' + t('content.comment', { count: commentCount })
    : null;

  return (
    <div className={className}>
      <Button
        iconComponent={<Chat />}
        onClick={handleClick}
        aria-label={t('content.comment', { count: 1 })}
        title={commentActionTitle}
      >
        <span className="count">{commentCount}</span>
        {noText ? null : (
          <span className="text">
            &nbsp;{t('content.comment', { count: commentCount })}
          </span>
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  commentCount: contentSelectors.getContentCommentCount(state, props),
});

export default connect(mapStateToProps, null)(ContentActionComment);
