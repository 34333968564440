import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAssistantActionHandlers from '../../../../common/use-assistant-action-handlers';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../../../common/use-assistant-fetcher';
import { AssistantIntegration } from '../../../../models/assistant/selectors';
import { Spinner, Link } from '../../../ui';

const FEATURED_LINKS_TITLE_KEY = 'assistant.featured_links';
const MAX_LINKS_SHOWN = 5;

export interface ChannelDetailsLinksProps {
  channelId: string;
  analyticsData: Object;
}

interface IntegrationLinkListProps {
  integrations: AssistantIntegration[] | null;
  analyticsData?: unknown;
}

const IntegrationLinkList: React.FC<IntegrationLinkListProps> = ({
  integrations,
  analyticsData,
}) => {
  const { handleAction } = useAssistantActionHandlers();
  const { t } = useTranslation();

  if (!integrations?.length) {
    return null;
  }

  const renderLink = (integration: AssistantIntegration) => {
    const action = integration.subject.action;

    return (
      <div className="channel-details-v2__link" key={`${integration.id}`}>
        <Link onClick={() => handleAction(action, analyticsData)}>
          {integration.name}
        </Link>
      </div>
    );
  };

  return (
    <div className="channel-details-v2__links">
      <div className="channel-details-v2__subtitle">
        {t(FEATURED_LINKS_TITLE_KEY)}
      </div>
      <dl className="channel-details-v2__links-list">
        {integrations.map(renderLink)}
      </dl>
    </div>
  );
};

export const ChannelDetailsLinks: React.FC<ChannelDetailsLinksProps> = ({
  channelId,
  analyticsData,
}) => {
  const fetchedIntegrations = useAssistantFetcher({
    itemTypes: [AssistantDataType.INTEGRATIONS],
  });

  const integrationsToDisplay = useMemo(() => {
    if (fetchedIntegrations.isFetching) {
      return null;
    }

    const integrations: AssistantIntegration<{ channels?: string[] }>[] =
      fetchedIntegrations.assistantData?.integrations;

    if (fetchedIntegrations.isError || !integrations) {
      return null;
    }

    const linkIntegrationsForChannel = integrations.filter(
      (integration) =>
        integration.subject.integrationType === 'link' &&
        integration.subject.config?.channels?.includes?.(channelId)
    );

    return linkIntegrationsForChannel.slice(0, MAX_LINKS_SHOWN);
  }, [fetchedIntegrations.assistantData, channelId]);

  return (
    <Spinner loading={fetchedIntegrations.isFetching}>
      <IntegrationLinkList
        integrations={integrationsToDisplay}
        analyticsData={analyticsData}
      />
    </Spinner>
  );
};

export default ChannelDetailsLinks;
