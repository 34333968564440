import { DraftDecorator } from 'draft-js';
import { DecoratorComponentProps } from './types';
import styles from './styles.module.scss';

function Tag({ children }: DecoratorComponentProps): JSX.Element {
  return <span className={styles.userTag}>{children}</span>;
}

function createTagDecorator(): DraftDecorator {
  return {
    strategy(contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'REFERENCE'
        );
      }, callback);
    },
    component: Tag,
  };
}

export default createTagDecorator;
