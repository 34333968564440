import { useTranslation } from 'react-i18next';
import { Button, Modal } from '../../../ui';
import './confirm-flag-content-modal.scss';
import ContentService from '../../../../services/content';
import { usePushToastOrFlashMessage } from '../../toaster/deprecation-helper';
import { useState } from 'react';
import {
  trackContentCardConfirmFlag,
  trackContentCardConfirmFlagSuccess,
  trackContentCardConfirmFlagError,
} from '../../../../models/content/analytics';

type ConfirmFlagContentModalProps = {
  close: () => void;
  contentId: number;
  analyticsData: IAnalyticsData;
};

export function ConfirmFlagContentModal({
  close,
  contentId,
  analyticsData,
}: ConfirmFlagContentModalProps) {
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const [processing, setProcessing] = useState(false);

  const flagContent = () => {
    setProcessing(true);
    trackContentCardConfirmFlag(contentId, analyticsData);
    new ContentService(contentId)
      .flagContent()
      .then(() => {
        trackContentCardConfirmFlagSuccess(contentId, analyticsData);
        pushMessage({
          text: t('content.flagged.flag_post_success'),
          type: 'success',
        });
      })
      .catch((err) => {
        trackContentCardConfirmFlagError(contentId, analyticsData);
        const errorMessage =
          err.response.status === 409
            ? t('content.flagged.flag_post_error_already_flagged')
            : t('content.flagged.flag_post_error');
        pushMessage({
          text: errorMessage,
          type: 'error',
        });
      })
      .finally(() => {
        setProcessing(false);
        close();
      });
  };

  return (
    <Modal className="confirm-flag-content-modal" lockScroll onClose={close}>
      <header className="confirm-flag-content-modal__header">
        <div className="confirm-flag-content-modal__header--headings">
          <h2 className="title">{t('content.flagged.flag_post')}</h2>
        </div>
        <div className="confirm-flag-content-modal__header--actions">
          <Button theme="icon-only" icon="close" onClick={close} />
        </div>
      </header>

      <div className="confirm-flag-content-modal__body">
        {t('content.flagged.modal_description')}
      </div>

      <footer className="confirm-flag-content-modal__footer">
        <Button theme="secondary" onClick={close}>
          {t('common.cancel')}
        </Button>
        <Button theme="danger" onClick={flagContent} disabled={processing}>
          {t('content.flagged.flag_post')}
        </Button>
      </footer>
    </Modal>
  );
}

export const ID = 'Overlays.CONFIRM_FLAG_CONTENT_MODAL';
