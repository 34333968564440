import React from 'react';
import useProgramTheme from '../../common/use-program-theme';

const ProfileSectionHeader = ({ title, iconUrl }) => {
  const { themeColor } = useProgramTheme();

  return (
    <div className="fields-group__header">
      <div
        className={'card-icon'}
        style={{
          backgroundImage: `url(${encodeURI(iconUrl)})`,
          backgroundColor: themeColor,
        }}
      />
      <div className={'content'}>
        <h2>{title}</h2>
        <hr />
      </div>
    </div>
  );
};

export default ProfileSectionHeader;
