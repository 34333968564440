import { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import { Spinner } from '../../components/ui';
import useAssistantCommand from '../../common/use-assistant-command';

function AssistantCommandRunner({ match }: RouteComponentProps) {
  const integrationId: string | undefined = get(match.params, 'integrationId');
  const command: string | undefined = get(match.params, 'command');

  const { buildCommandAction, fetchCommandAction } = useAssistantCommand();

  const [isFetching, setIsFetching] = useState(true);
  const [cmd, setCmd] = useState<unknown>();

  useEffect(() => {
    if (!integrationId || !command) return;

    fetchCommandAction(integrationId, command)
      .then((action) => {
        setCmd({
          button_text: command,
          action: action,
        });
      })
      .catch((err) => {
        console.error(err);

        setCmd(buildCommandAction(integrationId, command));
      })
      .finally(() => setIsFetching(false));
  }, [buildCommandAction, command, fetchCommandAction, integrationId]);

  return isFetching ? (
    <Spinner center={true} />
  ) : (
    <Redirect
      to={{ pathname: '/assistant/commands', state: { command: cmd } }}
    />
  );
}

export default AssistantCommandRunner;
