import { useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modifier, usePopper } from 'react-popper';
import cx from 'classnames';

import { Button, Icon } from '../../../ui';
import './content-action-more.scss';

type ContentActionMoreProps = { actions: JSX.Element[] };

const ContentActionMore = ({ actions }: ContentActionMoreProps) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const className = cx('content__action');
  const menuButton = useRef(null);
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const applyWidthPropsModifier = useMemo<Modifier<'applyWidthProps'>>(
    () => ({
      name: 'applyWidthProps',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = 'auto';
        state.styles.popper.minWidth = 'max-content';
      },
    }),
    []
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      applyWidthPropsModifier,
    ],
  });

  let menuBlurTimeoutID: NodeJS.Timeout;

  const closeMenu = useCallback(() => {
    setIsShowingMenu(false);
    removeDocumentClickListener();
  }, [setIsShowingMenu]);

  const handleClick = () => {
    // toggle is meaningful if you use keyboard - you can open/close menu by using spacebar repeatedly while focusing menu button.
    isShowingMenu ? closeMenu() : showMenu();
  };

  const handleDocumentClick: (e: any) => void = useCallback(
    (e) => {
      // menu button click will bubble up to document in some cases when using keyboard to click it.
      if (e.target !== menuButton.current) {
        setTimeout(closeMenu, 15); // add slight timeout for mobile clients
      }
    },
    [closeMenu]
  );

  const showMenu = () => {
    setIsShowingMenu(true);
    addDocumentClickListener();
  };

  const handleBlur = () => {
    //only close menu after next tick, giving chance for other focus event handlers to cancel timeout within same tick.
    menuBlurTimeoutID = setTimeout(closeMenu, 0);
  };
  const handleFocus = () => {
    clearTimeout(menuBlurTimeoutID);
  };

  const addDocumentClickListener = () => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('touchend', handleDocumentClick);
  };

  const removeDocumentClickListener = useCallback(() => {
    document.removeEventListener('click', handleDocumentClick);
    document.removeEventListener('touchend', handleDocumentClick);
  }, [handleDocumentClick]);

  if (actions.length === 0) {
    return null;
  }

  return (
    <div className={className} onBlur={handleBlur} onFocus={handleFocus}>
      <Button
        onClick={handleClick}
        aria-label={t('content.more')}
        theme={'icon-only'}
        size={'32'}
        ref={(element) => {
          setReferenceElement(element);
          menuButton.current = element;
        }}
      >
        <Icon type="more_vert" />
      </Button>

      {isShowingMenu ? (
        <div
          className="dropdown__popover"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <ul>
            {actions.map((action, index) => (
              <li key={index} className="content-action-list_item">
                {action}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default ContentActionMore;
