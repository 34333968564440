import { CSSProperties, useRef } from 'react';
import { connect } from 'react-redux';

import { contentSelectors } from '../../models/content/index.js';
import useTruncate from '../../common/use-truncate';
import { RootPatronState } from '../../common/use-patron-selector';

import './content.scss';
import { languageDirection } from '../../lib/language-helper';
import { DangerousHTMLWithMentionsAndAutolinks } from '../ui/mention-text';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  contentId: number;
  truncate?: number;
  style?: CSSProperties;
  forceShow?: boolean;
};

type ContentSummaryProps = StateProps & OwnProps;

const ContentSummary = ({
  title,
  linkedSummary,
  summary,
  language,
  truncate,
  style,
  forceShow,
}: ContentSummaryProps) => {
  const ref = useRef<HTMLSpanElement>(null);

  useTruncate({ ref, lines: truncate, useEffectConditionValue: summary });

  // Display the summary if:
  // - The title is defined, otherwise the summary will be promoted to title
  // - `forceShow` prop has been set to `true`
  const displaySummary = title || forceShow ? linkedSummary : null;

  if (!displaySummary) return null;

  return (
    <p
      dir={languageDirection(language)}
      style={style}
      className="content__summary"
    >
      <span ref={ref}>
        <DangerousHTMLWithMentionsAndAutolinks text={displaySummary} />
      </span>
    </p>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  title: contentSelectors.getContentTitle(state, ownProps),
  linkedSummary: contentSelectors.getContentSummary(state, ownProps),
  summary: contentSelectors.getContentSummary(state, ownProps),
  language: contentSelectors.getContentLang(state, ownProps),
});

export default connect(mapStateToProps)(ContentSummary);
