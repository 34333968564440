import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import queryString from 'query-string';

import { Spinner } from '../../components/ui';
import ViewTrigger from '../../components/view-trigger';
import {
  AssistantNotificationItem,
  AssistantBackBar,
  AssistantCommandSelector,
} from '../../components/assistant';

import { assistantSelectors } from '../../models/assistant';
import useAssistantCommand from '../../common/use-assistant-command';
import useNotificationsFetcher from '../../common/use-notifications-fetcher';
import { RootPatronState } from '../../common/use-patron-selector';

import { trackAssistantNotificationsLoad } from '../../models/assistant/analytics';
import AssistantTitle from './assistant-title';

type StateProps = {
  labels: ReturnType<typeof assistantSelectors.getLabels>;
};

type OwnProps = RouteComponentProps;

type AssistantNotificationsProps = OwnProps & StateProps;

function AssistantNotifications({
  location,
  labels,
}: AssistantNotificationsProps) {
  const { runCommandInAssistant } = useAssistantCommand();

  useEffect(() => {
    trackAssistantNotificationsLoad();
  }, []);

  const params = queryString.parse(location.search);

  const { notifications, fetchNotifications, isFetching, canLoadMore } =
    useNotificationsFetcher(params.ids as string[]);

  const fetchMore = () => {
    fetchNotifications();
  };

  return (
    <div className="assistant__notifications assistant__search-view">
      {params.ids ? <AssistantBackBar /> : null}
      <div className="assistant__search-pane">
        <div className="assistant__notifications-list">
          <AssistantTitle>{labels.notifications}</AssistantTitle>
          {notifications && notifications.length
            ? notifications.map((notification, index) => (
                <AssistantNotificationItem
                  analyticsData={{
                    position: index,
                  }}
                  data={notification}
                  key={notification.id}
                />
              ))
            : null}

          {isFetching ? (
            <Spinner center={!notifications.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={fetchMore} />
          ) : null}
        </div>
      </div>
      <AssistantCommandSelector
        onSelect={(c) => {
          runCommandInAssistant(c, {
            location: 'notifications',
            ...c.tracking_context,
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  labels: assistantSelectors.getLabels(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantNotifications);
