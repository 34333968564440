import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import FocusLock from 'react-focus-lock';

import { programMembershipSelectors } from '../../models/program-membership';

import useContentFetcher from '../../common/use-content-fetcher';
import useHeaderLogoOnly from '../../common/use-header-logo-only';

import { Spinner } from '../../components/ui';
import ContentDetailsNotFound from './content-details-not-found';
import ContentDetail from '../content-details/content-detail';
import ContentInfo from '../content-details/content-info';
import { ContentDetail as ContentDetailV2 } from '../../components/v2/content-detail/content-detail';

import { trackContentCardRead } from '../../models/content/analytics';

import '../content-details/content-details.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';

const ContentDetailsOrBlank = (props) =>
  props.isActiveMembership ? <EmailContentDetails {...props} /> : null;

const EmailContentDetails = ({
  overlays,
  match: {
    path,
    params: { id: contentId, feedItemId },
  },
  history,
}) => {
  const isApproval = path.includes('approvals');
  const { content, fetching } = useContentFetcher({
    contentId,
    feedItemId,
    isApproval,
  });

  contentId = content && content.id;

  const newContentPage = useSelector((state) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  useHeaderLogoOnly(true); // Use Smaller Logo-only header

  useEffect(() => {
    if (feedItemId && content) {
      history.replace(`/contents/${content.id}`);
    } else if (!fetching && content) {
      trackContentCardRead(contentId);
    }
  }, [contentId, fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseClick = () => {
    history.goBack();
  };

  if (!content && !fetching) return <ContentDetailsNotFound />;

  return (
    <FocusLock disabled={!!overlays.length}>
      <section className="content-details">
        {!fetching ? (
          newContentPage ? (
            <ContentDetailV2
              contentId={contentId}
              navigateBack={handleCloseClick}
              emailContent={true}
            />
          ) : (
            <>
              <ContentDetail
                contentId={contentId}
                onClose={handleCloseClick}
                emailContent={true}
              />

              <ContentInfo contentId={contentId} emailContent={true} />
            </>
          )
        ) : (
          <Spinner />
        )}
      </section>
    </FocusLock>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isActiveMembership: programMembershipSelectors.getProgramMembershipIsActive(
    state,
    ownProps
  ),
  overlays: state.ui.overlays,
});

export default connect(mapStateToProps, null)(ContentDetailsOrBlank);
