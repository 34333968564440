import { useTranslation } from 'react-i18next';
import { Link } from '../ui';
import { VideoIcon, ImageIcon, NoteIcon, ArticleIcon, LinkIcon } from '../ui';
import {
  trackAddLinkClick,
  trackAddImageClick,
  trackAddVideoClick,
  trackAddNoteClick,
  trackAddArticleClick,
} from '../../models/content-submission/analytics';
import { ContentType } from '../../models/content/types';
import { JSXElementConstructor } from 'react';

type SiteHeaderSubmitLinksProps = {
  onLinkOver: (contentType: ContentType | null) => void;
  onClick?: () => void;
};

const SiteHeaderSubmitLinks = ({
  onLinkOver,
  onClick,
}: SiteHeaderSubmitLinksProps) => {
  const { t } = useTranslation();
  const handleClick = (type?: ContentType) => {
    onClick?.();
    switch (type) {
      case 'video':
        trackAddVideoClick();
        break;
      case 'image':
        trackAddImageClick();
        break;
      case 'link':
        trackAddLinkClick();
        break;
      case 'note':
        trackAddNoteClick();
        break;
      case 'article':
        trackAddArticleClick();
        break;
      default:
        break;
    }
  };

  const contentTypes: {
    key: ContentType;
    icon: JSXElementConstructor<{ className?: string }>;
  }[] = [
    { key: 'video', icon: VideoIcon },
    { key: 'image', icon: ImageIcon },
    { key: 'note', icon: NoteIcon },
    { key: 'article', icon: ArticleIcon },
    { key: 'link', icon: LinkIcon },
  ];

  return (
    <ul
      className="site-header-submit-links"
      onPointerLeave={() => onLinkOver(null)}
    >
      {contentTypes.map(({ key: contentType, icon: Icon }) => (
        <li key={contentType}>
          <Link
            className="site-header-submit-links__link"
            href={`/submit/${contentType}`}
            action="push"
            onPointerOver={() => onLinkOver(contentType)}
            onClick={() => handleClick(contentType)}
            aria-label={t('content_submission.create_post', {
              type: t(`content_submission.${contentType}`),
            })}
          >
            <Icon className="mr-15" /> {t(`content_submission.${contentType}`)}
          </Link>
        </li>
      ))}

      <div role="separator" className="site-header-submit-links__divider" />

      <li>
        <Link
          className="site-header-submit-links__link"
          href="/account/submitted"
          action="push"
          onPointerOver={() => onLinkOver(null)}
        >
          {t('content.all_your_posts')}
        </Link>
      </li>
    </ul>
  );
};

export default SiteHeaderSubmitLinks;
