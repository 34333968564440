export enum NotificationCenterTabs {
  MESSAGES = 'messages',
  ACTIVITY = 'activities',
  ARCHIVE = 'archived',
}

export type NotificationCategory =
  | 'welcome'
  | 'topic_auto_follow'
  | 'content_mention'
  | 'content_view'
  | 'content_like'
  | 'content_comment'
  | 'comment_like'
  | 'comment_mention'
  | 'comment_reply'
  | 'comment_reply_like'
  | 'push'
  | 'integration'
  | 'message'
  | 'content_critical'
  | 'content_acknowledgement'
  | 'content_approval'
  | 'poll'
  | 'content_for_you';

export type Notification = {
  id: string;
  attributes: {
    user_id?: number;
    program_id?: number;
    category?: NotificationCategory;
    read?: boolean;
    archived?: boolean;
    important?: boolean;
    created_at: string;
    updated_at?: string;
    action_required?: boolean;
    label?: string;
    text: string;
    avatar?: string;
    preview?: {
      icon: string;
      text: string;
    };
    //todo: map this to the action interface
    action: {
      type: string;
      navigation: {
        type: string;
        url: string;
      };
    };
  };
};

export type NotificationCounts = {
  total_alert?: number;
  [NotificationCenterTabs.MESSAGES]?: {
    unread: number;
    important: number;
    action_required: number;
  };
  [NotificationCenterTabs.ACTIVITY]?: {
    unread: number;
  };
  [NotificationCenterTabs.ARCHIVE]?: {
    unread: number;
  };
};

export type NotificationMeta = {
  page: number;
  perPage: number;
  totalPages: number;
  totalResults: number;
  counts: NotificationCounts;
};
