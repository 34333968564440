import * as types from './keys';
import { track } from '../../analytics/helpers';

export type ContentSection = 'title' | 'description' | 'body';
export interface TagSearchTraits {
  content_section: ContentSection;
}
export const trackTagSearchType = (traits: TagSearchTraits) => {
  track(types.TAG_SEARCH_TYPE, traits);
};

export interface TagSearchClickTraits extends TagSearchTraits {
  /**
   * Selected user id
   */
  selected: string;
}
export const trackTagSearchClick = (traits: TagSearchClickTraits) => {
  track(types.TAG_SEARCH_CLICK, traits);
};
