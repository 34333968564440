import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { CommentAssistantSuggestionType } from '../../../../../models/comments/types';
import { SuggestionSelectedCallback } from '../types';
import { CommentAssistantMenuItem } from './menu-item';

type CommentAssistantMainMenuProps = {
  children?: ReactNode;
  onSuggestionSelected: SuggestionSelectedCallback;
};

type AvailableSuggestionTypes = Exclude<
  CommentAssistantSuggestionType,
  'change_tone'
>;

const MENU_ITEMS: { name: AvailableSuggestionTypes; icon: string }[] = [
  { name: 'improve_writing', icon: 'auto_awesome' },
  { name: 'fix_spelling_grammar', icon: 'spellcheck' },
  { name: 'use_concise_language', icon: 'summarize' },
];

export const CommentAssistantDropdownMainMenu = forwardRef<
  HTMLDivElement,
  CommentAssistantMainMenuProps
>(({ children, onSuggestionSelected }, forwardedRef) => {
  const handleItemSelected = (name: AvailableSuggestionTypes) => {
    onSuggestionSelected({ suggestionType: name });
  };

  return (
    <DropdownMenu.Content
      ref={forwardedRef}
      className={cx(
        'comment-assistant-dropdown',
        'comment-assistant-menu__list'
      )}
      align="start"
    >
      {MENU_ITEMS.map(({ name, icon }) => (
        <DropdownMenu.Item
          key={name}
          className="comment-assistant-menu__list-item__wrapper"
        >
          <CommentAssistantMenuItem
            name={name}
            icon={icon}
            onItemSelected={handleItemSelected}
          />
        </DropdownMenu.Item>
      ))}
      {children}
    </DropdownMenu.Content>
  );
});
CommentAssistantDropdownMainMenu.displayName =
  'CommentAssistantDropdownMainMenu';

export function CommentAssistantMainMenu({
  children,
  onSuggestionSelected,
}: CommentAssistantMainMenuProps) {
  const handleItemSelected = (name: AvailableSuggestionTypes) => {
    onSuggestionSelected({ suggestionType: name });
  };

  return (
    <ul className="comment-assistant-menu__list">
      {MENU_ITEMS.map(({ name, icon }) => (
        <li key={name}>
          <CommentAssistantMenuItem
            name={name}
            icon={icon}
            onItemSelected={handleItemSelected}
          />
        </li>
      ))}
      {children}
    </ul>
  );
}
