export const contentCommentReplyRoutePath =
  '/contents/:id/comments/*/replies/:commentId';

export const contentCommentAllRepliesRoutePath =
  '/contents/:id/comments/:commentId/replies';

export const contentCommentsRoutePaths = [
  contentCommentReplyRoutePath,
  contentCommentAllRepliesRoutePath,
  '/contents/:id/comments/:commentId',
  '/contents/:id/comments',
] as const;

export const contentsRoutePaths = [
  '/contents/:id',
  ...contentCommentsRoutePaths,
] as const;

export const webviewContentsRoutePaths = [
  '/webview/contents/:id/comments/*/replies/:commentId',
  '/webview/contents/:id/comments/:commentId/replies',
  '/webview/contents/:id/comments/:commentId',
  '/webview/contents/:id/comments',
] as const;
