import types from './types';

const initialState = {
  commands: [],
  integrations: [],
  integrationsById: {},
  history: [],
  commandText: '',
  inboxCount: 0,
  quickActions: [],
  inbox: [],
  bootstrap: null,
  dismissedMessages: {},
  trackedContent: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMMANDS:
      return {
        ...state,
        commands: action.commands.map((command, index) => ({
          ...command,
          index,
        })),
      };

    case types.SET_INTEGRATIONS: {
      const integrations = action.integrations;
      const integrationsById = integrations.reduce(
        (obj, integration) => ({
          ...obj,
          [integration.id]: integration,
        }),
        {}
      );

      return {
        ...state,
        integrations,
        integrationsById,
      };
    }
    case types.ADD_HISTORY_ITEM:
      return {
        ...state,
        history: [...state.history, action.historyItem],
      };

    case types.DISMISS_MESSAGE:
      return {
        ...state,
        dismissedMessages: {
          ...state.dismissedMessages,
          [action.messageId]: true,
        },
      };

    case types.UPDATE_HISTORY_ITEM: {
      const index = state.history.findIndex(
        (item) => item.id === action.historyItem.id
      );
      const newHistory = [...state.history];
      newHistory[index] = action.historyItem;
      return {
        ...state,
        history: newHistory,
      };
    }
    case types.SET_ITEM:
      return {
        ...state,
        [action.itemName]: action.item,
      };

    case types.ITEM_VIEWED:
      return {
        ...state,
        trackedContent: {
          ...state.trackedContent,
          [action.itemId]: true,
        },
      };

    default:
      return state;
  }
};

export default reducer;
