import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useProfileFeed from '../../common/use-profile-feed';
import { programSelectors } from '../../models/program';

import { Spinner } from '../../components/ui';
import ProfileList from '../../components/profile-list';
import ViewTrigger from '../../components/view-trigger';
import EndOfFeed from '../../components/feed/end-of-feed';
import EmptyFeed from '../../components/feed/empty-feed';

import { SearchResults } from './search';

import { trackSearchSuccess, trackSearchError } from '../../models/analytics';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const SearchPeopleStandard = ({ term }) => {
  const { profiles, page, isFetching, isError, canLoadMore, onLoadMore } =
    useProfileFeed({ search: term });

  const analyticsData = {
    location: 'search_people',
    searchType: 'people',
    searchTerm: term,
  };

  useEffect(() => {
    if (!isError) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  if (isError) return <SearchPeopleError term={term} />;

  return (
    <section className="search-people" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        <>
          {page ? (
            <ProfileList
              profiles={profiles}
              page={page}
              isFetching={isFetching}
              canLoadMore={canLoadMore}
              onLoadMore={onLoadMore}
              analyticsData={analyticsData}
              key={term}
            />
          ) : null}

          {isFetching ? (
            <Spinner center={!profiles.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={onLoadMore} />
          ) : profiles.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </>
      </div>
    </section>
  );
};

const SearchPeopleSearchHeader = ({ term }) => {
  const { t } = useTranslation();
  const {
    profiles,
    page,
    isFetching,
    numPages,
    pageSize,
    isError,
    canLoadMore,
    total,
    onLoadMore,
  } = useProfileFeed({ search: term });

  const analyticsData = {
    location: 'search_people',
    searchType: 'people',
    searchTerm: term,
  };

  useEffect(() => {
    if (!isError) {
      // track success
      trackSearchSuccess(analyticsData);
    } else {
      // track error
      trackSearchError(analyticsData);
    }
  }, [term, isError]);

  if (isError) return <SearchPeopleError term={term} />;

  return (
    <section className="search-people" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      {!isFetching && total ? (
        <div className="search-component__header">
          <SearchResults count={total} />
        </div>
      ) : null}
      <div className="container container--slim">
        <>
          {page ? (
            <ProfileList
              profiles={profiles}
              page={page}
              isFetching={isFetching}
              canLoadMore={canLoadMore}
              onLoadMore={onLoadMore}
              analyticsData={analyticsData}
              key={term}
            />
          ) : null}

          {isFetching ? (
            <Spinner center={!profiles.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={onLoadMore} />
          ) : profiles.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </>
        {!isFetching && total == 0 ? (
          <div className="search-component__footer">
            <SearchResults count={total} />
          </div>
        ) : null}
      </div>
    </section>
  );
};

const SearchPeopleError = ({ term }) => {
  const { t } = useTranslation();

  return (
    <section className="search-people" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <div className="container container--slim">
        <div className="error">
          {t('assistant.error_searching_people', { term })}
        </div>
      </div>
    </section>
  );
};

const SearchPeople = ({ term, searchBarInHeaderEnabled }) => {
  return searchBarInHeaderEnabled ? (
    <SearchPeopleSearchHeader term={term} />
  ) : (
    <SearchPeopleStandard term={term} />
  );
};

const mapStateToProps = (state) => ({
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps, null)(SearchPeople);
