import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '../../../../../common/use-feature-flag';
import { Feature } from '../../../../../models/features/features';
import useProgramTheme from '../../../../../common/use-program-theme';

const accessibleGreen = '#008545';

const StyledItem = styled.button<{ backgroundColor: string }>`
  display: inline-block;
  border: 2px solid ${(props) => props.backgroundColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  line-height: 25px;
  margin-left: auto;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: normal;
  transition: width 0.3s, color 0.8s, background-color 0.8s;
  cursor: pointer;
  white-space: nowrap;
  color: white;
  min-width: 100px;

  > i.material-icons {
    line-height: 25px;
    float: right;
    margin-left: 20px;
    max-width: 24px;
  }

  &.following {
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.backgroundColor};
  }
`;

interface FollowProps {
  id: number;
  following: boolean;
  was_following: boolean;
  channelName: string;
  follow: (id: number) => void;
  unfollow: (id: number) => void;
}

const Follow: React.FC<FollowProps> = ({
  id,
  following,
  was_following,
  channelName,
  follow,
  unfollow,
}) => {
  const { t } = useTranslation();
  const channelsToTopicsEnabled = useFeatureFlag(
    Feature.CHANNELS_TO_TOPICS_ENABLED
  );

  const { themeColor } = useProgramTheme();

  return was_following ? null : (
    <StyledItem
      onClick={() => {
        following ? unfollow(id) : follow(id);
      }}
      className={following ? 'following' : 'notfollowing'}
      aria-label={
        following ? `Unfollow ${channelName}` : `Follow ${channelName}`
      }
      backgroundColor={themeColor || accessibleGreen}
    >
      {following ? (
        <>
          {channelsToTopicsEnabled
            ? t('screens.explore.following')
            : t('screens.explore.joined')}
          <i className="material-icons">check_circle</i>
        </>
      ) : (
        <>
          {channelsToTopicsEnabled
            ? t('screens.explore.follow')
            : t('screens.explore.join')}
          <i className="material-icons">add_circle_outline</i>
        </>
      )}
    </StyledItem>
  );
};

export default Follow;
