import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { contentSelectors } from '../../../models/content/index.js';

import { Icon, Avatar } from '../../ui';
import ContentLink from '../content-link';

const ContentImage = ({
  contentId,
  isPublished,
  isProcessing,
  isPortrait,
  isSquare,
  ignoreLink,
  contentBorderColor,
  noteSettings,
  style,
  analyticsData,
  onClick,
  avatarSrc,
  avatarColor,
}) => {
  const className = cx('content__image', 'content__image--notev2', {
    'content__image--portrait': isPortrait,
    'content__image--square': isSquare,
  });

  // Use border color from note settings, or default to the v1 contentBorderColor
  const borderColor =
    (noteSettings.border && noteSettings.border.color) || contentBorderColor;

  // Use background color from note settings, or set to null
  const backgroundColor =
    noteSettings.background && noteSettings.background.color;

  // If the card has a background color, use white otherwise use the color of the border.
  const quoteColor = backgroundColor ? 'white' : borderColor;

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor,
        border: borderColor && `4px ${borderColor} solid`,
        ...style,
      }}
    >
      <ContentLink
        contentId={contentId}
        ignoreLink={ignoreLink || !isPublished || isProcessing}
        analyticsData={analyticsData}
        onClick={onClick}
      >
        <div className="message-source">
          <Icon
            className="message-quote message-quote--start"
            rotate={180}
            type="format_quote"
            style={{ color: quoteColor }}
          />
          <div className="message-avatar">
            <Avatar
              className="message-avatar__avatar"
              src={avatarSrc}
              bgColor={avatarColor}
            />
            <div
              className="avatar-mask"
              style={{ backgroundColor: backgroundColor || borderColor }}
            />
          </div>
          <Icon
            className="message-quote message-quote--end"
            type="format_quote"
            style={{ color: quoteColor }}
          />
        </div>
      </ContentLink>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
  isProcessing: contentSelectors.getContentIsProcessing(state, ownProps),
  isPortrait: contentSelectors.getContentIsPortrait(state, ownProps),
  isSquare: contentSelectors.getContentIsSquare(state, ownProps),
  contentBorderColor: contentSelectors.getContentBorderColor(state, ownProps),
  noteSettings: contentSelectors.getContentNoteSettings(state, ownProps),
  avatarSrc: contentSelectors.getContentAuthorAvatarSrc(state, ownProps),
  avatarColor: contentSelectors.getContentAuthorAvatarColor(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentImage);
