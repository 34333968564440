import { RefObject, useEffect, useState } from 'react';
import shave from 'shave';
import useScreenSize from './use-screen-size';

type UseTruncateProps = {
  ref: RefObject<HTMLElement>;
  lines?: number;
  character?: string;
  useEffectConditionValue?: string;
};

//character is optional, modeling this way for ts consumers
//useEffectConditionValue is only provided for the useEffect condition.
//refs only update after the render loop, so trying to listen to the text value through the ref will lag behind the render loop
//it is optional to provide, only nessessary when we expect the value to change
export const useTruncate = ({
  ref,
  lines,
  character,
  useEffectConditionValue,
}: UseTruncateProps) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const { width } = useScreenSize();

  useEffect(() => {
    if (!ref.current || !lines) return;

    const el = ref.current;
    const lineHeight = parseInt(
      window.getComputedStyle(el).getPropertyValue('line-height'),
      10
    );

    shave(el, lineHeight * lines + 5, { character }); // add 5px extra to prevent last-line clipping

    setIsTruncated(!!el.getElementsByClassName('js-shave').length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEffectConditionValue, ref.current, lines, width]);

  return {
    ref,
    isTruncated,
  };
};

export default useTruncate;
