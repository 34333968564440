import React, { MouseEventHandler, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button, ShareIcon } from '../../ui';

import { trackContentCardShare } from '../../../models/content/analytics';
import { connect } from 'react-redux';
import { contentSelectors } from '../../../models/content';
import { MoreActionsModalContext } from '../content-card/content-card-toolbar/content-card-toolbar';
import Tooltip from '../../tooltip/tooltip';
import { ID as ContentCardShareModalID } from '../content-card/content-card-share-modal/content-card-share-modal';
import { uiOperations } from '../../../models/ui';
import {
  RootPatronState,
  usePatronSelector,
} from '../../../common/use-patron-selector';

interface IContentActionShare {
  isShared: boolean;
  onClick: MouseEventHandler | undefined;
  contentTitle: string;
  showText?: boolean;
  wideMode?: boolean;
}

const ContentActionShare: React.FC<IContentActionShare> = ({
  isShared,
  onClick,
  contentTitle,
  showText = false,
  wideMode = false,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const className = cx('content__action', 'content__action--share', {
    'content__action--shared': isShared,
    'content__action--active': isShared,
  });

  const buttonElement = useRef<HTMLButtonElement>(null);

  const displayText = showText || wideMode;

  const actionLabel = t('content.share_action', { title: contentTitle });

  const WrappedButton = () => (
    <Tooltip aria-hidden text={t('content.share')}>
      <Button
        theme={'icon-only'}
        size="32"
        iconComponent={<ShareIcon />}
        ref={buttonElement}
        onClick={(event) => {
          onClick && onClick(event);
        }}
        aria-label={actionLabel}
      />
    </Tooltip>
  );

  return (
    <div className={className}>
      {displayText ? (
        <Button
          theme={displayText ? 'text' : 'icon-only'}
          size={displayText && 'compact'}
          iconComponent={<ShareIcon />}
          ref={buttonElement}
          onClick={(event) => {
            onClick && onClick(event);
          }}
          aria-label={actionLabel}
        >
          <span>{t('content.share')}</span>
        </Button>
      ) : (
        <WrappedButton />
      )}
    </div>
  );
};

type ContentActionShareWrapperStateProps = ReturnType<typeof mapStateToProps>;
type ContentActionShareWrapperDispatchProps = typeof mapDispatchToProps;
type ContentActionShareWrapperOwnProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
  showText?: boolean;
  wideMode?: boolean;
};

type ContentActionShareWrapperProps = ContentActionShareWrapperStateProps &
  ContentActionShareWrapperDispatchProps &
  ContentActionShareWrapperOwnProps;

const ContentActionShareWrapper = ({
  contentId,
  isShared,
  analyticsData,
  showText,
  addOverlay,
  wideMode,
}: ContentActionShareWrapperProps) => {
  const { setModalVisibility } = useContext(MoreActionsModalContext);

  const content = usePatronSelector((state) =>
    contentSelectors.getContentById(state, { contentId })
  );

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();

    setModalVisibility(false);
    addOverlay(ContentCardShareModalID, {
      shouldSnapToTop: false,
      contentId: content.id,
    });

    trackContentCardShare(content.id, analyticsData);
  };

  return (
    <ContentActionShare
      isShared={isShared}
      showText={showText}
      onClick={handleClick}
      contentTitle={content.title}
      wideMode={wideMode}
    />
  );
};

export { ContentActionShare };

const mapStateToProps = (
  state: RootPatronState,
  ownProps: ContentActionShareWrapperOwnProps
) => ({
  isShared: contentSelectors.getContentShared(state, {
    contentId: ownProps.contentId,
  }),
});

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentActionShareWrapper);
