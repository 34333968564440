import { uiOperations } from '../models/ui';
import store from './store';

import { ID as AuthenticationErrorModalID } from '../components/authentication-error-modal/authentication-error-modal';
import { createApiClient } from './base-api';

/** @deprecated Use the `v2` method on [`apiFactory`](./api-factory) instead */
const api = createApiClient();

// Redirect to login on any unauth'd response
api.interceptors.response.use(undefined, (err) => {
  if (
    err.response.status === 401 &&
    !err.response.config.ignoreUnauthedRedirect
  ) {
    console.error(err);
    store.dispatch(uiOperations.addOverlay(AuthenticationErrorModalID));
  }

  return Promise.reject(err);
});

export default api;
