import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionArticle,
  submissionIsSubmitting,
} from '../../../models/content-submission/atoms';

import { default as RichInput } from '../../../components/ui/rich-editor';

import { trackAddLinkAddLinkClick } from '../../../models/content-submission/analytics';

const trackingContext = {
  contentSection: 'body',
};

const BodyInput = ({
  dispatch, // ignore
  ...props
}) => {
  const [article, setArticle] = useRecoilState(submissionArticle);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();

  const handleChange = (content) => {
    setArticle(content);
  };

  const handleLinkClick = () => {
    trackAddLinkAddLinkClick();
  };

  return (
    <RichInput
      className="content-submission-input-rich"
      defaultValue={article || ''}
      placeholder={t('content_submission.field_article_placeholder')}
      disabled={isSubmitting}
      maxLength={10000}
      onLinkClick={handleLinkClick}
      onChange={handleChange}
      trackingContext={trackingContext}
      {...props}
    />
  );
};

export default BodyInput;
