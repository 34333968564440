import { useEffect } from 'react';
import MessageService from '../services/message';

const useMessageViewer = (messageId: string) => {
  useEffect(() => {
    if (messageId) {
      new MessageService(messageId).view();
    }
  }, [messageId]);
};

export default useMessageViewer;
