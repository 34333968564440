import React from 'react';

import Button from '../../../ui/button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uiOperations } from '../../../../models/ui';
import { trackContentCardFlag } from '../../../../models/content/analytics';
import { ID as ConfirmFlagContentModalID } from '../confirm-flag-content-modal/confirm-flag-content-modal';

type FlaggedPostButtonProps = {
  contentId: number;
  analyticsData: IAnalyticsData;
};

export const FlagPostAction = ({
  contentId,
  analyticsData,
}: FlaggedPostButtonProps) => {
  const dispatch = useDispatch();
  const openFlagContentConfirmationModal = () =>
    dispatch(
      uiOperations.displayOverlay(ConfirmFlagContentModalID, {
        contentId,
        analyticsData,
      })
    );
  const { t } = useTranslation(undefined, { useSuspense: false });
  const onClickFlagContent = () => {
    trackContentCardFlag(contentId, analyticsData);
    openFlagContentConfirmationModal();
  };
  return (
    <Button
      className="flag-danger"
      onClick={onClickFlagContent}
      theme="text"
      size="compact"
      aria-label={t('content.flagged.flag_post')}
      icon={'flag'}
    >
      <span>{t('content.flagged.flag_post')}</span>
    </Button>
  );
};
