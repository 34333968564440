import { ElementType, RefCallback } from 'react';
import cx from 'classnames';

import { languageDirection } from '../../../../lib/language-helper';
import {
  getContentLang,
  getContentFunctionalTitle,
} from '../../../../models/content/selectors';
import Tooltip from '../../../tooltip/tooltip';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import useIsTruncated from '../../../../common/use-is-truncated';
import i18n from '../../../../config/i18n';
import Text from '../../../ui/text';
import { contentSelectors } from '../../../../models/content';
import { usePlainTextMentions } from '../../../../common/use-plaintext-mentions';
import { DangerousHTMLWithMentionsAndAutolinks } from '../../../ui/mention-text';

type ContentTitleProps<T extends keyof HTMLElementTagNameMap> = {
  id: string;
  title: string;
  titleTag: T;
  maxLinebreaks: number;
  language: string;
  largeFont: boolean;
};

const ContentTitle = <T extends keyof HTMLElementTagNameMap>({
  id,
  title,
  titleTag,
  maxLinebreaks,
  language,
  largeFont,
}: ContentTitleProps<T>) => {
  const [, setRef, isTruncated] = useIsTruncated<HTMLElementTagNameMap[T]>();

  const hideTooltip = !isTruncated;
  const parsedTitle = usePlainTextMentions({
    text: title,
    disabled: hideTooltip,
  });

  return (
    <Tooltip text={parsedTitle} disabled={hideTooltip}>
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Text
          as={titleTag}
          id={id}
          dir={languageDirection(language)}
          className={cx('content__title', {
            'large-font': largeFont,
          })}
          style={{ WebkitLineClamp: maxLinebreaks }}
          ref={setRef as RefCallback<ElementType>}
          key={title}
        >
          <DangerousHTMLWithMentionsAndAutolinks text={title} />
        </Text>
      </div>
    </Tooltip>
  );
};

type ContentTitleWrapperProps = {
  id: string;
  contentId: number;
  titleTag: keyof HTMLElementTagNameMap;
  maxLinebreaks: number;
  largeFont?: boolean;
};

const ContentTitleWrapper = ({
  id,
  contentId,
  titleTag = 'div',
  maxLinebreaks,
  largeFont = false,
}: ContentTitleWrapperProps) => {
  const title = usePatronSelector((state) =>
    getContentFunctionalTitle(state, { contentId })
  );
  const language = usePatronSelector((state) =>
    getContentLang(state, { contentId })
  );
  const canTranslate = usePatronSelector((s) =>
    contentSelectors.getContentCanTranslate(s, { contentId })
  );

  if (!title) {
    maxLinebreaks += 1;
  } else {
    maxLinebreaks -= canTranslate ? 1 : 0;
  }

  return (
    <ContentTitle
      id={id}
      title={title || i18n.t('content.no_description')}
      titleTag={titleTag}
      maxLinebreaks={maxLinebreaks}
      language={language}
      largeFont={largeFont}
    />
  );
};

export default ContentTitleWrapper;
