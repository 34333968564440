import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePatronSelector } from './use-patron-selector';

type pathType = 'push' | 'replace';
/**
 * Returns a function that can be used to create a `LinkNavigation` object used by the shared UI components.
 */
export function useLinkNavigation() {
  const programPath = usePatronSelector(
    (state) => state.appConfig.program_path
  );
  const history = useHistory();

  const navigate = useCallback(
    (type: pathType = 'push') =>
      (newPath: string) => {
        const url = new URL(newPath, location.origin);

        const newPathIsCurrent =
          history.location.pathname === url.pathname &&
          history.location.search === url.search &&
          history.location.hash === url.hash;

        // using replace over a no-op since it will trigger a re-render, and that is the intuitive result of clicking on a link to the current page
        newPathIsCurrent ? history.replace(newPath) : history[type](newPath);
      },
    [history]
  );

  return useCallback(
    (path: string, type?: pathType) => {
      return {
        baseUrl: new URL(programPath, location.origin).href,
        pathname: path,
        navigate: navigate(type),
      };
    },
    [navigate, programPath]
  );
}
