import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLayout = styled.div`
  padding: 20px;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0px;
  background: white;
  color: black;
`;

const MoreLikeThisHeader: React.FC = () => {
  const { t } = useTranslation();

  return <StyledLayout>{t('content.related_content')}</StyledLayout>;
};

export default MoreLikeThisHeader;
