import React from 'react';
import { SelectInput } from '../../../components/ui';

const ProfileSelectInput = ({
  name,
  options = [],
  value,
  placeholder,
  className,
  disabled,
  onChange,
}) => {
  const newOptions = options.map(({ value, displayText }) => {
    return { value: value, label: displayText };
  });

  return (
    <SelectInput
      name={name}
      options={newOptions}
      value={value || ''}
      placeholder={placeholder}
      className={className}
      isDisabled={disabled}
      onChange={onChange}
    />
  );
};

export default ProfileSelectInput;
