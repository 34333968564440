const DISPLAY_OVERLAY = 'patron/ui/DISPLAY_OVERLAY';
const ADD_OVERLAY = 'patron/ui/ADD_OVERLAY';
const REMOVE_OVERLAY = 'patron/ui/REMOVE_OVERLAY';
const ADD_FLASH_MESSAGE = 'patron/ui/ADD_FLASH_MESSAGE';
const REMOVE_FLASH_MESSAGE = 'patron/ui/REMOVE_FLASH_MESSAGE';
const SET_TOAST_MESSAGE_SHIM = 'patron/ui/SET_TOAST_MESSAGE_SHIM'; //prefer useToastMessages hook, this is used to shim redux/recoil
const SET_ITEM = 'patron/ui/SET_ITEM';

export default {
  DISPLAY_OVERLAY,
  ADD_OVERLAY,
  REMOVE_OVERLAY,
  ADD_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
  SET_TOAST_MESSAGE_SHIM,
  SET_ITEM,
};
