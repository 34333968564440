import { datadogRum } from '@datadog/browser-rum';

if (
  import.meta.env.PROD &&
  import.meta.env.VITE_DATADOG_APP_ID &&
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    service: 'patron',
    env: import.meta.env.VITE_DATADOG_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.VITE_DATADOG_VERSION,
    sessionSampleRate: Number(import.meta.env.VITE_DATADOG_SAMPLE_RATE),
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackFrustrations: true,
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
  });
}
