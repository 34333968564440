import { Reducer } from 'redux';

export type BoxTokenData = {
  token: string;
  expiresAt: string;
};

enum ActionTypes {
  SET_TOKEN_DATA = 'patron/box-integration/SET_TOKEN_DATA',
}

export type BoxResourceType = 'file' | 'folder';
export type BoxResourceId = string;

export type BoxIntegrationReducerState = {
  tokenData?: Record<string, BoxTokenData>;
};

export type BoxIntegrationReducerActions = {
  type: ActionTypes.SET_TOKEN_DATA;
  resourceType: BoxResourceType;
  resourceId: BoxResourceId;
  payload: BoxTokenData;
};

export type BoxIntegrationReducer = Reducer<
  BoxIntegrationReducerState,
  BoxIntegrationReducerActions
>;

export default ActionTypes;
