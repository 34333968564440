import React from 'react';
import Parser from 'html-react-parser';
import { Button } from '../ui';

class EmojiPickerListItem extends React.PureComponent {
  render() {
    const { emoji, onClick } = this.props;

    return (
      <Button
        type="button"
        className="emoji-picker__list-item"
        onClick={() => onClick(emoji)}
      >
        {Parser(emoji)}
      </Button>
    );
  }
}

export default EmojiPickerListItem;
