import {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  KeyboardEventHandler,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui';
import IconButton from '../../../icon-button/icon-button';
import { Modal } from '../../../ui';
import './content-card-share-modal.scss';
import ContentService from '../../../../services/content';
import closeIcon from './images/close.svg';
import facebookIcon from './images/Facebook.svg';
import linkedinIcon from './images/Linkedin.svg';
import xIcon from './images/social-icon-x.svg';
import copyIcon from './images/copy.svg';
import useDocumentListeners from '../../../../common/use-document-listeners';
import { RootPatronState } from '../../../../common/use-patron-selector';
import {
  contentSelectors,
  contentOperations,
} from '../../../../models/content/index.js';
import { appConfigSelectors } from '../../../../models/app-config';
import { uiOperations } from '../../../../models/ui';
import { useHistory } from 'react-router';
import { usePushToastOrFlashMessage } from '../../toaster/deprecation-helper';

export const ID = 'Overlays.CONTENT_CARD_SHARE_MODAL';

type CommonEventHandler = (event: Pick<MouseEvent, 'preventDefault'>) => void;

type ContentCardShareModalProps = {
  contentUrl?: string;
  onFacebookClick?: () => void;
  onLinkedinClick?: () => void;
  onTwitterClick?: () => void;
  onCopy?: () => void;
  onCloseClick?: () => void;
  isLoading?: boolean;
  hasErrored?: boolean;
  removeOverlay: typeof uiOperations.removeOverlay;
};

function ContentCardShareModal({
  contentUrl,
  onFacebookClick,
  onLinkedinClick,
  onTwitterClick,
  onCopy,
  onCloseClick,
  isLoading = false,
  hasErrored = false,
  removeOverlay,
}: ContentCardShareModalProps) {
  const { t } = useTranslation();
  const contentLinkTextareaRef = useRef<HTMLTextAreaElement>(null);
  const [copyBtnText, setCopyBtnText] = useState(t('common.copy'));
  const [isMounted, setIsMounted] = useState(true);

  const shareLabel = (service: string) => {
    return `${service} ${t('content.share')}`;
  };

  const history = useHistory();
  history.listen(() => {
    removeOverlay({ id: ID, key: undefined });
  });

  const modalElementRef = useRef<HTMLDivElement>(null);
  const buttonElementRef = useRef<HTMLButtonElement>(null);

  const handleDocClick = (e: Event) => {
    e.stopPropagation();
    const targetInShare =
      modalElementRef.current !== null &&
      modalElementRef.current.contains(e.target as Node | null);

    const targetIsButton =
      buttonElementRef.current !== null &&
      buttonElementRef.current === e.target;

    if (!targetInShare || targetIsButton) {
      removeOverlay && removeOverlay({ id: ID, key: undefined });
    }
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useDocumentListeners('click', isMounted, handleDocClick);

  const unavailableMessage = isLoading ? (
    <p className="content-share__item">Loading..</p>
  ) : hasErrored ? (
    <p className="content-share__item">
      Unable to fetch sharing URLs. Please try again later.
    </p>
  ) : undefined;

  const createKeyPressHandler = (
    onClickHandler?: CommonEventHandler
  ): KeyboardEventHandler | undefined => {
    if (!onClickHandler) return undefined;

    return (event) => {
      if (['Enter', ' '].indexOf(event.key) !== -1) {
        onClickHandler(event);
      }
    };
  };

  const onCopyClick: CommonEventHandler = (event) => {
    event.preventDefault();
    const input = contentLinkTextareaRef.current;

    if (input) {
      input.select();
      document.execCommand('copy');

      onCopy?.();
      setCopyBtnText(t('common.copied'));
    }
  };

  return (
    <div className="share-modal" ref={modalElementRef}>
      <header className="share-modal__header">
        <span className="share-modal__label">Share</span>
        <Button
          ref={buttonElementRef}
          onClick={onCloseClick}
          onKeyPress={createKeyPressHandler(onCloseClick)}
        >
          <img src={closeIcon} alt="close" />
        </Button>
      </header>
      {unavailableMessage || (
        <>
          <div className="share-modal__share-buttons">
            <IconButton
              onClick={onFacebookClick}
              iconImg={facebookIcon}
              iconAlt="Facebook"
              iconLabel="Facebook"
              className="share-modal__share-button"
              aria-label={shareLabel('Facebook')}
            />
            <IconButton
              onClick={onLinkedinClick}
              iconImg={linkedinIcon}
              iconAlt="Linkedin"
              iconLabel="Linkedin"
              className="share-modal__share-button"
              aria-label={shareLabel('Linkedin')}
            />
            <IconButton
              onClick={onTwitterClick}
              iconImg={xIcon}
              iconAlt="X"
              iconLabel="X"
              className="share-modal__share-button"
              aria-label={shareLabel('X')}
            />
          </div>
          <hr className="share-modal__divider" />
          <textarea
            readOnly
            className="share-modal__share-link"
            value={contentUrl}
            ref={contentLinkTextareaRef}
          ></textarea>
          <div className={'share-modal__footer'}>
            <Button
              className="share-modal__copy-btn"
              onClick={onCopyClick}
              onKeyPress={createKeyPressHandler(onCopyClick)}
            >
              <img alt="copy" src={copyIcon} />
              <span className="copy-btn__label">{copyBtnText}</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

type Network = 'facebook' | 'x_social' | 'linked_in';

type OwnProps = {
  contentId: number;
  onCloseClick?: () => void;
  active?: boolean;
};

type ContentCardShareModalContainerProps = ReturnType<typeof mapStateToProps> &
  OwnProps;

export const ContentCardShareModalContainer = ({
  contentId,
  onCloseClick,
  active,
  title,
  summary,
  shareText,
  shareHashtag,
  channelIds,
  facebookKey,
}: ContentCardShareModalContainerProps) => {
  const dispatch = useDispatch();

  const [shareUrls, setShareUrls] = useState<Record<string, string>>({});
  const [hasErrored, setHasErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const shareWindow = useRef<Window | null>(null);
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const removeOverlay = useCallback(
    (...params: Parameters<typeof uiOperations.removeOverlay>) => {
      return dispatch(uiOperations.removeOverlay(...params));
    },
    [dispatch]
  );

  const contentService = useMemo(
    () => new ContentService(contentId),
    [contentId]
  );

  const setContentUrls = useCallback(async () => {
    try {
      const res = await contentService.fetchShareableUrl();
      const shareUrls = {
        x_social: res.data.trackable_links[0].uniquified_original_url,
        default: res.data.trackable_links[0].trackable_url,
      };
      setShareUrls(shareUrls);
      setIsLoading(false);
    } catch {
      setHasErrored(true);
      setIsLoading(false);
    }
  }, [contentService]);

  const getContentUrl = (network: string) => {
    const url = shareUrls[network] || shareUrls.default || '';
    return encodeURIComponent(url);
  };

  const contentTitle = () => {
    return encodeURIComponent(title || '');
  };

  const contentDescription = () => {
    return encodeURIComponent(summary || '');
  };

  const contentShareText = () => {
    return encodeURIComponent(shareText || '');
  };

  const contentHashtag = () => {
    return encodeURIComponent(shareHashtag || '');
  };

  const getShareUrl = (network: Network) => {
    if (network === 'facebook')
      return (
        'https://www.facebook.com/dialog/share' +
        `?app_id=${facebookKey}` +
        '&display=iframe' +
        `&href=${getContentUrl('facebook')}` +
        (shareHashtag ? `&hashtag=%23${contentHashtag()}` : '') +
        (shareText ? `&quote=${contentShareText()}` : '')
      );

    if (network === 'x_social')
      return (
        'https://x.com/intent/tweet' +
        `?url=${getContentUrl('x_social')}` +
        `&text=${contentShareText()}` +
        (shareHashtag ? `&hashtags=${contentHashtag()}` : '')
      );

    if (network === 'linked_in')
      return (
        'https://www.linkedin.com/shareArticle' +
        '?mini=true' +
        `&text=${contentShareText()}` +
        `&url=${getContentUrl('linked_in')}` +
        `&title=${contentTitle()}` +
        `&summary=${contentDescription()}`
      );
  };

  useEffect(() => {
    setContentUrls();
  }, [setContentUrls]);

  const openShareWindow = () => {
    const params =
      'menubar=no' +
      ', toolbar=no' +
      ', scrolling=yes' +
      ', scrollbars=yes' +
      ', width=600' +
      ', height=700' +
      ', left=' +
      (window.screen.width / 2 - 300) +
      ', top=' +
      (window.screen.height / 2 - 350);

    shareWindow.current = window.open('', '_blank', params);

    if (shareWindow.current) {
      const shareWindowEl = shareWindow.current.document.createElement('div');
      shareWindowEl.style.paddingTop = '320px';
      shareWindowEl.style.textAlign = 'center';
      shareWindowEl.appendChild(
        shareWindow.current.document.createTextNode('Loading...')
      );

      shareWindow.current.document.body.appendChild(shareWindowEl);
    }
  };

  const updateShareWindowUrl = (network: Network) => {
    const shareUrl = getShareUrl(network);

    if (shareWindow.current && shareUrl) {
      shareWindow.current.location = shareUrl;
    }
  };

  const submitShareIntent = async (network: Network | 'copy') => {
    try {
      await dispatch(
        contentOperations.shareContent({
          id: contentId,
          url: decodeURIComponent(getContentUrl(network)),
          network,
          channelId: channelIds[0],
        })
      );

      pushMessage({
        text: t('flash_messages.thank_you_for_sharing'),
        type: 'success',
      });
    } catch (err) {
      pushMessage({
        text: t('errors.default'),
        type: 'error',
      });
    }
  };

  const shareAndCreateIntent = (network: Network) => {
    openShareWindow();
    updateShareWindowUrl(network);
    submitShareIntent(network);
  };

  const onCopy = () => {
    submitShareIntent('copy');
  };

  if (!active) return null;

  return (
    <ContentCardShareModal
      removeOverlay={removeOverlay}
      isLoading={isLoading}
      hasErrored={hasErrored}
      onCloseClick={() => {
        removeOverlay({ id: ID, key: undefined });
        onCloseClick?.();
      }}
      contentUrl={shareUrls.default}
      onFacebookClick={() => {
        shareAndCreateIntent('facebook');
      }}
      onTwitterClick={() => {
        shareAndCreateIntent('x_social');
      }}
      onLinkedinClick={() => {
        shareAndCreateIntent('linked_in');
      }}
      onCopy={onCopy}
    />
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  title: contentSelectors.getContentTitle(state, ownProps),
  summary: contentSelectors.getContentSummary(state, ownProps),
  shareText: contentSelectors.getContentShareText(state, ownProps),
  shareHashtag: contentSelectors.getContentShareHashtag(state, ownProps),
  channelIds: contentSelectors.getContentChannelIds(state, ownProps),
  facebookKey: appConfigSelectors.getFacebookKey(state),
});

export const ContentCardShareModalWrapper = connect(mapStateToProps)(
  ContentCardShareModalContainer
);

export const ContentCardShareModalOverlay = ({
  contentId,
}: Pick<OwnProps, 'contentId'>) => {
  return (
    <Modal transparent={false} lockScroll={true}>
      <ContentCardShareModalWrapper contentId={contentId} active={true} />
    </Modal>
  );
};
