import { useTranslation } from 'react-i18next';
import { SearchType } from './search-screen';

export function useLocalizedSearchType(searchType: SearchType) {
  const { t } = useTranslation();
  const localeKey = `search.types.${searchType}`;

  return t(localeKey);
}

/**
 * Returns a localized message for screen readers when the search results are loading.
 */
export function useLocalizedScreenReaderLoadingMessage(searchType: SearchType) {
  const type = useLocalizedSearchType(searchType);
  const { t } = useTranslation();
  return t('search.loading', { type });
}

/**
 * Formats a number using the current locale.
 *
 * @param num - The number to be formatted.
 * @returns The formatted number as a string.
 */
export function useFormattedNumber(num: number, digits = 0): string {
  const { i18n } = useTranslation();

  const formattedNum = Intl.NumberFormat(i18n.language, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: digits,
  }).format(num);

  return formattedNum;
}

/**
 * Localizes date for the user. Returns emtpy string if the date is invalid.
 *
 * @param date - The date to be formatted.
 */
export function useLocalDateTime(date: string) {
  const { i18n } = useTranslation();

  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };

  return parsedDate.toLocaleDateString(i18n.language, options);
}
