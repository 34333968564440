const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
};

const getRGB = (input) => {
  let sanitizedInput = new RegExp(/^#?([\da-fA-F]{3})$/).exec(input);
  // Find RGB Values
  if (!sanitizedInput) {
    sanitizedInput = new RegExp(/^#?([\da-fA-F]{6})$/).exec(input);
  }

  let color, r, g, b;

  if (!sanitizedInput) {
    return 'blue';
  } else if (sanitizedInput[1].length === 3) {
    color = sanitizedInput[1];
    r = color[0];
    g = color[1];
    b = color[2];
  } else {
    color = sanitizedInput[1];
    r = color.substr(0, 2);
    g = color.substr(2, 2);
    b = color.substr(4, 2);
  }

  let red = parseInt(r, 16);
  let green = parseInt(g, 16);
  let blue = parseInt(b, 16);

  return {
    red: red,
    green: green,
    blue: blue,
  };
};

const getBrightness = (color) => {
  let { red, green, blue } = color;

  let sR = red / 255;
  let sG = green / 255;
  let sB = blue / 255;

  let sRed = sR <= 0.03928 ? sR / 12.92 : Math.pow((sR + 0.055) / 1.055, 2.4);
  let sGreen = sG <= 0.03928 ? sG / 12.92 : Math.pow((sG + 0.055) / 1.055, 2.4);
  let sBlue = sB <= 0.03928 ? sB / 12.92 : Math.pow((sB + 0.055) / 1.055, 2.4);

  return 0.2126 * sRed + 0.7152 * sGreen + 0.0722 * sBlue;
};

const getIsBright = (color) => {
  let { red, green, blue } = color;

  let hsp = Math.sqrt(
    0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue)
  );

  return hsp > 127;
};

const getContrastingBrightness = (inputBrightness, isBright = false) => {
  const COMPLIANT_RATIO = 7.5;

  // Contrast Ratio = (bl + 0.05)/(dl + 0.05)
  // Dark Luminance = bl/(CR + 0.05CR - 0.05)
  // Bright Luminance = dlCR + 0.05CR - 0.05

  let outputBrightness = isBright
    ? // if bright, find dark
      (inputBrightness + 0.05) / COMPLIANT_RATIO - 0.05
    : // if dark, find bright
      COMPLIANT_RATIO * (inputBrightness + 0.05) - 0.05;

  return clamp(outputBrightness, 0, 1);
};

const getContrastRatio = (brightnesses) => {
  let light = Math.max(...brightnesses);
  let dark = Math.min(...brightnesses);

  return (light + 0.05) / (dark + 0.05);
};
// Input should be a string like "#FFFFFF"
export const generateContrastingColor = (input) => {
  let inputColor = getRGB(input);

  // Calculate Brightness
  let inputBrightness = getBrightness(inputColor);
  let outputBrightness = getContrastingBrightness(
    inputBrightness,
    getIsBright(inputColor)
  );

  // Calculate Complementary Fallback Color
  let cRed = clamp(Math.floor((255 * outputBrightness) / 0.2126), 0, 255);
  let cGreen = clamp(Math.floor((255 * outputBrightness) / 0.7152), 0, 255);
  let cBlue = clamp(Math.floor((255 * outputBrightness) / 0.0722), 0, 255);

  // Pray
  let cR = cRed.toString(16).padStart(2, '0');
  let cG = cGreen.toString(16).padStart(2, '0');
  let cB = cBlue.toString(16).padStart(2, '0');

  let finalColor = `#${cR}${cG}${cB}`;

  return finalColor;
};
