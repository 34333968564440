import { Reducer } from 'react';
import { Comment, CommentWithReplies } from '../types';
import * as operations from './operations';

export const actionTypes = {
  SET_COMMENTS: 'SET_COMMENTS' as const,
  APPEND_COMMENTS: 'APPEND_COMMENTS' as const,
  ADD_COMMENT: 'ADD_COMMENT' as const,
  REMOVE_COMMENT: 'REMOVE_COMMENT' as const,
  REMOVE_REPLY: 'REMOVE_REPLY' as const,
  UPDATE_COMMENT: 'UPDATE_COMMENT' as const,
  UPDATE_COMMENTS: 'UPDATE_COMMENTS' as const,
  UPDATE_COMMENT_DATA: 'UPDATE_COMMENT_DATA' as const,
  UPDATE_REPLY: 'UPDATE_REPLY' as const,
};

type Action =
  | { type: typeof actionTypes.SET_COMMENTS; payload: { comments: Comment[] } }
  | {
      type: typeof actionTypes.APPEND_COMMENTS;
      payload: { comments: Comment[] };
    }
  | {
      type: typeof actionTypes.ADD_COMMENT;
      payload: Parameters<typeof operations.addComment>[1];
    }
  | {
      type: typeof actionTypes.REMOVE_COMMENT;
      payload: Parameters<typeof operations.removeComment>[1];
    }
  | {
      type: typeof actionTypes.REMOVE_REPLY;
      payload: Parameters<typeof operations.removeReply>[1];
    }
  | {
      type: typeof actionTypes.UPDATE_COMMENT;
      payload: Parameters<typeof operations.updateComment>[1];
    }
  | {
      type: typeof actionTypes.UPDATE_COMMENTS;
      payload: { comments: Partial<Comment>[] };
    }
  | {
      type: typeof actionTypes.UPDATE_REPLY;
      payload: Parameters<typeof operations.updateReply>[1];
    };

export const commentFeedReducer: Reducer<
  (Comment | CommentWithReplies)[],
  Action
> = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_COMMENTS:
      return action.payload.comments;
    case actionTypes.APPEND_COMMENTS:
      return [...state, ...action.payload.comments];
    case actionTypes.ADD_COMMENT:
      return operations.addComment(state, action.payload);
    case actionTypes.REMOVE_COMMENT:
      return operations.removeComment(state, action.payload);
    case actionTypes.REMOVE_REPLY:
      return operations.removeReply(state, action.payload);
    case actionTypes.UPDATE_COMMENT:
      return operations.updateComment(state, action.payload);
    case actionTypes.UPDATE_COMMENTS:
      return action.payload.comments.reduce(
        (acc, comment) => operations.updateComment(acc, { comment }),
        [...state]
      );
    case actionTypes.UPDATE_REPLY:
      return operations.updateReply(state, action.payload);
    default:
      console.error(`Invalid action type called: ${action}`);
      return state;
  }
};
