import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { CommentAssistantSuggestionSelection } from '../models/comments/types';
import {
  createSuggestionRequest,
  getSuggestionOutput,
} from '../services/comment-suggestions';

const useCommentSuggestions = (pollingInterval = 3000) => {
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [suggestion, setSuggestion] = useState('');

  const [outputId, setOutputId] = useState<string>();

  useSWR(
    ['comments/suggestions', outputId],
    () => (outputId ? getSuggestionOutput(outputId) : undefined),
    {
      refreshInterval: outputId ? pollingInterval : undefined,
      errorRetryCount: 3,
      onSuccess: (output) => {
        if (!output) return;

        if (output.status === 'complete') {
          setSuggestion(output.value);
          setOutputId(undefined);
          setIsFetching(false);
        }
      },
      onError: () => {
        setErrorMessage(t('comments.comment_assistant.error'));
      },
    }
  );

  const { t } = useTranslation();

  const requestSuggestion = useCallback(
    async ({
      comment,
      selectedSuggestion,
      contentId,
    }: {
      comment: string;
      selectedSuggestion: CommentAssistantSuggestionSelection | null;
      contentId: string;
    }) => {
      setIsFetching(true);
      setErrorMessage(undefined);
      setOutputId(undefined);

      if (selectedSuggestion) {
        try {
          const task = await createSuggestionRequest(
            comment,
            selectedSuggestion,
            contentId
          );

          // TODO: handle multiple outputs (not sure if we'll ever have more than one)
          setOutputId(task.outputs[0].id);
        } catch (err) {
          setErrorMessage(t('comments.comment_assistant.error'));
          setIsFetching(false);
        }
      }
    },
    [t]
  );

  return {
    errorMessage,
    isFetching,
    suggestion,
    requestSuggestion,
  };
};

export default useCommentSuggestions;
