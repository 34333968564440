import { useCallback } from 'react';
import { RootPatronState } from '../../../../../common/use-patron-selector';
import {
  Feature,
  getFeatureFlag,
} from '../../../../../models/features/features';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { channelOperations } from '../../../../../models/channels';
import {
  trackChannelFollow,
  trackChannelUnfollow,
} from '../../../../../models/channels/analytics';
import { getChannelById } from '../../../../../models/channels/selectors';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import useProgramTheme from '../../../../../common/use-program-theme';
import { useCssVariableOverride } from '@socialchorus/shared-ui-components';

const FollowButton = ({
  topicId,
  analyticsData,
  onFollowUnfollow,
}: {
  topicId: string;
  analyticsData: IAnalyticsData;
  onFollowUnfollow?: (following: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const followingText = newTopicsString
    ? 'screens.explore.following'
    : 'screens.explore.joined';
  const notFollowingText = newTopicsString
    ? 'screens.explore.follow'
    : 'screens.explore.join';
  const following =
    useSelector((state: RootPatronState) => getChannelById(state, topicId))
      ?.following || false;
  const icon = following ? 'done' : 'add';
  const variant = following ? 'outline' : 'primary';
  const label = following ? followingText : notFollowingText;
  const dispatch = useDispatch();
  const { themeColor: primaryColor, accentColor: secondaryColor } =
    useProgramTheme();

  useCssVariableOverride({
    primaryColor,
    secondaryColor,
  });

  const handleClick = useCallback(() => {
    if (following) {
      dispatch(channelOperations.unfollowChannel(topicId));
      trackChannelUnfollow(topicId, analyticsData);
    } else {
      dispatch(channelOperations.followChannel(topicId));
      trackChannelFollow(topicId, analyticsData);
    }

    if (onFollowUnfollow) {
      onFollowUnfollow(following);
    }
  }, [dispatch, topicId, analyticsData, following, onFollowUnfollow]);

  return (
    <Button
      variant={variant}
      size="compact"
      label={t(label)}
      leftIcon={<Icon>{icon}</Icon>}
      onClick={handleClick}
    />
  );
};

export default FollowButton;
