import { ComponentProps, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomSlugRoute } from '../../../common/use-custom-slug-route';
import { Link, Spinner } from '../../ui';
import CommentListItem from './comment-list-item';
import { CommentTranslationContext } from './translations/context';

type CommentsSingleThreadProps = ComponentProps<typeof CommentListItem> & {
  contentId: number;
  isFetching: boolean;
  hasDeepLinkedReply?: boolean;
};

const CommentsSingleThread = ({
  contentId,
  comment,
  context,
  isFetching,
  hasDeepLinkedReply,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
}: CommentsSingleThreadProps) => {
  const customSlug = useCustomSlugRoute();
  const { t } = useTranslation();

  const commentsRoute = `/contents/${customSlug || contentId}#comments`;
  const viewAllRepliesRoute = `/contents/${customSlug || contentId}/comments/${
    comment.id
  }/replies`;

  return (
    <div className="comments__main comments__single">
      {!isFetching ? (
        <>
          <div className="comment__list">
            <CommentListItem
              hideReplies={hasDeepLinkedReply}
              comment={comment}
              context={context}
              onReply={onReply}
              onEdit={onEdit}
              onDelete={onDelete}
              onReport={onReport}
              onReplies={onReplies}
              onHighlight={onHighlight}
            />
          </div>

          {hasDeepLinkedReply && (
            <div className="comment__replies">
              <div className="comment__replies-body">
                {'replies' in comment &&
                  comment.replies.map((reply) => (
                    <CommentListItem
                      comment={reply}
                      context={context}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      onReport={onReport}
                      key={reply.id}
                    />
                  ))}
                {comment.replyCount > 1 && (
                  <Link
                    className="comment__replies-toggle"
                    action="push"
                    href={viewAllRepliesRoute}
                  >
                    {t('comments.view_all_replies')}
                  </Link>
                )}
              </div>
            </div>
          )}

          <Link href={commentsRoute} action="push" className="view-all">
            {t('comments.view_all_comments')}
          </Link>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CommentsSingleThread;
