const SET = 'patron/advocate/SET';
const SET_ENTITLEMENTS = 'patron/advocate/SET_ENTITLEMENTS';

type Avatar = {
  url: string | null;
  color: string;
};

export type User = {
  id: string;
  name: string;
  display_name: string;
  job_title: string | null;
  avatar: Avatar;
  search_attributes: Array<string>;
};

type AvatarWithInitials = Avatar & {
  initials: string;
};

export type AdvocateProfile = {
  id: number;
  avatar: AvatarWithInitials;
  name: string;
  privateProfile: boolean;
  pronouns: string | null;
  profileFields: Array<{
    label: string;
    name: string;
    value: string;
    type: string;
  }>;
  federatedIdentifier: unknown;
  publishingConfig: {
    create_content: Array<unknown>;
    edit_content: Array<string>;
    contributor_channels: Array<number>;
    content_settings: Array<string>;
  };
  role: string;
  hasElevatedRole: boolean;
  email: string;
  hasFallbackNameValue: boolean;
  statusMessage: string | null;
  orgChartEnabled: boolean;
  entitlements?: Array<unknown>;
};

export type AdvocateGroup = {
  label: string;
  name: string;
  type: string;
  iconUrl: string;
  fields: Array<{
    label: string;
    name: string;
    value: string;
    type: string;
    displayValue: string | null;
  }>;
};

export type AdvocateProfileResponse = {
  data: {
    profile: AdvocateProfile;
    groups: Array<AdvocateGroup>;
  };
};

export type AdvocateSuggestionsSearchResponse = {
  users: Array<User & Record<string, unknown>>;
};

type ContentChannels = {
  id: string;
  name: string;
  description: string;
  background_image_url: string | null;
  cropped_background_image: string | null;
  uploaded_image: {
    width: number | null;
    height: number | null;
    format: string | null;
    filesize: number | null;
    url: string;
  } | null;
  cropped_uploaded_image: {
    width: number | null;
    height: number | null;
    format: string | null;
    filesize: number | null;
    url: string;
  } | null;
  last_published_at: string | null;
  following: boolean;
  default: boolean;
  follower_count: number;
  feed_filters: Array<unknown>;
  can_publish_as_owner: boolean;
  can_submit: boolean;
  last_content_published_at: string | null;
  background_color: string;
  auto_publish: boolean;
  is_recommended: boolean;
  created_at: string;
};

export type AdvocateChannels = {
  content_channels: Array<ContentChannels>;
};

export type AdvocateProfileEditDataResponse = {
  data: {
    profile: AdvocateProfile;
    groups: Array<{
      label: string;
      name: string;
      type: string;
      fields: Array<{
        label: string;
        name: string;
        value: string;
        displayText?: string;
        type: string;
        options: Array<{
          value: string;
          displayText: string;
        }> | null;
        placeholder?: string;
        disabled: boolean;
        required: boolean;
        displayValue?: string;
        helpText?: string;
      }>;
      iconUrl: string;
    }>;
    action: {
      endpoint: string;
      method: string;
    };
  };
};

export type AdvocateOrganizationResponse = {
  data: Array<{
    id: number;
    avatar: AvatarWithInitials;
    name: string;
    jobTitle: string;
    currentUser: boolean;
    level: number;
  }>;
  links: {
    self: string;
  };
};

export type SearchedProfile = Pick<
  User,
  'name' | 'job_title' | 'avatar' | 'display_name'
> & {
  user_id: number;
  email: string | null;
  first_name: string;
  last_name: string;
  postal_code: string | null;
  federated_identifier: string | null;
  phone_number: string | null;
  preferred_name: string | null;
  work_location: string | null;
  start_date: string | null;
  birthdate: string | null;
  private_profile: boolean;
  username: string | null;
  role: string;
  publishing_config: Record<string, unknown>;
};

export type ProfilesSearchResponse = {
  profiles: Array<SearchedProfile>;
  meta: {
    page: {
      size: number;
      number: number;
      total: number;
    };
    total: number;
  };
};

export type UpdateProfileResponse = {
  data: {
    profile: Pick<
      AdvocateProfile,
      | 'avatar'
      | 'email'
      | 'federatedIdentifier'
      | 'hasFallbackNameValue'
      | 'id'
      | 'name'
      | 'privateProfile'
      | 'profileFields'
      | 'pronouns'
      | 'publishingConfig'
      | 'role'
    >;
    groups: Array<unknown>;
  };
};

export type UpdateAdvocateProfilePayload = {
  preferred_name: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_phone: string;
  phone_number: string;
  timezone: string;
  display_name: string;
  status_message: string;
  pronouns: string;
  preferred_language: string;
  birthdate: string;
};

export type UpdateAdvocatePasswordResponse = {
  message: string;
  data: Record<
    | 'session_id'
    | 'advocate_id'
    | 'brand_id'
    | 'program_id'
    | 'scope'
    | 'service',
    string | null
  >;
};

export type UpdateAdvocateAvatar = Record<'avatar_url', string>;

export default {
  SET,
  SET_ENTITLEMENTS,
};
