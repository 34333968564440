import React from 'react';
import './skip-to-content.scss';
import useSkipToContent from '../../common/use-skip-to-content';

type SkipToContentProps = {
  children?: React.ReactNode;
  /**
   * An alternative ID for element. If used, the same value must be
   * provided to the `id` prop on the element.
   */
  targetId?: string;
};
export function SkipToContentLink({
  targetId,
  children = 'Skip to content',
}: SkipToContentProps) {
  const { targetId: id, skipToContent } = useSkipToContent(targetId);

  return (
    <a href={`#${id}`} className="skip-to-content-link" onClick={skipToContent}>
      {children}
    </a>
  );
}

export { DEFAULT_SKIP_TO_CONTENT_ID } from '../../common/use-skip-to-content';
