/**
 * Patron route -> Mobile supported deeplink
 * Matcher will use first match found, so order paths appropriately
 *
 * @type {Record<string, string>}
 */
const deepLinkRedirectMap = {
  '/search/contents/:term': '/search::term',
  '/search/:type/:term': '/search/:type::term',
  '/search/**': '/search',

  '/feed/resources': '/resources',
  '/feed/**': '/', // Feed catch-all

  '/assistant/todos': '/todos',
  '/assistant/todos/:id': '/todos/:id',
  '/assistant/integrations/:id': '/service/:id',
  '/assistant/integrations/:id/command/:command':
    '/service/:id/command/:command',
  '/assistant/service/:id': '/service/:id',
  '/assistant/service/:id/command/:command': '/service/:id/command/:command',
  '/assistant/notifications': '/notifications',
  '/assistant/**': '/assistant', // Assistant catch-all

  '/profiles/:id': '/profile/:id',
  '/profiles/:id/view': '/profile/:id',
  '/account/bookmarks': '/bookmarks',
  '/account/**': '/profile',

  '/discover': '/explore',
  '/channels/:id': '/explore/:id',

  '/submit/**': '/submit',

  '/approvals/:id': '/approval/:id',

  // Note, do *not* use wildcard /contents unless you absolutely need to.
  // If you do need to, also be sure to implement comment and reply route maps
  '/contents/:id/info': '/contents/:id',
  '/contents/:id/edit': '/contents/:id',
  '/contents/:id': '/contents/:id',

  // These are mobile deeplinks with no current patron equivalent
  // 'approval/51111766'
  // 'services/6409/command/this_is_the_command'
  // 'login' (will go to home page)
  // 'activities'
  // 'orgmenu'
  // 'highlights'
};

// Calculates the deeplink based on the patron path and adds the protocol, program and org_slug so that
// the url is ready to be used
const EXIT_DEEPLINK = 'sc://exitview';

module.exports = {
  deepLinkRedirectMap,
  EXIT_DEEPLINK,
};
