import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { MessageResponse } from '../models/messages/types';
import MessageService from '../services/message';
import { messageById } from '../models/messages/recoil-state';

type UseMessageFetcherProps = {
  messageId: string;
};

const useMessageFetcher = ({ messageId }: UseMessageFetcherProps) => {
  const [message, setMessage] = useRecoilState(messageById(messageId));

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const handleFetchSuccess = useCallback(
    (res: AxiosResponse<MessageResponse>) => {
      const message = res.data.data.attributes;

      setMessage(message);
      setIsFetching(false);
    },
    [setMessage]
  );

  const handleFetchFailure = useCallback(() => {
    setIsFetching(false);
  }, []);

  const fetchMessage = useCallback(async () => {
    setIsFetching(true);

    try {
      const res = await new MessageService(messageId).fetch();
      handleFetchSuccess(res);
    } catch (err) {
      if (_.get(err, 'response')) {
        handleFetchFailure();
      } else {
        throw err;
      }
    }
  }, [handleFetchFailure, handleFetchSuccess, messageId]);

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);

  return {
    message,
    isFetching,
  };
};

export default useMessageFetcher;
