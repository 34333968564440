import { MouseEventHandler } from 'react';
import { Link } from '../ui';
import { Comment } from '../../models/comments/types';

type CommentAuthorName = {
  author: Comment['author'];
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const CommentAuthorName = ({ author, onClick }: CommentAuthorName) => {
  const profilePath = `/profiles/${author.id}`;

  const name = author.name;

  const linkedName = (
    <Link href={profilePath} action="push" onClick={onClick}>
      {name}
    </Link>
  );

  return <div className="author-name">{author.id ? linkedName : name}</div>;
};

export default CommentAuthorName;
