import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LocationDescriptorObject } from 'history';
import { RouteComponentProps } from 'react-router';
import ReactFocusLock from 'react-focus-lock';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  TabsRootContainer,
  TabsList,
  Tag,
  TooltipRoot,
  TooltipContent,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import { Curtain } from '../../components/ui';
import { usePatronSelector } from '../../common/use-patron-selector';
import { uiSelectors } from '../../models/ui';
import { setPageScroll } from '../../patron-routes';
import { NotificationFeed } from './notification-feed';
import { Toolbar } from './toolbar';
import { useRecoilValue } from 'recoil';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import styles from './notification-center.module.scss';
import { NotificationCenterTabs } from '../../models/notifications/types';
import {
  FilterTags,
  mapFilterTagToIcon,
  mapFilterTagsToVariant,
} from './filter-dropdown';
import { NotificationContext } from './context';

const ANIMATION_DURATION = 200; //needs to match $animation-duration in notification-center.scss

export type NotificationCenterProps = RouteComponentProps & {
  goBack: LocationDescriptorObject | null; //used to give us a location to go back to when the notification center is closed (might not be able to rely on history.goBack() if its the first route)
};

const mapTabToLabel = (tab: NotificationCenterTabs) => {
  return {
    [NotificationCenterTabs.MESSAGES]: 'notification_center.messages',
    [NotificationCenterTabs.ACTIVITY]: 'notification_center.activity',
    [NotificationCenterTabs.ARCHIVE]: 'notification_center.archive',
  }[tab];
};

const CountTag: React.FC<{
  count?: number;
  tab: NotificationCenterTabs;
  type: FilterTags;
  container: HTMLDivElement | null | undefined;
}> = ({ count, tab, type, container }) => {
  const { t } = useTranslation();

  const {
    activeTab: [activeTab],
    filterTags: [, setFilterTags],
  } = useContext(NotificationContext);

  const active = activeTab === tab;

  const handleTabTagClick = useCallback(
    (tag: FilterTags) =>
      active
        ? (e: MouseEvent) => {
            e.stopPropagation();
            setFilterTags([tag]);
          }
        : undefined,
    [active, setFilterTags]
  );

  if (!count) return null;

  const tag = (
    <Tag
      variant={mapFilterTagsToVariant(type)}
      icon={mapFilterTagToIcon({
        tag: type,
        compact: true,
      })}
      label={`${count >= 10 ? '9+' : count}`}
      compact
      onClick={handleTabTagClick(type)}
    />
  );

  //there seems to be an issue with disabling tooltips with an asChild TooltipTrigger when combined with Tag, so we just return the tag if the tab is not active.
  return active ? (
    <TooltipRoot>
      <TooltipContent
        description={t(`notification_center.tags.${type}`)}
        sideOffset={5}
        container={container}
      />
      <TooltipTrigger asChild>{tag}</TooltipTrigger>
    </TooltipRoot>
  ) : (
    tag
  );
};

export const NotificationCenterRoot: React.FC<NotificationCenterProps> = ({
  history,
  goBack,
}) => {
  const { t } = useTranslation();

  const {
    activeTab: [activeTab, setActiveTab],
    tabs: [tabs],
  } = useContext(NotificationContext);
  const counts = useRecoilValue(notificationCountsState);
  const [body, setBody] = useState<HTMLDivElement | null>();
  const [closing, setClosing] = useState(false);

  const overlayIsActive = usePatronSelector((state) =>
    uiSelectors.getOverlayIsActive(state)
  );

  const close = useCallback(() => {
    goBack?.pathname ? history.replace(goBack?.pathname) : history.goBack();
  }, [history, goBack?.pathname]);

  const handleCloseClick = useCallback(() => {
    setClosing(true);

    //wait for the animation to finish
    setTimeout(() => {
      close();
    }, ANIMATION_DURATION);
  }, [close]);

  useEffect(() => {
    if (closing) {
      //trigger the replacement of the global scroll bar before the close animation finishes to avoid a flicker on completion.
      setPageScroll(true);
    }
  }, [closing]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTab(tab as NotificationCenterTabs);
    },
    [setActiveTab]
  );

  return (
    <>
      <Curtain
        onClick={handleCloseClick}
        className="notification-center"
        enabled={!closing}
      />
      <ReactFocusLock disabled={!!overlayIsActive}>
        <TabsRootContainer value={activeTab} onValueChange={onTabChange}>
          <div
            ref={(el) => setBody(el)}
            className={cx(styles.notificationCenter, closing && styles.closing)}
          >
            <div>
              <div className={styles.header}>
                <div
                  className={cx(
                    'font-semibold text-tertiary-heading',
                    styles.title
                  )}
                >
                  {t('notification_center.title')}
                </div>
                <IconButton iconName="close" onClick={handleCloseClick} />
              </div>
              <hr className="bg-black-10" />
              <Toolbar body={body} />
              <TabsList
                className={styles.tabs}
                width="full"
                supportInteractiveTabs
                tabs={tabs.map((tab) => {
                  return {
                    label: (
                      <div className={styles.labelContainer}>
                        <span>{t(mapTabToLabel(tab))}</span>
                        {tab === NotificationCenterTabs.MESSAGES && (
                          <CountTag
                            count={counts[tab]?.action_required}
                            tab={tab}
                            type={FilterTags.ACTION_REQUIRED}
                            container={body}
                          />
                        )}
                        {tab === NotificationCenterTabs.MESSAGES && (
                          <CountTag
                            count={counts[tab]?.important}
                            tab={tab}
                            type={FilterTags.IMPORTANT}
                            container={body}
                          />
                        )}
                        <CountTag
                          count={counts[tab]?.unread}
                          tab={tab}
                          type={FilterTags.UNREAD}
                          container={body}
                        />
                      </div>
                    ),
                    value: tab,
                  };
                })}
              />
            </div>
            <div className={styles.feedContainer}>
              <NotificationFeed />
            </div>
          </div>
        </TabsRootContainer>
      </ReactFocusLock>
    </>
  );
};
