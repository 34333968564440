import api from '../config/api';
import { AttributesObject, ResourceObject } from '../lib/types/json-api';
import { CommentAssistantSuggestionSelection } from '../models/comments/types';

const getV3BaseUrl = () => api.defaults.baseURL?.replace('v2', 'v3');

type ApiResponseWrapper<
  N extends string,
  T extends AttributesObject
> = ResourceObject<N, T> & Required<Pick<ResourceObject<N, T>, 'attributes'>>;

type CommentSuggestionOutputBase = {
  id: string;
  name: string;
} & (
  | {
      status: 'pending';
      value: null;
    }
  | {
      status: 'complete';
      value: string;
    }
);

type CommentSuggestionTask = {
  id: string;
  agent_id: string;
  outputs: CommentSuggestionOutputBase[];
};

export async function createSuggestionRequest(
  text: string,
  selectedSuggestion: CommentAssistantSuggestionSelection,
  contentId: string
) {
  const data: {
    text: string;
    type: string;
    subtype?: string;
    content_id: string;
  } = {
    text,
    type: selectedSuggestion.suggestionType,
    content_id: contentId,
  };

  if (selectedSuggestion.suggestionType === 'change_tone') {
    data['subtype'] = selectedSuggestion.suggestionSubtype;
  }

  const res = await api.post<{
    data: ApiResponseWrapper<'suggestion_tasks', CommentSuggestionTask>;
  }>(`/comments/suggestions`, data, {
    baseURL: getV3BaseUrl(),
  });

  return res.data.data.attributes;
}

type CommentSuggestionOutput = CommentSuggestionOutputBase & {
  task_id: string;
};

export async function getSuggestionOutput(outputId: string) {
  const res = await api.get<{
    data: ApiResponseWrapper<'suggestion_outputs', CommentSuggestionOutput>;
  }>(`/comments/suggestions/${outputId}`, {
    baseURL: getV3BaseUrl(),
  });

  return res.data.data.attributes;
}
