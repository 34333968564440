import { CSSProperties, ElementType, MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { contentSelectors } from '../../models/content/index.js';
import { programSelectors } from '../../models/program';
import ContentLink from './content-link';
import './content.scss';
import { languageDirection } from '../../lib/language-helper';
import { RootPatronState } from '../../common/use-patron-selector';
import { DangerousHTMLWithMentionsAndAutolinks } from '../ui/mention-text';

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  contentId: number;
  ignoreLink?: boolean;
  titleTag?: ElementType;
  truncate?: number;
  style?: CSSProperties;
  analyticsData?: IAnalyticsData;
  onClick?: MouseEventHandler;
};

type ContentTitleProps = StateProps & OwnProps;

const ContentTitle = ({
  contentId,
  ignoreLink,
  title,
  titleTag = 'div',
  language,
  truncate,
  style,
  isPublished,
  isProcessing,
  analyticsData,
  onClick,
}: ContentTitleProps) => {
  const TitleTag = titleTag;
  return (
    <ContentLink
      contentId={contentId}
      ignoreLink={ignoreLink || !isPublished || isProcessing}
      analyticsData={analyticsData}
      onClick={onClick}
    >
      <TitleTag
        dir={languageDirection(language)}
        style={{ ...style, WebkitLineClamp: truncate }}
        className="content__title"
        key={title}
      >
        <DangerousHTMLWithMentionsAndAutolinks text={title} />
      </TitleTag>
    </ContentLink>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  title: contentSelectors.getContentTitleOrSummaryPlain(state, ownProps),
  language: contentSelectors.getContentLang(state, ownProps),
  isPublished: contentSelectors.getContentIsPublished(state, ownProps),
  isProcessing: contentSelectors.getContentIsProcessing(state, ownProps),
  contentSanitized: programSelectors.getSanitizedContentEnabled(state),
});

export default connect(mapStateToProps)(ContentTitle);
