import { CSSProperties, useEffect, useRef, useState } from 'react';
import { darken } from 'polished';
import { debounce } from 'lodash';
import thumbnailFill from './images/thumbnail-fill.svg';
import playIcon from './images/play-icon.svg';
import pollIcon from './images/poll-icon.svg';
import multiImageIcon from './images/multiimage-icon.svg';
import cx from 'classnames';
import './content-card-thumbnail.scss';
import { NoteIcon } from '../../../ui/icons_google';
import Tooltip from '../../../tooltip/tooltip';
import { useEventListener } from 'usehooks-ts';
import useElementSize from '../../../../common/use-element-size';

type ContentCardThumbnailProps = {
  style?: CSSProperties;
  imgSrc: string;
  label: string;
  labelIcon?: JSX.Element;
  imgSizing?: string;
  contentProperties?: {
    contentType: string;
    contentLength?: number;
    backgroundColor?: string;
  };
  aspectRatio?: string;
};

const ContentCardThumbnail = ({
  style,
  imgSrc,
  label,
  labelIcon,
  imgSizing = 'fill',
  contentProperties = {
    contentType: 'image',
  },
  aspectRatio = '16:9',
}: ContentCardThumbnailProps) => {
  const containerClassNames = 'thumbnail-container';
  const imgClassNames = cx('thumbnail__img', {
    fill: imgSizing === 'fill',
    fit: imgSizing === 'fit',
    cover: imgSizing === 'cover',
  });
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const defaultImgStyle = { width: '100%', height: '100%' };
  const contentTypeIcon: Record<string, string | undefined> = {
    note: undefined,
    image: undefined,
    video: playIcon,
    poll: pollIcon,
    'multi-image': multiImageIcon,
  };
  const iconImgSrc = contentTypeIcon[contentProperties.contentType];
  const iconDataDesc = contentProperties.contentLength;
  const iconLabel = iconImgSrc && (
    <div className="thumbnail__content_icon">
      <img
        className="thumbnail__content_icon_img"
        src={iconImgSrc}
        alt={`${contentProperties.contentType}`}
      />
      {iconDataDesc && (
        <span className="thumbnail__datadesc">{iconDataDesc}</span>
      )}
    </div>
  );
  const isNote = contentProperties.contentType === 'note';
  const [imageWidth, setImageWidth] = useState(0); // Note, stole constant from old UI code
  const canDisplayImage = imgSrc && imageWidth > 0;

  if (canDisplayImage) {
    if (imgSrc.match(/h_(\d+)/)) {
      imgSrc = imgSrc
        .replace(/w_(\d+)/, `w_${imageWidth}`)
        .replace(/h_(\d+)/, `ar_${aspectRatio},dpr_${window.devicePixelRatio}`);
    } else if (imgSrc.match(/ar_(\d+):(\d+)/)) {
      imgSrc = imgSrc
        .replace(/w_(\d+)/, `w_${imageWidth}`)
        .replace(
          /ar_(\d+):(\d+)/,
          `ar_${aspectRatio},dpr_${window.devicePixelRatio}`
        );
    } else {
      imgSrc = imgSrc.replace(
        /w_(\d+)/,
        `w_${imageWidth},ar_${aspectRatio},dpr_${window.devicePixelRatio}`
      );
    }
    // TODO: this is brittle if we didnt have a height or width, not sure if that's a realistic data-case though
  }

  const debouncedSetImageSrc = debounce(() => {
    const node = imageContainerRef.current;

    if (node) {
      const width = Math.ceil(node.offsetWidth / 10) * 10;
      if (width && width > imageWidth) {
        // only update if the image to be fetched is larger, we can scale down
        setImageWidth(width);
      }
    }
  }, 100);

  useEffect(() => {
    debouncedSetImageSrc();
  }, [debouncedSetImageSrc, setImageWidth]);

  useEventListener('resize', debouncedSetImageSrc);

  const ThumbnailComponent = () => {
    if (isNote) {
      return (
        <NoteThumbnail
          className={imgClassNames}
          bgColor={contentProperties.backgroundColor}
        />
      );
    } else {
      return (
        <img
          src={canDisplayImage ? imgSrc : thumbnailFill}
          className={imgClassNames}
          alt="thumbnail"
        />
      );
    }
  };

  return (
    <div style={{ ...defaultImgStyle, ...style }}>
      <div className={containerClassNames}>
        <div className="thumbnail" ref={imageContainerRef}>
          {label && <ThumbnailLabel label={label} icon={labelIcon} />}
          <ThumbnailComponent />
          {iconLabel}
        </div>
      </div>
    </div>
  );
};

type NoteThumbnailProps = {
  bgColor?: string;
  className?: string;
};

function ThumbnailLabel({
  label,
  icon,
}: {
  label: string;
  icon: JSX.Element | undefined;
}) {
  const [labelTextRef, size] = useElementSize();
  const isTextTruncated = size.width < size.scrollWidth;

  return (
    <Tooltip text={label} disabled={!isTextTruncated}>
      <div className="thumbnail__featured-label">
        {icon && icon}
        <span ref={labelTextRef} className="thumbnail__featured-label__text">
          {label}
        </span>
      </div>
    </Tooltip>
  );
}

const NoteThumbnail = ({
  bgColor = 'rgba(0, 0, 0, 0.05)',
  className = 'thumbnail__img',
}: NoteThumbnailProps) => {
  const containerClassName = cx(className, 'message-quote-container');
  return (
    <div
      style={{ backgroundColor: bgColor, color: darken(0.15, bgColor) }}
      className={containerClassName}
    >
      <NoteIcon />
    </div>
  );
};

export default ContentCardThumbnail;
