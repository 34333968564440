import withRerenderPrevent from '../../common/with-rerender-prevent';
import { getChannelById } from '../../models/channels/selectors';
import useScreenTitle from '../../common/use-screen-title';
import useContentFeed from '../../common/use-content-feed';
import Page, { PageMain, PageSidebar } from '../../components/ui/page';
import ChannelDetails from '../../components/channel-details';
import ChannelDetailsV2 from '../../components/v2/channel/channel-details/channel-details';
import Feed from '../../components/feed';
import NotFound from '../not-found';
import { Feature } from '../../models/features/features';
import { useParams } from 'react-router-dom';
import { usePatronSelector } from '../../common/use-patron-selector';
import useFeatureFlag from '../../common/use-feature-flag';

const ChannelFeed = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const channel = usePatronSelector((state) =>
    getChannelById(state, channelId)
  );
  const { name: title } = channel ?? {};

  useScreenTitle(title);

  const analyticsData = {
    content_channel_id: channelId,
    content_channel_name: title ?? '',
    location: 'channel_feed',
  };

  const { fetching, feedItems, page, canLoadMore, fetchFeed } = useContentFeed({
    channelId,
  });

  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);

  const ChannelDetailsComponent = newUIEnabled
    ? ChannelDetailsV2
    : ChannelDetails;

  return channel ? (
    <Page className="channel-feed">
      <PageSidebar>
        <ChannelDetailsComponent
          channelId={channelId}
          analyticsData={analyticsData}
        />
      </PageSidebar>

      <PageMain>
        <Feed
          items={feedItems}
          page={page}
          fetching={fetching}
          canLoadMore={canLoadMore}
          fetchFeed={fetchFeed}
          analyticsData={analyticsData}
          contentTitleTag="h2"
        />
      </PageMain>
    </Page>
  ) : (
    <NotFound />
  );
};

export default withRerenderPrevent(ChannelFeed);
