import { useState } from 'react';
import generateUniqueId from '../lib/random-id';

export default function useRandomId() {
  const [id, setId] = useState<string>(generateUniqueId);

  const regenerateId = () => {
    setId(generateUniqueId);
  };

  return {
    id,
    regenerateId,
  };
}
