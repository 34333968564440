import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '../../components/ui';

const ArchiveConfirmDialog = ({ onContinue, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('content_submission.archive_title')}
      accept={{
        text: t('content_submission.archive'),
        theme: 'danger',
        onClick: onContinue,
      }}
      cancel={{
        text: t('common.cancel'),
        theme: 'link',
        onClick: onCancel,
      }}
    >
      {t('content_submission.archive_confirm')}
    </Dialog>
  );
};

export const ID = 'Overlays.SUBMISSION_ARCHIVE_CONFIRM_DIALOG';

export default ArchiveConfirmDialog;
