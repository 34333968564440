import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { channelSelectors } from '../../models/channels';
import { programSelectors } from '../../models/program';
import withRerenderPrevent from '../../common/with-rerender-prevent';
import ChannelContent from '../channel-content';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { HomeSection } from '../v2/home-section';

const ChannelContentFilterV1 = ({
  title,
  channelId,
  analyticsData,
  searchBarInHeaderEnabled,
}) => {
  const classNames = cx('container', {
    'container--medium': searchBarInHeaderEnabled,
  });
  return (
    <div className={classNames}>
      <div className="section-heading">
        <h2 className="section-title">{title}</h2>
      </div>

      <ChannelContent channelId={channelId} analyticsData={analyticsData} />
    </div>
  );
};

const ChannelContentFilters = ({
  randomize,
  channelIdFirstUniqRecommended,
  channelIdFirstUniqNewest,
  channelIdFirstUniqPopular,
  channelIdRandUniqRecommended,
  channelIdRandUniqNewest,
  channelIdRandUniqPopular,
  analyticsData,
  searchBarInHeaderEnabled,
}) => {
  const recommendedChannelId = randomize
    ? channelIdRandUniqRecommended
    : channelIdFirstUniqRecommended;
  const newestChannelId = randomize
    ? channelIdRandUniqNewest
    : channelIdFirstUniqNewest;
  const popularChannelId = randomize
    ? channelIdRandUniqPopular
    : channelIdFirstUniqPopular;

  const { t } = useTranslation();

  const analyticsDataFor = (filter) => ({
    ...analyticsData,
    location: `${analyticsData.location}_channel_${filter}`,
  });

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );

  const recommendedChannelString = newTopicsString
    ? 'landing.recommended_topic'
    : 'landing.recommended_channel';
  const newestChannelString = newTopicsString
    ? 'landing.newest_topic'
    : 'landing.newest_channel';
  const mostPopularChannelString = newTopicsString
    ? 'landing.most_popular_topic'
    : 'landing.most_popular_channel';

  const ChannelContentFilter = newUIEnabled
    ? HomeSection
    : ChannelContentFilterV1;

  return (
    <div
      className={cx('channels-content-filters', {
        'container container--medium': newUIEnabled,
      })}
    >
      {recommendedChannelId ? (
        <ChannelContentFilter
          title={t(recommendedChannelString)}
          channelId={recommendedChannelId}
          searchBarInHeaderEnabled={searchBarInHeaderEnabled}
          analyticsData={analyticsDataFor('recommended')}
        />
      ) : null}

      {newestChannelId ? (
        <ChannelContentFilter
          title={t(newestChannelString)}
          channelId={newestChannelId}
          searchBarInHeaderEnabled={searchBarInHeaderEnabled}
          analyticsData={analyticsDataFor('newest')}
        />
      ) : null}

      {popularChannelId ? (
        <ChannelContentFilter
          title={t(mostPopularChannelString)}
          channelId={popularChannelId}
          searchBarInHeaderEnabled={searchBarInHeaderEnabled}
          analyticsData={analyticsDataFor('popular')}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  channelIdFirstUniqRecommended:
    channelSelectors.getChannelIdFirstUniqRecommended(state, ownProps),
  channelIdFirstUniqNewest: channelSelectors.getChannelIdFirstUniqNewest(
    state,
    ownProps
  ),
  channelIdFirstUniqPopular: channelSelectors.getChannelIdFirstUniqPopular(
    state,
    ownProps
  ),
  channelIdRandUniqRecommended:
    channelSelectors.getChannelIdRandUniqRecommended(state, ownProps),
  channelIdRandUniqNewest: channelSelectors.getChannelIdRandUniqNewest(
    state,
    ownProps
  ),
  channelIdRandUniqPopular: channelSelectors.getChannelIdRandUniqPopular(
    state,
    ownProps
  ),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default compose(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(ChannelContentFilters);
