import { forwardRef, PropsWithChildren } from 'react';

const SiteHeaderSubmitPopover = forwardRef<
  HTMLDivElement,
  PropsWithChildren<unknown>
>(({ children }, ref) => {
  return (
    <div ref={ref} className="site-header-submit-popover">
      {children}
    </div>
  );
});

SiteHeaderSubmitPopover.displayName = 'SiteHeaderSubmitPopover';
export default SiteHeaderSubmitPopover;
