export const CONTENT_CARD_VIEW = 'w:ContentCard:view';
export const CONTENT_CARD_CLICK = 'w:ContentCard:click';
export const CONTENT_CARD_READ = 'w:ContentCard:read';
export const CONTENT_CARD_AVATAR_CLICK = 'w:ContentCard:Avatar:click';
export const CONTENT_CARD_MULTI_IMAGE_CLICK = 'w:ContentCard:multiimage:click';
export const CONTENT_CARD_BOOKMARK = 'w:Bookmark:click';
export const CONTENT_CARD_UNBOOKMARK = 'w:Unbookmark:click';
export const CONTENT_CARD_FLAG = 'w:ContentCard:Menu:Report:click';
export const CONTENT_CARD_FLAG_CONFIRM =
  'w:ContentCard:Menu:Report:Confirm:click';
export const CONTENT_CARD_FLAG_CONFIRM_SUCCESS =
  'w:ContentCard:Menu:Report:Confirm:success';
export const CONTENT_CARD_FLAG_CONFIRM_ERROR =
  'w:ContentCard:Menu:Report:Confirm:error';
export const CONTENT_CARD_COMMENT = 'w:Comment:click';
export const CONTENT_CARD_LIKE = 'w:Reaction:Like:click';
export const CONTENT_CARD_UNLIKE = 'w:Reaction:Unlike:click';
export const CONTENT_CARD_SHARE = 'w:Share:ShareNow:click';
export const CONTENT_CARD_INFO = 'w:ContentInfo:click';
export const CONTENT_CARD_EDIT = 'w:ContentEdit:click';
export const CONTENT_CARD_TRANSLATE = 'w:ContentTranslate:click';
export const CONTENT_CARD_COPY_LINK = 'w:ContentCard:Menu:copylink';
export const CONTENT_CARD_LIKES_SEE_ALL = 'w:ContentCard:Likes:seeall';
export const CONTENT_CARD_LIKES_PROFILE_CLICK =
  'w:ContentCard:Likes:Profile:click';
export const CONTENT_VIDEO_PLAY = 'w:video_play';
export const CONTENT_EMBEDDED_VIDEO_PLAY = 'w:ContentCard:video_play';
export const CONTENT_CARD_COMMENT_SORT_CLICK =
  'w:ContentCard:Comment:Sort:click';
export const CONTENT_CARD_LINK_CLICK = 'w:ContentCard:Article:linkFollow';
