import { Dispatch } from 'redux';
import ChannelService from '../../services/channel';
import actions from './actions';

const setChannels = actions.setChannels;
const followedChannel = actions.followedChannel;
const unfollowedChannel = actions.unfollowedChannel;

const followChannel = (id: string | number) => async (dispatch: Dispatch) => {
  dispatch(followedChannel(id));

  try {
    return await new ChannelService(String(id)).follow();
  } catch (err) {
    dispatch(unfollowedChannel(id));
    throw err;
  }
};

const unfollowChannel = (id: string | number) => async (dispatch: Dispatch) => {
  dispatch(unfollowedChannel(id));

  try {
    return await new ChannelService(String(id)).unfollow();
  } catch (err) {
    dispatch(followedChannel(id));
    throw err;
  }
};

export default {
  setChannels,
  followChannel,
  unfollowChannel,
};
