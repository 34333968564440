import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  trackProfileEditError,
  trackProfileEditSave,
} from '../../models/profile/analytics';

import { ID as DialogID } from '../../components/ui/dialog';

import AdvocateService from '../../services/advocate';
import { advocateSelectors } from '../../models/advocate';
import { uiOperations } from '../../models/ui';
import { programMembershipSelectors } from '../../models/program-membership';
import { programSelectors } from '../../models/program';
import { usePushToastOrFlashMessage } from '../../components/v2/toaster/deprecation-helper';

const ProfileEditContainer = ({
  children,

  analyticsData,
  syncAdvocate,

  displayOverlay,
  removeOverlay,
  advocateId,
  programMembershipStatus,
  programName,
  programMembershipId,
  name,
  email,
  advocateFederatedId,
  gdprComplianceEnabled,
  gdprComplianceContacts,
}) => {
  const [profile, setProfile] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const updateProfileField = (key, value) => {
    setProfile((p) => Object.assign({}, p, { [key]: value }));
  };

  const handleChange = (e) => {
    const { value, checked, name, type } = e.target;

    updateProfileField(name, type === 'checkbox' ? checked : value);
  };

  const handleExportClick = () => {
    generateExportDataEmail();
  };

  const handleEraseClick = () => {
    const key = 'forget-me-dialog';

    displayOverlay(
      DialogID,
      {
        title: t('screens.profile.edit_profile.forget_me'),
        children: t('screens.profile.edit_profile.forget_me_pop_up_body'),
        accept: {
          icon: 'check',
          text: t('common.continue'),
          onClick: () => {
            generateEraseDataEmail();
            removeOverlay({ key });
          },
        },
        cancel: {
          text: t('common.cancel'),
          onClick: () => {
            removeOverlay({ key });
          },
        },
      },
      key
    );
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();

    setSubmitting(true);

    trackProfileEditSave(analyticsData);

    new AdvocateService(advocateId)
      .update({ ...profile })
      .then(handleSubmitSuccess)
      .catch(handleSubmitFailure);
  };

  const handleSubmitSuccess = () => {
    setSubmitting(false);

    syncAdvocate();

    pushMessage({
      text: t('screens.profile.edit_profile.profile_update_success'),
      type: 'success',
    });
  };

  const handleSubmitFailure = (e) => {
    console.error('profile edit error', e);

    const errorData = (e.response && e.response.data) || {};
    const errorMessage =
      errorData.errors && errorData.errors.length
        ? errorData.errors[0].title
        : t('errors.default');

    pushMessage({
      text: errorMessage,
      type: 'error',
    });

    trackProfileEditError(analyticsData);

    setSubmitting(false);
  };

  const gdprContactsList = gdprComplianceContacts
    ? gdprComplianceContacts.join(';')
    : '';

  /* eslint-disable */
  const generateExportDataEmail = () => {
    let mailto = gdprContactsList;

    const subject = `Request to export personal data for ${programName}`;
    const body =
      `Please export my personal data related to ${programName}. I understand the exported data will be returned to this email address.\r\n\r\n` +
      `**** Do not edit content below ****\r\n\r\n` +
      `A user just submitted a request related to data privacy - Export All My Personal Data. As your company is the Data Controller per GDPR standards, you are receiving this request for resolution.\r\n\r\n` +
      `Please refer to the member's identifying data below:\r\n\r\n` +
      `User: ${name}\r\n` +
      `Email: ${email || 'n/a'}\r\n` +
      `Federated Identifier: ${advocateFederatedId || 'n/a'}\r\n` +
      `Program: ${programName}\r\n` +
      `M: ${programMembershipId}\r\n` +
      `A: ${advocateId}\r\n\r\n` +
      `Please refer to this Firstup Knowledge Base article for more details on exporting or forgetting a user's data: https://support.firstup.io/hc/en-us/articles/4419515793815-Export-or-Forget-a-User-s-Data-GDPR-Compliance`;

    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    location.href = `mailto:${mailto}?subject=${encodedSubject}&body=${encodedBody}`;
  };
  /* eslint-enable */

  /* eslint-disable */
  const generateEraseDataEmail = () => {
    let mailto = gdprContactsList;

    const subject = `Request to erase personal data for ${programName}`;
    const body =
      `Please erase my personal data related to ${programName}. I understand this request will inactivate my account in all communities tied to this account.\r\n\r\n` +
      `**** Do not edit content below ****\r\n\r\n` +
      `A user just submitted a request related to data privacy - Erase All My Personal Data. As your company is the Data Controller per GDPR standards, you are receiving this request for resolution.\r\n\r\n` +
      `Please refer to the member's identifying data below:\r\n\r\n` +
      `User: ${name}\r\n` +
      `Email: ${email || 'n/a'}\r\n` +
      `Federated Identifier: ${advocateFederatedId || 'n/a'}\r\n` +
      `Program: ${programName}\r\n` +
      `M: ${programMembershipId}\r\n` +
      `A: ${advocateId}\r\n\r\n` +
      `Please refer to this Firstup Knowledge Base article for more details on exporting or forgetting a user's data: https://support.firstup.io/hc/en-us/articles/4419515793815-Export-or-Forget-a-User-s-Data-GDPR-Compliance`;

    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    location.href = `mailto:${mailto}?subject=${encodedSubject}&body=${encodedBody}`;
  };
  /* eslint-enable */

  const allowedStatuses = ['active', 'registering'];
  const advocateStatus = programMembershipStatus;
  const displayGdprOptions =
    gdprComplianceEnabled &&
    gdprComplianceContacts &&
    allowedStatuses.includes(advocateStatus);

  return children({
    updatedValues: profile,
    submitting,
    displayGdprOptions,
    onChange: handleChange,
    onExportClick: handleExportClick,
    onEraseClick: handleEraseClick,
    onSubmit: handleSubmit,
  });
};

const mapStateToProps = (state, ownProps) => {
  return {
    gdprComplianceEnabled: programSelectors.getProgramGdprComplianceEnabled(
      state,
      ownProps
    ),
    gdprComplianceContacts: programSelectors.getProgramGdprComplianceContacts(
      state,
      ownProps
    ),
    programName: programSelectors.getProgramName(state, ownProps),
    programMembershipId: programMembershipSelectors.getProgramMembershipId(
      state,
      ownProps
    ),
    programMembershipStatus:
      programMembershipSelectors.getProgramMembershipStatus(state, ownProps),

    advocateId: advocateSelectors.getAdvocateId(state, ownProps),
    name: advocateSelectors.getAdvocateName(state, ownProps),
    email: advocateSelectors.getAdvocateEmail(state, ownProps),
    advocateFederatedId: advocateSelectors.getAdvocateFederatedId(
      state,
      ownProps
    ),
  };
};

const mapDispatchToProps = {
  displayOverlay: uiOperations.displayOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditContainer);
