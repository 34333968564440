import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  submissionNoteColor,
  submissionNoteColors,
} from '../../../models/content-submission/atoms';

import TitleInput from '../inputs/title';
import DescriptionInput from '../inputs/description';
import ColorInput from '../inputs/color';

const NoteForm = () => {
  const [noteColor, setNoteColor] = useRecoilState(submissionNoteColor);
  const noteColors = useRecoilValue(submissionNoteColors);

  const { t } = useTranslation();

  // Set initial note color
  useEffect(() => {
    if (!noteColor) setNoteColor(noteColors[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content-submission-form content-submission-form--note">
      <div className="content-submission-form__header">
        <div className="content-submission-form__text">
          <TitleInput
            aria-label={t('content_submission.field_title')}
            placeholder={t('content_submission.field_title_optional')}
          />

          <DescriptionInput
            aria-label={t('content_submission.field_description_optional')}
            placeholder={t('content_submission.field_description')}
          />
        </div>
        <div className="content-submission-form__cover-image">
          <ColorInput />
        </div>
      </div>
    </div>
  );
};

export default NoteForm;
