import store from '../config/store';
import queryString from 'query-string';

import { track } from '../models/analytics/helpers';
import * as types from '../models/analytics/keys';

export const advocatoUrl = () => {
  const {
    appConfig: { advo_url: advoUrl },
  } = store.getState();

  return advoUrl;
};

export const redirectUrl = (stripPath) => {
  const {
    appConfig: { auth_url: authUrl },
    program: { root_path: programPath },
  } = store.getState();

  const programPathRegex = new RegExp(programPath, 'i');

  const route = window.location.pathname
    .replace(/\/sc4$/, '')
    .replace(programPathRegex, '')
    .replace(/^\//, '');

  const hash = !stripPath
    ? '#' + route + window.location.hash.replace(/#/, route ? '/' : '')
    : '';

  // Whitelist only [tok] and [ids] queryparams
  const skip_mobile_redirect = true;
  const { tok, ids } = queryString.parse(window.location.search, {
    parseNumbers: true,
  });
  const search = queryString.stringify(
    { tok, ids, skip_mobile_redirect },
    { arrayFormat: 'comma', skipNull: true }
  );

  return authUrl + (search ? `?${search}` : '') + hash;
};

export const redirectToLogin = (stripPath) => {
  const {
    program: { id: programId },
  } = store.getState();

  track(types.AUTH_LOGIN_REDIR, { url: redirectUrl, program_id: programId });

  window.location.href = redirectUrl(stripPath);
};

export const redirectToCommunitySwitch = () => {
  window.location.href = advocatoUrl() + '/programs';
};

export const redirectToSSOLogout = () => {
  const {
    program: { root_url: rootUrl },
  } = store.getState();

  window.location.href = rootUrl + '/saml/logout';
};
