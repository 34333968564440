import './empty-state.scss';

type TopicPageSidebarEmptyStateProps = {
  heading?: string;
  details: string;
  icon: JSX.Element;
  button?: JSX.Element;
};

export function TopicPageSidebarEmptyState({
  heading,
  details,
  icon,
  button,
}: TopicPageSidebarEmptyStateProps) {
  return (
    <div className="empty-state">
      {icon && icon}
      <div className="empty-state__text-block">
        {heading && (
          <h3 className="empty-state__text-block__heading">{heading}</h3>
        )}
        <p className="empty-state__text-block__details">{details}</p>
      </div>
      {button && button}
    </div>
  );
}
