/* eslint-disable react/display-name */

import { ComponentProps, forwardRef, ReactNode, Ref } from 'react';
import cx from 'classnames';
import AutosizedTextarea, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import ConditionalWrapper from '../../common/conditional-wrapper';
import { Icon } from '.';

export const Label = (props: ComponentProps<'label'>) => (
  <label className="label" htmlFor={props.htmlFor} {...props} />
);
Label.displayName = 'UI.Label';

export const Textbox = forwardRef<HTMLInputElement, ComponentProps<'input'>>(
  (props, ref) => <input ref={ref} type="text" autoComplete="off" {...props} />
);
Textbox.displayName = 'UI.Textbox';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  (props, ref) => <AutosizedTextarea ref={ref} maxRows={5} {...props} />
);
Textarea.displayName = 'UI.Textarea';

// Defined ref in the props because dynamic ref object is not possible
// https://stackoverflow.com/a/74763650/14163928
type TextInputProps = {
  icon?: string;
  label?: ReactNode;
  compact?: boolean;
  className?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
} & (
  | ({ multiline: true; ref?: Ref<HTMLTextAreaElement> } & ComponentProps<
      typeof Textarea
    >)
  | ({ multiline?: false; ref?: Ref<HTMLInputElement> } & ComponentProps<
      typeof Textbox
    >)
);

const TextInput = ({
  name,
  value,
  label,
  icon,
  compact,
  multiline,
  className,
  isSubmitting,
  isDisabled,
  onReset,
  ref,
  ...props
}: TextInputProps) => {
  className = cx(className, 'text-input', {
    compact: compact,
    'text-input--loading': isSubmitting,
    'text-input--with-reset': onReset,
  });

  return (
    <div className={className}>
      {label ? <Label htmlFor={name}>{label}</Label> : null}

      <ConditionalWrapper
        condition={!!icon}
        wrapper={(children) => (
          <div className="text-input__container">{children}</div>
        )}
      >
        {multiline ? (
          <Textarea
            id={name}
            name={name}
            value={value}
            className="input"
            disabled={isDisabled}
            ref={ref}
            {...(props as ComponentProps<typeof Textarea>)}
          />
        ) : (
          <Textbox
            id={name}
            name={name}
            value={value}
            className="input"
            disabled={isDisabled}
            ref={ref}
            {...(props as ComponentProps<typeof Textbox>)}
          />
        )}
        {icon ? <Icon type={icon} /> : ''}
      </ConditionalWrapper>
    </div>
  );
};

TextInput.displayName = 'UI.TextInput';

export default TextInput;
