import React from 'react';

import useScreenTitle from '../../common/use-screen-title';

import './not-found.scss';
import { NavLink } from '../../components/ui';

const NotFound = () => {
  localStorage.removeItem('lastLocation'); // Make sure users won't be sent here again

  useScreenTitle('Not Found');

  return (
    <div className="not-found-container">
      <div className="not-found">
        <h1>404</h1>
        <h2>Not Found...</h2>
        <NavLink className="not-found-home__link" href="/" action="push" exact>
          Return to my feed
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
