import { connect } from 'react-redux';
import MessageDetail from './message-detail/message-detail';
import MessageDetailsNotFound from './message-details-not-found';
import useHeaderLogoOnly from '../../common/use-header-logo-only';
import useMessageFetcher from '../../common/use-message-fetcher';
import useMessageViewer from '../../common/use-message-viewer';
import { Spinner } from '../../components/ui';
import { programMembershipSelectors } from '../../models/program-membership';
import './message-details.scss';

const MessageDetailsOrBlank = (props) =>
  props.isActiveMembership ? <MessageDetails {...props} /> : null;

const MessageDetails = ({ match: { params } }) => {
  useHeaderLogoOnly(true);

  useMessageViewer(params.messageId);
  const { message, isFetching } = useMessageFetcher({
    messageId: params.messageId,
  });

  const showNotFound = !isFetching && !message;

  if (showNotFound) return <MessageDetailsNotFound />;

  return (
    <section className="message-details">
      {isFetching ? (
        <Spinner />
      ) : (
        <MessageDetail messageId={params.messageId} />
      )}
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isActiveMembership: programMembershipSelectors.getProgramMembershipIsActive(
    state,
    ownProps
  ),
});

export default connect(mapStateToProps, null)(MessageDetailsOrBlank);
