import { useRef } from 'react';
import ChannelLink from './channel-link';
import useTruncate from '../../common/use-truncate';

const ChannelTitle = ({
  channel,
  truncate = undefined,
  analyticsData,
  onClick = undefined,
  noLink = false,
  titleTag = 'div',
}) => {
  const ref = useRef();

  useTruncate({ ref, lines: truncate });

  const TitleTag = titleTag;

  return noLink ? (
    <TitleTag className="channel__title" ref={ref}>
      {channel.name}
    </TitleTag>
  ) : (
    <ChannelLink
      channelId={channel.id}
      analyticsData={analyticsData}
      onClick={onClick}
    >
      <TitleTag className="channel__title" ref={ref}>
        {channel.name}
      </TitleTag>
    </ChannelLink>
  );
};

export default ChannelTitle;
