import { useState, useEffect } from 'react';
import { Icon, Button } from '../ui';
import ContentSubmissionService from '../../services/content-submission';
import { useTranslation } from 'react-i18next';

const CommentLinkPreview = ({ linkText, handleClose }) => {
  const { t } = useTranslation();

  const [, setIsFetching] = useState(false);
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [fetched, setFetched] = useState(false);

  // gotta fetch the data from orare
  const fetchLinkPreview = async () => {
    setIsFetching(true);

    try {
      const res = await new ContentSubmissionService().fetchLinkPreview(
        linkText
      );

      handleFetchLinkPreviewSuccess(res);
    } catch (err) {
      handleFetchLinkPreviewFailure(err);
    }
  };

  const handleFetchLinkPreviewSuccess = (res) => {
    const { title, cropped_image_url: imageUrl, url: resolvedUrl } = res.data;

    const parsedUrl = new URL(resolvedUrl);

    setTitle(title);
    setUrl(parsedUrl.hostname);
    setImageUrl(imageUrl);

    setFetched(true);
    setIsFetching(false);
  };

  const handleFetchLinkPreviewFailure = () => {
    setIsFetching(false);
    handleClose();
  };

  const hideImage = (e) => {
    e.target.style.display = 'none';
  };

  useEffect(() => {
    fetchLinkPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {fetched ? (
        <div className="link-preview-wrapper">
          <div className="link-preview">
            <img src={imageUrl} alt="link preview" onError={hideImage} />
            <div className="link-preview-text">
              <p className="url">{url}</p>
              <p>{title}</p>
            </div>
            <Button
              type="button"
              className="close-button"
              title={t('common.close')}
              onClick={handleClose}
              aria-label={t('common.close')}
            >
              <Icon>close</Icon>
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CommentLinkPreview;
