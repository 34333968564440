import { TopicShortcut } from '../../../../../models/channels/types';
import { Shortcut as OldShortcut } from '../../../../ui/shortcut/old';
import { Shortcut } from '../../../../ui/shortcut';
import useFeatureFlag from '../../../../../common/use-feature-flag';
import { Feature } from '../../../../../models/features/features';

type TopicPageShortcutProps = {
  shortcut: TopicShortcut;
};

export function TopicPageShortcut({ shortcut }: TopicPageShortcutProps) {
  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );

  return notificationCenterEnabled ? (
    <Shortcut
      label={shortcut.name}
      description={shortcut.description}
      imgSrc={shortcut.icon_url}
      links={shortcut.links.map(({ name, url }) => ({
        url,
        label: name,
      }))}
    />
  ) : (
    <OldShortcut
      label={shortcut.name}
      description={shortcut.description}
      imgSrc={shortcut.icon_url}
      links={shortcut.links.map(({ name, url }) => ({
        url,
        label: name,
      }))}
    />
  );
}
