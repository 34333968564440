import { atom, selectorFamily } from 'recoil';

//stores a list of all feeds fetched by `use-content-feed`.
//key should be a string determined by that component.
//over time should move use-content-feed into here entirely as selectors, will need to phase out redux first.
export const contentFeeds = atom({
  key: 'contentFeeds',
  default: {},
});

export const contentFeedFamily = selectorFamily({
  key: 'contentFeedSelector',
  get:
    (feedKey) =>
    ({ get }) => {
      //regarding feedItems: generally speaking, should only use the id from here, and look it up in the redux state.
      //system is already built to work this way. to refactor later.

      const {
        feedItems,
        page,
        numPages,
        pageSize,
        total,
        canLoadMore,
        loaded,
      } = get(contentFeeds)[feedKey] || {};

      //not sure if this is the best way to do default values
      return {
        feedItems: feedItems || [],
        page: page || null,
        numPages: numPages || 0,
        pageSize: pageSize || 0,
        total: total || 0,
        canLoadMore: canLoadMore || null,
        loaded: loaded || false, //used to indicate at least 1 fetch has been run
      };
    },
  //allowing to set partial data, unmentioned keys will not be erased on a set.
  set:
    (feedKey) =>
    ({ get, set }, newFeedData) => {
      set(contentFeeds, {
        ...get(contentFeeds),
        [feedKey]: { ...get(contentFeeds)[feedKey], ...newFeedData },
      });
    },
});

export const setContentFeedItems = selectorFamily({
  key: 'contentFeedSetItems',
  set:
    (feedKey) =>
    ({ set }, feedItems) =>
      set(contentFeedFamily(feedKey), { feedItems }),
});
