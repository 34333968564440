import ChannelLink from './channel-link';
import './channel.scss';

export const ChannelImage = ({
  channel,
  analyticsData,
  onClick = undefined,
  noLink = false,
}) => (
  <div
    style={{
      backgroundImage:
        channel.background_image_url && `url(${channel.background_image_url})`,
      backgroundColor:
        !channel.background_image_url && channel.background_color,
    }}
    className="channel__image"
  >
    {noLink ? (
      <img
        src={channel.background_image_url}
        alt={channel.name}
        className="image"
      />
    ) : (
      <ChannelLink
        channelId={channel.id}
        analyticsData={analyticsData}
        onClick={onClick}
      >
        <img
          src={channel.background_image_url}
          alt={channel.name}
          className="image"
        />
      </ChannelLink>
    )}
  </div>
);

export default ChannelImage;
