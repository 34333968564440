import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../config/store';
import { locationOperations } from '../models/location';

const useScreenTitle = (title) => {
  const { t } = useTranslation();
  const state = store.getState();
  const siteName = state.program && state.program.name;

  useEffect(() => {
    document.title = `${title || t('nav_links.home')} | ${siteName}`;

    store.dispatch(locationOperations.setLocation(title));
  }, [title, siteName]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useScreenTitle;
