import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useRouteMatch } from 'react-router';
import { Link, Spinner } from '../../components/ui';
import cx from 'classnames';
import ContentInfoComments from '../content-details/content-info/content-info-comments';
import useContentFetcher from '../../common/use-content-fetcher';

import './comments-webview.scss';
import { programSelectors } from '../../models/program';
import { uiOperations } from '../../models/ui';
import {
  RootPatronState,
  usePatronSelector,
} from '../../common/use-patron-selector';
import { usePrependProgramPath } from '../../common/use-prepend-program-path';
import { Content } from '../../models/content/types';
import { EXIT_DEEPLINK, getDeeplinkUrl } from '../../lib/mobile-deeplinks/es';
import { Feature, getFeatureFlag } from '../../models/features/features';
import ContentComments from '../../components/v2/comments/content-comments';

export function CommentsWebviewView() {
  const { params } = useRouteMatch<{ id: string; feedItemId: string }>();
  return (
    <CommentsWebView
      contentId={Number(params.id)}
      feedItemId={params.feedItemId}
    />
  );
}

type CommentsWebViewProps = { contentId: Content['id']; feedItemId: string };

const CommentsWebView = ({
  contentId: id,
  feedItemId,
}: CommentsWebViewProps) => {
  const contentIdParam = Number(id);

  const programId = usePatronSelector((state) =>
    programSelectors.getProgramId(state)
  );
  const orgSlug = usePatronSelector((state) =>
    programSelectors.getProgramOrgSlug(state)
  );

  const { content, fetching } = useContentFetcher({
    contentId: contentIdParam,
    feedItemId,
    isApproval: false,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const contentId = content?.id ?? contentIdParam;

  dispatch(uiOperations.hideHeader());

  useEffect(() => {
    // Navigating out of this component should close the website in all cases
    const unblock = history.block((location) => {
      const webviewPathMatch = matchPath(location.pathname, {
        path: '/webview/contents/:id/comments/:commentId',
      });
      if (webviewPathMatch) {
        return;
      }

      const commentPathMatch = matchPath(location.pathname, {
        path: '/contents/:id/comments/:commentId',
      });
      if (commentPathMatch) {
        location.pathname = `/webview${location.pathname}`;
        return;
      }

      const commentsPathMatch = matchPath(location.pathname, {
        path: '/contents/:id/comments',
      });
      if (commentsPathMatch) {
        location.pathname = `/webview${location.pathname}`;
        return;
      }

      const contentDetailMatch = matchPath(location.pathname, {
        path: '/contents/:id',
        exact: true,
      });
      if (contentDetailMatch) {
        location.pathname = `/webview${location.pathname}/comments`;
        return;
      }

      const deeplinkUrl = getDeeplinkUrl(location.pathname, programId, orgSlug);
      if (deeplinkUrl) {
        window.location.href = deeplinkUrl;
      } else {
        window.location.href = EXIT_DEEPLINK;
      }

      //you might think calling 'unblock()` here would be the solution, but the mobile app requires a full stop in navigation to work properly.
      //until we find a better way, we have to be satified with this hack.
      throw 'intentional halt';
    });

    return unblock;
  }, [programId, orgSlug]);

  const contentLink = usePrependProgramPath(`/contents/${contentId}`);

  const newContentPage = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW)
  );

  return (
    <>
      {fetching ? (
        <Spinner />
      ) : (
        <>
          {newContentPage ? (
            <CommentsV2Wrapper
              contentId={contentId}
              description={content?.description}
              link={contentLink}
              title={content?.title}
            />
          ) : (
            <CommentsV1Wrapper
              contentId={contentId}
              description={content?.description}
              link={contentLink}
              title={content?.title}
            />
          )}
        </>
      )}
    </>
  );
};

const CommentsContextBlock = ({
  title,
  description,
  link,
}: {
  title?: string;
  description?: string;
  link: string;
}) => {
  const { t } = useTranslation();

  return (
    <Link className="comment-context-block" href={link}>
      <div className="comment-context">
        <div className="comment-context__title">
          {t('comments.in_response')}
        </div>

        <div className="comment-context__body">
          <h5> {title} </h5>
          <p> {description} </p>
        </div>
      </div>
    </Link>
  );
};

type CommentsWrapperProps = {
  contentId: Content['id'];
  title?: Content['title'];
  description?: Content['description'];
  link: string;
};

const CommentsV2Wrapper = ({
  contentId,
  title,
  description,
  link,
}: CommentsWrapperProps) => {
  cx;
  return (
    <div className="responsive-content-page comments-web-view-v2">
      <CommentsContextBlock
        description={description}
        title={title}
        link={link}
      />
      <ContentComments contentId={contentId} />
    </div>
  );
};

const CommentsV1Wrapper = ({
  contentId,
  title,
  description,
  link,
}: CommentsWrapperProps) => {
  return (
    <div className="heartsplosion-container comments-web-view">
      <CommentsContextBlock
        description={description}
        title={title}
        link={link}
      />
      <ContentInfoComments contentId={contentId} />
    </div>
  );
};

export default CommentsWebView;
