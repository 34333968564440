import 'intersection-observer'; // IntersectionObserver polyfill
import React, { RefObject, useEffect, useRef } from 'react';

export type InviewEventHandler = (
  change: IntersectionObserverEntry,
  observer: IntersectionObserver
) => void;

interface InviewProps<T extends HTMLElement> {
  ref: React.RefObject<T | null | undefined>;
  offset?: string;
  once?: boolean;
  onInview?: InviewEventHandler;
  onOutview?: InviewEventHandler;
  enabled?: boolean;
}

export const useInview = <T extends HTMLElement>({
  ref,
  offset = '0px',
  once,
  onInview = undefined,
  onOutview = undefined,
}: InviewProps<T>) => {
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      (changes, observer) => {
        const change = changes[0];

        if (change.intersectionRatio === 1) {
          if (once) observer.unobserve(change.target);

          return ref.current && onInview?.(change, observer);
        }

        if (change.intersectionRatio !== 1) {
          return ref.current && onOutview?.(change, observer);
        }
      },
      {
        rootMargin: offset,
        threshold: 1.0,
      }
    );

    observer.observe(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

interface InviewForProps {
  ref: RefObject<HTMLElement>;
  once?: boolean;
  timeout: number;
  cb: () => void;
}

export const useInviewFor = ({ ref, once, timeout, cb }: InviewForProps) => {
  const callback = useRef(cb);
  callback.current = cb;
  useEffect(() => {
    let viewTimeout: NodeJS.Timeout | null;
    if (!ref.current) return;
    function onIn() {
      if (!viewTimeout) {
        viewTimeout = setTimeout(() => {
          if (ref.current) {
            callback.current();
            viewTimeout = null;
            if (once) observer.unobserve(ref.current);
          }
        }, timeout);
      }
    }

    function onOut() {
      if (viewTimeout) {
        clearTimeout(viewTimeout);
        viewTimeout = null;
      }
    }

    const observer = new IntersectionObserver(
      (changes) => {
        const change = changes[0];
        if (change.intersectionRatio === 1) {
          return onIn();
        } else {
          onOut();
        }
      },
      {
        threshold: 1.0,
      }
    );

    observer.observe(ref.current);

    return function cleanup() {
      if (viewTimeout) {
        clearTimeout(viewTimeout);
        viewTimeout = null;
      }
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
};

export default useInview;
