import { Container } from '@socialchorus/shared-ui-components';
import { Link } from '../../../ui';
import React from 'react';
import ShortListContainer from './short-list-container';
import styles from './topics-short-list.module.scss';
import { TopicSearchResponse } from '../topics/topics-search';
import { SearchType } from '../search-screen';
import { trackSearchPreviewResultClick } from '../../../../models/analytics';
import { useTranslation } from 'react-i18next';

interface TopicsShortListProps {
  topicData: TopicSearchResponse[];
  error: boolean;
  viewAllUrl: string;
  searchType: SearchType;
}

const TopicsShortList: React.FC<TopicsShortListProps> = ({
  error,
  viewAllUrl,
  topicData,
  searchType,
}) => {
  const { t } = useTranslation();
  const topicShortList =
    topicData[0]?.data?.length > 0 ? topicData[0]?.data?.slice(0, 6) : [];

  return (
    <ShortListContainer
      error={error}
      viewAllUrl={viewAllUrl}
      dataType={searchType}
      dataCount={topicShortList.length}
    >
      {topicShortList.map((topic) => (
        <Container
          shadow="none"
          shape="large"
          variant="default"
          className={styles.Container}
          key={topic.id}
          onClick={() =>
            trackSearchPreviewResultClick({ result_type: searchType })
          }
        >
          <Link
            href={`${topic.attributes.permalink_url}`}
            className={styles.NavLink}
          >
            <article
              className={styles.TopicCard}
              aria-labelledby={`topic-name-${topic.id}`}
            >
              {topic.attributes.background_image_url ? (
                <Container
                  tag="img"
                  src={topic.attributes.background_image_url}
                  alt=""
                  className={styles.Icon}
                />
              ) : (
                <Container
                  tag="div"
                  alt=""
                  className={styles.Icon}
                  style={{ background: topic.attributes.background_color }}
                />
              )}
              <div>
                <h2
                  id={`topic-name-${topic.id}`}
                  className={`${styles.Name} ${styles.Text}`}
                >
                  {topic.attributes.name}
                </h2>
                <p>
                  <span className={`${styles.MemberCount} ${styles.Text}`}>
                    {t('channel_details.number_joined', {
                      count: topic.attributes.follower_count,
                    })}
                  </span>
                </p>
              </div>
            </article>
          </Link>
        </Container>
      ))}
    </ShortListContainer>
  );
};

export default TopicsShortList;
