import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Spinner } from '../../components/ui';
import CommentsWebview from '../comments-webview/comments-webview';
import ContentDetails from '../content-details';
import '../content-details/content-details.scss';
import { ContentError } from '../content-error/content-error';
import { useCustomSlug } from './use-custom-slug';

export function ContentCustomUrl() {
  return (
    <ContentCustomUrlWrapper>
      {({ contentId }) => <ContentDetails contentId={contentId} />}
    </ContentCustomUrlWrapper>
  );
}

export function CommentsWebviewCustomUrl() {
  const { params } = useRouteMatch<{ id: string; feedItemId: string }>();
  return (
    <ContentCustomUrlWrapper>
      {({ contentId }) => (
        <CommentsWebview contentId={contentId} feedItemId={params.feedItemId} />
      )}
    </ContentCustomUrlWrapper>
  );
}

function ContentCustomUrlWrapper({
  children,
}: {
  children: ({ contentId }: { contentId: number }) => JSX.Element | JSX.Element;
}) {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ id: string }>();
  const { data: customUrlData, isLoading: isCustomUrlLoading } = useCustomSlug(
    params.id
  );

  if (isCustomUrlLoading) {
    return <Spinner />;
  }

  const customUrl = customUrlData?.data.find(
    (item) => String(item.attributes?.slug) === params.id
  );
  const contentId = customUrl?.attributes?.content_id;

  if (!contentId || customUrlData?.data.length === 0) {
    return (
      <ContentError
        title={t('errors.something_doesnt_seem_right')}
        description={t('errors.link_not_found_related_posts')}
      />
    );
  }

  const isRenderFunction = typeof children === 'function';

  if (isRenderFunction) {
    return children({ contentId });
  }

  return <>{children}</>;
}
