import { createApiClient } from './base-api';

export const eva = createApiClient({
  headers: {
    'Cache-Control': 'no-store, max-age=0',
  },
});

eva.interceptors.response.use(undefined, (err) => {
  if (err.response && err.response.status === 401) {
    console.error(err);
  }

  return Promise.reject(err);
});

export default eva;
