import { useTranslation } from 'react-i18next';
import { ChannelFollowersResponse } from '../../../../models/channels/types';
import { Link, HoverableFollowerAvatar } from '../../../ui';

type ChannelMembersListProps = {
  followerCount: number | undefined;
  channelFollowers: ChannelFollowersResponse['followers']['data'];
};
const ChannelMembersList = ({
  followerCount,
  channelFollowers,
}: ChannelMembersListProps) => {
  const { t } = useTranslation();

  const renderFollower = (follower: typeof channelFollowers[number]) => (
    <div className="channel-details-v2__follower" key={follower.id}>
      <Link href={`/profiles/${follower.id}`} action="push">
        <HoverableFollowerAvatar
          follower={{ id: follower.id, ...follower.attributes }}
        />
      </Link>
    </div>
  );

  return (
    <div className="channel-details-v2__followers">
      <h2 className="channel-details-v2__subtitle">
        {t('channel_details.number_joined', {
          count: followerCount,
        })}
      </h2>

      <div className="channel-details-v2__followers-list">
        {channelFollowers.map(renderFollower)}
      </div>
    </div>
  );
};

export default ChannelMembersList;
