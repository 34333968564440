import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { uiOperations } from '../../../models/ui';

import {
  submissionUrl,
  submissionImageUrls,
  submissionTitle,
  submissionDescription,
  submissionIsFetching,
  submissionIsSubmitting,
  submissionImages,
} from '../../../models/content-submission/atoms';

import ContentSubmissionService from '../../../services/content-submission';
import { trackLoadImagePreviewRemoveClick } from '../../../models/content-submission/analytics';

import DropzoneInput from '../inputs/dropzone';
import UrlInput from '../inputs/url';
import ImageInput from '../inputs/image';
import TitleInput from '../inputs/title';
import DescriptionInput from '../inputs/description';
import ImagePreview from '../image-preview';
import DropzoneOverlay from '../inputs/dropzone-overlay';
import { usePushToastOrFlashMessage } from '../../../components/v2/toaster/deprecation-helper';

const LinkForm = () => {
  const [title, setTitle] = useRecoilState(submissionTitle);
  const [description, setDescription] = useRecoilState(submissionDescription);
  const [url, setUrl] = useRecoilState(submissionUrl);
  const setImages = useSetRecoilState(submissionImages);
  const [imageUrls, setImageUrls] = useRecoilState(submissionImageUrls);
  const setIsFetching = useSetRecoilState(submissionIsFetching);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);
  const [canEdit, setCanEdit] = useState(url && (title || description));

  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();

  const handleEnterPress = () => {
    fetchLinkPreview();
  };

  const fetchLinkPreview = async () => {
    setIsFetching(true);

    try {
      const res = await new ContentSubmissionService().fetchLinkPreview(url);
      handleFetchLinkPreviewSuccess(res);
    } catch (err) {
      handleFetchLinkPreviewFailure(err);
    }
  };

  const handleFetchLinkPreviewSuccess = (res) => {
    const {
      title,
      description,
      cropped_image_url: imageUrl,
      url: resolvedUrl,
    } = res.data;

    setIsFetching(false);

    setUrl(resolvedUrl);
    setTitle(title);
    setDescription(description);
    setImageUrls([imageUrl]);
    setCanEdit(true);
  };

  const handleImageReset = (e) => {
    trackLoadImagePreviewRemoveClick();

    setImages([]);
    setImageUrls([]);
  };

  const handleFetchLinkPreviewFailure = (err) => {
    console.error('link preview fetch error', err);

    setIsFetching(false);

    // Set the editable value as true even the link is not valid.
    setCanEdit(true);
    const text =
      err.response &&
      (err.response.status === 400 || err.response.status === 404)
        ? t('content_submission.error_invalid_url')
        : t('errors.default');

    pushMessage({ text, type: 'error' });
  };

  useEffect(() => {
    if (!url) setCanEdit(false);
  }, [url]);

  const showImageInput = !imageUrls.length;

  return (
    <DropzoneInput
      accept="image/jpeg, image/png"
      maxSize={10485760} // 10mb
    >
      {({
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        draggedFilesErrorMessage,
        droppedFilesErrorMessage,
      }) => (
        <div
          className="content-submission-form content-submission-form--link"
          {...getRootProps()}
          role="none"
          tabIndex={-1}
          onClick={null}
          onKeyDown={null}
        >
          <UrlInput onEnterPress={handleEnterPress} />

          <div className="content-submission-form__header">
            <div className="content-submission-form__text">
              <TitleInput
                aria-label={t('content_submission.field_title')}
                placeholder={t('content_submission.field_title')}
                isDisabled={!canEdit}
              />

              <DescriptionInput
                aria-label={t('content_submission.field_description_optional')}
                placeholder={t('content_submission.field_description_optional')}
                isDisabled={!canEdit}
              />
            </div>
            <div className="content-submission-form__cover-image">
              {showImageInput && canEdit ? (
                <ImageInput
                  inputProps={!isSubmitting && getInputProps()}
                  openFileBrowser={open}
                  errorMessage={droppedFilesErrorMessage}
                  isDisabled={!canEdit}
                />
              ) : (
                <ImagePreview
                  onReset={!isSubmitting && canEdit && handleImageReset}
                />
              )}
            </div>
          </div>

          {isDragActive ? (
            <DropzoneOverlay
              placeholderText={t('content_submission.field_image_placeholder')}
              errorText={draggedFilesErrorMessage}
            />
          ) : null}
        </div>
      )}
    </DropzoneInput>
  );
};

export default LinkForm;
