import cx from 'classnames';
import './content-card-placeholder.scss';

const ContentCardPlaceholder = () => {
  const className = cx(
    'content-card-placeholder',
    'content-item',
    'content-item--landscape',
    'content-item--small'
  );

  return (
    <div className={className}>
      <div className="content-card-placeholder__image" />

      <div className="content-card-placeholder__body">
        <div className="content-card-placeholder__title" />

        <div className="content-card-placeholder__source" />

        <div className="content-card-placeholder__actions">
          <div className="content-card-placeholder__action" />
          <div className="content-card-placeholder__action" />
        </div>
      </div>
    </div>
  );
};

export default ContentCardPlaceholder;
