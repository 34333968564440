export default [
  {
    emoji: '😀',
    category: 'People',
  },
  {
    emoji: '😃',
    category: 'People',
  },
  {
    emoji: '😄',
    category: 'People',
  },
  {
    emoji: '😁',
    category: 'People',
  },
  {
    emoji: '😆',
    category: 'People',
  },
  {
    emoji: '😅',
    category: 'People',
  },
  {
    emoji: '😂',
    category: 'People',
  },
  {
    emoji: '🤣',
    category: 'People',
  },
  {
    emoji: '☺️',
    category: 'People',
  },
  {
    emoji: '😊',
    category: 'People',
  },
  {
    emoji: '😇',
    category: 'People',
  },
  {
    emoji: '🙂',
    category: 'People',
  },
  {
    emoji: '🙃',
    category: 'People',
  },
  {
    emoji: '😉',
    category: 'People',
  },
  {
    emoji: '😌',
    category: 'People',
  },
  {
    emoji: '😍',
    category: 'People',
  },
  {
    emoji: '😘',
    category: 'People',
  },
  {
    emoji: '😗',
    category: 'People',
  },
  {
    emoji: '😙',
    category: 'People',
  },
  {
    emoji: '😚',
    category: 'People',
  },
  {
    emoji: '😋',
    category: 'People',
  },
  {
    emoji: '😜',
    category: 'People',
  },
  {
    emoji: '😝',
    category: 'People',
  },
  {
    emoji: '😛',
    category: 'People',
  },
  {
    emoji: '🤑',
    category: 'People',
  },
  {
    emoji: '🤗',
    category: 'People',
  },
  {
    emoji: '🤓',
    category: 'People',
  },
  {
    emoji: '😎',
    category: 'People',
  },
  {
    emoji: '🤡',
    category: 'People',
  },
  {
    emoji: '🤠',
    category: 'People',
  },
  {
    emoji: '😏',
    category: 'People',
  },
  {
    emoji: '😒',
    category: 'People',
  },
  {
    emoji: '😞',
    category: 'People',
  },
  {
    emoji: '😔',
    category: 'People',
  },
  {
    emoji: '😟',
    category: 'People',
  },
  {
    emoji: '😕',
    category: 'People',
  },
  {
    emoji: '🙁',
    category: 'People',
  },
  {
    emoji: '☹️',
    category: 'People',
  },
  {
    emoji: '😣',
    category: 'People',
  },
  {
    emoji: '😖',
    category: 'People',
  },
  {
    emoji: '😫',
    category: 'People',
  },
  {
    emoji: '😩',
    category: 'People',
  },
  {
    emoji: '😤',
    category: 'People',
  },
  {
    emoji: '😠',
    category: 'People',
  },
  {
    emoji: '😡',
    category: 'People',
  },
  {
    emoji: '😶',
    category: 'People',
  },
  {
    emoji: '😐',
    category: 'People',
  },
  {
    emoji: '😑',
    category: 'People',
  },
  {
    emoji: '😯',
    category: 'People',
  },
  {
    emoji: '😦',
    category: 'People',
  },
  {
    emoji: '😧',
    category: 'People',
  },
  {
    emoji: '😮',
    category: 'People',
  },
  {
    emoji: '😲',
    category: 'People',
  },
  {
    emoji: '😵',
    category: 'People',
  },
  {
    emoji: '😳',
    category: 'People',
  },
  {
    emoji: '😱',
    category: 'People',
  },
  {
    emoji: '😨',
    category: 'People',
  },
  {
    emoji: '😰',
    category: 'People',
  },
  {
    emoji: '😢',
    category: 'People',
  },
  {
    emoji: '😥',
    category: 'People',
  },
  {
    emoji: '🤤',
    category: 'People',
  },
  {
    emoji: '😭',
    category: 'People',
  },
  {
    emoji: '😓',
    category: 'People',
  },
  {
    emoji: '😪',
    category: 'People',
  },
  {
    emoji: '😴',
    category: 'People',
  },
  {
    emoji: '🙄',
    category: 'People',
  },
  {
    emoji: '🤔',
    category: 'People',
  },
  {
    emoji: '🤥',
    category: 'People',
  },
  {
    emoji: '😬',
    category: 'People',
  },
  {
    emoji: '🤐',
    category: 'People',
  },
  {
    emoji: '🤢',
    category: 'People',
  },
  {
    emoji: '🤧',
    category: 'People',
  },
  {
    emoji: '😷',
    category: 'People',
  },
  {
    emoji: '🤒',
    category: 'People',
  },
  {
    emoji: '🤕',
    category: 'People',
  },
  {
    emoji: '😈',
    category: 'People',
  },
  {
    emoji: '👿',
    category: 'People',
  },
  {
    emoji: '👹',
    category: 'People',
  },
  {
    emoji: '👺',
    category: 'People',
  },
  {
    emoji: '💩',
    category: 'People',
  },
  {
    emoji: '👻',
    category: 'People',
  },
  {
    emoji: '💀',
    category: 'People',
  },
  {
    emoji: '☠️',
    category: 'People',
  },
  {
    emoji: '👽',
    category: 'People',
  },
  {
    emoji: '👾',
    category: 'People',
  },
  {
    emoji: '🤖',
    category: 'People',
  },
  {
    emoji: '🎃',
    category: 'People',
  },
  {
    emoji: '😺',
    category: 'People',
  },
  {
    emoji: '😸',
    category: 'People',
  },
  {
    emoji: '😹',
    category: 'People',
  },
  {
    emoji: '😻',
    category: 'People',
  },
  {
    emoji: '😼',
    category: 'People',
  },
  {
    emoji: '😽',
    category: 'People',
  },
  {
    emoji: '🙀',
    category: 'People',
  },
  {
    emoji: '😿',
    category: 'People',
  },
  {
    emoji: '😾',
    category: 'People',
  },
  {
    emoji: '👐',
    category: 'People',
  },
  {
    emoji: '🙌',
    category: 'People',
  },
  {
    emoji: '👏',
    category: 'People',
  },
  {
    emoji: '🙏',
    category: 'People',
  },
  {
    emoji: '🤝',
    category: 'People',
  },
  {
    emoji: '👍',
    category: 'People',
  },
  {
    emoji: '👎',
    category: 'People',
  },
  {
    emoji: '👊',
    category: 'People',
  },
  {
    emoji: '✊',
    category: 'People',
  },
  {
    emoji: '🤛',
    category: 'People',
  },
  {
    emoji: '🤜',
    category: 'People',
  },
  {
    emoji: '🤞',
    category: 'People',
  },
  {
    emoji: '✌️',
    category: 'People',
  },
  {
    emoji: '🤘',
    category: 'People',
  },
  {
    emoji: '👌',
    category: 'People',
  },
  {
    emoji: '👈',
    category: 'People',
  },
  {
    emoji: '👉',
    category: 'People',
  },
  {
    emoji: '👆',
    category: 'People',
  },
  {
    emoji: '👇',
    category: 'People',
  },
  {
    emoji: '☝️',
    category: 'People',
  },
  {
    emoji: '✋',
    category: 'People',
  },
  {
    emoji: '🤚',
    category: 'People',
  },
  {
    emoji: '🖐',
    category: 'People',
  },
  {
    emoji: '🖖',
    category: 'People',
  },
  {
    emoji: '👋',
    category: 'People',
  },
  {
    emoji: '🤙',
    category: 'People',
  },
  {
    emoji: '💪',
    category: 'People',
  },
  {
    emoji: '✍️',
    category: 'People',
  },
  {
    emoji: '🤳',
    category: 'People',
  },
  {
    emoji: '💅',
    category: 'People',
  },
  {
    emoji: '💍',
    category: 'People',
  },
  {
    emoji: '💄',
    category: 'People',
  },
  {
    emoji: '💋',
    category: 'People',
  },
  {
    emoji: '👄',
    category: 'People',
  },
  {
    emoji: '👅',
    category: 'People',
  },
  {
    emoji: '👂',
    category: 'People',
  },
  {
    emoji: '👃',
    category: 'People',
  },
  {
    emoji: '👣',
    category: 'People',
  },
  {
    emoji: '👁',
    category: 'People',
  },
  {
    emoji: '👀',
    category: 'People',
  },
  {
    emoji: '🗣',
    category: 'People',
  },
  {
    emoji: '👤',
    category: 'People',
  },
  {
    emoji: '👥',
    category: 'People',
  },
  {
    emoji: '👶',
    category: 'People',
  },
  {
    emoji: '👦',
    category: 'People',
  },
  {
    emoji: '👧',
    category: 'People',
  },
  {
    emoji: '👨',
    category: 'People',
  },
  {
    emoji: '👩',
    category: 'People',
  },
  {
    emoji: '👱‍♀',
    category: 'People',
  },
  {
    emoji: '👱',
    category: 'People',
  },
  {
    emoji: '👴',
    category: 'People',
  },
  {
    emoji: '👵',
    category: 'People',
  },
  {
    emoji: '👲',
    category: 'People',
  },
  {
    emoji: '👳‍♀',
    category: 'People',
  },
  {
    emoji: '👳',
    category: 'People',
  },
  {
    emoji: '👮‍♀',
    category: 'People',
  },
  {
    emoji: '👮',
    category: 'People',
  },
  {
    emoji: '👷‍♀',
    category: 'People',
  },
  {
    emoji: '👷',
    category: 'People',
  },
  {
    emoji: '💂‍♀',
    category: 'People',
  },
  {
    emoji: '💂',
    category: 'People',
  },
  {
    emoji: '🕵️‍♀️',
    category: 'People',
  },
  {
    emoji: '🕵',
    category: 'People',
  },
  {
    emoji: '👩‍⚕',
    category: 'People',
  },
  {
    emoji: '👨‍⚕',
    category: 'People',
  },
  {
    emoji: '👩‍🌾',
    category: 'People',
  },
  {
    emoji: '👨‍🌾',
    category: 'People',
  },
  {
    emoji: '👩‍🍳',
    category: 'People',
  },
  {
    emoji: '👨‍🍳',
    category: 'People',
  },
  {
    emoji: '👩‍🎓',
    category: 'People',
  },
  {
    emoji: '👨‍🎓',
    category: 'People',
  },
  {
    emoji: '👩‍🎤',
    category: 'People',
  },
  {
    emoji: '👨‍🎤',
    category: 'People',
  },
  {
    emoji: '👩‍🏫',
    category: 'People',
  },
  {
    emoji: '👨‍🏫',
    category: 'People',
  },
  {
    emoji: '👩‍🏭',
    category: 'People',
  },
  {
    emoji: '👨‍🏭',
    category: 'People',
  },
  {
    emoji: '👩‍💻',
    category: 'People',
  },
  {
    emoji: '👨‍💻',
    category: 'People',
  },
  {
    emoji: '👩‍💼',
    category: 'People',
  },
  {
    emoji: '👨‍💼',
    category: 'People',
  },
  {
    emoji: '👩‍🔧',
    category: 'People',
  },
  {
    emoji: '👨‍🔧',
    category: 'People',
  },
  {
    emoji: '👩‍🔬',
    category: 'People',
  },
  {
    emoji: '👨‍🔬',
    category: 'People',
  },
  {
    emoji: '👩‍🎨',
    category: 'People',
  },
  {
    emoji: '👨‍🎨',
    category: 'People',
  },
  {
    emoji: '👩‍🚒',
    category: 'People',
  },
  {
    emoji: '👨‍🚒',
    category: 'People',
  },
  {
    emoji: '👩‍🚀',
    category: 'People',
  },
  {
    emoji: '👨‍🚀',
    category: 'People',
  },
  {
    emoji: '🤶',
    category: 'People',
  },
  {
    emoji: '🎅',
    category: 'People',
  },
  {
    emoji: '👸',
    category: 'People',
  },
  {
    emoji: '🤴',
    category: 'People',
  },
  {
    emoji: '👰',
    category: 'People',
  },
  {
    emoji: '🤵',
    category: 'People',
  },
  {
    emoji: '👼',
    category: 'People',
  },
  {
    emoji: '🤰',
    category: 'People',
  },
  {
    emoji: '🙇‍♀',
    category: 'People',
  },
  {
    emoji: '🙇',
    category: 'People',
  },
  {
    emoji: '💁',
    category: 'People',
  },
  {
    emoji: '💁‍♂',
    category: 'People',
  },
  {
    emoji: '🙅',
    category: 'People',
  },
  {
    emoji: '🙅‍♂',
    category: 'People',
  },
  {
    emoji: '🙆',
    category: 'People',
  },
  {
    emoji: '🙆‍♂',
    category: 'People',
  },
  {
    emoji: '🙋',
    category: 'People',
  },
  {
    emoji: '🙋‍♂',
    category: 'People',
  },
  {
    emoji: '🤦‍♀',
    category: 'People',
  },
  {
    emoji: '🤦‍♂',
    category: 'People',
  },
  {
    emoji: '🤷‍♀',
    category: 'People',
  },
  {
    emoji: '🤷‍♂',
    category: 'People',
  },
  {
    emoji: '🙎',
    category: 'People',
  },
  {
    emoji: '🙎‍♂',
    category: 'People',
  },
  {
    emoji: '🙍',
    category: 'People',
  },
  {
    emoji: '🙍‍♂',
    category: 'People',
  },
  {
    emoji: '💇',
    category: 'People',
  },
  {
    emoji: '💇‍♂',
    category: 'People',
  },
  {
    emoji: '💆',
    category: 'People',
  },
  {
    emoji: '💆‍♂',
    category: 'People',
  },
  {
    emoji: '🕴',
    category: 'People',
  },
  {
    emoji: '💃',
    category: 'People',
  },
  {
    emoji: '🕺',
    category: 'People',
  },
  {
    emoji: '👯',
    category: 'People',
  },
  {
    emoji: '👯‍♂',
    category: 'People',
  },
  {
    emoji: '🚶‍♀',
    category: 'People',
  },
  {
    emoji: '🚶',
    category: 'People',
  },
  {
    emoji: '🏃‍♀',
    category: 'People',
  },
  {
    emoji: '🏃',
    category: 'People',
  },
  {
    emoji: '👫',
    category: 'People',
  },
  {
    emoji: '👭',
    category: 'People',
  },
  {
    emoji: '👬',
    category: 'People',
  },
  {
    emoji: '💑',
    category: 'People',
  },
  {
    emoji: '👩‍❤️‍👩',
    category: 'People',
  },
  {
    emoji: '👨‍❤️‍👨',
    category: 'People',
  },
  {
    emoji: '💏',
    category: 'People',
  },
  {
    emoji: '👩‍❤️‍💋‍👩',
    category: 'People',
  },
  {
    emoji: '👨‍❤️‍💋‍👨',
    category: 'People',
  },
  {
    emoji: '👪',
    category: 'People',
  },
  {
    emoji: '👨‍👩‍👧',
    category: 'People',
  },
  {
    emoji: '👨‍👩‍👧‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👩‍👦‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👩‍👧‍👧',
    category: 'People',
  },
  {
    emoji: '👩‍👩‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👩‍👧',
    category: 'People',
  },
  {
    emoji: '👩‍👩‍👧‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👩‍👦‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👩‍👧‍👧',
    category: 'People',
  },
  {
    emoji: '👨‍👨‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👨‍👧',
    category: 'People',
  },
  {
    emoji: '👨‍👨‍👧‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👨‍👦‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👨‍👧‍👧',
    category: 'People',
  },
  {
    emoji: '👩‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👧',
    category: 'People',
  },
  {
    emoji: '👩‍👧‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👦‍👦',
    category: 'People',
  },
  {
    emoji: '👩‍👧‍👧',
    category: 'People',
  },
  {
    emoji: '👨‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👧',
    category: 'People',
  },
  {
    emoji: '👨‍👧‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👦‍👦',
    category: 'People',
  },
  {
    emoji: '👨‍👧‍👧',
    category: 'People',
  },
  {
    emoji: '👚',
    category: 'People',
  },
  {
    emoji: '👕',
    category: 'People',
  },
  {
    emoji: '👖',
    category: 'People',
  },
  {
    emoji: '👔',
    category: 'People',
  },
  {
    emoji: '👗',
    category: 'People',
  },
  {
    emoji: '👙',
    category: 'People',
  },
  {
    emoji: '👘',
    category: 'People',
  },
  {
    emoji: '👠',
    category: 'People',
  },
  {
    emoji: '👡',
    category: 'People',
  },
  {
    emoji: '👢',
    category: 'People',
  },
  {
    emoji: '👞',
    category: 'People',
  },
  {
    emoji: '👟',
    category: 'People',
  },
  {
    emoji: '👒',
    category: 'People',
  },
  {
    emoji: '🎩',
    category: 'People',
  },
  {
    emoji: '🎓',
    category: 'People',
  },
  {
    emoji: '👑',
    category: 'People',
  },
  {
    emoji: '⛑',
    category: 'People',
  },
  {
    emoji: '🎒',
    category: 'People',
  },
  {
    emoji: '👝',
    category: 'People',
  },
  {
    emoji: '👛',
    category: 'People',
  },
  {
    emoji: '👜',
    category: 'People',
  },
  {
    emoji: '💼',
    category: 'People',
  },
  {
    emoji: '👓',
    category: 'People',
  },
  {
    emoji: '🕶',
    category: 'People',
  },
  {
    emoji: '🌂',
    category: 'People',
  },
  {
    emoji: '☂️',
    category: 'People',
  },
  {
    emoji: '🐶',
    category: 'Nature',
  },
  {
    emoji: '🐱',
    category: 'Nature',
  },
  {
    emoji: '🐭',
    category: 'Nature',
  },
  {
    emoji: '🐹',
    category: 'Nature',
  },
  {
    emoji: '🐰',
    category: 'Nature',
  },
  {
    emoji: '🦊',
    category: 'Nature',
  },
  {
    emoji: '🐻',
    category: 'Nature',
  },
  {
    emoji: '🐼',
    category: 'Nature',
  },
  {
    emoji: '🐨',
    category: 'Nature',
  },
  {
    emoji: '🐯',
    category: 'Nature',
  },
  {
    emoji: '🦁',
    category: 'Nature',
  },
  {
    emoji: '🐮',
    category: 'Nature',
  },
  {
    emoji: '🐷',
    category: 'Nature',
  },
  {
    emoji: '🐽',
    category: 'Nature',
  },
  {
    emoji: '🐸',
    category: 'Nature',
  },
  {
    emoji: '🐵',
    category: 'Nature',
  },
  {
    emoji: '🙈',
    category: 'Nature',
  },
  {
    emoji: '🙉',
    category: 'Nature',
  },
  {
    emoji: '🙊',
    category: 'Nature',
  },
  {
    emoji: '🐒',
    category: 'Nature',
  },
  {
    emoji: '🐔',
    category: 'Nature',
  },
  {
    emoji: '🐧',
    category: 'Nature',
  },
  {
    emoji: '🐦',
    category: 'Nature',
  },
  {
    emoji: '🐤',
    category: 'Nature',
  },
  {
    emoji: '🐣',
    category: 'Nature',
  },
  {
    emoji: '🐥',
    category: 'Nature',
  },
  {
    emoji: '🦆',
    category: 'Nature',
  },
  {
    emoji: '🦅',
    category: 'Nature',
  },
  {
    emoji: '🦉',
    category: 'Nature',
  },
  {
    emoji: '🦇',
    category: 'Nature',
  },
  {
    emoji: '🐺',
    category: 'Nature',
  },
  {
    emoji: '🐗',
    category: 'Nature',
  },
  {
    emoji: '🐴',
    category: 'Nature',
  },
  {
    emoji: '🦄',
    category: 'Nature',
  },
  {
    emoji: '🐝',
    category: 'Nature',
  },
  {
    emoji: '🐛',
    category: 'Nature',
  },
  {
    emoji: '🦋',
    category: 'Nature',
  },
  {
    emoji: '🐌',
    category: 'Nature',
  },
  {
    emoji: '🐚',
    category: 'Nature',
  },
  {
    emoji: '🐞',
    category: 'Nature',
  },
  {
    emoji: '🐜',
    category: 'Nature',
  },
  {
    emoji: '🕷',
    category: 'Nature',
  },
  {
    emoji: '🕸',
    category: 'Nature',
  },
  {
    emoji: '🐢',
    category: 'Nature',
  },
  {
    emoji: '🐍',
    category: 'Nature',
  },
  {
    emoji: '🦎',
    category: 'Nature',
  },
  {
    emoji: '🦂',
    category: 'Nature',
  },
  {
    emoji: '🦀',
    category: 'Nature',
  },
  {
    emoji: '🦑',
    category: 'Nature',
  },
  {
    emoji: '🐙',
    category: 'Nature',
  },
  {
    emoji: '🦐',
    category: 'Nature',
  },
  {
    emoji: '🐠',
    category: 'Nature',
  },
  {
    emoji: '🐟',
    category: 'Nature',
  },
  {
    emoji: '🐡',
    category: 'Nature',
  },
  {
    emoji: '🐬',
    category: 'Nature',
  },
  {
    emoji: '🦈',
    category: 'Nature',
  },
  {
    emoji: '🐳',
    category: 'Nature',
  },
  {
    emoji: '🐋',
    category: 'Nature',
  },
  {
    emoji: '🐊',
    category: 'Nature',
  },
  {
    emoji: '🐆',
    category: 'Nature',
  },
  {
    emoji: '🐅',
    category: 'Nature',
  },
  {
    emoji: '🐃',
    category: 'Nature',
  },
  {
    emoji: '🐂',
    category: 'Nature',
  },
  {
    emoji: '🐄',
    category: 'Nature',
  },
  {
    emoji: '🦌',
    category: 'Nature',
  },
  {
    emoji: '🐪',
    category: 'Nature',
  },
  {
    emoji: '🐫',
    category: 'Nature',
  },
  {
    emoji: '🐘',
    category: 'Nature',
  },
  {
    emoji: '🦏',
    category: 'Nature',
  },
  {
    emoji: '🦍',
    category: 'Nature',
  },
  {
    emoji: '🐎',
    category: 'Nature',
  },
  {
    emoji: '🐖',
    category: 'Nature',
  },
  {
    emoji: '🐐',
    category: 'Nature',
  },
  {
    emoji: '🐏',
    category: 'Nature',
  },
  {
    emoji: '🐑',
    category: 'Nature',
  },
  {
    emoji: '🐕',
    category: 'Nature',
  },
  {
    emoji: '🐩',
    category: 'Nature',
  },
  {
    emoji: '🐈',
    category: 'Nature',
  },
  {
    emoji: '🐓',
    category: 'Nature',
  },
  {
    emoji: '🦃',
    category: 'Nature',
  },
  {
    emoji: '🕊',
    category: 'Nature',
  },
  {
    emoji: '🐇',
    category: 'Nature',
  },
  {
    emoji: '🐁',
    category: 'Nature',
  },
  {
    emoji: '🐀',
    category: 'Nature',
  },
  {
    emoji: '🐿',
    category: 'Nature',
  },
  {
    emoji: '🐾',
    category: 'Nature',
  },
  {
    emoji: '🐉',
    category: 'Nature',
  },
  {
    emoji: '🐲',
    category: 'Nature',
  },
  {
    emoji: '🌵',
    category: 'Nature',
  },
  {
    emoji: '🎄',
    category: 'Nature',
  },
  {
    emoji: '🌲',
    category: 'Nature',
  },
  {
    emoji: '🌳',
    category: 'Nature',
  },
  {
    emoji: '🌴',
    category: 'Nature',
  },
  {
    emoji: '🌱',
    category: 'Nature',
  },
  {
    emoji: '🌿',
    category: 'Nature',
  },
  {
    emoji: '☘️',
    category: 'Nature',
  },
  {
    emoji: '🍀',
    category: 'Nature',
  },
  {
    emoji: '🎍',
    category: 'Nature',
  },
  {
    emoji: '🎋',
    category: 'Nature',
  },
  {
    emoji: '🍃',
    category: 'Nature',
  },
  {
    emoji: '🍂',
    category: 'Nature',
  },
  {
    emoji: '🍁',
    category: 'Nature',
  },
  {
    emoji: '🍄',
    category: 'Nature',
  },
  {
    emoji: '🌾',
    category: 'Nature',
  },
  {
    emoji: '💐',
    category: 'Nature',
  },
  {
    emoji: '🌷',
    category: 'Nature',
  },
  {
    emoji: '🌹',
    category: 'Nature',
  },
  {
    emoji: '🥀',
    category: 'Nature',
  },
  {
    emoji: '🌻',
    category: 'Nature',
  },
  {
    emoji: '🌼',
    category: 'Nature',
  },
  {
    emoji: '🌸',
    category: 'Nature',
  },
  {
    emoji: '🌺',
    category: 'Nature',
  },
  {
    emoji: '🌎',
    category: 'Nature',
  },
  {
    emoji: '🌍',
    category: 'Nature',
  },
  {
    emoji: '🌏',
    category: 'Nature',
  },
  {
    emoji: '🌕',
    category: 'Nature',
  },
  {
    emoji: '🌖',
    category: 'Nature',
  },
  {
    emoji: '🌗',
    category: 'Nature',
  },
  {
    emoji: '🌘',
    category: 'Nature',
  },
  {
    emoji: '🌑',
    category: 'Nature',
  },
  {
    emoji: '🌒',
    category: 'Nature',
  },
  {
    emoji: '🌓',
    category: 'Nature',
  },
  {
    emoji: '🌔',
    category: 'Nature',
  },
  {
    emoji: '🌚',
    category: 'Nature',
  },
  {
    emoji: '🌝',
    category: 'Nature',
  },
  {
    emoji: '🌞',
    category: 'Nature',
  },
  {
    emoji: '🌛',
    category: 'Nature',
  },
  {
    emoji: '🌜',
    category: 'Nature',
  },
  {
    emoji: '🌙',
    category: 'Nature',
  },
  {
    emoji: '💫',
    category: 'Nature',
  },
  {
    emoji: '⭐️',
    category: 'Nature',
  },
  {
    emoji: '🌟',
    category: 'Nature',
  },
  {
    emoji: '✨',
    category: 'Nature',
  },
  {
    emoji: '⚡️',
    category: 'Nature',
  },
  {
    emoji: '🔥',
    category: 'Nature',
  },
  {
    emoji: '💥',
    category: 'Nature',
  },
  {
    emoji: '☄',
    category: 'Nature',
  },
  {
    emoji: '☀️',
    category: 'Nature',
  },
  {
    emoji: '🌤',
    category: 'Nature',
  },
  {
    emoji: '⛅️',
    category: 'Nature',
  },
  {
    emoji: '🌥',
    category: 'Nature',
  },
  {
    emoji: '🌦',
    category: 'Nature',
  },
  {
    emoji: '🌈',
    category: 'Nature',
  },
  {
    emoji: '☁️',
    category: 'Nature',
  },
  {
    emoji: '🌧',
    category: 'Nature',
  },
  {
    emoji: '⛈',
    category: 'Nature',
  },
  {
    emoji: '🌩',
    category: 'Nature',
  },
  {
    emoji: '🌨',
    category: 'Nature',
  },
  {
    emoji: '☃️',
    category: 'Nature',
  },
  {
    emoji: '⛄️',
    category: 'Nature',
  },
  {
    emoji: '❄️',
    category: 'Nature',
  },
  {
    emoji: '🌬',
    category: 'Nature',
  },
  {
    emoji: '💨',
    category: 'Nature',
  },
  {
    emoji: '🌪',
    category: 'Nature',
  },
  {
    emoji: '🌫',
    category: 'Nature',
  },
  {
    emoji: '🌊',
    category: 'Nature',
  },
  {
    emoji: '💧',
    category: 'Nature',
  },
  {
    emoji: '💦',
    category: 'Nature',
  },
  {
    emoji: '☔️',
    category: 'Nature',
  },
  {
    emoji: '🍏',
    category: 'Foods',
  },
  {
    emoji: '🍎',
    category: 'Foods',
  },
  {
    emoji: '🍐',
    category: 'Foods',
  },
  {
    emoji: '🍊',
    category: 'Foods',
  },
  {
    emoji: '🍋',
    category: 'Foods',
  },
  {
    emoji: '🍌',
    category: 'Foods',
  },
  {
    emoji: '🍉',
    category: 'Foods',
  },
  {
    emoji: '🍇',
    category: 'Foods',
  },
  {
    emoji: '🍓',
    category: 'Foods',
  },
  {
    emoji: '🍈',
    category: 'Foods',
  },
  {
    emoji: '🍒',
    category: 'Foods',
  },
  {
    emoji: '🍑',
    category: 'Foods',
  },
  {
    emoji: '🍍',
    category: 'Foods',
  },
  {
    emoji: '🥝',
    category: 'Foods',
  },
  {
    emoji: '🥑',
    category: 'Foods',
  },
  {
    emoji: '🍅',
    category: 'Foods',
  },
  {
    emoji: '🍆',
    category: 'Foods',
  },
  {
    emoji: '🥒',
    category: 'Foods',
  },
  {
    emoji: '🥕',
    category: 'Foods',
  },
  {
    emoji: '🌽',
    category: 'Foods',
  },
  {
    emoji: '🌶',
    category: 'Foods',
  },
  {
    emoji: '🥔',
    category: 'Foods',
  },
  {
    emoji: '🍠',
    category: 'Foods',
  },
  {
    emoji: '🌰',
    category: 'Foods',
  },
  {
    emoji: '🥜',
    category: 'Foods',
  },
  {
    emoji: '🍯',
    category: 'Foods',
  },
  {
    emoji: '🥐',
    category: 'Foods',
  },
  {
    emoji: '🍞',
    category: 'Foods',
  },
  {
    emoji: '🥖',
    category: 'Foods',
  },
  {
    emoji: '🧀',
    category: 'Foods',
  },
  {
    emoji: '🥚',
    category: 'Foods',
  },
  {
    emoji: '🍳',
    category: 'Foods',
  },
  {
    emoji: '🥓',
    category: 'Foods',
  },
  {
    emoji: '🥞',
    category: 'Foods',
  },
  {
    emoji: '🍤',
    category: 'Foods',
  },
  {
    emoji: '🍗',
    category: 'Foods',
  },
  {
    emoji: '🍖',
    category: 'Foods',
  },
  {
    emoji: '🍕',
    category: 'Foods',
  },
  {
    emoji: '🌭',
    category: 'Foods',
  },
  {
    emoji: '🍔',
    category: 'Foods',
  },
  {
    emoji: '🍟',
    category: 'Foods',
  },
  {
    emoji: '🥙',
    category: 'Foods',
  },
  {
    emoji: '🌮',
    category: 'Foods',
  },
  {
    emoji: '🌯',
    category: 'Foods',
  },
  {
    emoji: '🥗',
    category: 'Foods',
  },
  {
    emoji: '🥘',
    category: 'Foods',
  },
  {
    emoji: '🍝',
    category: 'Foods',
  },
  {
    emoji: '🍜',
    category: 'Foods',
  },
  {
    emoji: '🍲',
    category: 'Foods',
  },
  {
    emoji: '🍥',
    category: 'Foods',
  },
  {
    emoji: '🍣',
    category: 'Foods',
  },
  {
    emoji: '🍱',
    category: 'Foods',
  },
  {
    emoji: '🍛',
    category: 'Foods',
  },
  {
    emoji: '🍚',
    category: 'Foods',
  },
  {
    emoji: '🍙',
    category: 'Foods',
  },
  {
    emoji: '🍘',
    category: 'Foods',
  },
  {
    emoji: '🍢',
    category: 'Foods',
  },
  {
    emoji: '🍡',
    category: 'Foods',
  },
  {
    emoji: '🍧',
    category: 'Foods',
  },
  {
    emoji: '🍨',
    category: 'Foods',
  },
  {
    emoji: '🍦',
    category: 'Foods',
  },
  {
    emoji: '🍰',
    category: 'Foods',
  },
  {
    emoji: '🎂',
    category: 'Foods',
  },
  {
    emoji: '🍮',
    category: 'Foods',
  },
  {
    emoji: '🍭',
    category: 'Foods',
  },
  {
    emoji: '🍬',
    category: 'Foods',
  },
  {
    emoji: '🍫',
    category: 'Foods',
  },
  {
    emoji: '🍿',
    category: 'Foods',
  },
  {
    emoji: '🍩',
    category: 'Foods',
  },
  {
    emoji: '🍪',
    category: 'Foods',
  },
  {
    emoji: '🥛',
    category: 'Foods',
  },
  {
    emoji: '🍼',
    category: 'Foods',
  },
  {
    emoji: '☕️',
    category: 'Foods',
  },
  {
    emoji: '🍵',
    category: 'Foods',
  },
  {
    emoji: '🍶',
    category: 'Foods',
  },
  {
    emoji: '🍺',
    category: 'Foods',
  },
  {
    emoji: '🍻',
    category: 'Foods',
  },
  {
    emoji: '🥂',
    category: 'Foods',
  },
  {
    emoji: '🍷',
    category: 'Foods',
  },
  {
    emoji: '🥃',
    category: 'Foods',
  },
  {
    emoji: '🍸',
    category: 'Foods',
  },
  {
    emoji: '🍹',
    category: 'Foods',
  },
  {
    emoji: '🍾',
    category: 'Foods',
  },
  {
    emoji: '🥄',
    category: 'Foods',
  },
  {
    emoji: '🍴',
    category: 'Foods',
  },
  {
    emoji: '🍽',
    category: 'Foods',
  },
  {
    emoji: '⚽️',
    category: 'Activity',
  },
  {
    emoji: '🏀',
    category: 'Activity',
  },
  {
    emoji: '🏈',
    category: 'Activity',
  },
  {
    emoji: '⚾️',
    category: 'Activity',
  },
  {
    emoji: '🎾',
    category: 'Activity',
  },
  {
    emoji: '🏐',
    category: 'Activity',
  },
  {
    emoji: '🏉',
    category: 'Activity',
  },
  {
    emoji: '🎱',
    category: 'Activity',
  },
  {
    emoji: '🏓',
    category: 'Activity',
  },
  {
    emoji: '🏸',
    category: 'Activity',
  },
  {
    emoji: '🥅',
    category: 'Activity',
  },
  {
    emoji: '🏒',
    category: 'Activity',
  },
  {
    emoji: '🏑',
    category: 'Activity',
  },
  {
    emoji: '🏏',
    category: 'Activity',
  },
  {
    emoji: '⛳️',
    category: 'Activity',
  },
  {
    emoji: '🏹',
    category: 'Activity',
  },
  {
    emoji: '🎣',
    category: 'Activity',
  },
  {
    emoji: '🥊',
    category: 'Activity',
  },
  {
    emoji: '🥋',
    category: 'Activity',
  },
  {
    emoji: '⛸',
    category: 'Activity',
  },
  {
    emoji: '🎿',
    category: 'Activity',
  },
  {
    emoji: '⛷',
    category: 'Activity',
  },
  {
    emoji: '🏂',
    category: 'Activity',
  },
  {
    emoji: '🏋️‍♀️',
    category: 'Activity',
  },
  {
    emoji: '🏋',
    category: 'Activity',
  },
  {
    emoji: '🤺',
    category: 'Activity',
  },
  {
    emoji: '🤼‍♀',
    category: 'Activity',
  },
  {
    emoji: '🤼‍♂',
    category: 'Activity',
  },
  {
    emoji: '🤸‍♀',
    category: 'Activity',
  },
  {
    emoji: '🤸‍♂',
    category: 'Activity',
  },
  {
    emoji: '⛹️‍♀️',
    category: 'Activity',
  },
  {
    emoji: '⛹',
    category: 'Activity',
  },
  {
    emoji: '🤾‍♀',
    category: 'Activity',
  },
  {
    emoji: '🤾‍♂',
    category: 'Activity',
  },
  {
    emoji: '🏌️‍♀️',
    category: 'Activity',
  },
  {
    emoji: '🏌',
    category: 'Activity',
  },
  {
    emoji: '🏄‍♀',
    category: 'Activity',
  },
  {
    emoji: '🏄',
    category: 'Activity',
  },
  {
    emoji: '🏊‍♀',
    category: 'Activity',
  },
  {
    emoji: '🏊',
    category: 'Activity',
  },
  {
    emoji: '🤽‍♀',
    category: 'Activity',
  },
  {
    emoji: '🤽‍♂',
    category: 'Activity',
  },
  {
    emoji: '🚣‍♀',
    category: 'Activity',
  },
  {
    emoji: '🚣',
    category: 'Activity',
  },
  {
    emoji: '🏇',
    category: 'Activity',
  },
  {
    emoji: '🚴‍♀',
    category: 'Activity',
  },
  {
    emoji: '🚴',
    category: 'Activity',
  },
  {
    emoji: '🚵‍♀',
    category: 'Activity',
  },
  {
    emoji: '🚵',
    category: 'Activity',
  },
  {
    emoji: '🎽',
    category: 'Activity',
  },
  {
    emoji: '🏅',
    category: 'Activity',
  },
  {
    emoji: '🎖',
    category: 'Activity',
  },
  {
    emoji: '🥇',
    category: 'Activity',
  },
  {
    emoji: '🥈',
    category: 'Activity',
  },
  {
    emoji: '🥉',
    category: 'Activity',
  },
  {
    emoji: '🏆',
    category: 'Activity',
  },
  {
    emoji: '🏵',
    category: 'Activity',
  },
  {
    emoji: '🎗',
    category: 'Activity',
  },
  {
    emoji: '🎫',
    category: 'Activity',
  },
  {
    emoji: '🎟',
    category: 'Activity',
  },
  {
    emoji: '🎪',
    category: 'Activity',
  },
  {
    emoji: '🤹‍♀',
    category: 'Activity',
  },
  {
    emoji: '🤹‍♂',
    category: 'Activity',
  },
  {
    emoji: '🎭',
    category: 'Activity',
  },
  {
    emoji: '🎨',
    category: 'Activity',
  },
  {
    emoji: '🎬',
    category: 'Activity',
  },
  {
    emoji: '🎤',
    category: 'Activity',
  },
  {
    emoji: '🎧',
    category: 'Activity',
  },
  {
    emoji: '🎼',
    category: 'Activity',
  },
  {
    emoji: '🎹',
    category: 'Activity',
  },
  {
    emoji: '🥁',
    category: 'Activity',
  },
  {
    emoji: '🎷',
    category: 'Activity',
  },
  {
    emoji: '🎺',
    category: 'Activity',
  },
  {
    emoji: '🎸',
    category: 'Activity',
  },
  {
    emoji: '🎻',
    category: 'Activity',
  },
  {
    emoji: '🎲',
    category: 'Activity',
  },
  {
    emoji: '🎯',
    category: 'Activity',
  },
  {
    emoji: '🎳',
    category: 'Activity',
  },
  {
    emoji: '🎮',
    category: 'Activity',
  },
  {
    emoji: '🎰',
    category: 'Activity',
  },
  {
    emoji: '🚗',
    category: 'Places',
  },
  {
    emoji: '🚕',
    category: 'Places',
  },
  {
    emoji: '🚙',
    category: 'Places',
  },
  {
    emoji: '🚌',
    category: 'Places',
  },
  {
    emoji: '🚎',
    category: 'Places',
  },
  {
    emoji: '🏎',
    category: 'Places',
  },
  {
    emoji: '🚓',
    category: 'Places',
  },
  {
    emoji: '🚑',
    category: 'Places',
  },
  {
    emoji: '🚒',
    category: 'Places',
  },
  {
    emoji: '🚐',
    category: 'Places',
  },
  {
    emoji: '🚚',
    category: 'Places',
  },
  {
    emoji: '🚛',
    category: 'Places',
  },
  {
    emoji: '🚜',
    category: 'Places',
  },
  {
    emoji: '🛴',
    category: 'Places',
  },
  {
    emoji: '🚲',
    category: 'Places',
  },
  {
    emoji: '🛵',
    category: 'Places',
  },
  {
    emoji: '🏍',
    category: 'Places',
  },
  {
    emoji: '🚨',
    category: 'Places',
  },
  {
    emoji: '🚔',
    category: 'Places',
  },
  {
    emoji: '🚍',
    category: 'Places',
  },
  {
    emoji: '🚘',
    category: 'Places',
  },
  {
    emoji: '🚖',
    category: 'Places',
  },
  {
    emoji: '🚡',
    category: 'Places',
  },
  {
    emoji: '🚠',
    category: 'Places',
  },
  {
    emoji: '🚟',
    category: 'Places',
  },
  {
    emoji: '🚃',
    category: 'Places',
  },
  {
    emoji: '🚋',
    category: 'Places',
  },
  {
    emoji: '🚞',
    category: 'Places',
  },
  {
    emoji: '🚝',
    category: 'Places',
  },
  {
    emoji: '🚄',
    category: 'Places',
  },
  {
    emoji: '🚅',
    category: 'Places',
  },
  {
    emoji: '🚈',
    category: 'Places',
  },
  {
    emoji: '🚂',
    category: 'Places',
  },
  {
    emoji: '🚆',
    category: 'Places',
  },
  {
    emoji: '🚇',
    category: 'Places',
  },
  {
    emoji: '🚊',
    category: 'Places',
  },
  {
    emoji: '🚉',
    category: 'Places',
  },
  {
    emoji: '🚁',
    category: 'Places',
  },
  {
    emoji: '🛩',
    category: 'Places',
  },
  {
    emoji: '✈️',
    category: 'Places',
  },
  {
    emoji: '🛫',
    category: 'Places',
  },
  {
    emoji: '🛬',
    category: 'Places',
  },
  {
    emoji: '🚀',
    category: 'Places',
  },
  {
    emoji: '🛰',
    category: 'Places',
  },
  {
    emoji: '💺',
    category: 'Places',
  },
  {
    emoji: '🛶',
    category: 'Places',
  },
  {
    emoji: '⛵️',
    category: 'Places',
  },
  {
    emoji: '🛥',
    category: 'Places',
  },
  {
    emoji: '🚤',
    category: 'Places',
  },
  {
    emoji: '🛳',
    category: 'Places',
  },
  {
    emoji: '⛴',
    category: 'Places',
  },
  {
    emoji: '🚢',
    category: 'Places',
  },
  {
    emoji: '⚓️',
    category: 'Places',
  },
  {
    emoji: '🚧',
    category: 'Places',
  },
  {
    emoji: '⛽️',
    category: 'Places',
  },
  {
    emoji: '🚏',
    category: 'Places',
  },
  {
    emoji: '🚦',
    category: 'Places',
  },
  {
    emoji: '🚥',
    category: 'Places',
  },
  {
    emoji: '🗺',
    category: 'Places',
  },
  {
    emoji: '🗿',
    category: 'Places',
  },
  {
    emoji: '🗽',
    category: 'Places',
  },
  {
    emoji: '⛲️',
    category: 'Places',
  },
  {
    emoji: '🗼',
    category: 'Places',
  },
  {
    emoji: '🏰',
    category: 'Places',
  },
  {
    emoji: '🏯',
    category: 'Places',
  },
  {
    emoji: '🏟',
    category: 'Places',
  },
  {
    emoji: '🎡',
    category: 'Places',
  },
  {
    emoji: '🎢',
    category: 'Places',
  },
  {
    emoji: '🎠',
    category: 'Places',
  },
  {
    emoji: '⛱',
    category: 'Places',
  },
  {
    emoji: '🏖',
    category: 'Places',
  },
  {
    emoji: '🏝',
    category: 'Places',
  },
  {
    emoji: '⛰',
    category: 'Places',
  },
  {
    emoji: '🏔',
    category: 'Places',
  },
  {
    emoji: '🗻',
    category: 'Places',
  },
  {
    emoji: '🌋',
    category: 'Places',
  },
  {
    emoji: '🏜',
    category: 'Places',
  },
  {
    emoji: '🏕',
    category: 'Places',
  },
  {
    emoji: '⛺️',
    category: 'Places',
  },
  {
    emoji: '🛤',
    category: 'Places',
  },
  {
    emoji: '🛣',
    category: 'Places',
  },
  {
    emoji: '🏗',
    category: 'Places',
  },
  {
    emoji: '🏭',
    category: 'Places',
  },
  {
    emoji: '🏠',
    category: 'Places',
  },
  {
    emoji: '🏡',
    category: 'Places',
  },
  {
    emoji: '🏘',
    category: 'Places',
  },
  {
    emoji: '🏚',
    category: 'Places',
  },
  {
    emoji: '🏢',
    category: 'Places',
  },
  {
    emoji: '🏬',
    category: 'Places',
  },
  {
    emoji: '🏣',
    category: 'Places',
  },
  {
    emoji: '🏤',
    category: 'Places',
  },
  {
    emoji: '🏥',
    category: 'Places',
  },
  {
    emoji: '🏦',
    category: 'Places',
  },
  {
    emoji: '🏨',
    category: 'Places',
  },
  {
    emoji: '🏪',
    category: 'Places',
  },
  {
    emoji: '🏫',
    category: 'Places',
  },
  {
    emoji: '🏩',
    category: 'Places',
  },
  {
    emoji: '💒',
    category: 'Places',
  },
  {
    emoji: '🏛',
    category: 'Places',
  },
  {
    emoji: '⛪️',
    category: 'Places',
  },
  {
    emoji: '🕌',
    category: 'Places',
  },
  {
    emoji: '🕍',
    category: 'Places',
  },
  {
    emoji: '🕋',
    category: 'Places',
  },
  {
    emoji: '⛩',
    category: 'Places',
  },
  {
    emoji: '🗾',
    category: 'Places',
  },
  {
    emoji: '🎑',
    category: 'Places',
  },
  {
    emoji: '🏞',
    category: 'Places',
  },
  {
    emoji: '🌅',
    category: 'Places',
  },
  {
    emoji: '🌄',
    category: 'Places',
  },
  {
    emoji: '🌠',
    category: 'Places',
  },
  {
    emoji: '🎇',
    category: 'Places',
  },
  {
    emoji: '🎆',
    category: 'Places',
  },
  {
    emoji: '🌇',
    category: 'Places',
  },
  {
    emoji: '🌆',
    category: 'Places',
  },
  {
    emoji: '🏙',
    category: 'Places',
  },
  {
    emoji: '🌃',
    category: 'Places',
  },
  {
    emoji: '🌌',
    category: 'Places',
  },
  {
    emoji: '🌉',
    category: 'Places',
  },
  {
    emoji: '🌁',
    category: 'Places',
  },
  {
    emoji: '⌚️',
    category: 'Objects',
  },
  {
    emoji: '📱',
    category: 'Objects',
  },
  {
    emoji: '📲',
    category: 'Objects',
  },
  {
    emoji: '💻',
    category: 'Objects',
  },
  {
    emoji: '⌨️',
    category: 'Objects',
  },
  {
    emoji: '🖥',
    category: 'Objects',
  },
  {
    emoji: '🖨',
    category: 'Objects',
  },
  {
    emoji: '🖱',
    category: 'Objects',
  },
  {
    emoji: '🖲',
    category: 'Objects',
  },
  {
    emoji: '🕹',
    category: 'Objects',
  },
  {
    emoji: '🗜',
    category: 'Objects',
  },
  {
    emoji: '💽',
    category: 'Objects',
  },
  {
    emoji: '💾',
    category: 'Objects',
  },
  {
    emoji: '💿',
    category: 'Objects',
  },
  {
    emoji: '📀',
    category: 'Objects',
  },
  {
    emoji: '📼',
    category: 'Objects',
  },
  {
    emoji: '📷',
    category: 'Objects',
  },
  {
    emoji: '📸',
    category: 'Objects',
  },
  {
    emoji: '📹',
    category: 'Objects',
  },
  {
    emoji: '🎥',
    category: 'Objects',
  },
  {
    emoji: '📽',
    category: 'Objects',
  },
  {
    emoji: '🎞',
    category: 'Objects',
  },
  {
    emoji: '📞',
    category: 'Objects',
  },
  {
    emoji: '☎️',
    category: 'Objects',
  },
  {
    emoji: '📟',
    category: 'Objects',
  },
  {
    emoji: '📠',
    category: 'Objects',
  },
  {
    emoji: '📺',
    category: 'Objects',
  },
  {
    emoji: '📻',
    category: 'Objects',
  },
  {
    emoji: '🎙',
    category: 'Objects',
  },
  {
    emoji: '🎚',
    category: 'Objects',
  },
  {
    emoji: '🎛',
    category: 'Objects',
  },
  {
    emoji: '⏱',
    category: 'Objects',
  },
  {
    emoji: '⏲',
    category: 'Objects',
  },
  {
    emoji: '⏰',
    category: 'Objects',
  },
  {
    emoji: '🕰',
    category: 'Objects',
  },
  {
    emoji: '⌛️',
    category: 'Objects',
  },
  {
    emoji: '⏳',
    category: 'Objects',
  },
  {
    emoji: '📡',
    category: 'Objects',
  },
  {
    emoji: '🔋',
    category: 'Objects',
  },
  {
    emoji: '🔌',
    category: 'Objects',
  },
  {
    emoji: '💡',
    category: 'Objects',
  },
  {
    emoji: '🔦',
    category: 'Objects',
  },
  {
    emoji: '🕯',
    category: 'Objects',
  },
  {
    emoji: '🗑',
    category: 'Objects',
  },
  {
    emoji: '🛢',
    category: 'Objects',
  },
  {
    emoji: '💸',
    category: 'Objects',
  },
  {
    emoji: '💵',
    category: 'Objects',
  },
  {
    emoji: '💴',
    category: 'Objects',
  },
  {
    emoji: '💶',
    category: 'Objects',
  },
  {
    emoji: '💷',
    category: 'Objects',
  },
  {
    emoji: '💰',
    category: 'Objects',
  },
  {
    emoji: '💳',
    category: 'Objects',
  },
  {
    emoji: '💎',
    category: 'Objects',
  },
  {
    emoji: '⚖️',
    category: 'Objects',
  },
  {
    emoji: '🔧',
    category: 'Objects',
  },
  {
    emoji: '🔨',
    category: 'Objects',
  },
  {
    emoji: '⚒',
    category: 'Objects',
  },
  {
    emoji: '🛠',
    category: 'Objects',
  },
  {
    emoji: '⛏',
    category: 'Objects',
  },
  {
    emoji: '🔩',
    category: 'Objects',
  },
  {
    emoji: '⚙️',
    category: 'Objects',
  },
  {
    emoji: '⛓',
    category: 'Objects',
  },
  {
    emoji: '🔫',
    category: 'Objects',
  },
  {
    emoji: '💣',
    category: 'Objects',
  },
  {
    emoji: '🔪',
    category: 'Objects',
  },
  {
    emoji: '🗡',
    category: 'Objects',
  },
  {
    emoji: '⚔️',
    category: 'Objects',
  },
  {
    emoji: '🛡',
    category: 'Objects',
  },
  {
    emoji: '🚬',
    category: 'Objects',
  },
  {
    emoji: '⚰️',
    category: 'Objects',
  },
  {
    emoji: '⚱️',
    category: 'Objects',
  },
  {
    emoji: '🏺',
    category: 'Objects',
  },
  {
    emoji: '🔮',
    category: 'Objects',
  },
  {
    emoji: '📿',
    category: 'Objects',
  },
  {
    emoji: '💈',
    category: 'Objects',
  },
  {
    emoji: '⚗️',
    category: 'Objects',
  },
  {
    emoji: '🔭',
    category: 'Objects',
  },
  {
    emoji: '🔬',
    category: 'Objects',
  },
  {
    emoji: '🕳',
    category: 'Objects',
  },
  {
    emoji: '💊',
    category: 'Objects',
  },
  {
    emoji: '💉',
    category: 'Objects',
  },
  {
    emoji: '🌡',
    category: 'Objects',
  },
  {
    emoji: '🚽',
    category: 'Objects',
  },
  {
    emoji: '🚰',
    category: 'Objects',
  },
  {
    emoji: '🚿',
    category: 'Objects',
  },
  {
    emoji: '🛁',
    category: 'Objects',
  },
  {
    emoji: '🛀',
    category: 'Objects',
  },
  {
    emoji: '🛎',
    category: 'Objects',
  },
  {
    emoji: '🔑',
    category: 'Objects',
  },
  {
    emoji: '🗝',
    category: 'Objects',
  },
  {
    emoji: '🚪',
    category: 'Objects',
  },
  {
    emoji: '🛋',
    category: 'Objects',
  },
  {
    emoji: '🛏',
    category: 'Objects',
  },
  {
    emoji: '🛌',
    category: 'Objects',
  },
  {
    emoji: '🖼',
    category: 'Objects',
  },
  {
    emoji: '🛍',
    category: 'Objects',
  },
  {
    emoji: '🛒',
    category: 'Objects',
  },
  {
    emoji: '🎁',
    category: 'Objects',
  },
  {
    emoji: '🎈',
    category: 'Objects',
  },
  {
    emoji: '🎏',
    category: 'Objects',
  },
  {
    emoji: '🎀',
    category: 'Objects',
  },
  {
    emoji: '🎊',
    category: 'Objects',
  },
  {
    emoji: '🎉',
    category: 'Objects',
  },
  {
    emoji: '🎎',
    category: 'Objects',
  },
  {
    emoji: '🏮',
    category: 'Objects',
  },
  {
    emoji: '🎐',
    category: 'Objects',
  },
  {
    emoji: '✉️',
    category: 'Objects',
  },
  {
    emoji: '📩',
    category: 'Objects',
  },
  {
    emoji: '📨',
    category: 'Objects',
  },
  {
    emoji: '📧',
    category: 'Objects',
  },
  {
    emoji: '💌',
    category: 'Objects',
  },
  {
    emoji: '📥',
    category: 'Objects',
  },
  {
    emoji: '📤',
    category: 'Objects',
  },
  {
    emoji: '📦',
    category: 'Objects',
  },
  {
    emoji: '🏷',
    category: 'Objects',
  },
  {
    emoji: '📪',
    category: 'Objects',
  },
  {
    emoji: '📫',
    category: 'Objects',
  },
  {
    emoji: '📬',
    category: 'Objects',
  },
  {
    emoji: '📭',
    category: 'Objects',
  },
  {
    emoji: '📮',
    category: 'Objects',
  },
  {
    emoji: '📯',
    category: 'Objects',
  },
  {
    emoji: '📜',
    category: 'Objects',
  },
  {
    emoji: '📃',
    category: 'Objects',
  },
  {
    emoji: '📄',
    category: 'Objects',
  },
  {
    emoji: '📑',
    category: 'Objects',
  },
  {
    emoji: '📊',
    category: 'Objects',
  },
  {
    emoji: '📈',
    category: 'Objects',
  },
  {
    emoji: '📉',
    category: 'Objects',
  },
  {
    emoji: '🗒',
    category: 'Objects',
  },
  {
    emoji: '🗓',
    category: 'Objects',
  },
  {
    emoji: '📆',
    category: 'Objects',
  },
  {
    emoji: '📅',
    category: 'Objects',
  },
  {
    emoji: '📇',
    category: 'Objects',
  },
  {
    emoji: '🗃',
    category: 'Objects',
  },
  {
    emoji: '🗳',
    category: 'Objects',
  },
  {
    emoji: '🗄',
    category: 'Objects',
  },
  {
    emoji: '📋',
    category: 'Objects',
  },
  {
    emoji: '📁',
    category: 'Objects',
  },
  {
    emoji: '📂',
    category: 'Objects',
  },
  {
    emoji: '🗂',
    category: 'Objects',
  },
  {
    emoji: '🗞',
    category: 'Objects',
  },
  {
    emoji: '📰',
    category: 'Objects',
  },
  {
    emoji: '📓',
    category: 'Objects',
  },
  {
    emoji: '📔',
    category: 'Objects',
  },
  {
    emoji: '📒',
    category: 'Objects',
  },
  {
    emoji: '📕',
    category: 'Objects',
  },
  {
    emoji: '📗',
    category: 'Objects',
  },
  {
    emoji: '📘',
    category: 'Objects',
  },
  {
    emoji: '📙',
    category: 'Objects',
  },
  {
    emoji: '📚',
    category: 'Objects',
  },
  {
    emoji: '📖',
    category: 'Objects',
  },
  {
    emoji: '🔖',
    category: 'Objects',
  },
  {
    emoji: '🔗',
    category: 'Objects',
  },
  {
    emoji: '📎',
    category: 'Objects',
  },
  {
    emoji: '🖇',
    category: 'Objects',
  },
  {
    emoji: '📐',
    category: 'Objects',
  },
  {
    emoji: '📏',
    category: 'Objects',
  },
  {
    emoji: '📌',
    category: 'Objects',
  },
  {
    emoji: '📍',
    category: 'Objects',
  },
  {
    emoji: '✂️',
    category: 'Objects',
  },
  {
    emoji: '🖊',
    category: 'Objects',
  },
  {
    emoji: '🖋',
    category: 'Objects',
  },
  {
    emoji: '✒️',
    category: 'Objects',
  },
  {
    emoji: '🖌',
    category: 'Objects',
  },
  {
    emoji: '🖍',
    category: 'Objects',
  },
  {
    emoji: '📝',
    category: 'Objects',
  },
  {
    emoji: '✏️',
    category: 'Objects',
  },
  {
    emoji: '🔍',
    category: 'Objects',
  },
  {
    emoji: '🔎',
    category: 'Objects',
  },
  {
    emoji: '🔏',
    category: 'Objects',
  },
  {
    emoji: '🔐',
    category: 'Objects',
  },
  {
    emoji: '🔒',
    category: 'Objects',
  },
  {
    emoji: '🔓',
    category: 'Objects',
  },
  {
    emoji: '❤️',
    category: 'Symbols',
  },
  {
    emoji: '💛',
    category: 'Symbols',
  },
  {
    emoji: '💚',
    category: 'Symbols',
  },
  {
    emoji: '💙',
    category: 'Symbols',
  },
  {
    emoji: '💜',
    category: 'Symbols',
  },
  {
    emoji: '🖤',
    category: 'Symbols',
  },
  {
    emoji: '💔',
    category: 'Symbols',
  },
  {
    emoji: '❣️',
    category: 'Symbols',
  },
  {
    emoji: '💕',
    category: 'Symbols',
  },
  {
    emoji: '💞',
    category: 'Symbols',
  },
  {
    emoji: '💓',
    category: 'Symbols',
  },
  {
    emoji: '💗',
    category: 'Symbols',
  },
  {
    emoji: '💖',
    category: 'Symbols',
  },
  {
    emoji: '💘',
    category: 'Symbols',
  },
  {
    emoji: '💝',
    category: 'Symbols',
  },
  {
    emoji: '💟',
    category: 'Symbols',
  },
  {
    emoji: '☮️',
    category: 'Symbols',
  },
  {
    emoji: '✝️',
    category: 'Symbols',
  },
  {
    emoji: '☪️',
    category: 'Symbols',
  },
  {
    emoji: '🕉',
    category: 'Symbols',
  },
  {
    emoji: '☸️',
    category: 'Symbols',
  },
  {
    emoji: '✡️',
    category: 'Symbols',
  },
  {
    emoji: '🔯',
    category: 'Symbols',
  },
  {
    emoji: '🕎',
    category: 'Symbols',
  },
  {
    emoji: '☯️',
    category: 'Symbols',
  },
  {
    emoji: '☦️',
    category: 'Symbols',
  },
  {
    emoji: '🛐',
    category: 'Symbols',
  },
  {
    emoji: '⛎',
    category: 'Symbols',
  },
  {
    emoji: '♈️',
    category: 'Symbols',
  },
  {
    emoji: '♉️',
    category: 'Symbols',
  },
  {
    emoji: '♊️',
    category: 'Symbols',
  },
  {
    emoji: '♋️',
    category: 'Symbols',
  },
  {
    emoji: '♌️',
    category: 'Symbols',
  },
  {
    emoji: '♍️',
    category: 'Symbols',
  },
  {
    emoji: '♎️',
    category: 'Symbols',
  },
  {
    emoji: '♏️',
    category: 'Symbols',
  },
  {
    emoji: '♐️',
    category: 'Symbols',
  },
  {
    emoji: '♑️',
    category: 'Symbols',
  },
  {
    emoji: '♒️',
    category: 'Symbols',
  },
  {
    emoji: '♓️',
    category: 'Symbols',
  },
  {
    emoji: '🆔',
    category: 'Symbols',
  },
  {
    emoji: '⚛️',
    category: 'Symbols',
  },
  {
    emoji: '🉑',
    category: 'Symbols',
  },
  {
    emoji: '☢️',
    category: 'Symbols',
  },
  {
    emoji: '☣️',
    category: 'Symbols',
  },
  {
    emoji: '📴',
    category: 'Symbols',
  },
  {
    emoji: '📳',
    category: 'Symbols',
  },
  {
    emoji: '🈶',
    category: 'Symbols',
  },
  {
    emoji: '🈚️',
    category: 'Symbols',
  },
  {
    emoji: '🈸',
    category: 'Symbols',
  },
  {
    emoji: '🈺',
    category: 'Symbols',
  },
  {
    emoji: '🈷️',
    category: 'Symbols',
  },
  {
    emoji: '✴️',
    category: 'Symbols',
  },
  {
    emoji: '🆚',
    category: 'Symbols',
  },
  {
    emoji: '💮',
    category: 'Symbols',
  },
  {
    emoji: '🉐',
    category: 'Symbols',
  },
  {
    emoji: '㊙️',
    category: 'Symbols',
  },
  {
    emoji: '㊗️',
    category: 'Symbols',
  },
  {
    emoji: '🈴',
    category: 'Symbols',
  },
  {
    emoji: '🈵',
    category: 'Symbols',
  },
  {
    emoji: '🈹',
    category: 'Symbols',
  },
  {
    emoji: '🈲',
    category: 'Symbols',
  },
  {
    emoji: '🅰️',
    category: 'Symbols',
  },
  {
    emoji: '🅱️',
    category: 'Symbols',
  },
  {
    emoji: '🆎',
    category: 'Symbols',
  },
  {
    emoji: '🆑',
    category: 'Symbols',
  },
  {
    emoji: '🅾️',
    category: 'Symbols',
  },
  {
    emoji: '🆘',
    category: 'Symbols',
  },
  {
    emoji: '❌',
    category: 'Symbols',
  },
  {
    emoji: '⭕️',
    category: 'Symbols',
  },
  {
    emoji: '🛑',
    category: 'Symbols',
  },
  {
    emoji: '⛔️',
    category: 'Symbols',
  },
  {
    emoji: '📛',
    category: 'Symbols',
  },
  {
    emoji: '🚫',
    category: 'Symbols',
  },
  {
    emoji: '💯',
    category: 'Symbols',
  },
  {
    emoji: '💢',
    category: 'Symbols',
  },
  {
    emoji: '♨️',
    category: 'Symbols',
  },
  {
    emoji: '🚷',
    category: 'Symbols',
  },
  {
    emoji: '🚯',
    category: 'Symbols',
  },
  {
    emoji: '🚳',
    category: 'Symbols',
  },
  {
    emoji: '🚱',
    category: 'Symbols',
  },
  {
    emoji: '🔞',
    category: 'Symbols',
  },
  {
    emoji: '📵',
    category: 'Symbols',
  },
  {
    emoji: '🚭',
    category: 'Symbols',
  },
  {
    emoji: '❗️',
    category: 'Symbols',
  },
  {
    emoji: '❕',
    category: 'Symbols',
  },
  {
    emoji: '❓',
    category: 'Symbols',
  },
  {
    emoji: '❔',
    category: 'Symbols',
  },
  {
    emoji: '‼️',
    category: 'Symbols',
  },
  {
    emoji: '⁉️',
    category: 'Symbols',
  },
  {
    emoji: '🔅',
    category: 'Symbols',
  },
  {
    emoji: '🔆',
    category: 'Symbols',
  },
  {
    emoji: '〽️',
    category: 'Symbols',
  },
  {
    emoji: '⚠️',
    category: 'Symbols',
  },
  {
    emoji: '🚸',
    category: 'Symbols',
  },
  {
    emoji: '🔱',
    category: 'Symbols',
  },
  {
    emoji: '⚜️',
    category: 'Symbols',
  },
  {
    emoji: '🔰',
    category: 'Symbols',
  },
  {
    emoji: '♻️',
    category: 'Symbols',
  },
  {
    emoji: '✅',
    category: 'Symbols',
  },
  {
    emoji: '🈯️',
    category: 'Symbols',
  },
  {
    emoji: '💹',
    category: 'Symbols',
  },
  {
    emoji: '❇️',
    category: 'Symbols',
  },
  {
    emoji: '✳️',
    category: 'Symbols',
  },
  {
    emoji: '❎',
    category: 'Symbols',
  },
  {
    emoji: '🌐',
    category: 'Symbols',
  },
  {
    emoji: '💠',
    category: 'Symbols',
  },
  {
    emoji: 'Ⓜ️',
    category: 'Symbols',
  },
  {
    emoji: '🌀',
    category: 'Symbols',
  },
  {
    emoji: '💤',
    category: 'Symbols',
  },
  {
    emoji: '🏧',
    category: 'Symbols',
  },
  {
    emoji: '🚾',
    category: 'Symbols',
  },
  {
    emoji: '♿️',
    category: 'Symbols',
  },
  {
    emoji: '🅿️',
    category: 'Symbols',
  },
  {
    emoji: '🈳',
    category: 'Symbols',
  },
  {
    emoji: '🈂️',
    category: 'Symbols',
  },
  {
    emoji: '🛂',
    category: 'Symbols',
  },
  {
    emoji: '🛃',
    category: 'Symbols',
  },
  {
    emoji: '🛄',
    category: 'Symbols',
  },
  {
    emoji: '🛅',
    category: 'Symbols',
  },
  {
    emoji: '🚹',
    category: 'Symbols',
  },
  {
    emoji: '🚺',
    category: 'Symbols',
  },
  {
    emoji: '🚼',
    category: 'Symbols',
  },
  {
    emoji: '🚻',
    category: 'Symbols',
  },
  {
    emoji: '🚮',
    category: 'Symbols',
  },
  {
    emoji: '🎦',
    category: 'Symbols',
  },
  {
    emoji: '📶',
    category: 'Symbols',
  },
  {
    emoji: '🈁',
    category: 'Symbols',
  },
  {
    emoji: '🔣',
    category: 'Symbols',
  },
  {
    emoji: 'ℹ️',
    category: 'Symbols',
  },
  {
    emoji: '🔤',
    category: 'Symbols',
  },
  {
    emoji: '🔡',
    category: 'Symbols',
  },
  {
    emoji: '🔠',
    category: 'Symbols',
  },
  {
    emoji: '🆖',
    category: 'Symbols',
  },
  {
    emoji: '🆗',
    category: 'Symbols',
  },
  {
    emoji: '🆙',
    category: 'Symbols',
  },
  {
    emoji: '🆒',
    category: 'Symbols',
  },
  {
    emoji: '🆕',
    category: 'Symbols',
  },
  {
    emoji: '🆓',
    category: 'Symbols',
  },
  {
    emoji: '0️⃣',
    category: 'Symbols',
  },
  {
    emoji: '1️⃣',
    category: 'Symbols',
  },
  {
    emoji: '2️⃣',
    category: 'Symbols',
  },
  {
    emoji: '3️⃣',
    category: 'Symbols',
  },
  {
    emoji: '4️⃣',
    category: 'Symbols',
  },
  {
    emoji: '5️⃣',
    category: 'Symbols',
  },
  {
    emoji: '6️⃣',
    category: 'Symbols',
  },
  {
    emoji: '7️⃣',
    category: 'Symbols',
  },
  {
    emoji: '8️⃣',
    category: 'Symbols',
  },
  {
    emoji: '9️⃣',
    category: 'Symbols',
  },
  {
    emoji: '🔟',
    category: 'Symbols',
  },
  {
    emoji: '🔢',
    category: 'Symbols',
  },
  {
    emoji: '#️⃣',
    category: 'Symbols',
  },
  {
    emoji: '*️⃣',
    category: 'Symbols',
  },
  {
    emoji: '▶️',
    category: 'Symbols',
  },
  {
    emoji: '⏸',
    category: 'Symbols',
  },
  {
    emoji: '⏯',
    category: 'Symbols',
  },
  {
    emoji: '⏹',
    category: 'Symbols',
  },
  {
    emoji: '⏺',
    category: 'Symbols',
  },
  {
    emoji: '⏭',
    category: 'Symbols',
  },
  {
    emoji: '⏮',
    category: 'Symbols',
  },
  {
    emoji: '⏩',
    category: 'Symbols',
  },
  {
    emoji: '⏪',
    category: 'Symbols',
  },
  {
    emoji: '⏫',
    category: 'Symbols',
  },
  {
    emoji: '⏬',
    category: 'Symbols',
  },
  {
    emoji: '◀️',
    category: 'Symbols',
  },
  {
    emoji: '🔼',
    category: 'Symbols',
  },
  {
    emoji: '🔽',
    category: 'Symbols',
  },
  {
    emoji: '➡️',
    category: 'Symbols',
  },
  {
    emoji: '⬅️',
    category: 'Symbols',
  },
  {
    emoji: '⬆️',
    category: 'Symbols',
  },
  {
    emoji: '⬇️',
    category: 'Symbols',
  },
  {
    emoji: '↗️',
    category: 'Symbols',
  },
  {
    emoji: '↘️',
    category: 'Symbols',
  },
  {
    emoji: '↙️',
    category: 'Symbols',
  },
  {
    emoji: '↖️',
    category: 'Symbols',
  },
  {
    emoji: '↕️',
    category: 'Symbols',
  },
  {
    emoji: '↔️',
    category: 'Symbols',
  },
  {
    emoji: '↪️',
    category: 'Symbols',
  },
  {
    emoji: '↩️',
    category: 'Symbols',
  },
  {
    emoji: '⤴️',
    category: 'Symbols',
  },
  {
    emoji: '⤵️',
    category: 'Symbols',
  },
  {
    emoji: '🔀',
    category: 'Symbols',
  },
  {
    emoji: '🔁',
    category: 'Symbols',
  },
  {
    emoji: '🔂',
    category: 'Symbols',
  },
  {
    emoji: '🔄',
    category: 'Symbols',
  },
  {
    emoji: '🔃',
    category: 'Symbols',
  },
  {
    emoji: '🎵',
    category: 'Symbols',
  },
  {
    emoji: '🎶',
    category: 'Symbols',
  },
  {
    emoji: '➕',
    category: 'Symbols',
  },
  {
    emoji: '➖',
    category: 'Symbols',
  },
  {
    emoji: '➗',
    category: 'Symbols',
  },
  {
    emoji: '✖️',
    category: 'Symbols',
  },
  {
    emoji: '💲',
    category: 'Symbols',
  },
  {
    emoji: '💱',
    category: 'Symbols',
  },
  {
    emoji: '™️',
    category: 'Symbols',
  },
  {
    emoji: '©️',
    category: 'Symbols',
  },
  {
    emoji: '®️',
    category: 'Symbols',
  },
  {
    emoji: '〰️',
    category: 'Symbols',
  },
  {
    emoji: '➰',
    category: 'Symbols',
  },
  {
    emoji: '➿',
    category: 'Symbols',
  },
  {
    emoji: '🔚',
    category: 'Symbols',
  },
  {
    emoji: '🔙',
    category: 'Symbols',
  },
  {
    emoji: '🔛',
    category: 'Symbols',
  },
  {
    emoji: '🔝',
    category: 'Symbols',
  },
  {
    emoji: '🔜',
    category: 'Symbols',
  },
  {
    emoji: '✔️',
    category: 'Symbols',
  },
  {
    emoji: '☑️',
    category: 'Symbols',
  },
  {
    emoji: '🔘',
    category: 'Symbols',
  },
  {
    emoji: '⚪️',
    category: 'Symbols',
  },
  {
    emoji: '⚫️',
    category: 'Symbols',
  },
  {
    emoji: '🔴',
    category: 'Symbols',
  },
  {
    emoji: '🔵',
    category: 'Symbols',
  },
  {
    emoji: '🔺',
    category: 'Symbols',
  },
  {
    emoji: '🔻',
    category: 'Symbols',
  },
  {
    emoji: '🔸',
    category: 'Symbols',
  },
  {
    emoji: '🔹',
    category: 'Symbols',
  },
  {
    emoji: '🔶',
    category: 'Symbols',
  },
  {
    emoji: '🔷',
    category: 'Symbols',
  },
  {
    emoji: '🔳',
    category: 'Symbols',
  },
  {
    emoji: '🔲',
    category: 'Symbols',
  },
  {
    emoji: '▪️',
    category: 'Symbols',
  },
  {
    emoji: '▫️',
    category: 'Symbols',
  },
  {
    emoji: '◾️',
    category: 'Symbols',
  },
  {
    emoji: '◽️',
    category: 'Symbols',
  },
  {
    emoji: '◼️',
    category: 'Symbols',
  },
  {
    emoji: '◻️',
    category: 'Symbols',
  },
  {
    emoji: '⬛️',
    category: 'Symbols',
  },
  {
    emoji: '⬜️',
    category: 'Symbols',
  },
  {
    emoji: '🔈',
    category: 'Symbols',
  },
  {
    emoji: '🔇',
    category: 'Symbols',
  },
  {
    emoji: '🔉',
    category: 'Symbols',
  },
  {
    emoji: '🔊',
    category: 'Symbols',
  },
  {
    emoji: '🔔',
    category: 'Symbols',
  },
  {
    emoji: '🔕',
    category: 'Symbols',
  },
  {
    emoji: '📣',
    category: 'Symbols',
  },
  {
    emoji: '📢',
    category: 'Symbols',
  },
  {
    emoji: '👁‍🗨',
    category: 'Symbols',
  },
  {
    emoji: '💬',
    category: 'Symbols',
  },
  {
    emoji: '💭',
    category: 'Symbols',
  },
  {
    emoji: '🗯',
    category: 'Symbols',
  },
  {
    emoji: '♠️',
    category: 'Symbols',
  },
  {
    emoji: '♣️',
    category: 'Symbols',
  },
  {
    emoji: '♥️',
    category: 'Symbols',
  },
  {
    emoji: '♦️',
    category: 'Symbols',
  },
  {
    emoji: '🃏',
    category: 'Symbols',
  },
  {
    emoji: '🎴',
    category: 'Symbols',
  },
  {
    emoji: '🀄️',
    category: 'Symbols',
  },
  {
    emoji: '🕐',
    category: 'Symbols',
  },
  {
    emoji: '🕑',
    category: 'Symbols',
  },
  {
    emoji: '🕒',
    category: 'Symbols',
  },
  {
    emoji: '🕓',
    category: 'Symbols',
  },
  {
    emoji: '🕔',
    category: 'Symbols',
  },
  {
    emoji: '🕕',
    category: 'Symbols',
  },
  {
    emoji: '🕖',
    category: 'Symbols',
  },
  {
    emoji: '🕗',
    category: 'Symbols',
  },
  {
    emoji: '🕘',
    category: 'Symbols',
  },
  {
    emoji: '🕙',
    category: 'Symbols',
  },
  {
    emoji: '🕚',
    category: 'Symbols',
  },
  {
    emoji: '🕛',
    category: 'Symbols',
  },
  {
    emoji: '🕜',
    category: 'Symbols',
  },
  {
    emoji: '🕝',
    category: 'Symbols',
  },
  {
    emoji: '🕞',
    category: 'Symbols',
  },
  {
    emoji: '🕟',
    category: 'Symbols',
  },
  {
    emoji: '🕠',
    category: 'Symbols',
  },
  {
    emoji: '🕡',
    category: 'Symbols',
  },
  {
    emoji: '🕢',
    category: 'Symbols',
  },
  {
    emoji: '🕣',
    category: 'Symbols',
  },
  {
    emoji: '🕤',
    category: 'Symbols',
  },
  {
    emoji: '🕥',
    category: 'Symbols',
  },
  {
    emoji: '🕦',
    category: 'Symbols',
  },
  {
    emoji: '🕧',
    category: 'Symbols',
  },
  {
    emoji: '🏳️',
    category: 'Flags',
  },
  {
    emoji: '🏴',
    category: 'Flags',
  },
  {
    emoji: '🏁',
    category: 'Flags',
  },
  {
    emoji: '🚩',
    category: 'Flags',
  },
  {
    emoji: '🏳️‍🌈',
    category: 'Flags',
  },
  {
    emoji: '🇦🇫',
    category: 'Flags',
  },
  {
    emoji: '🇦🇽',
    category: 'Flags',
  },
  {
    emoji: '🇦🇱',
    category: 'Flags',
  },
  {
    emoji: '🇩🇿',
    category: 'Flags',
  },
  {
    emoji: '🇦🇸',
    category: 'Flags',
  },
  {
    emoji: '🇦🇩',
    category: 'Flags',
  },
  {
    emoji: '🇦🇴',
    category: 'Flags',
  },
  {
    emoji: '🇦🇮',
    category: 'Flags',
  },
  {
    emoji: '🇦🇶',
    category: 'Flags',
  },
  {
    emoji: '🇦🇬',
    category: 'Flags',
  },
  {
    emoji: '🇦🇷',
    category: 'Flags',
  },
  {
    emoji: '🇦🇲',
    category: 'Flags',
  },
  {
    emoji: '🇦🇼',
    category: 'Flags',
  },
  {
    emoji: '🇦🇺',
    category: 'Flags',
  },
  {
    emoji: '🇦🇹',
    category: 'Flags',
  },
  {
    emoji: '🇦🇿',
    category: 'Flags',
  },
  {
    emoji: '🇧🇸',
    category: 'Flags',
  },
  {
    emoji: '🇧🇭',
    category: 'Flags',
  },
  {
    emoji: '🇧🇩',
    category: 'Flags',
  },
  {
    emoji: '🇧🇧',
    category: 'Flags',
  },
  {
    emoji: '🇧🇾',
    category: 'Flags',
  },
  {
    emoji: '🇧🇪',
    category: 'Flags',
  },
  {
    emoji: '🇧🇿',
    category: 'Flags',
  },
  {
    emoji: '🇧🇯',
    category: 'Flags',
  },
  {
    emoji: '🇧🇲',
    category: 'Flags',
  },
  {
    emoji: '🇧🇹',
    category: 'Flags',
  },
  {
    emoji: '🇧🇴',
    category: 'Flags',
  },
  {
    emoji: '🇧🇶',
    category: 'Flags',
  },
  {
    emoji: '🇧🇦',
    category: 'Flags',
  },
  {
    emoji: '🇧🇼',
    category: 'Flags',
  },
  {
    emoji: '🇧🇷',
    category: 'Flags',
  },
  {
    emoji: '🇮🇴',
    category: 'Flags',
  },
  {
    emoji: '🇻🇬',
    category: 'Flags',
  },
  {
    emoji: '🇧🇳',
    category: 'Flags',
  },
  {
    emoji: '🇧🇬',
    category: 'Flags',
  },
  {
    emoji: '🇧🇫',
    category: 'Flags',
  },
  {
    emoji: '🇧🇮',
    category: 'Flags',
  },
  {
    emoji: '🇨🇻',
    category: 'Flags',
  },
  {
    emoji: '🇰🇭',
    category: 'Flags',
  },
  {
    emoji: '🇨🇲',
    category: 'Flags',
  },
  {
    emoji: '🇨🇦',
    category: 'Flags',
  },
  {
    emoji: '🇮🇨',
    category: 'Flags',
  },
  {
    emoji: '🇰🇾',
    category: 'Flags',
  },
  {
    emoji: '🇨🇫',
    category: 'Flags',
  },
  {
    emoji: '🇹🇩',
    category: 'Flags',
  },
  {
    emoji: '🇨🇱',
    category: 'Flags',
  },
  {
    emoji: '🇨🇳',
    category: 'Flags',
  },
  {
    emoji: '🇨🇽',
    category: 'Flags',
  },
  {
    emoji: '🇨🇨',
    category: 'Flags',
  },
  {
    emoji: '🇨🇴',
    category: 'Flags',
  },
  {
    emoji: '🇰🇲',
    category: 'Flags',
  },
  {
    emoji: '🇨🇬',
    category: 'Flags',
  },
  {
    emoji: '🇨🇩',
    category: 'Flags',
  },
  {
    emoji: '🇨🇰',
    category: 'Flags',
  },
  {
    emoji: '🇨🇷',
    category: 'Flags',
  },
  {
    emoji: '🇨🇮',
    category: 'Flags',
  },
  {
    emoji: '🇭🇷',
    category: 'Flags',
  },
  {
    emoji: '🇨🇺',
    category: 'Flags',
  },
  {
    emoji: '🇨🇼',
    category: 'Flags',
  },
  {
    emoji: '🇨🇾',
    category: 'Flags',
  },
  {
    emoji: '🇨🇿',
    category: 'Flags',
  },
  {
    emoji: '🇩🇰',
    category: 'Flags',
  },
  {
    emoji: '🇩🇯',
    category: 'Flags',
  },
  {
    emoji: '🇩🇲',
    category: 'Flags',
  },
  {
    emoji: '🇩🇴',
    category: 'Flags',
  },
  {
    emoji: '🇪🇨',
    category: 'Flags',
  },
  {
    emoji: '🇪🇬',
    category: 'Flags',
  },
  {
    emoji: '🇸🇻',
    category: 'Flags',
  },
  {
    emoji: '🇬🇶',
    category: 'Flags',
  },
  {
    emoji: '🇪🇷',
    category: 'Flags',
  },
  {
    emoji: '🇪🇪',
    category: 'Flags',
  },
  {
    emoji: '🇪🇹',
    category: 'Flags',
  },
  {
    emoji: '🇪🇺',
    category: 'Flags',
  },
  {
    emoji: '🇫🇰',
    category: 'Flags',
  },
  {
    emoji: '🇫🇴',
    category: 'Flags',
  },
  {
    emoji: '🇫🇯',
    category: 'Flags',
  },
  {
    emoji: '🇫🇮',
    category: 'Flags',
  },
  {
    emoji: '🇫🇷',
    category: 'Flags',
  },
  {
    emoji: '🇬🇫',
    category: 'Flags',
  },
  {
    emoji: '🇵🇫',
    category: 'Flags',
  },
  {
    emoji: '🇹🇫',
    category: 'Flags',
  },
  {
    emoji: '🇬🇦',
    category: 'Flags',
  },
  {
    emoji: '🇬🇲',
    category: 'Flags',
  },
  {
    emoji: '🇬🇪',
    category: 'Flags',
  },
  {
    emoji: '🇩🇪',
    category: 'Flags',
  },
  {
    emoji: '🇬🇭',
    category: 'Flags',
  },
  {
    emoji: '🇬🇮',
    category: 'Flags',
  },
  {
    emoji: '🇬🇷',
    category: 'Flags',
  },
  {
    emoji: '🇬🇱',
    category: 'Flags',
  },
  {
    emoji: '🇬🇩',
    category: 'Flags',
  },
  {
    emoji: '🇬🇵',
    category: 'Flags',
  },
  {
    emoji: '🇬🇺',
    category: 'Flags',
  },
  {
    emoji: '🇬🇹',
    category: 'Flags',
  },
  {
    emoji: '🇬🇬',
    category: 'Flags',
  },
  {
    emoji: '🇬🇳',
    category: 'Flags',
  },
  {
    emoji: '🇬🇼',
    category: 'Flags',
  },
  {
    emoji: '🇬🇾',
    category: 'Flags',
  },
  {
    emoji: '🇭🇹',
    category: 'Flags',
  },
  {
    emoji: '🇭🇳',
    category: 'Flags',
  },
  {
    emoji: '🇭🇰',
    category: 'Flags',
  },
  {
    emoji: '🇭🇺',
    category: 'Flags',
  },
  {
    emoji: '🇮🇸',
    category: 'Flags',
  },
  {
    emoji: '🇮🇳',
    category: 'Flags',
  },
  {
    emoji: '🇮🇩',
    category: 'Flags',
  },
  {
    emoji: '🇮🇷',
    category: 'Flags',
  },
  {
    emoji: '🇮🇶',
    category: 'Flags',
  },
  {
    emoji: '🇮🇪',
    category: 'Flags',
  },
  {
    emoji: '🇮🇲',
    category: 'Flags',
  },
  {
    emoji: '🇮🇱',
    category: 'Flags',
  },
  {
    emoji: '🇮🇹',
    category: 'Flags',
  },
  {
    emoji: '🇯🇲',
    category: 'Flags',
  },
  {
    emoji: '🇯🇵',
    category: 'Flags',
  },
  {
    emoji: '🎌',
    category: 'Flags',
  },
  {
    emoji: '🇯🇪',
    category: 'Flags',
  },
  {
    emoji: '🇯🇴',
    category: 'Flags',
  },
  {
    emoji: '🇰🇿',
    category: 'Flags',
  },
  {
    emoji: '🇰🇪',
    category: 'Flags',
  },
  {
    emoji: '🇰🇮',
    category: 'Flags',
  },
  {
    emoji: '🇽🇰',
    category: 'Flags',
  },
  {
    emoji: '🇰🇼',
    category: 'Flags',
  },
  {
    emoji: '🇰🇬',
    category: 'Flags',
  },
  {
    emoji: '🇱🇦',
    category: 'Flags',
  },
  {
    emoji: '🇱🇻',
    category: 'Flags',
  },
  {
    emoji: '🇱🇧',
    category: 'Flags',
  },
  {
    emoji: '🇱🇸',
    category: 'Flags',
  },
  {
    emoji: '🇱🇷',
    category: 'Flags',
  },
  {
    emoji: '🇱🇾',
    category: 'Flags',
  },
  {
    emoji: '🇱🇮',
    category: 'Flags',
  },
  {
    emoji: '🇱🇹',
    category: 'Flags',
  },
  {
    emoji: '🇱🇺',
    category: 'Flags',
  },
  {
    emoji: '🇲🇴',
    category: 'Flags',
  },
  {
    emoji: '🇲🇰',
    category: 'Flags',
  },
  {
    emoji: '🇲🇬',
    category: 'Flags',
  },
  {
    emoji: '🇲🇼',
    category: 'Flags',
  },
  {
    emoji: '🇲🇾',
    category: 'Flags',
  },
  {
    emoji: '🇲🇻',
    category: 'Flags',
  },
  {
    emoji: '🇲🇱',
    category: 'Flags',
  },
  {
    emoji: '🇲🇹',
    category: 'Flags',
  },
  {
    emoji: '🇲🇭',
    category: 'Flags',
  },
  {
    emoji: '🇲🇶',
    category: 'Flags',
  },
  {
    emoji: '🇲🇷',
    category: 'Flags',
  },
  {
    emoji: '🇲🇺',
    category: 'Flags',
  },
  {
    emoji: '🇾🇹',
    category: 'Flags',
  },
  {
    emoji: '🇲🇽',
    category: 'Flags',
  },
  {
    emoji: '🇫🇲',
    category: 'Flags',
  },
  {
    emoji: '🇲🇩',
    category: 'Flags',
  },
  {
    emoji: '🇲🇨',
    category: 'Flags',
  },
  {
    emoji: '🇲🇳',
    category: 'Flags',
  },
  {
    emoji: '🇲🇪',
    category: 'Flags',
  },
  {
    emoji: '🇲🇸',
    category: 'Flags',
  },
  {
    emoji: '🇲🇦',
    category: 'Flags',
  },
  {
    emoji: '🇲🇿',
    category: 'Flags',
  },
  {
    emoji: '🇲🇲',
    category: 'Flags',
  },
  {
    emoji: '🇳🇦',
    category: 'Flags',
  },
  {
    emoji: '🇳🇷',
    category: 'Flags',
  },
  {
    emoji: '🇳🇵',
    category: 'Flags',
  },
  {
    emoji: '🇳🇱',
    category: 'Flags',
  },
  {
    emoji: '🇳🇨',
    category: 'Flags',
  },
  {
    emoji: '🇳🇿',
    category: 'Flags',
  },
  {
    emoji: '🇳🇮',
    category: 'Flags',
  },
  {
    emoji: '🇳🇪',
    category: 'Flags',
  },
  {
    emoji: '🇳🇬',
    category: 'Flags',
  },
  {
    emoji: '🇳🇺',
    category: 'Flags',
  },
  {
    emoji: '🇳🇫',
    category: 'Flags',
  },
  {
    emoji: '🇲🇵',
    category: 'Flags',
  },
  {
    emoji: '🇰🇵',
    category: 'Flags',
  },
  {
    emoji: '🇳🇴',
    category: 'Flags',
  },
  {
    emoji: '🇴🇲',
    category: 'Flags',
  },
  {
    emoji: '🇵🇰',
    category: 'Flags',
  },
  {
    emoji: '🇵🇼',
    category: 'Flags',
  },
  {
    emoji: '🇵🇸',
    category: 'Flags',
  },
  {
    emoji: '🇵🇦',
    category: 'Flags',
  },
  {
    emoji: '🇵🇬',
    category: 'Flags',
  },
  {
    emoji: '🇵🇾',
    category: 'Flags',
  },
  {
    emoji: '🇵🇪',
    category: 'Flags',
  },
  {
    emoji: '🇵🇭',
    category: 'Flags',
  },
  {
    emoji: '🇵🇳',
    category: 'Flags',
  },
  {
    emoji: '🇵🇱',
    category: 'Flags',
  },
  {
    emoji: '🇵🇹',
    category: 'Flags',
  },
  {
    emoji: '🇵🇷',
    category: 'Flags',
  },
  {
    emoji: '🇶🇦',
    category: 'Flags',
  },
  {
    emoji: '🇷🇪',
    category: 'Flags',
  },
  {
    emoji: '🇷🇴',
    category: 'Flags',
  },
  {
    emoji: '🇷🇺',
    category: 'Flags',
  },
  {
    emoji: '🇷🇼',
    category: 'Flags',
  },
  {
    emoji: '🇧🇱',
    category: 'Flags',
  },
  {
    emoji: '🇸🇭',
    category: 'Flags',
  },
  {
    emoji: '🇰🇳',
    category: 'Flags',
  },
  {
    emoji: '🇱🇨',
    category: 'Flags',
  },
  {
    emoji: '🇵🇲',
    category: 'Flags',
  },
  {
    emoji: '🇻🇨',
    category: 'Flags',
  },
  {
    emoji: '🇼🇸',
    category: 'Flags',
  },
  {
    emoji: '🇸🇲',
    category: 'Flags',
  },
  {
    emoji: '🇸🇹',
    category: 'Flags',
  },
  {
    emoji: '🇸🇦',
    category: 'Flags',
  },
  {
    emoji: '🇸🇳',
    category: 'Flags',
  },
  {
    emoji: '🇷🇸',
    category: 'Flags',
  },
  {
    emoji: '🇸🇨',
    category: 'Flags',
  },
  {
    emoji: '🇸🇱',
    category: 'Flags',
  },
  {
    emoji: '🇸🇬',
    category: 'Flags',
  },
  {
    emoji: '🇸🇽',
    category: 'Flags',
  },
  {
    emoji: '🇸🇰',
    category: 'Flags',
  },
  {
    emoji: '🇸🇮',
    category: 'Flags',
  },
  {
    emoji: '🇸🇧',
    category: 'Flags',
  },
  {
    emoji: '🇸🇴',
    category: 'Flags',
  },
  {
    emoji: '🇿🇦',
    category: 'Flags',
  },
  {
    emoji: '🇬🇸',
    category: 'Flags',
  },
  {
    emoji: '🇰🇷',
    category: 'Flags',
  },
  {
    emoji: '🇸🇸',
    category: 'Flags',
  },
  {
    emoji: '🇪🇸',
    category: 'Flags',
  },
  {
    emoji: '🇱🇰',
    category: 'Flags',
  },
  {
    emoji: '🇸🇩',
    category: 'Flags',
  },
  {
    emoji: '🇸🇷',
    category: 'Flags',
  },
  {
    emoji: '🇸🇿',
    category: 'Flags',
  },
  {
    emoji: '🇸🇪',
    category: 'Flags',
  },
  {
    emoji: '🇨🇭',
    category: 'Flags',
  },
  {
    emoji: '🇸🇾',
    category: 'Flags',
  },
  {
    emoji: '🇹🇼',
    category: 'Flags',
  },
  {
    emoji: '🇹🇯',
    category: 'Flags',
  },
  {
    emoji: '🇹🇿',
    category: 'Flags',
  },
  {
    emoji: '🇹🇭',
    category: 'Flags',
  },
  {
    emoji: '🇹🇱',
    category: 'Flags',
  },
  {
    emoji: '🇹🇬',
    category: 'Flags',
  },
  {
    emoji: '🇹🇰',
    category: 'Flags',
  },
  {
    emoji: '🇹🇴',
    category: 'Flags',
  },
  {
    emoji: '🇹🇹',
    category: 'Flags',
  },
  {
    emoji: '🇹🇳',
    category: 'Flags',
  },
  {
    emoji: '🇹🇷',
    category: 'Flags',
  },
  {
    emoji: '🇹🇲',
    category: 'Flags',
  },
  {
    emoji: '🇹🇨',
    category: 'Flags',
  },
  {
    emoji: '🇹🇻',
    category: 'Flags',
  },
  {
    emoji: '🇺🇬',
    category: 'Flags',
  },
  {
    emoji: '🇺🇦',
    category: 'Flags',
  },
  {
    emoji: '🇦🇪',
    category: 'Flags',
  },
  {
    emoji: '🇬🇧',
    category: 'Flags',
  },
  {
    emoji: '🇺🇸',
    category: 'Flags',
  },
  {
    emoji: '🇻🇮',
    category: 'Flags',
  },
  {
    emoji: '🇺🇾',
    category: 'Flags',
  },
  {
    emoji: '🇺🇿',
    category: 'Flags',
  },
  {
    emoji: '🇻🇺',
    category: 'Flags',
  },
  {
    emoji: '🇻🇦',
    category: 'Flags',
  },
  {
    emoji: '🇻🇪',
    category: 'Flags',
  },
  {
    emoji: '🇻🇳',
    category: 'Flags',
  },
  {
    emoji: '🇼🇫',
    category: 'Flags',
  },
  {
    emoji: '🇪🇭',
    category: 'Flags',
  },
  {
    emoji: '🇾🇪',
    category: 'Flags',
  },
  {
    emoji: '🇿🇲',
    category: 'Flags',
  },
  {
    emoji: '🇿🇼',
    category: 'Flags',
  },
  {
    emoji: '🏳️‍⚧️',
    category: 'Flags',
  },
];
