import queryString from 'query-string';
import * as types from '../models/analytics/keys';

export const trackEmailLinkClick = (tracker, traits = {}) => {
  if (!tracker) return;

  const { tok } = queryString.parse(window.location.search, {
    parseNumbers: true,
  });
  if (!tok) return;

  const event = types.EMAIL_LINK_CLICK;

  traits = {
    ...traits,
    tok,
    event,
    url: window.location.href,
  };

  tracker.track(event, traits);
};
