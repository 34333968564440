import { useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from '../../config/store';
import { advocateSelectors } from '../../models/advocate';
import { getChannelById } from '../../models/channels/selectors';
import { trackChannelCardView } from '../../models/channels/analytics';
import useInview from '../../common/use-inview';
import ChannelLink from '../channel/channel-link';
import {
  ChannelTag,
  ChannelImage,
  ChannelTitle,
  ChannelFollowerCount,
  ChannelFollowButton,
} from '../channel';
import './channel-card.scss';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const ChannelCard = ({
  channelId,
  tag,
  orientation,
  size,
  analyticsData,
  shouldTrackView = true,
  onClick,
}) => {
  const { t } = useTranslation();
  const sectionRef = useRef();
  const { pushMessage } = usePushToastOrFlashMessage();

  const channel = useSelector((state) => getChannelById(state, channelId));

  if (!channel) {
    console.error(`channel card - missing channel data for id: ${channelId}`);
    pushMessage({
      text: t('errors.default'),
      type: 'error',
    });
  }

  const className = cx('channel-card', {
    [`channel-card--${orientation}`]: orientation,
    [`channel-card--${size}`]: size,
  });

  const followBtnSize =
    size === 'small' && orientation !== 'landscape' ? 'small' : null;

  const handleView = () => {
    if (shouldTrackView) trackChannelCardView(channelId, analyticsData);
  };
  const isContributor = advocateSelectors.getAdvocateIsContributor(
    store.getState()
  );

  useInview({
    ref: sectionRef,
    once: true,
    onInview: handleView,
  });

  return channel ? (
    <section className={className} ref={sectionRef}>
      <div className="channel-card__contain">
        <ChannelLink
          channelId={channel.id}
          analyticsData={analyticsData}
          onClick={onClick}
        >
          <div className="channel-card__image">
            <ChannelImage
              channel={channel}
              analyticsData={analyticsData}
              onClick={onClick}
              noLink={true}
            />
          </div>
          <div className="channel-card__body">
            <div className="channel-card__text">
              {tag ? <ChannelTag tag={tag} /> : null}

              <ChannelTitle
                channel={channel}
                truncate={2}
                onClick={onClick}
                analyticsData={analyticsData}
                noLink={true}
              />
            </div>
          </div>
        </ChannelLink>
        <div className="channel-card__footer">
          <ChannelFollowButton
            channelId={channelId}
            size={followBtnSize}
            analyticsData={analyticsData}
          />

          <ChannelFollowerCount channelId={channel.id} />

          {channel.can_publish_as_owner && isContributor ? (
            <div className="channel__contributor">
              {t('channel_details.contributor')}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  ) : null;
};

ChannelCard.defaultProps = {
  orientation: 'landscape',
};

export default ChannelCard;
