import * as types from './keys';
import { track } from '../../analytics/helpers';
import store from '../../../config/store';

export const channelTraits = (channelId, assignTraits) => {
  const channel = store.getState().channels[channelId];

  return {
    content_channel_id: channel?.id,
    content_channel_name: channel?.name,
    ...assignTraits,
  };
};

//keeps track of the view events we have already sent, to avoid duplicates while navigating to/from home page and channels.
const channelsViewed = {};
export const trackChannelCardView = (channelId, traits) => {
  if (channelsViewed[channelId]) return;

  channelsViewed[channelId] = true;
  track(types.CHANNEL_CARD_VIEW, channelTraits(channelId, traits));
};

export const trackChannelClick = (channelId, traits) => {
  track(types.CHANNEL_CLICK, channelTraits(channelId, traits));
};

export const trackChannelFollow = (channelId, traits) => {
  track(types.CHANNEL_FOLLOW, channelTraits(channelId, traits));
};

export const trackChannelUnfollow = (channelId, traits) => {
  track(types.CHANNEL_UNFOLLOW, channelTraits(channelId, traits));
};
