import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { contentSelectors } from '../../../models/content/index.js';
import { programSelectors } from '../../../models/program';

import Comments from '../../../components/comments';
import Comment from '../../../components/comments/comment';
import { DropdownList } from '../../../components/ui';
import { SortOrder } from '../../../common/use-comment-feed';
import { trackContentCardCommentSortClick } from '../../../models/content/analytics';
import { RootPatronState } from '../../../common/use-patron-selector';
import { ValueType } from '../../../lib/utility-types';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  contentId: number;
};

type ContentInfoCommentsProps = StateProps & OwnProps;

const ContentInfoComments = ({
  contentId,
  canComment,
  commentCount,
  commentSortEnabled,
}: ContentInfoCommentsProps) => {
  const { commentId } = useParams<{ commentId?: string }>();

  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.TOP);
  const onSelectionChange = (item: ValueType<typeof commentSortOptions>) => {
    const sort_type = item.sortOrder;
    trackContentCardCommentSortClick(contentId, { sort_type });
    setSortOrder(sort_type);
  };

  const commentSortOptions = Object.values(SortOrder).map((item) => ({
    name: t(`comments.sort.${item}.name`),
    description: t(`comments.sort.${item}.description`),
    sortOrder: item,
  }));

  const showCommentSort = commentSortEnabled ? !commentId : false;

  return (
    <div className="content-info__comments">
      {canComment ? (
        <>
          <div className="comment-title-container">
            {showCommentSort ? (
              <DropdownList
                options={commentSortOptions}
                onSelectionChange={onSelectionChange}
                disabled={commentCount <= 1}
              />
            ) : null}

            <div className="section-title section-title--small">
              {commentCount} {t('content.comment', { count: commentCount })}
            </div>
          </div>

          {!commentId ? (
            <Comments contentId={contentId} sortOrder={sortOrder} />
          ) : (
            <Comment contentId={contentId} commentId={commentId} />
          )}
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootPatronState, ownProps: OwnProps) => ({
  canComment: contentSelectors.getContentCanComment(state, ownProps),
  commentCount: contentSelectors.getContentCommentCount(state, ownProps),
  commentSortEnabled: programSelectors.getCommentSortEnabled(state),
});

export default connect(mapStateToProps)(ContentInfoComments);
