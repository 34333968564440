import { NotificationContextProvider } from './context';
import {
  NotificationCenterProps,
  NotificationCenterRoot,
} from './notification-center-root';

export const NotificationCenter: React.FC<NotificationCenterProps> = (
  props
) => {
  return (
    <NotificationContextProvider>
      <NotificationCenterRoot {...props} />
    </NotificationContextProvider>
  );
};
