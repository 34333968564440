// This library is taken from draft-js-export-html and modified slightly.
//   First used flow-remove-types to get rid of the flow-annotations.
//   see here for source https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-export-html

function combineOrderedStyles(customMap, defaults) {
  if (customMap == null) {
    return defaults;
  }
  let [defaultStyleMap, defaultStyleOrder] = defaults;
  let styleMap = {
    ...defaultStyleMap,
  };
  let styleOrder = [...defaultStyleOrder];
  for (let styleName of Object.keys(customMap)) {
    // TODO: refactor for ESLint
    // eslint-disable-next-line no-prototype-builtins
    if (defaultStyleMap.hasOwnProperty(styleName)) {
      let defaultStyles = defaultStyleMap[styleName];
      styleMap[styleName] = {
        ...defaultStyles,
        ...customMap[styleName],
      };
    } else {
      styleMap[styleName] = customMap[styleName];
      styleOrder.push(styleName);
    }
  }
  return [styleMap, styleOrder];
}

export default combineOrderedStyles;
