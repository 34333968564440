import { useTranslation } from 'react-i18next';
import { Icon } from '.';
import cx from 'classnames';
import { ComponentProps } from 'react';

export const Globe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    focusable="false"
    {...props}
  >
    <path d="M15.868 4.998c-.58-2.008-1.479-3.62-2.58-4.645 2.588.722 4.772 2.402 6.15 4.645h-3.57zm5.09 5.88h-4.376c-.026 1.495-.202 2.915-.504 4.2h3.813c.639-1.277 1.008-2.696 1.067-4.2zm-5.998 5.04h-4.057v4.998c1.689-.286 3.184-2.234 4.057-4.998zm4.94-10.08h-3.814c.302 1.285.479 2.705.504 4.2h4.376c-.067-1.504-.436-2.923-1.066-4.2zm-.462 10.08h-3.57c-.58 2.008-1.479 3.62-2.58 4.645 2.588-.714 4.772-2.402 6.15-4.645zm-4.242-10.08h-4.293v4.2h4.847c-.034-1.495-.227-2.915-.554-4.2zm-4.293 9.24h4.293c.327-1.285.52-2.705.554-4.2h-4.847v4.2zm0-15.078v4.998h4.057C14.087 2.234 12.592.286 10.903 0zM.008 10.038h4.377c.025-1.495.201-2.915.504-4.2H1.075C.437 7.115.067 8.534.008 10.038zm7.67 10.525c-1.1-1.033-2-2.646-2.58-4.645h-3.57c1.378 2.243 3.562 3.931 6.15 4.645zm-6.611-5.485H4.88c-.302-1.285-.478-2.705-.504-4.2H0c.067 1.504.437 2.923 1.067 4.2zm8.996-4.2H5.216c.034 1.495.227 2.915.555 4.2h4.292v-4.2zm-4.057-5.88h4.057V0C8.375.286 6.88 2.234 6.006 4.998zm4.057 15.918v-4.998H6.006c.874 2.764 2.369 4.712 4.057 4.998zM1.53 4.998h3.57c.58-2.008 1.478-3.62 2.579-4.645-2.588.714-4.772 2.402-6.15 4.645zm8.534.84H5.771c-.328 1.285-.521 2.705-.555 4.2h4.847v-4.2z" />
  </svg>
);

export const Inbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="21"
    viewBox="0 0 25 21"
    focusable="false"
    {...props}
  >
    <path d="M24.97 10.37v-.04L21.7 1.32A2.008 2.008 0 0019.82 0H5c-.85 0-1.62.54-1.89 1.35L.03 10.34c-.01.02 0 .04-.01.06-.01.03-.02.07-.02.1v9.25C0 20.44.56 21 1.25 21h22.5c.69 0 1.25-.56 1.25-1.25V10.5c0-.05-.01-.09-.03-.13zM4.05 1.68c.14-.4.52-.68.95-.68h14.83c.42 0 .8.27.94.66L23.79 10h-5.54c-.69 0-1.25.56-1.25 1.25v3.5c0 .14-.11.25-.25.25h-8.5c-.14 0-.25-.11-.25-.25v-3.5C8 10.56 7.44 10 6.75 10H1.2l2.85-8.32z" />
  </svg>
);

export const Chat = (props) => (
  <span
    role="img"
    className="material-icons"
    {...props}
    style={{
      fontFamily: 'Material Icons',
      fontSize: '17px',
      marginRight: '6px',
      ...(props.style ?? {}),
    }}
  >
    chat_bubble_outline
  </span>
);

export const Notifications = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="23"
    viewBox="0 0 20 23"
    focusable="false"
    {...props}
  >
    <path d="M16.941 15.54V9.435c0-3.408-2.417-6.26-5.647-7.015v-.755C11.294.745 10.536 0 9.6 0c-.936 0-1.694.745-1.694 1.665v.755c-3.242.755-5.647 3.607-5.647 7.015v6.105L0 17.76v1.11h19.2v-1.11l-2.259-2.22zM9.6 22.2c1.248 0 2.259-.994 2.259-2.22H7.34c0 1.226 1.011 2.22 2.259 2.22z" />
  </svg>
);

export const Compass = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    explore
  </Icon>
);

export const LightBulb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    focusable="false"
  >
    <path
      fill="#FFF"
      d="M20.196 9.192c-1.656-1.476-3.876-2.184-6.084-1.944-3.42.384-6.288 3.12-6.816 6.504-.36 2.292.3 4.596 1.788 6.336 1.116 1.296 1.704 2.784 1.704 4.296V25.8c0 2.316 1.884 4.2 4.2 4.2 2.316 0 4.2-1.884 4.2-4.2v-1.428c0-1.5.6-3.012 1.68-4.26 1.236-1.416 1.92-3.24 1.92-5.112.012-2.22-.936-4.332-2.592-5.808zM18 25.8c0 1.656-1.344 3-3 3s-3-1.344-3-3v-.6h6v.6zM15.6 0h-1.2v4.8h1.2V0zm9.6 15.6v1.2H30v-1.2h-4.8zM0 16.8h4.8v-1.2H0v1.2zM2.436 4.728l3.396 3.396.852-.852-3.396-3.396-.852.852zm23.784-.852l-3.396 3.396.852.852 3.396-3.396-.852-.852z"
    />
  </svg>
);

export const HappyInbox = (props) => (
  <svg
    width="112"
    height="100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.84 13.597h85.006V67.77H13.84V13.597Z"
      fill="#D6DAE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.84 13.597v82.288H.13v-49.76l13.71-32.528ZM98.846 13.597V95.2h13.025V46.126L98.846 13.597Z"
      fill="#C1C8D4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.746 4H87.87a2.41 2.41 0 0 1 2.41 2.41v25.457a2.41 2.41 0 0 1-2.41 2.41H24.747a2.41 2.41 0 0 1-2.41-2.41V6.41A2.41 2.41 0 0 1 24.746 4h-.001Z"
      fill="#F8EBEE"
      stroke="#fff"
      strokeWidth="6.497"
    />
    <path
      d="M93.333 14.53H19.277a.567.567 0 0 0-.567.567v32.351c0 .313.253.567.567.567h74.056a.567.567 0 0 0 .567-.567V15.097a.567.567 0 0 0-.567-.567Z"
      fill="#EFE8D8"
      stroke="#fff"
      strokeWidth="7.219"
    />
    <path
      d="M96.974 27.095H14.943a.567.567 0 0 0-.567.567v36.305c0 .313.253.567.567.567h82.03a.567.567 0 0 0 .568-.567V27.662a.567.567 0 0 0-.567-.567Z"
      stroke="#fff"
      strokeWidth="8.021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.13 45.827H111.87v50.752c0 1.89-1.532 3.421-3.421 3.421H3.55a3.42 3.42 0 0 1-3.42-3.42V45.826v.001Z"
      fill="#F0F2F7"
    />
    <path
      opacity=".391"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.395 65.115c1.19-.515 2.363-.773 3.52-.773 1.132 0 2.265.247 3.397.74a2.819 2.819 0 0 1 1.691 2.583.971.971 0 0 1-1.387.877c-1.233-.585-2.475-.877-3.727-.877-1.258 0-2.516.295-3.774.886a.98.98 0 0 1-1.395-.886c0-1.108.658-2.11 1.675-2.55Zm34.962 0c1.19-.515 2.363-.773 3.52-.773 1.132 0 2.265.247 3.397.74a2.819 2.819 0 0 1 1.691 2.583.971.971 0 0 1-1.387.877c-1.233-.585-2.475-.877-3.727-.877-1.258 0-2.516.295-3.774.886a.98.98 0 0 1-1.395-.886c0-1.108.658-2.11 1.675-2.55ZM56.343 83.546c6.057 0 10.968-3.54 10.968-9.6-10.968-.036-13.327.175-21.937 0 0 6.06 4.911 9.6 10.969 9.6Z"
      fill="#1B3554"
    />
  </svg>
);

export const Shield = () => (
  <svg
    viewBox="0 0 24 24"
    className="icon mr-10"
    focusable="false"
    style={{ width: '24px', height: '24px' }}
  >
    <path
      fill="currentColor"
      d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M11,7H13V13H11V7M11,15H13V17H11V15Z"
    />
  </svg>
);

export const LaptopRobot = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" focusable="false">
    <g fill="none" fillRule="evenodd" stroke="#000" transform="translate(1 1)">
      <rect width="52" height="33" x="18" y="18" rx="3" />
      <path d="M70 78v-9.333a4.701 4.701 0 0 0-1.342-3.302A4.543 4.543 0 0 0 65.412 64H22.588a4.543 4.543 0 0 0-3.246 1.365A4.701 4.701 0 0 0 18 68.667V78M28 46h33M28 51v-8a3 3 0 0 1 3-3h27a3 3 0 0 1 3 3v8" />
      <rect width="9" height="9" x="27" y="24" rx="2" />
      <rect width="9" height="9" x="52" y="24" rx="2" />
      <path d="M18 44c-4.418 0-8-3.358-8-7.5 0-4.142 3.582-7.5 8-7.5M70 29c4.418 0 8 3.358 8 7.5 0 4.142-3.582 7.5-8 7.5M39.226 9L44 14.047l-5 3.803.141.15M46.73 18L52 14.118 43 5" />
      <circle cx="38.5" cy="4.5" r="4.5" />
      <path d="M38 58a3 3 0 1 1 0-6M38 64a3 3 0 1 1 0-6M50 52a3 3 0 0 1 0 6M50 58a3 3 0 0 1 0 6M50 64H38M88 83.5v3a1.513 1.513 0 0 1-1.517 1.5H1.517A1.513 1.513 0 0 1 0 86.5v-3C0 81.015 2.038 79 4.552 79h28.827c.838 0 1.518.672 1.518 1.5s.679 1.5 1.517 1.5h16.69c.837 0 1.517-.672 1.517-1.5S55.3 79 56.138 79h27.31C85.962 79 88 81.015 88 83.5zM17.765 23H8.588a4.607 4.607 0 0 0-3.246 1.328A4.51 4.51 0 0 0 4 27.541V79h26M62 79h21V27.54a4.555 4.555 0 0 0-1.316-3.212A4.474 4.474 0 0 0 78.5 23h-9" />
      <path d="M25 79v-6a3 3 0 0 1 3-3h33a3 3 0 0 1 3 3v6M18 70c-4.418 0-8 3.358-8 7.5V79M70 70c4.418 0 8 3.358 8 7.5V79" />
    </g>
  </svg>
);

export const Profile = (props) => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      focusable="false"
      {...props}
    >
      <title>{t('screens.account.profile.title')}</title>
      <path d="M12.5 0C5.61 0 0 5.61 0 12.5c0 3.39 1.4 6.68 3.84 9.02 2.19 2.11 5.03 3.31 8.06 3.45.15.01.31.03.46.03h.14c3.23 0 6.29-1.22 8.63-3.45 2.5-2.39 3.87-5.6 3.87-9.05C25 5.61 19.39 0 12.5 0zm8.41 20.33c-.46-1.63-2.64-2.51-4.28-3.16l-.56-.23C15 16.5 15 16.1 15 15.5c0-.42.21-1.02.59-1.38.97-.85 1.48-2.02 1.48-3.38C17.07 7.99 15.15 6 12.5 6c-2.64 0-4.48 1.95-4.48 4.74 0 1.42.48 2.56 1.41 3.38.39.35.56.96.56 1.38 0 .42 0 .94-1.3 1.47-1.6.66-3.72 1.56-4.69 3.26-1.91-2.11-3-4.88-3-7.73C1 6.16 6.16 1 12.5 1S24 6.16 24 12.5c0 2.94-1.1 5.69-3.09 7.83z" />
    </svg>
  );
};

export const SideBarProfileIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    account_circle
  </Icon>
);

export const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="8"
    viewBox="0 0 6 8"
    focusable="false"
  >
    <path
      fillRule="evenodd"
      d="M0.333 7.053L3.424 4 0.333 0.94 1.285 0 5.333 4 1.285 8z"
    />
  </svg>
);

export const ChevronLeft = (props) => (
  <Icon className="old" type="chevron_left" {...props} />
);

export const VideoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M63.75 496.625v8.25c0 .143-.08.27-.202.338-.05.03-.108.037-.158.037-.08 0-.151-.023-.216-.075L60.386 503h-.958v-4.5h.958l2.788-2.175c.108-.083.252-.098.374-.038.123.068.202.195.202.338zm-5.942-1.125h-1.11l-3.046-3.173c-.087-.742-.685-1.327-1.427-1.327h-2.88c-.793 0-1.441.675-1.441 1.5s.648 1.5 1.44 1.5h2.881c.548 0 1.016-.322 1.26-.788l2.198 2.288H46.65c-.497 0-.9.42-.9.938v8.625c0 .517.403.937.9.937h11.165c.497 0 .9-.42.9-.938v-8.625c-.007-.517-.41-.937-.907-.937z"
        transform="translate(-44 -488)"
      />
    </g>
  </svg>
);

export const VideoPostIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    videocam
  </Icon>
);

export const ImageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M191.781 491H174.22c-.539 0-.976.427-.976.952v14.096c0 .525.437.952.976.952h17.562c.539 0 .976-.427.976-.952v-14.096c0-.525-.437-.952-.976-.952zm-12.684 3.048c1.077 0 1.952.853 1.952 1.904 0 1.052-.875 1.905-1.952 1.905s-1.951-.853-1.951-1.905c0-1.051.874-1.904 1.951-1.904zm-2.732 9.142s.61-3.931 2.49-3.931c1.6 0 2.147 2.88 3.731.58 1.772-2.576 3.068-5.852 7.049 3.351h-13.27z"
        transform="translate(-172 -488)"
      />
    </g>
  </svg>
);

export const NoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M323 506.881h3.2v1.619h-8v-1.619h3.2v-13.762h-4.8v.81H315v-1.62c0-.445.36-.809.8-.809h12.8c.44 0 .8.364.8.81v1.619h-1.6v-.81H323v13.762zm9.6 0c-.44 0-.8-.364-.8-.81v-4.047h2.4v-1.62h-2.4v-3.237h-1.6v3.238h-2.4v1.619h2.4v4.047c0 1.336 1.08 2.429 2.4 2.429h2.4v-1.619h-2.4z"
        transform="translate(-314 -489)"
      />
    </g>
  </svg>
);

export const ArticleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M476.657 497.783l-4.987-4.666c-.075-.07-.175-.117-.284-.117H459.71c-.233 0-.467.171-.467.389v13.222c0 .218.242.389.467.389h16.682c.233 0 .367-.171.367-.389v-8.555c0-.102-.017-.203-.1-.273zm-14.078-.116h5.004v.777h-5.004v-.777zm0 4.666h10.008v.778H462.58v-.778zm10.842-1.555H462.58V500h10.842v.778zm-1.668-3.111v-3.337l3.637 3.337h-3.637z"
        transform="translate(-457 -489)"
      />
    </g>
  </svg>
);

export const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M618.75 492h-15.528c-.813 0-1.479.665-1.479 1.478v5.913c0 .813.666 1.479 1.479 1.479h15.527c.814 0 1.48-.666 1.48-1.479v-5.913c0-.813-.666-1.478-1.48-1.478zm-11.092 5.41c0 .732-.458 1.242-1.109 1.242-.295 0-.547-.103-.74-.288-.191.185-.443.288-.738.288-.651 0-1.11-.51-1.11-1.242v-3.193h.74v3.193c0 .148.037.503.37.503.332 0 .37-.355.37-.503v-2.453h.739v2.453c0 .148.037.503.37.503.332 0 .369-.355.369-.503v-3.193h.74v3.193zm5.176 0c0 .732-.458 1.242-1.109 1.242-.296 0-.547-.103-.74-.288-.192.185-.443.288-.739.288-.65 0-1.109-.51-1.109-1.242v-3.193h.74v3.193c0 .148.037.503.37.503.332 0 .369-.355.369-.503v-2.453h.74v2.453c0 .148.036.503.37.503.332 0 .369-.355.369-.503v-3.193h.74v3.193zm5.176 0c0 .732-.458 1.242-1.11 1.242-.295 0-.546-.103-.739-.288-.192.185-.443.288-.739.288-.65 0-1.109-.51-1.109-1.242v-3.193h.74v3.193c0 .148.036.503.37.503.332 0 .369-.355.369-.503v-2.453h.74v2.453c0 .148.036.503.369.503s.37-.355.37-.503v-3.193h.739v3.193zm2.004 7.4l-5.176-2.425c-.118-.06-.259-.045-.37.03-.11.08-.17.214-.155.34l.643 5.802c.015.162.126.288.281.325.155.037.31-.03.4-.163l.909-1.441 1.294 1.589c.066.089.177.133.28.133.082 0 .156-.022.223-.074l1.264-.931c.081-.067.133-.155.148-.252.015-.103-.015-.206-.081-.28l-1.25-1.538 1.538-.428c.148-.045.251-.17.266-.326.015-.155-.074-.295-.214-.362z"
        transform="translate(-600 -489)"
      />
    </g>
  </svg>
);

export const FeaturedIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    focusable="false"
    className="content-tag-image"
  >
    <path
      fill={color || '#000'}
      fillRule="nonzero"
      d="M13 .707A.707.707 0 0 0 12.293 0H.707A.707.707 0 0 0 0 .707v11.586c0
      .39.317.707.707.707h11.586c.39 0 .707-.317.707-.707V.707zM9.58 8.94a.736.736 0 0
      1-.236.035.49.49 0 0 1-.341-.131L7.2 7.056 4.242 10 3 8.765 5.94 5.82
      4.155 4.034c-.123-.123-.166-.456-.096-.622.07-.167.227-.412.402-.412h4.813c.245 0
      .726.482.726.718v4.819c-.009.175-.262.333-.42.403z"
    />
  </svg>
);

export const StarFeaturedIcon = (props) => <Icon {...props} type="star" />;

export const TrendingIcon = (props) => (
  <Icon
    aria-hidden="true"
    {...props}
    className={cx('old', props?.className)}
    type="whatshot"
  />
);

export const SwitchIcon = (props) => (
  <Icon aria-hidden="true" {...props} type="cached" />
);

export const BookmarkIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    bookmarks
  </Icon>
);

export const BookmarkedIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    bookmark_added
  </Icon>
);

export const PostIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    article
  </Icon>
);

export const ChannelsIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    check_circle
  </Icon>
);

export const EditProfileIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    edit
  </Icon>
);

export const ResetPasswordIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    lock
  </Icon>
);

export const ShareIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    share
  </Icon>
);

export const SubmitCommentIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    send
  </Icon>
);

export const PersonIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    person
  </Icon>
);

export const LatestIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    new_releases
  </Icon>
);

export const ResourcesIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    all_inbox
  </Icon>
);

export const InviteIcon = (props) => (
  <Icon aria-hidden="true" {...props} className={cx('old', props?.className)}>
    person_add
  </Icon>
);

export const HomeIcon = (props) => (
  <Icon type="home" {...props} className={cx('old', props?.className)} />
);

export const JoinedChannelsIcon = (props) => (
  <Icon
    {...props}
    className={cx('old', props?.className)}
    type="check_circle"
  />
);

export const JoinChannelsIcon = (props) => (
  <Icon
    {...props}
    className={cx('old', props?.className)}
    type="add_circle_outline"
  />
);

export const SettingsIcon = (props) => (
  <Icon {...props} className={cx('old', props?.className)} type="settings" />
);
