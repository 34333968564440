import { PropsWithChildren, ReactElement } from 'react';

type TopicPageSidebarCard = PropsWithChildren<{
  title: string;
  action?: ReactElement;
}>;

export function TopicPageSidebarCard({
  children,
  title,
  action,
}: TopicPageSidebarCard) {
  return (
    <div className="topic-page__card">
      <div className="topic-page__card-header">
        <h3>{title}</h3>
        {action}
      </div>
      {children}
    </div>
  );
}
