import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import AssistantService from '../services/assistant';
import store from '../config/store';
import { Actionable } from '../models/assistant/interfaces/Actionable';

import { ID as ExternalLinkModalId } from '../components/external-link-modal/external-link-modal';
import { uiOperations } from '../models/ui';
import { shouldShowExternalLinkWarning } from '../lib/teams-helper';

export default function useAssistantActionHandlers() {
  const history = useHistory();
  const dispatch = useDispatch();

  const getDeeplink = useCallback(
    (navigation: Actionable['navigation'], pushState?: unknown) => {
      const state = store.getState();
      const programPath = state.appConfig.program_path;
      const experienceBase = state.program.experience_url;

      let url = navigation.url;
      let isPush = false;

      // Start by checking for old-style #sc4 links
      // If the program has a programPath, we can split on it
      // If no path, we can split on the base URL
      // If none of these work we fallback to opening the full URL as push=false
      if (navigation.url.match(/sc4/)) {
        url = '/sc4' + navigation.url.split('sc4')[1];
        isPush = true;
      } else if (programPath && navigation.url.match(programPath)) {
        url = navigation.url.split(programPath)[1];
        isPush = true;
      } else if (experienceBase && navigation.url.match(experienceBase)) {
        url = navigation.url.split(experienceBase)[1];
        isPush = true;
      }

      const to = { pathname: url, state: pushState };

      return {
        isPush,
        url,
        ...(pushState ? { to } : undefined),
      };
    },
    []
  );

  const handleNavAction = useCallback(
    (navigation: Actionable['navigation'], analyticsData: unknown) => {
      switch (navigation.type) {
        case 'deeplink':
          // eslint-disable-next-line no-case-declarations
          const deeplink = getDeeplink(navigation);
          if (deeplink.isPush) {
            history.push(deeplink.url);
          } else {
            window.location.href = deeplink.url;
          }
          break;
        case 'internal':
          // Open summary detail
          history.push('/assistant/commands/internal', {
            renderUrl: navigation.url,
            analyticsData,
          });
          break;
        case 'authorization':
        case 'external':
          if (shouldShowExternalLinkWarning()) {
            dispatch(
              uiOperations.displayOverlay(ExternalLinkModalId, {
                linkUrl: navigation.url,
              })
            );
          } else {
            window.open(navigation.url, '_blank');
          }
          break;
        default:
          window.location.href = navigation.url;
      }
    },
    [getDeeplink, history, dispatch]
  );

  const handleRequest = useCallback((request) => {
    return new AssistantService().handleRequest(request);
  }, []);

  const handleAction = useCallback(
    (action: Actionable | undefined, analyticsData?: unknown) => {
      if (!action) return;

      switch (action.type) {
        case 'navigation':
          return handleNavAction(action.navigation, analyticsData);
        case 'request':
          return handleRequest(action.request);
        default:
          break;
      }
    },
    [handleNavAction, handleRequest]
  );

  return {
    getDeeplink,
    handleAction,
    handleRequest,
  };
}
