import { RootPatronState } from '../../common/use-patron-selector';
import { BoxResourceId, BoxResourceType } from './types';
import { getTokenDataKey } from './utils';

export const getTokenData = (
  state: RootPatronState,
  resourceType: BoxResourceType,
  resourceId: BoxResourceId
) =>
  state.boxIntegration?.tokenData?.[getTokenDataKey(resourceType, resourceId)];

export const getTokenExpiresAt = (
  state: RootPatronState,
  resourceType: BoxResourceType,
  resourceId: BoxResourceId
) => getTokenData(state, resourceType, resourceId)?.expiresAt;

export const getTokenValue = (
  state: RootPatronState,
  resourceType: BoxResourceType,
  resourceId: BoxResourceId
) => getTokenData(state, resourceType, resourceId)?.token;
