import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getChannelById } from '../../../../models/channels/selectors';
import { channelOperations } from '../../../../models/channels';
import {
  trackChannelFollow,
  trackChannelUnfollow,
} from '../../../../models/channels/analytics';
import { Button } from '../../../ui';
import { Feature, getFeatureFlag } from '../../../../models/features/features';
import { RootPatronState } from '../../../../common/use-patron-selector';

type ChannelFollowButtonProps = {
  isFollowing: boolean;
  followingText: string;
  notFollowingText: string;
  handleClick: () => void;
};

const ChannelFollowButton = ({
  isFollowing,
  followingText,
  notFollowingText,
  handleClick,
}: ChannelFollowButtonProps) => {
  const icon = isFollowing ? 'done' : 'add';
  const theme = isFollowing ? 'secondary' : 'primary';
  const label = isFollowing ? followingText : notFollowingText;

  return (
    <Button
      icon={icon}
      theme={theme}
      size={'compact'}
      onClick={handleClick}
      aria-label={label}
    >
      {label}
    </Button>
  );
};

type ChannelFollowButtonContainerProps = {
  channelId: string;
  analyticsData: unknown;
};

const ChannelFollowButtonContainer = ({
  channelId,
  analyticsData,
}: ChannelFollowButtonContainerProps) => {
  const { t } = useTranslation();

  const following =
    useSelector((state: RootPatronState) => getChannelById(state, channelId))
      ?.following || false;
  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );

  const followingText = newTopicsString
    ? 'screens.explore.following'
    : 'screens.explore.joined';
  const notFollowingText = newTopicsString
    ? 'screens.explore.follow'
    : 'screens.explore.join';

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (following) {
      dispatch(channelOperations.unfollowChannel(channelId));
      trackChannelUnfollow(channelId, analyticsData);
    } else {
      dispatch(channelOperations.followChannel(channelId));
      trackChannelFollow(channelId, analyticsData);
    }
  }, [dispatch, channelId, analyticsData, following]);

  return (
    <ChannelFollowButton
      isFollowing={following}
      followingText={t(followingText)}
      notFollowingText={t(notFollowingText)}
      handleClick={handleClick}
    />
  );
};

export default ChannelFollowButtonContainer;
