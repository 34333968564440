import { useState, useEffect } from 'react';
import AdvocateService from '../services/advocate';

const useProfileFeed = ({ search }) => {
  const [profiles, setProfiles] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState();
  const [page, setPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [total, setTotal] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState();
  const resetState = () => {
    setProfiles([]);
    setIsFetching(true);
    setIsError();
    setPage(0);
    setCanLoadMore();
    setTotal(0);
  };

  const advocateService = new AdvocateService();

  const fetchProfiles = async () => {
    setIsFetching(true);

    try {
      const res = await advocateService.search(search, page + 1);
      handleFetchSuccess(res);
    } catch (err) {
      if (err.response) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res) => {
    const items = res.data.profiles;
    const [curPage, pageSize] =
      res.data.meta && res.data.meta.page
        ? [
            res.data.meta.page.page || res.data.meta.page.number,
            res.data.meta.page.size,
          ]
        : [1, 0];

    setProfiles([...profiles, ...items]);
    setCanLoadMore(pageSize && items.length >= pageSize);
    setPage(curPage);
    setNumPages(res.data.meta.page.total);
    setPageSize(res.data.meta.page.size);
    setTotal(res.data.meta.total || 0);
    setIsFetching(false);
  };

  const handleFetchFailure = () => {
    setIsError(true);
    setIsFetching(false);
  };

  useEffect(() => {
    resetState();

    fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    profiles,
    page,
    numPages,
    pageSize,
    isFetching,
    isError,
    canLoadMore,
    total,
    onLoadMore: fetchProfiles,
  };
};

export default useProfileFeed;
