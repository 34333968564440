import { Container } from '@socialchorus/shared-ui-components';
import { useEffect } from 'react';
import styles from './documents.module.scss';
import { DocumentCard } from './document-card';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';
import { useProgram } from '../../../../common/use-program';
import { ResourceObject } from '../../../../lib/types/json-api';
import { trackDocumentView } from '../../../../models/analytics';
import { ContentAttachment } from '../../../../models/content/types';
import { NoResultsFound } from '../no-results-found';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../skip-to-content/skip-to-content';
import { t } from 'i18next';
import { VisuallyHidden } from '../../visually-hidden';
import { createCacheKey, optimus, parseCacheKey } from '../http';
import { InfiniteSearchResults, useInfiniteSearch } from '../infinite-search';
import { useLocalizedScreenReaderLoadingMessage } from '../locale';
import { CompleteError } from '../overview/overview';
import { SearchParams, stringify, useSearchQueryParams } from '../query-params';
import { SearchResultState } from '../search-screen';
import { DocumentLoadingSkeleton } from './document-loading-skeleton';
import { Filter } from '../filter-bar/filters/filters';
import { useFilters } from '../filter-context';

export type DocumentSearchResponse = {
  data: ResourceObject<'content_attachment', ContentAttachment>[];
};

export async function searchDocuments(programId: string, params: SearchParams) {
  const query = stringify(params);

  return optimus
    .get<DocumentSearchResponse>(
      `/v1/programs/${programId}/search/attachments?${query}`
    )
    .then((response) => response.data);
}

export const documentsCacheKey = createCacheKey('documents');
export const useDocumentSearch = (searchParams: SearchParams) => {
  const programId = useProgram().id;
  const {
    data: documentSearchResponse,
    isLoading,
    ...rest
  } = useInfiniteSearch(
    (page) => [documentsCacheKey({ ...searchParams, page: page + 1 })],
    ([cacheKey]) => searchDocuments(programId, parseCacheKey(cacheKey).params)
  );

  const isLoadingMore = Boolean(
    isLoading ||
      (documentSearchResponse &&
        documentSearchResponse[documentSearchResponse.length - 1] === undefined)
  );

  const isEmpty = documentSearchResponse?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (documentSearchResponse &&
        documentSearchResponse[documentSearchResponse.length - 1]?.data.length <
          Number(searchParams?.perPage))
  );

  return {
    documentsData: documentSearchResponse,
    isLoadingMore,
    isReachingEnd,
    ...rest,
  };
};

interface DocumentsProps {
  searchReturnedResults?: (searchResultState: SearchResultState) => void;
}

export function DocumentSearch({ searchReturnedResults }: DocumentsProps) {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const {
    documentsData,
    error,
    isValidating,
    isLoadingMore,
    isReachingEnd,
    mutate: retry,
    size,
    setSize,
  } = useDocumentSearch(query);

  const srOnlyLoadingMessage =
    useLocalizedScreenReaderLoadingMessage('documents');

  const retryDocumentSearch = () => {
    retry();
  };

  useEffect(() => {
    if (documentsData !== undefined && documentsData[0].data.length > 0) {
      trackDocumentView();
    }
  }, [documentsData]);

  if (searchReturnedResults && !documentsData) {
    searchReturnedResults(SearchResultState.Loading);
  }

  if (searchReturnedResults && documentsData !== undefined && !error) {
    searchReturnedResults(SearchResultState.HasResults);
  }

  if (documentsData !== undefined) {
    return (
      <section id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {documentsData[0].data.length > 0 ? (
          <InfiniteSearchResults
            isLoadingMore={isValidating || isLoadingMore}
            isReachingEnd={isReachingEnd}
            onViewTrigger={() => setSize(size + 1)}
            errorFetching={Boolean(error)}
            handleRetry={retryDocumentSearch}
          >
            <Container
              variant="large"
              shadow="light"
              className={styles.DocumentsContainer}
            >
              {documentsData.map((documents) => {
                return documents.data.map((document) => (
                  <DocumentCard
                    key={document.id}
                    document={document.attributes}
                  />
                ));
              })}
            </Container>
          </InfiniteSearchResults>
        ) : (
          <NoResultsFound
            title={t('search.no_results_type.title', {
              type: t(`search.types.documents`),
            })}
            description={t('search.no_results_type.description')}
          />
        )}
      </section>
    );
  }

  if (error) {
    return (
      <CompleteError
        query={query.query ?? ''}
        handleRetry={retryDocumentSearch}
      />
    );
  }

  return (
    <>
      <VisuallyHidden id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {srOnlyLoadingMessage}
      </VisuallyHidden>
      <Container
        variant="large"
        className={styles.DocumentsContainer}
        aria-busy
        aria-live="polite"
      >
        {Array.from({ length: 35 }).map((_, index) => (
          <DocumentLoadingSkeleton key={index} />
        ))}
      </Container>
    </>
  );
}
