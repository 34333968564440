import React from 'react';
import { useTranslation } from 'react-i18next';

import withRerenderPrevent from '../../common/with-rerender-prevent';

import useScreenTitle from '../../common/use-screen-title';
import useContentFeed from '../../common/use-content-feed';

import Page, {
  PageMain,
  PageSidebar,
  PageTitle,
} from '../../components/ui/page';
import PageNav from '../../components/page-nav';
import Feed from '../../components/feed';

import './filtered-feed.scss';
import { programSelectors } from '../../models/program';
import { connect } from 'react-redux';
import {
  LatestIcon,
  PersonIcon,
  ResourcesIcon,
  StarFeaturedIcon,
  TrendingIcon,
} from '../../components/ui';

const FilteredFeed = ({
  match: {
    params: { filter = 'latest' },
  },
  resourcesEnabled,
  forYouFeedEnabled,
}) => {
  const { t } = useTranslation();

  const capitalizeWord = (w) => w.charAt(0).toUpperCase() + w.slice(1);

  const filterTitle = filter.split('_').map(capitalizeWord).join(' ');

  const filterTranslations = {
    featured: 'content.featured',
    latest: 'landing.latest',
    trending: 'landing.trending',
    community: 'landing.from_the_community',
    bookmarks: 'screens.bookmarks.title',
    resources: 'menu.resources',
    for_you: 'landing.for_you',
    recent_activity: 'screens.recent_activity.title',
  };

  const sectionTitle = t(filterTranslations[filter], filterTitle);

  useScreenTitle(sectionTitle);

  const analyticsData = {
    feed_filter: filter,
    location: 'filtered_feed',
  };

  const { fetching, feedItems, page, canLoadMore, fetchFeed } = useContentFeed({
    filter: filter === 'community' ? 'community_images' : filter,
  });

  const navItems = [];
  if (resourcesEnabled) {
    navItems.push({
      title: t('menu.resources'),
      url: '/feed/resources',
      iconComponent: <ResourcesIcon />,
    });
  }
  navItems.push(
    ...[
      {
        title: t('content.featured'),
        url: '/feed/featured',
        iconComponent: <StarFeaturedIcon />,
      },
      {
        title: t('landing.latest'),
        url: '/feed/latest',
        iconComponent: <LatestIcon />,
      },
      {
        title: t('landing.trending'),
        url: '/feed/trending',
        iconComponent: <TrendingIcon />,
      },
    ]
  );
  if (forYouFeedEnabled) {
    navItems.push({
      title: t('landing.for_you'),
      url: '/feed/for_you',
      iconComponent: <PersonIcon />,
    });
  }

  return (
    <Page className="filtered-feed">
      <PageSidebar>
        <PageTitle>{sectionTitle}</PageTitle>

        <PageNav items={navItems} />
      </PageSidebar>

      <PageMain>
        <Feed
          items={feedItems}
          page={page}
          fetching={fetching}
          canLoadMore={canLoadMore}
          fetchFeed={fetchFeed}
          analyticsData={analyticsData}
          contentTitleTag="h2"
        />
      </PageMain>
    </Page>
  );
};

const mapStateToProps = (state, ownProps) => ({
  resourcesEnabled: programSelectors.getProgramResourcesEnabled(
    state,
    ownProps
  ),
  forYouFeedEnabled: programSelectors.getForYouFeedEnabled(state, ownProps),
});

export default connect(mapStateToProps)(withRerenderPrevent(FilteredFeed));
