import { useRecoilValue } from 'recoil';
import { submissionAllChannels } from '../../../models/content-submission/atoms';
import { useMemo } from 'react';
import { NormalizedChannel } from '../../../models/channels/types';

type ChannelType = 'contributor' | 'publishable' | 'submittable';

type ChannelGroups = Partial<
  Record<ChannelType | 'suggested', NormalizedChannel[]>
>;

// Detect channel type based on its properties
export function getChannelType(channel: NormalizedChannel): ChannelType | null {
  if (channel.can_publish_as_owner) {
    return 'contributor';
  }
  if (channel.auto_publish) {
    return 'publishable';
  }
  if (channel.can_submit) {
    return 'submittable';
  }
  return null;
}

// Create filter function for searching channels
function getSearchFilter(text: string) {
  const filter = text.toLowerCase();
  if (!filter) return () => true;
  return (c: NormalizedChannel) =>
    c.name.toLowerCase().includes(filter) ||
    (c.description || '').toLowerCase().includes(filter);
}

// Create sort function for channels that will put selected channels to the top
function getSort(selectedChannelIds: number[]) {
  return (a: NormalizedChannel, b: NormalizedChannel): number => {
    const aSelected = selectedChannelIds.includes(b.id) ? 1 : 0;
    const bSelected = selectedChannelIds.includes(a.id) ? 1 : 0;
    return aSelected - bSelected;
  };
}

// Group channels by their type and sort them, this is done on initial render
function buildChannelGroups(
  channels: NormalizedChannel[],
  selectedChannelIds: number[]
): ChannelGroups {
  const groups: ChannelGroups = {};

  channels.forEach((channel) => {
    const t = getChannelType(channel);
    if (!t) {
      return;
    }
    const group = groups[t] || [];
    group.push(channel);
    groups[t] = group;
  });

  const sort = getSort(selectedChannelIds);
  groups.contributor?.sort(sort);
  groups.submittable?.sort(sort);
  groups.publishable?.sort(sort);

  return groups;
}

type DisabledState = Partial<Record<ChannelType | 'suggested', boolean>>;

// Calculate disabled state based on selected channels
// We don't allow to select both contributor and publishable/submittable channels
function getDisabledState(
  channelGroups: ChannelGroups,
  selectedChannelIds: number[]
): DisabledState {
  const state: DisabledState = {
    contributor: false,
    submittable: false,
    publishable: false,
  };
  if (channelGroups.contributor) {
    const contributorSelected = channelGroups.contributor.some((c) =>
      selectedChannelIds.includes(c.id)
    );
    const publishableSelected = channelGroups.publishable?.some((c) =>
      selectedChannelIds.includes(c.id)
    );
    const submittableSelected = channelGroups.submittable?.some((c) =>
      selectedChannelIds.includes(c.id)
    );
    state.contributor = !!(publishableSelected || submittableSelected);
    state.publishable = contributorSelected;
    state.submittable = contributorSelected;
  }
  return state;
}

// If there are suggested channels, add them to separate group and remove from
// other groups. Also calculate disabled state for suggested channels
function addSuggestions(
  groups: ChannelGroups,
  disabled: DisabledState,
  suggestedChannels: NormalizedChannel[]
) {
  // Do nothing if there are no suggested channels
  if (!suggestedChannels.length) {
    return {
      groups,
      disabled,
    };
  }
  const newGroups = { ...groups };
  const newDisabled = { ...disabled };
  const hasContgibutorSuggestions =
    groups.contributor &&
    suggestedChannels.some((c) => getChannelType(c) === 'contributor');
  // If there are contributor suggestions, we only want to show them
  // otherwise we show publishable and submittable suggestions only
  const suggestionTypes: Array<ChannelType> = hasContgibutorSuggestions
    ? ['contributor']
    : ['publishable', 'submittable'];
  const suggested = suggestedChannels.filter((c) =>
    suggestionTypes.includes(getChannelType(c) as ChannelType)
  );
  if (!suggested.length) {
    return {
      groups,
      disabled,
    };
  }
  newGroups.suggested = suggested;
  newDisabled.suggested = hasContgibutorSuggestions
    ? disabled.contributor
    : disabled.publishable || disabled.submittable;
  const suggestedIndex = suggested.reduce<Record<string, boolean>>(
    (index, channel) => {
      index[channel.id] = true;
      return index;
    },
    {}
  );
  // Remove suggested channels from other groups, if group becomes empty, remove it
  suggestionTypes.forEach((type) => {
    if (newGroups[type]) {
      newGroups[type] = newGroups[type]?.filter((c) => !suggestedIndex[c.id]);
    }
    if (!newGroups[type]?.length) {
      delete newGroups[type];
    }
  });
  return {
    groups: newGroups,
    disabled: newDisabled,
  };
}

// Apply search to all groups,  if search text is empty, return original groups
// If group becomes empty after search, we keep it in the result, so we can show
// empty state for it
function applySearch(groups: ChannelGroups, searchText: string) {
  if (!searchText) {
    return groups;
  }
  const filter = getSearchFilter(searchText);
  return Object.entries(groups).reduce<ChannelGroups>(
    (acc, [key, channels]) => {
      acc[key as ChannelType] = channels.filter(filter);
      return acc;
    },
    {}
  );
}

export function useChannels(
  suggestions: NormalizedChannel[],
  searchText: string,
  selectedChannelIds: number[]
) {
  const allChannels = useRecoilValue(submissionAllChannels);
  // We want suggested channels to be elements from allChannels
  const suggestedChannels = useMemo<NormalizedChannel[]>(() => {
    if (!suggestions.length) {
      return [];
    }
    const channelIndex = allChannels.reduce<Record<string, NormalizedChannel>>(
      (index, channel) => {
        index[channel.id] = channel;
        return index;
      },
      {}
    );
    return suggestions.reduce<NormalizedChannel[]>((channels, c) => {
      const channel = channelIndex[c.id];
      if (channel) {
        channels.push(channel);
      }
      return channels;
    }, []);
  }, [allChannels, suggestions]);

  const channelGroups = useMemo(
    () => buildChannelGroups(allChannels, selectedChannelIds),
    // We only want put initially selected channels to the top,
    // so not adding selectedChannelIds to the dependencies
    [allChannels]
  );
  const disableState = useMemo(
    () => getDisabledState(channelGroups, selectedChannelIds),
    [channelGroups, selectedChannelIds]
  );
  const stateWithSuggestions = useMemo(
    () => addSuggestions(channelGroups, disableState, suggestedChannels),
    [channelGroups, disableState, suggestedChannels]
  );
  const filteredChannels = useMemo(
    () => applySearch(stateWithSuggestions.groups, searchText),
    [stateWithSuggestions.groups, searchText]
  );

  return {
    channels: filteredChannels,
    disabled: stateWithSuggestions.disabled,
  };
}
