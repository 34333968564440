import { useRef } from 'react';
import useTruncate from '../../common/use-truncate';

const ChannelDescription = ({ channel, truncate = undefined }) => {
  const ref = useRef();

  useTruncate({ ref, lines: truncate });

  return (
    <p className="channel__description" ref={ref}>
      {channel.description}
    </p>
  );
};

export default ChannelDescription;
