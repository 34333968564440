import { ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  submissionPublicationStatus,
  submissionIsEdit,
  submissionIsPending,
  submissionIsValid,
  submissionIsSubmitting,
} from '../../models/content-submission/atoms';
import { uiOperations } from '../../models/ui';
import { advocateSelectors } from '../../models/advocate';
import useOutsideClick from '../../common/use-outside-click';
import { Button, SettingsIcon } from '../../components/ui';
import { ID as ArchiveConfirmDialogID } from './archive-dialog';
import {
  ContentSubmissionTypes,
  publicationStateMap,
} from '../../services/content-submission';
import { RootPatronState } from '../../common/use-patron-selector';

type PopoverProps = {
  id?: string;
  children: ReactNode;
};

const Popover = ({ id, children }: PopoverProps) => {
  return (
    <div id={id} role="menu" className="content-submission-publish-popover">
      {children}
    </div>
  );
};

type SaveActionsStateProps = ReturnType<typeof mapStateToProps>;
type SaveActionsDispatchProps = typeof mapDispatchToProps;

type SaveActionsOwnProps = {
  onSubmit: (saveAs: ContentSubmissionTypes) => void;
};

type SaveActionsProps = SaveActionsStateProps &
  SaveActionsDispatchProps &
  SaveActionsOwnProps;

const SaveActions = ({
  canDraft,
  canArchive,
  onSubmit,
  addOverlay,
  removeOverlay,
}: SaveActionsProps) => {
  const [stateIsVisible, setStateIsVisible] = useState(false);
  const publicationStatus = useRecoilValue(submissionPublicationStatus);
  const isEdit = useRecoilValue(submissionIsEdit);
  const isPending = useRecoilValue(submissionIsPending);
  const isValid = useRecoilValue(submissionIsValid);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const { t } = useTranslation();

  const handleTriggerClick = () => {
    setStateIsVisible(!stateIsVisible);
  };

  const handleSubmit = (saveAs: ContentSubmissionTypes) => {
    setStateIsVisible(false);

    if (isSubmitting) return;

    onSubmit(saveAs);
  };

  const handleArchiveClick = () => {
    addOverlay(ArchiveConfirmDialogID, {
      onContinue: () => {
        removeOverlay({ id: ArchiveConfirmDialogID, key: undefined });
        handleSubmit('archive');
      },
      onCancel: () => {
        removeOverlay({ id: ArchiveConfirmDialogID, key: undefined });
      },
    });
  };

  const ref = useOutsideClick({
    disabled: !stateIsVisible,
    onClick: handleTriggerClick,
  });

  const publishButtonText =
    publicationStatus === 'published'
      ? t('content_submission.update')
      : t('content_submission.publish');

  canArchive = canArchive && !isPending;

  const popoverId = 'content-submission-publish-popover';

  return (
    <div className="content-submission-publish" ref={ref}>
      {canDraft || canArchive ? (
        <Button
          className="content-submission-publish__state-trigger"
          iconComponent={<SettingsIcon />}
          onClick={handleTriggerClick}
          aria-label={t('common.settings')}
          aria-haspopup="menu"
          aria-controls={popoverId}
          aria-expanded={stateIsVisible}
        />
      ) : null}

      {stateIsVisible ? (
        <Popover id={popoverId}>
          {canArchive ? (
            <Button
              icon="archive"
              disabled={!isEdit || !isValid}
              onClick={handleArchiveClick}
              role="menuitem"
              aria-label={t('content_submission.archive')}
            >
              {t('content_submission.archive')}
            </Button>
          ) : null}

          {canDraft ? (
            <Button
              icon="drafts"
              disabled={!isValid}
              onClick={() => handleSubmit(publicationStateMap.draft)}
              role="menuitem"
              aria-label={t('content_submission.save_as_draft')}
            >
              {t('content_submission.save_as_draft')}
            </Button>
          ) : null}
        </Popover>
      ) : null}

      <Button
        type="submit"
        className="content-submission-publish__submit"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        onClick={() => handleSubmit('publish')}
        aria-label={publishButtonText}
      >
        {publishButtonText}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  canDraft: advocateSelectors.getCanDraftSubmission(state),
  canArchive: advocateSelectors.getCanArchiveSubmission(state),
});

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveActions);
