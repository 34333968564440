import * as moment from 'moment';
const ALIASES = {
  no: 'nb',
  zh: 'zh-cn',
};

export function loadMomentLocale(language) {
  //English is built-in
  if (language === 'en') {
    return;
  }
  let localeName = language.toLocaleLowerCase();
  if (ALIASES[localeName]) {
    localeName = ALIASES[localeName];
  }
  //Webpack will compile all files in moment/locale/ to separate chunks
  import(`moment/locale/${localeName}`)
    .then((result) => {
      moment.locale(localeName);
    })
    .catch(() => {
      // ignore unresolved locale
    });
}

export default loadMomentLocale;
