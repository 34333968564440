import { Avatar } from '@socialchorus/shared-ui-components';
import React from 'react';
import ShortListContainer from './short-list-container';
import styles from './people-short-list.module.scss';
import { PeopleSearchResponse } from '../people/people';
import { SearchType } from '../search-screen';
import { AssistantLink } from '../../../assistant/assistant-link';
import { trackSearchPreviewResultClick } from '../../../../models/analytics';

interface PeopleShortListProps {
  peopleData: PeopleSearchResponse[];
  error: boolean;
  viewAllUrl: string;
  searchType: SearchType;
}

const PeopleShortList: React.FC<PeopleShortListProps> = ({
  error,
  viewAllUrl,
  peopleData,
  searchType,
}) => {
  const peopleShortList =
    peopleData[0]?.data?.length > 0 ? peopleData[0]?.data?.slice(0, 6) : [];
  return (
    <ShortListContainer
      error={error}
      viewAllUrl={viewAllUrl}
      dataType={searchType}
      dataCount={peopleShortList.length}
    >
      {peopleShortList.map((person) => (
        <AssistantLink
          key={person.attributes['user-id']}
          variant="nav"
          to={`/profiles/${person.attributes['user-id']}`}
          onClick={() =>
            trackSearchPreviewResultClick({ result_type: searchType })
          }
          className={styles.PeopleCard}
        >
          <article aria-labelledby={`person-preview-${person.id}`}>
            <Avatar
              size="medium"
              shape="circle"
              imgSrc={person.attributes['avatar-url'] || undefined}
            />
            <div className={styles.Text}>
              <h2 id={`person-preview-${person.id}`} className={styles.Name}>
                {person.attributes.name}
              </h2>
              <p className={styles.Title}>{person.attributes['job-title']}</p>
            </div>
          </article>
        </AssistantLink>
      ))}
    </ShortListContainer>
  );
};

export default PeopleShortList;
