import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Button, Link } from '../../components/ui';
import twig from '../../config/twig';
import { getDeepLinkRedirect } from '../../lib/mobile-deeplinks/es';
import type { NativePlatform, Program } from '../../models/program/types';
import { useNativeRedirectMessage } from './use-native-redirect-message';
import './native-redirect.scss';

const nativeRedirectLabelId = 'native-redirect';

type NativeRedirectButtonProps = {
  program: Program;
  path: string;
};
const AndroidButton = ({ program, path }: NativeRedirectButtonProps) => {
  const { t } = useTranslation();

  const programRoot = program.twig_redirect_url;

  const paramsArray = [['scheme', 'https']];
  const market_referrer = uuidv4();
  const market_deeplink = program.is_custom_domain
    ? `https://${programRoot}/${path}?program=${program.id}`
    : `https://${programRoot}/sc4?program=${program.id}#${path}`;

  const clickHandler = () => {
    twig.put('/deeplink/' + market_referrer, null, {
      params: { deeplink: market_deeplink },
    });
  };

  if (
    program.mobile_package_info &&
    program.mobile_package_info.android_app &&
    program.mobile_package_info.android_app.package
  ) {
    paramsArray.push([
      'package',
      program.mobile_package_info.android_app.package,
    ]);
    paramsArray.push([
      'S.market_referrer',
      encodeURIComponent(market_deeplink),
    ]);
  }

  const params = paramsArray
    .map((p) => p.join('='))
    .filter((p) => p)
    .join(';');

  const url = program.is_custom_domain
    ? `intent://${programRoot}/${path}?program=${program.id}&app_store_redirect=android#Intent;${params};end`
    : `intent://${programRoot}/sc4?program=${program.id}&app_store_redirect=android#${path}#Intent;${params};end`;

  return (
    <>
      <Link
        style={{ backgroundColor: program.theme.theme_color }}
        className="button button--program"
        href={url}
        onClick={clickHandler}
      >
        <span>{t('screens.native_redirect.cta.open_in_android_app')}</span>
      </Link>
    </>
  );
};

const IosButton = ({ program, path }: NativeRedirectButtonProps) => {
  const { t } = useTranslation();

  const url = `${program.root_url}/sc4#${path}`;
  const ios_app_store_id = program.mobile_package_info?.ios_app?.package ?? '';

  return (
    <>
      <form
        action={url}
        target="_blank"
        method="get"
        aria-labelledby={nativeRedirectLabelId}
      >
        <input type="hidden" name="program" value={program.id} />
        <input type="hidden" name="app_store_redirect" value="ios" />
        <input type="hidden" name="app_store_id" value={ios_app_store_id} />
        <input
          style={{ backgroundColor: program.theme.theme_color }}
          type="submit"
          className="button button--program"
          value={t('screens.native_redirect.cta.open_in_ios_app')}
        />
      </form>
    </>
  );
};

type NativeRedirectProps = {
  platform: NativePlatform;
  program: Program;
};

const NativeRedirect = ({ platform, program }: NativeRedirectProps) => {
  const { heading, body, hideButtons } = useNativeRedirectMessage(program.id);

  const loc = useLocation();
  const deepLinkPath = getDeepLinkRedirect(loc.pathname) || loc.pathname;

  const handleWebOpen = () => {
    sessionStorage.setItem('useMobileWeb', 'true');
    window.location.reload();
  };

  const { t } = useTranslation();
  return (
    <div className="native-redirect">
      <div className="native-redirect__image">
        <img
          className="logo-image"
          src={program.logo_image_url ?? undefined}
          alt={program.brand_name + ' logo'}
        />
      </div>

      <div className="native-redirect__welcome" id={nativeRedirectLabelId}>
        <h2>{heading}</h2>
        <p>{body}</p>
      </div>

      {hideButtons ? null : (
        <div className="button-group">
          {(() => {
            switch (platform) {
              case 'ios':
                return <IosButton program={program} path={deepLinkPath} />;
              case 'android':
                return <AndroidButton program={program} path={deepLinkPath} />;
            }
          })()}
          <Button theme="info" onClick={handleWebOpen}>
            {t('messages.open_browser')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NativeRedirect;
