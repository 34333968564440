import React, { useCallback, useEffect, useState } from 'react';
import { track } from '../../../../models/analytics/helpers';
import { channelOperations } from '../../../../models/channels/index';
import RelatedContent from './components/related-content';
import ChannelHeader from './components/channel-header';
import MoreLikeThisHeader from './components/more-like-this-header';

import RelatedContentLayout from './components/related-content-layout';
import ViewTracker from './components/view-tracker';
import { Item, ItemResponse, RelatedChannel } from './models';
import ContentService from '../../../../services/content';
import { useDispatch } from 'react-redux';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { programSelectors } from '../../../../models/program/index';

const parseContent = (item: ItemResponse) => ({
  id: item.attributes.id,
  content_type: item.attributes.content_type,
  url: item.attributes.url,
  title: item.attributes.title,
  image: item.attributes.background_image_url,
  summary: item.attributes.description,
  source_name: item.attributes.source_name,
  published_at: item.attributes.published_at,
});

type Props = {
  content_id: number;
  onReady: () => void;
};

type State = Props & {
  status: string;
  event_prefix: string;
  abort?: boolean;
  channel?: RelatedChannel;
  more_like_this_items?: Item[];
  related_items?: Item[];
};

export const RelatedContentsWidget = (props: Props) => {
  const [state, setState] = useState<State>({
    ...props,
    status: 'init',
    event_prefix: 'w', //always 'w' for patron. this came from advo where it seems mobile could use it. probably cant sunset the original till we provide a mobile version
  });

  const { onReady } = props;

  const dispatch = useDispatch();
  const programId = usePatronSelector((state) =>
    programSelectors.getProgramId(state)
  );

  const getContentLink = (item: Item) => {
    return `/contents/${item.id}`;
  };

  const getChannelLink = () => {
    return `/channels/${state.channel?.id}`;
  };

  const onOpenContent = (item: Item, track_opts = {}) => {
    const action = state.event_prefix === 'w' ? 'click' : 'tap';
    trackEvent(`ContentCard:${action}`, {
      ...track_opts,
      content_id: item.id,
    });
  };

  const onOpenChannel = (id: number) => {
    trackEvent('ChannelCard:click', {
      content_channel_id: id,
    });
  };

  const viewChannelCard = (id: number, track_opts = {}) => {
    trackEvent('ChannelCard:view', {
      ...track_opts,
      content_channel_id: id,
    });
  };

  const viewContentCard = (id: number) => {
    trackEvent('ContentCard:view', {
      content_id: id,
    });
  };

  const gotMoreLikeThis = useCallback(
    (more_like_this) => {
      if (!state.abort) {
        setState((s) => ({
          ...s,
          more_like_this_items: more_like_this.data.map(parseContent),
        }));
      }
    },
    [state.abort]
  );

  const gotRelated = useCallback(
    (related) => {
      if (!state.abort) {
        setState((s) => ({
          ...s,
          status: 'ready',
          channel: {
            ...related.included[0].attributes,
            was_following: related.included[0].attributes.following,
          },
          related_items: related.data.map(parseContent),
        }));
      }
    },
    [state.abort]
  );

  useEffect(() => {
    if (state.status === 'ready') {
      onReady();
    }
  }, [state.status, onReady]);

  const loadPage = useCallback(
    async (options = { status: 'loading' }) => {
      const contentService = new ContentService(state.content_id);
      setState((s) => ({ ...s, status: options.status }));

      await Promise.all([
        contentService
          .fetchMoreLikeThis({ program_id: programId })
          .then(gotMoreLikeThis),
        contentService.fetchRelated({ program_id: programId }).then(gotRelated),
      ]);
    },
    [state.content_id, programId, gotMoreLikeThis, gotRelated]
  );

  const trackEvent = useCallback(
    (event_name: string, props?: any) => {
      const properties = {
        location: 'related',
        ...props, // props can override location
        program_id: programId,
        related_content_id: state.content_id,
      };
      track(`${state.event_prefix}:${event_name}`, properties);
    },
    [state.event_prefix, state.content_id, programId]
  );

  const setChannelFollowing = useCallback((following: boolean) => {
    setState(
      (s) =>
        ({
          ...s,
          channel: {
            ...s.channel,
            following,
          },
        } as State)
    );
  }, []);

  const follow = useCallback(
    (id: number) => {
      trackEvent('ChannelCard:follow', {
        content_channel_id: id,
      });

      setChannelFollowing(true);

      channelOperations
        .followChannel(id)(dispatch)
        .catch(() => setChannelFollowing(false));
    },
    [dispatch, setChannelFollowing, trackEvent]
  );

  const unfollow = useCallback(
    (id: number) => {
      trackEvent('ChannelCard:unfollow', {
        content_channel_id: id,
      });

      setChannelFollowing(false);

      channelOperations
        .unfollowChannel(id)(dispatch)
        .catch(() => setChannelFollowing(true));
    },
    [dispatch, setChannelFollowing, trackEvent]
  );

  useEffect(() => {
    loadPage({ status: 'init' }).catch((e) => {
      console.error('errror in related content load', e);
    });

    trackEvent('ChannelCard:load');

    return () => {
      setState((s) => ({ ...s, abort: true }));
    };
  }, [loadPage, trackEvent]);

  return (
    <RelatedContentLayout>
      {state.status === 'ready' &&
        state.related_items &&
        state.channel &&
        state.related_items.length > 0 && (
          <div id="moreFrom">
            <ViewTracker
              onView={viewChannelCard}
              windowSelector="#moreFrom"
              trackableClassName="trackable-channel"
            >
              <ChannelHeader
                trackableClassName="trackable-channel"
                key={state.channel.id}
                item={state.channel}
                follow={follow}
                unfollow={unfollow}
                channelLink={getChannelLink()}
                onOpenChannel={onOpenChannel}
              />
            </ViewTracker>
            <ViewTracker
              onView={viewContentCard}
              windowSelector="#moreFrom"
              trackableClassName="trackable-content"
            >
              {state.related_items.map((item: Item) => (
                <RelatedContent
                  key={item.id}
                  item={item}
                  trackableClassName="trackable-content"
                  href={getContentLink(item)}
                />
              ))}
            </ViewTracker>
          </div>
        )}

      {state.status === 'ready' &&
        state.more_like_this_items &&
        state.more_like_this_items.length > 0 && (
          <div id="moreLikeThis">
            <MoreLikeThisHeader />

            <ViewTracker
              onView={(id) => viewContentCard(id)}
              windowSelector="#moreLikeThis"
              trackableClassName="trackable-content"
            >
              {state.more_like_this_items.map((item: Item) => (
                <RelatedContent
                  key={item.id}
                  item={item}
                  trackableClassName="trackable-content"
                  href={getContentLink(item)}
                  onClick={() =>
                    onOpenContent(item, { location: 'more_like_this' })
                  }
                />
              ))}
            </ViewTracker>
          </div>
        )}
    </RelatedContentLayout>
  );
};
