import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import store from '../config/store';
import { assistantOperations } from '../models/assistant';
import { trackAssistantCommandRun } from '../models/assistant/analytics';
import useAssistantActionHandlers from './use-assistant-action-handlers';

const useCommandRunner = () => {
  const { handleAction } = useAssistantActionHandlers();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = store.getState();

  const advocateAvatar = state.advocate.avatar;
  const programThemeIconUrl = state.program.theme.icon_image_url;

  const addHistoryItem = (item) =>
    dispatch(assistantOperations.addHistoryItem(item));
  const updateHistoryItem = (item) =>
    dispatch(assistantOperations.updateHistoryItem(item));

  const SELF_AUTHOR = 'self';
  const SYSTEM_AUTHOR = 'system';

  const runCommand = (command, analyticsData) => {
    trackAssistantCommandRun(analyticsData);

    // Add the user's request to the history, with self as author. Hold a reference, so it can be updated later if the
    // integration in Pythia has a LabelGetter label generator
    const requestId = uuidv4();
    let requestItem = null;
    if (command.button_text) {
      requestItem = selfResponse({ text: command.button_text }, requestId);
      addHistoryItem(requestItem);
    }

    const actionResponse = handleAction(command.action);

    if (actionResponse) {
      // This command will eventually return a response,
      // so add the response to the history with a loading indicator
      const responseId = uuidv4();

      addHistoryItem({
        id: responseId,
        author: SYSTEM_AUTHOR,
        service_name: command.attribution,
        avatar: {
          url: command.button_image_url || programThemeIconUrl,
        },
        loading: true,
      });

      actionResponse
        .then((res) => res.data)
        .then((data) => {
          // The command response was successful, update the history item to display the result
          const item = data.data[0];
          if (item) {
            const responseAnalytics = {
              service_name: command.attribution,
              command: command.button_text,
            };
            if (requestItem && data.metadata && data.metadata.button_text) {
              // An updated label was provided in the response, update the user request text
              updateHistoryItem(
                selfResponse({ text: data.metadata.button_text }, requestId)
              );
            }
            const successResponse = systemResponse(
              item,
              responseId,
              responseAnalytics
            );
            updateHistoryItem(successResponse);
          }
        })
        .catch((err) => {
          console.error('command runner error: ', err);

          // The command response was not successful,
          // update the history item to display an error
          const message = err.response.data?.errors?.[0]?.title;
          const failureResponse = systemResponse(
            { text: message || t('errors.unknown') },
            responseId
          );
          updateHistoryItem(failureResponse);
        });
    }
  };

  const selfResponse = (item, id) => ({
    ...item,
    author: SELF_AUTHOR,
    avatar: advocateAvatar,
    id: id || uuidv4(),
  });

  const systemResponse = (item, id, analyticsData) => ({
    ...item,
    author: SYSTEM_AUTHOR,
    avatar: {
      url: programThemeIconUrl,
      ...item.avatar,
    },
    id: id || uuidv4(),
    analyticsData,
  });

  return { runCommand };
};

export default useCommandRunner;
