import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { contentSelectors } from '../../../models/content/index.js';

import { trackContentCardCopyLink } from '../../../models/content/analytics';

import './content-deeplink.scss';

const ContentDeeplink = ({ contentId, permalinkUrl }) => {
  const [isCopied, setIsCopied] = useState();

  const inputEl = useRef();

  const { t } = useTranslation();

  const handleCopyClick = () => {
    copyUrl();

    setIsCopied(true);

    trackContentCardCopyLink(contentId);
  };

  const copyUrl = () => {
    const input = inputEl.current;

    input.select();
    document.execCommand('copy');

    const selection = document.getSelection();

    if (selection.removeAllRanges) {
      selection.removeAllRanges();
    } else if (selection.empty) {
      selection.empty();
    }

    input.blur();
  };

  return (
    <div className="content-deeplink">
      <input
        id="content-deeplink__input"
        type="text"
        value={permalinkUrl}
        readOnly
        className="content-deeplink__input"
        ref={inputEl}
      />

      <button
        className="content-deeplink__button pt-secondary-color"
        onClick={handleCopyClick}
      >
        {!isCopied ? t('common.copy') : t('common.copied')}
      </button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  permalinkUrl: contentSelectors.getContentPermalinkUrl(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentDeeplink);
