import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../models/ui';
import { siteHeaderShouldBeInModalOverlay } from '../models/ui/recoil-state';
import { useRecoilValue } from 'recoil';

const useHeaderMinimizer = (minimize: boolean) => {
  const dispatch = useDispatch();

  const headerInOverlayEnabled = useRecoilValue(
    siteHeaderShouldBeInModalOverlay
  );

  const _minimize = headerInOverlayEnabled ? false : minimize;

  useEffect(() => {
    _minimize
      ? dispatch(uiOperations.minimizeHeader())
      : dispatch(uiOperations.maximizeHeader());

    return () => {
      dispatch(uiOperations.maximizeHeader());
    };
  }, [dispatch, _minimize]);
};

export default useHeaderMinimizer;
