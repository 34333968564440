import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { AssistantResponseChannel } from '../../components/assistant/assistant-responses';
import { AssistantBackBar } from '../../components/assistant';

function AssistantContents({ location }: RouteComponentProps) {
  const params = queryString.parse(location.search, { arrayFormat: 'comma' });

  const ids = params.ids as string[];

  return (
    <div className="assistant__filtered-list">
      <AssistantBackBar />

      <div className="assistant__panel">
        {ids && ids.length
          ? ids.map((id) => (
              <AssistantResponseChannel
                analyticsData={{ location: 'assistant_channels' }}
                response={{ id }}
                key={id}
              />
            ))
          : null}
      </div>
    </div>
  );
}

export default AssistantContents;
