import {
  ButtonHTMLAttributes,
  ComponentProps,
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';
import cx from 'classnames';
import Icon from './icon';
import { ForwardActions } from '../../config/history';
import './ui.scss';
import { useHistory } from 'react-router-dom';

type Theme =
  | 'tag'
  | 'icon-only'
  | 'icon-only-dark'
  | 'primary'
  | 'secondary'
  | 'text'
  | 'warning'
  | 'default'
  | 'primary-legacy'
  | 'info'
  | 'success'
  | 'danger'
  | 'link'
  | 'link-color'
  | 'action';
type Shape = 'round' | 'pill';
type Size = 'small' | 'compact' | '24' | '32' | '40' | 'large' | 'block';

type Props = HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> & {
  theme?: Theme;
  transparent?: boolean;
  shape?: Shape;
  size?: Size;
  upcase?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: ComponentProps<typeof Icon>['type'];
  iconComponent?: ReactElement;
  name?: string;
  href?: string;
  action?: ForwardActions;
  title?: string;
  tabIndex?: number;
  style?: CSSProperties;
  className?: string;
  onKeyPress?: KeyboardEventHandler;
  onClick?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  target?: string;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
};

//this is an old component, not all of the styling props work well together.
const Button = forwardRef<any, PropsWithChildren<Props>>(
  (
    {
      theme,
      transparent,
      shape,
      size,
      upcase,
      loading,
      disabled,
      icon,
      iconComponent,
      name,
      href,
      action,
      title,
      tabIndex,
      style,
      className,
      onKeyPress,
      onClick,
      onMouseDown,
      type,
      children,
      target,
      onMouseEnter,
      onMouseLeave,
      ...props
    },
    ref
  ) => {
    const history = useHistory();

    className = cx(className, 'button', {
      //new ui buttons
      'button--tag': theme === 'tag',
      'button--icon-only': theme === 'icon-only',
      'button--icon-only-dark': theme === 'icon-only-dark',
      'button--v2-primary': theme === 'primary',
      'button--v2-secondary': theme === 'secondary',
      'button--v2-text': theme === 'text',
      'button--v2-warning': theme === 'warning',

      'button--disabled': disabled,

      //pill 'share' option is legacy
      'button--pill': shape === 'pill' || theme === 'tag',
      //size 'small' option is legacy
      'button--small': size && ['small', 'compact'].includes(size),
      'button--icon--only-24': size === '24',
      'button--icon--only-32': size === '32',
      'button--icon--only-40': size === '40',

      //legacy ui only
      'button--default': theme === 'default',
      'button--primary': theme === 'primary-legacy',
      'button--info': theme === 'info',
      'button--success': theme === 'success',
      'button--danger': theme === 'danger',
      'button--link': theme === 'link',
      'button--link-color': theme === 'link-color',
      'button--action': theme === 'action',
      'button--transparent': transparent,
      'button--upcase': upcase,
      'button--round': shape === 'round',
      'button--large': size === 'large',
      'button--block': size === 'block',
      'button--loading': loading,
    });

    const Component = !href ? 'button' : 'a';

    const prefixedHref = action ? history.createHref({ pathname: href }) : href;

    const handleClick: MouseEventHandler = (e) => {
      if (onClick) onClick(e);

      if (!action) return;

      if (!e.defaultPrevented && href) {
        e.preventDefault();

        history[action](href);
      }
    };

    return (
      <Component
        ref={ref}
        disabled={disabled}
        name={name}
        href={prefixedHref}
        title={title}
        tabIndex={tabIndex}
        style={style}
        className={className}
        onKeyPress={!disabled ? onKeyPress : undefined}
        onClick={!disabled ? handleClick : undefined}
        onMouseDown={!disabled ? onMouseDown : undefined}
        type={!href ? type : undefined}
        target={href ? target : undefined}
        aria-disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...props}
      >
        <span>
          {' '}
          {/* Thanks to shit Safari 10 support for flexbox, inner wrapper must be used */}
          {icon && <Icon type={icon} />}
          {iconComponent && iconComponent}
          {children}
        </span>
      </Component>
    );
  }
);

Button.defaultProps = {
  type: 'button',
};
Button.displayName = 'UI.Button';

export default Button;
