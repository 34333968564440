import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  programMembershipSelectors,
  programMembershipOperations,
} from '../../models/program-membership';
import { Dialog, Checkbox } from '../ui';
import AdvocateService from '../../services/advocate';

const PrivateProfileDialog = ({
  advocateId,
  continue: accept,
  close: dismiss,
  hidePrivateProfileWarning,
}) => {
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();

  const updateProfileWarning = () => {
    hidePrivateProfileWarning();

    new AdvocateService(advocateId).update({
      hide_private_profile_warning: ignoreWarning,
    });
  };

  const handleIgnoreChange = (event) => {
    const target = event.target;

    setIgnoreWarning(target.checked);
  };

  const handleAccept = () => {
    setIsSaving(true);

    if (ignoreWarning) updateProfileWarning();

    dismiss();
    accept();
  };

  const handleClose = () => {
    dismiss();
  };

  return (
    <Dialog
      title={t('comments.private_dialog.title')}
      accept={{
        text: t('comments.private_dialog.post'),
        loading: isSaving,
        disabled: isSaving,
        onClick: handleAccept,
      }}
      cancel={{
        text: t('comments.private_dialog.cancel'),
        disabled: isSaving,
        onClick: handleClose,
      }}
      close={handleClose}
      className={'private-profile-dialog'}
    >
      <div className="private-profile-dialog__content">
        <p>{t('comments.private_dialog.description')}</p>

        <Checkbox
          label={t('comments.private_dialog.hide_warn')}
          name="private_profile"
          checked={ignoreWarning}
          onChange={handleIgnoreChange}
        />
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  programMembership: programMembershipSelectors.getProgramMembership(
    state,
    ownProps
  ),
  ignoredPrivateProfileWarning:
    programMembershipSelectors.getProgramMembershipHidePrivateWarning(
      state,
      ownProps
    ),
});

const mapDispatchToProps = {
  hidePrivateProfileWarning:
    programMembershipOperations.hidePrivateProfileWarning,
};

export const ID = 'Overlays.PRIVATE_PROFILE_DIALOG';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateProfileDialog);
