import { connect } from 'react-redux';

import { uiOperations } from '../models/ui';

import FlashMessage from './flash-message';

const MessageController = ({ message, removeMessage }) =>
  message ? (
    <FlashMessage
      text={message.text}
      {...{ [message.type || 'warning']: true }}
      close={removeMessage}
      key={message.timestamp}
    />
  ) : null;

const mapStateToProps = (state) => ({
  message: state.ui.flashMessages[0],
});

const mapDispatchToProps = {
  removeMessage: uiOperations.removeFlashMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageController);
