import React from 'react';
import { connect } from 'react-redux';

import { advocateSelectors } from '../../../models/advocate';

import { HoverableAvatar, Link } from '../../../components/ui';

import { trackContentCardLikesProfileClick } from '../../../models/content/analytics';

const getAdvocateData = (like) => ({
  id: parseInt(like.user_id, 10),
  full_name: like.name,
  display_name: like.display_name ? `@${like.display_name}` : null,
  job_title: like.job_title,
  avatar: like.avatar,
});

const ContentInfoLike = ({ like, advocateId, isInList }) => {
  const advocate = getAdvocateData(like);

  const profilePath =
    advocate.id !== advocateId
      ? `/profiles/${advocate.id}`
      : '/account/profile';

  const handleClick = () => {
    trackContentCardLikesProfileClick();
  };

  return (
    <li
      className="content-info__like"
      style={isInList ? { width: '23px' } : undefined}
    >
      <Link onClick={handleClick} href={profilePath} action="push">
        <HoverableAvatar
          className="content-info__like__avatar"
          advocate={advocate}
          isInList={isInList}
        />
      </Link>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  advocateId: advocateSelectors.getAdvocateId(state, ownProps),
});

export default connect(mapStateToProps, null)(ContentInfoLike);
