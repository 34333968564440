import store from '../../config/store';
import types from './types';
import selectors from './selectors';

import { trackable } from '../analytics/helpers';

import {
  SUBMIT_ADD_LINK_ADD_LINK_ERROR,
  SUBMIT_LOAD_IMAGE_PREVIEW_SUCCESS,
  SUBMIT_LOAD_LINK_PREVIEW_SUCCESS,
  SUBMIT_LOAD_VIDEO_PREVIEW_SUCCESS,
  SUBMIT_SAVE_CLICK,
  SUBMIT_SUBMIT_CLICK,
  SUBMIT_SUBMIT_ERROR,
  SUBMIT_SUBMIT_SUCCESS,
} from './analytics/keys';

const reset = () => ({
  type: types.RESET,
});

const resetWithChannels = () => ({
  type: types.RESET_WITH_CHANNELS,
});

const setId = (id) => ({
  type: types.SET_ID,
  id,
});

const setAuthorId = (authorId) => ({
  type: types.SET_AUTHOR_ID,
  authorId,
});

const setContentType = (contentType) => ({
  type: types.SET_CONTENT_TYPE,
  contentType,
});

const setChannelIds = (channelIds) => ({
  type: types.SET_CHANNEL_IDS,
  channelIds,
});

const setUrl = (url) => ({
  type: types.SET_URL,
  url,
});

const setTitle = (title) => ({
  type: types.SET_TITLE,
  title,
});

const setDescription = (description) => ({
  type: types.SET_DESCRIPTION,
  description,
});

const setMedia = (media) => {
  const contentType = selectors.getContentType(store.getState());

  return trackable(
    {
      type: types.SET_MEDIA,
      media,
    },
    contentType === 'video'
      ? SUBMIT_LOAD_VIDEO_PREVIEW_SUCCESS
      : SUBMIT_LOAD_IMAGE_PREVIEW_SUCCESS
  );
};

const setMediaUrl = (url) => {
  url = url.split(/[?#]/)[0];
  return {
    type: types.SET_MEDIA_URL,
    url,
  };
};

const setNoteColor = (noteColor) => ({
  type: types.SET_NOTE_COLOR,
  noteColor,
});

const setImageUrl = (imageUrl) => ({
  type: types.SET_IMAGE_URL,
  imageUrl,
});

const setArticle = (article) => ({
  type: types.SET_ARTICLE,
  article,
});

const setSubmitProgress = (progress) => ({
  type: types.SET_SUBMIT_PROGRESS,
  progress,
});

const setPublicationState = (publicationState) => ({
  type: types.SET_PUBLICATION_STATE,
  publicationState,
});

const fetchLinkPreviewBegin = () => ({
  type: types.FETCH_LINK_PREVIEW_BEGIN,
});

const fetchLinkPreviewFailure = () =>
  trackable(
    {
      type: types.FETCH_LINK_PREVIEW_FAILURE,
    },
    SUBMIT_ADD_LINK_ADD_LINK_ERROR
  );

const fetchLinkPreviewSuccess = ({
  requested_url,
  title,
  description,
  cropped_image_url,
}) =>
  trackable(
    {
      type: types.FETCH_LINK_PREVIEW_SUCCESS,
      url: requested_url,
      title,
      description,
      imageUrl: cropped_image_url,
    },
    requested_url ? SUBMIT_LOAD_LINK_PREVIEW_SUCCESS : null
  );

const fetchContentSubmissionBegin = () => ({
  type: types.FETCH_CONTENT_SUBMISSION_BEGIN,
});

const fetchContentSubmissionSuccess = () => ({
  type: types.FETCH_CONTENT_SUBMISSION_SUCCESS,
});

const fetchContentSubmissionFailure = (error) => ({
  type: types.FETCH_CONTENT_SUBMISSION_FAILURE,
});

const submitContentBegin = ({ isEdit, contentType }) =>
  trackable(
    {
      type: types.SUBMIT_CONTENT_BEGIN,
    },
    isEdit ? SUBMIT_SAVE_CLICK : SUBMIT_SUBMIT_CLICK,
    {
      content_type: contentType,
    }
  );

const submitContentSuccess = () =>
  trackable(
    {
      type: types.SUBMIT_CONTENT_SUCCESS,
    },
    SUBMIT_SUBMIT_SUCCESS
  );

const submitContentFailure = () =>
  trackable(
    {
      type: types.SUBMIT_CONTENT_FAILURE,
    },
    SUBMIT_SUBMIT_ERROR
  );

export default {
  reset,
  resetWithChannels,
  setId,
  setAuthorId,
  setContentType,
  setChannelIds,
  setUrl,
  setTitle,
  setDescription,
  setMedia,
  setMediaUrl,
  setNoteColor,
  setArticle,
  setImageUrl,
  setSubmitProgress,
  setPublicationState,
  fetchLinkPreviewBegin,
  fetchLinkPreviewSuccess,
  fetchLinkPreviewFailure,
  fetchContentSubmissionBegin,
  fetchContentSubmissionSuccess,
  fetchContentSubmissionFailure,
  submitContentBegin,
  submitContentSuccess,
  submitContentFailure,
};
