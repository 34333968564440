import { FeedCard as SharedFeedCard } from '@socialchorus/shared-ui-components';
import { JSXElementConstructor, ReactElement, useMemo } from 'react';
import { Content } from '../../../../../../models/content/types';
import { FeedCardActionsContainerProps, FeedCardActionsProps } from './type';
import { FeedCardActionLike } from './action-like';
import { FeedCardActionComment } from './action-comment';
import { FeedCardActionBookmark } from './action-bookmark';
import { FeedCardActionShare } from './action-share';
import { FeedCardActionEdit } from './action-edit';

export function FeedCardActions({
  content,
  analyticsData,
  maxVisibleActions = 3,
}: FeedCardActionsContainerProps) {
  const [actions, hiddenActions] = useMemo(() => {
    const actions: ReactElement[] = [];
    const hiddenActions: ReactElement[] = [];

    Object.entries(ContentCardToolbarActions).forEach(
      ([key, { canShow, element }], index, array) => {
        const ActionComponent =
          element as JSXElementConstructor<FeedCardActionsProps>;
        const comp = (isHidden: boolean) => (
          <ActionComponent
            key={key}
            content={content}
            analyticsData={analyticsData}
            isHidden={isHidden}
          />
        );

        if (canShow(content)) {
          // "more" button is counted as a visible action
          // also, if there's only 1 hidden action, then replace the "more" button with the action itself
          if (
            actions.length >= maxVisibleActions &&
            !(array.length === index + 1 && hiddenActions.length === 0)
          ) {
            hiddenActions.push(comp(true));
          } else {
            actions.push(comp(false));
          }
        }
      }
    );

    return [actions, hiddenActions];
  }, [analyticsData, content, maxVisibleActions]);

  return (
    <SharedFeedCard.Actions>
      <>
        {actions}
        {hiddenActions.length > 0 ? (
          <SharedFeedCard.HiddenActions>
            {hiddenActions}
          </SharedFeedCard.HiddenActions>
        ) : null}
      </>
    </SharedFeedCard.Actions>
  );
}

const ContentCardToolbarActions: {
  [key: string]: {
    canShow: (content: Content) => boolean;
    element: JSXElementConstructor<FeedCardActionsProps>;
  };
} = {
  like: {
    canShow: (content) => content.publication_state === 'published',
    element: FeedCardActionLike,
  },
  comment: {
    canShow: (content) =>
      content.publication_state === 'published' && content.is_commentable,
    element: FeedCardActionComment,
  },
  bookmark: {
    canShow: (content) => content.publication_state === 'published',
    element: FeedCardActionBookmark,
  },
  share: {
    canShow: (content) => content.can_share,
    element: FeedCardActionShare,
  },
  edit: {
    canShow: (content) =>
      content.is_editable &&
      content.publication_state !== 'archived' &&
      !(content.processing_state && content.processing_state !== 'completed'),
    element: FeedCardActionEdit,
  },
};
