import MessageFrame from '../../../components/messages/message-frame';
import './message-detail.scss';

const MessageDetail = ({ messageId }) => {
  const className = 'message-detail message-detail--article';

  return (
    <article className={className}>
      <MessageFrame messageId={messageId} />
    </article>
  );
};

export default MessageDetail;
