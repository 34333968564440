import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cx from 'classnames';

import i18n from '../../config/i18n';
import {
  contentOperations,
  contentSelectors,
} from '../../models/content/index.js';

import { Button } from '../ui';

import ContentService from '../../services/content';

import { trackContentCardTranslate } from '../../models/content/analytics';

import './content.scss';
import { usePushToastOrFlashMessage } from '../v2/toaster/deprecation-helper';

const ContentActionTranslate = ({
  contentId,
  noText,
  updateContent,
  analyticsData,
  isTranslated,
}) => {
  const { t } = useTranslation();
  const { pushMessage } = usePushToastOrFlashMessage();
  const className = cx('content__action content__action--translate', {
    'content__action--translated': isTranslated,
  });

  const handleClick = async () => {
    const translate = !isTranslated;
    try {
      const res = await new ContentService(contentId).fetch({ translate });
      const content = {
        ...res.data.feed[0].attributes,
        ...(translate ? { display_language: i18n.language } : {}),
      };
      updateContent(content);
    } catch (err) {
      if (err.response) {
        pushMessage({
          text: 'There was an error translating content',
          type: 'error',
        });
      } else {
        throw err;
      }
    }

    trackContentCardTranslate(contentId, analyticsData);
  };

  return (
    <div className={className}>
      <Button
        icon="translate"
        onClick={handleClick}
        aria-label={t('content.translate')}
        title={noText ? t('content.translate') : null}
        tabIndex="0"
      >
        {noText ? null : <span className="text">{t('content.translate')}</span>}
      </Button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isTranslated: contentSelectors.getContentIsTranslated(state, ownProps),
});

const mapDispatchToProps = {
  updateContent: contentOperations.addContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentActionTranslate);
