import types from './types';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...action.programMembership,
      };

    case types.UPDATE_STATUS:
      return {
        ...state,
        status: action.status,
      };

    case types.HIDE_PRIVATE_WARNING:
      return {
        ...state,
        hide_private_profile_warning: true,
      };

    default:
      return state;
  }
};

export default reducer;
