import types from './types';

const setCommands = (commands) => ({
  type: types.SET_COMMANDS,
  commands,
});

const setIntegrations = (integrations) => ({
  type: types.SET_INTEGRATIONS,
  integrations,
});

const addHistoryItem = (historyItem) => ({
  type: types.ADD_HISTORY_ITEM,
  historyItem,
});

const updateHistoryItem = (historyItem) => ({
  type: types.UPDATE_HISTORY_ITEM,
  historyItem,
});

const setQuickActions = (quickActions) => ({
  type: types.SET_ITEM,
  item: quickActions,
  itemName: 'quickActions',
});

const setInbox = (inbox) => ({
  type: types.SET_ITEM,
  item: inbox,
  itemName: 'inbox',
});

const setBootstrap = (bootstrap) => ({
  type: types.SET_ITEM,
  item: bootstrap,
  itemName: 'bootstrap',
});

const setCommandText = (commandText) => ({
  type: types.SET_ITEM,
  item: commandText,
  itemName: 'commandText',
});

const setInboxCount = (count) => ({
  type: types.SET_ITEM,
  itemName: 'inboxCount',
  item: count,
});

const dismissMessage = (messageId) => ({
  type: types.DISMISS_MESSAGE,
  messageId,
});

const markContentTracked = (itemId) => ({
  type: types.ITEM_VIEWED,
  itemId,
});

export default {
  setInbox,
  setBootstrap,
  setCommands,
  setQuickActions,
  setInboxCount,
  setIntegrations,
  addHistoryItem,
  updateHistoryItem,
  setCommandText,
  dismissMessage,
  markContentTracked,
};
