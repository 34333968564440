import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

import {
  AssistantCommandSelector,
  AssistantDialogue,
  AssistantBackBar,
} from '../../components/assistant';

import useCommandRunner from '../../common/use-command-runner';

import { trackAssistantDialogLoad } from '../../models/assistant/analytics';

function AssistantCommands({ location, history, match }: RouteComponentProps) {
  const [showGoBack, setShowGoBack] = useState(false);
  const { runCommand } = useCommandRunner();

  useEffect(() => {
    if (isObject(location.state)) {
      const command = get(location.state, 'command');

      setShowGoBack(Boolean(command || get(location.state, 'showBack')));

      if (command) {
        history.replace({
          pathname: location.pathname,
          state: { showBack: true },
        });
        runCommand(command, get(location.state, 'analyticsData'));
        setShowGoBack(true);
        trackAssistantDialogLoad({
          location: 'deeplink',
        });
      }
    } else if (!location.state) {
      trackAssistantDialogLoad();
    }
  }, [location.state, match.params]);

  return (
    <div className="assistant__commands assistant__search-view">
      {showGoBack ? <AssistantBackBar /> : null}
      <AssistantDialogue
        onSelect={(command) => {
          runCommand(command, { location: 'suggested_followup' });
        }}
      />
      <AssistantCommandSelector
        onSelect={(command) => {
          runCommand(command, {
            location: 'command_search',
            ...command.tracking_context,
          });
        }}
      />
    </div>
  );
}

export default AssistantCommands;
