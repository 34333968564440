import { useEffect, useRef, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import useContentFeed from '../../common/use-content-feed';
import useInview from '../../common/use-inview';
import {
  trackAssistantResourcesLoad,
  trackAssistantResourceView,
  trackAssistantResourceClick,
} from '../../models/assistant/analytics';

import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantBackBar } from '../../components/assistant';
import { Spinner } from '../../components/ui';
import {
  ContentImage,
  ContentTitle,
  ContentSummary,
} from '../../components/content';
import ViewTrigger from '../../components/view-trigger';
import EndOfFeed from '../../components/feed/end-of-feed';
import EmptyFeed from '../../components/feed/empty-feed';
import { programSelectors } from '../../models/program';
import AssistantContainer from './assistant-container';
import AssistantContent from './assistant-content';
import get from 'lodash/get';

type AssistantResourceProps = {
  contentId: number;
  position: number;
};

function AssistantResource({ contentId, position }: AssistantResourceProps) {
  const ref = useRef(null);

  const analyticsData = {
    content_id: contentId,
    position,
    location: 'assistant_resources',
  };

  const handleView = () => {
    trackAssistantResourceView(analyticsData);
  };

  const handleClick = () => {
    trackAssistantResourceClick(analyticsData);
  };

  useInview({
    ref,
    once: true,
    onInview: handleView,
  });

  return (
    <div className="assistant__resource" ref={ref}>
      <div className="assistant__resource__image">
        <ContentImage
          contentId={contentId}
          onClick={handleClick}
          analyticsData={analyticsData}
        />
      </div>

      <div className="assistant__resource__body">
        <ContentTitle
          contentId={contentId}
          onClick={handleClick}
          analyticsData={analyticsData}
        />
        <ContentSummary contentId={contentId} />
      </div>
    </div>
  );
}

type StateProps = {
  programThemeIconUrl: ReturnType<typeof programSelectors.getProgramThemeIcon>;
};
type OwnProps = RouteComponentProps;
type AssistantResourcesProps = StateProps & OwnProps;

function AssistantResources({
  location,
  programThemeIconUrl,
}: AssistantResourcesProps) {
  const { t } = useTranslation();
  const [showGoBack, setShowGoBack] = useState(false);

  useEffect(() => {
    trackAssistantResourcesLoad();

    const referer: string = get(location.state, 'referer', 'deeplink');
    setShowGoBack(referer === 'explorer');
  }, [location.state]);

  const { fetching, feedItems, page, canLoadMore, fetchFeed } = useContentFeed({
    filter: 'resources',
  });

  return (
    <AssistantContainer flex className="assistant__resources">
      {showGoBack ? <AssistantBackBar /> : null}
      <img
        className="assistant__resources-banner"
        src="https://lib.socialchorus.com/assistant/public/services/resources.png"
        alt=""
      />
      <AssistantContent>
        <div className="assistant__title-container">
          <img
            alt="Assistant App Logo"
            width="40"
            height="40"
            className="assistant__app-logo"
            src={programThemeIconUrl || undefined}
          />
          <div>
            <h2 className="assistant__title">{t('menu.resources')}</h2>
            <p className="assistant__description">
              {t('resources.description')}
            </p>
          </div>
        </div>

        <div className="assistant__resources-list">
          <h3 className="assistant__title">{t('resources.title')}</h3>

          {page
            ? feedItems.map((content, position) => (
                <AssistantResource
                  key={content.id}
                  contentId={content.id}
                  position={position}
                />
              ))
            : null}

          {fetching ? (
            <Spinner center={!feedItems.length} />
          ) : canLoadMore ? (
            <ViewTrigger onInview={() => fetchFeed()} />
          ) : feedItems.length ? (
            <EndOfFeed />
          ) : (
            <EmptyFeed />
          )}
        </div>
      </AssistantContent>
    </AssistantContainer>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  programThemeIconUrl: programSelectors.getProgramThemeIcon(state),
});

export default connect<StateProps, never, OwnProps, RootPatronState>(
  mapStateToProps
)(AssistantResources);
