import { useTranslation } from 'react-i18next';
import { LightBulb } from '../ui';

const CommentsThinkWarning = ({ thinkWarningHtml, thinkWarningLink }) => {
  const { t } = useTranslation();
  const defaultWarningHtml = `<strong>${t(
    'comments.warning.think_before'
  )} </strong><span style="opacity:0.7">${t(
    'comments.warning.before_send'
  )} </span>`;

  thinkWarningHtml = thinkWarningHtml || defaultWarningHtml;
  const linkHtml = thinkWarningLink
    ? `<a class="think-warning__link" target="_blank" rel="noopener noreferrer" href="${thinkWarningLink}">${t(
        'comments.warning.more_info'
      )}</a>`
    : '';

  const thinkWarningBodyHtml = {
    __html: `${thinkWarningHtml}${linkHtml}`,
  };

  return (
    <div className="comments__think-warning">
      <div className="think-warning__lightbulb-wrapper">
        <LightBulb />
      </div>
      <div
        className="think-warning__body"
        dangerouslySetInnerHTML={thinkWarningBodyHtml}
      />
    </div>
  );
};

export default CommentsThinkWarning;
