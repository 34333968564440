/* eslint-disable react/prop-types */
import {
  ComponentPropsWithRef,
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import cx from 'classnames';
import { ForwardActions } from '../../config/history';
import useNavigationRefetch from '../../common/use-navigation-refetch';
import { uiOperations } from '../../models/ui';
import { ID as ExternalLinkModalId } from '../external-link-modal/external-link-modal';
import { shouldShowExternalLinkWarning } from '../../lib/teams-helper';

import './ui.scss';

type NavLinkProps = PropsWithChildren<{
  action?: ForwardActions;
  activeClassName?: string;
  exact?: boolean;
  className?: string;
  href: string;
  reloadDocument?: boolean;
}>;

export function NavLink({
  action,
  activeClassName,
  exact,
  reloadDocument,
  className,
  ...props
}: Omit<LinkProps, keyof NavLinkProps> & NavLinkProps) {
  const routeMatch = useRouteMatch({
    path: props.href,
    exact,
  });

  className = cx(className, {
    [activeClassName || 'active']: routeMatch && (!exact || routeMatch.isExact),
  });

  return (
    <Link
      className={className}
      action={action || routeMatch?.isExact ? 'replace' : 'push'}
      reloadDocument={reloadDocument}
      {...props}
    />
  );
}

type LinkProps = {
  action?: ForwardActions;
  reloadDocument?: boolean;
  followNestedLink?: boolean;
} & PropsWithChildren<ComponentPropsWithRef<'a'>>;
const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      rel,
      href,
      target,
      title,
      action,
      reloadDocument,
      className,
      style,
      children,
      onClick,
      onKeyDown,
      onPointerOver,
      onPointerOut,
      tabIndex,
      followNestedLink = false,
      ...props
    },
    ref
  ) => {
    const history = useHistory();
    const { navigateWithRefetch } = useNavigationRefetch();
    const dispatch = useDispatch();

    className = cx(className, 'link');

    const prefixedHref = action ? history.createHref({ pathname: href }) : href;

    const isTargetNestedLink = (e: MouseEvent) =>
      e.target !== e.currentTarget && e.target instanceof HTMLAnchorElement;

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      if (onClick) onClick(e);

      if (shouldShowExternalLinkWarning() && target === '_blank' && href) {
        dispatch(
          uiOperations.displayOverlay(ExternalLinkModalId, { linkUrl: href })
        );
        e.preventDefault();
        return;
      }

      if (
        !action ||
        !href ||
        e.defaultPrevented ||
        (isTargetNestedLink(e) && followNestedLink)
      ) {
        return;
      }

      e.preventDefault();

      if (reloadDocument && href) {
        navigateWithRefetch(href, undefined, action);
        return;
      }

      history[action](href);
    };

    return (
      <a
        rel={rel}
        href={prefixedHref}
        title={title}
        target={target}
        className={className}
        style={style}
        ref={ref}
        onClick={handleClick}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        aria-label={props['aria-label']}
        //generally speaking, do not use this. only for stopgap and hack solutions. links should be accessible pretty much always.
        aria-hidden={props['aria-hidden']}
        {...props}
      >
        {children}
      </a>
    );
  }
);

Link.displayName = 'UI.Link';
export default Link;
