import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useContentFetcher from '../../common/use-content-fetcher';
import get from 'lodash/get';

import useInview, { useInviewFor } from '../../common/use-inview';
import {
  trackAssistantCommandSummaryResponseView,
  trackAssistantCommandSummaryResponseClick,
  trackAssistantCommandContentResponseView,
  trackAssistantCommandContentResponseClick,
  trackAssistantCommandChannelResponseView,
  trackAssistantCommandChannelResponseClick,
  trackAssistantCommandLinkResponseView,
  trackAssistantCommandLinkResponseClick,
  trackAssistantCommandPollResponseView,
} from '../../models/assistant/analytics';
import operations from '../../models/assistant/operations';
import { getViewTrackingStatus } from '../../models/assistant/selectors';
import AssistantAction from './assistant-action';
import AssistantPoll from './assistant-poll';
import { Spinner } from '../ui';
import ChannelCard from '../channel-card';
import ContentItem from '../content-item';
import './assistant.scss';
import { Subject } from '../../models/assistant/interfaces/Subject';
import { AnalyticsData } from '../../models/assistant/interfaces/AnalyticsData';
import { SubjectItem } from '../../models/assistant/interfaces/SubjectItem';
import { Card } from '../../models/assistant/interfaces/subject/Summary';

interface AssistantResponseSummaryProps {
  response: Subject;
  analyticsData: AnalyticsData;
}

export function AssistantResponseSummary({
  response,
  analyticsData,
}: AssistantResponseSummaryProps) {
  const handleResponseView = () => {
    trackAssistantCommandSummaryResponseView(analyticsData);
  };

  const handleResponseClick = () => {
    trackAssistantCommandSummaryResponseClick(analyticsData);
  };

  const ref = useRef<HTMLInputElement>(null);
  useInview({
    ref,
    once: true,
    onInview: handleResponseView,
  });

  const title = decodeURIComponent(response.title || '');
  const description = decodeURIComponent(response.description || '');

  const summaryClass =
    'assistant__history-item-summary assistant__history-item-tile';

  const table = (
    <>
      <h3>{title}</h3>
      <p>{description}</p>
      {response.data_table ? (
        <table>
          <tbody>
            {response.data_table.map((row, index) => (
              <tr key={index}>
                {row.map((col, idx) => (
                  <td key={idx}>{col}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </>
  );

  return response.title || response.description || response.data_table ? (
    response.action ? (
      <AssistantAction
        asCommand
        className={summaryClass}
        style={{ backgroundColor: response.background_color }}
        ref={ref}
        action={response.action}
        analyticsData={analyticsData}
        onClick={handleResponseClick}
      >
        {table}
      </AssistantAction>
    ) : (
      <div
        className={summaryClass}
        style={{ backgroundColor: response.background_color }}
        ref={ref}
      >
        {table}
      </div>
    )
  ) : null;
}

interface AssistantResponseChannelProps {
  response: Pick<Subject, 'id'>;
  analyticsData: AnalyticsData;
}

export function AssistantResponseChannel({
  response,
  analyticsData,
}: AssistantResponseChannelProps) {
  const handleResponseView = () => {
    trackAssistantCommandChannelResponseView({
      ...analyticsData,
      channel_id: response.id,
    });
  };

  const handleResponseClick = () => {
    trackAssistantCommandChannelResponseClick({
      ...analyticsData,
      channel_id: response.id,
    });
  };

  const ref = useRef<HTMLInputElement>(null);
  useInview({
    ref,
    once: true,
    onInview: handleResponseView,
  });

  return (
    <div
      className="assistant__history-item-channel assistant__history-item-tile"
      ref={ref}
    >
      <ChannelCard
        onClick={handleResponseClick}
        analyticsData={analyticsData}
        channelId={response.id}
        orientation="portrait"
        shouldTrackView={false}
        tag={null}
        size="small"
      />
    </div>
  );
}

interface AssistantResponseContentProps {
  response: Pick<Subject, 'id'>;
  analyticsData: AnalyticsData;
  historyItemId?: string;
}

export function AssistantResponseContent({
  response,
  analyticsData,
  historyItemId,
}: AssistantResponseContentProps) {
  const { content, fetching } = useContentFetcher({
    contentId: response.id as unknown as number,
  });
  const dispatch = useDispatch();
  const contentCardId = historyItemId + '-' + response.id;
  const isViewTracked = useSelector(getViewTrackingStatus(contentCardId));
  const { t } = useTranslation();

  const handleResponseView = useCallback(() => {
    if (isViewTracked) {
      return;
    }
    dispatch(operations.markContentTracked(contentCardId));
    trackAssistantCommandContentResponseView({
      ...analyticsData,
      content_id: response.id,
    });
  }, [isViewTracked, dispatch, contentCardId, analyticsData, response.id]);

  const handleResponseClick = useCallback(() => {
    trackAssistantCommandContentResponseClick({
      ...analyticsData,
      content_id: response.id,
    });
  }, [analyticsData, response.id]);

  const ref = useRef<HTMLInputElement>(null);
  useInviewFor({
    ref,
    once: true,
    timeout: 1000,
    cb: handleResponseView,
  });

  return (
    <div
      className="assistant__history-item-content assistant__history-item-tile"
      ref={ref}
    >
      <Spinner center loading={fetching}>
        {content ? (
          <ContentItem
            analyticsData={analyticsData}
            onClick={handleResponseClick}
            contentId={response.id}
            orientation="portrait"
            playInline
            size="small"
          />
        ) : (
          <div className="assistant__history-item-content__error">
            {t('comments.errors.content_not_found')}
          </div>
        )}
      </Spinner>
    </div>
  );
}

interface AssistantResponseLinkProps {
  response: Subject;
  analyticsData: AnalyticsData;
}

export function AssistantResponseLink({
  response,
  analyticsData,
}: AssistantResponseLinkProps) {
  const isLinkAction = response.action && response.action.type === 'navigation';
  const isExternal =
    isLinkAction && response.action?.navigation?.type === 'external';
  const url = isLinkAction ? response.action?.navigation?.url : undefined;

  const handleResponseView = () => {
    trackAssistantCommandLinkResponseView({
      ...analyticsData,
      url,
    });
  };

  const handleResponseClick = () => {
    trackAssistantCommandLinkResponseClick({
      ...analyticsData,
      url,
    });
  };

  const ref = useRef<HTMLAnchorElement>(null);
  useInview({
    ref,
    once: true,
    onInview: handleResponseView,
  });

  return isLinkAction ? (
    <a
      href={url}
      target={isExternal ? '_blank' : undefined}
      ref={ref}
      onClick={handleResponseClick}
      className="assistant__history-item-link assistant__history-item-tile"
      style={{ backgroundColor: response.background_color }}
      rel="noreferrer"
    >
      <h3>{response.title}</h3>
      <p>{response.description}</p>
      <p className="url">{url}</p>
    </a>
  ) : null;
}

interface AssistantResponseCarouselProps {
  response: Subject;
  analyticsData: AnalyticsData;
  historyItemId: string;
}
export function AssistantResponseCarousel({
  response,
  analyticsData,
  historyItemId,
}: AssistantResponseCarouselProps) {
  return (
    <div className="assistant__history-item-carousel">
      {response.items &&
        response.items.map((item, position) => {
          const ResponseComponent = get(
            ResponseCardTypes,
            item.type,
            AssistantResponseSummary
          );
          return (
            <ResponseComponent
              response={item as Subject}
              key={item.id}
              analyticsData={{ ...analyticsData, position }}
              historyItemId={historyItemId}
            />
          );
        })}
    </div>
  );
}

interface AssistantResponsePollProps {
  response: Subject;
  analyticsData: AnalyticsData;
}
export function AssistantResponsePoll({
  response,
  analyticsData,
}: AssistantResponsePollProps) {
  const handleResponseView = () => {
    trackAssistantCommandPollResponseView({
      ...analyticsData,
      poll_id: response.id,
    });
  };

  const ref = useRef<HTMLInputElement>(null);
  useInview({
    ref,
    once: true,
    onInview: handleResponseView,
  });

  // response.poll_results
  return (
    <div
      className="assistant__history-item-poll assistant__history-item-tile"
      ref={ref}
    >
      <AssistantPoll subject={response as Card | SubjectItem} />
    </div>
  );
}

export const AssistantResponseNull = () => null;

interface AssistantResponseCardProps {
  response: Subject;
  analyticsData?: AnalyticsData;
  historyItemId: string;
}
export function AssistantResponseCard({
  response,
  analyticsData,
  historyItemId,
}: AssistantResponseCardProps) {
  const ResponseComponent = get(
    ResponseCardTypes,
    response.type,
    AssistantResponseSummary
  );
  return (
    <ResponseComponent
      response={response}
      analyticsData={{ ...analyticsData, location: 'assistant_dialog' }}
      historyItemId={historyItemId}
    />
  );
}

const ResponseCardTypes = {
  carousel: AssistantResponseCarousel,
  summary: AssistantResponseSummary,
  content: AssistantResponseContent,
  channel: AssistantResponseChannel,
  link: AssistantResponseLink,
  loading: AssistantResponseNull,
  poll: AssistantResponsePoll,
  service: AssistantResponseNull,
};

export default AssistantResponseCard;
