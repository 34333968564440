const acknowledgeContent = (state, id) => ({
  ...state,
  [id]: {
    ...state[id],
    acknowledgement: {
      ...state[id].acknowledgement,
      acknowledged_at: new Date(),
    },
  },
});

const unacknowledgeContent = (state, id) => ({
  ...state,
  [id]: {
    ...state[id],
    acknowledgement: {
      ...state[id].acknowledgement,
      acknowledged_at: null,
    },
  },
});

const addContent = (state, content) => ({
  ...state,
  [content.id]: content,
});

const addContents = (state, contents) => {
  const newContents = contents.reduce(
    (obj, content) => ({
      ...obj,
      [content.id]: content,
    }),
    {}
  );

  return {
    ...state,
    ...newContents,
  };
};

const likeContent = (state, id) => {
  const likeCount = state[id].reaction_counts.likes || 0;

  return {
    ...state,
    [id]: {
      ...state[id],
      liked: true,
      reaction_counts: {
        ...state[id].reaction_counts,
        likes: likeCount + 1,
      },
    },
  };
};

const unlikeContent = (state, id) => {
  // Defaulting to 1 because we're immediately going to subtract a like, avoid "-1 Likes"
  const likeCount = state[id].reaction_counts.likes || 1;

  return {
    ...state,
    [id]: {
      ...state[id],
      liked: false,
      reaction_counts: {
        ...state[id].reaction_counts,
        likes: likeCount - 1,
      },
    },
  };
};

const updateContentCommentsCount = (state, id, val) => {
  const count = state[id].comment_count + val;
  return updateContent(state, id, { comment_count: count });
};

const updateContent = (state, id, attrs = {}) => ({
  ...state,
  [id]: {
    ...state[id],
    ...attrs,
  },
});

export default {
  acknowledgeContent,
  unacknowledgeContent,
  addContent,
  addContents,
  likeContent,
  unlikeContent,
  updateContentCommentsCount,
  updateContent,
};
