import { useState, useEffect } from 'react';
import ProgramService from '../services/program';

const usePoliciesFetcher = () => {
  const [policies, setPolicies] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState();

  const programService = new ProgramService();

  const fetchPolicies = async () => {
    setIsFetching(true);

    try {
      const res = await programService.fetchPolicies();
      handleFetchSuccess(res);
    } catch (err) {
      if (err.response) {
        handleFetchFailure(err);
      } else {
        throw err;
      }
    }
  };

  const handleFetchSuccess = (res) => {
    const policies = res.data;

    setPolicies(policies);
    setIsFetching(false);
  };

  const handleFetchFailure = () => {
    setIsError(true);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    policies,
    isFetching,
    isError,
  };
};

export default usePoliciesFetcher;
