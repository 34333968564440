import { Dispatch } from 'redux';
import ContentService from '../../services/content.js';
import { dispatchToastOrFlashMessage } from '../../components/v2/toaster/deprecation-helper';
import actions from './actions.js';
import { RootPatronState } from '../../common/use-patron-selector';

const acknowledgedContent = actions.acknowledgedContent;
const acknowledgedContentComplete = actions.acknowledgedContentComplete;
const unacknowledgedContent = actions.unacknowledgedContent;
const addContent = actions.addContent;
const addContents = actions.addContents;
const likedContent = actions.likedContent;
const unlikedContent = actions.unlikedContent;
const bookmarkedContent = actions.bookmarkedContent;
const unbookmarkedContent = actions.unbookmarkedContent;
const sharedContent = actions.sharedContent;
const unsharedContent = actions.unsharedContent;
const incrementCommentCount = actions.incrementCommentCount;
const decrementCommentCount = actions.decrementCommentCount;

const acknowledgeContent =
  (id: number) => (dispatch: Dispatch, getState: () => RootPatronState) => {
    dispatch(acknowledgedContent(id));

    new ContentService(id)
      .acknowledge()
      .then((res) => {
        const contentItem =
          res.data && res.data.data ? res.data.data[0].attributes : {};
        dispatch(acknowledgedContentComplete(id, contentItem));
      })
      .catch((err) => {
        dispatch(unacknowledgedContent(id));
        dispatchToastOrFlashMessage(
          {
            text: 'Error during acknowlegement',
            type: 'error',
          },
          dispatch,
          getState
        );
        throw err;
      });
  };

const completeContent = (id: number) => (dispatch: Dispatch) => {
  dispatch(actions.completedContent(id));
};

const likeContent = (id: number) => async (dispatch: Dispatch) => {
  dispatch(likedContent(id));

  try {
    await new ContentService(id).like();
  } catch (err) {
    dispatch(unlikedContent(id));

    throw err;
  }
};

const unlikeContent = (id: number) => async (dispatch: Dispatch) => {
  dispatch(unlikedContent(id));

  try {
    await new ContentService(id).unlike();
  } catch (err) {
    dispatch(likedContent(id));

    throw err;
  }
};

const bookmarkContent = (id: string | number) => (dispatch: Dispatch) => {
  dispatch(bookmarkedContent(id));

  new ContentService(id).bookmark().catch((err) => {
    dispatch(unbookmarkedContent(id));

    throw err;
  });
};

const unbookmarkContent = (id: string | number) => (dispatch: Dispatch) => {
  dispatch(unbookmarkedContent(id));

  new ContentService(id).unbookmark().catch((err) => {
    dispatch(bookmarkedContent(id));

    throw err;
  });
};

type ShareContentProps = {
  id: number;
  url: string;
  network: string;
  channelId?: string;
};

const shareContent =
  ({ id, url, network, channelId }: ShareContentProps) =>
  async (dispatch: Dispatch) => {
    dispatch(sharedContent(id));

    try {
      await new ContentService(id).share({ url, network, channelId });
    } catch (err) {
      dispatch(unsharedContent(id));
      throw err;
    }
  };

export default {
  acknowledgeContent,
  addContent,
  addContents,
  completeContent,
  likeContent,
  unlikeContent,
  bookmarkContent,
  unbookmarkContent,
  shareContent,
  incrementCommentCount,
  decrementCommentCount,
};
