import { matchPath, useLocation } from 'react-router-dom';

type UseRouteMatchOneOfOptions = {
  exact?: boolean;
  strict?: boolean;
};

export function useRouteMatchOneOf(
  patterns: readonly string[],
  options?: UseRouteMatchOneOfOptions
) {
  const location = useLocation();

  for (const pattern of patterns) {
    const match = matchPath(location.pathname, { path: pattern, ...options });
    if (match) {
      return match;
    }
  }
}
