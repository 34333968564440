import { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { uiOperations } from '../../models/ui';
import { programSelectors } from '../../models/program';
import { contentOperations } from '../../models/content/index.js';

import useCommentFeed, { SortOrder } from '../../common/use-comment-feed';

import {
  destroyComment,
  reportComment,
  highlightComment,
  unhighlightComment,
} from '../../services/comment';

import CommentsMain from './comments-main';
import CommentsFooter from './comments-footer';
import { ID as CommentReportConfirmDialogID } from './comment-report-confirm-dialog';
import { ID as CommentDeleteConfirmDialogID } from './comment-delete-confirm-dialog';

import ThinkWarningProvider from '../../config/context';

import './comments.scss';
import { CommentContext } from './types';
import { Comment, CommentWithReplies } from '../../models/comments/types';
import { RootPatronState } from '../../common/use-patron-selector';
import { findFirstScrollableParent } from '../../lib/dom-utils';
import { FlashMessage } from './comment-flash-message';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = {
  contentId: number;
  sortOrder: SortOrder;
};

type CommentsProps = StateProps & DispatchProps & OwnProps;

const Comments = ({
  contentId,
  sortOrder,
  addOverlay,
  removeOverlay,
  incrementCommentCount,
  decrementCommentCount,
  showThinkWarning,
  thinkWarningHtml,
  thinkWarningLink,
}: CommentsProps) => {
  const { t } = useTranslation();

  const footerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [context, setContext] = useState<CommentContext>({ contentId });
  const [isFormFocused, setIsFormFocused] = useState(false);
  const [scrollRequested, setScrollRequested] = useState<HTMLDivElement>();

  const {
    comments,
    hasNextPage,
    fetchNextPage,
    fetchReplies,
    isFetching,
    message,
    setMessage,
    addComment,
    updateComment,
    removeComment,
  } = useCommentFeed({ contentId, sortOrder });

  const resetContext = useCallback(
    () => setContext({ contentId }),
    [contentId]
  );

  const handleReply = (
    comment: Comment,
    commentRef: RefObject<HTMLDivElement>
  ) => {
    setContext({
      contentId,
      activeCommentId: comment.id,
      replyToId: comment.id,
      replyAuthor: comment.author,
      action: 'reply',
    });

    setScrollRequested(commentRef.current ?? undefined);
  };

  const handleEdit = (comment: Comment) => {
    setContext({
      contentId,
      commentId: comment.id,
      replyToId: comment.replyToId ?? undefined,
      commentText: comment.rawContent,
      action: 'edit',
    });
  };

  const handleDelete = (comment: Comment) => {
    addOverlay(CommentDeleteConfirmDialogID, {
      onDeleteConfirm: () => {
        destroyComment(contentId, comment.id)
          .then(() => {
            removeComment(comment);
            decrementCommentCount(contentId, 1 + (comment.replyCount || 0));
            removeOverlay({ id: CommentDeleteConfirmDialogID, key: undefined });
            resetContext();
          })
          .catch((error) => {
            console.error('Failed to delete comment', error);
          });
      },
    });
  };

  const handleReport = (comment: Comment) => {
    addOverlay(CommentReportConfirmDialogID, {
      onReportConfirm: () => {
        reportComment(contentId, comment.id)
          .then(() => {
            const updatedComment: Comment = {
              ...comment,
              isReported: true,
              canReport: false,
            };

            updateComment(updatedComment);

            removeOverlay({ id: CommentReportConfirmDialogID, key: undefined });

            setMessage({
              text: t('comments.messages.success.report'),
              type: 'success',
              timeout: 1000 * 3,
            });
          })
          .catch((error) => {
            console.error('Failed to report comment', error);
          });
      },
    });
  };

  const handleSubmit = (comment: Comment) => {
    setMessage(undefined);

    setContext({
      contentId,
      activeCommentId: comment.id,
    });

    if (!comment.isEdited) incrementCommentCount(contentId);

    addComment(comment);
  };

  const handleReplies = (comment: CommentWithReplies) => {
    //TODO: add this condition for pagination support: || (comment.replies_current_page < comment.replies_page_count)
    if (!comment.replies && !!comment.replyCount) {
      void fetchReplies(comment);
    }
  };

  const handleHighlight = (comment: Comment) => {
    if (!comment.highlightedAt) {
      highlightComment(contentId, comment.id)
        .then(() => {
          const updatedComment = {
            ...comment,
            highlightedAt: new Date().toISOString(),
          };

          updateComment(updatedComment);

          if (sortOrder === 'top') {
            fetchNextPage(true).catch((error) => {
              console.error('Failed to fetch next page', error);
            });
          }
        })
        .catch((error) => {
          console.error('Failed to highlight comment', error);
        });
    } else {
      unhighlightComment(contentId, comment.id)
        .then(() => {
          const updatedComment = {
            ...comment,
            highlighted: false,
          };

          updateComment(updatedComment);

          if (sortOrder === 'top') {
            fetchNextPage(true).catch((error) => {
              console.error('Failed to fetch next page', error);
            });
          }
        })
        .catch((error) => {
          console.error('Failed to unhighlight comment', error);
        });
    }
  };

  const handleReset = () => {
    setMessage(undefined);

    resetContext();
  };

  const handleError = (err: FlashMessage) => {
    setMessage(err);
  };

  const scrollToComment = useCallback((comment: HTMLDivElement) => {
    if (!containerRef.current || !footerRef.current) return;

    const commentY = comment.getBoundingClientRect().top;
    const commentHeight = comment.clientHeight;
    const footerY = footerRef.current.getBoundingClientRect().top;

    const offsetY = footerY - commentHeight - commentY;

    const scrollParent =
      findFirstScrollableParent(containerRef.current) || document.body;

    scrollParent.scrollBy({ top: -offsetY, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    resetContext();
  }, [contentId, resetContext]);

  useEffect(() => {
    if (!isFormFocused || !scrollRequested) return;

    // added some delay to wait for the virtual keyboard on mobile devices
    // since it'll affect the footer's Y coordinate
    setTimeout(() => {
      scrollToComment(scrollRequested);
      setScrollRequested(undefined);
    }, 3);
  }, [isFormFocused, scrollRequested, scrollToComment]);

  return (
    <ThinkWarningProvider
      thinkWarning={{
        showThinkWarning,
        thinkWarningHtml,
        thinkWarningLink,
      }}
    >
      <div ref={containerRef} className="comments">
        <CommentsMain
          comments={comments}
          context={context}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          onNextPage={fetchNextPage}
          onEdit={handleEdit}
          onReply={handleReply}
          onDelete={handleDelete}
          onReport={handleReport}
          onReplies={handleReplies}
          onHighlight={handleHighlight}
        />

        <CommentsFooter
          ref={footerRef}
          context={context}
          message={message}
          isDisabled={!comments.length && isFetching}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onError={handleError}
          onFormFocus={() => setIsFormFocused(true)}
          onFormBlur={() => setIsFormFocused(false)}
        />
      </div>
    </ThinkWarningProvider>
  );
};

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
  incrementCommentCount: contentOperations.incrementCommentCount,
  decrementCommentCount: contentOperations.decrementCommentCount,
};

const mapStateToProps = (state: RootPatronState) => ({
  showThinkWarning: programSelectors.getCommentThinkWarningEnabled(state),
  thinkWarningHtml: programSelectors.getCommentThinkWarningHtml(state),
  thinkWarningLink: programSelectors.getCommentThinkWarningLink(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
