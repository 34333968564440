import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { channelSelectors } from '../../models/channels';
import { LatestIcon, Link, TrendingIcon } from '../ui';
import ChannelCard from '../../components/channel-card';
import ListPicker from '../../components/list-picker';
import EmptyFeed from '../../components/feed/empty-feed';
import './channel-filters.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';

const ChannelFilters = ({
  channelIdsRecommended,
  channelIdsPopular,
  channelIdsNewest,
  analyticsData,
}) => {
  const { t } = useTranslation();
  const channelFilters = [
    { id: 'recommended', icon: 'star', text: t('discover.recommended') },
    {
      id: 'popular',
      iconComponent: <TrendingIcon />,
      text: t('discover.popular'),
    },
    { id: 'newest', iconComponent: <LatestIcon />, text: t('discover.newest') },
  ];
  const [activeFilter, setActiveFilter] = useState(channelFilters[0]);
  const [activeChannelIds, setActiveChannelIds] = useState([]);

  const handleChannelFilterChange = ({ id }) => {
    const filter = channelFilters.filter((f) => f.id === id)[0];

    setActiveFilter(filter);
  };

  useEffect(() => {
    const updateActiveChannelIds = () => {
      let channelIds = [];

      const channelLimit = 4;

      if (activeFilter.id === 'recommended') channelIds = channelIdsRecommended;

      if (activeFilter.id === 'popular') channelIds = channelIdsPopular;

      if (activeFilter.id === 'newest') channelIds = channelIdsNewest;

      channelIds = channelIds.slice(0, channelLimit);

      setActiveChannelIds(channelIds);
    };
    updateActiveChannelIds();
  }, [
    activeFilter,
    channelIdsNewest,
    channelIdsPopular,
    channelIdsRecommended,
  ]);

  const newTopicsString = useSelector((state) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const viewAllChannelsString = newTopicsString
    ? 'discover.view_all_topics'
    : 'discover.view_all_channels';

  return (
    <div className="channel-filters">
      <div className="container">
        <ListPicker
          items={channelFilters}
          activeItem={activeFilter}
          onChange={handleChannelFilterChange}
        />

        <div className="channel-filters__view-all">
          <Link href="/channels" action="push">
            {t(viewAllChannelsString)}
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="channel-list channel-list--brick">
          {activeChannelIds.length ? (
            activeChannelIds.map((id) => {
              return (
                <ChannelCard
                  channelId={id}
                  analyticsData={{
                    location: `${analyticsData.location}_channel_list`,
                  }}
                  key={id}
                />
              );
            })
          ) : (
            <EmptyFeed />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  channelIdsRecommended: channelSelectors.getChannelIdsRecommended(
    state,
    ownProps
  ),
  channelIdsPopular: channelSelectors.getChannelIdsPopular(state, ownProps),
  channelIdsNewest: channelSelectors.getChannelIdsNewest(state, ownProps),
});

export default connect(mapStateToProps, null)(ChannelFilters);
