import { usePatronSelector } from './use-patron-selector';
import { useCallback } from 'react';

export function useProgramPathPrepender() {
  const programPath = usePatronSelector(
    (state) => state.appConfig.program_path
  );

  return useCallback(
    (url: string) => {
      if (!programPath) {
        return url;
      }

      if (!url.startsWith('/')) {
        url = `/${url}`;
      }

      if (programPath.endsWith('/')) {
        return `${programPath.slice(0, -1)}${url}`;
      }

      return `${programPath}${url}`;
    },
    [programPath]
  );
}
