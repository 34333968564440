import * as types from './keys';
import { track } from '../../analytics/helpers';

const commentTraits = (comment, assignTraits) => ({
  content_id: comment.content_id,
  comment_id: comment.id,
  ...assignTraits,
});

const commentReplyTraits = (comment, assignTraits) => {
  const commentId =
    comment.reply_to_id || (comment.collection && comment.collection.commentId);

  return commentTraits(comment, {
    reply_to_id: commentId,
    ...assignTraits,
  });
};

const trackCommentSubmit = (comment, traits) => {
  track(types.CONTENT_COMMENT_SUBMIT, commentTraits(comment, traits));
};

const trackCommentEdit = (comment, traits) => {
  track(types.CONTENT_COMMENT_EDIT, commentTraits(comment, traits));
};

const trackCommentDelete = (comment, traits) => {
  track(types.CONTENT_COMMENT_DELETE, commentTraits(comment, traits));
};

const trackCommentReport = (comment, traits) => {
  track(types.CONTENT_COMMENT_REPORT, commentTraits(comment, traits));
};

const trackCommentLike = (comment, traits) => {
  track(types.CONTENT_COMMENT_LIKE, commentTraits(comment, traits));
};

const trackCommentUnlike = (comment, traits) => {
  track(types.CONTENT_COMMENT_UNLIKE, commentTraits(comment, traits));
};

const trackCommentHighlight = (comment, traits) => {
  track(types.CONTENT_COMMENT_HIGHLIGHT, commentTraits(comment, traits));
};

const trackCommentUnhighlight = (comment, traits) => {
  track(types.CONTENT_COMMENT_UNHIGHLIGHT, commentTraits(comment, traits));
};

const trackCommentProfileClick = (comment, traits) => {
  track(types.CONTENT_COMMENT_AVATAR_CLICK, commentTraits(comment, traits));
};

const trackCommentReplyClick = (comment, traits) => {
  track(types.CONTENT_COMMENT_REPLY_CLICK, commentTraits(comment, traits));
};

const trackCommentReplySubmit = (comment, traits) => {
  track(
    types.CONTENT_COMMENT_REPLY_SUBMIT,
    commentReplyTraits(comment, traits)
  );
};

const trackCommentReplyEdit = (comment, traits) => {
  track(types.CONTENT_COMMENT_REPLY_EDIT, commentReplyTraits(comment, traits));
};

const trackCommentReplyDelete = (comment, traits) => {
  track(
    types.CONTENT_COMMENT_REPLY_DELETE,
    commentReplyTraits(comment, traits)
  );
};

const trackCommentReplyReport = (comment, traits) => {
  track(
    types.CONTENT_COMMENT_REPLY_REPORT,
    commentReplyTraits(comment, traits)
  );
};

const trackCommentReplyLike = (comment, traits) => {
  track(types.CONTENT_COMMENT_REPLY_LIKE, commentReplyTraits(comment, traits));
};

const trackCommentReplyUnlike = (comment, traits) => {
  track(
    types.CONTENT_COMMENT_REPLY_UNLIKE,
    commentReplyTraits(comment, traits)
  );
};

const trackCommentReplyProfileClick = (comment, traits) => {
  track(
    types.CONTENT_COMMENT_REPLY_AVATAR_CLICK,
    commentReplyTraits(comment, traits)
  );
};

export const trackCommentEvent = (event, comment, isReply, traits = {}) => {
  if (event === 'avatarClick') traits.profile_id = comment.author.id;

  if (isReply)
    switch (event) {
      case 'submit':
        return trackCommentReplySubmit(comment, traits);
      case 'edit':
        return trackCommentReplyEdit(comment, traits);
      case 'delete':
        return trackCommentReplyDelete(comment, traits);
      case 'report':
        return trackCommentReplyReport(comment, traits);
      case 'like':
        return trackCommentReplyLike(comment, traits);
      case 'unlike':
        return trackCommentReplyUnlike(comment, traits);
      case 'avatarClick':
        return trackCommentReplyProfileClick(comment, traits);
      default:
        return;
    }

  if (!isReply)
    switch (event) {
      case 'submit':
        return trackCommentSubmit(comment, traits);
      case 'edit':
        return trackCommentEdit(comment, traits);
      case 'delete':
        return trackCommentDelete(comment, traits);
      case 'report':
        return trackCommentReport(comment, traits);
      case 'like':
        return trackCommentLike(comment, traits);
      case 'unlike':
        return trackCommentUnlike(comment, traits);
      case 'replyClick':
        return trackCommentReplyClick(comment, traits);
      case 'avatarClick':
        return trackCommentProfileClick(comment, traits);
      case 'highlight':
        return trackCommentHighlight(comment, traits);
      case 'unhighlight':
        return trackCommentUnhighlight(comment, traits);
      default:
        return;
    }
};
