import types, { Channel } from './types';

export type Action =
  | {
      type: types.SET;
      channels: Channel[];
    }
  | { type: types.FOLLOW; id: string | number }
  | { type: types.UNFOLLOW; id: string | number };

const setChannels = (channels: Channel[]): Action => ({
  type: types.SET,
  channels,
});

const followedChannel = (id: string | number): Action => ({
  type: types.FOLLOW,
  id,
});

const unfollowedChannel = (id: string | number): Action => ({
  type: types.UNFOLLOW,
  id,
});

export default {
  setChannels,
  followedChannel,
  unfollowedChannel,
};
