import moment from 'moment';

import i18n from '../config/i18n';

const msInMinute = 60000;
const msInHour = 3600000;
const msInDay = 86400000;
const msInWeek = 604800000;

/* TODO: we are at a hybrid of using moment and Date, we should pick one down the line (likely Date) */
export const toDate = (date) => {
  const dateVal = moment(date);

  return dateVal.isValid() ? dateVal.toDate() : date;
};

export const toMoment = (date) => {
  const dateVal = moment(date);

  return dateVal.isValid() ? dateVal : date;
};

/**
 * Localize and format a date string for presentation to the user
 * @param {string} date - date to be formatted
 * @param {string} format - format to be used (defaults to MMMM_DD_YYYY)
 * @returns {string} the localized date
 */
export const localizeDate = (date = '', format = undefined) => {
  let dateStr = toMoment(date).format(
    format || i18n.t('datetime.formats.MMMM_DD_YYYY')
  );

  dateStr = dateStr.replace('January', i18n.t('datetime.months.january'));
  dateStr = dateStr.replace('February', i18n.t('datetime.months.february'));
  dateStr = dateStr.replace('March', i18n.t('datetime.months.march'));
  dateStr = dateStr.replace('April', i18n.t('datetime.months.april'));
  dateStr = dateStr.replace('May', i18n.t('datetime.months.may'));
  dateStr = dateStr.replace('June', i18n.t('datetime.months.june'));
  dateStr = dateStr.replace('July', i18n.t('datetime.months.july'));
  dateStr = dateStr.replace('August', i18n.t('datetime.months.august'));
  dateStr = dateStr.replace('September', i18n.t('datetime.months.september'));
  dateStr = dateStr.replace('October', i18n.t('datetime.months.october'));
  dateStr = dateStr.replace('November', i18n.t('datetime.months.november'));
  dateStr = dateStr.replace('December', i18n.t('datetime.months.december'));

  return dateStr;
};

/**
 * Returns "minutes/hours/days" until time passed
 * @param {string} date
 * @return {string} Localized time remaining until `date`
 */
export const timeTo = (date = '') => {
  let dateVal = toDate(date);

  const difference = dateVal - moment();

  // TODO: this should use the built-in plurals from i18n as not all languages follow english plurals
  if (difference < msInHour) {
    let count = Math.floor(difference / msInMinute);
    return i18n.t(`datetime.minutes.${count === 1 ? 'one' : 'other'}`, {
      count,
    });
  } else if (difference < msInDay) {
    let count = Math.floor(difference / msInHour);
    return i18n.t(`datetime.hours.${count === 1 ? 'one' : 'other'}`, { count });
  } else {
    let count = Math.floor(difference / msInDay);
    return i18n.t(`datetime.days.${count === 1 ? 'one' : 'other'}`, { count });
  }
};

/**
 * Returns "minutes/hours/days" or a Date if the time period is longer than a week
 * @param {string} date
 * @returns {string} Localized time passed since `date`
 */
export const timeAgoOrDate = (date = '') => {
  let dateVal = toDate(date);

  const difference = moment() - dateVal;

  if (difference < msInHour) {
    let count = Math.floor(difference / msInMinute);
    return i18n.t(
      `datetime.time_passed.minutes.${count === 1 ? 'one' : 'other'}`,
      {
        count,
      }
    );
  } else if (difference < msInDay) {
    let count = Math.floor(difference / msInHour);
    return i18n.t(
      `datetime.time_passed.hours.${count === 1 ? 'one' : 'other'}`,
      { count }
    );
  } else if (difference < msInWeek) {
    let count = Math.floor(difference / msInDay);
    return i18n.t(
      `datetime.time_passed.days.${count === 1 ? 'one' : 'other'}`,
      { count }
    );
  } else {
    return localizeDate(date);
  }
};
