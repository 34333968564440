import React from 'react';

import useContentFeed from '../../common/use-content-feed';

import Feed from '../../components/feed';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../components/skip-to-content/skip-to-content';

const ProfileSubmitted = ({ profileId, isOwner, analyticsData }) => {
  const { fetching, feedItems, page, canLoadMore, fetchFeed } = useContentFeed({
    advocateId: profileId,
    advocateFilter: 'submitted',
  });

  const analyticsDataFeed = {
    ...analyticsData,
    location: 'profile_submitted',
  };

  return (
    <div className="user-profile-submitted" id={DEFAULT_SKIP_TO_CONTENT_ID}>
      <Feed
        items={feedItems}
        page={page}
        fetching={fetching}
        canLoadMore={canLoadMore}
        fetchFeed={fetchFeed}
        analyticsData={analyticsDataFeed}
      />
    </div>
  );
};

export default ProfileSubmitted;
