import { connect } from 'react-redux';
import { RootPatronState } from '../../common/use-patron-selector';
import { advocateSelectors } from '../../models/advocate';
import { User } from '../../models/advocate/types';
import MentionPickerListItem from './MentionPickerListItem';

type StateProps = ReturnType<typeof mapsStateToProps>;

type OwnProps = {
  users: User[];
  onClick: (mention: string) => void;
  selected: number | undefined;
};

type MentionPickerListProps = StateProps & OwnProps;

const MentionPickerList = ({
  users,
  onClick,
  selected,
  advocateId,
}: MentionPickerListProps) => {
  const selectedUser = selected ? users[selected] : undefined;

  const userList = users.map((user) => (
    <MentionPickerListItem
      user={user}
      isSelf={advocateId === parseInt(user.id, 10)}
      onClick={onClick}
      selected={selectedUser?.id === user.id}
      key={user.id}
    />
  ));

  return (
    <ul className="mention-picker__list">
      {userList.length ? userList : null}
    </ul>
  );
};

const mapsStateToProps = (state: RootPatronState) => ({
  advocateId: advocateSelectors.getAdvocateId(state),
});

export default connect(mapsStateToProps)(MentionPickerList);
