import { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from './icon';

interface CheckboxProps {
  name: string;
  checked: boolean;
  readOnly?: boolean;
  label: string;
  helpText?: string;
  error?: string;
  tabIndex?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

/**
 * The 2-state **checkbox** in its native format.
 *
 * Checkboxes are controlled components and will not change state on their own.
 */
export function Checkbox({
  name,
  checked,
  readOnly,
  label,
  helpText,
  error,
  tabIndex,
  onChange,
  ...props
}: CheckboxProps) {
  const [isFocused, setIsFocused] = useState<boolean>();

  const handleKeydown: KeyboardEventHandler = (e) => {
    // Trigger click on spacebar
    // don't prevent default so it propagates to the label
    if (e.key === ' ') (e.target as HTMLElement).click();
  };

  return (
    <label
      {...props}
      className={cx('checkbox', props.className, { 'is-selected': checked })}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        readOnly={readOnly}
        onChange={onChange}
        tabIndex={tabIndex || 0}
      />

      <Icon
        onKeyDown={handleKeydown}
        type={checked ? 'check_box' : 'check_box_outline_blank'}
        className={cx('input', { 'is-focused': isFocused })}
      >
        {checked ? 'check_box' : 'check_box_outline_blank'}
      </Icon>

      <span className="label">
        {label ? <span className="label-text">{label}</span> : null}

        {!error && helpText ? (
          <div className="help-text">{helpText}</div>
        ) : null}

        {error ? <div className="error-text">{error}</div> : null}
      </span>
    </label>
  );
}

Checkbox.propTypes = {
  /** This name of the input */
  name: PropTypes.string,
  /** Checked status */
  checked: PropTypes.bool,
  /* Label to be display after checbock */
  label: PropTypes.string,
  /* Help text to be display below checkbox */
  helpText: PropTypes.string,
  /* Error text to be display below checkbox. Will replace help text. */
  error: PropTypes.string,
  /* Callback function to be invoked when input state is changed */
  onChange: PropTypes.func,
};

/** @component */
export default Checkbox;
