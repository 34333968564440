import { FC } from 'react';
import { ChannelTabType } from '../../../../models/channels/types';
import AboutPreview from '../preview/AboutPreview';
import MembersPreview from '../preview/MembersPreview';
import PostsPreview from '../preview/PostsPreview';
import ShortcutsPreview from '../preview/ShortcutsPreview';

export interface CardProps {
  topicId: string;
}

const PreviewCards: Record<ChannelTabType, FC<CardProps>> = {
  about: AboutPreview,
  posts: PostsPreview,
  shortcuts: ShortcutsPreview,
  members: MembersPreview,
};
import styles from './styles.module.css';
import StatsPreview from '../preview/StatsPreview';

interface Props {
  tabs: ChannelTabType[];
  currentTab: ChannelTabType;
  topicId: string;
  showStats: boolean;
}

export const Sidebar: FC<Props> = ({
  tabs,
  currentTab,
  topicId,
  showStats,
}) => {
  return (
    <div className={styles.sidebar}>
      {tabs.map((tab) => {
        if (tab === currentTab) {
          return null;
        }
        const Card = PreviewCards[tab];
        return <Card topicId={topicId} key={tab} />;
      })}
      {showStats && <StatsPreview topicId={topicId} />}
    </div>
  );
};
