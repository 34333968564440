import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { trackContentCardAvatarClick } from '../../../../models/content/analytics';

import { Avatar, Link } from '../../../ui';

import { timeAgoOrDate } from '../../../../lib/date-formatter';
import { contentSelectors } from '../../../../models/content';
import { getContentAuthorProfilePath } from '../../../../models/content/helpers';
import { programSelectors } from '../../../../models/program';
import ContentCardViews from '../content-card-views/content-card-views';
import { isEllipsisActive } from '../../../../lib/string-utils';
import Tooltip from '../../../tooltip/tooltip';
import { usePatronSelector } from '../../../../common/use-patron-selector';

type AuthorLinkProps = {
  authorId: number;
  onClick?: () => void;
  children: ReactNode;
  disableLink?: boolean;
};

const AuthorLink = forwardRef<HTMLAnchorElement, AuthorLinkProps>(
  ({ authorId, onClick, children, disableLink }, ref) => {
    return authorId && !disableLink ? (
      <Link
        rel="author"
        ref={ref}
        href={getContentAuthorProfilePath(authorId)}
        onClick={onClick}
        action="push"
      >
        {children}
      </Link>
    ) : (
      <span ref={ref} className="text">
        {children}
      </span>
    );
  }
);

AuthorLink.displayName = 'AuthorLink';

interface IContentSourceAvatar {
  contentId: number;
  authorId: number;
  authorName: string;
  avatarSrc: string | null;
  avatarColor: string | undefined;
  disableLink: boolean;
}

const trackAvatarClick = (contentId: number, authorId: number) => {
  trackContentCardAvatarClick(contentId, { author_id: authorId });
};

export const ContentSourceAvatar: React.FC<IContentSourceAvatar> = ({
  contentId,
  authorId,
  authorName,
  avatarSrc,
  avatarColor,
  disableLink,
}) => (
  <div className="content__source-avatar">
    <AuthorLink
      onClick={() => {
        trackAvatarClick(contentId, authorId);
      }}
      disableLink={disableLink}
      authorId={authorId}
    >
      <Avatar
        src={avatarSrc ?? undefined}
        alt={authorName}
        bgColor={avatarColor}
      />
    </AuthorLink>
  </div>
);

interface IContentSourceName {
  authorId: number;
  authorName: string;
  disableLink: boolean;
}

export const ContentSourceName: React.FC<IContentSourceName> = ({
  authorId,
  authorName,
  disableLink,
}) => {
  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const nameRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      setIsNameTruncated(isEllipsisActive(nameRef.current));
    }
  }, [nameRef.current]);

  const NameWithTooltip = () => {
    return (
      <Tooltip text={authorName}>
        <AuthorLink ref={nameRef} authorId={authorId} disableLink={disableLink}>
          {authorName}
        </AuthorLink>
      </Tooltip>
    );
  };

  return (
    <div className="content__source-name">
      {isNameTruncated ? (
        <NameWithTooltip />
      ) : (
        <AuthorLink ref={nameRef} authorId={authorId} disableLink={disableLink}>
          {authorName}
        </AuthorLink>
      )}
    </div>
  );
};

interface IContentSourceDate {
  publishedAt: string | false;
}

export const ContentSourceDate: React.FC<IContentSourceDate> = ({
  publishedAt,
}) => {
  return (
    <time
      dateTime={publishedAt ? String(publishedAt) : undefined}
      className="content__source-date"
    >
      {publishedAt ? timeAgoOrDate(publishedAt) : '-'}
    </time>
  );
};

type IContentSourceInfo = {
  contentId: number;
  authorId: number;
  authorName: string;
  avatarSrc: string | null;
  avatarColor: string | undefined;
  disableLink: boolean;
  publishedAt: string | false;
  showViews: boolean;
};

const ContentSourceInfo: React.FC<IContentSourceInfo> = ({
  contentId,
  authorId,
  authorName,
  avatarSrc,
  avatarColor,
  publishedAt,
  showViews,
}) => {
  const { t } = useTranslation();

  return (
    <footer className="content__source">
      <address
        aria-label={`${t('common.by')} ${authorName}`}
        className="content__source"
      >
        <ContentSourceAvatar
          contentId={contentId}
          authorId={authorId}
          authorName={authorName}
          avatarSrc={avatarSrc}
          avatarColor={avatarColor}
          disableLink={false}
        />
        <ContentSourceName
          authorId={authorId}
          authorName={authorName}
          disableLink={false}
        />
      </address>
      <span className="content__source-meta">
        <ContentSourceDate publishedAt={publishedAt} />
        {showViews && (
          <>
            <span role="separator"> ・ </span>
            <ContentCardViews contentId={contentId} showText={true} />
          </>
        )}
      </span>
    </footer>
  );
};

type ContentSourceInfoWrapperProps = {
  contentId: number;
};

const ContentSourceInfoWrapper = ({
  contentId,
}: ContentSourceInfoWrapperProps) => {
  const authorId = usePatronSelector((s) =>
    contentSelectors.getContentAuthorId(s, { contentId })
  );
  const authorName = usePatronSelector((s) =>
    contentSelectors.getContentAuthorName(s, { contentId })
  );
  const avatarSrc = usePatronSelector((s) =>
    contentSelectors.getContentAuthorAvatarSrc(s, { contentId })
  );
  const avatarColor = usePatronSelector((s) =>
    contentSelectors.getContentAuthorAvatarColor(s, { contentId })
  );
  const publishedAt = usePatronSelector((s) =>
    contentSelectors.getContentPublishedAt(s, { contentId })
  );
  const viewsEnabled = usePatronSelector((s) =>
    // @ts-ignore
    programSelectors.getFeedCardViewsEnabled(s, contentId)
  );

  return (
    <ContentSourceInfo
      contentId={contentId}
      authorId={authorId}
      authorName={authorName}
      avatarSrc={avatarSrc}
      avatarColor={avatarColor}
      disableLink={false}
      publishedAt={publishedAt}
      showViews={viewsEnabled}
    />
  );
};

export { ContentSourceInfo };

export default ContentSourceInfoWrapper;
