import { FormEventHandler, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import cx from 'classnames';

import {
  submissionNoteColor,
  submissionNoteColors,
  submissionIsSubmitting,
} from '../../../models/content-submission/atoms';
import { useTranslation } from 'react-i18next';

type ColorLabelProps = {
  color: string;
  active?: boolean;
  disabled?: boolean;
};

const ColorLabel = ({ color, active, disabled }: ColorLabelProps) => {
  const className = cx('content-submission-input-color__label', {
    'content-submission-input-color__label--active': active,
    'content-submission-input-color__label--disabled': disabled,
  });

  return (
    <label // eslint-disable-line jsx-a11y/label-has-associated-control
      className={className}
      style={{
        backgroundColor: color,
        boxShadow: active ? `0 0 0 2px ${color}` : undefined,
      }}
      htmlFor={color}
    >
      {color}
    </label>
  );
};

type ColorRadioProps = {
  color: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

const ColorRadio = ({ color, disabled, onFocus, onBlur }: ColorRadioProps) => {
  const className = cx('content-submission-input-color__input', {
    'content-submission-input-color__input--disabled': disabled,
  });

  return (
    <input
      id={color}
      className={className}
      type="radio"
      name="color"
      value={color}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

const ColorInput = () => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [noteColor, setNoteColor] = useRecoilState(submissionNoteColor);
  const noteColors = useRecoilValue(submissionNoteColors);
  const isSubmitting = useRecoilValue(submissionIsSubmitting);

  const handleChange: FormEventHandler<HTMLDivElement> = (e) => {
    const val = (e.target as HTMLInputElement).value;

    setNoteColor(val);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const className = cx('content-submission-input-color', {
    'is-focused': isFocused,
  });

  return (
    <div
      role="radiogroup"
      aria-label={t('content_submission.color_picker')}
      className={className}
      onChange={handleChange}
    >
      <div
        className="content-submission-input-color__selected"
        style={{ backgroundColor: noteColor }}
      />

      {noteColors.map((color) => (
        <div className="content-submission-input-color__block" key={color}>
          <ColorLabel
            color={color}
            active={color === noteColor}
            disabled={isSubmitting}
          />

          <ColorRadio
            color={color}
            disabled={isSubmitting}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      ))}
    </div>
  );
};

export default ColorInput;
