import { useTranslation } from 'react-i18next';
import PasswordFormRulesItem from './password-form-rules-item';
import './password-form-dialog.scss';

const PasswordFormRules = ({ passwordValidations, validationErrors }) => {
  const { t } = useTranslation();

  const items = passwordValidations.map((validation) => (
    <PasswordFormRulesItem
      text={validation.text}
      isValid={!validationErrors.includes(validation.rule)}
      key={validation.rule}
    />
  ));

  return (
    <div className="password-change-rules">
      <div className="password-change-rules__title">
        {t('password.must_contain')}
      </div>

      <ul className="password-change-rules__list">{items}</ul>
    </div>
  );
};

export default PasswordFormRules;
