import { createSelector } from 'reselect';

const getUI = (state) => state.ui || {};
const getOverlays = (state) => state.ui.overlays || [];

const getOverlayIsActive = createSelector(
  [getOverlays],
  (overlays) => overlays.length > 0
);

const getMinimizeHeader = createSelector([getUI], (ui) => !!ui.minimizeHeader);

export default {
  getOverlayIsActive,
  getMinimizeHeader,
};
