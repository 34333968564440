import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { homeLinks, homeLinksLoaded } from '../models/home-page/recoil-state';
import ProgramService from '../services/program';

const useHomeLinks = () => {
  const setHomeLinks = useSetRecoilState(homeLinks);
  const [loaded, setLoaded] = useRecoilState(homeLinksLoaded);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchDone = useCallback(() => {
    setIsFetching(false);
    setLoaded(true);
  }, [setLoaded]);

  const handleFetchHomeLinksSuccess = useCallback(
    (res) => {
      setHomeLinks(res.data.program_links);
    },
    [setHomeLinks]
  );

  const handleFetchHomeLinksError = (err: unknown) => {
    console.error(err);
    setIsError(true);
  };

  const fetchHomeLinks = useCallback(() => {
    setIsFetching(true);

    new ProgramService()
      .fetchHomeLinks()
      .then(handleFetchHomeLinksSuccess)
      .catch(handleFetchHomeLinksError)
      .finally(fetchDone);
  }, [fetchDone, handleFetchHomeLinksSuccess]);

  useEffect(() => {
    if (!loaded) {
      fetchHomeLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return {
    isFetching: !loaded || isFetching,
    isError,
    fetchHomeLinks,
  };
};

export default useHomeLinks;
