import moment from 'moment';

export const getCookieValue = (key, defaultVal, decoded = true) => {
  try {
    const item = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${key}=`))
      .split('=')[1];

    const item_value = decoded ? decodeURIComponent(item) : item;

    return item_value ? item_value : defaultVal;
  } catch {
    return defaultVal;
  }
};

export const setCookieValue = (key, value, encoded = true, opts = {}) => {
  const options = Object.assign(
    {
      expires: moment().add(1, 'years').utc(true),
    },
    opts
  );

  try {
    const cookie_value = encoded ? encodeURIComponent(value) : value;
    document.cookie =
      `${key}=${cookie_value};` +
      Object.entries(options)
        .map(([k, v]) => `${k}=${v};`)
        .join('');
    return true;
  } catch {
    return false;
  }
};
