import { useTranslation } from 'react-i18next';

import './feed.scss';

const EndOfFeed = () => {
  const { t } = useTranslation();

  return <div className="end-of-feed">{t('messages.end_of_feed')}</div>;
};

export default EndOfFeed;
