import { ComponentProps, useEffect } from 'react';
import ContentItem from '../../../components/content-item';

type ContentTypeDefaultProps = Pick<
  ComponentProps<typeof ContentItem>,
  'contentId'
> & {
  analyticsData: Record<string, unknown>;
  onResize?: () => void;
};

const ContentTypeDefault = ({
  contentId,
  onResize,
  analyticsData,
}: ContentTypeDefaultProps) => {
  useEffect(() => {
    onResize?.();
  }, [onResize]);

  return (
    <ContentItem
      contentId={contentId}
      orientation="portrait"
      size="large"
      truncate={false}
      analyticsData={analyticsData}
    />
  );
};

export default ContentTypeDefault;
