import api from '../config/api';

class ProgramService {
  fetch = () => {
    return api.get(''); // program route already part of base url
  };

  fetchInviteLink = () => {
    return api.post('/invite_links');
  };

  fetchAppStoreLinks = () => {
    return api.get('mobile_apps/app_store_links');
  };

  fetchHomeLinks = () => {
    return api.get('/links', {
      baseURL: api.defaults.baseURL.replace('v2', 'v3'),
    });
  };

  fetchFeedSections = () => {
    return api.get('/feed_sections', {
      baseURL: api.defaults.baseURL.replace('v2', 'v3'),
    });
  };

  fetchPolicies = () => {
    return api.get('/policies', {
      baseURL: api.defaults.baseURL.replace('v2', 'v1'),
    });
  };

  fetchTrendingSearches = () => {
    return api.get('/trending_searches', {
      baseURL: api.defaults.baseURL.replace('v2', 'v3'),
    });
  };
}

export default ProgramService;
