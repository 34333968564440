import { atom, DefaultValue, selectorFamily } from 'recoil';
import { TopicShortcut } from '../../channels/types';

export const topicShortcutsState = atom<{
  [key: string]: TopicShortcut[] | DefaultValue | null;
}>({
  key: 'topicShortcutsState',
  default: {},
});

export const shortcutsByTopicId = selectorFamily<
  TopicShortcut[] | null,
  string
>({
  key: 'topicShortcutSelector',
  get:
    (topicId: string) =>
    ({ get }) => {
      return (get(topicShortcutsState)[topicId] as TopicShortcut[]) || null;
    },
  set:
    (topicId: string) =>
    ({ set, get }, shortcuts: DefaultValue | TopicShortcut[] | null) => {
      const store = get(topicShortcutsState);
      const newStore = {
        ...store,
        [topicId]: shortcuts,
      };

      if (shortcuts !== null) {
        set(topicShortcutsState, newStore);
      }
    },
});
