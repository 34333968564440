import { Modal } from '../ui';

import './content-error-modal.scss';

const ContentErrorModal = ({ close }) => {
  return (
    <Modal className="content-error-modal" onClose={close}>
      <div className="content-error-modal__title">Content Not Found!</div>
      <p className="content-error-modal__body">
        Please check the URL and try again.
      </p>
    </Modal>
  );
};

export const ID = 'Overlays.CONTENT_ERROR_MODAL';

export default ContentErrorModal;
