import { useTranslation } from 'react-i18next';

import useMediaUploader from '../../common/use-media-uploader';

type MediaUploaderProps = {
  file: File;
  onStatusChange?: (status: string) => void;
  onComplete?: (url: string) => void;
  onError?: (err: Error) => void;
};

const MediaUploader = ({
  file,
  onStatusChange,
  onComplete,
  onError,
}: MediaUploaderProps) => {
  const { status, progress } = useMediaUploader({
    file,
    onStatusChange,
    onComplete,
    onError,
  });

  const { t } = useTranslation();

  let translatedStatus;

  switch (status) {
    case 'fetching':
      translatedStatus = t('common.fetching');
      break;
    case 'uploading':
      translatedStatus = t('common.uploading');
      break;
    case 'complete':
      translatedStatus = t('common.done');
      break;
    case 'error':
      translatedStatus = t('common.error');
      break;
    default:
      translatedStatus = status;
  }

  return (
    <div
      className="media-uploader"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={progress}
      aria-labelledby="media-uploader--status"
    >
      <div className="progress">
        <div className="progress__inner" style={{ width: `${progress}%` }} />
      </div>

      <div id="media-uploader--status" className="status">
        {translatedStatus}
      </div>
    </div>
  );
};

export default MediaUploader;
