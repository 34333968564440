import { ComponentProps } from 'react';
import CommentListItem from './comment-list-item';
import CommentsEmpty from './comments-empty';

type CommentListProps = {
  comments: ComponentProps<typeof CommentListItem>['comment'][];
} & Pick<
  ComponentProps<typeof CommentListItem>,
  | 'context'
  | 'onReply'
  | 'onEdit'
  | 'onDelete'
  | 'onReport'
  | 'onReplies'
  | 'onHighlight'
>;

const CommentList = ({
  comments,
  context,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
}: CommentListProps) => {
  return comments.length ? (
    <div className="comment__list">
      {comments.map((comment) => (
        <CommentListItem
          comment={comment}
          context={context}
          onReply={onReply}
          onEdit={onEdit}
          onDelete={onDelete}
          onReport={onReport}
          onReplies={onReplies}
          onHighlight={onHighlight}
          key={comment.id}
        />
      ))}
    </div>
  ) : (
    <CommentsEmpty />
  );
};

export default CommentList;
